import React from 'react';
import { Flex, Text } from '@mozaic-ds/react';
import { useUserData } from 'store/auth/user';
import { getLocale, parseDateTime } from 'common/utils';
import { useChatMessagesData } from 'store/chat/chat';
import styles from './Chat.module.scss';

const Messages = () => {
  const { messages } = useChatMessagesData().chatMessages;
  const { lang } = useUserData().user;

  return (
    <>
      {messages.map(({ id, message, userName, createDate }) => (
        <Flex justifyContent="space-between" className="mu-pb-150" key={id}>
          <Text weight="semi-bold">
            {userName}
            <Text as="p" weight="light">
              {message}
            </Text>
          </Text>
          <Text className={styles.date}>
            {parseDateTime(new Date(createDate), getLocale(lang))}
          </Text>
        </Flex>
      ))}
    </>
  );
};

export default Messages;
