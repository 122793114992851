import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequiredStateFields, RootState } from 'store/types';
import { useAppSelector } from 'store/hooks';
import { IGetLatestCbdTemplateInfoResponse } from 'common/cbd/types';
import { getLatestCbdTemplateInfo } from 'api/cbd/templates';
import { setLoading, setFailure } from '../helpers';

const initialState = {
  date: '',
  fileName: '',
  fileUrl: '',
  id: 0,
  version: '',
  isLoading: false,
  isFailure: false,
  isUploadLoading: false,
  isUploadFailure: false,
};

interface SliceState extends IGetLatestCbdTemplateInfoResponse, RequiredStateFields {
  isUploadLoading: boolean;
  isUploadFailure: boolean;
}

// Возвращает информацию последнему темплейту CBD, не предусмотрен выбор
export const getLatestCbdTemplateInfoData = createAsyncThunk(
  'latestTemplate/getLatestCbdTemplateInfoData',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getLatestCbdTemplateInfo();
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const latestTemplateSlice = createSlice({
  initialState,
  name: 'latestTemplate',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLatestCbdTemplateInfoData.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getLatestCbdTemplateInfoData.fulfilled,
        (state: SliceState, action: PayloadAction<IGetLatestCbdTemplateInfoResponse>) => {
          state.date = action.payload.date;
          state.fileName = action.payload.fileName;
          state.id = action.payload.id;
          state.version = action.payload.version;
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getLatestCbdTemplateInfoData.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const useLatestTemplate = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.latestTemplate);
};
