import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IGetCompareCommonPayload, getCompareComponentsCostReq } from 'api/cbd/cbdAnalysis';
import { IPurchasePrice } from 'common/cbd/types';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

interface SliceState {
  data: IPurchasePrice;
}

const initialState: SliceState = {
  data: {},
};

export const getCompareComponentsCost = createAsyncThunk(
  'cbd/cbdAnalysis/getCompareComponentsCost',
  async (payload: IGetCompareCommonPayload, { rejectWithValue }) => {
    try {
      return await getCompareComponentsCostReq(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const compareComponentsCostSlice = createSlice({
  initialState,
  name: 'compareComponentsCost',
  reducers: {
    clearCompareComponentCost: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCompareComponentsCost.fulfilled,
      (state: SliceState, action: PayloadAction<IPurchasePrice>) => {
        state.data = action.payload;
      }
    );
  },
});

export const { clearCompareComponentCost } = compareComponentsCostSlice.actions;

export const useCompareComponentsCostData = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.cbdAnalysis.compare.componentsCost.data);
};
