import { DownloadWeb24 } from '@mozaic-ds/icons/react';
import { Button, Flex, Text, View } from '@mozaic-ds/react';
import React, { FC } from 'react';
import cn from 'classnames';
import FileUploader from 'components/FileUploader/FileUploader';
import { getLatestCbdTemplateInfoData, useLatestTemplate } from 'store/cbd/templatesCbd';
import { useUserData } from 'store/auth/user';
import { useAppDispatch } from 'store/hooks';
import { useAppTranslations } from 'translations';
import { getHasGrants, downloadCbdTemplate } from 'common/utils';
import { useMount } from 'hook/useMount';
import { useCbdList } from 'store/cbd/allUploads/list';
import styles from './UploadForm.module.scss';
import useUploadTemplateCbdFilesForm from './hooks/useTemplateCbdForm';

interface IUploadForm {
  isWrapped?: boolean;
  updateTableData?: () => void;
}

const UploadForm: FC<IUploadForm> = ({ isWrapped, updateTableData }) => {
  const dispatch = useAppDispatch();
  const { selectedRowsId } = useCbdList();
  const { user } = useUserData();
  const template = useLatestTemplate();
  const translate = useAppTranslations();
  const hasGrants = getHasGrants(user.userGrants);
  const { templateCbdFiles, templateCbdUploadFile, onDelete, handleChange, handleDelete } =
    useUploadTemplateCbdFilesForm(updateTableData);

  useMount(() => {
    dispatch(getLatestCbdTemplateInfoData());
  });

  return (
    <View className={cn({ [styles.container]: isWrapped })} shadow="s" radius="s">
      <Text className={styles.article}>
        {`${translate('actualTemplate')}: ${translate('version')} - ${template.version} ${translate(
          'startDate'
        )} - ${new Date(template.date).toLocaleDateString()}`}
      </Text>
      <Flex className={cn(['mu-mt-100', styles.actions])} alignItems="flex-start">
        {hasGrants.AdminRole && (
          <FileUploader
            id="templateCbdFile"
            className={styles.fileUpload}
            files={templateCbdFiles.value}
            onChange={handleChange}
            onDeleteFile={handleDelete}
          >
            {translate('uploadCBDTemplate')}
          </FileUploader>
        )}
        {templateCbdFiles.value.length ? (
          <Button
            type="button"
            className="mu-ml-100"
            onClick={templateCbdUploadFile}
            size="m"
            theme="primary-02"
            variant="bordered"
          >
            {translate('upload')}
          </Button>
        ) : null}
        <Button
          type="button"
          onClick={downloadCbdTemplate}
          leftIcon={<DownloadWeb24 />}
          size="m"
          theme="neutral"
          variant="bordered"
          className="mu-mr-100 mu-ml-100"
        >
          {translate('downloadTemplate')}
        </Button>
        {updateTableData && hasGrants.AdminRole && (
          <Button
            isDisabled={!selectedRowsId.length}
            onClick={onDelete}
            size="m"
            theme="danger"
            variant="bordered"
          >
            {translate('delete')}
          </Button>
        )}
      </Flex>
    </View>
  );
};

export default UploadForm;
