import React from 'react';
import { Flex } from '@mozaic-ds/react';
import { useRequestData } from 'store/request/request';
import { DeleteRequest } from 'components/DeleteRequest/DeleteRequest';
import { useGetGrants } from 'pages/Request/hooks';
import styles from './ActionButtons.module.scss';
import { ActionButtonsContainers } from './partial/ActionButtonsContainer';
import { UpdateButton } from './partial/UpdateButton';

type Props = {
  id: string;
};
export const ActionButtons = ({ id }: Props) => {
  const { selectedContainers } = useRequestData().data;
  const { isRequestDeleteGrant } = useGetGrants();

  return (
    <Flex
      paddingTop="mu100"
      paddingBottom="mu100"
      paddingLeft="mu300"
      className={styles.actionButtons}
    >
      <UpdateButton id={id} />
      {isRequestDeleteGrant && <DeleteRequest id={id} />}
      {selectedContainers.length > 0 && <ActionButtonsContainers id={id} />}
    </Flex>
  );
};
