import { GetRequestValidationResponse, DueDateValidation } from '@ppm/ppm-platform-api';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDate } from 'api/dictionary';
import { setFailure, setLoading } from '../helpers';
import { RequiredStateFields, RootState } from '../types';
import { useAppSelector } from '../hooks';

interface SliceState extends RequiredStateFields {
  dueDate: DueDateValidation;
}

export const getDueDate = createAsyncThunk('dueDate/getDueDate', async (_, { rejectWithValue }) => {
  try {
    return await getDate({});
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const dueDateSlice = createSlice({
  initialState: { dueDate: { min: '', max: '' }, isLoading: false, isFailure: false },
  name: 'dueDate',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDueDate.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getDueDate.fulfilled,
        (state: SliceState, action: PayloadAction<GetRequestValidationResponse>) => {
          state.dueDate = action.payload.data.dueDate;
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getDueDate.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const useDueDateData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.dictionary.dueDate) };
};
