import React, { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { Button, Flex, Text, TextInput, Toggle, View, useModals } from '@mozaic-ds/react';
import { useAppTranslations } from 'translations';
import classNames from 'classnames';
import { IFilter } from 'common/types';
import { resetLoadErrors, useLoadErrors } from 'store/cbd/loadErrors';
import Modal from 'components/Modal/Modal';
import { setItemsForCompare, setSelectedRowsId } from 'store/cbd/compare/list';
import { clearPurchasePriceFilters } from 'store/cbd/cbdAnalysis/compare/purchasePrice/filters';
import {
  cbdTypeValues,
  setCbdType,
  useCbdAnalysisInfoInfoData,
} from 'store/cbd/cbdAnalysis/cbdAnalysisInfo';
import ToggleWithLabels from 'components/ToogleWithLabels/ToggleWithLabels';
import { ITableData, useTableData } from './hooks/useTableData';
import { Provider } from './partials/FormProvider/FormProvider';
import UploadForm from './partials/UploadForm/UploadForm';
import styles from './CbdView.module.scss';
import CbdTable, { IColumnsTableProps } from './partials/CbdTable/CbdTable';
import ColumnsPopup, {
  IColumnsPopup,
  RequiredFilterColumnFields,
} from './partials/ColumnsPopup/ColumnsPopup';
import FiltersPopup from './partials/FiltersPopup/FiltersPopup';
import Tags from './partials/Tags/Tags';
import useActions from './hooks/actions';
import { LOAD_ERRORS_MODAL_ID } from '../Uploads/hooks/consts';
import NomenclatureWrapper from './partials/Nomenclature/NomenclatureWrapper';
import BoundFiltersPopup from './partials/BoundFiltersPopup/BoundFiltersPopup';
import {
  ExtendsColumns,
  ExtendsFilters,
  ExtendsMainData,
  ExtendsTableRows,
} from './partials/consts';
import UploadsFiltersPopup from './partials/UploadsFiltersPopup/UploadsFiltersPopup';
import CompareFiltersPopup from './partials/CompareFiltersPopup/CompareFiltersPopup';
import ExternalSourceFiltersPopup from './partials/ExternalSourceFiltersPopup/ExternalSourceFiltersPopup';

export interface ICbdView<
  Data extends ExtendsMainData,
  Columns extends ExtendsColumns,
  FilterColumn,
  TableRows extends ExtendsTableRows
> {
  needToWrap?: boolean;
  nomenclatureVisible?: boolean;
  toggleVisible?: boolean;
  uploadFormVisible?: boolean;
  filtersVisible?: boolean;
  compareFilterVisible?: boolean;
  uploadsFiltersVisible?: boolean;
  externalSourceFiltersVisible?: boolean;
  loadErrorsPopupVisible?: boolean;
  paginationVisible?: boolean;
  boundFiltersVisible?: boolean;
  searchInputVisible?: boolean;
  pageFilters?: IFilter<ExtendsFilters>[];
  onRemoveTag?: (idForDelete: string[], filtersForDelete: IFilter<ExtendsFilters>[]) => void;
  resetFilters?: () => void;
  columnsPopup?: IColumnsPopup<Columns, FilterColumn, TableRows>;
  isLoading: boolean;
  data: ITableData<Data>;
  table: IColumnsTableProps<Columns, TableRows>;
}

const CbdView = <
  Data extends ExtendsMainData,
  Columns extends ExtendsColumns,
  FilterColumn extends RequiredFilterColumnFields<Columns>,
  TableRows extends ExtendsTableRows
>({
  needToWrap = true,
  nomenclatureVisible,
  toggleVisible,
  uploadFormVisible,
  searchInputVisible,
  filtersVisible,
  boundFiltersVisible,
  uploadsFiltersVisible,
  compareFilterVisible,
  externalSourceFiltersVisible,
  loadErrorsPopupVisible,
  paginationVisible = true,
  pageFilters,
  onRemoveTag,
  resetFilters,
  data,
  isLoading,
  columnsPopup,
  table,
}: ICbdView<Data, Columns, FilterColumn, TableRows>) => {
  const translate = useAppTranslations();
  const dispatch = useAppDispatch();
  const { haveError, id } = useActions();
  const { loadErrors, isLoadErrorsPending } = useLoadErrors();
  const { cbdType } = useCbdAnalysisInfoInfoData();
  const { close } = useModals();

  const { tableData, updateTableData, pagination } = useTableData<Data>({
    ...data,
    compareFilterVisible,
    filtersVisible,
    uploadsFiltersVisible,
    nomenclatureVisible,
    haveError: toggleVisible ? haveError.value : undefined,
    id: searchInputVisible ? id.value : undefined,
    filters: pageFilters,
    sortedField: table.sortedField,
  });

  const handleLoadErrorsModalBtn = useCallback(() => {
    dispatch(resetLoadErrors());
    close(LOAD_ERRORS_MODAL_ID);
  }, [dispatch, close]);

  const onCompare = useCallback(() => {
    dispatch(
      setItemsForCompare(
        table.selectedRowsId?.length
          ? table.selectedRowsId
          : tableData.map((item) => item.id.toString())
      )
    );
    dispatch(clearPurchasePriceFilters());
  }, [dispatch, table.selectedRowsId, tableData]);

  const onStructureChange = useCallback(() => {
    if (resetFilters) {
      resetFilters();
    }
    pagination.onPageChange(1);
    dispatch(setCbdType(cbdType === cbdTypeValues[0] ? cbdTypeValues[1] : cbdTypeValues[0]));
    dispatch(setSelectedRowsId([]));
  }, [resetFilters, pagination, dispatch, cbdType]);

  return (
    <View className={classNames({ [styles.wrapper]: needToWrap })}>
      {uploadFormVisible && (
        <Provider>
          <UploadForm isWrapped updateTableData={updateTableData} />
        </Provider>
      )}
      {nomenclatureVisible && <NomenclatureWrapper updateTableData={updateTableData} />}
      <View>
        <View marginBottom="mu100" marginTop="mu100">
          <Flex className={styles.control_flex} justifyContent="space-between" alignItems="center">
            {columnsPopup && <ColumnsPopup<Columns, FilterColumn, TableRows> {...columnsPopup} />}
            {compareFilterVisible && (
              <Flex justifyContent="center" alignItems="center">
                <Text size="l" weight="semi-bold">
                  {translate('CBD')}
                </Text>
                <ToggleWithLabels
                  labels={[translate('competitors'), translate('supplier')]}
                  activeValue={cbdType}
                  values={cbdTypeValues}
                  onChange={onStructureChange}
                />
              </Flex>
            )}
            <Flex className={styles.control_flex} alignItems="center">
              {toggleVisible && (
                <Toggle
                  {...haveError}
                  value={haveError.name}
                  isChecked={haveError.value}
                  className={classNames(styles.control_toggle, 'mu-mr-100')}
                  size="s"
                >
                  {translate('hideErroneous')}
                </Toggle>
              )}

              {searchInputVisible && (
                <TextInput
                  {...id}
                  onChange={(e) => id.onChange(e.target.value.trim())}
                  className={classNames(styles.control_input_id, 'mu-mr-100')}
                  size="s"
                  placeholder={translate(filtersVisible ? 'idRequest' : 'cbdId')}
                />
              )}

              {filtersVisible && <FiltersPopup />}
              {boundFiltersVisible && <BoundFiltersPopup />}
              {uploadsFiltersVisible && <UploadsFiltersPopup />}
              {compareFilterVisible && <CompareFiltersPopup />}
              {compareFilterVisible && (
                <Button
                  className="mu-ml-100"
                  size="s"
                  onClick={onCompare}
                  isDisabled={!data.data.length}
                >
                  {translate('compare')}
                </Button>
              )}
              {externalSourceFiltersVisible && <ExternalSourceFiltersPopup />}
            </Flex>
          </Flex>
        </View>
        {pageFilters && pageFilters.length > 0 && (
          <>
            <hr />
            <View marginBottom="mu100" marginTop="mu100">
              <Flex justifyContent="space-between">
                <View className="mu-mr-100">
                  <Tags filters={pageFilters} onRemoveTag={onRemoveTag} className={styles.tag} />
                </View>
                <View onClick={resetFilters} className="mu-pl-100">
                  <Text className={styles.control_clearTags} as="span">
                    {translate('clear')}
                  </Text>
                </View>
              </Flex>
            </View>
          </>
        )}
        {loadErrorsPopupVisible && (
          <Modal
            id={LOAD_ERRORS_MODAL_ID}
            title={translate('errorsList')}
            okBtnText={translate('close')}
            text=""
            isLoading={isLoadErrorsPending}
            handleOk={handleLoadErrorsModalBtn}
            handleClose={handleLoadErrorsModalBtn}
            onClose={handleLoadErrorsModalBtn}
          >
            <View>
              {loadErrors.map((le) => (
                <Flex>
                  <Text>
                    {le.code}: {le.description}
                  </Text>
                </Flex>
              ))}
            </View>
          </Modal>
        )}
        <CbdTable<Columns, TableRows>
          rows={tableData}
          isLoading={isLoading}
          paginationVisible={paginationVisible}
          {...table}
          pagination={pagination}
        />
      </View>
    </View>
  );
};

export default CbdView;
