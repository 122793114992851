import React from 'react';
import FileUploader from 'components/FileUploader/FileUploader';
import { useAppTranslations } from 'translations';
import { FileType } from '@ppm/ppm-platform-file-service/build/enums';
import { useActionsFileForm } from 'hook/useActionsFileForm';
import { useTemplateFilesForm } from 'components/TemplateUploader/hooks';
import { useDownloadFile } from 'hook/useDownloadFile';
import { ExistingGrantsType } from 'common/types';
import { isProd, isSupplierEnvironment } from 'common/consts';
import { gtmUploadTemplate } from 'components/TemplateUploader/gtm';

type TemplateUploaderProps = {
  id: string;
  isDisabled?: boolean;
  grants: ExistingGrantsType;
  className: string;
};

export const TemplateUploader = ({ id, isDisabled, grants, className }: TemplateUploaderProps) => {
  const translations = useAppTranslations();
  const { templateFiles } = useTemplateFilesForm();
  const { handleUploadFiles, handleDeleteFiles } = useActionsFileForm(
    id,
    templateFiles,
    FileType.SupplierTemplate,
    false
  );

  const { SupplierTemplateUpload, SupplierTemplateDelete, SupplierTemplateDownload } = grants;
  const handelDownloadFile = useDownloadFile(id);

  if (!(id && SupplierTemplateUpload)) return null;

  return (
    <FileUploader
      id="templateFiles"
      files={templateFiles.value}
      onChange={handleUploadFiles}
      onDeleteFile={SupplierTemplateDelete ? handleDeleteFiles : undefined}
      onDownloadFile={SupplierTemplateDownload ? handelDownloadFile : undefined}
      className={className}
      isDisabled={isDisabled}
      onClick={
        isSupplierEnvironment && isProd
          ? () => {
              gtmUploadTemplate(translations, id);
            }
          : undefined
      }
    >
      {translations('uploadTemplate')}
    </FileUploader>
  );
};
