import { useCallback, useEffect } from 'react';
import { IDataTableColumn, SortDirection } from 'common/types';
import { createColumns, getHasGrants } from 'common/utils';
import { setUploadsSortable, setVisibleUploadsColumns } from 'store/cbd/uploads/columns';
import { useAppDispatch } from 'store/hooks';
import { useUserData } from 'store/auth/user';
import { useAppTranslations } from 'translations';
import { useForm } from 'react-hook-form';
import { IUploadsColumnSort, IUploadTable } from '../Uploads.types';
import { UploadsColumns } from './consts';
import { useGetColumnTemplate } from './helpers';

type UploadsSortConfig = { [key in UploadsColumns]: IUploadsColumnSort | null };

export const sortConfig: UploadsSortConfig = {
  [UploadsColumns.NAV_CBD_INFO]: null,
  [UploadsColumns.NAV_CBD_ANALYSIS]: null,
  [UploadsColumns.CBD_ID]: 'cbd.id',
  [UploadsColumns.FILE_LINK]: 'load.fileName',
  [UploadsColumns.GROUP]: 'cbd.group',
  [UploadsColumns.STATUS]: 'cbd.status',
  [UploadsColumns.IS_LOADED]: null,
  [UploadsColumns.ITEM_GROUP]: null,
  [UploadsColumns.CREATED_AT]: null,
  [UploadsColumns.DATE_CBD]: null,
};

export const useGetColumns = (): {
  columns: Record<UploadsColumns, IDataTableColumn<IUploadTable>>;
} => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const handleSort = (field: IUploadsColumnSort, sortDirection: SortDirection) => {
    dispatch(setUploadsSortable({ field, sortDirection }));
  };

  const columnTemplate = useGetColumnTemplate();

  return {
    columns: createColumns<UploadsColumns, IUploadTable, IUploadsColumnSort>({
      columnTemplate,
      translations,
      handleSort,
      sortConfig,
    }),
  };
};

export const useColumnsForm = () => {
  const dispatch = useAppDispatch();
  const { userGrants } = useUserData().user;
  const { CBDAnalyse } = getHasGrants(userGrants);

  const form = useForm({
    defaultValues: {
      navCbdInfo: true,
      navCbdAnalysis: !!CBDAnalyse,
      itemGroup: true,
      cbdId: true,
      createdAt: true,
      dateCbd: true,
      fileLink: true,
      group: true,
      status: true,
      isLoaded: true,
    },
  });

  const saveColumnsFilter = useCallback(() => {
    form.handleSubmit((data) => {
      dispatch(setVisibleUploadsColumns(data));
    })();
  }, [dispatch, form]);

  const resetColumnsFilters = () => {
    form.reset();
    dispatch(setVisibleUploadsColumns(form.getValues()));
  };

  useEffect(() => {
    if (!CBDAnalyse) {
      saveColumnsFilter();
    }
  }, [dispatch, saveColumnsFilter, CBDAnalyse]);

  return { form, saveColumnsFilter, resetColumnsFilters };
};
