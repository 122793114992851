import React from 'react';
import { DataTable, DataTableEmptyView, Flex, Text, View } from '@mozaic-ds/react';
import { useAppTranslations } from 'translations';
import classNames from 'classnames';
import { useCbdAnalysisInfoInfoData, cbdTypeValues } from 'store/cbd/cbdAnalysis/cbdAnalysisInfo';
import ComponentsCostFilters from './filters/ComponentsCostFilters/ComponentsCostFilters';
import styles from './Comparison.module.scss';
import useTableData, { IComparisonTableData } from './hooks/useTableData';
import PurchasePriceFilters from './filters/PurchasePriceFilters/PurchasePriceFilters';
import {
  commonChartLabels,
  commonCurrencyChartLabels,
  expensesChartLabels,
  priceChartLabels,
  currencyChartLabels,
} from './hooks/consts';
import BarChart from './BarChart';
import LineChart from './LineChart';
import useSwitchingChartData from './hooks/useSwitchingChartData';
import usePurchasePriceChartData from './hooks/usePurchasePriceChartData';
import useComponentsCostChartConfig from './hooks/useComponentsCostChartConfig';
import useCommonCurrencyChartConfig from './hooks/useCommonCurrencyChartConfig';

const Comparison = () => {
  const translations = useAppTranslations();
  const {
    chartConfig: commonChartConfig,
    viewType: commonChartViewType,
    setViewType: setCommonChartViewType,
  } = useSwitchingChartData(commonChartLabels, 'groups');
  const {
    chartConfig: currencyChartConfig,
    viewType: currencyChartViewType,
    setViewType: setCurrencyChartViewType,
  } = useSwitchingChartData(currencyChartLabels, 'currency');
  const {
    chartConfig: expensesChartConfig,
    viewType: expensesChartViewType,
    setViewType: setExpensesChartViewType,
  } = useSwitchingChartData(expensesChartLabels, 'expenses');
  const { purchasePriceChartConfig } = usePurchasePriceChartData();
  const { componentsCostChartConfig } = useComponentsCostChartConfig();
  const { commonCurrencyChartConfig } = useCommonCurrencyChartConfig();
  const { cbdType } = useCbdAnalysisInfoInfoData();
  const { columns, rows, renderCustomRow, selectCustomRow } = useTableData();

  return (
    <View className={styles.comparison}>
      <Flex marginBottom="mu150">
        <Text weight="semi-bold">{translations('comparison')}</Text>
      </Flex>
      <BarChart
        title={translations('sharesMainCostGroups')}
        chartViewType={commonChartViewType}
        chartLabels={commonChartLabels}
        chartConfig={commonChartConfig}
        radioGroupName="commonChartViewType"
        onRadioChange={setCommonChartViewType}
      />
      <Flex marginBottom="mu150">
        <BarChart
          className="mu-mr-150"
          title={translations('sharesOfCurrencies')}
          chartViewType={currencyChartViewType}
          chartLabels={commonCurrencyChartLabels}
          chartConfig={currencyChartConfig}
          radioGroupName="currencyChartViewType"
          onRadioChange={setCurrencyChartViewType}
        />
        <BarChart
          title={translations('sharesOfVariableCosts')}
          chartViewType={expensesChartViewType}
          chartLabels={expensesChartLabels}
          chartConfig={expensesChartConfig}
          radioGroupName="expensesChartViewType"
          onRadioChange={setExpensesChartViewType}
        />
      </Flex>
      {cbdType === cbdTypeValues[1] ? (
        <>
          <Flex marginBottom="mu150">
            <LineChart
              className="mu-mr-150"
              chartConfig={purchasePriceChartConfig}
              chartLabels={priceChartLabels}
              title={translations('purchasePrice')}
            >
              <PurchasePriceFilters />
            </LineChart>
            <LineChart
              chartConfig={componentsCostChartConfig}
              chartLabels={priceChartLabels}
              title={translations('componentCost')}
            >
              <ComponentsCostFilters />
            </LineChart>
          </Flex>
          <Flex marginBottom="mu150">
            <LineChart
              chartConfig={commonCurrencyChartConfig}
              chartLabels={commonCurrencyChartLabels}
              title={translations('exchangeRates')}
            />
            <Flex className={styles.empty} padding="mu100" />
          </Flex>
        </>
      ) : (
        <Flex
          marginBottom="mu150"
          className={classNames(styles.chart, styles.table)}
          direction="column"
          padding="mu100"
        >
          <Text weight="semi-bold" className="mu-mb-100">
            {translations('comparisonByCostGroup')}
          </Text>
          <DataTable<IComparisonTableData>
            className={styles.table}
            withStickyHeader
            columns={columns}
            rows={rows}
            getRowKey={(row) => row.id}
            renderCustomRow={renderCustomRow}
            selectCustomRow={selectCustomRow}
          >
            {rows.length === 0 && (
              <DataTableEmptyView className="mu-p-300">
                <Text size="l" theme="light">
                  {translations('noData')}
                </Text>
              </DataTableEmptyView>
            )}
          </DataTable>
        </Flex>
      )}
    </View>
  );
};

export default Comparison;
