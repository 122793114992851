import { combineReducers } from '@reduxjs/toolkit';
import { compareCommonSlice } from './common';
import { componentsCost } from './componentsCost';
import { compareCostGroupsSlice } from './costGroups';
import { purchasePrice } from './purchasePrice';

export const compare = combineReducers({
  common: compareCommonSlice.reducer,
  componentsCost,
  purchasePrice,
  costGroups: compareCostGroupsSlice.reducer,
});
