import React from 'react';
import { Flex, Text, TextInput, Button, Tooltip } from '@mozaic-ds/react';
import { Label } from 'components/Label/Label';
import { useAppTranslations } from 'translations';
import styles from '../../EditTemplateForm.module.scss';
import useEditTemplateActions from './hooks/useActions';
import useEditTemplateForm from './hooks/useEditTemplateForm';

const AdditionPanel = () => {
  const translations = useAppTranslations();
  const { form, row, column } = useEditTemplateForm();
  const { onClear, onAddStructureSubmit } = useEditTemplateActions(form);

  return (
    <>
      <Flex marginBottom="mu200" marginTop="mu200">
        <Text weight="semi-bold">{translations('structuralChangeActions')}:</Text>
      </Flex>
      <Flex marginBottom="mu200" className={styles.fields}>
        <Tooltip
          label={`1. ${translations('costCalculation')}; 1`}
          placement="top"
          className="mu-mr-200"
        >
          <Label text={translations('insertLineAfter')}>
            <TextInput
              {...row}
              size="m"
              placeholder={`${translations('sheet')}; ${translations('string')}`}
            />
          </Label>
        </Tooltip>
        <Tooltip
          label={`1. ${translations('costCalculation')}; B`}
          placement="top"
          className="mu-mr-200"
        >
          <Label text={translations('insertColumnAfter')}>
            <TextInput
              {...column}
              size="m"
              placeholder={`${translations('sheet')}; ${translations('column')}`}
            />
          </Label>
        </Tooltip>
      </Flex>
      <Flex justifyContent="flex-end" marginTop="mu150">
        <Button className="mu-mr-100" onClick={onAddStructureSubmit} theme="primary">
          {translations('apply')}
        </Button>
        <Button onClick={onClear} variant="bordered" theme="neutral">
          {translations('cancel')}
        </Button>
      </Flex>
    </>
  );
};

export default AdditionPanel;
