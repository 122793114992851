import * as React from 'react';
import { useAppDispatch } from 'store/hooks';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import {
  getPricelistItemsCountData,
  getPricelistItemsData,
  usePriceListItemsData,
} from 'store/priceList/priceListItems';
import { PriceListTable } from './PriceListTable';

export const PriceListTableContainer = () => {
  const dispatch = useAppDispatch();
  const { requestId, priceListId } = useParams();
  const { limit, page } = usePriceListItemsData().data;

  useEffect(() => {
    if (priceListId && requestId) {
      dispatch(getPricelistItemsCountData({ requestId, priceListId }));
    }
  }, [dispatch, priceListId, requestId]);

  useEffect(() => {
    if (priceListId && requestId) {
      dispatch(getPricelistItemsData({ requestId, priceListId, query: { limit, page } }));
    }
  }, [dispatch, limit, page, priceListId, requestId]);

  return <PriceListTable />;
};
