import useUploadCbdFilesFormActions from 'pages/cbd/hok/hooks/useUploadCbdFilesFormActions';
import { useController, useForm } from 'react-hook-form';
import { IUploadTemplateCbdFormFields } from '../types';

const useTemplateCbdForm = (update?: () => void) => {
  const { control } = useForm<IUploadTemplateCbdFormFields>({
    criteriaMode: 'all',
    defaultValues: {
      templateCbdFiles: [],
    },
  });

  const { field: templateCbdFiles } = useController({ name: 'templateCbdFiles', control });

  const { templateCbdUploadFile, onDelete, handleChange, handleDelete } =
    useUploadCbdFilesFormActions({
      controller: templateCbdFiles,
      update,
    });

  return { templateCbdFiles, templateCbdUploadFile, onDelete, handleChange, handleDelete };
};

export default useTemplateCbdForm;
