import { TBadgeTheme } from '@mozaic-ds/react';
import { RequestStatusCode } from '@ppm/ppm-platform-request-service';
import { es, ru, de, enUS, Locale } from 'date-fns/locale';
import { GrantsKey, SelectItem } from './types';
import { CbdStatus, IPageable, NomenclatureState } from './cbd/types';

export const statusColorConfig: { [key in CbdStatus]: TBadgeTheme } = {
  [CbdStatus.Approved]: 'success',
  [CbdStatus.Success]: 'success',
  [CbdStatus.NotSeen]: 'neutral',
  [CbdStatus.Loading]: 'neutral',
  [CbdStatus.Declined]: 'warning',
  [CbdStatus.Error]: 'danger',
};

export const initialNomenclature: NomenclatureState = {
  dep: '',
  subDep: '',
  classData: '',
  subClass: '',
  item: '',
};

export const paginationSizeOptions: SelectItem[] = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

export const initPageable: IPageable = {
  page: {
    elements: 0,
    number: 0,
  },
  sort: {
    by: 'DESC',
    direction: 'id',
    sorted: true,
  },
  total: {
    elements: 0,
    pages: 1,
  },
};

export const isLmEnvironment = process.env.REACT_APP_ID === 'lm-front';
export const isSupplierEnvironment = process.env.REACT_APP_ID === 'supplier-front';

export const isProd = process.env.NODE_ENV === 'production';

export const DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss XXXXX';

export const existedLocale: Record<string, Locale> = {
  'es-ES': es,
  ru,
  'de-DE': de,
  en: enUS,
};

export const technicalRequestStatuses = [
  RequestStatusCode.InitializingRequest,
  RequestStatusCode.GroupingPurchasePrices,
  RequestStatusCode.InitializeSubrequests,
  RequestStatusCode.GroupingRecommendedRetailPrices,
  RequestStatusCode.InitializeData,
];

export const statusesOfPriceListFlow: GrantsKey[] = [
  'PricelistTransitionNewToGropingPrices',
  'PricelistTransitionXlsxFormedToGroupingPrices',
  'PricelistTransitionPdfFormedToSending',
  'PricelistTransitionErrorToPdfFormed',
  'PricelistTransitionRejectToPdfFormed',
  'PricelistTransitionXLSXFormedToNew',
  'PricelistTransitionPDFFormedToNew',
];

export const statusesOfRejection: GrantsKey[] = [
  'RequestTransitionOnLmValidationToRequestRejected',
  'RequestTransitionProposedToRequestRejected',
  'RequestTransitionRequestValidatedToPricesRejected',
  'RequestTransitionOnPurchasePriceValidationToRejected',
  'RequestTransitionPricelistFormationToRejected',
];

export const statusesOfPurchasePriceValidation: GrantsKey[] = [
  'RequestTransitionDraftToOnPurchasePriceValidation',
];

export const statusesOfValidation: GrantsKey[] = [
  'RequestTransitionProposedToOnLmValidation',
  'RequestTransitionDraftToOnLmValidation',
  'RequestTransitionOnLmValidationToRequestValidated',
  'RequestTransitionRequestValidatedToPricesValidated',
  'RequestTransitionOnSupplierValidationToOnPurchasePriceValidation',
  'RequestTransitionRequiresDetailingFromLMUserToOnSupplierValidation',
  'RequestTransitionRequiresDetailingFromSupplierToOnPurchasePriceValidation',
  'RequestTransitionDraftToOnSupplierValidation',
  'RequestTransitionOnPurchasePriceValidationToOnRecommendedRetailPriceValidation',
  'RequestTransitionOnRecommendedRetailPriceValidationToPricelistFormation',
  'RequestTransitionOnRecommendedRetailPriceValidationToOnValidationWithPurchasingDirector',
  'RequestTransitionOnValidationWithPurchasingDirectorToOnValidationWithDepartmentDirector',
  'RequestTransitionOnValidationWithDepartmentDirectorToPricelistFormation',
  'RequestTransitionPricelistFormationToValidated',
  'RequestTransitionPricelistFormationToPricelistFormed',
];

export const statusesOfExplanation: GrantsKey[] = [
  'RequestTransitionOnSupplierValidationToRequiresDetailingFromLMUser',
  'RequestTransitionOnPurchasePriceValidationToRequiresDetailingFromSupplier',
  'RequestTransitionOnRecommendedRetailPriceValidationToOnPurchasePriceValidation',
  'RequestTransitionPricelistFormationToOnPurchasePriceValidation',
  'RequestTransitionOnValidationWithDepartmentDirectorToOnValidationWithPurchasingDirector',
  'RequestTransitionOnValidationWithPurchasingDirectorToOnRecommendedRetailPriceValidation',
];
export const chartViewTypes = ['absolute', 'percent'];
export const pathsThatNeedToGoBack = ['cbd', 'uploads', 'bound', 'scheduler', 'cbd-analysis'];
