import React, { FC } from 'react';
import { Upload24 } from '@mozaic-ds/icons/react';
import styles from 'components/FileUploader/FileUploader.module.scss';

const FileUploaderLabel: FC<{ id: string; className?: string }> = ({ id, className, children }) => (
  <label htmlFor={id} className={`${styles.label} ${className} mc-fileuploader__label`}>
    <Upload24 fill="currentColor" className="mu-mr-050" />
    <span className="mc-fileuploader__label--center">{children}</span>
  </label>
);

export default FileUploaderLabel;
