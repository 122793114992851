import { CostEntity } from 'api/cbd/cbdInfo';
import { ICbdInfoData } from 'common/cbd/types';

interface IProcessItems {
  items: CostEntity[];
  level: number;
  costsByParents: Record<number, CostEntity[]>;
}

export const processItems = ({ items, level, costsByParents }: IProcessItems): ICbdInfoData[] => {
  return items.map((item: CostEntity) => {
    const childrens: CostEntity[] = costsByParents[item.costDictionary.id] || [];
    return {
      id: item.id,
      currency: item.currencyId,
      price: item.sum,
      name: item.costDictionary.name,
      level,
      attributes: item.attributes.map((attr) => ({
        id: attr.id,
        label: attr.attributeDictionary?.name || '',
        value: attr.value,
      })),
      children: processItems({ items: childrens, level: level + 1, costsByParents }),
    };
  });
};
