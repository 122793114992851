import { useController, useForm } from 'react-hook-form';

const useActions = () => {
  const form = useForm({
    defaultValues: {
      haveError: true,
      id: '',
    },
  });

  const { field: id } = useController({ name: 'id', control: form.control });
  const { field: haveError } = useController({ name: 'haveError', control: form.control });

  return {
    haveError,
    id,
  };
};

export default useActions;
