import { use } from 'i18next';
import { GetCurrentLanguageResponse } from '@ppm/ppm-platform-api';
import { initReactI18next } from 'react-i18next';
import { translationNs } from 'translations';

export default function initI18next(
  lang: string,
  appTranslations: GetCurrentLanguageResponse['data']
): void {
  use(initReactI18next).init({
    resources: { [lang]: { [translationNs]: appTranslations } },
    lng: lang,
  });
}
