import * as yup from 'yup';
import { string, object } from 'yup';
import { DueDateValidation } from '@ppm/ppm-platform-api';
import { isAfter, isBefore, addDays } from 'date-fns';

export const getSchema = (dateValidation: DueDateValidation) =>
  object({
    dueDate: string()
      .defined()
      .test(
        'dueDate',
        `Желаемая дата применения новых цен должна быть в диапазоне c ${dateValidation.min} по ${dateValidation.max}`,
        (value) => {
          return (
            isAfter(addDays(new Date(value), 1), dateValidation.min) &&
            isBefore(addDays(new Date(value), -1), dateValidation.max)
          );
        }
      )
      .required('Обязательно для заполнения'),
    comment: yup.string(),
  }).required();
