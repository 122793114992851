import React, { useEffect } from 'react';
import { Button, Select, TextInput, View } from '@mozaic-ds/react';
import { DisplayFilter24 } from '@mozaic-ds/icons/react';
import Flatpickr from 'react-flatpickr';
import Layer from 'components/Layer/Layer';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import { useAppTranslations } from 'translations';
import { useFiltersData } from 'store/cbd/allUploads/filters';
import { filtersName, statusOptions } from 'pages/cbd/AllUploads/hooks/consts';
import FieldWrapper from 'components/FieldWrapper/FieldWrapper';
import classNames from 'classnames';
import { useAppDispatch } from 'store/hooks';
import { setSearchValue, useSupplierIdsData, getSupplierIds } from 'store/dictionary/supplierIds';
import useCostTypesOptions from 'pages/cbd/hooks/useCostTypesOptions';
import { getRequestStatusesList, useRequestStatuses } from 'store/cbd/requestStatuses';
import useNomenclatureOptions from 'pages/cbd/hooks/useNomenclatureOptions';
import { useFiltersForm } from '../../../AllUploads/hooks/filters';
import styles from '../../CbdView.module.scss';

const FiltersPopup = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();
  const { list } = useRequestStatuses();

  const { supplierIds, searchValue } = useSupplierIdsData().data;

  const { deletedFilters } = useFiltersData().data;
  const {
    form,
    cbdSupplierId,
    cbdDate,
    cbdId,
    loadFileName,
    cbdCreatedAt,
    itemGroup,
    itemDep,
    itemClass,
    itemSubclass,
    attributeDep,
    attributeSubDep,
    attributeGroup,
    cbdStatus,
    requestStatusId,
    onSubmit,
  } = useFiltersForm();

  const itemGroupOptionsWatcher = form.watch(['itemGroup', 'itemDep', 'itemClass']);
  const { groupsOptions, depOptions, classDataOptions, subClassOptions } =
    useNomenclatureOptions(itemGroupOptionsWatcher);
  const { categoryCostTypesOptions, getTypeCostTypesOptions, getSubtypeCostTypesOptions } =
    useCostTypesOptions();

  form.watch((_data, { name, type }) => {
    if (type === 'change') {
      switch (name) {
        case 'itemGroup': {
          form.setValue('itemDep', '');
          form.setValue('itemClass', '');
          form.setValue('itemSubclass', '');
          break;
        }
        case 'itemDep': {
          form.setValue('itemClass', '');
          form.setValue('itemSubclass', '');
          break;
        }
        case 'itemClass': {
          form.setValue('itemSubclass', '');
          break;
        }
        case 'attributeDep': {
          form.setValue('attributeSubDep', '');
          form.setValue('attributeGroup', '');
          break;
        }
        case 'attributeSubDep': {
          form.setValue('attributeGroup', '');
          break;
        }
        default: {
          break;
        }
      }
    }
  });

  useEffect(() => {
    dispatch(getRequestStatusesList());
    dispatch(getSupplierIds({ searchString: searchValue }));
  }, [searchValue, dispatch]);

  useEffect(() => {
    deletedFilters.forEach((id) => form.setValue(id, ''));
  }, [deletedFilters, form]);

  return (
    <Layer
      id="allUploadsFiltersPopup"
      icon={<DisplayFilter24 fill="#71706b" />}
      title={translations('filterPopupHeader')}
      resetItems={() => {
        form.reset();
        filtersName.forEach((id) => form.setValue(id, ''));
      }}
      saveItems={onSubmit}
      button={
        <Button leftIcon={<DisplayFilter24 />} variant="bordered" size="s">
          {translations('filters')}
        </Button>
      }
    >
      <View as="form" paddingLeft="mu025" paddingRight="mu025">
        <FieldWrapper label={translations('supplier')} id="supplierId">
          <Autocomplete
            {...cbdSupplierId}
            emptyMessageText={translations('emptySupplierLinks')}
            placeholder={translations('chooseAnOption')}
            listBoxItems={supplierIds}
            handleSearch={setSearchValue}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('dateCbd')} id="cbdDate">
          <Flatpickr
            {...cbdDate}
            className="mc-text-input"
            options={{ mode: 'range', dateFormat: 'm/d/Y', locale: { firstDayOfWeek: 1 } }}
            placeholder={translations('chooseAnOption')}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('cbdNumber')} id="cbdId">
          <TextInput {...cbdId} size="m" placeholder={translations('input')} />
        </FieldWrapper>
        <FieldWrapper label={translations('fileName')} id="loadFileName">
          <TextInput {...loadFileName} size="m" placeholder={translations('input')} />
        </FieldWrapper>
        <FieldWrapper label={translations('cbdLoadDate')} id="cbdCreatedAt">
          <Flatpickr
            {...cbdCreatedAt}
            className="mc-text-input"
            options={{ mode: 'range', dateFormat: 'm/d/Y', locale: { firstDayOfWeek: 1 } }}
            placeholder={translations('chooseAnOption')}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('statusCbd')} id="reasonListId">
          <Select
            {...cbdStatus}
            size="m"
            options={statusOptions.map((item) => ({
              ...item,
              label: translations(item.label),
            }))}
            placeholder={translations('chooseAnOption')}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('requestStatus')} id="reasonListId">
          <Select
            {...requestStatusId}
            size="m"
            options={list.map((item) => ({
              value: item.id,
              label: translations(item.code),
            }))}
            placeholder={translations('chooseAnOption')}
          />
        </FieldWrapper>
        <fieldset className={classNames('mu-mt-100', 'mu-p-100', styles.fieldset)}>
          <legend>{translations('nomenclatureOfGoods')}</legend>
          <FieldWrapper label={translations('devision')} id="itemGroup">
            <Select
              {...itemGroup}
              size="m"
              options={groupsOptions}
              placeholder={translations('chooseAnOption')}
            />
          </FieldWrapper>
          <FieldWrapper label={translations('subDevision')} id="itemDep">
            <Select
              {...itemDep}
              isDisabled={!itemGroup.value}
              size="m"
              options={depOptions}
              placeholder={translations('chooseAnOption')}
            />
          </FieldWrapper>
          <FieldWrapper label={translations('type')} id="itemClass">
            <Select
              {...itemClass}
              isDisabled={!itemDep.value}
              size="m"
              options={classDataOptions}
              placeholder={translations('chooseAnOption')}
            />
          </FieldWrapper>
          <FieldWrapper label={translations('subType')} id="itemSubclass">
            <Select
              {...itemSubclass}
              isDisabled={!itemClass.value}
              size="m"
              options={subClassOptions}
              placeholder={translations('chooseAnOption')}
            />
          </FieldWrapper>
        </fieldset>
        <fieldset className={classNames('mu-mt-100', 'mu-p-100', styles.fieldset)}>
          <legend>{translations('nomenclatureOfRawMaterials')}</legend>
          <FieldWrapper label={translations('industry')} id="attributeDep">
            <Select
              {...attributeDep}
              size="m"
              options={categoryCostTypesOptions}
              placeholder={translations('chooseAnOption')}
            />
          </FieldWrapper>
          <FieldWrapper label={translations('subIndustry')} id="attributeSubDep">
            <Select
              {...attributeSubDep}
              isDisabled={!attributeDep.value}
              size="m"
              options={getTypeCostTypesOptions(attributeDep.value)}
              placeholder={translations('chooseAnOption')}
            />
          </FieldWrapper>
          <FieldWrapper label={translations('materialGroup')} id="attributeGroup">
            <Select
              {...attributeGroup}
              isDisabled={!attributeSubDep.value}
              size="m"
              options={getSubtypeCostTypesOptions(attributeDep.value, attributeSubDep.value)}
              placeholder={translations('chooseAnOption')}
            />
          </FieldWrapper>
        </fieldset>
      </View>
    </Layer>
  );
};

export default FiltersPopup;
