import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IGetCompareCommonPayload, getCostGroupsReq } from 'api/cbd/cbdAnalysis';
import { ICostGroups } from 'common/cbd/types';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

interface SliceState {
  data: ICostGroups;
}

const initialState: SliceState = {
  data: {
    headers: [],
    body: [],
  },
};

export const getCompareCostGroups = createAsyncThunk(
  'cbd/cbdAnalysis/getCompareCostGroups',
  async (payload: IGetCompareCommonPayload, { rejectWithValue }) => {
    try {
      return await getCostGroupsReq(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const compareCostGroupsSlice = createSlice({
  initialState,
  name: 'compareCostGroups',
  reducers: {
    clearCompareCostGroupsCommon: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCompareCostGroups.fulfilled,
      (state: SliceState, action: PayloadAction<ICostGroups>) => {
        state.data = action.payload;
      }
    );
  },
});

export const { clearCompareCostGroupsCommon } = compareCostGroupsSlice.actions;

export const useCompareCostGroupsData = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.cbdAnalysis.compare.costGroups);
};
