import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/types';
import { useAppSelector } from 'store/hooks';
import { AlertCurrencyColumns } from 'pages/cbd/Scheduler/partials/AlertCurrency/hook/consts';

export type AlertCurrencyColumn = {
  key: AlertCurrencyColumns;
  isShown: boolean;
};

interface SliceState {
  columns: AlertCurrencyColumn[];
}

export const columnsSlice = createSlice({
  initialState: {
    sortedField: null,
    columns: Object.values(AlertCurrencyColumns).map((item) => ({
      key: item,
      isShown: true,
    })),
  },
  name: 'columns',
  reducers: {
    setVisibleAlertCurrencyColumns(
      state: SliceState,
      action: PayloadAction<Record<AlertCurrencyColumns, boolean>>
    ) {
      state.columns = state.columns.map((column) => {
        return { ...column, isShown: action.payload[column.key] };
      });
    },
  },
});

export const { setVisibleAlertCurrencyColumns } = columnsSlice.actions;

export const useColumnsData = (): { data: SliceState } => {
  return {
    data: useAppSelector((state: RootState) => state.cbd.scheduler.currencySubscriptions.columns),
  };
};
