import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAddTemplateStructure } from 'api/cbd/templates';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

interface SliceState {
  data: IAddTemplateStructure;
}

const initialState: SliceState = {
  data: {},
};

export const additionEditTemplateConfigSlice = createSlice({
  initialState,
  name: 'additionEditTemplateConfig',
  reducers: {
    setAdditionEditTemplateConfig: (
      state: SliceState,
      action: PayloadAction<IAddTemplateStructure>
    ) => {
      state.data = action.payload;
    },
    clearAdditionEditTemplateConfig: () => initialState,
  },
});

export const { clearAdditionEditTemplateConfig, setAdditionEditTemplateConfig } =
  additionEditTemplateConfigSlice.actions;

export const useAdditionEditTemplateConfig = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.additionEditTemplateConfig);
};
