import React, { FC } from 'react';
import { Text } from '@mozaic-ds/react';
import cn from 'classnames';
import styles from './Label.module.scss';

type Props = {
  text: string;
  className?: string;
  subText?: string;
  hasMarginTop?: boolean;
};
export const Label: FC<Props> = ({ children, text, className, subText, hasMarginTop = true }) => {
  return (
    <label
      className={cn(className, 'mc-field__label', { 'mu-mt-100': hasMarginTop }, styles.label)}
    >
      {text}
      {subText && (
        <Text size="s" theme="light">
          {subText}
        </Text>
      )}
      {children}
    </label>
  );
};
