import { Translations } from 'translations';

export function gtmChooseSubrequest({
  t,
  requestId,
  subrequestId,
}: {
  t: Translations;
  requestId: string;
  subrequestId: string;
}) {
  window.dataLayer.push({
    event: 'LeroyMerlin',
    eventCategory: 'click-button', // действие-элемент
    eventAction: t('request'), // блок, в котором находится кнопка
    eventLabel: t('subRequest'), // текст на кнопке
    eventVariant: subrequestId, // в виде “2259/1”
    eventRequestID: requestId, // ID запроса
  });
}
