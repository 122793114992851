import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDeleteTemplateStructure } from 'api/cbd/templates';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

interface SliceState {
  data: IDeleteTemplateStructure;
}

const initialState: SliceState = {
  data: {},
};

export const deletionEditTemplateConfigSlice = createSlice({
  initialState,
  name: 'deletionEditTemplateConfig',
  reducers: {
    setDeletionEditTemplateConfig: (
      state: SliceState,
      action: PayloadAction<IDeleteTemplateStructure>
    ) => {
      state.data = action.payload;
    },
    clearDeletionEditTemplateConfig: () => initialState,
  },
});

export const { clearDeletionEditTemplateConfig, setDeletionEditTemplateConfig } =
  deletionEditTemplateConfigSlice.actions;

export const useDeletionEditTemplateConfig = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.deletionEditTemplateConfig);
};
