import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { BoundColumn, useColumnsData } from 'store/cbd/bound/columns';
import { getBoundList, useBoundList, setSelectedRowsId } from 'store/cbd/bound/boundList';
import {
  changeBoundDeletedFilters,
  setBoundFilters,
  useBoundFiltersData,
} from 'store/cbd/bound/filters';
import { ICbdItemGroup, IPageSize } from 'common/cbd/types';
import { IFilter } from 'common/types';
import { useMount } from 'hook/useMount';
import { getNomenclature } from 'store/cbd/nomenclature';
import CbdView, { ICbdView } from '../hok/CbdView';
import { useColumnsForm, useGetColumns, sortConfig } from './hooks/columns';
import { BoundColumns, boundFiltersName } from './hooks/consts';
import { ExtendsFilters } from '../hok/partials/consts';

interface IBound {
  hideNomenclature?: boolean;
  boundFiltersVisible?: boolean;
  customShownColumns?: Partial<Record<BoundColumns, boolean>>;
  needToWrap?: boolean;
}

const Bound: FC<IBound> = ({
  boundFiltersVisible = false,
  hideNomenclature = false,
  customShownColumns,
  needToWrap = true,
}) => {
  const dispatch = useAppDispatch();
  const { columns } = useGetColumns();
  const { columns: columnsFilter, sortedField } = useColumnsData().data;
  const { filters } = useBoundFiltersData().data;
  const { form, saveColumnsFilter, resetColumnsFilters } = useColumnsForm(customShownColumns);
  const { data, isLoading, pageable } = useBoundList();
  const { selectedRowsId } = useBoundList();
  const { cbdId } = useParams();

  useMount(() => {
    dispatch(getNomenclature());
  });

  const config: ICbdView<ICbdItemGroup, BoundColumns, BoundColumn, ICbdItemGroup> = {
    needToWrap,
    nomenclatureVisible: !hideNomenclature,
    boundFiltersVisible,
    isLoading,
    pageFilters: filters,
    onRemoveTag: (idForDelete: string[], filtersForDelete: IFilter<ExtendsFilters>[]) => {
      dispatch(changeBoundDeletedFilters(idForDelete));
      dispatch(setBoundFilters(filtersForDelete));
    },
    resetFilters: () => {
      dispatch(changeBoundDeletedFilters(boundFiltersName));
      dispatch(setBoundFilters([]));
    },
    data: {
      data,
      getData: (params: IPageSize) => {
        dispatch(getBoundList({ cbdId: cbdId || '', ...params }));
      },
      pageable,
    },
    ...(Boolean(customShownColumns) && {
      columnsPopup: {
        columns,
        columnsFilter,
        form,
        saveColumnsFilter,
        resetColumnsFilters,
      },
    }),
    table: {
      sortConfig,
      columns,
      columnsFilter,
      sortedField,
      selectedRowsId,
      setSelectedRowsId: (selected: string[]) => {
        dispatch(setSelectedRowsId(selected));
      },
    },
  };

  return <CbdView<ICbdItemGroup, BoundColumns, BoundColumn, ICbdItemGroup> {...config} />;
};

export default Bound;
