import React, { FC, useCallback } from 'react';
import { NavigateOptions, To, useNavigate, Link } from 'react-router-dom';
import cn from 'classnames';
import { ArrowBack24, ArrowArrowLeft16 } from '@mozaic-ds/icons/react';
import { Badge, Breadcrumbs, BreadcrumbsItem, Flex, Heading, LinkLeftIcon } from '@mozaic-ds/react';
import { useGetRenderComponent } from 'components/Header/hooks';
import { NotificationToggle } from './partial/NotificationToggle';
import { DeletePriceListButton } from './partial/DeletePriceListButton';
import styles from './Header.module.scss';

export type HeaderProps = {
  path: string;
  navigation?: {
    to?: To;
    delta?: number;
    options?: NavigateOptions;
  };
};

export const Header: FC<HeaderProps> = ({ path, navigation }) => {
  const navigate = useNavigate();
  const {
    redirectItems,
    hasArrowBack,
    text,
    badge,
    isShownPriseListDeleteButton = false,
  } = useGetRenderComponent(path);

  const goBack = useCallback(() => {
    const { to, options, delta } = navigation || {};

    if (delta) navigate(delta);
    else if (to) navigate(to, options);
    else navigate('/');
  }, [navigate, navigation]);

  return (
    <header className={cn(styles.header, 'mu-pt-100')}>
      {redirectItems && (
        <>
          {redirectItems.length > 1 ? (
            <Breadcrumbs className="mu-pl-300">
              {redirectItems.map(({ url, name, isCurrent }) => (
                <BreadcrumbsItem key={name} href={url} isCurrent={isCurrent}>
                  {name}
                </BreadcrumbsItem>
              ))}
            </Breadcrumbs>
          ) : (
            <Flex className="mu-pl-300">
              <Link className="mc-link" to={redirectItems[0].url as string}>
                <LinkLeftIcon>
                  <ArrowArrowLeft16 />
                </LinkLeftIcon>
                {redirectItems[0].name}
              </Link>
            </Flex>
          )}
        </>
      )}
      <Flex wrap="nowrap" className="mu-pr-300 mu-pl-300 mu-pb-100" justifyContent="space-between">
        <Flex direction="row" alignItems="center">
          {hasArrowBack && <ArrowBack24 className="mu-mr-100" onClick={goBack} />}
          <Flex direction="column">
            <Heading weight="semi-bold" size="s" className={styles.heading}>
              {text}
            </Heading>
            {badge && badge.statusTheme && badge.name && (
              <Badge theme={badge.statusTheme} className={styles.badge}>
                {badge.name}
              </Badge>
            )}
          </Flex>
        </Flex>
        {isShownPriseListDeleteButton && <DeletePriceListButton />}
        {!isShownPriseListDeleteButton && <NotificationToggle />}
      </Flex>
    </header>
  );
};
