import React, { FC, useEffect } from 'react';
import { Flex, useModals } from '@mozaic-ds/react';
import { useAppDispatch } from 'store/hooks';
import { getCurrentPricelistData, useCurrentPriceListData } from 'store/priceList/currentPriceList';
import { useMount } from 'hook/useMount';
import { getRequest } from 'store/request/request';
import { isEmpty } from 'lodash';
import { getStartDateValidation } from 'store/priceList/validation';
import { ModalRate } from 'components/ModalRate';
import { PRICELIST_MODAL_RATE_POPUP_OPENED_ACTIONS } from 'components/SocketContainer/consts';
import { QuizSocketData, setEntityId, setModalRateDate } from 'store/modalRate/modalRate';
import { modalRatePopupId } from 'components/ModalRate/consts';
import { useSocketContext } from 'components/SocketContainer/SocketContext';
import { EEntityName } from '@ppm/ppm-platform-feedback-service';
import { Settings } from './partials/PriceListSettings/Settings';
import { Structure } from './partials/Structure';
import { Files } from './partials/Files';
import { PriceListTableContainer } from './partials/Items/PriceListTableContainer';

type PriceListProps = {
  requestId: string;
  priceListId: string;
};

const PriceList: FC<PriceListProps> = ({ requestId, priceListId }) => {
  const dispatch = useAppDispatch();

  const { open } = useModals();
  const { socket } = useSocketContext();

  const { priceList } = useCurrentPriceListData().data;

  useMount(() => {
    dispatch(getRequest(requestId));
    dispatch(getStartDateValidation({ id: requestId, query: { pricelistId: priceListId } }));
    dispatch(getCurrentPricelistData({ requestId, priceListId }));
  });

  useEffect(() => {
    socket.on(PRICELIST_MODAL_RATE_POPUP_OPENED_ACTIONS, (data: QuizSocketData) => {
      dispatch(setEntityId(priceListId));
      dispatch(setModalRateDate(data));
      open(modalRatePopupId);
    });

    return () => {
      socket.off(PRICELIST_MODAL_RATE_POPUP_OPENED_ACTIONS);
    };
  }, [dispatch, open, priceListId, socket]);

  return (
    <Flex direction="column" marginTop="mu250">
      <Flex justifyContent="space-between">
        <Structure />
        {!isEmpty(priceList) && <Settings />}
      </Flex>
      <Files />
      <PriceListTableContainer />
      {requestId && <ModalRate requestId={requestId} entityName={EEntityName.Pricelist} />}
    </Flex>
  );
};

export default PriceList;
