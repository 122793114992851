import { useController, useForm } from 'react-hook-form';

export interface IAdditionalTemplateForm {
  row: string;
  column: string;
  name: string;
  format: string;
  range: string;
}

const useEditTemplateForm = () => {
  const form = useForm({
    defaultValues: {
      row: '',
      column: '',
      name: '',
      format: '',
      range: '',
    },
  });

  const { control } = form;

  const { field: row } = useController({ name: 'row', control });
  const { field: column } = useController({ name: 'column', control });
  const { field: name } = useController({ name: 'name', control });
  const { field: format } = useController({ name: 'format', control });
  const { field: range } = useController({ name: 'range', control });

  return {
    form,
    row,
    column,
    name,
    format,
    range,
  };
};

export default useEditTemplateForm;
