import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialNomenclature } from 'common/consts';
import { IClass, IDepartment, IGroup, ISubClass } from 'common/cbd/types';
import { StringSelectItem } from 'common/types';
import { formNomenclatureOptions, setLoading } from 'store/helpers';
import { useAppSelector } from 'store/hooks';
import { RequiredStateFields, RootState } from 'store/types';
import { getGroups, getDepartment, getClass, getSubClass } from 'api/cbd/selects';

interface INomenclatureMap {
  groups: IGroup[];
  dep: IDepartment[];
  classData: IClass[];
  subClass: ISubClass[];
}

interface INomenclatureOptions {
  subDepOptions: StringSelectItem[];
  classDataOptions: StringSelectItem[];
  subClassOptions: StringSelectItem[];
}

interface SliceState extends RequiredStateFields {
  data: INomenclatureMap;
  nomenclatureOptions: INomenclatureOptions;
}

export interface SetNomenclatureOptionsPayload {
  groupId: string;
  departmentId?: string;
  classId?: string;
}

const initialState = {
  selectedNomenclature: initialNomenclature,
  nomenclatureOptions: {
    subDepOptions: [],
    classDataOptions: [],
    subClassOptions: [],
  },
  data: {
    groups: [],
    dep: [],
    classData: [],
    subClass: [],
  },
  isLoading: false,
  isFailure: false,
};

export const getNomenclature = createAsyncThunk(
  'nomenclature/getNomenclature',
  async (_, { rejectWithValue }) => {
    try {
      const groups = await getGroups();
      const dep = await getDepartment();
      const classData = await getClass();
      const subClass = await getSubClass();
      return {
        groups: groups.data,
        dep: dep.data,
        classData: classData.data,
        subClass: subClass.data,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const nomenclatureSlice = createSlice({
  initialState,
  name: 'nomenclature',
  reducers: {
    setSubDepOptions: (state: SliceState, action: PayloadAction<SetNomenclatureOptionsPayload>) => {
      state.nomenclatureOptions.subDepOptions = formNomenclatureOptions<IDepartment>(
        state.data.dep,
        action.payload
      );
    },
    setClassDataOptions: (
      state: SliceState,
      action: PayloadAction<SetNomenclatureOptionsPayload>
    ) => {
      state.nomenclatureOptions.classDataOptions = formNomenclatureOptions<IClass>(
        state.data.classData,
        action.payload
      );
    },
    setSubClassOptions: (
      state: SliceState,
      action: PayloadAction<SetNomenclatureOptionsPayload>
    ) => {
      state.nomenclatureOptions.subClassOptions = formNomenclatureOptions<ISubClass>(
        state.data.subClass,
        action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNomenclature.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getNomenclature.fulfilled,
        (state: SliceState, action: PayloadAction<INomenclatureMap>) => {
          state.data.groups = action.payload.groups.map((item) => ({
            ...item,
            id: item.id.toString(),
          }));
          state.data.dep = action.payload.dep.map((item) => ({
            ...item,
            id: item.id.toString(),
            groupId: item.groupId.toString(),
          }));
          state.data.classData = action.payload.classData.map((item) => ({
            ...item,
            id: item.id.toString(),
            groupId: item.groupId.toString(),
            departmentId: item.departmentId.toString(),
          }));
          state.data.subClass = action.payload.subClass.map((item) => ({
            ...item,
            id: item.id.toString(),
            groupId: item.groupId.toString(),
            departmentId: item.departmentId.toString(),
            classId: item.classId.toString(),
          }));
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getNomenclature.rejected, (state: SliceState) => {
        setLoading(state);
      });
  },
});

export const { setSubDepOptions, setClassDataOptions, setSubClassOptions } =
  nomenclatureSlice.actions;

export const useNomenclature = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.nomenclature);
};
