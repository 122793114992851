import { useController, useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { Translations, useAppTranslations } from 'translations';
import {
  clearComponentsCostDeletedFilters,
  setComponentsCostFilters,
} from 'store/cbd/cbdAnalysis/compare/componentsCost/filters';

export type ComponentsCostFilterName = 'categoryId' | 'typeId' | 'subtypeId';

const getLabel = (key: ComponentsCostFilterName, t: Translations) => {
  return {
    categoryId: t('categoryId'),
    typeId: t('typeId'),
    subtypeId: t('subtypeId'),
  }[key];
};

export const componentsCostFiltersName: ComponentsCostFilterName[] = [
  'categoryId',
  'typeId',
  'subtypeId',
];

export const useComponentsCostFiltersForm = () => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const form = useForm({
    defaultValues: {
      categoryId: '',
      typeId: '',
      subtypeId: '',
    },
  });

  const { control, handleSubmit } = form;

  const { field: categoryId } = useController({ name: 'categoryId', control });
  const { field: typeId } = useController({ name: 'typeId', control });
  const { field: subtypeId } = useController({ name: 'subtypeId', control });

  const onSubmit = useCallback(() => {
    handleSubmit((data) => {
      const keys = Object.keys(data);
      dispatch(clearComponentsCostDeletedFilters());
      dispatch(
        setComponentsCostFilters(
          keys
            .map((key) => ({
              id: key,
              value: data[key as ComponentsCostFilterName],
              label: getLabel(key as ComponentsCostFilterName, translations),
            }))
            .filter(({ value }) => (Array.isArray(value) ? value.length > 0 : Boolean(value)))
        )
      );
    })();
  }, [dispatch, handleSubmit, translations]);

  return {
    form,
    categoryId,
    typeId,
    subtypeId,
    onSubmit,
  };
};
