import { combineReducers } from '@reduxjs/toolkit';
import { listSlice } from './list';
import { filtersSlice } from './filters';
import { columnsSlice } from './columns';

export const allUploads = combineReducers({
  list: listSlice.reducer,
  filters: filtersSlice.reducer,
  columns: columnsSlice.reducer,
});
