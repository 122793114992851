import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISupplier } from 'common/cbd/types';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';
import { getSuppliers } from 'api/cbd/selects';

interface SliceState {
  suppliers: ISupplier[];
}

const initialState = {
  suppliers: [],
};

export const getSuppliersData = createAsyncThunk(
  'suppliers/getSuppliersData',
  async (action: number | number[], { rejectWithValue }) => {
    try {
      const { data } = await getSuppliers(action);

      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const suppliersSlice = createSlice({
  initialState,
  name: 'suppliers',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSuppliersData.rejected, (state: SliceState) => {
        state.suppliers = [];
      })
      .addCase(
        getSuppliersData.fulfilled,
        (state: SliceState, action: PayloadAction<ISupplier[]>) => {
          state.suppliers = action.payload;
        }
      );
  },
});

export const useSuppliers = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.suppliers);
};
