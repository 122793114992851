import React, { useCallback, FC } from 'react';
import { DataTable, ExpandableDataTableRow } from '@mozaic-ds/react';
import { ICbdInfoData, IExpandedInfo } from 'common/cbd/types';
import useTableData from './hooks/useTableData';

const CbdInfoDataTable: FC<IExpandedInfo> = ({ cost }) => {
  const { treeData, columns, selectCustomRow, expandedRowIds } = useTableData({ cost });

  const getRowKey = useCallback((row: ICbdInfoData): string => row.id, []);
  const renderCustomRow = (row: ICbdInfoData): JSX.Element => {
    return (
      <ExpandableDataTableRow<ICbdInfoData>
        key={getRowKey(row)}
        row={row}
        getRowKey={getRowKey}
        columns={columns}
        isExpanded={expandedRowIds.includes(row.id)}
      >
        <DataTable
          isSubTable
          isHeaderHidden
          rows={row.children}
          columns={columns}
          getRowKey={(subRow) => subRow.id}
          selectCustomRow={selectCustomRow}
          renderCustomRow={renderCustomRow}
        />
      </ExpandableDataTableRow>
    );
  };

  return (
    <DataTable<ICbdInfoData>
      isHeaderHidden
      columns={columns}
      rows={treeData}
      getRowKey={getRowKey}
      selectCustomRow={selectCustomRow}
      renderCustomRow={renderCustomRow}
    />
  );
};

export default CbdInfoDataTable;
