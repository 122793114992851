import React from 'react';
import { Badge, Button, Flex, Link } from '@mozaic-ds/react';
import { statusColorConfig } from 'common/consts';
import { CbdStatus, IRenderButton } from 'common/cbd/types';
import { useNavigate } from 'react-router-dom';
import { getLocale, getHasGrants, IColumnTemplate, parseDate, downloadFile } from 'common/utils';
import styles from 'pages/cbd/hok/CbdView.module.scss';
import { useUserData } from 'store/auth/user';
import { useAppTranslations } from 'translations';
import { ICbdTable } from '../AllUploads.types';
import { AllUploadsColumns } from './consts';

type TUseGetColumnTemplate = {
  [key in AllUploadsColumns]: IColumnTemplate<ICbdTable>;
};

export const useGetColumnTemplate = (): TUseGetColumnTemplate => {
  const navigate = useNavigate();
  const { lang, userGrants } = useUserData().user;
  const { CBDAnalyse } = getHasGrants(userGrants);
  const translations = useAppTranslations();

  const renderStatusColumn = (row: ICbdTable) => {
    const status = row.isLoaded ? row.status : CbdStatus.Error;

    return <Badge theme={statusColorConfig[status]}>{translations(status)}</Badge>;
  };

  const renderFileLink = (row: ICbdTable) => {
    return (
      <Flex alignItems="center" justifyContent="center" className={styles.fileNameCell}>
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <Link
          href="#"
          onClick={(e) => {
            e.preventDefault();
            downloadFile(row.requestId.toString(), row.fileId);
          }}
        >
          {row.fileName}
        </Link>
        {/* eslint-enable jsx-a11y/anchor-is-valid */}
      </Flex>
    );
  };

  const renderButton = ({
    row,
    buttonText,
    buttonNavigate,
    theme,
    isDisabled = false,
    className = '',
  }: IRenderButton<ICbdTable>) => {
    return (
      <Button
        onClick={(e) => {
          e.stopPropagation();
          buttonNavigate(row);
        }}
        size="s"
        theme={theme || 'primary'}
        isDisabled={isDisabled}
        variant="bordered"
        className={className}
      >
        {buttonText}
      </Button>
    );
  };

  const columnTemplate: TUseGetColumnTemplate = {
    [AllUploadsColumns.REQUEST_ID]: {
      message: 'requestNumber',
      isSortable: true,
      render: (record: ICbdTable) => record.requestId,
    },
    [AllUploadsColumns.NAV_CBD_INFO]: {
      message: 'cbdInfo',
      isSortable: false,
      render: (row: ICbdTable) => {
        const [cbdId] = row.id.split('-');

        return row.isLoaded
          ? renderButton({
              row,
              buttonText: translations('view'),
              buttonNavigate: () => navigate(`/cbd/${cbdId}`),
            })
          : null;
      },
    },
    [AllUploadsColumns.NAV_CBD_ANALYSIS]: {
      message: 'cbdAnalysis',
      isSortable: false,
      render: (row: ICbdTable) =>
        row.isLoaded
          ? renderButton({
              row,
              buttonText: translations('analysis'),
              isDisabled: !CBDAnalyse,
              buttonNavigate: () => {
                const [id] = row.id.split('-');
                navigate(`/cbd-analysis/${id}`);
              },
            })
          : null,
    },
    [AllUploadsColumns.SUPPLIER_ID]: {
      message: 'supplier',
      isSortable: true,
      render: (record: ICbdTable) => record.supplierId,
    },
    [AllUploadsColumns.ID]: {
      message: 'cbdNumber',
      isSortable: true,
      render: (record: ICbdTable) => record.id,
    },
    [AllUploadsColumns.FILE_LINK]: {
      message: 'fileName',
      isSortable: true,
      render: (record: ICbdTable) => renderFileLink(record),
    },
    [AllUploadsColumns.STATUS]: {
      message: 'statusCbd',
      isSortable: true,
      render: (record: ICbdTable) => renderStatusColumn(record),
    },
    [AllUploadsColumns.REQUEST_STATUS]: {
      message: 'requestStatus',
      isSortable: true,
      render: (record: ICbdTable) => translations(record.requestStatus),
    },
    [AllUploadsColumns.GROUP]: {
      message: 'supplierProductGroup',
      isSortable: true,
      render: (record: ICbdTable) => record.group,
    },
    [AllUploadsColumns.GROUPS_NAME]: {
      message: 'devision',
      isSortable: true,
      render: (record: ICbdTable) => (record.groups ? `${record.groups}-${record.groupName}` : ''),
    },
    [AllUploadsColumns.DEP_NAME]: {
      message: 'subDevision',
      isSortable: true,
      render: (record: ICbdTable) => (record.dep ? `${record.dep}-${record.depName}` : ''),
    },
    [AllUploadsColumns.CLASS_NAME]: {
      message: 'type',
      isSortable: true,
      render: (record: ICbdTable) => (record.class ? `${record.class}-${record.className}` : ''),
    },
    [AllUploadsColumns.SUB_CLASS_NAME]: {
      message: 'subType',
      isSortable: true,
      render: (record: ICbdTable) =>
        record.subclass ? `${record.subclass}-${record.subclassName}` : '',
    },
    [AllUploadsColumns.ITEM]: {
      message: 'lmCode',
      isSortable: true,
      render: (record: ICbdTable) => record.item,
    },
    [AllUploadsColumns.CREATED_AT]: {
      message: 'cbdLoadDate',
      isSortable: true,
      render: (record: ICbdTable) => parseDate(new Date(record.createdAt), getLocale(lang)),
    },
    [AllUploadsColumns.DATE_CBD]: {
      message: 'dateCbd',
      isSortable: true,
      render: (record: ICbdTable) => parseDate(new Date(record.dateCbd), getLocale(lang)),
    },
  };

  return columnTemplate;
};
