import { NameSpaceBaseImplementation } from 'api/utility/types';

export const getUrl = <U>(url: NameSpaceBaseImplementation<U>['url'], urlParameters?: U) => {
  if (typeof url === 'string') {
    return url;
  }
  if (typeof url === 'function' && urlParameters instanceof Array) {
    return url(...urlParameters);
  }
  return '';
};
