import React, { useCallback } from 'react';
import { Badge, Flex, Loader, Text, View, Link as MozaicLink } from '@mozaic-ds/react';
import { Link } from 'react-router-dom';
import { usePriceListData } from 'store/priceList/priceList';
import { Document24, Pdf24, PublishTrashbin24 } from '@mozaic-ds/icons/react';
import { downloadFile, getPriceListStatusBadgeTheme, getHasGrants } from 'common/utils';
import { useAppTranslations } from 'translations';
import cn from 'classnames';
import { SubrequestNumberElement } from 'components/SubrequestNumberElement/SubrequestNumberElement';
import { getRequest, useRequestData } from 'store/request/request';
import { usePossibilityCreationPriceList } from 'store/priceList/possibility/creation';
import { isLmEnvironment } from 'common/consts';
import { executePricelistFlow } from 'api/pricelist';
import { PricelistStatusCode } from '@ppm/ppm-platform-pricelist-service';
import { useAppDispatch } from 'store/hooks';
import styles from './PriceListBlock.module.scss';

type Props = {
  id: string;
  isChangeable: boolean;
};

export const PriceListItem = ({ isChangeable, id }: Props) => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const { isLoading, data } = usePriceListData().data;
  const { error } = usePossibilityCreationPriceList().data;
  const { containers } = useRequestData().data;

  const handleArchivePriceList = useCallback(
    async ({
      requestId,
      priceListId,
      status,
    }: {
      status: PricelistStatusCode;
      requestId: string;
      priceListId: string;
    }) => {
      await executePricelistFlow({
        urlParameters: [requestId, priceListId, status],
      });
      dispatch(getRequest(requestId));
    },
    [dispatch]
  );

  if (isLoading) return <Loader />;

  return (
    <View marginTop="mu150">
      {data.length > 0 &&
        data.map(({ pricelist, flowRights }, index) => {
          const {
            id: priceListId,
            requestId,
            statusId,
            statusName,
            containers: subrequests,
            xlsxFileId,
            pdfFileId,
            pricelistName,
            pricelistStartDate,
          } = pricelist;
          const hasGrant = getHasGrants(flowRights.grants);
          const { PricelistDownloadPDF, PricelistDownloadXLSX, PricelistTransitionToArchive } =
            hasGrant;

          return (
            <React.Fragment key={priceListId}>
              <Flex justifyContent="space-between" marginTop="mu075">
                <Flex direction="column" className={styles.first} alignItems="baseline">
                  {isChangeable ? (
                    <Link
                      className="mc-link mc-link--primary"
                      to={id ? `/${id}/price-list/${priceListId}` : `/new-price-list/${id}`}
                    >
                      {translations('priceList')} {pricelistName} №{priceListId}
                    </Link>
                  ) : (
                    <Text theme="primary-01" as="p">
                      {translations('priceList')} {pricelistName} № {priceListId}
                    </Text>
                  )}
                  <Text as="p" size="s" theme="primary-02">
                    {translations('from').toLowerCase()} {pricelistStartDate}
                  </Text>
                  {Boolean(error) && (
                    <Text as="p" size="s" theme="warning">
                      {translations(
                        `${
                          isLmEnvironment
                            ? 'creationPricelistLMWarning'
                            : 'creationPricelistSupplierWarning'
                        }`
                      )}
                    </Text>
                  )}
                </Flex>
                <Flex alignItems="baseline" className={styles.grow}>
                  <Badge className={styles.badge} theme={getPriceListStatusBadgeTheme(statusId)}>
                    {statusName}
                  </Badge>
                </Flex>
                <Flex direction="column" className={styles.grow}>
                  <SubrequestNumberElement
                    data={subrequests}
                    containers={containers}
                    textProps={{ theme: 'dark' }}
                    iconProps={{ className: 'mu-mr-025' }}
                  />
                </Flex>
                <Flex direction="column" className={styles.grow}>
                  {xlsxFileId && PricelistDownloadXLSX && (
                    <Flex>
                      <Document24 fill="#188803" className="mu-mr-050" />
                      <MozaicLink
                        theme="primary"
                        size="s"
                        onClick={() => downloadFile(id, Number(pricelist.xlsxFileId))}
                      >
                        {translations('download')}
                      </MozaicLink>
                    </Flex>
                  )}
                  {pdfFileId && PricelistDownloadPDF && (
                    <Flex marginTop="mu025">
                      <Pdf24 fill="#C61112" className="mu-mr-050" />
                      <MozaicLink
                        theme="primary"
                        size="s"
                        onClick={() => downloadFile(id, Number(pdfFileId))}
                      >
                        {translations('download')}
                      </MozaicLink>
                    </Flex>
                  )}
                  {PricelistTransitionToArchive && (
                    <Flex marginTop="mu025">
                      <PublishTrashbin24 fill="#C61112" className="mu-mr-050" />
                      <MozaicLink
                        theme="primary"
                        size="s"
                        onClick={() =>
                          handleArchivePriceList({
                            requestId,
                            priceListId,
                            status: PricelistStatusCode.Archived,
                          })
                        }
                      >
                        {translations('deletePricelist')}
                      </MozaicLink>
                    </Flex>
                  )}
                </Flex>
              </Flex>
              {data.length !== index + 1 && (
                <hr className={cn('mu-mt-100', styles.verticalDivider)} />
              )}
            </React.Fragment>
          );
        })}
    </View>
  );
};
