import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetSubruquestContainerByRequestIdResponse } from '@ppm/ppm-platform-api';
import { getSubRequestsContainer } from 'api/request/subRequests';
import { RequiredStateFields, RootState } from '../types';
import { useAppSelector } from '../hooks';
import { setFailure, setLoading } from '../helpers';

interface SliceState extends RequiredStateFields {
  subrequests: GetSubruquestContainerByRequestIdResponse;
}

export const getSubRequests = createAsyncThunk<GetSubruquestContainerByRequestIdResponse, string>(
  'subrequests/getSubRequests',
  async (requestId: string, { rejectWithValue }) => {
    try {
      return await getSubRequestsContainer({ urlParameters: [requestId] });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const initialState = {
  subrequests: [],
  isFailure: false,
  isLoading: false,
};

export const subrequestsSlice = createSlice({
  initialState,
  name: 'subrequests',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubRequests.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(getSubRequests.fulfilled, (state: SliceState, action) => {
        state.subrequests = action.payload;
        state.isLoading = false;
        state.isFailure = false;
      })
      .addCase(getSubRequests.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const useSubrequests = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.subrequests.subrequests) };
};
