import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';
import { IFilter } from 'common/types';
import { FilterName } from 'pages/cbd/Scheduler/partials/AlertAttribute/AlertAttribute.types';

interface SliceState {
  filters: IFilter<FilterName>[];
  deletedFilters: FilterName[];
  searchValue: string;
}

export const filtersSlice = createSlice({
  initialState: {
    filters: [],
    deletedFilters: [],
    searchValue: '',
  },
  name: 'subscriptionsFilters',
  reducers: {
    clearDeletedFilters(state) {
      state.deletedFilters = [];
    },
    changeDeletedFilters(state, action) {
      state.deletedFilters = state.deletedFilters.concat(action.payload);
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
  },
});

export const { setFilters, changeDeletedFilters, clearDeletedFilters } = filtersSlice.actions;

export const useFiltersData = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.scheduler.subscriptions.filters);
};
