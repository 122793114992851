import { cbdApiClient } from 'api';
import { AxiosResponse } from 'axios';

interface InfoBaseCurrency {
  currency: string;
  name: string;
}

interface CurrencyEntity {
  id: number;
  cbdId: number;
  rate: number;
  currencyId: string;
  info: InfoBaseCurrency;
}

interface CostDictionaryEntity {
  id: number;
  code: string;
  name: string;
  parentId: number | null;
}

interface AttributeDictionary {
  id: number;
  costId: number;
  name: string;
}

export interface AttributesEntity {
  id: string;
  costCbdId: number;
  attributeId: number;
  value: string;
  attributeDictionary: AttributeDictionary;
}

export interface ICbdInfo {
  id: number;
  supplierId: number;
  dateCbd: string;
  createdAt: string;
  group: string;
  division: number;
  item: number;
  factoryCity: string;
  factoryCountry: string;
  purchaseVolume: number;
  baseCurrencyId: string;
  tpType: string;
  status: string;
  cbdTemplateId: number;
  baseCurrency: InfoBaseCurrency;
  currency: CurrencyEntity[];
  cost: CostEntity[];
}

export interface CostEntity {
  id: string;
  cbdId: number;
  costId: number;
  sum: number;
  currencyId: string;
  attributes: AttributesEntity[];
  costDictionary: CostDictionaryEntity;
}

export const getCbdInfoRequest = async (id: string) => {
  const { data }: AxiosResponse<ICbdInfo> = await cbdApiClient.get(`/api/cbd/${id}`);
  return data;
};
