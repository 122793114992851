import { clearLoadListSlice, getLoadList, useLoadList } from 'store/cbd/uploads/loadList';
import React, { FC } from 'react';
import { UploadsColumn, useColumnsData } from 'store/cbd/uploads/columns';
import { useAppDispatch } from 'store/hooks';
import { useParams } from 'react-router-dom';
import { ICbdLoad, IPageSize } from 'common/cbd/types';
import { IFilter } from 'common/types';
import {
  changeUploadsDeletedFilters,
  setUploadsFilters,
  useUploadsFiltersData,
} from 'store/cbd/uploads/filters';
import { useMount } from 'hook/useMount';
import CbdView, { ICbdView } from '../hok/CbdView';
import { useColumnsForm, useGetColumns, sortConfig } from './hooks/columns';
import { UploadsColumns, uploadsFiltersName } from './hooks/consts';
import { IUploadTable } from './Uploads.types';
import { ExtendsFilters } from '../hok/partials/consts';

const Uploads: FC = () => {
  const dispatch = useAppDispatch();
  const { columns } = useGetColumns();
  const { columns: columnsFilter, sortedField } = useColumnsData().data;
  const { filters } = useUploadsFiltersData().data;
  const { form, saveColumnsFilter, resetColumnsFilters } = useColumnsForm();
  const { data, isLoading, pageable } = useLoadList();
  const { reqId } = useParams();

  const config: ICbdView<ICbdLoad, UploadsColumns, UploadsColumn, IUploadTable> = {
    toggleVisible: true,
    uploadsFiltersVisible: true,
    searchInputVisible: true,
    loadErrorsPopupVisible: true,
    isLoading,
    data: {
      data,
      getData: (params: IPageSize) => {
        dispatch(getLoadList({ reqId: reqId || '', ...params }));
      },
      pageable,
    },
    columnsPopup: { columns, columnsFilter, form, saveColumnsFilter, resetColumnsFilters },
    pageFilters: filters,
    onRemoveTag: (idForDelete: string[], filtersForDelete: IFilter<ExtendsFilters>[]) => {
      dispatch(changeUploadsDeletedFilters(idForDelete));
      dispatch(setUploadsFilters(filtersForDelete));
    },
    resetFilters: () => {
      dispatch(changeUploadsDeletedFilters(uploadsFiltersName));
      dispatch(setUploadsFilters([]));
    },
    table: {
      sortConfig,
      columns,
      columnsFilter,
      sortedField,
    },
  };

  useMount(() => {
    return () => {
      dispatch(clearLoadListSlice());
    };
  });

  return <CbdView<ICbdLoad, UploadsColumns, UploadsColumn, IUploadTable> {...config} />;
};

export default Uploads;
