import { ChangeEvent, useCallback } from 'react';
import { forEach, uniqueId } from 'lodash';
import { FileInfo } from '@ppm/ppm-platform-api';
import { Controller } from 'hook/useActionsFileForm';

export type TFile = File & { id: number };

export const useUploadFile = (multiple: boolean, controller: Controller) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, fileServerId?: number) => {
      const currentFiles = e.target.files;

      if (!currentFiles) return [...controller.value];

      forEach(currentFiles, (file) => {
        (file as TFile).id = fileServerId || Number(uniqueId());
      });

      if (multiple) {
        controller.onChange([...controller.value, ...currentFiles]);
      } else {
        controller.onChange([...currentFiles]);
      }
      return null;
    },
    [multiple, controller]
  );

  const handleDelete = useCallback(
    (fileId: number) =>
      controller.onChange(controller.value.filter((item: FileInfo) => item.id !== fileId)),
    [controller]
  );

  return { handleChange, handleDelete };
};
