import React, { useCallback, useEffect, useRef } from 'react';
import {
  View,
  Flex,
  Text,
  DateInput,
  RemovableTag,
  Loader,
  CheckBox,
  Button,
} from '@mozaic-ds/react';
import { Label } from 'components/Label/Label';
import { FormProvider } from 'react-hook-form';
import { useAppTranslations } from 'translations';
import { formatToSelectValue } from 'store/helpers';
import { getUsers, setSearchValue, useUsers } from 'store/users/users';
import { useAppDispatch } from 'store/hooks';
import { Search } from 'components/Search/Search';
import { resetStore, searchRequest, useRequestData } from 'store/request/request';
import cn from 'classnames';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import { addDays } from 'date-fns';
import { parseDateForMozaicComponent } from 'common/utils';
import { getRequestIds } from 'api/request/requestAll';
import { GetAllRequestListQuery } from '@ppm/ppm-platform-api';
import { useReplacementForm } from './hooks';
import styles from './Replacement.module.scss';
import Buttons from './Buttons';

const Replacement = () => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();
  const controller = useRef<AbortController>(new AbortController());
  const { users, searchText } = useUsers().data;
  const { id: requestSearchingId, isLoading } = useRequestData().data;
  const {
    form,
    endDate,
    replacementLdap,
    requestIds,
    defaultValues,
    handelSubmit,
    handleReset,
    isDisabled,
    areFullRequests,
  } = useReplacementForm();

  const handleDetRequestIds = useCallback(async (query: GetAllRequestListQuery) => {
    return getRequestIds({ conf: { params: query } });
  }, []);

  useEffect(() => {
    if (requestSearchingId)
      handleDetRequestIds({ search: requestSearchingId }).then(({ data }) => {
        if (data.length === 0) return null;
        const { id } = data[0];
        if (!requestIds.value.find((item) => item === id)) {
          form.setValue('areFullRequests', false);
          requestIds.onChange([...requestIds.value, id]);
        }
        return null;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, requestSearchingId]);

  useEffect(() => {
    controller.current.abort();
    controller.current = new AbortController();
    if (searchText) dispatch(getUsers({ text: searchText, signal: controller.current.signal }));
  }, [dispatch, searchText]);

  return (
    <Flex direction="column" className={styles.container}>
      <Text weight="semi-bold" className="mu-mb-075">
        {translations('replacementHeader')}
      </Text>
      <FormProvider {...form}>
        <View
          className={styles.block}
          shadow="s"
          radius="l"
          paddingTop="mu025"
          paddingBottom="mu200"
          paddingRight="mu200"
          paddingLeft="mu200"
          as="form"
        >
          <Label text={translations('ldap')}>
            <Autocomplete
              {...replacementLdap}
              placeholder={translations('searchByLdap')}
              listBoxItems={formatToSelectValue(users)}
              handleSearch={setSearchValue}
              isDisabled={isDisabled}
              className={cn({
                'is-invalid': Boolean(form.formState.errors?.replacementLdap),
              })}
            />
          </Label>
          <Label text={translations('endDate')}>
            <DateInput
              {...endDate}
              min={parseDateForMozaicComponent(addDays(new Date(), 1))}
              className="mu-mt-025"
              isDisabled={isDisabled}
            />
          </Label>
          <Label text={translations('access')}>
            <Search
              size="m"
              className="mu-mt-025"
              placeholder={translations('searchRequests')}
              setStoreValue={searchRequest}
              clear={resetStore}
              isDisabled={isDisabled}
            />
            {Boolean(requestIds.value.length) && (
              <Flex className={cn('mu-mt-050', styles.requestContainer)} wrap="wrap">
                {requestIds.value.map((requestId) => (
                  <RemovableTag
                    className="mu-mr-025 mu-mb-025"
                    key={requestId}
                    onRemove={() => {
                      const value = requestIds.value.filter((item) => item !== requestId);
                      requestIds.onChange(value);
                      if (!value.length) form.setValue('areFullRequests', true);
                    }}
                  >
                    {requestId}
                  </RemovableTag>
                ))}
                {isLoading && <Loader />}
              </Flex>
            )}
            {!requestIds.value.length && isLoading && <Loader />}
          </Label>
          <CheckBox
            className="mu-mt-100"
            {...areFullRequests}
            value={areFullRequests.name}
            isChecked={areFullRequests.value}
            onChange={(e) => {
              areFullRequests.onChange(e);
              if (e.target.checked) form.setValue('requestIds', []);
            }}
            isDisabled={isDisabled || !requestIds.value.length}
          >
            {translations('allOption')}
          </CheckBox>
          <Button
            type="button"
            className="mu-mt-100"
            theme="neutral"
            variant="bordered"
            size="m"
            width="fit"
            onClick={() => {
              form.setValue('replacementLdap', null);
              form.setValue('endDate', '');
              form.setValue('requestIds', []);
            }}
          >
            {translations('clearAll')}
          </Button>
        </View>
      </FormProvider>
      {/* надо вынести отсюда */}
      <FormProvider {...form}>
        <Buttons
          defaultValues={defaultValues}
          handleReset={handleReset}
          submitForm={handelSubmit}
        />
      </FormProvider>
      {/* надо вынести отсюда */}
    </Flex>
  );
};
export default Replacement;
