import React, { FC, useEffect } from 'react';
import cn from 'classnames';
import { IOverlayProps } from 'components/OverlayCustom/types';
import { Flex } from '@mozaic-ds/react';
import styles from './Overlay.module.scss';

const OverlayCustom: FC<IOverlayProps> = ({
  children,
  className,
  isVisible,
  ...props
}): JSX.Element => {
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isVisible]);

  useEffect(
    () => () => {
      document.body.style.overflow = '';
    },
    []
  );

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      className={cn('mc-overlay', styles.overlay, isVisible && 'is-visible')}
      role="dialog"
      tabIndex={-1}
      {...props}
    >
      {children}
    </Flex>
  );
};

export default OverlayCustom;
