import React, { useCallback } from 'react';
import { SubrequestContainerWithRights } from '@ppm/ppm-platform-api';
import { Flex, Text } from '@mozaic-ds/react';
import { NotificationAvailable24 } from '@mozaic-ds/icons/react';
import { ITextProps } from '@mozaic-ds/react/lib/components/Text/Text.types';

type SubrequestNumberElementProps = {
  data: string[];
  containers: SubrequestContainerWithRights[];
  textProps?: Partial<ITextProps>;
  iconProps?: {
    className?: string;
    fill?: string;
  };
};
export const SubrequestNumberElement = ({
  data,
  containers,
  textProps,
  iconProps,
}: SubrequestNumberElementProps) => {
  const getPriceListContainer = useCallback(
    (containerId: string): SubrequestContainerWithRights | undefined => {
      if (!containers || containers.length === 0) return undefined;

      return containers.find(({ id: requestContainerId }) => containerId === requestContainerId);
    },
    [containers]
  );
  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((subrequestId) => (
          <Flex alignItems="center" marginBottom="mu075" key={subrequestId}>
            <NotificationAvailable24 fill="#188803" {...iconProps} />
            <Text {...textProps}>
              n° {getPriceListContainer(subrequestId)?.containerNumber || ''}
            </Text>
          </Flex>
        ))}
    </>
  );
};
