import React, { FC } from 'react';
import { cbdApiClient } from 'api';
import { useNotifications } from '@mozaic-ds/react';
import { AxiosError } from 'axios';
import { useAppTranslations } from 'translations';
import { expiredAccess } from 'store/auth/access';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { useMount } from 'hook/useMount';
import { apiClient } from 'api/utility/consts';

interface IError {
  message: string;
  code: number;
}

interface IErrorData {
  errors?: IError[];
  message?: string;
}

export interface IArrayError {
  errors: IErrorData[];
}

const AxiosInterceptor: FC = (props) => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();
  const navigate = useNavigate();
  const { danger } = useNotifications();

  const { children } = props;
  const getErrorText = (data: IErrorData) => {
    if (Array.isArray(data.errors)) {
      return data.errors[0].message;
    }

    return data.message;
  };

  const resInterceptor = <T,>(response: T) => {
    return response;
  };

  const errInterceptor = (error: AxiosError<IErrorData>) => {
    const errorResponseData = error.response?.data;

    if (errorResponseData?.errors) return Promise.reject(error);

    if (error.response?.status === 440) {
      dispatch(expiredAccess());
      navigate(0);
    } else if (error.message !== 'canceled') {
      danger({
        title: translations('dangerNotification'),
        message: errorResponseData
          ? getErrorText(errorResponseData)
          : translations('anErrorOccurred'),
      });
    }

    return Promise.reject(error);
  };

  useMount(() => {
    const cbdInterceptor = cbdApiClient.interceptors.response.use(resInterceptor, errInterceptor);
    const interceptor = apiClient.interceptors.response.use(resInterceptor, errInterceptor);

    return () => {
      cbdApiClient.interceptors.response.eject(cbdInterceptor);
      apiClient.interceptors.response.eject(interceptor);
    };
  });

  return <>{children}</>;
};

export default AxiosInterceptor;
