import { Translations } from 'translations';
import { FilterName } from '../../../ExternalData.types';

export enum ExternalDataColumns {
  ID = 'id',
  EXT_DATA_TYPE = 'extDataType',
  CREATED_AT = 'createdAt',
  PERIOD_TYPE = 'periodType',
  UPDATED_AT = 'updatedAt',
  SOURCE = 'source',
  SOURCE_TYPE = 'sourceType',
  CATEGORY_ID = 'categoryId',
  TYPE_ID = 'typeId',
  SUB_TYPE_ID = 'subtypeId',
  NAME = 'name',
  SECOND_NAME = 'name2',
  DATE = 'date',
  VALUE = 'value',
  REGION = 'region',
  MEASURE = 'measure',
  CURRENCY = 'currencyCode',
}

export const getLabel = (key: string, t: Translations) => {
  return {
    extDataType: t('externalDataType'),
    sourceType: t('sourceType'),
    source: t('source'),
    periodType: t('periodicity'),
    categoryId: t('category'),
    typeId: t('type'),
    subtypeId: t('subType'),
    name: t('nomination'),
    name2: t('newNomination'),
    externalSourceDate: t('quoteDate'),
    value: t('value'),
    region: t('region'),
    measure: t('unit'),
    currencyCode: t('currency'),
  }[key];
};

export const filtersName: FilterName[] = [
  'extDataType',
  'sourceType',
  'source',
  'periodType',
  'categoryId',
  'typeId',
  'subtypeId',
  'name',
  'name2',
  'externalSourceDate',
  'value',
  'region',
  'measure',
];
