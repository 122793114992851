import { SelectItem } from 'common/types';
import { TRequest } from 'pages/Requests/types';
import { useMemo } from 'react';

export const useGetReasonLabel = (reasons: SelectItem[]) => {
  return useMemo(
    () => (request: TRequest) =>
      reasons
        .filter((reason) => request?.reasonListId?.includes(reason.value))
        .map((reason) => reason.label)
        .join(', '),
    [reasons]
  );
};
