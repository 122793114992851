import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetSupplierLinkQuery, GetSupplierLinkUsersResponse } from '@ppm/ppm-platform-api';
import { getSupplierLinkUser } from 'api/links/supplierLinks';
import { setFailure, setLoading } from 'store/helpers';
import { RequiredStateFields, RootState } from 'store/types';
import { useAppSelector } from 'store/hooks';

interface SliceState extends RequiredStateFields {
  data: GetSupplierLinkUsersResponse['data'];
}

export const getSupplierLinkUsers = createAsyncThunk(
  'dashboard/getSupplierLinkUsers',
  async (query: GetSupplierLinkQuery, { rejectWithValue }) => {
    try {
      return await getSupplierLinkUser({ conf: { params: query } });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const supplierLinkUsersSlice = createSlice({
  initialState: { data: {}, isLoading: false, isFailure: false },
  name: 'supplierLinkUsers',
  reducers: {
    clearAll(state: SliceState) {
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSupplierLinkUsers.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getSupplierLinkUsers.fulfilled,
        (state: SliceState, action: PayloadAction<GetSupplierLinkUsersResponse>) => {
          state.data = action.payload.data;
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getSupplierLinkUsers.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const { clearAll } = supplierLinkUsersSlice.actions;

export const useSupplierLinkUsersData = (): { supplierLinkUsers: SliceState } => {
  return {
    supplierLinkUsers: useAppSelector((state: RootState) => state.dashboard.supplierLinkUsers),
  };
};
