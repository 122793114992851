import { useCallback, useState } from 'react';

export type UseStepperMethods<T> = { validatedStep: (step: T) => void };

export type UseStepperState<T> = [T, UseStepperMethods<T>];

export function useStepperState<T>(initStep: T): UseStepperState<T> {
  const [currentStep, setStep] = useState(initStep);

  const validatedStep = useCallback((nextStep: T) => setStep(nextStep), []);

  return [currentStep, { validatedStep }];
}
