import { InputHTMLAttributes } from 'react';
import { SubrequestContainer } from '@ppm/ppm-platform-request-service/models/request/subrequest-container';

export type ContainerDatesProps = {
  id: string;
  filedName: Dates.SigningDate | Dates.PriceApplyDate;
  isDisabled: boolean;
  restriction: Pick<InputHTMLAttributes<HTMLInputElement>, 'min' | 'max'>;
};

export enum Dates {
  SigningDate = 'signingDate',
  PriceApplyDate = 'priceApplyDate',
}

export type SigningDate = Extract<keyof SubrequestContainer, 'signingDate'>;
export type ApplyDate = Extract<keyof SubrequestContainer, 'priceApplyDate'>;
