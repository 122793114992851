import React from 'react';
import { NotificationAvailable16 } from '@mozaic-ds/icons/react';
import { useAppTranslations } from 'translations';
import cn from 'classnames';
import { useStepperContext } from './StepperContext';

export const Stepper = <T,>() => {
  const translations = useAppTranslations();
  const { currentStepId, steps } = useStepperContext<T>();
  const indexOfCurrentStep = steps.findIndex((e) => e.id === currentStepId);

  return (
    <nav className="mc-stepper--primary-02" aria-label={translations('stepperDescription')}>
      <ol className="mc-stepper__list">
        {steps.map(({ id, title, label }, index) => {
          const isCurrent = currentStepId === id;
          const isValidated = indexOfCurrentStep > index;
          const numberOfCurrentStep = index + 1;
          return (
            <li
              key={id as string}
              className={cn('mc-stepper__item', {
                'mc-stepper__item--current': isCurrent,
                'mc-stepper__item--validated': isValidated,
              })}
            >
              <div className="mc-stepper__indicator" aria-hidden="true">
                {isCurrent && numberOfCurrentStep}
                {isValidated && <NotificationAvailable16 className="mc-stepper__icon" />}
              </div>
              <div className="mc-stepper__detail">
                <span className="mc-stepper__title">
                  {title
                    ? translations(title)
                    : `${translations('step')} ${numberOfCurrentStep} / ${steps.length}`}
                </span>
                <span className="mc-stepper__label">{translations(label)}</span>
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
