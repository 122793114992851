import { Components, OptionData } from 'components/Header/types';
import { Translations } from 'translations';
import { getPriceListStatusBadgeTheme, getRequestStatusBadgeTheme } from 'common/utils';

export const getComponents = (t: Translations, options: OptionData): Record<string, Components> => {
  const { params, data } = options;

  return {
    '/cbd': {
      text: t('cbd'),
      hasArrowBack: true,
    },
    '/dashboard': {
      text: t('adminDashboardHeader'),
      hasArrowBack: true,
    },
    '/rights-replacement/:name/:ldap': {
      redirectItems: [
        { name: t('adminDashboardRedirect'), url: '/dashboard', isCurrent: false },
        { name: t('profileRightsRedirect'), isCurrent: true },
      ],
      text: `${params?.name} ${params?.ldap}`,
      hasArrowBack: false,
    },
    '/new-price-list/:requestId': {
      redirectItems: [
        {
          name: `${t('request')} ${params?.requestId}`,
          url: `/request/${params?.requestId}`,
          isCurrent: false,
        },
      ],
      text: t('creatingPriceList'),
      hasArrowBack: false,
    },
    '/:requestId/price-list/:priceListId': {
      redirectItems: [
        {
          name: `${t('request')} ${params?.requestId}`,
          url: `/request/${params?.requestId}`,
          isCurrent: false,
        },
      ],
      text: `${t('priceList')} ${params?.priceListId}`,
      badge: {
        statusTheme: data.priceList.statusId
          ? getPriceListStatusBadgeTheme(data.priceList.statusId)
          : undefined,
        name: data.priceList?.statusName,
      },
      hasArrowBack: false,
      isShownPriseListDeleteButton: true,
    },
    '/uploads/:reqId': {
      text: `${t('cbd')} #${params?.reqId}`,
      hasArrowBack: true,
    },
    '/uploads/:reqId/bound/:cbdId': {
      text: `${t('request')} #${params?.reqId},
    ${t('loadedCBD')}`,
      hasArrowBack: true,
    },
    '/external-data': {
      text: t('externalSourcesData'),
      hasArrowBack: true,
    },
    '/analysis': {
      text: t('marketSituationAnalysis'),
      hasArrowBack: true,
    },
    '/edit-template': {
      text: t('editTemplateCbd'),
      hasArrowBack: true,
    },
    '/scheduler': {
      text: t('scheduler'),
      hasArrowBack: true,
    },
    '/cbd-analysis/:cbdId': {
      text: `${t('cbdAnalysis')} #${params?.cbdId}`,
      hasArrowBack: true,
    },
    '/cbd/:cbdId': {
      text: `${t('cbd')} #${params?.cbdId}`,
      hasArrowBack: true,
    },
    '/create-request': {
      text: `${t('requestCreatingHeader')} ${
        data.createRequest.id ? `#${data.createRequest.id}` : ''
      }`,
      hasArrowBack: true,
    },
    '/request/:id': {
      text: `${t('request')} #${params?.id}`,
      badge:
        params?.id === data.request.id
          ? {
              statusTheme: data.request.statusId
                ? getRequestStatusBadgeTheme(data.request.statusId)
                : undefined,
              name: data.request?.statusName,
            }
          : {},
      hasArrowBack: true,
    },
    '/': {
      text: t('ppm'),
      hasArrowBack: false,
    },
  };
};
