import React, { useEffect, useMemo } from 'react';
import {
  clearNomenclatureStore,
  useNomenclatureData,
} from 'store/dashboard/rightsManagement/nomenclature';
import {
  clearAll as clearAllSupplier,
  useSuppliersData,
} from 'store/dashboard/rightsManagement/supplier';
import { Flex, Loader, Notification, View } from '@mozaic-ds/react';
import {
  getNomenclatureLinkUsers,
  useNomenclatureLinkUsersData,
  clearAll as clearAllNomenclatureLinkUsers,
} from 'store/dashboard/rightsManagement/nomenclatureLinkUsers';
import { useAppTranslations } from 'translations';
import { useAppDispatch } from 'store/hooks';
import {
  getSupplierLinkUsers,
  useSupplierLinkUsersData,
  clearAll as clearAllSupplierLinkUsers,
} from 'store/dashboard/rightsManagement/supplierLinkUsers';
import { createBodyNomenclatureLinksRequest } from 'store/dashboard/helpers';
import { getUsers, useUsers, clear, setSearchValue } from 'store/users/users';
import { Search } from 'components/Search/Search';
import Card from './Links/UserCards/Card/Card';
import Links from './Links/Links';
import styles from './NomenclatureLinksManagement.module.scss';

const NomenclatureLinksManagement = () => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const { data: nomenclatureLink, isLoading: isLoadingNomenclatureLinks } =
    useNomenclatureLinkUsersData().nomenclatureLinkUsers;
  const { data: supplierLink, isLoading: isLoadingSupplierLinks } =
    useSupplierLinkUsersData().supplierLinkUsers;
  const { users, searchText, isLoading: isLoadingSearchedUser, isFailure } = useUsers().data;
  const { selectedNomenclature } = useNomenclatureData().nomenclature;
  const { checkedFields: selectedSuppliers } = useSuppliersData().supplier;

  useEffect(() => {
    return () => {
      dispatch(clearAllNomenclatureLinkUsers());
      dispatch(clearAllSupplierLinkUsers());
      dispatch(clearAllSupplier({ isClearRole: false }));
      dispatch(clearNomenclatureStore());
    };
  }, [dispatch]);

  useEffect(() => {
    if (searchText) {
      dispatch(getUsers({ text: searchText }));
    }
  }, [dispatch, searchText]);

  useEffect(() => {
    if (selectedNomenclature.length > 0) {
      dispatch(getNomenclatureLinkUsers(createBodyNomenclatureLinksRequest(selectedNomenclature)));
    }
  }, [dispatch, selectedNomenclature]);

  useEffect(() => {
    if (selectedSuppliers.length > 0) {
      dispatch(
        getSupplierLinkUsers({
          supplierIds: selectedSuppliers.map((item) => item.value),
        })
      );
    }
  }, [dispatch, selectedSuppliers]);

  const isNomenclatureSelected = selectedNomenclature.length > 0;
  const isSupplierSelected = selectedSuppliers.length > 0;
  const isSearchedUsers = users.length > 0 && searchText;
  const isSomeIsChecked = isNomenclatureSelected || selectedSuppliers.length > 0;
  const isLoading = isLoadingNomenclatureLinks || isLoadingSupplierLinks;

  const isNomenclatureLink = useMemo(
    () => Object.keys(nomenclatureLink).length > 0,
    [nomenclatureLink]
  );
  const isSupplierLink = useMemo(() => Object.keys(supplierLink).length > 0, [supplierLink]);

  return (
    <View className={styles.selection} radius="s" padding="mu150" marginTop="mu200">
      {isNomenclatureLink && isNomenclatureSelected && <Links links={nomenclatureLink} />}
      {isSupplierLink && isSupplierSelected && <Links links={supplierLink} />}

      {!isSomeIsChecked && (
        <>
          <Search
            size="m"
            className="mu-p-150"
            placeholder={translations('searchByLdap')}
            clear={clear}
            setStoreValue={setSearchValue}
          />
          {!isLoadingSearchedUser && (isFailure || (searchText && !users.length)) && (
            <Notification
              theme="warning"
              className="mu-mt-125"
              message={translations('noUserSearch')}
            />
          )}
        </>
      )}
      {!isLoading && isSomeIsChecked && !(isNomenclatureLink || isSupplierLink) && (
        <Notification theme="warning" message={translations('noUserSearch')} />
      )}
      {isSearchedUsers &&
        users.map(({ name, id, properties }) => (
          <Card name={name} ldap={properties.login} key={id} />
        ))}
      {isLoading && (
        <Flex justifyContent="center" paddingTop="mu075">
          <Loader size="m" />
        </Flex>
      )}
    </View>
  );
};
export default NomenclatureLinksManagement;
