import React, { useEffect } from 'react';
import { Button, TextInput, View, Toggle } from '@mozaic-ds/react';
import { DisplayFilter24 } from '@mozaic-ds/icons/react';
import Layer from 'components/Layer/Layer';
import { useAppTranslations } from 'translations';
import { useFiltersForm } from 'pages/cbd/EditTemplate/components/ParseTable/hooks/filters';
import { filtersName } from 'pages/cbd/EditTemplate/components/ParseTable/hooks/consts';
import FieldWrapper from 'components/FieldWrapper/FieldWrapper';
import { useFiltersData } from 'store/cbd/templateStructure/filters';

const TemplateStructureFiltersPopup = () => {
  const translations = useAppTranslations();
  const { form, costName, codeCost, costRequired, costUnique, onSubmit } = useFiltersForm();

  const { deletedFilters } = useFiltersData().data;

  useEffect(() => {
    deletedFilters.forEach((id) => form.setValue(id, ''));
  }, [deletedFilters, form]);

  return (
    <Layer
      id="filtersPopup"
      icon={<DisplayFilter24 fill="#71706b" />}
      title={translations('filterPopupHeader')}
      resetItems={() => {
        form.reset();
        filtersName.forEach((id) => form.setValue(id, ''));
      }}
      saveItems={onSubmit}
      button={
        <Button leftIcon={<DisplayFilter24 />} variant="bordered" size="s">
          {translations('filters')}
        </Button>
      }
    >
      <View as="form" paddingLeft="mu025" paddingRight="mu025">
        <FieldWrapper label={translations('catalogCostName')} id="costName">
          <TextInput {...costName} size="m" placeholder={translations('catalogCostName')} />
        </FieldWrapper>
        <FieldWrapper label={translations('catalogCostCode')} id="codeCost">
          <TextInput {...codeCost} size="m" placeholder={translations('catalogCostCode')} />
        </FieldWrapper>
        <FieldWrapper label={translations('catalogCostObligatory')} id="costRequired">
          <Toggle {...costRequired} value={costRequired.name} isChecked={costRequired.value} />
        </FieldWrapper>
        <FieldWrapper label={translations('catalogCostUniqueness')} id="costUnique">
          <Toggle {...costUnique} value={costUnique.name} isChecked={costUnique.value} />
        </FieldWrapper>
      </View>
    </Layer>
  );
};

export default TemplateStructureFiltersPopup;
