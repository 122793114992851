import React, { useEffect } from 'react';
import { Button, Select, TextInput, View } from '@mozaic-ds/react';
import Flatpickr from 'react-flatpickr';
import { DisplayFilter24 } from '@mozaic-ds/icons/react';
import Layer from 'components/Layer/Layer';
import { useAppTranslations } from 'translations';
import { useScheduler } from 'store/cbd/scheduler/scheduler';
import { getRegionsList, setSearchValue, useRegions } from 'store/cbd/regions';
import { useMeasureUnits } from 'store/cbd/measureUnits';
import FieldWrapper from 'components/FieldWrapper/FieldWrapper';
import {
  dayMonthOptions,
  extDataTypeOptions,
  sourceTypeOptions,
} from 'pages/cbd/Scheduler/partials/SchedulerForm/consts';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import { useAppDispatch } from 'store/hooks';
import useCostTypesOptions from 'pages/cbd/hooks/useCostTypesOptions';
import { useFiltersData } from 'store/cbd/externalData/filters';
import { filtersName } from '../hook/consts';
import { useFiltersForm } from '../hook/filters';

const FiltersPopup = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();
  const { data: sourceOptions } = useScheduler();
  const {
    form,
    extDataType,
    sourceType,
    source,
    periodType,
    categoryId,
    typeId,
    subtypeId,
    name,
    name2,
    externalSourceDate,
    value,
    region,
    measure,
    onSubmit,
  } = useFiltersForm();
  const { categoryCostTypesOptions, getTypeCostTypesOptions, getSubtypeCostTypesOptions } =
    useCostTypesOptions();
  const { list: regionList, searchValue } = useRegions();
  const { list: measureUnitList } = useMeasureUnits();
  const { deletedFilters } = useFiltersData();

  useEffect(() => {
    deletedFilters.forEach((id) => form.setValue(id, ''));
  }, [deletedFilters, form]);

  useEffect(() => {
    dispatch(
      getRegionsList({
        query: searchValue,
      })
    );
  }, [dispatch, searchValue]);

  return (
    <Layer
      id="filtersPopup"
      icon={<DisplayFilter24 fill="#71706b" />}
      title={translations('filterPopupHeader')}
      resetItems={() => {
        form.reset();
        filtersName.forEach((id) => form.setValue(id, ''));
      }}
      saveItems={onSubmit}
      button={
        <Button leftIcon={<DisplayFilter24 />} variant="bordered" size="s">
          {translations('filters')}
        </Button>
      }
    >
      <View as="form" paddingLeft="mu025" paddingRight="mu025">
        <FieldWrapper label={translations(' externalDataType')} id="extDataType">
          <Select
            {...extDataType}
            size="m"
            options={extDataTypeOptions.map((item) => ({
              ...item,
              label: translations(item.label),
            }))}
            placeholder={translations('chooseAnOption')}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('sourceType')} id="sourceType">
          <Select
            {...sourceType}
            size="m"
            options={sourceTypeOptions.map((item) => ({
              ...item,
              label: translations(item.label),
            }))}
            placeholder={translations('chooseAnOption')}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('source')} id="source">
          <Select
            {...source}
            size="m"
            placeholder={translations('chooseAnOption')}
            options={sourceOptions.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('periodicity')} id="periodType">
          <Select
            {...periodType}
            size="m"
            options={dayMonthOptions.map((item) => ({
              ...item,
              label: translations(item.label),
            }))}
            placeholder={translations('chooseAnOption')}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('category')} id="categoryId">
          <Select
            {...categoryId}
            size="m"
            placeholder={translations('chooseAnOption')}
            options={categoryCostTypesOptions}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('type')} id="typeId">
          <Select
            {...typeId}
            isDisabled={!categoryId.value}
            size="m"
            placeholder={translations('chooseAnOption')}
            options={getTypeCostTypesOptions(categoryId.value)}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('subType')} id="subtypeId">
          <Select
            {...subtypeId}
            isDisabled={!typeId.value}
            size="m"
            placeholder={translations('chooseAnOption')}
            options={getSubtypeCostTypesOptions(categoryId.value, typeId.value)}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('nomination')} id="name">
          <TextInput {...name} size="m" placeholder={translations('nomination')} />
        </FieldWrapper>
        <FieldWrapper label={translations('newNomination')} id="name2">
          <TextInput {...name2} size="m" placeholder={translations('newNomination')} />
        </FieldWrapper>
        <FieldWrapper label={translations('quoteDate')} id="date">
          <Flatpickr
            {...externalSourceDate}
            className="mc-text-input"
            options={{
              mode: 'range',
              dateFormat: 'm/d/Y',
              locale: { firstDayOfWeek: 1 },
            }}
            placeholder={translations('chooseAnOption')}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('value')} id="value">
          <TextInput {...value} size="m" placeholder={translations('value')} />
        </FieldWrapper>
        <FieldWrapper label={translations('Регион')} id="region">
          <Autocomplete
            {...region}
            emptyMessageText={translations('notFound')}
            placeholder={translations('chooseAnOption')}
            listBoxItems={regionList}
            handleSearch={setSearchValue}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('unit')} id="measure">
          <Select
            {...measure}
            size="m"
            placeholder={translations('chooseAnOption')}
            options={measureUnitList}
          />
        </FieldWrapper>
      </View>
    </Layer>
  );
};

export default FiltersPopup;
