import { Translations } from 'translations';

export function gtmFocusField({
  t,
  label,
  reasons,
}: {
  t: Translations;
  label: string;
  reasons: string[] | null;
}) {
  window.dataLayer.push({
    event: 'LeroyMerlin',
    eventCategory: 'focus-field', // действие-элемент
    eventAction: t('requestCreatingHeader'), // блок, в котором находится кнопка
    eventLabel: label, // заголовок поля
    eventVariant: reasons, // при выборе причины, иначе передавать null
  });
}

export function gtmClickCreateRequest({
  t,
  requestId,
  reasons,
}: {
  t: Translations;
  requestId: string;
  reasons: string[];
}) {
  window.dataLayer.push({
    event: 'LeroyMerlin',
    eventCategory: 'click-button', // действие-элемент
    eventAction: t('requestCreatingHeader'), // блок, в котором находится кнопка
    eventLabel: t('saveAsDraft'), // текст на кнопке
    eventVariant: reasons, // при выборе причины, иначе передавать null
    eventRequestID: requestId, // ID запроса
  });
}
