import { useMemo } from 'react';
import { useNomenclature } from 'store/cbd/nomenclature';
import { StringSelectItem } from 'common/types';

const useNomenclatureOptions = (itemGroupOptionsWatcher: [string, string, string]) => {
  const {
    data: { groups, dep, classData, subClass },
  } = useNomenclature();

  const groupsOptions = useMemo(
    () =>
      groups.map((item) => ({
        value: item.id,
        label: item.label,
      })),
    [groups]
  );

  const depOptions = useMemo(() => {
    const [itemGroupValue] = itemGroupOptionsWatcher;

    return dep.reduce((prev: StringSelectItem[], curr) => {
      if (itemGroupValue === curr.groupId) {
        prev.push({ value: curr.id, label: curr.label });
      }
      return prev;
    }, []);
  }, [dep, itemGroupOptionsWatcher]);

  const classDataOptions = useMemo(() => {
    const [itemGroupValue, itemDepValue] = itemGroupOptionsWatcher;

    return classData.reduce((prev: StringSelectItem[], curr) => {
      const isDepSelected = itemGroupValue === curr.groupId && itemDepValue === curr.departmentId;
      if (isDepSelected) {
        prev.push({ value: curr.id, label: curr.label });
      }
      return prev;
    }, []);
  }, [classData, itemGroupOptionsWatcher]);

  const subClassOptions = useMemo(() => {
    const [itemGroupValue, itemDepValue, itemClassValue] = itemGroupOptionsWatcher;

    return subClass.reduce((prev: StringSelectItem[], curr) => {
      const isClassDataSelected =
        itemGroupValue === curr.groupId &&
        itemDepValue === curr.departmentId &&
        itemClassValue === curr.classId;
      if (isClassDataSelected) {
        prev.push({ value: curr.id, label: curr.label });
      }
      return prev;
    }, []);
  }, [itemGroupOptionsWatcher, subClass]);

  return {
    groupsOptions,
    depOptions,
    classDataOptions,
    subClassOptions,
  };
};

export default useNomenclatureOptions;
