import { FilterName } from 'pages/Requests/types';
import { createSlice } from '@reduxjs/toolkit';
import { IFilter } from 'common/types';
import { RootState } from '../types';
import { useAppSelector } from '../hooks';

interface SliceState {
  filters: IFilter<FilterName>[];
  deletedFilters: FilterName[];
  searchValue: string;
}

const initialState = {
  filters: [],
  deletedFilters: [],
  searchValue: '',
};

export const filtersSlice = createSlice({
  initialState,
  name: 'filters',
  reducers: {
    clearDeletedFilters(state) {
      state.deletedFilters = [];
    },
    changeDeletedFilters(state, action) {
      state.deletedFilters = state.deletedFilters.concat(action.payload);
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    resetFilterStore() {
      return initialState;
    },
  },
});

export const {
  setFilters,
  setSearchValue,
  changeDeletedFilters,
  clearDeletedFilters,
  resetFilterStore,
} = filtersSlice.actions;

export const useFiltersData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.requests.filters) };
};
