import { useCallback } from 'react';
import { useAppTranslations } from 'translations';
import { createColumns } from 'common/utils';
import { useAppDispatch } from 'store/hooks';

import { useForm } from 'react-hook-form';
import { IDataTableColumn, SortDirection } from 'common/types';
import { IExternalData } from 'api/cbd/externalSource';
import {
  setExternalDataSortable,
  setVisibleExternalDataColumns,
} from 'store/cbd/externalData/columns';
import { ExternalDataColumns } from './consts';
import { useGetColumnTemplate } from './helpers';
import { IExternalDataColumnSort } from '../PriceOnComponents.types';

type ExternalDataSortConfig = {
  [key in ExternalDataColumns]: IExternalDataColumnSort | null;
};

export const sortConfig: ExternalDataSortConfig = {
  [ExternalDataColumns.ID]: 'extDataCost.id',
  [ExternalDataColumns.EXT_DATA_TYPE]: 'extDataCost.extDataType',
  [ExternalDataColumns.CREATED_AT]: 'extDataCost.createdAt',
  [ExternalDataColumns.PERIOD_TYPE]: 'extDataCost.periodType',
  [ExternalDataColumns.UPDATED_AT]: 'extDataCost.updatedAt',
  [ExternalDataColumns.SOURCE]: 'extDataCost.source',
  [ExternalDataColumns.SOURCE_TYPE]: 'extDataCost.sourceType',
  [ExternalDataColumns.CATEGORY_ID]: 'extDataCost.categoryId',
  [ExternalDataColumns.TYPE_ID]: 'extDataCost.typeId',
  [ExternalDataColumns.SUB_TYPE_ID]: 'extDataCost.subtypeId',
  [ExternalDataColumns.NAME]: 'extDataCost.name',
  [ExternalDataColumns.SECOND_NAME]: 'extDataCost.name2',
  [ExternalDataColumns.DATE]: 'extDataCost.date',
  [ExternalDataColumns.VALUE]: 'extDataCost.value',
  [ExternalDataColumns.REGION]: 'extDataCost.region',
  [ExternalDataColumns.MEASURE]: 'extDataCost.measure',
  [ExternalDataColumns.CURRENCY]: 'extDataCost.currencyCode',
};

export const useGetColumns = (): {
  columns: Record<ExternalDataColumns, IDataTableColumn<IExternalData>>;
} => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const handleSort = (field: IExternalDataColumnSort, sortDirection: SortDirection) => {
    dispatch(setExternalDataSortable({ field, sortDirection }));
  };

  const columnTemplate = useGetColumnTemplate();

  return {
    columns: createColumns<ExternalDataColumns, IExternalData, IExternalDataColumnSort>({
      handleSort,
      columnTemplate,
      translations,
      sortConfig,
    }),
  };
};

export const useColumnsForm = () => {
  const dispatch = useAppDispatch();

  const form = useForm<Record<ExternalDataColumns, boolean>>({
    defaultValues: {
      id: true,
      extDataType: true,
      sourceType: true,
      source: true,
      periodType: true,
      createdAt: true,
      updatedAt: true,
      categoryId: true,
      typeId: true,
      subtypeId: true,
      name: true,
      name2: true,
      date: true,
      value: true,
      region: true,
      measure: true,
      currencyCode: true,
    },
  });

  const saveColumnsFilter = useCallback(() => {
    form.handleSubmit((data) => {
      dispatch(setVisibleExternalDataColumns(data));
    })();
  }, [dispatch, form]);

  const resetColumnsFilters = () => {
    dispatch(setVisibleExternalDataColumns(form.getValues()));
    form.reset();
  };

  return { form, saveColumnsFilter, resetColumnsFilters };
};
