import React from 'react';
import {
  View,
  Flex,
  Button,
  Tabs,
  TabsList,
  TabsButton,
  TabsPanel,
  useNotifications,
} from '@mozaic-ds/react';
import { DownloadWeb24 } from '@mozaic-ds/icons/react';
import { useAppTranslations } from 'translations';
import FileUploader from 'components/FileUploader/FileUploader';
import styles from './EditTemplateForm.module.scss';
import useEditTemplateActions from './hooks/useActions';
import AdditionPanel from './components/AdditionPanel/AdditionPanel';
import DeletionPanel from './components/DeletionPanel/DeletionPanel';

const EditTemplateForm = () => {
  const translations = useAppTranslations();
  const {
    onDownloadTemplate,
    templateCbdFiles,
    templateCbdUploadFile,
    handleChange,
    handleDelete,
  } = useEditTemplateActions();
  const { danger } = useNotifications();

  return (
    <View
      className={styles.wrapper}
      paddingTop="mu150"
      paddingBottom="mu200"
      paddingLeft="mu200"
      paddingRight="mu200"
      marginBottom="mu150"
    >
      <Tabs defaultTab="tab1">
        <Flex justifyContent="space-between" alignItems="flex-start">
          <TabsList>
            <TabsButton tab="tab1">{translations('addition')}</TabsButton>
            <TabsButton tab="tab2">{translations('removal')}</TabsButton>
          </TabsList>
          <Flex justifyContent="space-around">
            <Button
              leftIcon={<DownloadWeb24 />}
              onClick={onDownloadTemplate}
              className="mu-mr-100"
              variant="bordered"
              theme="neutral"
            >
              {translations('downloadTemplate')}
            </Button>
            {templateCbdFiles && (
              <FileUploader
                id="templateCbdFile"
                files={templateCbdFiles?.value}
                onChange={handleChange}
                onDeleteFile={handleDelete}
              >
                {translations('uploadCBDTemplate')}
              </FileUploader>
            )}
            {Boolean(templateCbdFiles?.value?.length) && (
              <Button
                type="button"
                className="mu-ml-100"
                onClick={() =>
                  templateCbdUploadFile()
                    .then(() => {
                      return handleDelete(templateCbdFiles.value[0]?.id);
                    })
                    .catch(() => {
                      danger({
                        title: translations('dangerNotification'),
                        message: translations('anErrorOccurred'),
                      });
                    })
                }
                size="m"
                theme="primary-02"
                variant="bordered"
              >
                {translations('upload')}
              </Button>
            )}
          </Flex>
        </Flex>
        <TabsPanel tab="tab1">
          <AdditionPanel />
        </TabsPanel>
        <TabsPanel tab="tab2">
          <DeletionPanel />
        </TabsPanel>
      </Tabs>
    </View>
  );
};

export default EditTemplateForm;
