import React from 'react';
import { FormProvider } from 'react-hook-form';
import Requests from 'pages/Requests/Requests';
import { useInitFiltersForm } from 'pages/Requests/hooks/filters';
import { useRequests } from 'pages/Requests/hooks/requests';

const RequestsContainer = () => {
  const form = useInitFiltersForm();

  useRequests();

  return (
    <FormProvider {...form}>
      <Requests />
    </FormProvider>
  );
};

export default RequestsContainer;
