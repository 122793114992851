import React, { FC } from 'react';
import { Flex, Button } from '@mozaic-ds/react';
import { useFormContext } from 'react-hook-form';
import { useAppTranslations } from 'translations';
import { useGetDependenceOfButtons } from 'pages/ProfileRightsEditor/partials/Replacement/hooks';
import { useUserData } from 'store/auth/user';
import { getHasGrants } from 'common/utils';
import { ReplacementFormValue } from './types';

type ButtonsProps = {
  handleReset: () => void;
  submitForm: (formData: ReplacementFormValue) => Promise<void>;
  defaultValues: ReplacementFormValue;
};

const Buttons: FC<ButtonsProps> = ({ handleReset, defaultValues, submitForm }) => {
  const { watch, handleSubmit } = useFormContext<ReplacementFormValue>();
  const translations = useAppTranslations();
  const { isDefaultNomenclatureLinksChanged, isSupplierLinksChanged } = useGetDependenceOfButtons(); // переделать

  const { userGrants } = useUserData().user;
  const hasGrant = getHasGrants(userGrants);
  const { AdminNomenclatureLinksWrite } = hasGrant;

  const watchAllFields = JSON.stringify(watch()) === JSON.stringify(defaultValues);

  return (
    <Flex justifyContent="flex-end" paddingTop="mu150">
      <Button
        isDisabled={
          !AdminNomenclatureLinksWrite ||
          (watchAllFields && isSupplierLinksChanged && isDefaultNomenclatureLinksChanged) // переделать
        }
        type="submit"
        className="mu-mr-100"
        variant="bordered"
        size="m"
        width="fit"
        onClick={handleSubmit(submitForm)}
      >
        {translations('saveChanges')}
      </Button>
      <Button
        isDisabled={
          !AdminNomenclatureLinksWrite ||
          (watchAllFields && isSupplierLinksChanged && isDefaultNomenclatureLinksChanged) // переделать!
        }
        theme="neutral"
        variant="bordered"
        size="m"
        width="fit"
        onClick={handleReset}
      >
        {translations('resetChanges')}
      </Button>
    </Flex>
  );
};
export default Buttons;
