import { IGetExternalDataListQuery } from 'pages/cbd/ExternalData/ExternalData.types';
import { useEffect, useMemo } from 'react';
import { useColumnsData } from 'store/cbd/externalData/columns';
import { useFiltersData } from 'store/cbd/externalData/filters';
import {
  clearExtTableDataState,
  getExtData,
  useExtTableData,
} from 'store/cbd/externalData/tableData';
import { useAppDispatch } from 'store/hooks';

export const useQueryParams = () => {
  const { filters } = useFiltersData();
  const dispatch = useAppDispatch();
  const { pageNumber } = useExtTableData();
  const { sortedField } = useColumnsData().data;

  const queryParams: IGetExternalDataListQuery = useMemo(() => {
    const params: IGetExternalDataListQuery = filters.reduce((prev, curr) => {
      if (curr.id === 'externalSourceDate') {
        const [dateFrom, dateTo] = curr.value;

        return {
          ...prev,
          dateFrom,
          dateTo,
        };
      }

      if (curr.value) {
        return {
          ...prev,
          [curr.id]: curr.value,
        };
      }

      return prev;
    }, {});

    if (sortedField) {
      params.sortDirection = sortedField.sortDirection?.toUpperCase() || '';
      params.sortValue = sortedField.field;
    }

    return params;
  }, [filters, sortedField]);

  useEffect(() => {
    dispatch(clearExtTableDataState());
    dispatch(getExtData({ page: pageNumber, size: 50, ...queryParams }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  return { queryParams };
};
