import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IGetCompareCommonPayload, getCompareCommonReq } from 'api/cbd/cbdAnalysis';
import { IChartsExtends } from 'common/cbd/types';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

interface SliceState {
  data: IChartsExtends[];
}

const initialState: SliceState = {
  data: [],
};

export const getCompareCommon = createAsyncThunk(
  'cbd/cbdAnalysis/getCompareCommon',
  async (payload: IGetCompareCommonPayload, { rejectWithValue }) => {
    try {
      return await getCompareCommonReq(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const compareCommonSlice = createSlice({
  initialState,
  name: 'compareCommon',
  reducers: {
    clearCompareCommon: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCompareCommon.fulfilled,
      (state: SliceState, action: PayloadAction<IChartsExtends[]>) => {
        state.data = action.payload;
      }
    );
  },
});

export const { clearCompareCommon } = compareCommonSlice.actions;

export const useCompareCommonData = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.cbdAnalysis.compare.common);
};
