import { Translations } from 'translations';
import { Grants } from '@ppm/ppm-platform-user-service';

type Key = keyof Record<Grants, string>;
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const getButtonText = (t: Translations, key: Key) => {
  return (
    {
      [Grants.PricelistTransitionNewToGropingPrices]: t('formPriceListXLS'),
      [Grants.PricelistTransitionXlsxFormedToGroupingPrices]: t('priceListPdfForm'),
      [Grants.PricelistTransitionPdfFormedToSending]: t('priceListSupplierSending'),
      [Grants.PricelistTransitionErrorToPdfFormed]: t('backToPreviousStep'),
      [Grants.PricelistTransitionRejectToPdfFormed]: t('backToPreviousStep'),
      [Grants.PricelistTransitionXLSXFormedToNew]: t('backToStatusNew'),
      [Grants.PricelistTransitionPDFFormedToNew]: t('backToStatusNew'),
    } as any
  )[key];
};
