import { combineReducers } from '@reduxjs/toolkit';
import { requestsSlice } from './requests';
import { filtersSlice } from './filters';
import { columnsSlice } from './columns';

export const requests = combineReducers({
  requests: requestsSlice.reducer,
  filters: filtersSlice.reducer,
  columns: columnsSlice.reducer,
});
