import React, { useCallback } from 'react';
import { RemovableTag } from '@mozaic-ds/react';
import { useReasonsData } from 'store/dictionary/reasons';
import { useStatusData } from 'store/dictionary/status';
import { useAppDispatch } from 'store/hooks';
import { changeDeletedFilters, setFilters, useFiltersData } from 'store/requests/filters';
import { useUserData } from 'store/auth/user';
import { formattedFilterValue } from 'pages/Requests/partials/helpers';

const Tags = () => {
  const dispatch = useAppDispatch();
  const { reasons } = useReasonsData().data;
  const { status: statuses } = useStatusData().data;
  const { filters } = useFiltersData().data;
  const { lang } = useUserData().user;

  const handleRemoveTag = useCallback(
    (id: string): void => {
      dispatch(changeDeletedFilters([id]));
      dispatch(setFilters(filters.filter((item) => item.id !== id)));
    },
    [dispatch, filters]
  );

  return (
    <>
      {filters.map((tag) => (
        <RemovableTag key={tag.id} onRemove={() => handleRemoveTag(tag.id)}>
          {formattedFilterValue(tag, { statuses, reasons, lang })}
        </RemovableTag>
      ))}
    </>
  );
};

export default Tags;
