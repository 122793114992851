import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initPageable } from 'common/consts';
import { IGetItemGroupPayload, IGetItemGroupResponse } from 'common/cbd/types';
import { useAppSelector } from 'store/hooks';
import { getItemGroup } from 'api/cbd/cbd';
import { setFailure, setLoading } from '../../helpers';
import { RequiredStateFields, RootState } from '../../types';

interface SliceState extends RequiredStateFields, IGetItemGroupResponse {
  selectedRowsId: string[];
}

const initialState = {
  selectedRowsId: [],
  data: [],
  pageable: initPageable,
  isLoading: false,
  isFailure: false,
};

export const getBoundList = createAsyncThunk(
  'cbd/getBoundList',
  async (payload: IGetItemGroupPayload, { rejectWithValue }) => {
    try {
      return await getItemGroup(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const boundList = createSlice({
  initialState,
  name: 'list',
  reducers: {
    setSelectedRowsId: (state, action) => {
      state.selectedRowsId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBoundList.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getBoundList.fulfilled,
        (state: SliceState, action: PayloadAction<IGetItemGroupResponse>) => {
          state.data = action.payload.data;
          state.pageable = action.payload.pageable;
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getBoundList.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const { setSelectedRowsId } = boundList.actions;

export const useBoundList = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.bounds.list);
};
