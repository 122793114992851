export const myRoutes = [
  { path: '/request/:id' },
  { path: '/new-price-list/:requestId' },
  { path: '/:requestId/price-list/:priceListId' },
  { path: '/rights-replacement/:name/:ldap' },
  { path: '/uploads/:reqId' },
  { path: '/uploads/:reqId/bound/:cbdId' },
  { path: '/cbd/:cbdId' },
  { path: '/cbd-analysis/:cbdId' },
];
