import React from 'react';
import { Button, Select, View } from '@mozaic-ds/react';
import { DisplayFilter24 } from '@mozaic-ds/icons/react';
import Layer from 'components/Layer/Layer';
import { useAppTranslations } from 'translations';
import FieldWrapper from 'components/FieldWrapper/FieldWrapper';
import useCostTypesOptions from 'pages/cbd/hooks/useCostTypesOptions';
import {
  componentsCostFiltersName,
  useComponentsCostFiltersForm,
} from './useComponentsCostFiltersForm';

const ComponentsCostFilters = (): JSX.Element => {
  const translations = useAppTranslations();
  const { form, categoryId, typeId, subtypeId, onSubmit } = useComponentsCostFiltersForm();
  const { categoryCostTypesOptions, getTypeCostTypesOptions, getSubtypeCostTypesOptions } =
    useCostTypesOptions();

  return (
    <Layer
      id="componentsCostFilterPopup"
      icon={<DisplayFilter24 fill="#71706b" />}
      title={translations('filterPopupHeader')}
      resetItems={() => {
        form.reset();
        componentsCostFiltersName.forEach((id) => form.setValue(id, ''));
      }}
      saveItems={onSubmit}
      button={
        <Button leftIcon={<DisplayFilter24 />} variant="bordered" size="s">
          {translations('filters')}
        </Button>
      }
    >
      <View as="form" paddingLeft="mu025" paddingRight="mu025">
        <FieldWrapper label={translations('category')} id="categoryId">
          <Select {...categoryId} placeholder=" " options={categoryCostTypesOptions} />
        </FieldWrapper>
        <FieldWrapper label={translations('type')} id="typeId">
          <Select
            {...typeId}
            placeholder=" "
            isDisabled={!categoryId.value}
            options={getTypeCostTypesOptions(categoryId.value)}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('subType')} id="subtypeId">
          <Select
            {...subtypeId}
            placeholder=" "
            isDisabled={!typeId.value}
            options={getSubtypeCostTypesOptions(categoryId.value, typeId.value)}
          />
        </FieldWrapper>
      </View>
    </Layer>
  );
};

export default ComponentsCostFilters;
