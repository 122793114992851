import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCbdInfoRequest, ICbdInfo } from 'api/cbd/cbdInfo';
import { useAppSelector } from 'store/hooks';
import { RequiredStateFields, RootState } from 'store/types';
import { setFailure, setLoading } from '../helpers';

interface SliceState extends RequiredStateFields {
  data: ICbdInfo | null;
}

const initialState = {
  data: null,
  isLoading: false,
  isFailure: false,
};

export const getCbdInfo = createAsyncThunk(
  'cbd/getCbdInfo',
  async (payload: string, { rejectWithValue }) => {
    try {
      return await getCbdInfoRequest(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const cbdInfoSlice = createSlice({
  initialState,
  name: 'cbdInfo',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCbdInfo.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(getCbdInfo.fulfilled, (state: SliceState, action: PayloadAction<ICbdInfo>) => {
        state.data = action.payload;
        state.isLoading = false;
        state.isFailure = false;
      })
      .addCase(getCbdInfo.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const useCbdInfo = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.cbdInfo);
};
