import React, { FC } from 'react';
import { SupplierLinkUser } from '@ppm/ppm-platform-user-service';
import { AccountProfileView32, PublishEdit32 } from '@mozaic-ds/icons/react';
import { Flex, TextTag, Text, View } from '@mozaic-ds/react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { clearOffset } from 'store/dictionary/supplierIds';
import { useAppDispatch } from 'store/hooks';
import { clear } from 'store/users/users';
import styles from './Card.module.scss';

type CardProps = Pick<SupplierLinkUser, 'name' | 'ldap'>;

const Card: FC<CardProps> = ({ ldap, name }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <Flex
      radius="m"
      direction="row"
      wrap="nowrap"
      alignItems="center"
      justifyContent="space-between"
      className={cn(styles.card, 'mu-mt-100')}
      paddingBottom="mu050"
      paddingTop="mu050"
      paddingLeft="mu050"
      paddingRight="mu200"
    >
      <Flex>
        <View className={cn(styles.circle, 'mu-p-050 mu-mr-075')}>
          <AccountProfileView32 fill="white" />
        </View>
        <Flex direction="column">
          {name && (
            <Text theme="primary-02" size="s" weight="semi-bold">
              {name}
            </Text>
          )}
          <TextTag size="s">{ldap}</TextTag>
        </Flex>
      </Flex>
      <PublishEdit32
        className={styles.icon}
        onClick={() => {
          dispatch(clear());
          dispatch(clearOffset());
          navigate(`/rights-replacement/${name}/${ldap}`);
        }}
      />
    </Flex>
  );
};
export default Card;
