import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchUsersResponse } from '@ppm/ppm-platform-api';
import { getUsers as receiveUsers } from 'api/users';
import { setFailure, setLoading } from 'store/helpers';
import { RequiredStateFields, RootState } from 'store/types';
import { useAppSelector } from 'store/hooks';

interface SliceState extends RequiredStateFields {
  users: SearchUsersResponse['data'];
  searchText: string;
}

export const getUsers = createAsyncThunk(
  'dashboard/getUsers',
  async (args: { text: string; signal?: AbortSignal }, { rejectWithValue }) => {
    try {
      return await receiveUsers({ conf: { params: { text: args.text }, signal: args.signal } });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const usersSlice = createSlice({
  initialState: { users: [], searchText: '', isLoading: false, isFailure: false },
  name: 'users',
  reducers: {
    clear(state: SliceState) {
      state.users = [];
      state.searchText = '';
    },
    setSearchValue(state: SliceState, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(getUsers.fulfilled, (state: SliceState, action) => {
        const { meta, payload } = action;
        state.users = payload.data;
        state.searchText = state.searchText || meta.arg.text;
        state.isLoading = false;
        state.isFailure = false;
      })
      .addCase(getUsers.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const { clear, setSearchValue } = usersSlice.actions;

export const useUsers = (): { data: SliceState } => {
  return {
    data: useAppSelector((state: RootState) => state.users.users),
  };
};
