import { SuppliersInfo } from '@ppm/ppm-platform-api';
import { SelectItem } from 'common/types';

export const formatSuppliers = (data: SuppliersInfo[]): SelectItem[] => {
  return data
    .map(({ supplierDepartments }) => {
      return supplierDepartments.map(({ name, id }) => {
        return {
          value: id,
          label: `${id} - ${name}`,
        };
      });
    })
    .flat();
};

export const formatSuppliersBsg = (data: SuppliersInfo[]): SelectItem[] => {
  return data.map(({ supplierDepartments, supplierId }) => {
    return {
      value: supplierId,
      label: `${supplierId} ${supplierDepartments[0].name}`,
    };
  });
};
