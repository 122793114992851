import { combineReducers } from '@reduxjs/toolkit';
import { linkedSuppliersIdSlice } from 'store/dictionary/linkedSuppliersId';
import { supplierIdsSlice } from './supplierIds';
import { reasonsSlice } from './reasons';
import { statusSlice } from './status';
import { dueDateSlice } from './dueDate';

export const dictionary = combineReducers({
  linkedSuppliersId: linkedSuppliersIdSlice.reducer,
  supplierIds: supplierIdsSlice.reducer,
  reasons: reasonsSlice.reducer,
  status: statusSlice.reducer,
  dueDate: dueDateSlice.reducer,
});
