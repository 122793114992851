import { apiCreator } from 'api/index';
import {
  GetSupplierLinkUsersResponse,
  GetSupplierLinkUsers,
  GetSupplierLink,
  GetSupplierLinkResponse,
  UpsertSupplierLinks,
  UpsertSupplierLinksBody,
  UpsertSupplierLinksResponse,
} from '@ppm/ppm-platform-api';

export const getSupplierLinkUser = apiCreator<
  typeof GetSupplierLinkUsers,
  GetSupplierLinkUsersResponse
>(GetSupplierLinkUsers)[GetSupplierLinkUsers.method];

export const getSupplierLink = apiCreator<typeof GetSupplierLink, GetSupplierLinkResponse>(
  GetSupplierLink
)[GetSupplierLink.method];

export const setSupplierLink = apiCreator<
  typeof UpsertSupplierLinks,
  UpsertSupplierLinksResponse,
  undefined,
  UpsertSupplierLinksBody
>(UpsertSupplierLinks)[UpsertSupplierLinks.method];
