import { string, object, array, number } from 'yup';
import { getOtherSelectValue } from 'store/dictionary/reasons';
import { SelectItem } from 'common/types';
import { Translations } from 'translations';

export const getSchema = (reason: SelectItem[], t: Translations) =>
  object()
    .shape({
      reasonListId: array()
        .test((values) => {
          if (values) {
            return values.every((inputValue) =>
              reason.find(({ value }) => value === inputValue.value)
            );
          }
          return false;
        })
        .required('This field is required'),
      customReason: string().when('reasonListId', {
        is: (reasonListId: SelectItem[]) => {
          return reasonListId.find((item) => item.value === getOtherSelectValue(t).value);
        },
        then: (schema) => schema.min(5).required('This field is required'),
        otherwise: (schema) => schema.notRequired(),
      }),
      supplierId: object({ value: number().required(), label: string() }).required(
        'This field is required'
      ),
      dueDate: string().required('This field is required'),
    })
    .required();
