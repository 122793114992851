import { apiCreator } from 'api/index';
import {
  UpdatePricelist,
  PricelistCollapsedItems,
  PricelistCollapsedItemsResponse,
  CreatePricelist,
  CreatePricelistBody,
  CreatePricelistResponse,
  GetPricelist,
  GetPricelistResponse,
  GetPricelists,
  GetPricelistsResponse,
  ExecutePricelistFlow,
  ExecutePricelistFlowResponse,
  PricelistCollapsedItemsCount,
  PricelistCollapsedItemsCountResponse,
  GetNextPricelistStartDate,
  CheckPossibilityCreationPricelist,
} from '@ppm/ppm-platform-api';

export const executePricelistFlow = apiCreator<
  typeof ExecutePricelistFlow,
  ExecutePricelistFlowResponse,
  Parameters<(typeof ExecutePricelistFlow)['url']>
>(ExecutePricelistFlow)[ExecutePricelistFlow.method];

export const getCurrentPricelist = apiCreator<
  typeof GetPricelist,
  GetPricelistResponse,
  Parameters<(typeof GetPricelist)['url']>
>(GetPricelist, { headers: { 'Cache-Control': 'no-cache' } })[GetPricelist.method];

export const getPricelist = apiCreator<
  typeof GetPricelists,
  GetPricelistsResponse,
  Parameters<(typeof GetPricelists)['url']>
>(GetPricelists)[GetPricelists.method];

export const createPricelist = apiCreator<
  typeof CreatePricelist,
  CreatePricelistResponse,
  Parameters<(typeof CreatePricelist)['url']>,
  CreatePricelistBody
>(CreatePricelist)[CreatePricelist.method];

export const getPricelistCollapsedItems = apiCreator<
  typeof PricelistCollapsedItems,
  PricelistCollapsedItemsResponse,
  Parameters<(typeof PricelistCollapsedItems)['url']>
>(PricelistCollapsedItems)[PricelistCollapsedItems.method];

export const getPricelistCollapsedItemsCount = apiCreator<
  typeof PricelistCollapsedItemsCount,
  PricelistCollapsedItemsCountResponse,
  Parameters<(typeof PricelistCollapsedItemsCount)['url']>
>(PricelistCollapsedItemsCount)[PricelistCollapsedItemsCount.method];

export const updatePricelist = apiCreator<
  typeof UpdatePricelist,
  UpdatePricelist.Response,
  Parameters<(typeof UpdatePricelist)['url']>,
  UpdatePricelist.Body
>(UpdatePricelist)[UpdatePricelist.method];

export const getNextPricelistStartDate = apiCreator<
  typeof GetNextPricelistStartDate,
  GetNextPricelistStartDate.Response,
  Parameters<(typeof GetNextPricelistStartDate)['url']>
>(GetNextPricelistStartDate)[GetNextPricelistStartDate.method];

export const checkPossibilityCreationPricelist = apiCreator<
  typeof CheckPossibilityCreationPricelist,
  CheckPossibilityCreationPricelist.Response,
  Parameters<(typeof CheckPossibilityCreationPricelist)['url']>
>(CheckPossibilityCreationPricelist)[CheckPossibilityCreationPricelist.method];
