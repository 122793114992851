import { AxiosResponse } from 'axios';
import {
  IColumnTemplateStructure,
  IFormatTemplateStructure,
  IFormulaTemplateStructure,
  IGetLatestCbdTemplateInfoResponse,
  INameTemplateStructure,
  INumberTemplateStructure,
  IRowTemplateStructure,
  ITextTemplateStructure,
} from 'common/cbd/types';
import { IGetTemplateStructureQuery } from 'pages/cbd/EditTemplate/components/ParseTable/ParseTable.types';
import { cbdApiClient } from 'api/index';

interface ITemplateStructureRequest<Structure> {
  structure: Structure;
  userId?: number;
}

export interface IDeleteTemplateStructure {
  row?: IRowTemplateStructure;
  column?: IColumnTemplateStructure;
}

interface IAddTemplateStructureDataRequest {
  value: IChangeTemplateStructureData;
  userId?: number;
}

export interface IAddTemplateStructure {
  row?: IRowTemplateStructure;
  column?: IColumnTemplateStructure;
  name?: INameTemplateStructure;
  format?: IFormatTemplateStructure;
  range?: IFormatTemplateStructure;
}

export interface IChangeTemplateStructureData {
  number?: INumberTemplateStructure;
  text?: ITextTemplateStructure;
  formula?: IFormulaTemplateStructure;
}

interface ICreateCatalogCosts {
  code: string;
  name: string;
  parentId: number | null;
  required: boolean;
  unique: boolean;
  mappingSum: Record<string, string> | null;
  mappingCur: Record<string, string> | null;
  errRequiredSumId: number | null;
  errRequiredCurId: number | null;
  primeCost: boolean;
  purchasePrice: boolean;
}

export interface ICreateAttribute {
  id: string;
  name: string;
  parentId?: number | null;
  mapping: string;
  errRequiredId: number | null;
  errRequired?: { id: number };
  required: boolean;
  costId?: number;
}

export interface IUpdateCatalogCosts extends ICreateCatalogCosts {
  id: number;
}

export interface IUpdateAttribute extends ICreateAttribute {
  id: string;
}

export const uploadCbdTemplateRequest = async (formData: FormData) => {
  const { data }: AxiosResponse = await cbdApiClient.post('/api/templates', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return data;
};

// Возвращает информацию последнему темплейту CBD, не предусмотрен выбор
export const getLatestCbdTemplateInfo = async () => {
  const { data }: AxiosResponse<IGetLatestCbdTemplateInfoResponse> = await cbdApiClient.get(
    '/api/templates/latest-info'
  );
  return data;
};
export const downloadCbdTemplate = async () => {
  const { data }: AxiosResponse<ArrayBuffer> = await cbdApiClient.get(
    '/api/templates/latest-download',
    { responseType: 'arraybuffer' }
  );
  return data;
};

export const changeTemplateStructure = async (
  payload: ITemplateStructureRequest<IAddTemplateStructure>
) => {
  const { data } = await cbdApiClient.post('/api/templates/structure', payload);
  return data;
};

export const deleteTemplateStructure = async (
  payload: ITemplateStructureRequest<IDeleteTemplateStructure>
) => {
  const { data } = await cbdApiClient.post('/api/templates/structure/delete', payload);
  return data;
};

export const addTemplateStructureData = async (payload: IAddTemplateStructureDataRequest) => {
  const { data } = await cbdApiClient.post('/api/templates/data', payload);
  return data;
};

export const getTemplateStructure = async (value: IGetTemplateStructureQuery) => {
  const params = Object.keys(value).reduce(
    (prev: IGetTemplateStructureQuery, curr) => ({
      ...prev,
      [`filters[${curr}]`]: value[curr as keyof IGetTemplateStructureQuery],
    }),
    {}
  );

  const { data } = await cbdApiClient.get('/api/templates-structure/structure', { params });
  return data;
};

export const createCatalogCosts = (data: ICreateCatalogCosts) => {
  return cbdApiClient.post('/api/templates-structure/catalogCost', data);
};

export const updateCatalogCosts = (data: IUpdateCatalogCosts[]) => {
  return cbdApiClient.patch('/api/templates-structure/catalogCost/bulkUpdate', { data });
};

export const deleteCatalogCosts = (ids: Array<string>) => {
  return cbdApiClient.delete('/api/templates-structure/catalogCost/bulkDelete', {
    params: { ids },
  });
};

export const createAttributes = (item: ICreateAttribute) => {
  return cbdApiClient.post('/api/templates-structure/catalogAttribute', item);
};

export const updateAttributes = (item: IUpdateAttribute) => {
  return cbdApiClient.patch('/api/templates-structure/catalogAttribute/bulkUpdate', {
    data: [item],
  });
};

export const deleteAttributes = (ids: Array<number>) => {
  return cbdApiClient.delete('/api/templates-structure/catalogAttribute/bulkDelete', {
    params: { ids },
  });
};
