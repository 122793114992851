import { useController, useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { useAppTranslations } from 'translations';
import { SelectItem } from 'common/types';
import { clearCompareDeletedFilters, setCompareFilters } from 'store/cbd/compare/filters';
import { CompareFilterName } from '../CompareTable.types';
import { getLabel } from './consts';

interface IDefaultValues {
  cbdSupplierId: SelectItem | null;
  cbdDate: string;
  itemGroup: string;
  itemDep: string;
  itemClass: string;
  itemSubclass: string;
}

export const useCompareFilterForm = () => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const defaultValues: IDefaultValues = {
    cbdSupplierId: null,
    cbdDate: '',
    itemGroup: '',
    itemDep: '',
    itemClass: '',
    itemSubclass: '',
  };

  const form = useForm({
    defaultValues,
  });

  const { control, handleSubmit } = form;

  const { field: cbdDate } = useController({ name: 'cbdDate', control });
  const { field: cbdSupplierId } = useController({ name: 'cbdSupplierId', control });
  const { field: itemGroup } = useController({ name: 'itemGroup', control });
  const { field: itemDep } = useController({ name: 'itemDep', control });
  const { field: itemClass } = useController({ name: 'itemClass', control });
  const { field: itemSubclass } = useController({ name: 'itemSubclass', control });

  const onSubmit = useCallback(() => {
    handleSubmit((data) => {
      const keys = Object.keys(data);
      dispatch(clearCompareDeletedFilters());
      dispatch(
        setCompareFilters(
          keys
            .map((key) => ({
              id: key,
              value:
                key !== 'cbdSupplierId'
                  ? data[key as CompareFilterName]
                  : data.cbdSupplierId?.value || null,
              label: getLabel(key as CompareFilterName, translations),
            }))
            .filter(({ value }) => (Array.isArray(value) ? value.length > 0 : Boolean(value)))
        )
      );
    })();
  }, [dispatch, handleSubmit, translations]);

  return {
    form,
    cbdDate,
    cbdSupplierId,
    itemGroup,
    itemDep,
    itemClass,
    itemSubclass,
    onSubmit,
  };
};
