import { CbdStatus } from 'common/cbd/types';
import { Translations } from 'translations';
import { UploadsFilterName } from '../Uploads.types';

export enum UploadsColumns {
  NAV_CBD_INFO = 'navCbdInfo',
  NAV_CBD_ANALYSIS = 'navCbdAnalysis',
  ITEM_GROUP = 'itemGroup',
  CBD_ID = 'cbdId',
  CREATED_AT = 'createdAt',
  DATE_CBD = 'dateCbd',
  FILE_LINK = 'fileLink',
  GROUP = 'group',
  STATUS = 'status',
  IS_LOADED = 'isLoaded',
}

export const getLabel = (key: UploadsFilterName, t: Translations) => {
  return {
    cbdId: t('cbdId'),
    cbdDate: t('cbdDate'),
    cbdCreatedAt: t('cbdCreatedAt'),
    fileName: t('fileName'),
    cbdStatus: t('cbdStatus'),
  }[key];
};

export const uploadsFiltersName: UploadsFilterName[] = [
  'cbdId',
  'cbdDate',
  'cbdCreatedAt',
  'fileName',
  'cbdStatus',
];

export const statusOptions = [
  { value: CbdStatus.Approved, label: 'approved' },
  { value: CbdStatus.Declined, label: 'rejected' },
  { value: CbdStatus.NotSeen, label: 'new' },
  { value: CbdStatus.Error, label: 'error' },
];

export const LOAD_ERRORS_MODAL_ID = 'LOAD_ERRORS_MODAL_ID';
