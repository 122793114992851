import { combineReducers } from '@reduxjs/toolkit';
import { latestTemplateSlice } from './templatesCbd';
import { nomenclatureSlice } from './nomenclature';
import { allUploads } from './allUploads';
import { uploads } from './uploads';
import { bounds } from './bound';
import { selectedLoadSlice } from './selectedLoad';
import { suppliersSlice } from './suppliers';
import { scheduler } from './scheduler';
import { compare } from './compare';
import { cbdAnalysis } from './cbdAnalysis';
import { cbdInfoSlice } from './info';
import { loadErrorsSlice } from './loadErrors';
import { cbdSettingsSlice } from './settings';
import { externalData } from './externalData';
import { regionsSlice } from './regions';
import { measureUnitsSlice } from './measureUnits';
import { extCurrencySlice } from './extCurrency';
import { requestStatusesSlice } from './requestStatuses';
import { costTypesSlice } from './costTypes';
import { additionEditTemplateConfigSlice } from './additionEditTemplateConfig';
import { deletionEditTemplateConfigSlice } from './deletionEditTemplateConfig';
import { changeEditTemplateConfigSlice } from './changeEditTemplateConfig';
import { averagePrices } from './averagePrices';
import { extDataNamesSlice } from './extDataNames';
import { templateStructure } from './templateStructure';
import { loadErrorCodesSlice } from './loadErrorCodes';

export const cbd = combineReducers({
  uploads,
  allUploads,
  bounds,
  externalData,
  scheduler,
  cbdAnalysis,
  compare,
  averagePrices,
  templateStructure,
  extDataNames: extDataNamesSlice.reducer,
  nomenclature: nomenclatureSlice.reducer,
  latestTemplate: latestTemplateSlice.reducer,
  selectedLoad: selectedLoadSlice.reducer,
  suppliers: suppliersSlice.reducer,
  cbdInfo: cbdInfoSlice.reducer,
  loadErrors: loadErrorsSlice.reducer,
  cbdSettings: cbdSettingsSlice.reducer,
  regions: regionsSlice.reducer,
  measureUnits: measureUnitsSlice.reducer,
  extCurrency: extCurrencySlice.reducer,
  requestStatuses: requestStatusesSlice.reducer,
  costTypes: costTypesSlice.reducer,
  additionEditTemplateConfig: additionEditTemplateConfigSlice.reducer,
  deletionEditTemplateConfig: deletionEditTemplateConfigSlice.reducer,
  changeEditTemplateConfig: changeEditTemplateConfigSlice.reducer,
  loadErrorCodes: loadErrorCodesSlice.reducer,
});
