import { LoginQuery, LoginResponse } from '@ppm/ppm-platform-api';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAuthLoginPage } from 'api/auth';
import { setFailure, setLoading } from '../helpers';
import { RequiredStateFields } from '../types';

interface SliceState extends RequiredStateFields {
  url: string;
  isNeedLogin: boolean;
}

export const getAuthLogin = createAsyncThunk<LoginResponse, LoginQuery>(
  'login/getAuthLogin',
  async (params, { rejectWithValue }) => {
    try {
      return await getAuthLoginPage({ conf: { params: { ...params } } });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const loginSlice = createSlice({
  initialState: {
    url: '',
    isNeedLogin: true,
    isLoading: false,
    isFailure: false,
  },
  name: 'login',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAuthLogin.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getAuthLogin.fulfilled,
        (state: SliceState, action: PayloadAction<LoginResponse>) => {
          state.url = action.payload;
          state.isNeedLogin = false;
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getAuthLogin.rejected, (state: SliceState) => {
        setFailure(state);
        state.isNeedLogin = true;
      });
  },
});
