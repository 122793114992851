export const emptyExtTableRow = {
  categoryId: null,
  categoryName: '',
  typeId: null,
  typeName: '',
  subtypeId: null,
  subtypeName: '',
  id: -1,
  extDataType: '',
  sourceType: '',
  source: '',
  periodType: '',
  createdAt: '',
  updatedAt: '',
  name: '',
  name2: '',
  date: '',
  value: '',
  region: '',
  measure: '',
  currencyCode: '',
  info: {
    id: -1,
    grade: null,
    diameter: null,
    thickness: null,
    contract: null,
    standard: null,
    priceTypeId: null,
    priceTypeName: null,
  },
  scheduler: null,
  category: null,
  type: null,
  subtype: null,
};

export const DOWNLOAD_LIMIT_EXTERNAL_DATA = 50;
