import { Flex, Text, View } from '@mozaic-ds/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import {
  getCbdAnalysisInfo,
  setStructureType,
  structureTypeValues,
  useCbdAnalysisInfoInfoData,
} from 'store/cbd/cbdAnalysis/cbdAnalysisInfo';
import ToggleWithLabels from 'components/ToogleWithLabels/ToggleWithLabels';
import {
  clearCompareCommon,
  getCompareCommon,
  useCompareCommonData,
} from 'store/cbd/cbdAnalysis/compare/common';
import { useMount } from 'hook/useMount';
import { getCostType } from 'store/cbd/costTypes';
import { usePurchasePriceFiltersData } from 'store/cbd/cbdAnalysis/compare/purchasePrice/filters';
import { useComponentsCostFiltersData } from 'store/cbd/cbdAnalysis/compare/componentsCost/filters';
import {
  clearComparePurchasePrice,
  getComparePurchasePrice,
} from 'store/cbd/cbdAnalysis/compare/purchasePrice/purchasePrice';
import {
  clearCompareComponentCost,
  getCompareComponentsCost,
} from 'store/cbd/cbdAnalysis/compare/componentsCost/componentsCost';
import { IGetComparePurchasePayload, ITraceableComponent } from 'api/cbd/cbdAnalysis';
import CompareTable from 'pages/cbd/CompareTable/CompareTable';
import {
  clearCompareCostGroupsCommon,
  getCompareCostGroups,
} from 'store/cbd/cbdAnalysis/compare/costGroups';
import { useCbdWithItemGroupsList } from 'store/cbd/compare/list';
import { useAppTranslations } from 'translations';
import Comparison from './components/Comparison/Comparison';
import Indicators from './components/Indicators/Indicators';
import styles from './CbdAnalysis.module.scss';

const CbdAnalysis = () => {
  const translations = useAppTranslations();
  const dispatch = useAppDispatch();
  const params = useParams();
  const { data, structureType } = useCbdAnalysisInfoInfoData();
  const { data: compareCommonData } = useCompareCommonData();
  const { filters: purchasePriceFilters } = usePurchasePriceFiltersData();
  const { filters: componentsCostFilters } = useComponentsCostFiltersData();
  const { itemsForCompare } = useCbdWithItemGroupsList();

  useMount(() => {
    dispatch(getCostType());
  });

  const paramsForRequest = useMemo(
    () => ({
      cbdIds: data?.id ? [`${data.id}`, ...itemsForCompare] : itemsForCompare,
      costStructure: structureType,
    }),
    [itemsForCompare, data, structureType]
  );

  useEffect(() => {
    if (paramsForRequest.cbdIds.length) {
      dispatch(getCompareCommon(paramsForRequest));
      dispatch(getCompareCostGroups(paramsForRequest));
    } else {
      dispatch(clearCompareCommon());
      dispatch(clearCompareCostGroupsCommon());
    }
  }, [dispatch, paramsForRequest]);

  useEffect(() => {
    const queryParamsFromPurchasePriceFilter: IGetComparePurchasePayload =
      purchasePriceFilters.reduce((prev: IGetComparePurchasePayload, curr) => {
        if (curr.value) {
          prev[curr.id] = curr.value;
        }

        return prev;
      }, {});

    if (paramsForRequest.cbdIds.length) {
      dispatch(
        getComparePurchasePrice({ ...queryParamsFromPurchasePriceFilter, ...paramsForRequest })
      );
    } else {
      dispatch(clearComparePurchasePrice());
    }
  }, [dispatch, purchasePriceFilters, paramsForRequest]);

  useEffect(() => {
    const queryParamsFromComponentCostFilter: ITraceableComponent = componentsCostFilters.reduce(
      (prev: ITraceableComponent, curr) => {
        if (curr.value) {
          prev[curr.id] = curr.value;
        }

        return prev;
      },
      {}
    );

    if (paramsForRequest.cbdIds.length && Object.keys(queryParamsFromComponentCostFilter).length) {
      dispatch(
        getCompareComponentsCost({
          ...paramsForRequest,
          traceableComponent: queryParamsFromComponentCostFilter,
        })
      );
    } else {
      dispatch(clearCompareComponentCost());
    }
  }, [componentsCostFilters, dispatch, paramsForRequest]);

  useEffect(() => {
    if (params.cbdId) {
      dispatch(
        getCbdAnalysisInfo({
          id: params.cbdId,
          costStructure: structureType,
        })
      );
    }
  }, [dispatch, params.cbdId, structureType]);

  const onStructureChange = useCallback(() => {
    dispatch(
      setStructureType(
        structureType === structureTypeValues[0] ? structureTypeValues[1] : structureTypeValues[0]
      )
    );
  }, [dispatch, structureType]);

  return (
    data && (
      <View marginTop="mu150" className={styles.wrapper}>
        <Flex marginBottom="mu100" justifyContent="space-between">
          <Text size="l" weight="semi-bold">
            № CBD: {data.id} {translations('date')}: {new Date(data.dateCbd).toLocaleDateString()}
          </Text>
          <Flex alignItems="center">
            <Text size="m" weight="semi-bold">
              {translations('costStructure')}
            </Text>
            <ToggleWithLabels
              labels={[translations('atCost'), translations('atFinalPurchasePrice')]}
              activeValue={structureType}
              values={structureTypeValues}
              onChange={onStructureChange}
            />
          </Flex>
        </Flex>
        <Indicators />
        <CompareTable />
        {Boolean(compareCommonData.length) && <Comparison />}
      </View>
    )
  );
};

export default CbdAnalysis;
