import { useFieldArray, useForm } from 'react-hook-form';
import React, { useCallback, useEffect, useMemo } from 'react';
import { setSupplierBuyerSiteGroupLinks } from 'api/buyerSiteGroup';
import {
  getSupplierBuyerSiteGroupLinksData,
  useSupplierBuyerSiteGroupLinks,
} from 'store/buyerSiteGroup/supplierBuyerSiteGroupLinks';
import { useBuyerSiteGroupHierarchy } from 'store/buyerSiteGroup/buyerSiteGroupHierarchy';
import { formatLinksToFormFields } from 'pages/AdminDashboard/pannels/BuyerSiteGroup/helpers';
import { useAppDispatch } from 'store/hooks';
import { Links } from './types';
import { SelectItem } from '../../../../common/types';

export const useBSGForm = () => {
  const dispatch = useAppDispatch();

  const { data: headers } = useBuyerSiteGroupHierarchy().data;
  const { data: rows } = useSupplierBuyerSiteGroupLinks().data;

  const form = useForm<Record<'links', Links[]>>({ defaultValues: { links: [] } });
  const { append, replace, fields, remove } = useFieldArray({
    control: form.control,
    name: 'links',
  });

  useEffect(() => {
    if (fields.length > 0) {
      if (fields.length > rows.length) {
        const supplierDepartmentIds = rows.map(({ supplierDepartmentId }) => supplierDepartmentId);
        const needIndexesToDelete = form.getValues().links.reduce<number[]>((acc, curr, index) => {
          if (!supplierDepartmentIds.includes(curr.supplierDepartmentId)) acc.push(index);
          return acc;
        }, []);

        remove(needIndexesToDelete);
      } else {
        const existedSupplierDepartmentIds = fields.map(
          ({ supplierDepartmentId }) => supplierDepartmentId
        );
        append(
          formatLinksToFormFields({
            links: rows.filter(
              (row) => !existedSupplierDepartmentIds.includes(row.supplierDepartmentId)
            ),
            hierarchy: headers,
          }),
          { shouldFocus: false }
        );
      }
    } else
      append(
        formatLinksToFormFields({
          links: rows,
          hierarchy: headers,
        }),
        { shouldFocus: false }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, append, headers]);

  const defaultFormValue = useMemo<Links[]>(() => {
    return formatLinksToFormFields({
      links: rows,
      hierarchy: headers,
    });
  }, [rows, headers]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const splitTargetName = e.target.name.split('.');
      const indexToTargetRow = Number(splitTargetName[1]);
      const indexToTargetCell = Number(splitTargetName[3]);
      const targetRow = form.getValues().links[indexToTargetRow];
      const targetElement = targetRow.link[indexToTargetCell];
      const { isHub: targetElementIsHub, isFederal: targetElementIsFederal } = targetElement;
      if (e.target.checked) {
        targetRow?.link.forEach(({ isFederal, isHub }, i) => {
          if (targetElementIsFederal && !isFederal && !isHub) {
            form.setValue(`links.${indexToTargetRow}.link.${i}.isChecked`, false);
          } else if (!targetElementIsFederal && !targetElementIsHub && isFederal) {
            form.setValue(`links.${indexToTargetRow}.link.${0}.isChecked`, false);
          }
        });
      }
    },
    [form]
  );

  const handleSubmit = useCallback(
    (suppliers: SelectItem[]) => {
      form.handleSubmit((values) =>
        setSupplierBuyerSiteGroupLinks({
          body: {
            data: values.links.map((item) => ({
              supplierDepartmentId: item.supplierDepartmentId,
              buyerSiteGroupIds: item.link.filter(({ isChecked }) => isChecked).map(({ id }) => id),
            })),
          },
        }).then(() =>
          dispatch(getSupplierBuyerSiteGroupLinksData(suppliers.map(({ value }) => value)))
        )
      )();
    },
    [dispatch, form]
  );

  const watchFields =
    JSON.stringify(
      JSON.parse(JSON.stringify(form.watch().links)).sort(
        (curr: Links, next: Links) => curr.supplierId - next.supplierId
      )
    ) === JSON.stringify(defaultFormValue);

  return {
    handleSubmit,
    handleChange,
    form,
    fields,
    replace,
    watchFields,
    defaultFormValue,
  };
};
