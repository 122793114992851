import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { useReplacementData } from 'store/replacement/replacement';
import { setSupplierLink } from 'api/links/supplierLinks';
import {
  clearAll as clearSupplierStore,
  resetToDefaultValue as resetSupplier,
  useSuppliersData,
} from 'store/dashboard/rightsManagement/supplier';
import { useNavigate, useParams } from 'react-router-dom';
import {
  clearNomenclatureStore,
  useNomenclatureData,
  resetNomenclatureStoreToDefaultValue,
} from 'store/dashboard/rightsManagement/nomenclature';
import {
  clearSupplierLinks,
  useSupplierLink,
} from 'store/dashboard/rightsManagement/supplierLinks';
import { useAppDispatch } from 'store/hooks';
import {
  clearNomenclatureLinks,
  useNomenclatureLink,
} from 'store/dashboard/rightsManagement/nomenclatureLinks';
import { setNomenclatureLink } from 'api/links/nomenclatureLinks';
import {
  formatReplacementFormData,
  formattedNomenclatureLinks,
} from 'pages/ProfileRightsEditor/partials/Replacement/helpers';
import { createReplacement, deleteReplacement } from 'api/replacement';
import { every, isEmpty, isEqual, omit, some } from 'lodash';
import { useUserData } from 'store/auth/user';
import { getHasGrants, parseDateForMozaicComponent } from 'common/utils';
import { clear as clearUserSearchValue } from 'store/users/users';
import { ReplacementFormValue } from './types';
import { schema } from './schema';

export const useReplacementForm = () => {
  const dispatch = useAppDispatch();
  const { ldap } = useParams();
  const navigate = useNavigate();
  const { checkedNomenclatureGroupedByRole } = useNomenclatureData().nomenclature;
  const { checkedSuppliersGroupedByRole } = useSuppliersData().supplier;
  const { data: supplierLinks } = useSupplierLink().supplierLink;
  const { data: nomenclatureLink } = useNomenclatureLink().nomenclatureLink;
  const { data: replacement } = useReplacementData().replacement;
  const { userGrants } = useUserData().user;
  const hasGrant = getHasGrants(userGrants);

  const defaultValues: ReplacementFormValue = useMemo(() => {
    const { replacementLdap, endDate, requestIds, replacementUserName } = replacement[0] || {};
    return {
      replacementLdap: replacementLdap
        ? {
            value: Number(replacementLdap),
            label: replacementUserName,
          }
        : null,
      endDate: endDate ? parseDateForMozaicComponent(new Date(endDate)) : '',
      requestIds: requestIds || [],
      areFullRequests: !requestIds?.length,
    };
  }, [replacement]);

  const form = useForm<ReplacementFormValue>({
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { field: replacementLdap } = useController({
    name: 'replacementLdap',
    control: form.control,
  });
  const { field: endDate } = useController({ name: 'endDate', control: form.control });
  const { field: requestIds } = useController({
    name: 'requestIds',
    control: form.control,
  });
  const { field: areFullRequests } = useController({
    name: 'areFullRequests',
    control: form.control,
  });

  const handleChangeNomenclatureLinks = useCallback(async () => {
    if (nomenclatureLink || checkedNomenclatureGroupedByRole) {
      await setNomenclatureLink({
        body: {
          ldap: ldap as string,
          nomenclatures: formattedNomenclatureLinks(checkedNomenclatureGroupedByRole),
        },
      });
    }
    dispatch(clearNomenclatureStore());
  }, [checkedNomenclatureGroupedByRole, dispatch, ldap, nomenclatureLink]);

  const handleChangeSupplierLinks = useCallback(async () => {
    if (supplierLinks.length > 0 || checkedSuppliersGroupedByRole.length > 0) {
      await setSupplierLink({
        body: {
          ldap: ldap as string,
          supplierLinks: checkedSuppliersGroupedByRole.map(({ roleId, supplierId }) => ({
            supplierId,
            roleId,
          })),
        },
      });
    }
    dispatch(clearSupplierStore({ isClearRole: true }));
  }, [dispatch, checkedSuppliersGroupedByRole, ldap, supplierLinks.length]);

  const handleChangeLinks = useCallback(async () => {
    await handleChangeNomenclatureLinks();
    await handleChangeSupplierLinks();
    dispatch(clearUserSearchValue());
    dispatch(clearSupplierLinks());
    dispatch(clearNomenclatureLinks());
    navigate('/dashboard');
  }, [dispatch, handleChangeNomenclatureLinks, handleChangeSupplierLinks, navigate]);

  const handelSubmit = useCallback(
    async (formData: ReplacementFormValue) => {
      if (formData.replacementLdap && !isEqual(defaultValues, formData))
        await createReplacement({ body: formatReplacementFormData(formData, ldap as string) });
      else if (Boolean(replacement.length) && every(omit(formData, 'areFullRequests'), isEmpty)) {
        const { replacementLdap: replacementUserLdap } = replacement[0] || {};
        await deleteReplacement({
          conf: { data: { ldap: ldap as string, replacementLdap: replacementUserLdap } },
        });
      }
      await handleChangeLinks();
    },
    [defaultValues, handleChangeLinks, ldap, replacement]
  );

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(resetSupplier({ links: supplierLinks }));
    dispatch(resetNomenclatureStoreToDefaultValue({ links: nomenclatureLink }));
  }, [dispatch, form, nomenclatureLink, supplierLinks]);

  return {
    handleReset,
    handelSubmit,
    defaultValues,
    requestIds,
    replacementLdap,
    endDate,
    form,
    areFullRequests,
    isDisabled: !hasGrant.UserManageReplacement,
  };
};

export const useGetDependenceOfButtons = () => {
  const { data: supplierLink } = useSupplierLink().supplierLink;
  const { checkedSuppliersGroupedByRole } = useSuppliersData().supplier;
  const { checkedNomenclatureGroupedByRole } = useNomenclatureData().nomenclature;
  const { data: defaultNomenclatureLinks } = useNomenclatureLink().nomenclatureLink;

  const isSupplierLinksChanged = useMemo(() => {
    return (
      checkedSuppliersGroupedByRole.length === supplierLink.length &&
      checkedSuppliersGroupedByRole.every((item) => some(supplierLink, item))
    );
  }, [checkedSuppliersGroupedByRole, supplierLink]);
  const isDefaultNomenclatureLinksChanged = useMemo((): boolean => {
    if (!defaultNomenclatureLinks && checkedNomenclatureGroupedByRole) return false;
    if (defaultNomenclatureLinks && !checkedNomenclatureGroupedByRole) return false;
    if (!checkedNomenclatureGroupedByRole) return true;

    const checkedNomenclatureKeys = Object.keys(checkedNomenclatureGroupedByRole);
    const isNewNomenclature = checkedNomenclatureKeys.some((key) => {
      return (
        checkedNomenclatureGroupedByRole[key].length !==
        (defaultNomenclatureLinks &&
          defaultNomenclatureLinks[key] &&
          defaultNomenclatureLinks[key].length)
      );
    });
    if (isNewNomenclature) return false;

    const linkKeys = Object.keys(defaultNomenclatureLinks || {});
    const isDeletedNomenclature = linkKeys.some((key) => {
      return (
        (defaultNomenclatureLinks || {})[key].length !==
        (checkedNomenclatureGroupedByRole[key] && checkedNomenclatureGroupedByRole[key].length)
      );
    });
    if (isDeletedNomenclature) return false;

    return linkKeys.every((key) => {
      return (defaultNomenclatureLinks || {})[key].every((path) => {
        return (
          checkedNomenclatureGroupedByRole[key] &&
          checkedNomenclatureGroupedByRole[key].find((item) => item.join() === path.join())
        );
      });
    });
  }, [defaultNomenclatureLinks, checkedNomenclatureGroupedByRole]);
  return {
    isSupplierLinksChanged,
    isDefaultNomenclatureLinksChanged,
  };
};
