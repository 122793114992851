import React, { FC } from 'react';
import CbdView, { ICbdView } from 'pages/cbd/hok/CbdView';
import { useAppDispatch } from 'store/hooks';
import { getAllCbdListData, setSelectedRowsId, useCbdList } from 'store/cbd/allUploads/list';
import { ICbd, IGetCbdListPayload } from 'common/cbd/types';
import { IFilter } from 'common/types';
import { changeDeletedFilters, setFilters, useFiltersData } from 'store/cbd/allUploads/filters';
import { getNomenclature } from 'store/cbd/nomenclature';
import { getCostType } from 'store/cbd/costTypes';
import { AllUploadsColumn, useColumnsData } from 'store/cbd/allUploads/columns';
import { useMount } from 'hook/useMount';
import { useColumnsForm, useGetColumns, sortConfig } from './hooks/columns';
import { AllUploadsColumns, filtersName } from './hooks/consts';
import { ICbdTable } from './AllUploads.types';
import { ExtendsFilters } from '../hok/partials/consts';

const AllUploads: FC = () => {
  const dispatch = useAppDispatch();
  const { columns } = useGetColumns();
  const { filters } = useFiltersData().data;
  const { columns: columnsFilter, sortedField } = useColumnsData().data;
  const { form, saveColumnsFilter, resetColumnsFilters } = useColumnsForm();
  const { data, isLoading, pageable, selectedRowsId } = useCbdList();

  useMount(() => {
    dispatch(getNomenclature());
    dispatch(getCostType());
  });

  const config: ICbdView<ICbd, AllUploadsColumns, AllUploadsColumn, ICbdTable> = {
    uploadFormVisible: true,
    filtersVisible: true,
    searchInputVisible: true,
    isLoading,
    pageFilters: filters,
    onRemoveTag: (idForDelete: string[], filtersForDelete: IFilter<ExtendsFilters>[]) => {
      dispatch(changeDeletedFilters(idForDelete));
      dispatch(setFilters(filtersForDelete));
    },
    resetFilters: () => {
      dispatch(changeDeletedFilters(filtersName));
      dispatch(setFilters([]));
    },
    data: {
      data,
      getData: (params: IGetCbdListPayload) => {
        dispatch(getAllCbdListData(params));
      },
      pageable,
    },
    columnsPopup: { columns, columnsFilter, form, saveColumnsFilter, resetColumnsFilters },
    table: {
      sortConfig,
      columns,
      columnsFilter,
      sortedField,
      selectedRowsId,
      setSelectedRowsId: (selected: string[]) => {
        dispatch(setSelectedRowsId(selected));
      },
    },
  };

  return <CbdView<ICbd, AllUploadsColumns, AllUploadsColumn, ICbdTable> {...config} />;
};

export default AllUploads;
