import React from 'react';
import { PricelistStatusCode } from '@ppm/ppm-platform-pricelist-service';
import { useAppTranslations } from 'translations';
import { Button, INotificationProps, Notification, Text } from '@mozaic-ds/react';
import { getNotificationStatus } from 'pages/PriceList/partials/Notifications/helpers';
import { useCurrentPriceListData } from 'store/priceList/currentPriceList';
import { parseDateForMozaicComponent } from 'common/utils';
import { NotificationPropsByStatusType, NotificationStatuses, NotificationType } from './types';

const getNotificationPropsByStatus = ({
  t,
  priceList,
  handlePriceListFlow,
}: NotificationPropsByStatusType): Record<
  keyof typeof NotificationStatuses,
  INotificationProps
> => {
  const notificationWithComment = `${
    priceList.statusName
  } поставщиком ${parseDateForMozaicComponent(
    new Date(priceList.pricelistEndDate),
    'dd-MM-yyyy'
  )}, с комментарием: "${priceList?.ediData?.statusComment}"`;

  const groupingPricesComponent = (
    <>
      {t('needGeneratePriceListAgain')}
      <Button
        className="mu-ml-075"
        size="s"
        theme="neutral"
        onClick={() => handlePriceListFlow(PricelistStatusCode.GroupingPrices)}
      >
        {t('okay')}
      </Button>
    </>
  );

  return {
    sign: { message: notificationWithComment, theme: 'success' },
    reject: { message: notificationWithComment, theme: 'danger' },
    cancel: { message: notificationWithComment, theme: 'danger' },
    error: {
      message: (
        <>
          {t('priceListErrorMessage')} {priceList.error}
          <Text as="p" weight="semi-bold">
            {t('additionToPriceListError')}
          </Text>
        </>
      ),
      theme: 'danger',
    },
    gropingError: { message: t('groupingErrorNotification'), theme: 'warning' },
    edit: { message: t('priceListActionEditingNotification'), theme: 'warning' },
    generate: { message: groupingPricesComponent, theme: 'danger' },
    initializeError: { message: priceList.error, theme: 'danger' },
  };
};

export const PriceListNotifications = ({ handlePriceListFlow }: NotificationType) => {
  const t = useAppTranslations();
  const { priceList, isEdited, needToGenerateAgain } = useCurrentPriceListData().data;

  const status = getNotificationStatus({
    isEdited,
    needToGenerateAgain,
    priceList,
  });

  if (!status) return null;

  return (
    <Notification
      className="mu-mt-100"
      {...getNotificationPropsByStatus({
        t,
        priceList,
        handlePriceListFlow,
      })[status]}
    />
  );
};
