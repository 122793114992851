import { Dispatch, SetStateAction, useCallback } from 'react';

interface IUseSearchInput {
  onChange: (e: string) => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const useSearchInput = ({ onChange, setIsOpen }: IUseSearchInput) => {
  const handleFilterValueOnChange = useCallback(
    (inputValue: string) => {
      onChange(inputValue);
    },
    [onChange]
  );

  const handleClick = useCallback(
    (itemValue?: string) => {
      if (itemValue === undefined) {
        onChange('');
        setIsOpen(false);
        return;
      }

      onChange(itemValue);
      setIsOpen(false);
      handleFilterValueOnChange(itemValue);
    },
    [handleFilterValueOnChange, onChange, setIsOpen]
  );

  return { handleFilterValueOnChange, handleClick };
};

export default useSearchInput;
