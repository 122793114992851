import React, { useCallback } from 'react';
import { FileType } from '@ppm/ppm-platform-file-service/build/enums';
import FileUploader from 'components/FileUploader/FileUploader';
import { useAppTranslations } from 'translations';
import { isProd } from 'common/consts';
import { useActionsFileForm } from 'hook/useActionsFileForm';
import { getHasGrants } from 'common/utils';
import { useRequestData } from 'store/request/request';
import { Button } from '@mozaic-ds/react';
import { useNavigate } from 'react-router-dom';
import useUploadCbdFilesForm from './hooks';
import { gtmCbdHandleClick } from './gtm';

type Props = {
  id: string;
  isScrollable?: boolean;
  accessibilityToRedirect?: boolean;
};
export const CbdUploader = ({
  id,
  isScrollable = false,
  accessibilityToRedirect = false,
}: Props) => {
  const translations = useAppTranslations();
  const navigate = useNavigate();

  const { cbdFiles } = useUploadCbdFilesForm();
  const { requestGrants } = useRequestData().data;

  const { CBDRequestDelete } = getHasGrants(requestGrants);

  const { handleUploadFiles, handleDeleteFiles } = useActionsFileForm(id, cbdFiles, FileType.CBD);
  const gtmActionHandle = useCallback(
    (buttonLabel: string) => {
      gtmCbdHandleClick({
        t: translations,
        id,
        buttonLabel,
      });
    },
    [id, translations]
  );

  return (
    <>
      <FileUploader
        id="cbdFile"
        files={cbdFiles.value}
        onChange={async (e) => {
          await handleUploadFiles(e);
          if (isProd) gtmActionHandle('upload');
        }}
        onDeleteFile={CBDRequestDelete ? handleDeleteFiles : undefined}
        onClick={isProd ? () => gtmActionHandle('uploadCBD') : undefined}
        className="mu-mt-100"
        hasWrapper
        isScrollable={isScrollable}
      >
        {translations('uploadCBD')}
      </FileUploader>
      {accessibilityToRedirect && (
        <Button
          className="mu-mb-100 mu-mt-100"
          onClick={() => {
            if (isProd) gtmActionHandle('cbdLoads');
            navigate(`/uploads/${id}`);
          }}
          size="m"
          theme="neutral"
          variant="bordered"
        >
          {translations('cbdLoads')}
        </Button>
      )}
    </>
  );
};
