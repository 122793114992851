import React, { FC, ReactElement } from 'react';
import { Flex, Text } from '@mozaic-ds/react';
import styles from 'pages/CreatingRequest/CreatingRequest.module.scss';
import cn from 'classnames';

export type Block = {
  title: string;
  component: ReactElement;
};

type FileTemplateProps = {
  blocks: Block[];
};

export const FileTemplate: FC<FileTemplateProps> = ({ blocks }) => {
  return (
    <>
      <Flex direction="row">
        {blocks.map((block, index) => {
          const { title } = block;
          return (
            <Flex direction="row" key={title} className={styles.flex}>
              <Text as="span" className={styles.indicator}>
                {index + 1}
              </Text>
              <Text className="mu-pl-050">{title}</Text>
            </Flex>
          );
        })}
      </Flex>
      <Flex direction="row" marginBottom="mu250">
        {blocks.map((block) => {
          const { title, component } = block;
          return (
            <Flex direction="column" key={title} className={cn('mu-pl-200', styles.flex)}>
              {component}
            </Flex>
          );
        })}
      </Flex>
    </>
  );
};
