import React from 'react';
import { downloadFile, getHasGrants, getLocale, IColumnTemplate, parseDate } from 'common/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserData } from 'store/auth/user';
import { setSelectedLoadCbd } from 'store/cbd/selectedLoad';
import { useAppTranslations } from 'translations';
import { useAppDispatch } from 'store/hooks';
import { Badge, Button, Flex, Link, Select, useModals } from '@mozaic-ds/react';
import styles from 'pages/cbd/hok/CbdView.module.scss';
import { updateStatus } from 'api/cbd/cbd';
import { CbdStatus, IRenderButton } from 'common/cbd/types';
import { isLmEnvironment, statusColorConfig } from 'common/consts';
import { getLoadErrors } from 'store/cbd/loadErrors';
import { IUploadTable } from '../Uploads.types';
import { LOAD_ERRORS_MODAL_ID, statusOptions, UploadsColumns } from './consts';
import { getLoadList, useLoadList } from '../../../../store/cbd/uploads/loadList';
import { useUploadsFiltersData } from '../../../../store/cbd/uploads/filters';

type TUseGetColumnsTemplate = {
  [key in UploadsColumns]: IColumnTemplate<IUploadTable>;
};

export const useGetColumnTemplate = (): TUseGetColumnsTemplate => {
  const { user } = useUserData();
  const { lang } = user;
  const { reqId } = useParams();
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();
  const hasGrants = getHasGrants(user.userGrants);
  const navigate = useNavigate();
  const { params } = useLoadList();
  const { open } = useModals();
  const { filters } = useUploadsFiltersData().data;

  const getBoundButtonText = (row: IUploadTable): string => {
    if (!row.cbdId) {
      return translations('bindingNotPossible');
    }
    return row.itemGroup?.length ? translations('bindingDone') : translations('bindingRequired');
  };

  const renderButton = ({
    row,
    buttonText,
    buttonNavigate,
    theme,
    isDisabled = false,
    className = '',
  }: IRenderButton<IUploadTable>) => {
    return (
      <Button
        onClick={(e) => {
          e.stopPropagation();
          buttonNavigate(row);
        }}
        size="s"
        theme={theme || 'primary'}
        isDisabled={isDisabled}
        variant="bordered"
        className={className}
      >
        {buttonText}
      </Button>
    );
  };

  const renderFileLink = (row: IUploadTable) => {
    const name = row.fileName.split('_').join(' ');
    return (
      <Flex alignItems="center" justifyContent="center" className={styles.fileNameCell}>
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <Link
          href="#"
          title={name}
          onClick={(e) => {
            e.preventDefault();
            downloadFile(row.requestId.toString(), row.fileId);
          }}
        >
          {name}
        </Link>
        {/* eslint-enable jsx-a11y/anchor-is-valid */}
      </Flex>
    );
  };

  const onStatusChange = async (e: React.ChangeEvent<HTMLSelectElement>, cbdId: number) => {
    await updateStatus({ cbdId, status: e.target.value });

    dispatch(
      getLoadList({
        reqId: reqId || '',
        ...filters.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.id]: curr.value,
          };
        }, {}),
        ...params,
      })
    );
  };

  const renderStatusColumn = (row: IUploadTable) => {
    let { status } = row;
    if (row.isLoaded === false) status = CbdStatus.Error;
    else if (row.isLoaded === null) status = CbdStatus.Loading;

    const selectOptions = statusOptions.map((item) => ({
      ...item,
      label: translations(item.label),
    }));

    return isLmEnvironment && row.isLoaded ? (
      <Select
        defaultValue={status}
        onChange={(e) => onStatusChange(e, row.cbdId)}
        options={
          !row.itemGroup?.length
            ? selectOptions.filter((item) => item.value !== CbdStatus.Approved)
            : selectOptions
        }
        size="s"
      />
    ) : (
      <Badge theme={statusColorConfig[status]}>{translations(status)}</Badge>
    );
  };

  const renderErrorsButton = (row: IUploadTable) => {
    if (row.isLoaded || row.isLoaded === null) return '-';

    const openPopup = (e: React.SyntheticEvent) => {
      e.preventDefault();
      dispatch(getLoadErrors(row.id));
      open(LOAD_ERRORS_MODAL_ID);
    };

    return (
      <Button onClick={openPopup} size="s" theme="neutral" variant="bordered">
        {translations('showErrors')}
      </Button>
    );
  };

  const columnTemplate: TUseGetColumnsTemplate = {
    [UploadsColumns.ITEM_GROUP]: {
      message: 'bound_status',
      isSortable: false,
      render: (row: IUploadTable) =>
        renderButton({
          row,
          buttonText: getBoundButtonText(row),
          buttonNavigate: (record) => {
            navigate(`/uploads/${record.requestId}/bound/${record.cbdId}`);
            dispatch(setSelectedLoadCbd(row));
          },
          theme: row.itemGroup?.length ? 'primary' : 'danger',
          isDisabled: !row.cbdId,
          className: row.itemGroup?.length
            ? styles.statusBtn
            : ` ${styles.statusBtn} ${styles.statusBtn_danger}`,
        }),
    },
    [UploadsColumns.NAV_CBD_INFO]: {
      message: 'cbdInfo',
      isSortable: false,
      render: (row: IUploadTable) =>
        row.isLoaded
          ? renderButton({
              row,
              buttonText: translations('view'),
              buttonNavigate: (record) => navigate(`/cbd/${record.cbdId}`),
            })
          : null,
    },
    [UploadsColumns.NAV_CBD_ANALYSIS]: {
      message: 'cbdAnalysis',
      isSortable: false,
      render: (row: IUploadTable) =>
        row.isLoaded
          ? renderButton({
              row,
              buttonText: translations('analysis'),
              isDisabled: !hasGrants.CBDAnalyse,
              buttonNavigate: () => navigate(`/cbd-analysis/${row.cbdId}`),
            })
          : null,
    },
    [UploadsColumns.CBD_ID]: {
      message: 'cbdId',
      isSortable: true,
      render: (record: IUploadTable) => record.cbdId || '-',
    },
    [UploadsColumns.CREATED_AT]: {
      message: 'cbdLoadDate',
      isSortable: false,
      render: (record: IUploadTable) =>
        record.createdAt ? parseDate(new Date(record.createdAt), getLocale(lang)) : '-',
    },
    [UploadsColumns.DATE_CBD]: {
      message: 'dateCbd',
      isSortable: false,
      render: (record: IUploadTable) =>
        record.dateCbd ? parseDate(new Date(record.dateCbd), getLocale(lang)) : '-',
    },
    [UploadsColumns.FILE_LINK]: {
      message: 'fileName',
      isSortable: true,
      render: (record: IUploadTable) => renderFileLink(record),
    },
    [UploadsColumns.GROUP]: {
      message: 'supplierProductGroup',
      isSortable: true,
      render: (record: IUploadTable) => record.group,
    },
    [UploadsColumns.STATUS]: {
      message: 'status',
      isSortable: true,
      render: (record: IUploadTable) => renderStatusColumn(record),
    },
    [UploadsColumns.IS_LOADED]: {
      message: 'error_desc',
      isSortable: false,
      render: (record: IUploadTable) => renderErrorsButton(record),
    },
  };

  return columnTemplate;
};
