// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { forwardRef, useCallback, useState } from 'react';
import {
  ListBox,
  ListBoxItem,
  TextInput,
  TTextInputSize,
  useListBoxValues,
} from '@mozaic-ds/react';
import cn from 'classnames';
import { DisplaySearch24 } from '@mozaic-ds/icons/react';
import { useAppDispatch } from 'store/hooks';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { DropdownProps } from 'components/Dropdown/types';
import { SelectItem } from 'common/types';

type Props = {
  size?: TTextInputSize;
  handleSearch?: ActionCreatorWithPayload<string>;
  onChange: (...event: any[]) => void;
} & DropdownProps;

const AutocompleteWithSearch = forwardRef(
  (
    {
      placeholder,
      listBoxItems,
      isDisabled = false,
      className,
      onChange,
      handleSearch,
      size = 'm',
    }: Props,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const dispatch = useAppDispatch();

    const [values, { toggle }] = useListBoxValues([]);
    const [formedValue, setFormedValue] = useState<SelectItem[]>([]);

    const handleMultiClick = useCallback(
      (listBoxValue?: string) => {
        if (!listBoxValue) return;
        toggle(listBoxValue);

        const currentElementValue = listBoxItems.find(
          (item) => item.value === Number(listBoxValue)
        );
        if (formedValue.find((item) => item.value === Number(listBoxValue))) {
          setFormedValue((prevState) =>
            prevState.filter((item) => item.value !== currentElementValue?.value)
          );
          onChange(formedValue.filter((item) => item.value !== currentElementValue?.value));
        } else {
          setFormedValue((prevState) => prevState.concat(currentElementValue as SelectItem));
          onChange(formedValue.concat(currentElementValue as SelectItem));
        }
      },
      [toggle, formedValue, listBoxItems, onChange]
    );

    return (
      <>
        <TextInput
          ref={ref}
          onChange={(e) => {
            if (!e.target.value && handleSearch) dispatch(handleSearch(''));
            else if (handleSearch) dispatch(handleSearch((e.target as HTMLInputElement).value));
          }}
          className={cn(className)}
          size={size}
          placeholder={placeholder}
          isDisabled={isDisabled}
          icon={<DisplaySearch24 />}
          type="search"
        />
        {listBoxItems.length > 0 && (
          <ListBox values={values} mode="multi" onClick={handleMultiClick}>
            {listBoxItems.map((item) => (
              <ListBoxItem key={item.value} value={item.value.toString()}>
                {item.label}
              </ListBoxItem>
            ))}
          </ListBox>
        )}
      </>
    );
  }
);

export default AutocompleteWithSearch;
