import { useMemo } from 'react';
import { useCompareCommonData } from 'store/cbd/cbdAnalysis/compare/common';
import { ChartType } from 'chart.js';
import { ChartProps } from 'react-chartjs-2';
import { useChartConfig } from 'components/Chart/hooks/useChartConfig';
import { useAppTranslations } from 'translations';
import { commonCurrencyChartLabels } from './consts';
import { IChartData, getChartDataForConfig } from './helpers';

const useCommonCurrencyChartConfig = () => {
  const translations = useAppTranslations();
  const { data } = useCompareCommonData();

  const commonCurrencyChartData: IChartData = useMemo(
    () =>
      getChartDataForConfig({
        data,
        datasetLabels: commonCurrencyChartLabels,
        getValueCallBack: () => [
          data.map((item) => (item.extCurrencyRates?.USD || 0) / (item.extCurrencyRates?.RUB || 0)),
          data.map((item) => (item.extCurrencyRates?.EUR || 0) / (item.extCurrencyRates?.RUB || 0)),
          data.map((item) => (item.cbdCurrencyRates?.USD || 0) / (item.cbdCurrencyRates?.RUB || 0)),
          data.map((item) => (item.cbdCurrencyRates?.EUR || 0) / (item.cbdCurrencyRates?.RUB || 0)),
        ],
      }),
    [data]
  );

  const commonCurrencyChartConfig: ChartProps<ChartType> = useChartConfig({
    type: 'line',
    legendPosition: 'bottom',
    axisTitle: [translations('cbdNumber'), translations('rubles')],
    chartData: commonCurrencyChartData,
    needToShowLabels: false,
    needToShowLegend: false,
  });

  return {
    commonCurrencyChartConfig,
  };
};

export default useCommonCurrencyChartConfig;
