import { ActionCreatorWithPayload, AsyncThunk } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useTrackVisibility } from 'react-intersection-observer-hook';
import { useAppDispatch } from 'store/hooks';

interface ScrollPagination {
  scrollPagination?: IUseScrollPagination;
}

export interface IUseScrollPagination {
  pageNumber: number;
  isLastPage: boolean;
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryParams: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPageNumber: ActionCreatorWithPayload<any, string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getData: AsyncThunk<any, any, any>;
}

export const useScrollPagination = ({ scrollPagination }: ScrollPagination) => {
  const dispatch = useAppDispatch();
  const [ref, { isVisible }] = useTrackVisibility();

  useEffect(() => {
    if (!scrollPagination || scrollPagination.isLastPage) return;
    if (isVisible && !scrollPagination.isLoading) {
      dispatch(scrollPagination.setPageNumber(scrollPagination.pageNumber + 1));
      dispatch(
        scrollPagination.getData({
          page: scrollPagination.pageNumber + 1,
          size: 50,
          ...scrollPagination.queryParams,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return { ref };
};
