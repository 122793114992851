import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSuppliers } from 'api/dictionary';
import { FindSuppliersQuery } from '@ppm/ppm-platform-api';
import { SelectItem } from 'common/types';
import { formatSuppliers, formatSuppliersBsg } from 'store/dictionary/helpers';
import { omit } from 'lodash';
import { setFailure, setLoading } from '../helpers';
import { RequiredStateFields, RootState } from '../types';
import { useAppSelector } from '../hooks';

const OFFSET_LIMIT = 10;

type SupplierIdsSliceData = SelectItem[];

interface SliceState extends RequiredStateFields {
  supplierIds: SupplierIdsSliceData;
  suppliers: SupplierIdsSliceData;
  searchValue: string | undefined;
  offset: number;
}

export const getSupplierIds = createAsyncThunk(
  'supplierIds/getSupplierIds',
  async (
    params: (FindSuppliersQuery & { signal?: AbortSignal }) | undefined,
    { rejectWithValue }
  ) => {
    try {
      return await getSuppliers({
        conf: { params: omit(params, 'signal'), signal: params?.signal },
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const supplierIdsSlice = createSlice({
  initialState: {
    supplierIds: [],
    suppliers: [],
    searchValue: undefined,
    offset: 0,
    isLoading: false,
    isFailure: false,
  },
  name: 'supplierIds',
  reducers: {
    setSearchValue(state: SliceState, action: PayloadAction<string>) {
      state.searchValue = action.payload || undefined;
    },
    addMore(state: SliceState) {
      state.offset += OFFSET_LIMIT;
    },
    clearOffset(state: SliceState) {
      state.offset = 0;
    },
    clear(state: SliceState) {
      state.offset = 0;
      state.searchValue = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSupplierIds.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(getSupplierIds.fulfilled, (state: SliceState, action) => {
        const { meta, payload } = action;
        if (Number(meta.arg?.offset)) {
          state.supplierIds = state.supplierIds.concat(formatSuppliers(payload.data));
          state.suppliers = state.supplierIds.concat(formatSuppliersBsg(payload.data));
        } else {
          state.supplierIds = formatSuppliers(payload.data);
          state.suppliers = formatSuppliersBsg(payload.data);
        }
        state.isLoading = false;
        state.isFailure = false;
      })
      .addCase(getSupplierIds.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const { setSearchValue, addMore, clearOffset, clear } = supplierIdsSlice.actions;

export const useSupplierIdsData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.dictionary.supplierIds) };
};
