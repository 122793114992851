import { Accordion, AccordionContent, AccordionHeader, View } from '@mozaic-ds/react';
import Bound from 'pages/cbd/Bound/Bound';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppTranslations } from 'translations';
import { getCbdInfo, useCbdInfo } from 'store/cbd/info';
import { useAppDispatch } from 'store/hooks';
import CbdInfoDataTable from './components/CbdInfoDataTable/CbdInfoDataTable';
import InfoBlock from './components/InfoBlock/InfoBlock';

const UploadInfo = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { data } = useCbdInfo();
  const translations = useAppTranslations();

  useEffect(() => {
    if (params.cbdId) {
      dispatch(getCbdInfo(params.cbdId));
    }
  }, [dispatch, params]);

  return (
    <View marginTop="mu150">
      {data && (
        <>
          <InfoBlock />
          <View marginBottom="mu150">
            <Accordion>
              <AccordionHeader>{translations('bounds')}</AccordionHeader>
              <AccordionContent>
                <Bound
                  hideNomenclature
                  boundFiltersVisible
                  customShownColumns={{ groupsName: true, cbdGroup: true }}
                />
              </AccordionContent>
            </Accordion>
          </View>
          <CbdInfoDataTable cost={data.cost} />
        </>
      )}
    </View>
  );
};

export default UploadInfo;
