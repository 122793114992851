import React, { useCallback, useMemo } from 'react';
import { IExternalData } from 'api/cbd/externalSource';
import { DateInput, Select, TextInput } from '@mozaic-ds/react';
import { IColumnTemplate } from 'common/utils';
import { useAppTranslations } from 'translations';
import { useAppDispatch } from 'store/hooks';
import {
  dayMonthOptions,
  extDataTypeOptions,
} from 'pages/cbd/Scheduler/partials/SchedulerForm/consts';
import { useScheduler } from 'store/cbd/scheduler/scheduler';
import useCostTypesOptions from 'pages/cbd/hooks/useCostTypesOptions';
import { useRegions } from 'store/cbd/regions';
import { useMeasureUnits } from 'store/cbd/measureUnits';
import { useExtCurrency } from 'store/cbd/extCurrency';
import { changeTableRow, useExtTableData } from 'store/cbd/externalData/tableData';
import { ExternalDataColumns } from './consts';

export const useGetColumnTemplate = (): {
  [key in ExternalDataColumns]: IColumnTemplate<IExternalData>;
} => {
  const dispatch = useAppDispatch();
  const { editItems } = useExtTableData();
  const { data: sourceOptions } = useScheduler();
  const { categoryCostTypesOptions, getTypeCostTypesOptions, getSubtypeCostTypesOptions } =
    useCostTypesOptions();
  const translations = useAppTranslations();
  const { list: regionList } = useRegions();
  const { list: measureUnitList } = useMeasureUnits();
  const { fromCurrenciesList: currencyCodesList } = useExtCurrency();

  const isEditModeEnable = useCallback(
    (id: string) => {
      return editItems.includes(id);
    },
    [editItems]
  );

  const onFieldChange = useCallback(
    (id: number, fieldName: keyof IExternalData, value: string) => {
      dispatch(
        changeTableRow({
          id,
          fieldName,
          value,
        })
      );
    },
    [dispatch]
  );

  const columnTemplate = useMemo(
    () => ({
      [ExternalDataColumns.ID]: {
        message: 'ID',
        isSortable: true,
        render: (record: IExternalData) => {
          if (record.id !== -1) {
            return record.id || '';
          }

          return '';
        },
      },
      [ExternalDataColumns.EXT_DATA_TYPE]: {
        message: 'externalDataType',
        isSortable: true,
        render: (record: IExternalData) => {
          if (isEditModeEnable(record.id?.toString() || '')) {
            return (
              <Select
                value={record.extDataType}
                size="s"
                options={extDataTypeOptions.map((item) => ({
                  ...item,
                  label: translations(item.label),
                }))}
                onChange={(e) => {
                  if (record.id) {
                    onFieldChange(record.id, ExternalDataColumns.EXT_DATA_TYPE, e.target.value);
                  }
                }}
                placeholder={translations('chooseAnOption')}
              />
            );
          }

          return translations(
            extDataTypeOptions.find((item) => item.value === record.extDataType)?.label || ''
          );
        },
      },
      [ExternalDataColumns.SOURCE_TYPE]: {
        message: 'sourceType',
        isSortable: true,
        render: (record: IExternalData) => translations(record.sourceType || ''),
      },
      [ExternalDataColumns.SOURCE]: {
        message: 'source',
        isSortable: true,
        render: (record: IExternalData) => {
          if (isEditModeEnable(record.id?.toString() || '')) {
            return (
              <Select
                size="s"
                value={record.source}
                onChange={(e) => {
                  if (record.id) {
                    onFieldChange(record.id, ExternalDataColumns.SOURCE, e.target.value);
                  }
                }}
                options={sourceOptions.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                placeholder={translations('chooseAnOption')}
              />
            );
          }

          return translations(record.scheduler?.name || '');
        },
      },
      [ExternalDataColumns.PERIOD_TYPE]: {
        message: 'periodicity',
        isSortable: true,
        render: (record: IExternalData) =>
          translations(
            dayMonthOptions.find((item) => item.value === record.periodType)?.label || ''
          ),
      },
      [ExternalDataColumns.CREATED_AT]: {
        message: 'initialUploadDataDate',
        isSortable: true,
        render: (record: IExternalData) => {
          const dateStringValue = record.createdAt
            ? new Date(record.createdAt).toLocaleDateString('ru-RU')
            : '';

          return dateStringValue;
        },
      },
      [ExternalDataColumns.UPDATED_AT]: {
        message: 'updateDate',
        isSortable: true,
        render: (record: IExternalData) => {
          const dateStringValue = record.updatedAt
            ? new Date(record.updatedAt).toLocaleDateString('ru-RU')
            : '';

          return dateStringValue;
        },
      },
      [ExternalDataColumns.CATEGORY_ID]: {
        message: 'category',
        isSortable: true,
        render: (record: IExternalData) => {
          if (isEditModeEnable(record.id?.toString() || '')) {
            return (
              <Select
                size="s"
                value={record.categoryId || ''}
                onChange={(e) => {
                  if (record.id) {
                    onFieldChange(record.id, ExternalDataColumns.CATEGORY_ID, e.target.value);
                  }
                }}
                options={categoryCostTypesOptions}
                placeholder={translations('chooseAnOption')}
              />
            );
          }

          return record.category?.name || '';
        },
      },
      [ExternalDataColumns.TYPE_ID]: {
        message: 'type',
        isSortable: true,
        render: (record: IExternalData) => {
          if (isEditModeEnable(record.id?.toString() || '')) {
            return (
              <Select
                isDisabled={!record.categoryId}
                size="s"
                value={record.typeId || ''}
                onChange={(e) => {
                  if (record.id) {
                    onFieldChange(record.id, ExternalDataColumns.TYPE_ID, e.target.value);
                  }
                }}
                options={getTypeCostTypesOptions(record.categoryId || '')}
                placeholder={translations('chooseAnOption')}
              />
            );
          }

          return record.type?.name || '';
        },
      },
      [ExternalDataColumns.SUB_TYPE_ID]: {
        message: 'subType',
        isSortable: true,
        render: (record: IExternalData) => {
          if (isEditModeEnable(record.id?.toString() || '')) {
            return (
              <Select
                isDisabled={!record.typeId}
                size="s"
                value={record.subtypeId || ''}
                onChange={(e) => {
                  if (record.id) {
                    onFieldChange(record.id, ExternalDataColumns.SUB_TYPE_ID, e.target.value);
                  }
                }}
                options={getSubtypeCostTypesOptions(record.categoryId || '', record.typeId || '')}
                placeholder={translations('chooseAnOption')}
              />
            );
          }

          return record.subtype?.name || '';
        },
      },
      [ExternalDataColumns.NAME]: {
        message: 'nomination',
        isSortable: true,
        render: (record: IExternalData) => {
          if (isEditModeEnable(record.id?.toString() || '')) {
            return (
              <TextInput
                size="s"
                value={record.name}
                onChange={(e) => {
                  if (record.id) {
                    onFieldChange(record.id, ExternalDataColumns.NAME, e.target.value);
                  }
                }}
              />
            );
          }

          return record.name;
        },
      },
      [ExternalDataColumns.SECOND_NAME]: {
        message: 'newNomination',
        isSortable: true,
        render: (record: IExternalData) => {
          if (isEditModeEnable(record.id?.toString() || '')) {
            return (
              <TextInput
                size="s"
                value={record.name2}
                onChange={(e) => {
                  if (record.id) {
                    onFieldChange(record.id, ExternalDataColumns.SECOND_NAME, e.target.value);
                  }
                }}
              />
            );
          }

          return record.name2;
        },
      },
      [ExternalDataColumns.DATE]: {
        message: 'quoteDate',
        isSortable: true,
        render: (record: IExternalData) => {
          if (isEditModeEnable(record.id?.toString() || '')) {
            const dateStringValue = record.date
              ? new Date(record.date).toLocaleDateString('fr-CA')
              : '';

            return (
              <DateInput
                size="s"
                value={dateStringValue}
                onChange={(e) => {
                  if (record.id) {
                    onFieldChange(record.id, ExternalDataColumns.DATE, e.target.value);
                  }
                }}
              />
            );
          }

          return record.date ? new Date(record.date).toLocaleDateString('ru-RU') : '';
        },
      },
      [ExternalDataColumns.VALUE]: {
        message: 'price',
        isSortable: true,
        render: (record: IExternalData) => {
          if (isEditModeEnable(record.id?.toString() || '')) {
            return (
              <TextInput
                size="s"
                value={record.value}
                onChange={(e) => {
                  if (record.id) {
                    onFieldChange(record.id, ExternalDataColumns.VALUE, e.target.value);
                  }
                }}
              />
            );
          }

          return record.value;
        },
      },
      [ExternalDataColumns.REGION]: {
        message: 'region',
        isSortable: true,
        render: (record: IExternalData) => {
          if (isEditModeEnable(record.id?.toString() || '')) {
            return (
              <Select
                size="s"
                value={record.region}
                onChange={(e) => {
                  if (record.id) {
                    onFieldChange(record.id, ExternalDataColumns.REGION, e.target.value);
                  }
                }}
                options={regionList}
                placeholder={translations('chooseAnOption')}
              />
            );
          }

          return record.region;
        },
      },
      [ExternalDataColumns.MEASURE]: {
        message: 'unit',
        isSortable: true,
        render: (record: IExternalData) => {
          if (isEditModeEnable(record.id?.toString() || '')) {
            return (
              <Select
                size="s"
                value={record.measure}
                onChange={(e) => {
                  if (record.id) {
                    onFieldChange(record.id, ExternalDataColumns.MEASURE, e.target.value);
                  }
                }}
                options={measureUnitList}
                placeholder={translations('chooseAnOption')}
              />
            );
          }

          return record.measure;
        },
      },
      [ExternalDataColumns.CURRENCY]: {
        message: 'currencies',
        isSortable: true,
        render: (record: IExternalData) => {
          if (isEditModeEnable(record.id?.toString() || '')) {
            return (
              <Select
                size="s"
                value={record.currencyCode}
                onChange={(e) => {
                  if (record.id) {
                    onFieldChange(record.id, ExternalDataColumns.CURRENCY, e.target.value);
                  }
                }}
                options={currencyCodesList}
                placeholder={translations('chooseAnOption')}
              />
            );
          }

          return record.currencyCode;
        },
      },
    }),
    [
      categoryCostTypesOptions,
      getSubtypeCostTypesOptions,
      getTypeCostTypesOptions,
      isEditModeEnable,
      measureUnitList,
      currencyCodesList,
      onFieldChange,
      regionList,
      sourceOptions,
      translations,
    ]
  );

  return columnTemplate;
};
