import { Steps } from './types';

export const steps = [
  { id: Steps.start, label: 'fillGeneralRequestInfo', title: 'fillGeneralRequestInfo' },
  { id: Steps.template, label: 'fillSupplierTemplate', title: 'fillSupplierTemplate' },
  {
    id: Steps.cbd,
    label: 'attachJustificationPriceChange',
    title: 'attachJustificationPriceChange',
  },
  { id: Steps.finish, label: 'sentRequestForPriceChange', title: 'sentRequestForPriceChange' },
];
