import { FindLinkedSuppliers, FindSuppliers, FindSuppliersResponse } from '@ppm/ppm-platform-api';
import { apiCreator } from 'api/index';

export const getSuppliers = apiCreator<typeof FindSuppliers, FindSuppliersResponse>(FindSuppliers)[
  FindSuppliers.method
];

export const getLinkedSuppliers = apiCreator<typeof FindLinkedSuppliers, FindSuppliersResponse>(
  FindLinkedSuppliers
)[FindLinkedSuppliers.method];
