import React, { FC, useCallback, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { useMount } from 'hook/useMount';
import { getTranslations } from 'store/languages/translations';
import initI18next from 'services/initI18next';
import { Language } from '@ppm/ppm-platform-common-contracts';
import AxiosInterceptor from 'components/AxiosInterceptors/AxiosInterceptors';

export const AxiosInterceptorWrapper: FC = ({ children }) => {
  const [isLoading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  const handleLoadTranslationData = useCallback(async () => {
    const { data: translationData } = await dispatch(getTranslations()).unwrap();
    initI18next(Language.English, translationData);
    setLoading(false);
  }, [dispatch]);

  useMount(() => handleLoadTranslationData());

  if (isLoading) return null;

  return <AxiosInterceptor>{children}</AxiosInterceptor>;
};
