import React, { FC } from 'react';
import { GetNomenclatureLinkUsers, GetSupplierLinkUsersResponse } from '@ppm/ppm-platform-api';
import UserCards from './UserCards/UserCards';

type LinksProps = {
  links: GetNomenclatureLinkUsers.Response | GetSupplierLinkUsersResponse['data'];
};

const Links: FC<LinksProps> = ({ links }) => {
  return (
    <>
      {Object.keys(links).map((roleId) => (
        <UserCards key={roleId} roleId={Number(roleId)} links={links} />
      ))}
    </>
  );
};
export default Links;
