import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetRequestByIdResponse,
  RequestModelView,
  SubrequestContainerWithRights,
} from '@ppm/ppm-platform-api';
import { RequestFlowRights } from '@ppm/ppm-platform-request-service';
import { getRequestById } from 'api/request';
import { RequiredStateFields, RootState } from '../types';
import { setFailure, setLoading } from '../helpers';
import { useAppSelector } from '../hooks';

type RequestSliceData = null | RequestModelView;
export type RequestFlowTransitions = RequestFlowRights['transitions'];
export type RequestFlowGrants = RequestFlowRights['grants'];

interface SliceState extends RequiredStateFields {
  request: RequestSliceData;
  requestGrants: RequestFlowGrants;
  containers: SubrequestContainerWithRights[];
  selectedContainers: SubrequestContainerWithRights[];
  id: string;
}

const initialState = {
  request: null,
  requestGrants: {},
  containers: [],
  selectedContainers: [],
  isLoading: false,
  isFailure: false,
  id: '',
};

export const getRequest = createAsyncThunk<GetRequestByIdResponse, string>(
  'request/getRequest',
  async (id, { rejectWithValue }) => {
    try {
      return await getRequestById({ urlParameters: [id] });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const requestSlice = createSlice({
  initialState,
  name: 'request',
  reducers: {
    selectContainer(
      state: SliceState,
      action: PayloadAction<{ index: number; isChecked: boolean }>
    ) {
      const { isChecked, index } = action.payload;
      const selectedContainer = state.containers[index];

      if (!isChecked) {
        state.selectedContainers = state.selectedContainers.filter(
          (item) => item.id !== selectedContainer.id
        );
      } else if (isChecked && state.selectedContainers.length === 0) {
        state.selectedContainers = [selectedContainer];
      } else if (isChecked && state.selectedContainers.length >= 1) {
        const lastIndex = state.selectedContainers.length - 1;
        const statusId = state.containers.find(
          ({ id }) => id === state.selectedContainers[lastIndex].id
        )?.statusId;
        const newElementStatusId = state.containers.find(
          ({ id }) => id === state.containers[index].id
        )?.statusId;
        state.selectedContainers =
          statusId === newElementStatusId
            ? state.selectedContainers.concat(selectedContainer)
            : [selectedContainer];
      }
    },
    resetSelectedContainers(state: SliceState) {
      state.selectedContainers = [];
    },
    searchRequest(state: SliceState, action) {
      state.id = action.payload;
    },
    resetStore() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRequest.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getRequest.fulfilled,
        (state: SliceState, action: PayloadAction<GetRequestByIdResponse>) => {
          const { grants } = action.payload.data.flowRights;
          state.request = action.payload.data.request;
          state.requestGrants = grants;
          state.containers = action.payload.data.containers || [];

          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getRequest.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const { resetStore, resetSelectedContainers, selectContainer, searchRequest } =
  requestSlice.actions;

export const useRequestData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.request.request) };
};
