import { GetReplacementLinkQuery, GetReplacementLinkResponse } from '@ppm/ppm-platform-api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getReplacement as getReplacementData } from 'api/replacement';
import { setFailure, setLoading } from '../helpers';
import { RequiredStateFields, RootState } from '../types';
import { useAppSelector } from '../hooks';

interface SliceState extends RequiredStateFields {
  data: GetReplacementLinkResponse['data'];
}

export const getReplacement = createAsyncThunk<
  GetReplacementLinkResponse,
  GetReplacementLinkQuery | undefined
>('replacement/getReplacement', async (query, { rejectWithValue }) => {
  try {
    return await getReplacementData({ conf: { params: query } });
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const replacementSlice = createSlice({
  initialState: { data: [], isLoading: false, isFailure: false },
  name: 'replacement',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReplacement.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(getReplacement.fulfilled, (state: SliceState, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
        state.isFailure = false;
      })
      .addCase(getReplacement.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const useReplacementData = (): { replacement: SliceState } => {
  return { replacement: useAppSelector((state: RootState) => state.replacement.replacement) };
};
