import React from 'react';
import { Text } from '@mozaic-ds/react';
import { useAppTranslations } from 'translations';
import cn from 'classnames';
import styles from '../Request.module.scss';

interface InfoTextProps {
  label: string;
  value?: string | number | null;
  classValue?: string;
}

export const InfoText = (props: InfoTextProps) => {
  const translations = useAppTranslations();
  const { label, value, classValue = 'mu-mb-100' } = props;

  return (
    <>
      <Text weight="regular" theme="light" as="p" className="mu-mb-50" size="s">
        {translations(label)}
      </Text>
      <Text weight="regular" theme="light" as="p" className={cn(classValue, styles.text)}>
        {value || '-'}
      </Text>
    </>
  );
};
