import React from 'react';
import { View } from '@mozaic-ds/react';
import { useUsers } from 'store/users/users';
import { useNomenclatureData } from 'store/dashboard/rightsManagement/nomenclature';
import { useSuppliersData } from 'store/dashboard/rightsManagement/supplier';
import Nomenclature from './Nomenclature/Nomenclature';
import Suppliers from './Suppliers/Suppliers';
import CheckboxManagement from './CheckboxManagement/CheckboxManagement';
import styles from './AccordionsView.module.scss';

const AccordionsView = () => {
  const { searchText } = useUsers().data;
  const { checkedNomenclatureGroupedByRole } = useNomenclatureData().nomenclature;
  const { checkedSuppliersGroupedByRole } = useSuppliersData().supplier;
  return (
    <View className={styles.content}>
      <CheckboxManagement
        selectedSuppliersLinks={checkedSuppliersGroupedByRole}
        selectedNomenclatureLinks={checkedNomenclatureGroupedByRole}
      />
      <View
        radius="s"
        marginTop="mu075"
        paddingRight="mu075"
        paddingLeft="mu075"
        className={searchText ? styles.accordionDisabled : styles.accordion}
        aria-disabled
      >
        <Suppliers />
        <Nomenclature />
      </View>
    </View>
  );
};
export default AccordionsView;
