import { CostEntity } from 'api/cbd/cbdInfo';
import { ICreateAttribute } from 'api/cbd/templates';
import { SelectItem, StringSelectItem } from 'common/types';
import { IGetCbdListQuery } from 'pages/cbd/AllUploads/AllUploads.types';

export interface ValuesEntity {
  cbdId: string;
  value: number;
}

interface SupplierEntity {
  supplierId: string;
  data?: DataEntity[] | null;
}

interface Sums {
  expenseTypes: number;
  currencies: number;
  groups: number;
}

interface ItemSuppCountryEntity {
  countryID: string;
  countryInd: string;
}

export interface IPurchaseData {
  id: number;
  period: string;
  supplierId: number;
  dateCbd: string;
  value: number;
}

interface ILoadCbd {
  status: CbdStatus;
  baseCurrencyId: string;
  cbdTemplateId: number;
  createdAt: string;
  dateCbd: string;
  division: number;
  factoryCity: string;
  factoryCountry: string;
  group: string;
  id: number;
  item: number;
  purchaseVolume: number;
  load: ICbdLoad;
  itemGroup: ICbdItemGroup[];
  supplierId: number;
  tpType: string;
}

interface ICbdExtends {
  status: CbdStatus;
  baseCurrencyId: string;
  cbdTemplateId: number;
  createdAt: string;
  dateCbd: string;
  division: number;
  factoryCity: string;
  factoryCountry: string;
  group: string;
  id: number;
  item: number;
  purchaseVolume: number;
  load: ICbdLoad;
  supplierId: number;
  tpType: string;
}

interface ICatalogCost {
  id: string;
  code: string;
  name: string;
  parentId: number | null;
  required: boolean;
  unique: boolean;
  mappingSum: string;
  mappingCur: string;
  errRequiredSumId: number | null;
  errRequiredCurId: number | null;
  primeCost: boolean;
  purchasePrice: boolean;
  expensesType: string | null;
}

interface IAnalysisIndicator {
  text: string;
  type: CbdStatus;
}

interface HeadersEntity {
  id: number;
  supplierId: number;
  dateCbd: string;
  group: string;
}

interface Groups {
  purchase: number;
  production: number;
  other: number;
  logistics: number;
}

interface ExpenseTypes {
  variables: number;
  permanent: number;
}

interface DataEntity {
  itemId: string;
  mainSuppInd: string;
  partnerSKU: string;
  itemSuppCountry?: ItemSuppCountryEntity[] | null;
}

interface CurrencyRates {
  RUB: number;
  EUR: number;
  USD: number;
}

export interface ChildrenValuesEntity {
  value: number;
  cbdId: number;
}

interface ChildrenEntity {
  id: number;
  groups: string;
  name: string;
  bestValue: number;
  worstValue: number;
  values: ChildrenValuesEntity[];
}

export interface BodyEntity {
  id: string;
  groups: string;
  values: ValuesEntity[];
  bestValue: number;
  worstValue: number;
  childrens: ChildrenEntity[];
}

export enum CbdStatus {
  Success = 'success',
  Approved = 'approved',
  NotSeen = 'not_seen',
  Declined = 'declined',
  Error = 'error',
  Loading = 'loading',
}

export interface IPageSize {
  size: number;
  page: number;
}

export interface IRowTemplateStructure {
  list: string;
  rowNumber: number;
}

export interface IColumnTemplateStructure {
  list: string;
  column: string;
}

export interface IFormatTemplateStructure {
  srcList: string;
  srcCell: string;
  targetList: string;
  target: string;
}

export interface INameTemplateStructure {
  list: string;
  range: string;
  name: string;
}

export interface INumberTemplateStructure {
  list: string;
  cellName: string;
  value: number;
}

export interface ITextTemplateStructure {
  list: string;
  cellName: string;
  value: string;
}

export interface IFormulaTemplateStructure {
  list: string;
  cellName: string;
  formula: string;
}

export interface IGetCbdLoadListPayload extends IPageSize {
  reqId: string;
}

export interface IGetCbdListPayload extends IGetCbdListQuery, IPageSize {
  sortDirection?: string;
  sortValue?: string;
}

export interface IGetItemGroupPayload extends IPageSize {
  cbdId: string;
}

export interface IDeleteItemGroupPayload {
  cbdId: string;
  id: string;
}

export interface IBindCbdPayload {
  cbdId: string;
  body: IBindCbdNomenclature;
}

export interface IGetCbdItemInfoPayload {
  itemId: number;
  supplierId: number;
}

export interface IUpdateStatusPayload {
  cbdId: number;
  status: string;
}

export interface ISendCbdFilePayload {
  reqId: string;
  formData: FormData;
}

export interface ICbdItemInfo {
  itemId: string;
  subclassId: number;
  groupId: number;
  deptId: number;
  classId: number;
}

export interface ICbdWithArrayItemGroups extends ICbdExtends {
  itemGroup: ICbdItemGroup[];
}

export interface ICbd extends ICbdExtends {
  itemGroup: ICbdItemGroup;
}

export interface ICbdErrorUpload {
  id: number;
  code: string;
  description: string;
  comment: string;
}

export interface ICbdLoad {
  id: number;
  requestId: number;
  requestStatusId: number;
  requestStatusCode: string;
  fileId: number;
  cbdId: number;
  fileLink: string;
  fileName: string;
  isLoaded: boolean;
  error: ICbdErrorUpload;
  errorId: number;
  cbd: ILoadCbd;
}

export interface ISupplier {
  supplierId: number;
  supplierParentId: number;
  name: string;
  status: string;
  currencyCode: string;
  gln: number;
  deptId: number;
  orgUnitId: number;
  inn: string;
  kpp: string;
  supplierType: string;
  managerLdap: number;
  specLdap: number;
  providerCode: string;
  providerType: string;
}

export interface ICbdItemGroup {
  cbdId: number;
  class: number;
  className: string;
  department: number;
  departmentName: string;
  groups: number;
  groupName: string;
  id: number;
  item: string;
  subclass: number;
  subclassName: string;
  cbdGroup?: string;
  childrens?: ICbdItemGroup[];
  level?: number;
}

export interface ICatalogCostTreeItem extends ICatalogCost {
  childrens: Array<ICatalogCostTreeItem>;
  attrName: string;
  attrRequired: boolean;
  attrMapping: string;
  attrErrRequiredId: number | null;
  isCost: boolean;
  isAttr: boolean;
  attributes: Array<ICreateAttribute>;
}

export interface IGroup {
  id: string;
  label: string;
  lower: string;
}

export interface IDepartment extends IGroup {
  groupId: string;
}

export interface IClass extends IDepartment {
  departmentId: string;
}

export interface ISubClass extends IClass {
  classId: string;
}

export interface IRenderButton<TableData> {
  row: TableData;
  buttonText: string;
  buttonNavigate: (row: TableData) => void;
  theme?: 'primary' | 'danger' | 'primary-02' | 'neutral' | undefined;
  isDisabled?: boolean;
  className?: string;
}

export interface IFileUrlResponse {
  fileUrl: string;
}

export interface IGetLatestCbdTemplateInfoResponse extends IFileUrlResponse {
  date: string;
  fileName: string;
  id: number;
  version: string;
}

export interface NomenclatureState {
  dep: string;
  subDep: string;
  classData: string;
  subClass: string;
  item: string;
}

export interface IBindCbdNomenclature {
  groups: number | undefined;
  department: number | undefined;
  class: number | undefined;
  subclass: number | undefined;
  item: string;
}

export interface IPageable {
  page: {
    elements: number;
    number: number;
  };
  sort: {
    by: string;
    direction: string;
    sorted: boolean;
  };
  total: {
    elements: number;
    pages: number;
  };
}

export interface IRequestStatus {
  id: number;
  code: string;
  isSubStatus: boolean;
  isFinalForLm: boolean;
  isFinalForSupplier: boolean;
}

export interface IAttribute {
  id: string;
  label: string;
  value: string;
}

export interface ICbdInfoData {
  id: string;
  name: string;
  currency: string;
  price: number;
  level: number;
  children: ICbdInfoData[];
  attributes: IAttribute[];
}

export interface IExpandedInfo {
  cost: CostEntity[];
}

export interface IPurchasePrice {
  data?: IPurchaseData[] | null;
  simulationData?: IPurchaseData[] | null;
}

export interface IChartsExtends {
  id: number;
  supplierId: number;
  dateCbd: string;
  group: string;
  traceableComponents: boolean;
  expenseTypes: ExpenseTypes;
  currencies: CurrencyRates;
  groups: Groups;
  sums: Sums;
  cbdCurrencyRates: CurrencyRates;
  extCurrencyRates: CurrencyRates;
}

export interface ICbdAnalysisInfo extends IChartsExtends {
  indicators: IAnalysisIndicator[];
}

export interface IGetResponse {
  pageable: IPageable;
}

export interface ICostGroups {
  headers: HeadersEntity[];
  body: BodyEntity[];
}

export interface IGetAllCbdListResponse extends IGetResponse {
  data: ICbd[];
}

export interface IGetAllCbdListWithArrayItemGroupResponse extends IGetResponse {
  data: ICbdWithArrayItemGroups[];
}

export interface IGetCbdLoadListResponse extends IGetResponse {
  data: ICbdLoad[];
}

export interface IGetSuppliersResponse extends IGetResponse {
  data: ISupplier[];
}

export interface IGetItemGroupResponse extends IGetResponse {
  data: ICbdItemGroup[];
}

export interface IGetCbdItemInfoResponse {
  item: ICbdItemInfo[] | null;
  supplier: SupplierEntity | null;
}

export interface IGetGroupsResponse extends IGetResponse {
  data: IGroup[];
}

export interface IGetDepartmentResponse extends IGetResponse {
  data: IDepartment[];
}

export interface IGetClassResponse extends IGetResponse {
  data: IClass[];
}

export interface IGetSubClassResponse extends IGetResponse {
  data: ISubClass[];
}

export interface IGetSelectsResponse extends IGetResponse {
  data: StringSelectItem[];
}

export interface IGetExternalDataNames extends IGetResponse {
  data: StringSelectItem[];
}

export interface IGetTemplateStructureResponse extends IGetResponse {
  data: ICatalogCostTreeItem[];
}

export interface IGetLoadErrorCodesResponse extends IGetResponse {
  data: SelectItem[];
}

export interface IGetSelectsPayload {
  query?: string;
}

export interface ICbdLoadTimeouts {
  cbdUpdateTimeout: number;
  cbdUploadTimeout: number;
}
