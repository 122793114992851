import React, { useCallback } from 'react';
import { Button, Flex, TabsLink, TabsList } from '@mozaic-ds/react';
import { useAppTranslations } from 'translations';
import { useUserData } from 'store/auth/user';
import { getHasGrants } from 'common/utils';
import { useNavigate } from 'react-router-dom';
import { isProd, isSupplierEnvironment } from 'common/consts';
import { gtmTransferToCreateRequestPage } from 'pages/Requests/gtm';
import { reset } from 'store/requestCreation/requestCreation';
import { useAppDispatch } from 'store/hooks';
import { resetStore } from 'store/request/request';
import styles from './Navigation.module.scss';

export const Navigation = () => {
  const dispatch = useAppDispatch();
  const t = useAppTranslations();
  const navigate = useNavigate();
  const { data, userGrants } = useUserData().user;
  const { AdminDashboard, AdminCBD, CBDAnalyse, RequestCreate } = getHasGrants(userGrants);

  const handleCreateRequest = useCallback(() => {
    if (isSupplierEnvironment && isProd) gtmTransferToCreateRequestPage(t);
    dispatch(reset());
    dispatch(resetStore());
    navigate('create-request');
  }, [t, dispatch, navigate]);

  return (
    <Flex justifyContent="space-between" alignItems="center" className={styles.nav} shadow="s">
      <TabsList>
        <TabsLink isSelected>{t('requestsHeaderTitle')}</TabsLink>
        <TabsLink onClick={() => navigate('/cbd')}>{t('cbdAllLoads')}</TabsLink>
        {AdminCBD && (
          <>
            <TabsLink onClick={() => navigate('/external-data')}>
              {t('externalSourcesData')}
            </TabsLink>
            <TabsLink onClick={() => navigate('/edit-template')}>{t('editTemplateCbd')}</TabsLink>
          </>
        )}
        {CBDAnalyse && (
          <TabsLink onClick={() => navigate('/analysis')}>{t('marketSituationAnalysis')}</TabsLink>
        )}
        {AdminDashboard && (
          <TabsLink onClick={() => navigate('/dashboard')}>{t('adminDashboardRedirect')}</TabsLink>
        )}
      </TabsList>
      {data && RequestCreate && (
        <Button size="s" onClick={handleCreateRequest} className="mu-mr-300">
          {t('createRequest')}
        </Button>
      )}
    </Flex>
  );
};
