import React, { useEffect } from 'react';
import { Button, Select, View } from '@mozaic-ds/react';
import { DisplayFilter24 } from '@mozaic-ds/icons/react';
import Flatpickr from 'react-flatpickr';
import Layer from 'components/Layer/Layer';
import { useAppTranslations } from 'translations';
import FieldWrapper from 'components/FieldWrapper/FieldWrapper';
import classNames from 'classnames';
import { useAppDispatch } from 'store/hooks';
import { getSupplierIds, setSearchValue, useSupplierIdsData } from 'store/dictionary/supplierIds';
import { useCompareFiltersData } from 'store/cbd/compare/filters';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import { cbdTypeValues, useCbdAnalysisInfoInfoData } from 'store/cbd/cbdAnalysis/cbdAnalysisInfo';
import { useCompareFilterForm } from 'pages/cbd/CompareTable/hooks/filters';
import { compareFiltersName } from 'pages/cbd/CompareTable/hooks/consts';
import styles from '../../CbdView.module.scss';
import useNomenclatureOptions from '../../hooks/useNomenclatureOptions';

const CompareFiltersPopup = (): JSX.Element => {
  const translations = useAppTranslations();
  const dispatch = useAppDispatch();
  const { cbdType } = useCbdAnalysisInfoInfoData();

  const { supplierIds, searchValue } = useSupplierIdsData().data;

  const { deletedFilters } = useCompareFiltersData().data;
  const { form, itemGroup, itemDep, itemClass, itemSubclass, cbdDate, cbdSupplierId, onSubmit } =
    useCompareFilterForm();
  const itemGroupOptionsWatcher = form.watch(['itemGroup', 'itemDep', 'itemClass']);
  const { groupsOptions, depOptions, classDataOptions, subClassOptions } =
    useNomenclatureOptions(itemGroupOptionsWatcher);

  useEffect(() => {
    deletedFilters.forEach((id) => form.setValue(id, ''));
  }, [deletedFilters, form]);

  useEffect(() => {
    dispatch(getSupplierIds({ searchString: searchValue }));
  }, [searchValue, dispatch]);

  return (
    <Layer
      id="compareFiltersPopup"
      icon={<DisplayFilter24 fill="#71706b" />}
      title={translations('filterPopupHeader')}
      resetItems={() => {
        form.reset();
        compareFiltersName.forEach((id) => form.setValue(id, ''));
      }}
      saveItems={onSubmit}
      button={
        <Button leftIcon={<DisplayFilter24 />} variant="bordered" size="s">
          {translations('filters')}
        </Button>
      }
    >
      <View as="form" paddingLeft="mu025" paddingRight="mu025">
        {cbdType === cbdTypeValues[0] && (
          <FieldWrapper label={translations('supplier')} id="supplierId">
            <Autocomplete
              {...cbdSupplierId}
              emptyMessageText={translations('emptySupplierLinks')}
              placeholder={translations('chooseAnOption')}
              listBoxItems={supplierIds}
              handleSearch={setSearchValue}
            />
          </FieldWrapper>
        )}
        <FieldWrapper label={translations('dateCbd')} id="reasonListId">
          <Flatpickr
            {...cbdDate}
            className="mc-text-input"
            options={{ mode: 'range', dateFormat: 'm/d/Y', locale: { firstDayOfWeek: 1 } }}
            placeholder={translations('chooseAnOption')}
          />
        </FieldWrapper>
        <fieldset className={classNames('mu-mt-100', 'mu-p-100', styles.fieldset)}>
          <legend>{translations('nomenclatureOfGoods')}</legend>
          <FieldWrapper label={translations('devision')} id="statusIds">
            <Select
              {...itemGroup}
              size="m"
              options={groupsOptions}
              placeholder={translations('chooseAnOption')}
            />
          </FieldWrapper>
          <FieldWrapper label={translations('subDevision')} id="statusIds">
            <Select
              {...itemDep}
              isDisabled={!itemGroup.value}
              size="m"
              options={depOptions}
              placeholder={translations('chooseAnOption')}
            />
          </FieldWrapper>
          <FieldWrapper label={translations('type')} id="statusIds">
            <Select
              {...itemClass}
              isDisabled={!itemDep.value}
              size="m"
              options={classDataOptions}
              placeholder={translations('chooseAnOption')}
            />
          </FieldWrapper>
          <FieldWrapper label={translations('subType')} id="statusIds">
            <Select
              {...itemSubclass}
              isDisabled={!itemClass.value}
              size="m"
              options={subClassOptions}
              placeholder={translations('chooseAnOption')}
            />
          </FieldWrapper>
        </fieldset>
      </View>
    </Layer>
  );
};

export default CompareFiltersPopup;
