export const FILTERS_ID = {
  excludeCompleted: 'excludeCompleted',
  supplierId: 'supplierId',
  nameOrIDSupplier: 'nameOrIDSupplier',
  reasonListId: 'reasonListId',
  statusIds: 'statusIds',
  createDate: 'createDate',
  dueDate: 'dueDate',
  deadlineDate: 'deadlineDate',
  responsibleLdap: 'responsibleLdap',
};

export const DOWNLOAD_LIMIT_REQUESTS = 50;
