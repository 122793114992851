export const LABEL_CLASS = {
  left: 'label_row_left',
  right: 'label_row_right',
  vertical: 'label_vertical',
  custom: 'label_vertical',
};

export const FIELD_DIRECTION: {
  vertical: 'column';
  right: 'row-reverse';
  left: 'row';
  custom: 'column';
} = {
  vertical: 'column',
  right: 'row-reverse',
  left: 'row',
  custom: 'column',
};

export const FIELD_ALIGN: {
  vertical: 'flex-start';
  right: 'center';
  left: 'center';
  custom: undefined;
} = {
  vertical: 'flex-start',
  right: 'center',
  left: 'center',
  custom: undefined,
};
