import 'reflect-metadata';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { ModalsProvider, NotificationsProvider, NotificationsRenderer } from '@mozaic-ds/react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { AxiosInterceptorWrapper } from 'components/AxiosInterceptors/AxiosInterceptorWrapper';
import { App } from 'components/App';
import { store } from 'store';

import '@mozaic-ds/react/lib/index.adeo.css';
import 'assets/scss/index.scss';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <StrictMode>
        <ErrorBoundary>
          <NotificationsProvider>
            <ModalsProvider>
              <AxiosInterceptorWrapper>
                <App />
              </AxiosInterceptorWrapper>
              <NotificationsRenderer />
            </ModalsProvider>
          </NotificationsProvider>
        </ErrorBoundary>
      </StrictMode>
    </BrowserRouter>
  </Provider>,
  document.getElementById('app')
);
