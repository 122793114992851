import { useController, useForm } from 'react-hook-form';
import { useLoadList } from 'store/cbd/uploads/loadList';
import { UploadCbdFormFields } from './types';

const useUploadCbdFilesForm = () => {
  const { data } = useLoadList();

  const { control } = useForm<UploadCbdFormFields>({
    defaultValues: {
      cbdFiles: data
        ? data.map(({ fileName, fileId, error }) => ({
            id: fileId,
            name: fileName,
            messages: error ? [error.description] : [],
          }))
        : [],
    },
  });

  const { field: cbdFiles } = useController({ name: 'cbdFiles', control });

  return { cbdFiles };
};

export default useUploadCbdFilesForm;
