import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/types';
import { useAppSelector } from 'store/hooks';
import { getChartDataRequest, IExternalChartData } from 'api/cbd/externalSource';
import { IPageSize } from 'common/cbd/types';

interface SliceState {
  data: IExternalChartData[];
}

const initialState: SliceState = {
  data: [],
};

export const getExtChartData = createAsyncThunk(
  'cbd/externalData/getExtChartData',
  async (payload: IPageSize, { rejectWithValue }) => {
    try {
      return await getChartDataRequest(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const extChartDataSlice = createSlice({
  initialState,
  name: 'extChartData',
  reducers: {
    clearChartData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getExtChartData.fulfilled,
        (state: SliceState, action: PayloadAction<IExternalChartData[]>) => {
          state.data = action.payload;
        }
      )
      .addCase(getExtChartData.rejected, (state: SliceState) => {
        state.data = [];
      });
  },
});

export const { clearChartData } = extChartDataSlice.actions;

export const useExtChartData = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.externalData.chartData);
};
