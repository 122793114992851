import { getRequest, useRequestData } from 'store/request/request';
import { useCancelRequest } from 'hook/useCancelRequest';
import { ChangeEvent, useCallback, useState } from 'react';
import AsyncRetry from 'async-retry';
import { getRequestById } from 'api/request';
import { FileInfo } from '@ppm/ppm-platform-api';
import { FileType } from '@ppm/ppm-platform-file-service/build/enums';
import { useForm, useFormContext } from 'react-hook-form';
import { forEach, uniqueId } from 'lodash';
import { TFile } from 'hook/useUploadFile';
import { appendFileToFormData, downloadFile } from 'common/utils';
import { uploadFile } from 'api/file';
import { useAppDispatch } from 'store/hooks';
import { ModifFormFields } from './types';

export const useSubRequestsForm = () => {
  const { request } = useRequestData().data;
  const defaultValues: ModifFormFields = {
    modif: (request?.filesInfo || []).filter((file) => file.fileType === FileType.ModifTemplate),
  };
  return useForm<ModifFormFields>({
    criteriaMode: 'all',
    mode: 'onChange',
    defaultValues,
  });
};

export const useActionsModif = (requestId: string) => {
  const dispatch = useAppDispatch();
  const controller = useCancelRequest();

  const { setValue, getValues } = useFormContext<ModifFormFields>();

  const [isLoading, setLoading] = useState(false);

  const downloadingModif = useCallback(async () => {
    try {
      setLoading(true);
      await AsyncRetry(
        async () => {
          const { data } = await getRequestById({
            urlParameters: [requestId],
            conf: { signal: controller.signal },
          });
          if (data && data.request.filesInfo) {
            setLoading(false);
            const file: FileInfo = data.request.filesInfo
              .filter((item) => item.fileType === FileType.EmptyModifTemplate)
              .slice(-1)[0];
            return downloadFile(requestId, file.id);
          }
          throw new Error('Not ready yet');
        },
        {
          minTimeout: 2000,
          retries: 2,
        }
      );
    } catch (e) {
      setLoading(false);
    }
  }, [controller.signal, requestId]);

  const handleUploadModif = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const filesForUploading = e.target.files;

      if (!filesForUploading) return;

      await uploadFile({
        urlParameters: [requestId, FileType.ModifTemplate],
        file: appendFileToFormData(filesForUploading[0]),
      }).then(({ id }) => {
        forEach(filesForUploading, (file) => {
          (file as TFile).id = id || Number(uniqueId());
        });
        setValue('modif', [...getValues('modif'), ...(filesForUploading as unknown as FileInfo[])]);
        dispatch(getRequest(requestId));
        return null;
      });
    },
    [requestId, setValue, getValues, dispatch]
  );
  return { isLoading, downloadingModif, handleUploadModif };
};
