import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getNomenclatureLinkUser } from 'api/links';
import { GetNomenclatureLinkUsers } from '@ppm/ppm-platform-api';
import { setFailure, setLoading } from 'store/helpers';
import { RequiredStateFields, RootState } from 'store/types';
import { useAppSelector } from 'store/hooks';

interface SliceState extends RequiredStateFields {
  data: GetNomenclatureLinkUsers.Response;
}

export const getNomenclatureLinkUsers = createAsyncThunk(
  'dashboard/getNomenclatureLinkUsers',
  async (body: GetNomenclatureLinkUsers.Query, { rejectWithValue }) => {
    try {
      return await getNomenclatureLinkUser({ body });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const nomenclatureLinkUsersSlice = createSlice({
  initialState: { data: {}, isLoading: false, isFailure: false },
  name: 'nomenclatureLinkUsers',
  reducers: {
    clearAll(state: SliceState) {
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNomenclatureLinkUsers.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getNomenclatureLinkUsers.fulfilled,
        (state: SliceState, action: PayloadAction<GetNomenclatureLinkUsers.Response>) => {
          state.data = action.payload;
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getNomenclatureLinkUsers.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const { clearAll } = nomenclatureLinkUsersSlice.actions;

export const useNomenclatureLinkUsersData = (): { nomenclatureLinkUsers: SliceState } => {
  return {
    nomenclatureLinkUsers: useAppSelector(
      (state: RootState) => state.dashboard.nomenclatureLinkUsers
    ),
  };
};
