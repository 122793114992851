import React, { ReactNode, useCallback } from 'react';
import {
  Button,
  Flex,
  Layer as LayerMozaic,
  LayerBody,
  LayerFooter,
  LayerHeader,
  LayerTitle,
  useModals,
  View,
} from '@mozaic-ds/react';
import { useAppTranslations } from 'translations';
import styles from './Layer.module.scss';

interface ILayerProps {
  title: string;
  icon: JSX.Element;
  id: string;
  saveItems: () => void;
  resetItems: () => void;
  children: ReactNode;
  button: JSX.Element;
  isDisabled?: boolean;
}

const Layer = (props: ILayerProps): JSX.Element => {
  const translations = useAppTranslations();
  const { open, close } = useModals();
  const { title, icon, saveItems, resetItems, id, children, button, isDisabled } = props;

  const handleSave = useCallback(() => {
    saveItems();
    close(id);
  }, [id, saveItems, close]);

  const handleOpen = useCallback(() => {
    open(id);
  }, [open, id]);

  return (
    <>
      {React.cloneElement(button, { onClick: handleOpen })}

      <LayerMozaic id={id}>
        <LayerHeader>
          <View paddingLeft="mu025">
            <Flex alignItems="center">
              {icon}
              <LayerTitle>{translations(title)}</LayerTitle>
            </Flex>
          </View>
        </LayerHeader>

        <LayerBody className={styles.layer}>{children}</LayerBody>

        <LayerFooter>
          <Button onClick={handleSave} isDisabled={isDisabled}>
            {translations('save')}
          </Button>

          <Button variant="bordered" onClick={resetItems}>
            {translations('reset')}
          </Button>
        </LayerFooter>
      </LayerMozaic>
    </>
  );
};

export default Layer;
