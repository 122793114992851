import * as React from 'react';
import { Notification, Text } from '@mozaic-ds/react';
import { BusinessTag, RequestStatusCode } from '@ppm/ppm-platform-request-service';
import { isLmEnvironment, isSupplierEnvironment } from 'common/consts';
import { useAppTranslations } from 'translations';
import { useRequestData } from 'store/request/request';
import { usePossibilityCreationPriceList } from 'store/priceList/possibility/creation';
import { parseRequestErrorMessage } from 'pages/Request/helpers';
import { createMessageText } from 'pages/CreatingRequest/helpers';

export const Notifications = () => {
  const translations = useAppTranslations();

  const { request, selectedContainers } = useRequestData().data;
  const { error } = usePossibilityCreationPriceList().data;

  const isShowConsideredPricesNotification =
    request &&
    request.statusCode &&
    ![
      RequestStatusCode.Validated,
      RequestStatusCode.Rejected,
      RequestStatusCode.OnSupplierValidation,
      RequestStatusCode.RequiresDetailingFromSupplier,
      RequestStatusCode.InitializingRequest,
      RequestStatusCode.GroupingPurchasePrices,
      RequestStatusCode.InitializeSubrequests,
      RequestStatusCode.GroupingRecommendedRetailPrices,
      RequestStatusCode.InitializeData,
      RequestStatusCode.ErrorFromInitialize,
      RequestStatusCode.PricelistSigned,
    ].includes(request.statusCode);
  return (
    <>
      {request?.statusCode &&
        request.statusCode === RequestStatusCode.Draft &&
        request?.createDate && (
          <Notification
            theme="info"
            className="mu-mt-150"
            message={createMessageText({
              date: request.createDate,
              message: translations('requestShouldCreateAgainIfArticlesWasChangedNotification'),
              indexesOfInsertions: [0, 1],
              parseOptions: { separator: /[.,]/g },
            })}
          />
        )}
      {RequestStatusCode.ErrorFromInitialize === request?.statusCode && (
        <Notification
          className="mu-mt-125"
          theme="warning"
          message={parseRequestErrorMessage(request?.error).map((message) => (
            <Text as="p" key={message}>
              {message}
            </Text>
          ))}
        />
      )}
      {isShowConsideredPricesNotification && isSupplierEnvironment && (
        <Notification
          className="mu-mt-125"
          theme="info"
          message={translations('consideredPricesNotification')}
        />
      )}
      {selectedContainers.length > 1 && isLmEnvironment && (
        <Notification
          className="mu-mt-125"
          theme="warning"
          message={translations('lmNotificationRestrictionWorkWithModifAndDate')}
        />
      )}
      {selectedContainers.length > 0 &&
        RequestStatusCode.Draft === request?.statusCode &&
        isSupplierEnvironment && (
          <Notification
            className="mu-mt-125"
            theme="warning"
            message={translations('supplierNotificationRestrictionByDraftFlow')}
          />
        )}
      {Boolean(error) && (
        <Notification
          className="mu-mt-125"
          theme="warning"
          message={translations(
            `${
              isLmEnvironment
                ? 'creationPricelistLMNotification'
                : 'creationPricelistSupplierNotification'
            }`
          )}
        />
      )}
      {request?.tags &&
        request.tags.includes(BusinessTag.SalesPricesNotExist) &&
        isLmEnvironment && (
          <Notification
            className="mu-mt-125"
            theme="warning"
            message={translations('pricesUploadedLaterNotification')}
          />
        )}
    </>
  );
};
