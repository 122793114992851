import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getExternalSourceRequest, IExternalDataRequest } from 'api/cbd/externalSource';
import { initPageable } from 'common/consts';
import { IPageSize } from 'common/cbd/types';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

interface SliceState extends IExternalDataRequest {
  selectedRowsId: string[];
  isLoading: boolean;
}

const initialState: SliceState = {
  data: [],
  isLoading: false,
  selectedRowsId: [],
  pageable: initPageable,
};

export const getExternalSource = createAsyncThunk(
  'cbd/externalSourceTableData/getExternalSource',
  async (payload: IPageSize, { rejectWithValue }) => {
    try {
      return await getExternalSourceRequest(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const externalSourceTableDataSlice = createSlice({
  initialState,
  name: 'externalSourceTableData',
  reducers: {
    clearExternalState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getExternalSource.fulfilled,
        (state: SliceState, action: PayloadAction<IExternalDataRequest>) => {
          state.data = action.payload.data;
          state.pageable = action.payload.pageable;
          state.isLoading = false;
        }
      )
      .addCase(getExternalSource.pending, (state: SliceState) => {
        state.isLoading = true;
      })
      .addCase(getExternalSource.rejected, () => initialState);
  },
});

export const { clearExternalState } = externalSourceTableDataSlice.actions;

export const useExternalSourceTableData = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.externalData.analysis.tableData);
};
