export const emptyCatalogCostRow = {
  id: '-1',
  code: '',
  name: '',
  parentId: null,
  operationId: '',
  required: false,
  unique: false,
  mappingSum: '',
  mappingCur: '',
  errRequiredSumId: null,
  errRequiredCurId: null,
  primeCost: false,
  purchasePrice: false,
  expensesType: null,
  costGroups: null,
  level: 0,
  childrens: [],
  attrCostId: 0,
  attrName: '',
  attrMapping: '',
  attrRequired: true,
  attrErrRequiredId: null,
  isCost: true,
  isAttr: false,
  attributes: [],
};
