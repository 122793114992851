import { useController, useForm, useFormContext } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { clearDeletedFilters, setFilters } from 'store/requests/filters';
import { FilterName } from 'pages/Requests/types';
import { getLabel } from 'pages/Requests/partials/consts';
import { useAppDispatch } from 'store/hooks';
import { useAppTranslations } from 'translations';
import { isEmpty } from 'lodash';
import { SelectItem } from 'common/types';

export type FiltersForm = {
  supplierId: Partial<SelectItem>;
  responsibleLdap: string;
  reasonListId: string[];
  statusIds: string;
  createDate: string;
  deadlineDate: string;
  dueDate: string;
};

export const useDefaultFormValue = () => {
  return useMemo(() => {
    return {
      supplierId: {},
      reasonListId: [],
      responsibleLdap: '',
      statusIds: '',
      createDate: '',
      deadlineDate: '',
      dueDate: '',
    };
  }, []);
};
export const useInitFiltersForm = () => {
  const defaultValues = useDefaultFormValue();

  return useForm<FiltersForm>({
    defaultValues,
  });
};

export const useFiltersForm = () => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const { control, handleSubmit, reset, resetField, getValues, setValue, formState } =
    useFormContext<FiltersForm>();

  const { field: supplierId } = useController({ name: 'supplierId', control });
  const { field: reasonListId } = useController({ name: 'reasonListId', control });
  const { field: statusIds } = useController({
    name: 'statusIds',
    control,
  });
  const { field: createDate } = useController({ name: 'createDate', control });
  const { field: deadlineDate } = useController({ name: 'deadlineDate', control });
  const { field: dueDate } = useController({ name: 'dueDate', control });
  const { field: responsibleLdap } = useController({ name: 'responsibleLdap', control });

  const handelSubmit = useCallback(() => {
    handleSubmit((data) => {
      const keys = Object.keys(data) as FilterName[];
      dispatch(clearDeletedFilters());
      dispatch(
        setFilters(
          keys
            .map((key) => ({
              id: key,
              value: data[key],
              label: getLabel(key as FilterName, translations),
            }))
            .filter(({ value }) => (Array.isArray(value) ? value.length > 0 : Boolean(value)))
            .filter(({ value }) => !isEmpty(value))
        )
      );
    })();
  }, [dispatch, handleSubmit, translations]);

  return {
    formState,
    setValue,
    control,
    statusIds,
    getValues,
    resetField,
    handelSubmit,
    supplierId,
    dueDate,
    createDate,
    reasonListId,
    deadlineDate,
    responsibleLdap,
    reset,
  };
};
