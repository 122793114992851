import { FileType } from '@ppm/ppm-platform-file-service/build/enums';
import { ChangeEvent, useCallback } from 'react';
import { useUploadFile } from 'hook/useUploadFile';
import { deleteFile, uploadFile } from 'api/file';
import { appendFileToFormData } from 'common/utils';
import { ControllerRenderProps } from 'react-hook-form';
import { OtherFilesFormFields } from 'pages/Request/partials/OtherFileUploader/types';
import { IUploadTemplateCbdFormFields } from 'pages/cbd/hok/partials/UploadForm/types';
import { ModifFormFields } from 'pages/Request/partials/Modif/types';
import { SupplierTemplateFilesFormFields } from 'components/TemplateUploader/types';
import { useAppDispatch } from 'store/hooks';
import { getRequest } from 'store/request/request';
import { UploadCbdFormFields } from 'pages/CreatingRequest/Steps/FileContainer/CbdForm/types';

export type Controller =
  | ControllerRenderProps<UploadCbdFormFields, 'cbdFiles'>
  | ControllerRenderProps<IUploadTemplateCbdFormFields, 'templateCbdFiles'>
  | ControllerRenderProps<SupplierTemplateFilesFormFields, 'templateFiles'>
  | ControllerRenderProps<OtherFilesFormFields, 'otherFiles'>
  | ControllerRenderProps<ModifFormFields, 'modif'>;

export const useActionsFileForm = (
  requestId: string,
  formField: Controller,
  fileType: FileType,
  shouldUpdate = true
) => {
  const dispatch = useAppDispatch();
  const { handleChange, handleDelete } = useUploadFile(true, formField);

  const handleUploadFiles = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files;

      if (!file) return;
      await uploadFile({
        urlParameters: [requestId, fileType],
        file: appendFileToFormData(file[0]),
      })
        .then(({ id: fileId }) => handleChange(e, fileId))
        .then(() => {
          if (shouldUpdate) dispatch(getRequest(requestId));

          return null;
        });
    },
    [shouldUpdate, dispatch, fileType, handleChange, requestId]
  );

  const handleDeleteFiles = useCallback(
    async (fileId: number) => {
      await deleteFile({ urlParameters: [requestId, fileId.toString()] })
        .then(() => handleDelete(fileId))
        .then(() => {
          if (shouldUpdate) dispatch(getRequest(requestId));

          return null;
        });
    },
    [dispatch, handleDelete, requestId, shouldUpdate]
  );

  return { handleDeleteFiles, handleUploadFiles };
};
