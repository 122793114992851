import { Translations } from 'translations';
import { CurrentPriceListSliceData } from 'store/priceList/currentPriceList';
import { PricelistStatusCode } from '@ppm/ppm-platform-pricelist-service';

export type NotificationType = {
  handlePriceListFlow: (status: PricelistStatusCode) => Promise<unknown>;
};

export type NotificationPropsByStatusType = {
  t: Translations;
  priceList: CurrentPriceListSliceData;
} & NotificationType;

export type NotificationStatusType = {
  isEdited: boolean;
  needToGenerateAgain: boolean;
  priceList: CurrentPriceListSliceData;
};

export enum NotificationStatuses {
  sign = 'sign',
  reject = 'reject',
  cancel = 'cancel',
  error = 'error',
  gropingError = 'gropingError',
  edit = 'edit',
  generate = 'generate',
  initializeError = 'initializeError',
}
