import React, { useCallback, useState } from 'react';
import { EEntityName } from '@ppm/ppm-platform-feedback-service';
import { sentFeedbackApi } from 'api/feedback/feedback';
import Modal from 'components/Modal/Modal';
import { useAppTranslations } from 'translations';
import {
  RatingStarsInput,
  Heading,
  Flex,
  Text,
  TRatingStarsScore,
  useModals,
} from '@mozaic-ds/react';
import { useModalRateData, setThanYouPageState } from 'store/modalRate/modalRate';
import { ModalRateForm } from 'components/ModalRate/ModalRateForm';
import { useAppDispatch } from 'store/hooks';
import { modalRatePopupId } from './consts';
import { ModalRateProps } from './types';

export const ModalRate: React.FC<ModalRateProps> = ({
  requestId,
  actionAfterSuccessScoreRequest,
  entityName,
}) => {
  const translations = useAppTranslations();
  const dispatch = useAppDispatch();
  const { unregister, register } = useModals();

  const { modalData, isShownThankYouPage, entityId } = useModalRateData().data;
  const [score, setScore] = useState<TRatingStarsScore>(0);

  const sendFeedback = useCallback(
    async (stars: number) => {
      if (modalData && modalData.id && entityId) {
        await sentFeedbackApi({
          body: {
            answerIds: [],
            entityName,
            rating: stars === 0 ? null : stars,
            quizId: modalData.id,
            entityId,
            requestId,
          },
        });
      }
    },
    [entityId, entityName, modalData, requestId]
  );

  const handleClose = useCallback(async () => {
    if (score === 0 && !isShownThankYouPage) await sendFeedback(0);
    // скип формы оценки опросника без выбора оценки
    if (score < 80 && score > 0 && !isShownThankYouPage) await sendFeedback(score); // скип формы детального ответа опросника с оценкой ниже 4
    unregister(modalRatePopupId);
  }, [score, isShownThankYouPage, unregister, sendFeedback]);

  return (
    <Modal
      id={modalRatePopupId}
      title={translations('feedbackTitle')}
      onClose={async () => {
        await handleClose().then(() => {
          dispatch(setThanYouPageState(false));
          setScore(0);
          register(modalRatePopupId);

          if (actionAfterSuccessScoreRequest) actionAfterSuccessScoreRequest();

          return null;
        });
      }}
    >
      <Heading size="s" weight="semi-bold" textAlign="center">
        {isShownThankYouPage ? (
          translations('feedbackThanksHeading')
        ) : (
          <>
            {score === 0 && translations('feedbackHeading')}
            {score >= 80 && translations('feedbackThanksHeading')}
            {score < 80 && score > 0 && translations('feedbackDisappointmentHeading')}
          </>
        )}
      </Heading>
      {score === 0 && (
        <>
          <Flex justifyContent="center">
            <RatingStarsInput
              score={score}
              size="xl"
              onChange={(stars) => {
                if (stars >= 80) sendFeedback(stars);
                setScore(stars);
              }}
            />
          </Flex>
          <Flex justifyContent="space-evenly">
            <Text size="s" theme="light">
              {translations('feedbackLowRating')}
            </Text>
            <Text size="s" theme="light">
              {translations('feedbackHighRating')}
            </Text>
          </Flex>
        </>
      )}

      {!isShownThankYouPage &&
        entityId &&
        score < 80 &&
        score > 0 &&
        modalData &&
        modalData.answers && (
          <ModalRateForm
            answers={modalData.answers}
            score={score}
            requestId={requestId}
            entityId={entityId}
            entityName={entityName}
          />
        )}
    </Modal>
  );
};
