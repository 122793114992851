import React from 'react';
import { useAppTranslations } from 'translations';
import { TabsPanel, TabsList, TabsButton, Tabs } from '@mozaic-ds/react';
import cn from 'classnames';
import { tabs } from './consts';
import styles from './AdminDashboard.module.scss';

const AdminDashboard = () => {
  const translations = useAppTranslations();

  return (
    <Tabs defaultTab={tabs.find(({ isInitialTab }) => isInitialTab)?.name || ''}>
      <TabsList className={cn('mu-pl-300', styles.tabList)} theme="primary-01" width="full">
        {tabs.map(({ name, message }) => (
          <TabsButton key={name} tab={name}>
            {translations(message)}
          </TabsButton>
        ))}
      </TabsList>
      {tabs.map(({ component: Component, name }) => (
        <TabsPanel key={name} tab={name} className="mu-pt-600 mu-pb-150">
          <Component />
        </TabsPanel>
      ))}
    </Tabs>
  );
};

export default AdminDashboard;
