import React, { useEffect } from 'react';
import { Button, DateInput, Select, TextInput, View } from '@mozaic-ds/react';
import { DisplayFilter24 } from '@mozaic-ds/icons/react';
import Layer from 'components/Layer/Layer';
import { useAppTranslations } from 'translations';
import { useExtCurrency } from 'store/cbd/extCurrency';
import { useFiltersData } from 'store/cbd/scheduler/currencySubscriptions/filters';
import FieldWrapper from 'components/FieldWrapper/FieldWrapper';
import { filtersName } from '../hook/consts';
import { useFiltersForm } from '../hook/filters';
import { subscriptionTypeOptions } from '../../SchedulerForm/consts';

const FiltersPopup = (): JSX.Element => {
  const translations = useAppTranslations();
  const {
    form,
    login,
    dateStart,
    dateFinish,
    currency1,
    currency2,
    subscriptionType,
    value,
    term,
    onSubmit,
  } = useFiltersForm();
  const { fromCurrenciesList, toCurrenciesList } = useExtCurrency();
  const { deletedFilters } = useFiltersData();

  useEffect(() => {
    deletedFilters.forEach((id) => form.setValue(id, ''));
  }, [deletedFilters, form]);

  return (
    <Layer
      id="filtersCurrencyPopup"
      icon={<DisplayFilter24 fill="#71706b" />}
      title={translations('filterPopupHeader')}
      resetItems={() => {
        form.reset();
        filtersName.forEach((id) => form.setValue(id, ''));
      }}
      saveItems={onSubmit}
      button={
        <Button leftIcon={<DisplayFilter24 />} variant="bordered" size="s">
          {translations('filters')}
        </Button>
      }
    >
      <View as="form" paddingLeft="mu025" paddingRight="mu025">
        <FieldWrapper label={translations('LDAP')} id="measure">
          <TextInput {...login} size="m" placeholder={translations('LDAP')} />
        </FieldWrapper>
        <FieldWrapper label={translations('subscriptionStartDate')} id="dateStart">
          <DateInput {...dateStart} size="m" placeholder={translations('subscriptionStartDate')} />
        </FieldWrapper>
        <FieldWrapper label={translations('subscriptionEndDate')} id="dateFinish">
          <DateInput {...dateFinish} size="m" placeholder={translations('subscriptionEndDate')} />
        </FieldWrapper>
        <FieldWrapper label={`${translations('currency')} 1`} id="currency1">
          <Select
            {...currency1}
            size="m"
            options={fromCurrenciesList.map((item) => ({
              ...item,
              label: item.label.toUpperCase(),
            }))}
            placeholder={`${translations('currency')} 1`}
          />
        </FieldWrapper>
        <FieldWrapper label={`${translations('currency')} 2`} id="currency2">
          <Select
            {...currency2}
            size="m"
            options={toCurrenciesList.map((item) => ({
              ...item,
              label: item.label.toUpperCase(),
            }))}
            placeholder={`${translations('currency')} 1`}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('subscriptionType')} id="subscriptionType">
          <Select
            {...subscriptionType}
            size="m"
            placeholder={translations('subscriptionType')}
            options={subscriptionTypeOptions.map((item) => ({
              ...item,
              label: translations(item.label),
            }))}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('indicatorValue')} id="value">
          <TextInput
            {...value}
            size="m"
            placeholder={translations('indicatorValue')}
            type="number"
          />
        </FieldWrapper>
        <FieldWrapper label={translations('analyzedDaysPeriod')} id="term">
          <TextInput
            {...term}
            size="m"
            placeholder={translations('analyzedDaysPeriod')}
            type="number"
          />
        </FieldWrapper>
      </View>
    </Layer>
  );
};

export default FiltersPopup;
