import React from 'react';
import { StepperContextProvider } from 'components/Stepper/StepperContext';
import { Loader } from '@mozaic-ds/react';
import { useGetRequestInfo } from 'pages/CreatingRequest/hooks';
import { steps } from './const';
import { CreatingRequest } from './CreatingRequest';

const CreatingRequestContainer = () => {
  const { isLoading, step } = useGetRequestInfo();

  if (isLoading || !step) return <Loader />;

  return (
    <StepperContextProvider initStepId={step} steps={steps}>
      <CreatingRequest />
    </StepperContextProvider>
  );
};

export default CreatingRequestContainer;
