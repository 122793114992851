// todo: создать типизацию роутов проекта и навигации
import {
  ProfileRightsEditor,
  AdminDashboard,
  RequestSocketProvider,
  Requests,
  AllUploads,
  Uploads,
  Bound,
  Layout,
  PriceListSocketProvider,
  CreatingPriceListContainer,
  CreatingRequestContainer,
} from 'pages';
import React, { JSX, useEffect } from 'react';
import { Route, Routes as RoutesRRD } from 'react-router-dom';
import { useUserData } from 'store/auth/user';
import { gtmPageView } from 'router/gtm';
import { useAppTranslations } from 'translations';
import { getHasGrants } from 'common/utils';
import { isProd } from 'common/consts';
import CbdInfo from 'pages/cbd/CbdInfo/CbdInfo';
import Scheduler from 'pages/cbd/Scheduler/Scheduler';
import ExternalData from 'pages/cbd/ExternalData/ExternalData';
import EditTemplate from 'pages/cbd/EditTemplate/EditTemplate';
import CbdAnalysis from 'pages/cbd/CbdAnalysis/CbdAnalysis';
import Analysis from 'pages/cbd/Analysis/Analysis';

// если роутер имеет структуру 'something/:something/*' тогда добавь его в consts
export const Routes = (): JSX.Element => {
  const translations = useAppTranslations();

  const { userGrants, data } = useUserData().user;
  const { id } = data;
  const {
    AdminDashboard: AdminDashboardGrand,
    RequestCreate,
    AdminCBD,
    CBDAnalyse,
  } = getHasGrants(userGrants);

  useEffect(() => {
    if (isProd) gtmPageView(translations, id);
  });

  return (
    <RoutesRRD>
      <Route element={<Layout />} path="/">
        <Route element={<Requests />} index />
        <Route element={<RequestSocketProvider />} path="request/:id" />
        <Route element={<CreatingPriceListContainer />} path="new-price-list/:requestId" />
        <Route element={<PriceListSocketProvider />} path=":requestId/price-list/:priceListId" />
        <Route element={<AllUploads />} path="cbd" />
        <Route element={<Uploads />} path="uploads/:reqId" />
        <Route element={<Bound />} path="uploads/:reqId/bound/:cbdId" />
        <Route element={<CbdInfo />} path="cbd/:cbdId" />
        {AdminCBD && <Route element={<Scheduler />} path="scheduler" />}
        {AdminCBD && <Route element={<ExternalData />} path="external-data" />}
        {AdminCBD && <Route element={<EditTemplate />} path="edit-template" />}
        {CBDAnalyse && <Route element={<CbdAnalysis />} path="cbd-analysis/:cbdId" />}
        {CBDAnalyse && <Route element={<Analysis />} path="analysis" />}
        {RequestCreate && <Route element={<CreatingRequestContainer />} path="create-request" />}
        {AdminDashboardGrand && (
          <>
            <Route element={<AdminDashboard />} path="dashboard" />
            <Route element={<ProfileRightsEditor />} path="rights-replacement/:name/:ldap" />
          </>
        )}
      </Route>
    </RoutesRRD>
  );
};
