import React, { useCallback, useState } from 'react';
import { Button, Flex, TextInput } from '@mozaic-ds/react';
import styles from 'components/Chat/Chat.module.scss';
import { PublishSend24 } from '@mozaic-ds/icons/react';
import { useAppTranslations } from 'translations';
import { ChatType } from '@ppm/ppm-platform-chat-service';
import { sendChatMessage } from 'api/chat';
import { getMessages } from 'store/chat/chat';
import { useAppDispatch } from 'store/hooks';

type Props = {
  id: string;
  isSupplierChat: boolean;
};
const ManageChat = ({ isSupplierChat, id }: Props) => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const [message, setMessage] = useState<string>('');

  const handleSendMessage = useCallback(async () => {
    const { messageId } = await sendChatMessage({
      urlParameters: [id, isSupplierChat ? ChatType.External : ChatType.Internal],
      body: { message },
    });

    if (messageId) {
      dispatch(
        getMessages({ id, chatType: isSupplierChat ? ChatType.External : ChatType.Internal })
      );
    }
    setMessage('');
  }, [id, isSupplierChat, message, dispatch]);

  return (
    <Flex>
      <TextInput
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="mu-mr-150"
      />
      <Button
        className={styles.date}
        size="s"
        type="submit"
        leftIcon={<PublishSend24 className="mu-mt-050" />}
        onClick={handleSendMessage}
      >
        {translations('sendMessage')}
      </Button>
    </Flex>
  );
};

export default ManageChat;
