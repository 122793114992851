import {
  GetNomenclatureLinkUsers,
  GetNomenclatureLink,
  UpsertNomenclatureLink,
} from '@ppm/ppm-platform-api';
import { apiCreator } from 'api/index';

export const getNomenclatureLinkUser = apiCreator<
  typeof GetNomenclatureLinkUsers,
  GetNomenclatureLinkUsers.Response,
  undefined,
  GetNomenclatureLinkUsers.Query
>(GetNomenclatureLinkUsers)[GetNomenclatureLinkUsers.method];

export const getNomenclatureLink = apiCreator<
  typeof GetNomenclatureLink,
  GetNomenclatureLink.Response
>(GetNomenclatureLink)[GetNomenclatureLink.method];

export const setNomenclatureLink = apiCreator<
  typeof UpsertNomenclatureLink,
  UpsertNomenclatureLink.Response,
  undefined,
  UpsertNomenclatureLink.Body
>(UpsertNomenclatureLink)[UpsertNomenclatureLink.method];
