import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';
import { IFilter } from 'common/types';
import { ComponentsCostFilterName } from 'pages/cbd/CbdAnalysis/components/Comparison/filters/ComponentsCostFilters/useComponentsCostFiltersForm';

interface SliceState {
  filters: IFilter<ComponentsCostFilterName>[];
  deletedFilters: ComponentsCostFilterName[];
}

export const filtersSlice = createSlice({
  initialState: {
    filters: [],
    deletedFilters: [],
  },
  name: 'componentsCostFilters',
  reducers: {
    clearComponentsCostDeletedFilters(state) {
      state.deletedFilters = [];
    },
    setComponentsCostFilters(state, action) {
      state.filters = action.payload;
    },
  },
});

export const { setComponentsCostFilters, clearComponentsCostDeletedFilters } = filtersSlice.actions;

export const useComponentsCostFiltersData = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.cbdAnalysis.compare.componentsCost.filters);
};
