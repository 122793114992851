import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initPageable } from 'common/consts';
import { useAppSelector } from 'store/hooks';
import { IGetCbdListQuery } from 'pages/cbd/AllUploads/AllUploads.types';
import { getAveragePricesRequest, IAveragePricesResponse } from 'api/cbd/externalSource';
import { setFailure, setLoading } from '../../helpers';
import { RequiredStateFields, RootState } from '../../types';

interface SliceState extends RequiredStateFields, IAveragePricesResponse {}

const initialState = {
  data: [],
  pageable: initPageable,
  isLoading: false,
  isFailure: false,
};

export const getAveragePrices = createAsyncThunk(
  'cbd/getAveragePrices',
  async (payload: IGetCbdListQuery, { rejectWithValue }) => {
    try {
      return await getAveragePricesRequest(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const averagePricesListSlice = createSlice({
  initialState,
  name: 'averagePricesList',
  reducers: {
    resetAveragePricesStoreData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAveragePrices.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getAveragePrices.fulfilled,
        (state: SliceState, action: PayloadAction<IAveragePricesResponse>) => {
          state.data = action.payload.data;
          state.pageable = action.payload.pageable;
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getAveragePrices.rejected, (state: SliceState) => {
        setFailure(state);
        state.data = [];
        state.pageable = initPageable;
      });
  },
});

export const { resetAveragePricesStoreData } = averagePricesListSlice.actions;

export const useAveragePricesList = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.averagePrices.list);
};
