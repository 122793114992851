import { useController, useForm } from 'react-hook-form';

const useCurrencyRatesForm = () => {
  const form = useForm({
    defaultValues: {
      currencyRateDate: '',
    },
  });

  const { control } = form;

  const { field: currencyRateDate } = useController({ name: 'currencyRateDate', control });

  return { form, currencyRateDate };
};

export default useCurrencyRatesForm;
