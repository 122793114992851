import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FindLinkedSuppliersQuery } from '@ppm/ppm-platform-api';
import { getLinkedSuppliers } from 'api/dictionary';
import { SelectItem } from 'common/types';
import { formatSuppliers } from 'store/dictionary/helpers';
import { omit } from 'lodash';
import { setFailure, setLoading } from '../helpers';
import { RequiredStateFields, RootState } from '../types';
import { useAppSelector } from '../hooks';

type SupplierIdsSliceData = SelectItem[];

interface SliceState extends RequiredStateFields {
  linkedSuppliersId: SupplierIdsSliceData;
  searchValue: string | undefined;
  offset: number;
}

export const getLinkedSuppliersId = createAsyncThunk(
  'linkedSuppliersId/getLinkedSuppliersId',
  async (
    args: (FindLinkedSuppliersQuery & { signal?: AbortSignal }) | undefined,
    { rejectWithValue }
  ) => {
    try {
      return await getLinkedSuppliers({
        conf: { params: omit(args, 'signal'), signal: args?.signal },
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const linkedSuppliersIdSlice = createSlice({
  initialState: {
    linkedSuppliersId: [],
    offset: 0,
    searchValue: undefined,
    isLoading: false,
    isFailure: false,
  },
  name: 'linkedSuppliersId',
  reducers: {
    setSearchValue(state: SliceState, action: PayloadAction<string>) {
      state.searchValue = action.payload || undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLinkedSuppliersId.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(getLinkedSuppliersId.fulfilled, (state: SliceState, action) => {
        const { meta, payload } = action;
        if (Number(meta.arg?.offset)) {
          state.linkedSuppliersId = state.linkedSuppliersId.concat(formatSuppliers(payload.data));
        } else {
          state.linkedSuppliersId = formatSuppliers(payload.data);
        }
        state.isLoading = false;
        state.isFailure = false;
      })
      .addCase(getLinkedSuppliersId.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const { setSearchValue } = linkedSuppliersIdSlice.actions;

export const useLinkedSuppliersIdData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.dictionary.linkedSuppliersId) };
};
