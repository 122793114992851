import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getChatMessages } from 'api/chat';
import { ChatMessage } from '@ppm/ppm-platform-chat-service';
import { ChatType } from '@ppm/ppm-platform-chat-service/enums/chat-type';
import { setFailure, setLoading } from '../helpers';
import { RequiredStateFields, RootState } from '../types';
import { useAppSelector } from '../hooks';

interface SliceState extends RequiredStateFields {
  messages: ChatMessage[];
}

export const getMessages = createAsyncThunk(
  'chat/getUserData',
  async (args: { id: string; chatType: ChatType }, { rejectWithValue }) => {
    try {
      return await getChatMessages({ urlParameters: [args.id, args.chatType] });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const chatMessagesSlice = createSlice({
  initialState: {
    messages: [],
    isLoading: false,
    isFailure: false,
  },
  name: 'chatMessages',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMessages.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(getMessages.fulfilled, (state: SliceState, action) => {
        state.messages = action.payload.data;
        state.isLoading = false;
        state.isFailure = false;
      })
      .addCase(getMessages.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const useChatMessagesData = (): { chatMessages: SliceState } => {
  return { chatMessages: useAppSelector((state: RootState) => state.chatMessages.chatMessages) };
};
