import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetBuyerSiteGroupHierarchy } from '@ppm/ppm-platform-api';
import { getBuyerSiteGroupHierarchy } from 'api/buyerSiteGroup';
import { RequiredStateFields, RootState } from '../types';
import { useAppSelector } from '../hooks';
import { setFailure, setLoading } from '../helpers';

export type BuyerSiteGroupHierarchy = GetBuyerSiteGroupHierarchy.Response['data'];

interface SliceState extends RequiredStateFields {
  data: BuyerSiteGroupHierarchy;
}

export const getBuyerSiteGroupHierarchyData = createAsyncThunk(
  'bsg/getBuyerSiteGroupHierarchyData',
  async (_, { rejectWithValue }) => {
    try {
      return await getBuyerSiteGroupHierarchy({});
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const initialState = {
  data: [],
  isFailure: false,
  isLoading: false,
};

export const buyerSiteGroupHierarchySlice = createSlice({
  initialState,
  name: 'buyerSiteGroupHierarchy',
  reducers: {
    resetBuyerSiteGroupLinks() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBuyerSiteGroupHierarchyData.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getBuyerSiteGroupHierarchyData.fulfilled,
        (state: SliceState, action: PayloadAction<GetBuyerSiteGroupHierarchy.Response>) => {
          state.data = action.payload.data;
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getBuyerSiteGroupHierarchyData.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const { resetBuyerSiteGroupLinks } = buyerSiteGroupHierarchySlice.actions;

export const useBuyerSiteGroupHierarchy = (): { data: SliceState } => {
  return {
    data: useAppSelector((state: RootState) => state.buyerSiteGroup.buyerSiteGroupHierarchy),
  };
};
