import React from 'react';
import { Button, Text, DateInput, Flex, TextInput, Loader } from '@mozaic-ds/react';
import { Label } from 'components/Label/Label';
import { useAppTranslations } from 'translations';
import { useRequestData } from 'store/request/request';
import cn from 'classnames';
import { setEditState } from 'store/priceList/currentPriceList';
import { useAppDispatch } from 'store/hooks';
import { parseDateForMozaicComponent } from 'common/utils';
import { Flatpicker } from 'components/Flatpicker/Flatpicker';
import { useEditPriceListSettingsForm } from 'pages/PriceList/partials/PriceListSettings/hooks';
import { useValidationPriselistDate } from 'store/priceList/validation';
import { format } from 'date-fns';
import { DATE_FORMAT } from 'common/consts';
import styles from '../../PriceList.module.scss';

export const Form = () => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const { request } = useRequestData().data;
  const { nextPricelistDate } = useValidationPriselistDate().data;

  const {
    formState,
    watch,
    pricelistNumber,
    contractNumber,
    contractStartDate,
    pricelistDates,
    reset,
    handleSubmit,
    submitForm,
  } = useEditPriceListSettingsForm();

  const { isValid, isDirty, isSubmitting, errors } = formState;

  const resetForm = () => {
    reset();
    dispatch(setEditState(false));
  };
  const isValidPricelistDates =
    new Date(format(new Date(nextPricelistDate).setHours(23, 59, 59), DATE_FORMAT)).getTime() <=
    new Date(
      format(new Date(watch('pricelistDates')[0]).setHours(23, 59, 59), DATE_FORMAT)
    ).getTime();

  return (
    <Flex direction="column" className={styles.basis}>
      {request?.supplierName && (
        <Label text={translations('supplier')}>
          <TextInput value={request.supplierName} isDisabled />
        </Label>
      )}
      <Label text={translations('contractNumber')} className="mu-mr-050">
        <TextInput {...contractNumber} isInvalid={Boolean(errors && errors.contractNumber)} />
        {errors && errors.contractNumber && (
          <Text as="p" size="s" theme="danger" className="mu-mt-025">
            {errors.contractNumber.message}
          </Text>
        )}
      </Label>
      <Label text={translations('from')}>
        <DateInput
          {...contractStartDate}
          className="mc-text-input"
          isInvalid={Boolean(errors && errors.contractStartDate)}
        />
        {errors && errors.contractStartDate && (
          <Text as="p" size="s" theme="danger" className="mu-mt-025">
            {errors.contractStartDate.message}
          </Text>
        )}
      </Label>
      <Flex justifyContent="space-between">
        <Label text={translations('devision')} className={cn('mu-mr-100', styles.groupFields)}>
          <TextInput value={request?.departmentNumber} isDisabled />
        </Label>
        <Label text={translations('pricelistNumber')} className={styles.groupFields}>
          <TextInput {...pricelistNumber} />
        </Label>
      </Flex>
      <Text className="mu-mt-100">{translations('pricelistValid')}</Text>
      <Flatpicker
        id="pricelistDates"
        label={translations('with')}
        field={pricelistDates}
        options={{
          minDate: nextPricelistDate
            ? parseDateForMozaicComponent(new Date(nextPricelistDate), 'MM.dd.yyyy')
            : undefined,
          allowInput: false,
        }}
      />
      {nextPricelistDate && !isValidPricelistDates && (
        <Text as="p" size="s" theme="danger" className="mu-mt-025">
          Дата начала действия прейскуранта не может быть меньше{' '}
          {parseDateForMozaicComponent(nextPricelistDate)}
        </Text>
      )}
      <Flex marginTop="mu150">
        <Button
          onClick={handleSubmit((values) => submitForm(values))}
          className="mu-mr-100"
          isDisabled={!(isValid && isDirty) || isSubmitting || !isValidPricelistDates}
        >
          {isSubmitting && <Loader size="s" className="mu-mr-050" />}
          {translations('savePriceListSettings')}
        </Button>
        <Button onClick={resetForm} variant="bordered" theme="neutral">
          {translations('cancel')}
        </Button>
      </Flex>
    </Flex>
  );
};
