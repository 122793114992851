import { GetRequestReasonResponse } from '@ppm/ppm-platform-api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getReasons as getDictionaryReason } from 'api/dictionary';
import { SelectItem } from 'common/types';
import { Translations } from 'translations';
import { formatToSelectValue, setFailure, setLoading } from '../helpers';
import { RequiredStateFields, RootState } from '../types';
import { useAppSelector } from '../hooks';

type ReasonsSliceData = SelectItem[];

interface SliceState extends RequiredStateFields {
  reasons: ReasonsSliceData;
}

export const getOtherSelectValue = (t: Translations) => ({ value: 0, label: t('otherReason') });

export const getReasons = createAsyncThunk<GetRequestReasonResponse, Translations>(
  'reasons/getReasons',
  async (_, { rejectWithValue }) => {
    try {
      return await getDictionaryReason({});
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const reasonsSlice = createSlice({
  initialState: { reasons: [], isLoading: false, isFailure: false },
  name: 'reasons',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReasons.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(getReasons.fulfilled, (state: SliceState, action) => {
        state.reasons = formatToSelectValue(action.payload.data);
        state.reasons.push(getOtherSelectValue(action.meta.arg));
        state.isLoading = false;
        state.isFailure = false;
      })
      .addCase(getReasons.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const useReasonsData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.dictionary.reasons) };
};
