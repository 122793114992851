import * as yup from 'yup';
import { minContractStartDate } from 'pages/PriceList/partials/PriceListSettings/consts';

export const schema = yup
  .object()
  .shape({
    contractNumber: yup
      .string()
      .required('Обязательно для заполнения')
      .min(5, 'Договор поставки - это номер из 5 цифр')
      .max(5, 'Договор поставки - это номер из 5 цифр')
      .matches(/^\d+$/, { message: 'Договор поставки - это номер из 5 цифр' }),
    contractStartDate: yup
      .date()
      .typeError('Обязательно для заполнения')
      .min(minContractStartDate, 'Договор поставки не мог быть заключен ранее 2000 года')
      .required('Обязательно для заполнения'),
    pricelistNumber: yup.string().required('Обязательно для заполнения'),
    pricelistStartDate: yup
      .date()
      .typeError('Обязательно для заполнения')
      .max(
        yup.ref('pricelistEndDate'),
        'Начало действия прейскуранта не может быть позднее окончания'
      ),
    pricelistEndDate: yup
      .date()
      .typeError('Обязательно для заполнения')
      .min(
        yup.ref('pricelistStartDate'),
        'Начало действия прейскуранта не может быть позднее окончания'
      ),
  })
  .required();
