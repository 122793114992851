import { useCallback, useEffect } from 'react';
import { useModals } from '@mozaic-ds/react';
import { useAppTranslations } from 'translations';
import {
  setTemplateStructureSortable,
  setVisibleTemplateStructureColumns,
} from 'store/cbd/templateStructure/columns';
import { createColumns, getHasGrants } from 'common/utils';
import { useAppDispatch } from 'store/hooks';
import { useMount } from 'hook/useMount';
import { ICreateAttribute } from 'api/cbd/templates';
import { useForm } from 'react-hook-form';
import { useUserData } from 'store/auth/user';
import { ICatalogCostTreeItem } from 'common/cbd/types';
import { IDataTableColumn, SortDirection } from 'common/types';
import { TemplateStructureColumns } from './consts';
import { ITemplateStructureColumnSort } from '../ParseTable.types';
import { FnMappingEditClick, FnAttributesEditClick, useGetColumnTemplate } from './helpers';

type TemplateStructureSortConfig = {
  [key in TemplateStructureColumns]: ITemplateStructureColumnSort | null;
};

export interface IMappingModal {
  title: string;
  value: string;
  callBack: (value: string) => void;
}
export interface IAttributesModal {
  parentId: number;
  parentCode: string;
  attributes: Array<ICreateAttribute>;
  callBack: (value: string) => void;
}

export const sortConfig: TemplateStructureSortConfig = {
  [TemplateStructureColumns.CODE]: null,
  [TemplateStructureColumns.NAME]: null,
  [TemplateStructureColumns.MAPPING_SUM]: null,
  [TemplateStructureColumns.MAPPING_CUR]: null,
  [TemplateStructureColumns.REQUIRED]: null,
  [TemplateStructureColumns.UNIQUE]: null,
  [TemplateStructureColumns.ERR_REQUIRED_SUM_ID]: null,
  [TemplateStructureColumns.ERR_REQUIRED_CUR_ID]: null,
  [TemplateStructureColumns.ATTRIBUTES]: null,
};

export const useGetColumns = (
  setMappingModal: React.Dispatch<React.SetStateAction<IMappingModal>>,
  setAttributesModal: React.Dispatch<React.SetStateAction<IAttributesModal>>
): {
  columns: Record<TemplateStructureColumns, IDataTableColumn<ICatalogCostTreeItem>>;
} => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();
  const { open } = useModals();
  const modalName = 'fieldEdit';

  const handleSort = (field: ITemplateStructureColumnSort, sortDirection: SortDirection) => {
    dispatch(setTemplateStructureSortable({ field, sortDirection }));
  };

  const onMappingEditClick = ({ value, title, callBack }: FnMappingEditClick) => {
    setMappingModal({
      title,
      value,
      callBack,
    });
    open(modalName);
  };

  const onAttributesEditClick = ({
    callBack,
    attributes,
    parentId,
    parentCode,
  }: FnAttributesEditClick) => {
    setAttributesModal({
      callBack,
      attributes,
      parentId,
      parentCode,
    });
    open('attributesEdit');
  };

  const columnTemplate = useGetColumnTemplate(onMappingEditClick, onAttributesEditClick);

  return {
    columns: createColumns<
      TemplateStructureColumns,
      ICatalogCostTreeItem,
      ITemplateStructureColumnSort
    >({
      columnTemplate,
      translations,
      handleSort,
      sortConfig,
    }),
  };
};

export const useColumnsForm = () => {
  const dispatch = useAppDispatch();
  const { userGrants } = useUserData().user;
  const { CBDAnalyse } = getHasGrants(userGrants);
  const defaultValues = {
    code: true,
    name: true,
    mappingSum: true,
    mappingCur: true,
    required: true,
    unique: true,
    errRequiredSumId: true,
    errRequiredCurId: true,
    attributes: true,
  };

  const form = useForm<Record<TemplateStructureColumns, boolean>>({
    defaultValues,
  });

  useMount(() => {
    dispatch(setVisibleTemplateStructureColumns(defaultValues));
  });

  const saveColumnsFilter = useCallback(() => {
    form.handleSubmit((data) => {
      dispatch(setVisibleTemplateStructureColumns(data));
    })();
  }, [dispatch, form]);

  const resetColumnsFilters = () => {
    form.reset();
    dispatch(setVisibleTemplateStructureColumns(form.getValues()));
  };

  useEffect(() => {
    if (!CBDAnalyse) {
      saveColumnsFilter();
    }
  }, [dispatch, saveColumnsFilter, CBDAnalyse]);

  return { form, saveColumnsFilter, resetColumnsFilters };
};
