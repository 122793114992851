import axios from 'axios';
import { useAppDispatch } from 'store/hooks';
import { useState } from 'react';
import {
  setData,
  setError,
  setStep,
  useRequestCreationData,
} from 'store/requestCreation/requestCreation';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useMount } from 'hook/useMount';
import { getRequest } from 'store/request/request';
import { Steps } from './types';

export const useGetRequestInfo = () => {
  const dispatch = useAppDispatch();

  const { step } = useRequestCreationData().data;
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(false);

  useMount(async () => {
    if (searchParams.has('id')) {
      setLoading(true);
      try {
        const { data } = await dispatch(getRequest(searchParams.get('id') as string)).unwrap();
        const { request, flowRights } = data;
        dispatch(setData({ request, grants: flowRights.grants }));
        setLoading(false);
      } catch (error: unknown) {
        if (!axios.isAxiosError(error)) return;
        if (location.state) dispatch(setData({ request: location.state, grants: {} }));

        dispatch(setError(error.response?.data.message));

        setLoading(false);
      }
    } else {
      dispatch(setStep(Steps.start));
    }
  });
  return { isLoading, step };
};
