import React from 'react';
import { IColumnTemplate } from 'common/utils';
import { IAveragePricesData } from 'api/cbd/externalSource';
import { Text } from '@mozaic-ds/react';
import classNames from 'classnames';
import { useAppTranslations } from 'translations';
import { AveragePricesColumns } from './consts';
import styles from '../AveragePrices.module.scss';

export const useGetColumnTemplate = (): {
  [key in AveragePricesColumns]: IColumnTemplate<IAveragePricesData>;
} => {
  const translations = useAppTranslations();

  const columnTemplate = {
    [AveragePricesColumns.NAME]: {
      message: translations('significative'),
      isSortable: false,
      render: (record: IAveragePricesData) => record.name,
    },
    [AveragePricesColumns.VALUE]: {
      message: translations('value'),
      isSortable: false,
      render: (record: IAveragePricesData) => record.value,
    },
    [AveragePricesColumns.VALUE_YTD]: {
      message: `%${translations('shortChange')} YTD`,
      isSortable: false,
      render: (record: IAveragePricesData) => (
        <Text
          as="span"
          className={classNames({
            [styles.wrapper]: true,
            [styles.green]: Number(record.valueYTD) < 0,
            [styles.red]: Number(record.valueYTD) > 0,
          })}
        >
          {record.valueYTD}
        </Text>
      ),
    },
    [AveragePricesColumns.VALUE_3M]: {
      message: `%${translations('shortChange')} 3М`,
      isSortable: false,
      render: (record: IAveragePricesData) => (
        <Text
          as="span"
          className={classNames({
            [styles.wrapper]: true,
            [styles.green]: Number(record.value3M) < 0,
            [styles.red]: Number(record.value3M) > 0,
          })}
        >
          {record.value3M}
        </Text>
      ),
    },
    [AveragePricesColumns.VALUE_6M]: {
      message: `%${translations('shortChange')} 6М`,
      isSortable: false,
      render: (record: IAveragePricesData) => (
        <Text
          as="span"
          className={classNames({
            [styles.wrapper]: true,
            [styles.green]: Number(record.value6M) < 0,
            [styles.red]: Number(record.value6M) > 0,
          })}
        >
          {record.value6M}
        </Text>
      ),
    },
    [AveragePricesColumns.VALUE_9M]: {
      message: `%${translations('shortChange')} 9М`,
      isSortable: false,
      render: (record: IAveragePricesData) => {
        return (
          <Text
            as="span"
            className={classNames({
              [styles.wrapper]: true,
              [styles.green]: Number(record.value9M) < 0,
              [styles.red]: Number(record.value9M) > 0,
            })}
          >
            {record.value9M}
          </Text>
        );
      },
    },
    [AveragePricesColumns.VALUE_12M]: {
      message: `%${translations('shortChange')} 12М`,
      isSortable: false,
      render: (record: IAveragePricesData) => (
        <Text
          as="span"
          className={classNames({
            [styles.wrapper]: true,
            [styles.green]: Number(record.value12M) < 0,
            [styles.red]: Number(record.value12M) > 0,
          })}
        >
          {record.value12M}
        </Text>
      ),
    },
  };

  return columnTemplate;
};
