import { combineReducers } from '@reduxjs/toolkit';
import { columnsSlice } from './columns';
import { externalSourceFiltersSlice } from './filters';
import { externalSourceTableDataSlice } from './tableData';

export const analysis = combineReducers({
  columns: columnsSlice.reducer,
  filters: externalSourceFiltersSlice.reducer,
  tableData: externalSourceTableDataSlice.reducer,
});
