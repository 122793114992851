import React, { FC } from 'react';
import {
  Flex,
  Loader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Text,
  View,
} from '@mozaic-ds/react';
import { TRequest } from 'pages/Requests/types';
import { useNavigate } from 'react-router-dom';
import { useColumnsData } from 'store/requests/columns';
import { useAppTranslations } from 'translations';
import { useGetColumns } from 'pages/Requests/hooks/columns';
import { gtmClickByRequestRow } from 'pages/Requests/gtm';
import { isLmEnvironment, isProd, isSupplierEnvironment } from 'common/consts';
import { RequestStatusCode } from '@ppm/ppm-platform-request-service';
import { useAppDispatch } from 'store/hooks';
import { getRequest } from 'store/request/request';

type RequestTableProps = {
  isLoading: boolean;
  rows: Array<TRequest>;
};

const RequestTable: FC<RequestTableProps> = ({ rows, isLoading }) => {
  const dispatch = useAppDispatch();
  const translation = useAppTranslations();

  const navigate = useNavigate();
  const { columns: columnsFilter, sortedField } = useColumnsData().data;
  const { columns } = useGetColumns();

  const handleClickRow = (row: TRequest) => {
    if (isLmEnvironment && isProd) {
      gtmClickByRequestRow(translation, row.id);
    }
    if (
      isSupplierEnvironment &&
      [
        RequestStatusCode.Draft,
        RequestStatusCode.InitializingRequest,
        RequestStatusCode.ErrorFromInitialize,
        RequestStatusCode.InitializeSubrequests,
      ].includes(row.statusCode)
    ) {
      return navigate(`/create-request?id=${row.id}`, { state: row });
    }
    dispatch(getRequest(row.id));
    return navigate(`request/${row.id}`);
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHeader>
            <TableRow>
              {columnsFilter.map(({ key, isShown }) => {
                return isShown ? (
                  <TableHeaderCell
                    key={key}
                    isSortable={columns[key].isSortable}
                    onSort={columns[key].onSort}
                    sortDirection={
                      sortedField && sortedField?.field === key
                        ? sortedField.sortDirection
                        : undefined
                    }
                  >
                    {columns[key].label}
                  </TableHeaderCell>
                ) : null;
              })}
            </TableRow>
          </TableHeader>
          <TableBody>
            {rows.length > 0 &&
              rows.map((row) => (
                <TableRow key={row.id} onClick={() => handleClickRow(row)}>
                  {columnsFilter.map(({ key, isShown }) => {
                    return isShown ? (
                      <TableCell key={`row-${row.id}-${key}`}>{columns[key].render(row)}</TableCell>
                    ) : null;
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {rows.length === 0 && (
        <View margin="mu200">
          <Flex justifyContent="center" alignItems="center" direction="column">
            {isLoading ? <Loader /> : <Text as="span">{translation('noData')}</Text>}
          </Flex>
        </View>
      )}
    </>
  );
};

export default RequestTable;
