import { ICbdErrorUpload } from 'common/cbd/types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';
import { getLoadErrors as getLoadErrorsApi } from '../../api/cbd/cbd';

interface SliceState {
  loadErrors: ICbdErrorUpload[];
  isLoadErrorsPending: boolean;
}

const initialState = {
  loadErrors: [],
  isLoadErrorsPending: false,
};

export const getLoadErrors = createAsyncThunk(
  'loadErrors/getLoadErrors',
  async (payload: number, { rejectWithValue }) => {
    try {
      const { data } = await getLoadErrorsApi(payload);

      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const loadErrorsSlice = createSlice({
  initialState,
  name: 'loadErrors',
  reducers: {
    resetLoadErrors: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getLoadErrors.fulfilled,
        (state: SliceState, action: PayloadAction<ICbdErrorUpload[]>) => {
          state.loadErrors = action.payload;
          state.isLoadErrorsPending = false;
        }
      )
      .addCase(getLoadErrors.pending, (state: SliceState) => {
        state.isLoadErrorsPending = true;
        state.loadErrors = [];
      })
      .addCase(getLoadErrors.rejected, (state: SliceState) => {
        state.isLoadErrorsPending = false;
        state.loadErrors = [];
      });
  },
});

export const { resetLoadErrors } = loadErrorsSlice.actions;

export const useLoadErrors = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.loadErrors);
};
