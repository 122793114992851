import React from 'react';
import { useMount } from 'hook/useMount';
import { getReasons, useReasonsData } from 'store/dictionary/reasons';
import { getRequest, useRequestData } from 'store/request/request';
import { getMessages, useChatMessagesData } from 'store/chat/chat';
import { ChatType } from '@ppm/ppm-platform-chat-service';
import { Loader } from '@mozaic-ds/react';
import { useAppDispatch } from 'store/hooks';
import { useRequestCreationData } from 'store/requestCreation/requestCreation';
import { getHasGrants } from 'common/utils';
import { useAppTranslations } from 'translations';
import { getDueDate } from 'store/dictionary/dueDate';
import { FinishRequestCreation } from './FinishRequestCreation';

export const FinishRequestCreationContainer = () => {
  const dispatch = useAppDispatch();
  const t = useAppTranslations();

  const {
    request: currentRequest,
    requestGrants,
    isLoading: isRequestLoading,
  } = useRequestData().data;
  const { request: creationData } = useRequestCreationData().data;
  const { reasons } = useReasonsData().data;
  const { isLoading } = useChatMessagesData().chatMessages;

  const { RequestExternalChatRead, RequestInternalChatRead } = getHasGrants(requestGrants);

  useMount(() => {
    dispatch(getDueDate());
    if (reasons.length === 0) dispatch(getReasons(t));
    if (creationData?.id) dispatch(getRequest(creationData.id));
    if (RequestExternalChatRead || RequestInternalChatRead)
      dispatch(getMessages({ id: creationData?.id as string, chatType: ChatType.External }));
  });

  if (!currentRequest || !creationData || isLoading || isRequestLoading) return <Loader />;

  return <FinishRequestCreation request={currentRequest} />;
};
