import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetPricelistsResponse } from '@ppm/ppm-platform-api';
import { getPricelist } from 'api/pricelist';
import { setFailure, setLoading } from 'store/helpers';
import { RootState, RequiredStateFields } from '../types';
import { useAppSelector } from '../hooks';

interface SliceState extends RequiredStateFields {
  data: GetPricelistsResponse;
}

export const getPricelistData = createAsyncThunk(
  'priceList/getPricelistData',
  async (id: string, { rejectWithValue }) => {
    try {
      return await getPricelist({ urlParameters: [id] });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const priceListSlice = createSlice({
  initialState: {
    data: [],
    isLoading: false,
    isFailure: false,
  },
  name: 'priceList',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPricelistData.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(getPricelistData.fulfilled, (state: SliceState, action) => {
        state.data = action.payload;
        state.isLoading = false;
        state.isFailure = false;
      })
      .addCase(getPricelistData.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const usePriceListData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.priceList.priceList) };
};
