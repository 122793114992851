import {
  bulkDeleteCurrencySubscriptions,
  bulkUpdateCurrencySubscriptions,
  IPostCurrencySubscriptions,
  postCurrencySubscriptions,
} from 'api/cbd/scheduler';
import { useCallback } from 'react';
import {
  setEditItems,
  setIsAdd,
  setSelectedRowsId,
  useCurrencySubscriptionsList,
} from 'store/cbd/scheduler/currencySubscriptions/currencySubscriptionsList';
import { useAppDispatch } from 'store/hooks';
import { useTableData } from './data';

const useAlertCurrencyActions = () => {
  const dispatch = useAppDispatch();
  const { selectedRowsId, data, isAdd, editItems } = useCurrencySubscriptionsList();
  const { updateTableData } = useTableData();

  const deleteExtDataItems = useCallback(async () => {
    await bulkDeleteCurrencySubscriptions(selectedRowsId);
    updateTableData();
    dispatch(setSelectedRowsId([]));
  }, [dispatch, selectedRowsId, updateTableData]);

  const onAddClick = useCallback(async () => {
    try {
      if (isAdd) {
        const {
          login,
          dateStart,
          dateFinish,
          currency1,
          currency2,
          subscriptionType,
          value,
          term,
        } = data[0];

        await postCurrencySubscriptions({
          login,
          dateStart,
          dateFinish,
          currencyId1: currency1,
          currencyId2: currency2,
          subscriptionType,
          value: Number(value),
          term: Number(term),
        });
        dispatch(setEditItems([]));
        updateTableData();
      }
      dispatch(setIsAdd(!isAdd));
    } catch {
      dispatch(setEditItems([]));
      dispatch(setIsAdd(!isAdd));
      updateTableData();
    }
  }, [isAdd, dispatch, data, updateTableData]);

  const onEditClick = useCallback(async () => {
    try {
      dispatch(setSelectedRowsId([]));

      if (editItems.length) {
        const changedRows = editItems.reduce((prev: IPostCurrencySubscriptions[], curr) => {
          const item = data.find((elem) => elem.id.toString() === curr);

          if (item) {
            const {
              id,
              login,
              dateStart,
              dateFinish,
              currency1,
              currency2,
              subscriptionType,
              value,
              term,
            } = item;

            prev.push({
              id,
              login,
              dateStart,
              dateFinish,
              currencyId1: currency1,
              currencyId2: currency2,
              subscriptionType,
              value: Number(value),
              term: Number(term),
            });
          }
          return prev;
        }, []);

        await bulkUpdateCurrencySubscriptions(changedRows);

        dispatch(setEditItems([]));
        updateTableData();
        return;
      }

      dispatch(setEditItems(selectedRowsId));
    } catch {
      dispatch(setEditItems([]));
      updateTableData();
    }
  }, [editItems, dispatch, selectedRowsId, updateTableData, data]);

  const onCancel = () => {
    updateTableData();
    dispatch(setIsAdd(false));
    dispatch(setEditItems([]));
    dispatch(setSelectedRowsId([]));
  };

  return {
    deleteExtDataItems,
    onAddClick,
    onEditClick,
    onCancel,
  };
};

export default useAlertCurrencyActions;
