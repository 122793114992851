import React from 'react';
import { Answer } from '@ppm/ppm-platform-feedback-service';
import { useFeedbackForm } from 'components/ModalRate/hooks';
import { Button, CheckBox, Flex, TextArea } from '@mozaic-ds/react';
import { Controller } from 'react-hook-form';
import { useModalRateData } from 'store/modalRate/modalRate';
import { useAppTranslations } from 'translations';
import { OTHER_ANSWER_ID } from 'components/ModalRate/consts';
import { ModalRateProps } from './types';

type Props = Pick<ModalRateProps, 'requestId' | 'entityName'> & {
  answers: Answer[];
  score: number;
  entityId: string;
};

export const ModalRateForm = ({ answers, score, requestId, entityId, entityName }: Props) => {
  const translations = useAppTranslations();

  const { modalData } = useModalRateData().data;
  const { form, updateFormData, otherText } = useFeedbackForm(answers);

  const indexOfOtherCheckbox = form
    .getValues()
    .answerFields.findIndex((field) => field.id === OTHER_ANSWER_ID);
  const otherCheckboxWatcher = form.watch(`answerFields.${indexOfOtherCheckbox}`);

  return (
    <Flex alignItems="center" direction="column">
      <Flex direction="column" alignItems="flex-start">
        {answers.map((answer, index) => (
          <Controller
            key={answer.id}
            render={({ field }) => (
              <CheckBox
                className="mu-pb-075"
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  if (!e.target.checked && answer.id === OTHER_ANSWER_ID)
                    form.setValue('otherText', '');
                }}
                isChecked={Boolean(field.value)}
                value={field.name}
              >
                {answer.description}
              </CheckBox>
            )}
            name={`answerFields.${index}.isChecked`}
            control={form.control}
          />
        ))}
        {otherCheckboxWatcher.isChecked && (
          <TextArea {...otherText} placeholder={translations('feedbackOtherTextPlaceholder')} />
        )}
      </Flex>
      {modalData && modalData.id && (
        <Button
          className="mu-mt-100"
          onClick={form.handleSubmit((values) =>
            updateFormData({ values, score, quizId: modalData.id, requestId, entityId, entityName })
          )}
        >
          {translations('sendFeedbackButtonText')}
        </Button>
      )}
    </Flex>
  );
};
