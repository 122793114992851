import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getNomenclature } from 'api/dictionary/nomenclature';
import { setFailure, setLoading } from 'store/helpers';
import { RequiredStateFields, RootState } from 'store/types';
import { DEFAULT_UNAVAILABLE_ROLE } from 'store/dashboard/consts';
import { useAppSelector } from 'store/hooks';
import { RootType, Tree } from 'store/dashboard/model/nomenclature/tree';
import { formatNomenclatureData } from 'store/dashboard/helpers';
import { Leaf } from 'store/dashboard/model/nomenclature/leaf';
import { NomenclatureLink } from './nomenclatureLinks';

export interface SliceState extends RequiredStateFields {
  tree: Tree | null;
  data: RootType | null;
  checkedNomenclatureGroupedByRole: NomenclatureLink;
  defaultNomenclatureLinks: NomenclatureLink;
  selectedRole: number;
  selectedNomenclature: Array<number[]>;
}

const initialState = {
  tree: null,
  data: null,
  checkedNomenclatureGroupedByRole: null,
  defaultNomenclatureLinks: null,
  isLoading: false,
  isFailure: false,
  selectedRole: DEFAULT_UNAVAILABLE_ROLE,
  selectedNomenclature: [],
};

export const getNomenclatureData = createAsyncThunk(
  'dashboard/getNomenclature',
  async (args: { path: number[] }, { rejectWithValue }) => {
    try {
      return await getNomenclature({
        conf: { params: { path: args.path.length === 0 ? undefined : args.path.join() } },
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const nomenclatureSlice = createSlice({
  initialState,
  name: 'nomenclature',
  reducers: {
    setLink(
      state: SliceState,
      action: PayloadAction<{
        value: boolean;
        path: number[];
      }>
    ) {
      const { value, path } = action.payload;

      if (state.tree && state.data) {
        const tree = state.tree.change(value, path).clone();
        state.tree = tree;
        state.data = tree.get();
        state.selectedNomenclature = tree.findAllCheckedNodes();
        state.checkedNomenclatureGroupedByRole = {
          ...state.checkedNomenclatureGroupedByRole,
          [state.selectedRole]: tree.findAllCheckedNodes(),
        };
      }
    },

    setSelectedRole(state: SliceState, action: PayloadAction<number>) {
      state.selectedRole = action.payload;

      if (state.tree && state.data) {
        state.tree = state.tree
          .clearTree()
          .fillDefaultValue(state.checkedNomenclatureGroupedByRole, state.selectedRole)
          .clone();
        state.data = state.tree.get();
      }
    },

    resetNomenclatureStoreToDefaultValue(
      state: SliceState,
      action: PayloadAction<{ links: NomenclatureLink }>
    ) {
      state.selectedNomenclature = [];
      state.defaultNomenclatureLinks = action.payload.links;
      state.checkedNomenclatureGroupedByRole = action.payload.links;
      if (state.tree && state.data) {
        state.tree = state.tree
          .clearTree()
          .fillDefaultValue(action.payload.links, state.selectedRole)
          .clone();
        state.data = state.tree.get();
      }
    },

    clearNomenclatureStore(state: SliceState) {
      state.selectedNomenclature = [];
      state.defaultNomenclatureLinks = null;
      state.checkedNomenclatureGroupedByRole = null;
      if (state.tree && state.data) {
        state.tree = state.tree.clearTree().clone();
        state.data = state.tree.get();
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNomenclatureData.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(getNomenclatureData.fulfilled, (state: SliceState, action) => {
        const { meta, payload } = action;
        const { path } = meta.arg;
        const { data } = payload;
        const nodes = formatNomenclatureData(data);

        if (state.tree && state.data) {
          state.tree = state.tree
            .add({
              nodes,
              path,
            })
            .fillDefaultValue(
              state.checkedNomenclatureGroupedByRole || state.defaultNomenclatureLinks,
              state.selectedRole
            )
            .clone();
          state.data = state.tree.get();
        } else {
          state.tree = new Tree({
            tree: nodes.map((node) => new Leaf(node)),
          })
            .fillDefaultValue(state.defaultNomenclatureLinks, state.selectedRole)
            .clone();
          state.data = state.tree.get();
        }
        state.isLoading = false;
        state.isFailure = false;
      })
      .addCase(getNomenclatureData.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const {
  setSelectedRole,
  setLink,
  clearNomenclatureStore,
  resetNomenclatureStoreToDefaultValue,
} = nomenclatureSlice.actions;

export const useNomenclatureData = (): { nomenclature: SliceState } => {
  return { nomenclature: useAppSelector((state: RootState) => state.dashboard.nomenclature) };
};
