import React, { FC, useCallback, useEffect, KeyboardEvent } from 'react';
import { useAppDispatch } from 'store/hooks';
import { IUseInput } from 'hook/useInput';
import { useInput } from 'hook';
import { TextInput, ITextInputProps, IconButton, Flex, TTextInputSize } from '@mozaic-ds/react';
import { DisplaySearch24, DisplaySearch32 } from '@mozaic-ds/icons/react';
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useMount } from 'hook/useMount';
import styles from './Search.module.scss';

type SearchProps = {
  clearOffset?: ActionCreatorWithoutPayload;
  setStoreValue: ActionCreatorWithPayload<string>;
  clear?: ActionCreatorWithoutPayload;
  defaultValue?: string;
  size: TTextInputSize;
};

export const Search: FC<SearchProps & ITextInputProps> = ({
  placeholder,
  className,
  clearOffset,
  setStoreValue,
  clear,
  defaultValue,
  size,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const searchIdInput: IUseInput = useInput(defaultValue || '');

  useMount(() => {
    if (defaultValue) dispatch(setStoreValue(defaultValue));
  });

  useEffect(() => {
    if (clearOffset) dispatch(clearOffset());
    if (clear && !searchIdInput.value) dispatch(clear());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchIdInput.value]);

  const handleSearchByIcon = useCallback(() => {
    if (searchIdInput.value) dispatch(setStoreValue(searchIdInput.value));
  }, [dispatch, searchIdInput.value, setStoreValue]);

  return (
    <Flex className={className} radius="s">
      <TextInput
        className={styles.input}
        size={size}
        placeholder={placeholder}
        onChange={(e) => {
          searchIdInput.setValue(e.target.value);
          if (!e.target.value) dispatch(setStoreValue(''));
        }}
        onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') dispatch(setStoreValue((e.target as HTMLInputElement).value));
        }}
        isDisabled={props.isDisabled}
        type="search"
      />
      <IconButton
        className={styles.icon}
        size={size}
        theme="primary"
        onClick={handleSearchByIcon}
        type="button"
      >
        <>
          {size === 'm' && <DisplaySearch32 />}
          {size === 's' && <DisplaySearch24 />}
        </>
      </IconButton>
    </Flex>
  );
};
