import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Quiz } from '@ppm/ppm-platform-feedback-service';
import { RequestGateway } from '@ppm/ppm-platform-api';
import { RootState } from '../types';
import { useAppSelector } from '../hooks';

export type QuizSocketData = RequestGateway.NewQuiz.Message;

interface SliceState {
  modalData: Quiz | null;
  isShownThankYouPage: boolean;
  entityId: string | undefined;
}

const initialState = { modalData: null, isShownThankYouPage: false, entityId: undefined };

export const modalRateSlice = createSlice({
  initialState,
  name: 'modalRate',
  reducers: {
    setModalRateDate(state: SliceState, action: PayloadAction<QuizSocketData>) {
      state.modalData = action.payload.quiz;
    },
    setThanYouPageState(state: SliceState, action: PayloadAction<boolean>) {
      state.isShownThankYouPage = action.payload;
    },
    setEntityId(state: SliceState, action: PayloadAction<string>) {
      state.entityId = action.payload;
    },
  },
});

export const { setModalRateDate, setThanYouPageState, setEntityId } = modalRateSlice.actions;

export const useModalRateData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.modalRate.modalRate) };
};
