import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/types';
import { useAppSelector } from 'store/hooks';
import { AveragePricesColumns } from 'pages/cbd/AveragePrices/hooks/consts';

export type AveragePricesDataColumn = {
  key: AveragePricesColumns;
  isShown: boolean;
};

interface SliceState {
  columns: AveragePricesDataColumn[];
}

export const columnsSlice = createSlice({
  initialState: {
    columns: Object.values(AveragePricesColumns).map((item) => ({
      key: item,
      isShown: true,
    })),
  },
  name: 'columns',
  reducers: {
    setVisibleAveragePricesDataColumns(
      state: SliceState,
      action: PayloadAction<Record<AveragePricesColumns, boolean>>
    ) {
      state.columns = state.columns.map((column) => {
        return { ...column, isShown: action.payload[column.key] };
      });
    },
  },
});

export const { setVisibleAveragePricesDataColumns } = columnsSlice.actions;

export const useAveragePricesColumnsData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.cbd.averagePrices.columns) };
};
