import { AxiosResponse } from 'axios';
import {
  IGetClassResponse,
  IGetDepartmentResponse,
  IGetExternalDataNames,
  IGetGroupsResponse,
  IGetLoadErrorCodesResponse,
  IGetSelectsPayload,
  IGetSelectsResponse,
  IGetSubClassResponse,
  IGetSuppliersResponse,
  IRequestStatus,
} from 'common/cbd/types';
import { cbdApiClient } from 'api/index';

export const getGroups = async () => {
  const { data }: AxiosResponse<IGetGroupsResponse> = await cbdApiClient.get('/api/selects/groups');
  return data;
};

export const getDepartment = async () => {
  const { data }: AxiosResponse<IGetDepartmentResponse> = await cbdApiClient.get(
    '/api/selects/dep'
  );
  return data;
};

export const getClass = async () => {
  const { data }: AxiosResponse<IGetClassResponse> = await cbdApiClient.get('/api/selects/class');
  return data;
};

export const getSubClass = async () => {
  const { data }: AxiosResponse<IGetSubClassResponse> = await cbdApiClient.get(
    '/api/selects/subclass'
  );
  return data;
};

export const getSuppliers = async (supplierId: number | number[]) => {
  const { data }: AxiosResponse<IGetSuppliersResponse> = await cbdApiClient.get(
    `/api/selects/suppliers`,
    {
      params: {
        supplierIds: supplierId,
      },
    }
  );
  return data;
};

export const getRegions = async (params?: IGetSelectsPayload) => {
  const { data }: AxiosResponse<IGetSelectsResponse> = await cbdApiClient.get(
    '/api/selects/regions',
    {
      params,
    }
  );
  return data;
};

export const getMeasureUnits = async () => {
  const { data }: AxiosResponse<IGetSelectsResponse> = await cbdApiClient.get(
    '/api/selects/measure-units'
  );
  return data;
};

export const getFirstExtCurrency = async () => {
  const { data }: AxiosResponse<IGetSelectsResponse> = await cbdApiClient.get(
    '/api/selects/ext-currency-1'
  );
  return data;
};

export const getSecondExtCurrency = async () => {
  const { data }: AxiosResponse<IGetSelectsResponse> = await cbdApiClient.get(
    '/api/selects/ext-currency-2'
  );
  return data;
};

export const getRequestStatuses = async () => {
  const { data }: AxiosResponse<IRequestStatus[]> = await cbdApiClient.get(
    '/api/selects/request-statuses'
  );
  return data;
};

export const getExternalDataNames = async (params?: IGetSelectsPayload) => {
  const { data }: AxiosResponse<IGetExternalDataNames> = await cbdApiClient.get(
    '/api/selects/ext-data-name',
    {
      params,
    }
  );
  return data;
};

export const getLoadErrorCodesReq = async () => {
  const { data }: AxiosResponse<IGetLoadErrorCodesResponse> = await cbdApiClient.get(
    '/api/selects/load-error-codes '
  );
  return data;
};
