import React from 'react';
import { PricelistGateway } from '@ppm/ppm-platform-api';
import { SocketContextProvider } from 'components/SocketContainer/SocketContext';
import { useParams } from 'react-router-dom';
import PriceList from './PriceList';

const PriceListSocketProvider = () => {
  const { requestId, priceListId } = useParams();

  if (!requestId || !priceListId) return null;

  return (
    <SocketContextProvider
      id={priceListId}
      namespace={PricelistGateway.namespace}
      path={PricelistGateway.path}
      keyParam="pricelistId"
    >
      <PriceList requestId={requestId} priceListId={priceListId} />
    </SocketContextProvider>
  );
};

export default PriceListSocketProvider;
