import * as yup from 'yup';
import { parseDateForMozaicComponent } from 'common/utils';
import { minContractStartDate } from './consts';

export const schema = yup
  .object()
  .shape({
    contractNumber: yup
      .string()
      .required('Обязательно для заполнения')
      .min(5, 'Договор поставки - это номер из 5 цифр')
      .max(5, 'Договор поставки - это номер из 5 цифр')
      .matches(/^\d+$/, { message: 'Договор поставки - это номер из 5 цифр' }),
    contractStartDate: yup
      .date()
      .min(minContractStartDate, 'Договор поставки не мог быть заключен ранее 2000 года')
      .max(parseDateForMozaicComponent(new Date()))
      .required('Обязательно для заполнения'),
    pricelistStartDate: yup
      .date()
      .typeError('Обязательно для заполнения')
      .max(
        yup.ref('pricelistEndDate'),
        'Начало действия прейскуранта не может быть позднее окончания'
      ),
    pricelistEndDate: yup
      .date()
      .typeError('Обязательно для заполнения')
      .min(
        yup.ref('pricelistStartDate'),
        'Начало действия прейскуранта не может быть позднее окончания'
      ),
  })
  .required();
