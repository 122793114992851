import {
  ExecuteSubrequestContainerFlowTransitionResponse as ExecuteSubrequestResponse,
  ExecuteSubrequestContainerFlowTransition as ExecuteSubrequest,
  UpdateSubrequestsContainer,
  GetSubruquestContainerByRequestId,
  GetSubruquestContainerByRequestIdResponse,
} from '@ppm/ppm-platform-api';
import { apiCreator } from 'api/index';

export const getSubRequestsContainer = apiCreator<
  typeof GetSubruquestContainerByRequestId,
  GetSubruquestContainerByRequestIdResponse,
  Parameters<(typeof GetSubruquestContainerByRequestId)['url']>
>(GetSubruquestContainerByRequestId)[GetSubruquestContainerByRequestId.method];

export const executeSubRequestFlow = apiCreator<
  typeof ExecuteSubrequest,
  ExecuteSubrequestResponse,
  Parameters<(typeof ExecuteSubrequest)['url']>
>(ExecuteSubrequest, { headers: { 'Cache-Control': 'no-cache' } })[ExecuteSubrequest.method];

export const updateSubRequestsContainer = apiCreator<
  typeof UpdateSubrequestsContainer,
  UpdateSubrequestsContainer.Response,
  Parameters<(typeof UpdateSubrequestsContainer)['url']>,
  UpdateSubrequestsContainer.Body
>(UpdateSubrequestsContainer)[UpdateSubrequestsContainer.method];
