import { string, object, number, array } from 'yup';
import { DueDateValidation } from '@ppm/ppm-platform-api';
import { getOtherSelectValue } from 'store/dictionary/reasons';
import { SelectItem } from 'common/types';
import { Translations } from 'translations';
import { isAfter, isBefore, addDays } from 'date-fns';

export const getSchema = ({
  reasons,
  translations,
  dateValidation,
}: {
  reasons: SelectItem[];
  translations: Translations;
  dateValidation: DueDateValidation;
}) =>
  object({
    reasonListId: array()
      .test((values) => {
        if (values) {
          return values.every((inputValue) =>
            reasons.find(({ value }) => value === inputValue.value)
          );
        }
        return false;
      })
      .required('This field is required'),
    customReason: string().when('reasonListId', {
      is: (reasonListId: SelectItem[]) => {
        return reasonListId.find((item) => item.value === getOtherSelectValue(translations).value);
      },
      then: (schema) => schema.min(5).required('This field is required'),
    }),
    supplierId: object({ value: number().required(), label: string() }).required(
      'This field is required'
    ),
    dueDate: string()
      .defined()
      .test(
        'dueDate',
        `Желаемая дата применения новых цен должна быть в диапазоне c ${dateValidation.min} по ${dateValidation.max}`,
        (value) => {
          return (
            isAfter(addDays(new Date(value), 1), dateValidation.min) &&
            isBefore(addDays(new Date(value), -1), dateValidation.max)
          );
        }
      )
      .required('This field is required'),
    comment: string(),
  }).required();
