import React, { useEffect, PropsWithChildren } from 'react';
import { useModals } from '@mozaic-ds/react';
import { MODAL_RATE_POPUP_OPENED_ACTIONS } from 'components/SocketContainer/consts';
import { QuizSocketData, setEntityId, setModalRateDate } from 'store/modalRate/modalRate';
import { modalRatePopupId } from 'components/ModalRate/consts';
import { useAppDispatch } from 'store/hooks';
import { useSocketContext } from 'components/SocketContainer/SocketContext';
import { useRequestData } from 'store/request/request';

export const RequestSocketContent: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const { open } = useModals();
  const dispatch = useAppDispatch();
  const { socket } = useSocketContext();
  const { containers } = useRequestData().data;

  useEffect(() => {
    socket.on(MODAL_RATE_POPUP_OPENED_ACTIONS, (data: QuizSocketData) => {
      dispatch(setEntityId(containers[0]?.id));
      dispatch(setModalRateDate(data));
      open(modalRatePopupId);
    });

    return () => {
      socket.off(MODAL_RATE_POPUP_OPENED_ACTIONS);
    };
  }, [containers, dispatch, open, socket]);
  return <>{children}</>;
};
