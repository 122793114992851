import React from 'react';
import { View, CheckBox } from '@mozaic-ds/react';
import { CustomAccordion } from 'components/CustomAccordion/CustomAccordion';
import { resetSelectedContainers, selectContainer, useRequestData } from 'store/request/request';
import { useAppDispatch } from 'store/hooks';
import cn from 'classnames';
import { getHasGrants } from 'common/utils';
import { useUserData } from 'store/auth/user';
import { useAppTranslations } from 'translations';
import { useMount } from 'hook/useMount';
import { isProd, isSupplierEnvironment } from 'common/consts';
import { SubRequest } from './SubRequest';
import styles from './SubRequests.module.scss';
import { gtmChooseSubrequest } from './gtm';

export const SubRequests = ({ id }: { id: string }) => {
  const translations = useAppTranslations();
  const dispatch = useAppDispatch();

  const { containers, selectedContainers } = useRequestData().data;
  const { userGrants } = useUserData().user;

  const hasGrant = getHasGrants(userGrants);

  useMount(() => dispatch(resetSelectedContainers()));

  if (!hasGrant.RequestContainerGet) return null;

  return (
    <View marginTop="mu150" radius="l">
      {containers.length > 0 && (
        <CustomAccordion header={translations('subRequestHeader')} isOpen>
          {containers.map((container, index) => {
            const { id: containerId, flowRights, containerNumber } = container;
            const isChecked = Boolean(selectedContainers.find((item) => item.id === containerId));
            return (
              <CheckBox
                className={cn('mu-pl-150', styles.checkbox, {
                  [styles.activeSubRequest]: isChecked,
                })}
                isDisabled={!flowRights.isAccessible}
                key={containerId}
                value={containerId}
                isChecked={isChecked}
                onChange={(e) => dispatch(selectContainer({ index, isChecked: e.target.checked }))}
                onClick={() => {
                  if (isSupplierEnvironment && isProd)
                    gtmChooseSubrequest({
                      t: translations,
                      subrequestId: containerNumber,
                      requestId: id,
                    });
                }}
              >
                <SubRequest container={container} />
              </CheckBox>
            );
          })}
        </CustomAccordion>
      )}
    </View>
  );
};
