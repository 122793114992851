import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';
import { IFilter } from 'common/types';
import { FilterName } from 'pages/cbd/AllUploads/AllUploads.types';

interface SliceState {
  filters: IFilter<FilterName>[];
  deletedFilters: FilterName[];
  searchValue: string;
}

export const filtersSlice = createSlice({
  initialState: {
    filters: [],
    deletedFilters: [],
    searchValue: '',
  },
  name: 'cbdFilters',
  reducers: {
    clearDeletedFilters(state) {
      state.deletedFilters = [];
    },
    changeDeletedFilters(state, action) {
      state.deletedFilters = state.deletedFilters.concat(action.payload);
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
  },
});

export const { setFilters, changeDeletedFilters, clearDeletedFilters } = filtersSlice.actions;

export const useFiltersData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.cbd.allUploads.filters) };
};
