import { useRoles } from 'store/roles/roles';
import { SubrequestContainerWithRights } from '@ppm/ppm-platform-api';
import { reduce } from 'lodash';

export const useGetResponsibilities = (container: SubrequestContainerWithRights) => {
  const { roles } = useRoles().data;
  const availableRolesWithDescription = roles.reduce((acc: [number, string][], cur) => {
    acc.push([cur.id, cur.description]);
    return acc;
  }, []);
  const responsibilities = reduce(
    container.responsibles,
    (result: [string, string[]][], value, key) => {
      const hasAvailableRole = availableRolesWithDescription.find(([id]) => id === Number(key));
      if (hasAvailableRole) {
        result.push([hasAvailableRole[1], value]);
      }
      return result;
    },
    []
  );
  return { responsibilities };
};
