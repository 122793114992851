import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  Badge,
  Flex,
  Text,
  Tooltip,
  View,
} from '@mozaic-ds/react';
import { NotificationInformation24 } from '@mozaic-ds/icons/react';
import React from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartProps, Doughnut } from 'react-chartjs-2';
import { Dividers } from 'components/Dividers/Dividers';
import Bound from 'pages/cbd/Bound/Bound';
import { useAppTranslations } from 'translations';
import { useCbdAnalysisInfoInfoData } from 'store/cbd/cbdAnalysis/cbdAnalysisInfo';
import { statusColorConfig } from 'common/consts';
import styles from './Indicators.module.scss';
import useChartData from './hooks/useChartData';

Chart.register(ChartDataLabels);

const Indicators = () => {
  const translations = useAppTranslations();
  const { data } = useCbdAnalysisInfoInfoData();
  const { expensesConfig, currencyConfig, config } = useChartData();

  return (
    <View
      paddingTop="mu150"
      paddingBottom="mu150"
      paddingLeft="mu200"
      paddingRight="mu200"
      marginBottom="mu150"
      shadow="s"
      className={styles.infoBlock}
    >
      <Flex marginBottom="mu100" alignItems="center">
        <Text className="mu-mr-025" weight="semi-bold">
          {translations('indicators')}:
        </Text>
        <Tooltip
          className={styles.tooltip}
          label={
            <Flex alignItems="flex-start" direction="column">
              <Text textAlign="left">- {translations('variableCosts')}</Text>
              <Text textAlign="left">- {translations('currencies')}</Text>
              <Text textAlign="left">- {translations('monitoredComponents')}</Text>
            </Flex>
          }
        >
          <NotificationInformation24 />
        </Tooltip>
      </Flex>
      <Flex marginBottom="mu100">
        {data?.indicators.map((item) => {
          return (
            <Badge theme={statusColorConfig[item.type]} className="mu-mr-100">
              {item.text}
            </Badge>
          );
        })}
      </Flex>
      <Dividers className="mu-mb-100" />
      <Flex marginBottom="mu200">
        <Text weight="semi-bold">{translations('costStructure')}</Text>
      </Flex>
      <Flex marginBottom="mu200">
        <Flex className={styles.chart}>
          <Doughnut {...(expensesConfig as ChartProps<'doughnut'>)} />
        </Flex>
        <Flex className={styles.chart}>
          <Doughnut {...(currencyConfig as ChartProps<'doughnut'>)} />
        </Flex>
        <Flex className={styles.chart}>
          <Doughnut {...(config as ChartProps<'doughnut'>)} />
        </Flex>
      </Flex>
      <Flex className={styles.chart} direction="column" marginBottom="mu100">
        <Flex marginBottom="mu100">
          <Text weight="semi-bold">{translations('totalCostsForAllCurrencies')}</Text>
        </Flex>
        <Flex>
          <Flex direction="column" marginRight="mu200">
            <Text className="mu-mb-100" weight="semi-bold">
              CBD
            </Text>
            <Flex justifyContent="space-between">
              <Text weight="semi-bold" className="mu-mr-100">
                RUR {data?.cbdCurrencyRates.RUB}
              </Text>
              <Text weight="semi-bold" className="mu-mr-100">
                EUR {data?.cbdCurrencyRates.EUR}
              </Text>
              <Text weight="semi-bold">USD {data?.cbdCurrencyRates.USD}</Text>
            </Flex>
          </Flex>
          {data?.extCurrencyRates && (
            <Flex direction="column">
              <Text className="mu-mb-100" weight="semi-bold">
                {translations('cb')}
              </Text>
              <Flex justifyContent="space-between">
                <Text weight="semi-bold" className="mu-mr-100">
                  RUR {data?.extCurrencyRates.RUB}
                </Text>
                <Text weight="semi-bold" className="mu-mr-100">
                  EUR {data?.extCurrencyRates.EUR}
                </Text>
                <Text weight="semi-bold">USD {data?.extCurrencyRates.USD}</Text>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Accordion defaultIsOpen>
        <AccordionHeader>{translations('bounds')}</AccordionHeader>
        <AccordionContent>
          <Bound
            needToWrap={false}
            hideNomenclature
            boundFiltersVisible
            customShownColumns={{ groupsName: true }}
          />
        </AccordionContent>
      </Accordion>
    </View>
  );
};

export default Indicators;
