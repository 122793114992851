import React, { useState } from 'react';
import { NotificationBell32 } from '@mozaic-ds/icons/react';
import { useUserData } from 'store/auth/user';
import { Text, Flex } from '@mozaic-ds/react';
import { setUserNotification } from 'api/notification';
import { useAppTranslations } from 'translations';
import styles from '../Header.module.scss';

export const NotificationToggle = () => {
  const translations = useAppTranslations();
  const { data } = useUserData().user;

  const [notify, setNotification] = useState(data.receiveNotification);

  return (
    <Flex direction="row" alignItems="center" justifyContent="space-between">
      <NotificationBell32
        fill={!notify ? '#71706b' : '#007f8c'}
        className={styles.clickable}
        onClick={async () => {
          setNotification(!notify);
          await setUserNotification({ body: { receiveNotifications: !notify } });
        }}
      />
      <Text className="mu-mr-100">
        {translations(notify ? 'notificationOn' : 'notificationOff')}
      </Text>
    </Flex>
  );
};
