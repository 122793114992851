import { getRequest } from 'store/request/request';
import { useAppDispatch } from 'store/hooks';
import { useDefaultFormValue } from 'pages/Request/hooks';
import { useCallback } from 'react';
import { isEqual } from 'lodash';
import { updateRequest } from 'api/request';
import { createDefaultValue, formatDirtyFieldToStore } from 'pages/Request/helpers';
import { RequestFormFields } from 'pages/Request/types';
import { useAppTranslations } from 'translations';
import { useFormContext } from 'react-hook-form';
import { useReasonsData } from 'store/dictionary/reasons';
import { isProd } from 'common/consts';
import { gtmClickManageRequestButton } from '../../gtm';

export const useUpdateFormValues = (id: string) => {
  const t = useAppTranslations();
  const dispatch = useAppDispatch();
  const defaultValues = useDefaultFormValue();
  const { reset } = useFormContext<RequestFormFields>();
  const { reasons } = useReasonsData().data;

  const updateFormData = useCallback(
    async (formData: RequestFormFields) => {
      if (!isEqual(formData, defaultValues)) {
        await updateRequest({
          urlParameters: [id],
          body: formatDirtyFieldToStore(formData, defaultValues, t),
        });
        if (isProd) gtmClickManageRequestButton({ t, buttonLabel: 'shareUpdate', requestId: id });
        const { data } = await dispatch(getRequest(id)).unwrap();
        reset(() => createDefaultValue(data.request, reasons, t));
      }
    },
    [defaultValues, dispatch, id, reasons, reset, t]
  );
  return { updateFormData };
};
