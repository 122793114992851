import { Translations } from 'translations';
import { FilterName } from '../AlertAttribute.types';

export enum AlertAttributeColumns {
  LOGIN = 'login',
  DATE_START = 'dateStart',
  DATE_FINISH = 'dateFinish',
  SOURCE = 'source',
  CATEGORY_ID = 'categoryId',
  TYPE_ID = 'typeId',
  SUBTYPE_ID = 'subtypeId',
  SUBSCRIPTION_TYPE = 'subscriptionType',
  VALUE = 'value',
  TERM = 'term',
}

export const getLabel = (key: FilterName, t: Translations) => {
  return {
    login: t('externalDataType'),
    dateStart: t('subscriptionStartDate'),
    dateFinish: t('subscriptionEndDate'),
    sourceId: t('source'),
    categoryId: t('category'),
    typeId: t('type'),
    subtypeId: t('subType'),
    subscriptionType: t('subscriptionType'),
    value: t('indicatorValue'),
    term: t('analyzedDaysPeriod'),
  }[key];
};

export const filtersName: FilterName[] = [
  'login',
  'dateStart',
  'dateFinish',
  'sourceId',
  'categoryId',
  'typeId',
  'subtypeId',
  'subscriptionType',
  'value',
  'term',
];
