import { format } from 'date-fns';
import { existedLocale } from 'common/consts';

export const createMessageText = ({
  message,
  date,
  indexesOfInsertions,
  parseOptions,
}: {
  message: string;
  date: string;
  indexesOfInsertions: number[];
  parseOptions: { separator: string | RegExp; dateFormat?: string };
}) => {
  const { separator, dateFormat = 'd MMMM в H:m:s' } = parseOptions;

  const parsedDate = format(new Date(date), dateFormat, {
    locale: existedLocale.ru,
  });
  const parsedMessage = message.split(separator);

  return parsedMessage.map((item, index) => {
    if (!item) return item;

    const isNextElementStartWithUpperCase = parsedMessage[index + 1]
      ? parsedMessage[index + 1][1] === parsedMessage[index + 1][1].toUpperCase()
      : true;
    const endWithCommaOrDot = isNextElementStartWithUpperCase ? '. ' : ', ';

    if (indexesOfInsertions.includes(index)) {
      return item.concat(`${parsedDate}${endWithCommaOrDot}`);
    }
    return item.concat(endWithCommaOrDot);
  });
};
