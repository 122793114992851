import { combineReducers } from '@reduxjs/toolkit';
import { columnsSlice } from './columns';
import { listSlice } from './list';
import { filtersSlice } from './filters';

export const compare = combineReducers({
  columns: columnsSlice.reducer,
  list: listSlice.reducer,
  filters: filtersSlice.reducer,
});
