import {
  GetBuyerSiteGroupHierarchy,
  GetSupplierBsgLinks,
  UpsertSupplierBsgLink,
} from '@ppm/ppm-platform-api';
import { apiCreator } from '../index';

export const getBuyerSiteGroupHierarchy = apiCreator<
  typeof GetBuyerSiteGroupHierarchy,
  GetBuyerSiteGroupHierarchy.Response
>(GetBuyerSiteGroupHierarchy)[GetBuyerSiteGroupHierarchy.method];

export const getSupplierBuyerSiteGroupLinks = apiCreator<
  typeof GetSupplierBsgLinks,
  GetSupplierBsgLinks.Response
>(GetSupplierBsgLinks)[GetSupplierBsgLinks.method];

export const setSupplierBuyerSiteGroupLinks = apiCreator<
  typeof UpsertSupplierBsgLink,
  UpsertSupplierBsgLink.Response,
  undefined,
  UpsertSupplierBsgLink.Body
>(UpsertSupplierBsgLink)[UpsertSupplierBsgLink.method];
