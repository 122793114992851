import React from 'react';
import { Button, Flex } from '@mozaic-ds/react';
import cn from 'classnames';
import styles from 'components/Chat/Chat.module.scss';
import {
  AccountCustomerCollaborator24,
  ArrowArrowRight16,
  ServicePurchaseHelpTruck24,
} from '@mozaic-ds/icons/react';
import { isLmEnvironment } from 'common/consts';
import { useAppTranslations } from 'translations';
import { useRequestData } from 'store/request/request';
import { getHasGrants } from 'common/utils';

type Props = {
  isSupplierChat: boolean;
  setType: (isType: boolean) => void;
};
const Tabs = ({ isSupplierChat, setType }: Props) => {
  const translations = useAppTranslations();

  const { requestGrants } = useRequestData().data;

  const { RequestExternalChatRead, RequestInternalChatRead } = getHasGrants(requestGrants);

  return (
    <Flex className={cn('mu-mr-200', styles.buttons)} direction="column" shadow="s" radius="l">
      {RequestExternalChatRead && (
        <Button
          className={styles.supplier}
          size="l"
          variant={isSupplierChat ? undefined : 'bordered'}
          theme={isSupplierChat ? 'primary' : 'neutral'}
          rightIcon={<ArrowArrowRight16 className="mu-mt-050" />}
          onClick={() => setType(true)}
        >
          <ServicePurchaseHelpTruck24 className="mu-mr-100" />
          {isLmEnvironment ? translations('chatWithSupplier') : translations('chatWithLm')}
        </Button>
      )}
      {RequestInternalChatRead && (
        <Button
          className={styles.collaborator}
          size="l"
          variant={!isSupplierChat ? undefined : 'bordered'}
          theme={!isSupplierChat ? 'primary' : 'neutral'}
          onClick={() => setType(false)}
          rightIcon={<ArrowArrowRight16 className="mu-mt-050" />}
        >
          <AccountCustomerCollaborator24 className="mu-mr-100" />
          {translations('internalChat')}
        </Button>
      )}
    </Flex>
  );
};

export default Tabs;
