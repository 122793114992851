import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSchedulerReq, IGetScheduler, IScheduler } from 'api/cbd/scheduler';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

interface SliceState {
  data: IScheduler[];
}

const initialState = {
  data: [],
};

export const getScheduler = createAsyncThunk('cbd/getScheduler', async (_, { rejectWithValue }) => {
  try {
    return await getSchedulerReq();
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const schedulerSlice = createSlice({
  initialState,
  name: 'scheduler',
  reducers: {
    resetSchedulerData(state) {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getScheduler.fulfilled,
      (state: SliceState, action: PayloadAction<IGetScheduler>) => {
        state.data = action.payload.data;
      }
    );
  },
});

export const useScheduler = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.scheduler.main);
};
