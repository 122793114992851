import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getStatus as getDictionaryStatus } from 'api/dictionary';
import { GetRequestStatusResponse } from '@ppm/ppm-platform-api';
import { SelectItem } from 'common/types';
import { formatToSelectValue, setFailure, setLoading } from '../helpers';
import { RequiredStateFields, RootState } from '../types';
import { useAppSelector } from '../hooks';

type StatusSliceData = SelectItem[];

interface SliceState extends RequiredStateFields {
  status: StatusSliceData;
}

export const getStatus = createAsyncThunk('status/getStatus', async (_, { rejectWithValue }) => {
  try {
    return await getDictionaryStatus({});
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const statusSlice = createSlice({
  initialState: { status: [], isLoading: false, isFailure: false },
  name: 'status',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStatus.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getStatus.fulfilled,
        (state: SliceState, action: PayloadAction<GetRequestStatusResponse>) => {
          state.status = formatToSelectValue(action.payload.data);
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getStatus.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const useStatusData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.dictionary.status) };
};
