import { deleteCbd, sendCbdFile, getCbdLoad, createCbdLoad } from 'api/cbd/cbd';
import { uploadCbdTemplateRequest } from 'api/cbd/templates';
import { useNotifications, useModals } from '@mozaic-ds/react';
import { useNavigate } from 'react-router-dom';
import { getRequestById } from 'api/request/request';
import { appendFileToFormData } from 'common/utils';
import { TFile, useUploadFile } from 'hook/useUploadFile';
import { setSelectedRowsId, useCbdList } from 'store/cbd/allUploads/list';
import { getLatestCbdTemplateInfoData } from 'store/cbd/templatesCbd';
import { useUserData } from 'store/auth/user';
import { useCbdLoadTimeouts } from 'store/cbd/settings';
import { useAppDispatch } from 'store/hooks';
import { getLoadErrors } from 'store/cbd/loadErrors';
import { useAppTranslations } from 'translations';
import { Controller } from 'hook/useActionsFileForm';
import { LOAD_ERRORS_MODAL_ID } from '../../Uploads/hooks/consts';

interface IUseUploadCbdFilesFormActions {
  controller: Controller;
  reqId?: string;
  update?: () => void;
}

const useUploadCbdFilesFormActions = ({
  reqId,
  controller,
  update,
}: IUseUploadCbdFilesFormActions) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { success, warning } = useNotifications();
  const translate = useAppTranslations();
  const { selectedRowsId } = useCbdList();
  const cbdTimeouts = useCbdLoadTimeouts();
  const { open } = useModals();
  const { data: userData } = useUserData().user;

  const { handleChange, handleDelete } = useUploadFile(false, controller);

  const showDangerNotification = () => {
    warning({
      title: translate('dangerNotification'),
      message: translate('fileNotUploaded'),
    });
  };

  const templateCbdUploadFile = async () => {
    try {
      const formData = appendFileToFormData(controller.value[0] as unknown as TFile);
      formData.append('userId', userData?.id?.toString());
      await uploadCbdTemplateRequest(formData);
      success({
        title: translate('successNotification'),
        message: translate('templateSuccessUploaded'),
      });
      dispatch(getLatestCbdTemplateInfoData());
    } catch {
      showDangerNotification();
    }
  };

  const cbdUploadFile = async () => {
    if (reqId) {
      const formData = appendFileToFormData(controller.value[0] as unknown as TFile);
      const fileInfo = await sendCbdFile({ reqId, formData }).catch((err) => {
        showDangerNotification();
        throw err;
      });

      const request = await getRequestById({ urlParameters: [reqId] });
      const load = await createCbdLoad({
        fileId: fileInfo.id,
        fileName: fileInfo.name,
        request: request.data.request,
      });

      let currentWaiting = 0;
      const interval = setInterval(async () => {
        if (currentWaiting < cbdTimeouts.cbdUploadTimeout) {
          const updatedLoad = await getCbdLoad(load.id);
          if (updatedLoad.isLoaded) {
            success({
              title: translate('successNotification'),
              message: translate('fileSuccessUploaded'),
            });
            navigate(`/uploads/${reqId}`);
            clearInterval(interval);
          } else if (!updatedLoad.isLoaded) {
            dispatch(getLoadErrors(updatedLoad.id));
            navigate(`/uploads/${reqId}`);
            setTimeout(() => open(LOAD_ERRORS_MODAL_ID), 0);
            clearInterval(interval);
          } else currentWaiting += cbdTimeouts.cbdUpdateTimeout;
        } else {
          showDangerNotification();
          clearInterval(interval);
        }
      }, cbdTimeouts.cbdUpdateTimeout);
    }
  };

  const onDelete = async () => {
    if (update) {
      await Promise.all(
        selectedRowsId.map((item) => {
          const [id] = item.split('-');
          return deleteCbd(id.toString());
        })
      );
      dispatch(setSelectedRowsId([]));
      update();
    }
  };

  return {
    templateCbdUploadFile,
    cbdUploadFile,
    onDelete,
    handleChange,
    handleDelete,
  };
};

export default useUploadCbdFilesFormActions;
