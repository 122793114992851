import {
  bulkDeleteSubscriptions,
  postSubscriptions,
  IPostSubscriptions,
  bulkUpdateSubscriptions,
} from 'api/cbd/scheduler';
import { useCallback } from 'react';
import {
  setEditItems,
  setIsAdd,
  setSelectedRowsId,
  useSubscriptionsList,
} from 'store/cbd/scheduler/subscriptions/subscriptionsList';
import { useAppDispatch } from 'store/hooks';
import { useTableData } from './data';

const useAlertAttributeActions = () => {
  const dispatch = useAppDispatch();
  const { selectedRowsId, data, isAdd, editItems } = useSubscriptionsList();
  const { updateTableData } = useTableData();

  const deleteExtDataItems = useCallback(async () => {
    await bulkDeleteSubscriptions(selectedRowsId);
    updateTableData();
    dispatch(setSelectedRowsId([]));
  }, [dispatch, selectedRowsId, updateTableData]);

  const onAddClick = useCallback(async () => {
    try {
      if (isAdd) {
        const {
          login,
          dateStart,
          dateFinish,
          sourceId,
          categoryId,
          typeId,
          subtypeId,
          subscriptionType,
          value,
          term,
        } = data[0];

        await postSubscriptions({
          login,
          dateStart,
          dateFinish,
          sourceId,
          categoryId,
          typeId,
          subtypeId,
          subscriptionType,
          value: Number(value),
          term,
        });
        dispatch(setEditItems([]));
        updateTableData();
      }
      dispatch(setIsAdd(!isAdd));
    } catch {
      dispatch(setEditItems([]));
      dispatch(setIsAdd(!isAdd));
      updateTableData();
    }
  }, [isAdd, dispatch, data, updateTableData]);

  const onEditClick = useCallback(async () => {
    try {
      dispatch(setSelectedRowsId([]));

      if (editItems.length) {
        const changedRows = editItems.reduce((prev: IPostSubscriptions[], curr) => {
          const item = data.find((elem) => elem.id.toString() === curr);

          if (item) {
            const {
              id,
              login,
              dateStart,
              dateFinish,
              sourceId,
              categoryId,
              typeId,
              subtypeId,
              subscriptionType,
              value,
              term,
            } = item;

            prev.push({
              id,
              login,
              dateStart,
              dateFinish,
              sourceId,
              categoryId,
              typeId,
              subtypeId,
              subscriptionType,
              value: Number(value),
              term,
            });
          }
          return prev;
        }, []);

        await bulkUpdateSubscriptions(changedRows);

        updateTableData();

        dispatch(setEditItems([]));
        return;
      }

      dispatch(setEditItems(selectedRowsId));
    } catch {
      dispatch(setEditItems([]));
      updateTableData();
    }
  }, [editItems, dispatch, selectedRowsId, updateTableData, data]);

  const onCancel = () => {
    updateTableData();
    dispatch(setIsAdd(false));
    dispatch(setEditItems([]));
    dispatch(setSelectedRowsId([]));
  };

  return {
    deleteExtDataItems,
    onAddClick,
    onEditClick,
    onCancel,
  };
};

export default useAlertAttributeActions;
