import * as React from 'react';
import { Button, Flex, Select, View } from '@mozaic-ds/react';
import { ArrowArrowLeft32, ArrowArrowRight32 } from '@mozaic-ds/icons/react';

import { usePagingContext } from 'components/Paging/partial/PagingContext';

export const PageSelector = () => {
  const { pageOptions, setCurrentPage, currentPageValue, rowPerPageValue, itemsCount } =
    usePagingContext();

  const isDisabledNext = currentPageValue * rowPerPageValue >= itemsCount;
  const isDisabledPrev = currentPageValue === 1;

  return (
    <Flex>
      <Button
        className="mc-button--square mu-mr-050"
        size="s"
        theme="primary-02"
        onClick={() => setCurrentPage(currentPageValue - 1)}
        isDisabled={isDisabledPrev}
      >
        <ArrowArrowLeft32 className="mc-button__icon" />
      </Button>
      <View className="mc-pagination__field">
        <Select
          className="mc-pagination__select"
          size="s"
          value={currentPageValue}
          options={pageOptions}
          onChange={(e) => setCurrentPage(Number(e.target.value))}
        />
      </View>
      <Button
        className="mc-button--square mu-ml-050"
        size="s"
        theme="primary-02"
        isDisabled={isDisabledNext}
        onClick={() => setCurrentPage(currentPageValue + 1)}
      >
        <ArrowArrowRight32 className="mc-button__icon" />
      </Button>
    </Flex>
  );
};
