import {
  deleteExternalData,
  IPatchExtData,
  patchExternalData,
  postExternalData,
  uploadExternalFile,
} from 'api/cbd/externalSource';
import { useCallback, useRef } from 'react';
import { useNotifications } from '@mozaic-ds/react';
import { useAppTranslations } from 'translations';
import { appendFileToFormData, downloadEdcTemplate } from 'common/utils';
import {
  getExtData,
  removeUnusedRows,
  setEditItems,
  setIsAdding,
  setSelectedRowsId,
  useExtTableData,
} from 'store/cbd/externalData/tableData';
import { useAppDispatch } from 'store/hooks';
import { IGetExternalDataListQuery } from 'pages/cbd/ExternalData/ExternalData.types';

const PAGE_SIZE = 10;

const useExtDataActions = (queryParams: IGetExternalDataListQuery) => {
  const dispatch = useAppDispatch();
  const { selectedRowsId, data, isAdding, editItems, pageNumber } = useExtTableData();
  const { success } = useNotifications();
  const translations = useAppTranslations();
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  const updateTableData = useCallback(
    (rowsToDelete: Array<string> = []) => {
      if (rowsToDelete.length) dispatch(removeUnusedRows(rowsToDelete.map((item) => Number(item))));
      dispatch(getExtData({ page: pageNumber, size: PAGE_SIZE, ...queryParams }));
    },
    [dispatch, pageNumber, queryParams]
  );

  const onCancel = useCallback(() => {
    updateTableData(['-1']);
    dispatch(setIsAdding(false));
    dispatch(setEditItems([]));
    dispatch(setSelectedRowsId([]));
  }, [dispatch, updateTableData]);

  const deleteExtDataItems = useCallback(async () => {
    await Promise.all(selectedRowsId.map((item) => deleteExternalData(item)));
    updateTableData(selectedRowsId);
    dispatch(setSelectedRowsId([]));
  }, [dispatch, selectedRowsId, updateTableData]);

  const onAddClick = useCallback(async () => {
    try {
      if (isAdding) {
        const {
          extDataType,
          source,
          categoryId,
          typeId,
          subtypeId,
          name,
          name2,
          date,
          value,
          region,
          measure,
          info: { grade, diameter, thickness, contract, standard, priceTypeId, priceTypeName },
        } = data[0];

        await postExternalData({
          extDataType,
          source,
          categoryId,
          typeId,
          subtypeId,
          name,
          name2,
          date,
          value,
          region,
          measure,
          info: { grade, diameter, thickness, contract, standard, priceTypeId, priceTypeName },
        });
        dispatch(setEditItems([]));
        updateTableData();
      }
    } catch {
      dispatch(setEditItems([]));
      updateTableData();
    } finally {
      dispatch(setIsAdding(!isAdding));
    }
  }, [isAdding, dispatch, data, updateTableData]);

  const onEditClick = useCallback(async () => {
    try {
      if (editItems.length) {
        const changedRows = selectedRowsId.reduce((prev: IPatchExtData[], curr) => {
          const item = data.find((elem) => elem.id?.toString() === curr);

          if (item) {
            const {
              id,
              extDataType,
              source,
              categoryId,
              typeId,
              subtypeId,
              name,
              name2,
              date,
              value,
              region,
              measure,
              info: { grade, diameter, thickness, contract, standard, priceTypeId, priceTypeName },
            } = item;

            prev.push({
              id,
              extDataType,
              source,
              categoryId,
              typeId,
              subtypeId,
              name,
              name2,
              date,
              value,
              region,
              measure,
              info: {
                grade,
                diameter,
                thickness,
                contract,
                standard,
                priceTypeId,
                priceTypeName,
              },
            });
          }
          return prev;
        }, []);

        await Promise.all(changedRows.map((item) => patchExternalData(item)));

        dispatch(setSelectedRowsId([]));
        dispatch(setEditItems([]));
        updateTableData();
        return;
      }

      dispatch(setEditItems(selectedRowsId));
    } catch {
      dispatch(setEditItems([]));
      dispatch(setSelectedRowsId([]));
      updateTableData();
    }
  }, [editItems, dispatch, selectedRowsId, updateTableData, data]);

  const onUploadExternalTemplateClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const onUploadExternalTemplate = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const formData = appendFileToFormData(file);

      await uploadExternalFile(formData);

      success({
        title: translations('successNotification'),
        message: translations('fileSuccessUploaded'),
      });
    }
  };

  return {
    hiddenFileInput,
    deleteExtDataItems,
    onEditClick,
    onAddClick,
    onDownloadExternalTemplate: downloadEdcTemplate,
    onUploadExternalTemplateClick,
    onUploadExternalTemplate,
    onCancel,
  };
};

export default useExtDataActions;
