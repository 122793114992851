import * as React from 'react';
import FieldWrapper from 'components/FieldWrapper/FieldWrapper';
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr';
import { ControllerRenderProps } from 'react-hook-form';

type FlatpickerProps = {
  id: string;
  label: string;
  placeholder?: string;
  // eslint-disable-next-line eslint-comments/disable-enable-pair
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  field: ControllerRenderProps<any, any>;
  options?: DateTimePickerProps['options'];
};
export const Flatpicker = ({ id, placeholder, label, field, options }: FlatpickerProps) => {
  return (
    <FieldWrapper label={label} id={id}>
      <Flatpickr
        {...field}
        onClose={([firstDate, secondDate]) => {
          if (firstDate && !secondDate) field.onChange([firstDate, firstDate]);
        }}
        className="mc-text-input"
        options={{ mode: 'range', dateFormat: 'm/d/Y', locale: { firstDayOfWeek: 1 }, ...options }}
        placeholder={placeholder}
      />
    </FieldWrapper>
  );
};
