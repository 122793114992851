import { GetLanguageListResponse, LanguageListItem } from '@ppm/ppm-platform-api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getLanguage } from 'api/language';
import { setFailure, setLoading } from '../helpers';
import { RequiredStateFields, RootState } from '../types';
import { useAppSelector } from '../hooks';

type LanguagesData = LanguageListItem[];

interface SliceState extends RequiredStateFields {
  data: LanguagesData;
}

export const getLanguages = createAsyncThunk<GetLanguageListResponse>(
  'languages/getLanguages',
  async (_, { rejectWithValue }) => {
    try {
      return await getLanguage({});
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const languagesSlice = createSlice({
  initialState: { data: [], isLoading: false, isFailure: false },
  name: 'languages',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLanguages.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(getLanguages.fulfilled, (state: SliceState, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
        state.isFailure = false;
      })
      .addCase(getLanguages.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const useLanguageData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.languages.languages) };
};
