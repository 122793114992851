import { Translations } from 'translations';

export function gtmClickManageRequestButton({
  t,
  buttonLabel,
  requestId,
  subrequestId,
}: {
  t: Translations;
  buttonLabel: string;
  requestId: string;
  subrequestId?: string;
}) {
  window.dataLayer.push({
    event: 'LeroyMerlin',
    eventCategory: 'click-button', // действие-элемент
    eventAction: t('request'), // блок, в котором находится кнопка
    eventLabel: t(buttonLabel), // текст на кнопке.
    eventVariant: subrequestId || null, // при клике на согласование передавать также №подзапроса в виде “2259/1”, иначе передавать null
    eventRequestID: requestId, // ID запроса
  });
}
