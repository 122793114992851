import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TemplateStructureColumns } from 'pages/cbd/EditTemplate/components/ParseTable/hooks/consts';
import { RootState } from 'store/types';
import { useAppSelector } from 'store/hooks';
import { ITemplateStructureColumnSort } from 'pages/cbd/EditTemplate/components/ParseTable/ParseTable.types';
import { SortDirection } from 'common/types';

type SortValues = { field: ITemplateStructureColumnSort; sortDirection: SortDirection };
export type TemplateStructureSortedField = null | SortValues;
export type TemplateStructureColumn = {
  key: TemplateStructureColumns;
  isShown: boolean;
};

interface SliceState {
  columns: TemplateStructureColumn[];
  sortedField: TemplateStructureSortedField;
}

export const columnsSlice = createSlice({
  initialState: {
    sortedField: null,
    columns: Object.values(TemplateStructureColumns).map((item) => ({
      key: item,
      isShown: true,
    })),
  },
  name: 'columns',
  reducers: {
    setTemplateStructureSortable(state: SliceState, action: PayloadAction<SortValues>) {
      state.sortedField = action.payload;
    },
    setVisibleTemplateStructureColumns(
      state: SliceState,
      action: PayloadAction<Record<TemplateStructureColumns, boolean>>
    ) {
      state.columns = state.columns.map((column) => {
        return { ...column, isShown: action.payload[column.key] };
      });
    },
  },
});

export const { setVisibleTemplateStructureColumns, setTemplateStructureSortable } =
  columnsSlice.actions;

export const useColumnsData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.cbd.templateStructure.columns) };
};
