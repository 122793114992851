import { combineReducers } from '@reduxjs/toolkit';
import { loadListSlice } from './loadList';
import { columnsSlice } from './columns';
import { filtersSlice } from './filters';

export const uploads = combineReducers({
  list: loadListSlice.reducer,
  columns: columnsSlice.reducer,
  filters: filtersSlice.reducer,
});
