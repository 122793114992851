import { Grants } from '@ppm/ppm-platform-user-service';
import { GrantsKey } from 'common/types';
import { ButtonInfo } from 'pages/Request/partials/ActionButtons/types';

export const applyModal = 'applyModal';
export const purchasePriceNotificationModal = 'purchasePriceNotificationModal';

const validateButtonInfo: ButtonInfo = {
  grantKey: undefined,
  translationMessage: 'validate',
};

const approvePurchasePriceButtonInfo: ButtonInfo = {
  grantKey: undefined,
  translationMessage: 'approvePurchasePrice',
};

const approveRecommendedPriceAndPurchasePriceButtonInfo: ButtonInfo = {
  grantKey: undefined,
  translationMessage: 'approveRecommendedPriceAndPurchasePrice',
};

const createButtonInfo = (
  grantKey: GrantsKey | undefined,
  translationMessage: string
): ButtonInfo => ({
  grantKey,
  translationMessage,
});

export const validationButtonState: Record<Grants, ButtonInfo> = {
  [Grants.RequestTransitionDraftToOnSupplierValidation]: approvePurchasePriceButtonInfo,
  [Grants.RequestTransitionOnSupplierValidationToOnPurchasePriceValidation]:
    approvePurchasePriceButtonInfo,
  [Grants.RequestTransitionRequiresDetailingFromLMUserToOnSupplierValidation]:
    approvePurchasePriceButtonInfo,
  [Grants.RequestTransitionRequiresDetailingFromSupplierToOnPurchasePriceValidation]:
    approvePurchasePriceButtonInfo,
  [Grants.RequestTransitionPricelistFormationToPricelistFormed]: approvePurchasePriceButtonInfo,
  [Grants.RequestTransitionOnPurchasePriceValidationToOnRecommendedRetailPriceValidation]:
    createButtonInfo(
      'RequestTransitionOnPurchasePriceValidationToOnRecommendedRetailPriceValidation',
      'approveRecommendedRetailPrice'
    ),
  [Grants.RequestTransitionOnRecommendedRetailPriceValidationToPricelistFormation]:
    approveRecommendedPriceAndPurchasePriceButtonInfo,
  [Grants.RequestTransitionOnValidationWithDepartmentDirectorToPricelistFormation]:
    approveRecommendedPriceAndPurchasePriceButtonInfo,
  [Grants.RequestTransitionPricelistFormationToValidated]:
    approveRecommendedPriceAndPurchasePriceButtonInfo,
  [Grants.RequestTransitionOnRecommendedRetailPriceValidationToOnValidationWithPurchasingDirector]:
    createButtonInfo(
      'RequestTransitionOnRecommendedRetailPriceValidationToOnValidationWithPurchasingDirector',
      'approveWithPurchasingDirector'
    ),
  [Grants.RequestTransitionOnValidationWithPurchasingDirectorToOnValidationWithDepartmentDirector]:
    createButtonInfo(undefined, 'approveWithDepartmentDirector'),
  [Grants.RequestTransitionProposedToOnLmValidation]: validateButtonInfo,
  [Grants.RequestTransitionOnLmValidationToRequestValidated]: validateButtonInfo,
} as any;
