import { Translations } from 'translations';
import { ExternalSourceDeletedFilterName, ExternalSourceFilterName } from '../ExternalSource.types';

export enum ExternalSourceColumns {
  SOURCE = 'source',
  CURRENCY_CODE = 'currencyCode',
  CATEGORY = 'category',
  TYPE = 'type',
  SUBTYPE = 'subtype',
  NAME = 'name',
  NAME2 = 'name2',
  DATE = 'date',
  VALUE = 'value',
  REGION = 'region',
  MEASURE = 'measure',
  DIAMETER = 'diameter',
  THICKNESS = 'thickness',
  CONTRACT = 'contract',
  STANDARD = 'standard',
  GRADE = 'grade',
  PRICE_TYPE_NAME = 'priceTypeName',
}

export const getLabel = (key: ExternalSourceFilterName, t: Translations) => {
  return {
    source: t('source'),
    name2: t('newNomination'),
    categoryId: t('category'),
    typeId: t('type'),
    subtypeId: t('subType'),
    name: t('nomination'),
    externalSourceDate: t('date'),
    value: t('value'),
    region: t('region'),
    measure: t('unit'),
    infoGrade: t('mark'),
    infoDiameter: t('diameter'),
    infoThickness: t('thickness'),
    infoContract: t('contractType'),
    infoStandard: t('materialStandard'),
    priceTypeName: t('priceType'),
    multipleData: t('multipleData'),
  }[key];
};

export const externalSourceFiltersName: ExternalSourceDeletedFilterName[] = [
  'multipleData',
  'externalSourceDate',
  'name2',
  'value',
  'region',
  'measure',
  'infoGrade',
  'infoDiameter',
  'infoThickness',
  'infoContract',
  'infoStandard',
  'priceTypeName',
];
