import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChangeTemplateStructureData } from 'api/cbd/templates';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

interface SliceState {
  data: IChangeTemplateStructureData;
}

const initialState: SliceState = {
  data: {},
};

export const changeEditTemplateConfigSlice = createSlice({
  initialState,
  name: 'changeEditTemplateConfig',
  reducers: {
    setChangeEditTemplateConfig: (
      state: SliceState,
      action: PayloadAction<IChangeTemplateStructureData>
    ) => {
      state.data = action.payload;
    },
    clearChangeEditTemplateConfig: () => initialState,
  },
});

export const { clearChangeEditTemplateConfig, setChangeEditTemplateConfig } =
  changeEditTemplateConfigSlice.actions;

export const useChangeEditTemplateConfig = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.changeEditTemplateConfig);
};
