import React, { FC } from 'react';
import { Flex, Text, Tooltip } from '@mozaic-ds/react';
import { NotificationInformation24 } from '@mozaic-ds/icons/react';
import { ChartType } from 'chart.js';
import { ChartProps, Line } from 'react-chartjs-2';
import classNames from 'classnames';
import { useAppTranslations } from 'translations';
import { chartColors } from 'components/Chart/hooks/useChartConfig';
import styles from './Comparison.module.scss';

interface ILineChart {
  title: string;
  chartConfig: ChartProps<ChartType>;
  chartLabels: string[];
  className?: string;
}

const LineChart: FC<ILineChart> = ({ className, children, title, chartConfig, chartLabels }) => {
  const translations = useAppTranslations();

  return (
    <Flex className={classNames(styles.chart, className)} direction="column" padding="mu100">
      <Flex justifyContent="space-between">
        <Text weight="semi-bold">{title}</Text>
        <Flex>
          {children}
          <Tooltip
            className="mu-ml-100"
            placement="left"
            label={
              <Flex alignItems="flex-start" direction="column">
                {chartLabels.map((item, index) => (
                  <Flex alignItems="center">
                    <span
                      className={classNames(styles.legendCircle, 'mu-mr-100', 'mu-p-050')}
                      style={{
                        background: chartColors[index],
                      }}
                    />
                    <Text>{translations(item)}</Text>
                  </Flex>
                ))}
              </Flex>
            }
          >
            <NotificationInformation24 />
          </Tooltip>
        </Flex>
      </Flex>
      <Line {...(chartConfig as ChartProps<'line'>)} />
    </Flex>
  );
};

export default LineChart;
