import { Translations } from 'translations';

export function gtmTransferToCreateRequestPage(t: Translations) {
  window.dataLayer.push({
    event: 'LeroyMerlin',
    eventCategory: 'click-button', // действие-элемент
    eventAction: t('requestsHeaderTitle'), // блок, в котором находится кнопка
    eventLabel: t('createRequest'), // текст на кнопке
  });
}

export function gtmClickByRequestRow(t: Translations, id: string) {
  window.dataLayer.push({
    event: 'LeroyMerlin',
    eventCategory: 'click-button', // действие-элемент
    eventAction: t('requestsHeaderTitle'), // блок, в котором находится кнопка
    eventLabel: id, // Запрос / ID запроса
  });
}
