import { deleteTemplateStructure } from 'api/cbd/templates';
import { useCallback } from 'react';
import { useUserData } from 'store/auth/user';
import { UseFormReturn } from 'react-hook-form';
import { useNotifications } from '@mozaic-ds/react';
import { useAppTranslations } from 'translations';
import {
  clearDeletionEditTemplateConfig,
  setDeletionEditTemplateConfig,
  useDeletionEditTemplateConfig,
} from 'store/cbd/deletionEditTemplateConfig';
import { useAppDispatch } from 'store/hooks';
import { IDeletionTemplateForm } from './useEditTemplateForm';
import { onDeleteColumnChange, onDeleteRowChange } from '../../../helpers';

const useEditTemplateActions = (form: UseFormReturn<IDeletionTemplateForm>) => {
  const dispatch = useAppDispatch();
  const { danger, success } = useNotifications();
  const translations = useAppTranslations();
  const { data: config } = useDeletionEditTemplateConfig();
  const { data: userData } = useUserData().user;

  form.watch((data, { name, type }) => {
    if (type === 'change') {
      switch (name) {
        case 'deleteRow': {
          dispatch(setDeletionEditTemplateConfig(onDeleteRowChange(data.deleteRow || '', config)));
          break;
        }
        case 'deleteColumn': {
          dispatch(
            setDeletionEditTemplateConfig(onDeleteColumnChange(data.deleteColumn || '', config))
          );
          break;
        }
        default: {
          break;
        }
      }
    }
  });

  const onDeleteStructureSubmit = useCallback(async () => {
    try {
      const { row, column } = config;

      if (column || row) {
        await deleteTemplateStructure({
          userId: userData?.id,
          structure: { column, row },
        });

        success({
          title: translations('successNotification'),
          message: translations('changesSuccessCBDTemplate'),
        });
        return;
      }
      danger({
        title: translations('dangerNotification'),
        message: translations('unableChangesCBDTemplate'),
      });
    } catch {
      danger({
        title: translations('dangerNotification'),
        message: translations('invalidDeleteOptions'),
      });
    }
  }, [config, danger, success, translations, userData?.id]);

  const onClear = () => {
    form.reset();
    dispatch(clearDeletionEditTemplateConfig());
  };

  return {
    onClear,
    onDeleteStructureSubmit,
  };
};

export default useEditTemplateActions;
