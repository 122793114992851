import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initPageable } from 'common/consts';
import { IGetCbdListPayload, IGetAllCbdListWithArrayItemGroupResponse } from 'common/cbd/types';
import { useAppSelector } from 'store/hooks';
import { getAllCbdListWithArrayItemGroups } from 'api/cbd/cbd';
import { setFailure, setLoading } from '../../helpers';
import { RequiredStateFields, RootState } from '../../types';

interface SliceState extends RequiredStateFields, IGetAllCbdListWithArrayItemGroupResponse {
  selectedRowsId: string[];
  itemsForCompare: string[];
}

const initialState: SliceState = {
  selectedRowsId: [],
  itemsForCompare: [],
  data: [],
  pageable: initPageable,
  isLoading: false,
  isFailure: false,
};

export const getCbdListWithItemGroupsData = createAsyncThunk(
  'cbd/getCbdListWithItemGroupsData',
  async (payload: IGetCbdListPayload, { rejectWithValue }) => {
    try {
      return await getAllCbdListWithArrayItemGroups(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const listSlice = createSlice({
  initialState,
  name: 'list',
  reducers: {
    setSelectedRowsId: (state, action) => {
      state.selectedRowsId = action.payload;
    },
    setItemsForCompare: (state, action: PayloadAction<string[]>) => {
      state.itemsForCompare = action.payload;
    },
    clearCompareListSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCbdListWithItemGroupsData.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getCbdListWithItemGroupsData.fulfilled,
        (state: SliceState, action: PayloadAction<IGetAllCbdListWithArrayItemGroupResponse>) => {
          state.data = action.payload.data;
          state.itemsForCompare = action.payload.data.map((item) => item.id.toString());
          state.pageable = action.payload.pageable;
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getCbdListWithItemGroupsData.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const { setSelectedRowsId, setItemsForCompare, clearCompareListSlice } = listSlice.actions;

export const useCbdWithItemGroupsList = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.compare.list);
};
