import { useAppTranslations } from 'translations';
import { getComponents } from 'components/Header/consts';
import { useCurrentPath } from 'hook/useCurrentRoute';
import { useRequestData } from 'store/request/request';
import { useCurrentPriceListData } from 'store/priceList/currentPriceList';
import { OptionData } from 'components/Header/types';
import { useMemo } from 'react';

export const useGetRenderComponent = (path: string) => {
  const translations = useAppTranslations();

  const { params, route } = useCurrentPath();
  const { request } = useRequestData().data;
  const { priceList } = useCurrentPriceListData().data;

  const options: OptionData = useMemo(
    () => ({
      params,
      data: {
        createRequest: {
          id: request?.id,
        },
        request: {
          id: request?.id,
          statusId: request?.statusId,
          statusName: request?.statusName,
        },
        priceList: {
          id: priceList?.id,
          statusId: priceList?.statusId,
          statusName: priceList?.statusName,
        },
      },
    }),
    [
      params,
      priceList?.id,
      priceList?.statusId,
      priceList?.statusName,
      request?.id,
      request?.statusId,
      request?.statusName,
    ]
  );

  const componentKey = route ? route.path : path;

  return getComponents(translations, options)[componentKey];
};
