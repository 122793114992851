import {
  GetRequestById,
  GetRequestByIdResponse,
  UpdateRequest,
  DeleteRequest,
} from '@ppm/ppm-platform-api';
import { apiCreator } from 'api/index';

export const getRequestById = apiCreator<
  typeof GetRequestById,
  GetRequestByIdResponse,
  Parameters<(typeof GetRequestById)['url']>
>(GetRequestById, { headers: { 'Cache-Control': 'no-cache' } })[GetRequestById.method];

export const updateRequest = apiCreator<
  typeof UpdateRequest,
  UpdateRequest.Response,
  Parameters<(typeof UpdateRequest)['url']>,
  UpdateRequest.Body
>(UpdateRequest)[UpdateRequest.method];

export const deleteRequest = apiCreator<
  typeof DeleteRequest,
  undefined,
  Parameters<(typeof GetRequestById)['url']>
>(DeleteRequest)[DeleteRequest.method];
