export const emptyCurrencySubscriptionsRow = {
  id: -1,
  login: '',
  dateStart: '',
  dateFinish: '',
  currency1: '',
  currency2: '',
  subscriptionType: '',
  value: 0,
  term: 0,
};
