import * as React from 'react';
import { Badge, Text, Flex } from '@mozaic-ds/react';
import { ArrowArrowBottomRight16 } from '@mozaic-ds/icons/react';

export const PriceDownTag = () => {
  return (
    <Badge theme="success" className="mu-ml-075">
      <Flex alignItems="center">
        <Text lineHeight="s">₽</Text>
        <ArrowArrowBottomRight16 fill="#006902" />
      </Flex>
    </Badge>
  );
};
