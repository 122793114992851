import { useCallback } from 'react';
import { useModals } from '@mozaic-ds/react';
import { Grants } from '@ppm/ppm-platform-user-service';
import { getTransitionKeyByGrant } from 'common/utils';
import { getRequest, useRequestData } from 'store/request/request';
import { executeSubRequestFlow } from 'api/request/subRequests';
import { useAppDispatch } from 'store/hooks';
import { useAppTranslations } from 'translations';
import { isProd } from 'common/consts';
import { gtmClickManageRequestButton } from './gtm';
import { applyModal, purchasePriceNotificationModal } from './const';

export const useSetRequestToDifferentStatus = (id: string) => {
  const t = useAppTranslations();
  const dispatch = useAppDispatch();
  const { close } = useModals();

  const { selectedContainers } = useRequestData().data;

  const runSubRequestFlow = useCallback(
    async ({
      buttonName,
      grant,
      popupId,
    }: {
      buttonName: string;
      popupId: typeof purchasePriceNotificationModal | typeof applyModal;
      grant?: Grants;
    }) => {
      try {
        const container = selectedContainers[selectedContainers.length - 1];
        const currentTransitions = container.flowRights.transitions;
        const { transitionStatus } = getTransitionKeyByGrant(currentTransitions, grant);
        if (!transitionStatus) return;

        // eslint-disable-next-line no-restricted-syntax
        for (const item of selectedContainers) {
          // eslint-disable-next-line no-await-in-loop
          await executeSubRequestFlow({ urlParameters: [id, item.id, transitionStatus] });
          if (isProd)
            gtmClickManageRequestButton({
              t,
              requestId: id,
              buttonLabel: buttonName,
              subrequestId: item.id,
            });
        }
        dispatch(getRequest(id));
      } catch (e) {
        close(popupId);
      }
    },
    [close, dispatch, id, selectedContainers, t]
  );

  return {
    runSubRequestFlow,
  };
};
