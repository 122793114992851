import React, { FC } from 'react';
import {
  Button,
  Modal as ModalMozaic,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  ModalTitle,
  Text,
  Loader,
  View,
} from '@mozaic-ds/react';
import { IModalProps } from 'components/Modal/types';
import styles from './Modal.module.scss';

const Modal: FC<IModalProps> = (props) => {
  const {
    id,
    isLoading,
    heading,
    title,
    text,
    okBtnText,
    cancelBtnText,
    handleOk,
    handleClose,
    onClose,
    theme,
    children,
    disabledButton,
  } = props;

  return (
    <ModalMozaic id={id} onClose={onClose}>
      {title && (
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
      )}
      <ModalBody>
        {heading && <ModalHeading>{heading}</ModalHeading>}
        {text && <Text as="p">{text}</Text>}
        {children && <View className="mu-pt-150">{children}</View>}
      </ModalBody>
      {handleOk && (
        <ModalFooter>
          <Button
            onClick={handleOk}
            theme={theme === 'danger' ? 'danger' : 'primary'}
            variant={theme === 'danger' ? 'bordered' : 'solid'}
            className={isLoading ? styles.modalOkBtn : undefined}
            isDisabled={disabledButton}
          >
            {isLoading ? <Loader /> : okBtnText}
          </Button>
          {cancelBtnText && (
            <Button onClick={handleClose} variant="bordered">
              {cancelBtnText}
            </Button>
          )}
        </ModalFooter>
      )}
    </ModalMozaic>
  );
};

export default Modal;
