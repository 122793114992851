import React from 'react';
import { Flex, Text } from '@mozaic-ds/react';
import { IFieldWrapper } from './FieldWrapper.types';
import { FIELD_ALIGN, FIELD_DIRECTION, LABEL_CLASS } from './utils';
import styles from './FieldWrapper.module.scss';

const FieldWrapper = (props: IFieldWrapper) => {
  const { label, id, children, fieldLayout = 'vertical', className } = props;

  const flexDirection = FIELD_DIRECTION[fieldLayout];
  const alignItems = FIELD_ALIGN[fieldLayout];
  const labelClass = LABEL_CLASS[fieldLayout];

  return (
    <Flex direction={flexDirection} alignItems={alignItems} className={className}>
      <label htmlFor={id} className={`${styles.label} ${styles[labelClass]}`}>
        <Text as="span" className={styles.label_text}>
          {label}
        </Text>
      </label>

      {children}
    </Flex>
  );
};

export default FieldWrapper;
