import { Button, useModals, TButtonTheme } from '@mozaic-ds/react';
import { deleteRequest } from 'api/request';
import Modal from 'components/Modal/Modal';
import React, { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppTranslations } from 'translations';
import { isProd, isSupplierEnvironment } from 'common/consts';
import { gtmClickManageRequestButton } from 'pages/Request/partials/ActionButtons/gtm';

const deleteModal = 'deleteModal';

interface IDeleteRequestProps {
  id: string | null;
  theme?: TButtonTheme;
}

export const DeleteRequest: FC<IDeleteRequestProps> = (props) => {
  const { id, theme } = props;
  const [isLoading, setIsLoading] = useState(false);

  const { open, close } = useModals();
  const translations = useAppTranslations();
  const navigate = useNavigate();

  const handleDelete = useCallback(async () => {
    if (!id) return;

    setIsLoading(true);

    if (isSupplierEnvironment && isProd)
      gtmClickManageRequestButton({
        t: translations,
        buttonLabel: 'deleteRequest',
        requestId: id,
      });
    await deleteRequest({ urlParameters: [id] })
      .then(() => navigate('/'))
      .finally(() => setIsLoading(false));
  }, [id, navigate, translations]);

  return (
    <>
      <Button
        isDisabled={!id}
        className="mu-mr-100"
        theme={theme || 'danger'}
        variant="bordered"
        size="m"
        width="fit"
        onClick={(e) => {
          e.preventDefault();
          open(deleteModal);
        }}
      >
        {translations('deleteRequest')}
      </Button>

      <Modal
        id={deleteModal}
        title={translations('securityStep')}
        heading={translations('deleteRequest')}
        text={translations('sureDeleteRequest')}
        isLoading={isLoading}
        okBtnText={translations('yes')}
        cancelBtnText={translations('no')}
        handleOk={handleDelete}
        handleClose={() => close(deleteModal)}
        theme="danger"
      />
    </>
  );
};
