import React, { FC, useEffect, useMemo } from 'react';
import { Button, Flex, Loader, Text } from '@mozaic-ds/react';
import { RequestModelView } from '@ppm/ppm-platform-api';
import { DownloadWeb24 } from '@mozaic-ds/icons/react';
import { useAppTranslations } from 'translations';
import { useStepperContext } from 'components/Stepper/StepperContext';
import { TemplateUploader } from 'components/TemplateUploader/TemplateUploader';
import { useDownloadTemplate } from 'pages/Request/partials/DownloadingTemplate/hooks';
import { useUserData } from 'store/auth/user';
import { getHasGrants } from 'common/utils';
import cn from 'classnames';
import { DeleteRequest } from 'components/DeleteRequest/DeleteRequest';
import { FileType } from '@ppm/ppm-platform-file-service/build/enums';
import { Steps } from 'pages/CreatingRequest/types';
import { ParsingStatus } from '@ppm/ppm-platform-common';
import { useSocketContext } from 'components/SocketContainer/SocketContext';
import { FILE_UPLOADED_ACTION } from 'components/SocketContainer/consts';
import { getRequest } from 'store/request/request';
import { useAppDispatch } from 'store/hooks';
import { Block, FileTemplate } from '../FileTemplate';
import styles from '../../../CreatingRequest.module.scss';

export const SupplierTemplateForm: FC<{ request: RequestModelView }> = ({ request }) => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const { validatedStep } = useStepperContext<Steps>();
  const { userGrants } = useUserData().user;
  const { socket } = useSocketContext();

  useEffect(() => {
    socket.on(FILE_UPLOADED_ACTION, () => dispatch(getRequest(request.id)));
    return () => {
      socket.off(FILE_UPLOADED_ACTION);
    };
  }, [dispatch, request.id, socket]);

  const { id, filesInfo } = request;
  const hasTemplate: boolean = useMemo(() => {
    const template = filesInfo.find(
      (file) => file.fileType === FileType.SupplierTemplate && file.status === ParsingStatus.Success
    );
    return Boolean(template) && template?.messages.length === 0;
  }, [filesInfo]);
  const { isLoading, downloadTemplate } = useDownloadTemplate(id);

  const blocks: Block[] = [
    {
      title: translations('downloadSupplierTemplateTip'),
      component: (
        <Button
          type="button"
          leftIcon={isLoading ? <Loader className={styles.loader} /> : <DownloadWeb24 />}
          size="m"
          className={cn('mu-mt-100', styles.btn)}
          onClick={downloadTemplate}
        >
          {translations('downloadTemplate')}
        </Button>
      ),
    },
    {
      title: translations('uploadSupplierTemplateTip'),
      component: (
        <TemplateUploader id={id} grants={getHasGrants(userGrants)} className="mu-mt-100" />
      ),
    },
  ];

  return (
    <>
      <Text weight="semi-bold" theme="dark" as="p" className="mu-mb-150" size="xl">
        {translations('fillSupplierTemplateHeader')}
      </Text>
      <FileTemplate blocks={blocks} />
      <hr />
      <Flex justifyContent="space-between" marginTop="mu250">
        <DeleteRequest id={id} theme="neutral" />
        <Button
          isDisabled={!hasTemplate}
          type="button"
          size="m"
          width="fit"
          onClick={() => validatedStep(Steps.cbd)}
        >
          {translations('continue')}
        </Button>
      </Flex>
    </>
  );
};
