import { Flex } from '@mozaic-ds/react';
import React from 'react';
import ExchangeRates from './partials/ExchangeRates/ExchangeRates';
import PriceOnComponents from './partials/PriceOnComponents/PriceOnComponents';
import styles from './ExternalData.module.scss';

const ExternalData = () => {
  return (
    <Flex marginTop="mu150" className={styles.wrapper} direction="column">
      <ExchangeRates />
      <PriceOnComponents />
    </Flex>
  );
};

export default ExternalData;
