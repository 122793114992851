import React, { JSX, FC, Fragment } from 'react';
import { DownloadApp24 } from '@mozaic-ds/icons/react';
import cn from 'classnames';
import { Flex } from '@mozaic-ds/react';
import { IFlexProps } from '@mozaic-ds/react/lib/components/Flex/Flex.types';
import { IFileUploaderListProps } from '../types';
import styles from '../FileUploader.module.scss';

const FileUploaderList: FC<IFileUploaderListProps> = ({
  files,
  onDeleteFile,
  onDownloadFile,
  hasWrapper,
  isScrollable,
}): JSX.Element => {
  // eslint-disable-next-line react/jsx-no-useless-fragment, react/jsx-fragments
  const Wrapper = hasWrapper ? Flex : Fragment;
  const wrapperProps: IFlexProps =
    hasWrapper || isScrollable
      ? {
          className: cn({ [styles.listWrapper]: hasWrapper, [styles.scrollable]: isScrollable }),
          wrap: hasWrapper ? 'wrap' : 'nowrap',
        }
      : {};

  return (
    <ul className="mc-fileuploader__files">
      <Wrapper {...wrapperProps}>
        {files &&
          files.length > 0 &&
          files.map((item) => (
            <li
              key={item.id}
              className={cn(
                styles.file,
                'mc-fileuploader__file',
                `mc-fileuploader__file--${
                  item?.messages && item.messages.length ? 'is-invalid' : 'is-valid'
                }`
              )}
            >
              <span title={item.name} className={cn(styles.fileName, 'mc-fileuploader__file-name')}>
                {item.name}
              </span>
              <span className="mc-fileuploader__file-icon mu-m-050" />
              {typeof onDownloadFile !== 'undefined' && (
                <DownloadApp24
                  className={cn('mu-m-050', styles.pointer)}
                  onClick={() => onDownloadFile(item.id)}
                />
              )}
              {typeof onDeleteFile !== 'undefined' && (
                <button
                  type="button"
                  className="mc-fileuploader__delete"
                  onClick={() => onDeleteFile(item.id)}
                />
              )}
              {item.messages &&
                Boolean(item.messages.length) &&
                item.messages.map((text: string) => (
                  <div key={text} className="mc-fileuploader__file-message">
                    {text}
                  </div>
                ))}
            </li>
          ))}
      </Wrapper>
    </ul>
  );
};

export default FileUploaderList;
