import { View } from '@mozaic-ds/react';
import { useMount } from 'hook/useMount';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import { getCostType } from 'store/cbd/costTypes';
import { getScheduler } from 'store/cbd/scheduler/scheduler';
import { getFromCurrenciesList, getToCurrenciesList } from 'store/cbd/extCurrency';
import AlertAttribute from './partials/AlertAttribute/AlertAttribute';
import AlertCurrency from './partials/AlertCurrency/AlertCurrency';
import SchedulerForm from './partials/SchedulerForm/SchedulerForm';
import styles from './Scheduler.module.scss';

const Scheduler = () => {
  const dispatch = useAppDispatch();

  useMount(() => {
    dispatch(getCostType());
    dispatch(getScheduler());
    dispatch(getFromCurrenciesList());
    dispatch(getToCurrenciesList());
  });

  return (
    <View marginTop="mu200" className={styles.wrapper}>
      <SchedulerForm />
      <AlertAttribute />
      <AlertCurrency />
    </View>
  );
};

export default Scheduler;
