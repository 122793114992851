import { Translations } from 'translations';
import { FilterName } from '../AlertCurrency.types';

export enum AlertCurrencyColumns {
  LOGIN = 'login',
  DATE_START = 'dateStart',
  DATE_FINISH = 'dateFinish',
  CURRENCY1 = 'currency1',
  CURRENCY2 = 'currency2',
  SUBSCRIPTION_TYPE = 'subscriptionType',
  VALUE = 'value',
  TERM = 'term',
}

export const getLabel = (key: FilterName, t: Translations) => {
  return {
    login: t('LDAP'),
    dateStart: t('subscriptionStartDate'),
    dateFinish: t('subscriptionEndDate'),
    currency1: `${t('currency')} 1`,
    currency2: `${t('currency')} 2`,
    subscriptionType: t('subscriptionType'),
    value: t('indicatorValue'),
    term: t('analyzedDaysPeriod'),
  }[key];
};

export const filtersName: FilterName[] = [
  'login',
  'dateStart',
  'dateFinish',
  'currency1',
  'currency2',
  'subscriptionType',
  'value',
  'term',
];
