import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getExternalDataRequest,
  IExternalData,
  IExternalDataRequest,
} from 'api/cbd/externalSource';
import { initPageable } from 'common/consts';
import { useAppSelector } from 'store/hooks';
import { IPageSize } from 'common/cbd/types';
import { RootState } from 'store/types';
import { DOWNLOAD_LIMIT_EXTERNAL_DATA, emptyExtTableRow } from './consts';

interface SliceState extends IExternalDataRequest {
  isAdding: boolean;
  editItems: string[];
  selectedRowsId: string[];
  isLoading: boolean;
  isLastPage: boolean;
  pageNumber: number;
}

const initialState: SliceState = {
  isAdding: false,
  editItems: [],
  data: [],
  isLoading: false,
  selectedRowsId: [],
  isLastPage: false,
  pageNumber: 1,
  pageable: initPageable,
};

interface IChangeTableRowPayload {
  id: number;
  fieldName: keyof IExternalData;
  value: string;
}

export const getExtData = createAsyncThunk(
  'cbd/extTableData/getExtData',
  async (payload: IPageSize, { rejectWithValue }) => {
    try {
      return await getExternalDataRequest(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const extTableDataSlice = createSlice({
  initialState,
  name: 'extTableData',
  reducers: {
    setEditItems: (state, action: PayloadAction<string[]>) => {
      state.editItems = action.payload;
    },
    setIsAdding: (state, action: PayloadAction<boolean>) => {
      state.isAdding = action.payload;
      if (action.payload) {
        state.data = [emptyExtTableRow, ...state.data];
        state.editItems = ['-1'];
      }
    },
    setSelectedRowsId: (state, action: PayloadAction<string[]>) => {
      state.selectedRowsId = action.payload;
    },
    removeUnusedRows: (state, action: PayloadAction<number[]>) => {
      state.data = state.data.filter((item) => item.id && !action.payload.includes(item.id));
    },
    changeTableRow: (state, action: PayloadAction<IChangeTableRowPayload>) => {
      const rowIndex = state.data.findIndex((item) => item.id === action.payload.id);

      state.data[rowIndex] = {
        ...state.data[rowIndex],
        [action.payload.fieldName]: action.payload.value,
      };
    },
    setPageNumber(state, action) {
      state.pageNumber = action.payload;
    },
    clearExtTableDataState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getExtData.fulfilled,
        (state: SliceState, action: PayloadAction<IExternalDataRequest>) => {
          state.isLastPage = action.payload.data.length !== DOWNLOAD_LIMIT_EXTERNAL_DATA;
          state.data = [...state.data, ...action.payload.data];
          state.pageable = action.payload.pageable;
          state.isLoading = false;
        }
      )
      .addCase(getExtData.pending, (state: SliceState) => {
        state.isLoading = true;
      })
      .addCase(getExtData.rejected, (state: SliceState) => {
        state.isLoading = false;
      });
  },
});

export const {
  setSelectedRowsId,
  removeUnusedRows,
  setEditItems,
  setIsAdding,
  changeTableRow,
  setPageNumber,
  clearExtTableDataState,
} = extTableDataSlice.actions;

export const useExtTableData = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.externalData.tableData);
};
