import * as React from 'react';
import { DateInput } from '@mozaic-ds/react';
import styles from 'pages/Request/Request.module.scss';
import { Label } from 'components/Label/Label';
import { useAppTranslations } from 'translations';
import { useEffect } from 'react';
import { useRequestData } from 'store/request/request';
import { parseDateForMozaicComponent } from 'common/utils';
import { useSubRequestDateForm } from './hooks';
import { ContainerDatesProps } from './types';

export const FieldDate = (props: ContainerDatesProps) => {
  const { id, isDisabled, filedName, restriction } = props;
  const translations = useAppTranslations();

  const { selectedContainers } = useRequestData().data;
  const { field, handleUpdate, setValue } = useSubRequestDateForm(id, filedName);

  useEffect(
    () => {
      const date = selectedContainers[0]?.[filedName];
      setValue(filedName, date ? parseDateForMozaicComponent(date) : '');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedContainers]
  );

  return (
    <Label text={translations(filedName)} className={styles.dateInput}>
      <DateInput
        {...field}
        onBlur={handleUpdate}
        isDisabled={isDisabled}
        className="mc-text-input mu-mt-025 mu-mb-100"
        {...restriction}
      />
    </Label>
  );
};
