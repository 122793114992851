import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCurrencyRateRequest, ICurrencyRate, IGetCurrencyRate } from 'api/cbd/externalSource';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

interface SliceState {
  list: ICurrencyRate[];
  visibleCurrencies: number[];
}

const initialState = {
  list: [],
  visibleCurrencies: [],
};

export const getCurrencyRate = createAsyncThunk(
  'cbd/currencyRate/getCurrencyRate',
  async (payload: string, { rejectWithValue }) => {
    try {
      return await getCurrencyRateRequest(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const currencyRateSlice = createSlice({
  initialState,
  name: 'currencyRate',
  reducers: {
    setVisibleCurrencies(state: SliceState, action: PayloadAction<number[]>) {
      state.visibleCurrencies = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getCurrencyRate.fulfilled,
        (state: SliceState, action: PayloadAction<IGetCurrencyRate>) => {
          state.list = action.payload.data;
          state.visibleCurrencies = action.payload.data.map((item) => item.id);
        }
      )
      .addCase(getCurrencyRate.rejected, (state: SliceState) => {
        state.list = [];
        state.visibleCurrencies = [];
      });
  },
});

export const { setVisibleCurrencies } = currencyRateSlice.actions;

export const useCurrencyRate = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.externalData.currencyRate);
};
