import { AxiosResponse } from 'axios';
import {
  IBindCbdPayload,
  IDeleteItemGroupPayload,
  IGetCbdListPayload,
  IGetCbdLoadListPayload,
  IGetItemGroupPayload,
  IGetCbdLoadListResponse,
  ISendCbdFilePayload,
  IGetCbdItemInfoPayload,
  IUpdateStatusPayload,
  IGetAllCbdListResponse,
  IGetItemGroupResponse,
  IGetCbdItemInfoResponse,
  ICbdLoadTimeouts,
  ICbdLoad,
  IGetAllCbdListWithArrayItemGroupResponse,
} from 'common/cbd/types';
import { cbdApiClient } from 'api/index';
import { apiClient } from 'api/utility/consts';
import { RequestModelView } from '@ppm/ppm-platform-api';

interface ICreateCbdLoadPayload {
  fileId: string;
  fileName: string;
  request: RequestModelView;
}

export const getCbdLoadList = async (params: IGetCbdLoadListPayload) => {
  const { data }: AxiosResponse<IGetCbdLoadListResponse> = await cbdApiClient.get(
    '/api/cbd/loads',
    {
      params,
    }
  );
  return data;
};

export const getCbdLoad = async (loadId: number) => {
  const { data }: AxiosResponse<ICbdLoad> = await cbdApiClient.get(`api/cbd/loads/${loadId}`);
  return data;
};

export const createCbdLoad = async (params: ICreateCbdLoadPayload) => {
  const { data }: AxiosResponse<ICbdLoad> = await cbdApiClient.post(`api/cbd/loads`, params);
  return data;
};

export const getAllCbdList = async (params: IGetCbdListPayload) => {
  const { data }: AxiosResponse<IGetAllCbdListResponse> = await cbdApiClient.get('/api/cbd', {
    params,
  });
  return data;
};

export const getAllCbdListWithArrayItemGroups = async (params: IGetCbdListPayload) => {
  const { data }: AxiosResponse<IGetAllCbdListWithArrayItemGroupResponse> = await cbdApiClient.get(
    '/api/cbd/perItemGroup',
    {
      params,
    }
  );
  return data;
};

export const getItemGroup = async ({ cbdId, ...params }: IGetItemGroupPayload) => {
  const { data }: AxiosResponse<IGetItemGroupResponse> = await cbdApiClient.get(
    `/api/cbd/${cbdId}/itemGroup`,
    {
      params,
    }
  );
  return data;
};

export const getLoadErrors = async (loadId: number) => {
  const { data }: AxiosResponse = await cbdApiClient.get(`/api/cbd/loads/${loadId}/errors`);
  return data;
};

export const deleteCbd = async (cbdId: string) => {
  const { data }: AxiosResponse = await cbdApiClient.delete(`/api/cbd/${cbdId}`);
  return data;
};

export const deleteItemGroup = async ({ cbdId, id }: IDeleteItemGroupPayload) => {
  const { data }: AxiosResponse = await cbdApiClient.delete(`/api/cbd/${cbdId}/itemGroup/${id}`);
  return data;
};

export const bindCbd = async ({ cbdId, body }: IBindCbdPayload) => {
  const { data }: AxiosResponse = await cbdApiClient.post(`/api/cbd/${cbdId}/itemGroup`, body);
  return data;
};

export const updateStatus = async ({ cbdId, status }: IUpdateStatusPayload) => {
  const { data }: AxiosResponse = await cbdApiClient.patch(`/api/cbd/${cbdId}/status`, {
    status,
  });
  return data;
};

export const getCbdItemInfo = async (body: IGetCbdItemInfoPayload) => {
  const { data }: AxiosResponse<IGetCbdItemInfoResponse> = await cbdApiClient.post(
    '/api/cbd/itemGroup/validate',
    body
  );
  return data;
};

export const sendCbdFile = async (body: ISendCbdFilePayload) => {
  const { data }: AxiosResponse = await apiClient.post(
    `/api/request/${body.reqId}/file/CBD`,
    body.formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );
  return data;
};

export const getCbdSettingsTimeouts = async () => {
  const { data }: AxiosResponse<ICbdLoadTimeouts> = await cbdApiClient.get(
    '/api/cbd-settings/timeouts'
  );
  return data;
};
