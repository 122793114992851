import { SelectItem } from 'common/types';

export enum Steps {
  'start' = 'start',
  'template' = 'template',
  'cbd' = 'cbd',
  'finish' = 'finish',
}

export type StepsKey = Steps.start | Steps.template | Steps.cbd | Steps.finish;

export type CreateFormFields = {
  supplierId: SelectItem;
  comment?: string;
  reasonListId: SelectItem[];
  customReason: string;
  dueDate: string;
};
