import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';
import { IFilter } from 'common/types';
import { BoundFilterName } from 'pages/cbd/Bound/Bound.types';

interface SliceState {
  filters: IFilter<BoundFilterName>[];
  deletedFilters: BoundFilterName[];
}

export const filtersSlice = createSlice({
  initialState: {
    filters: [],
    deletedFilters: [],
  },
  name: 'cbdBoundFilters',
  reducers: {
    clearBoundDeletedFilters(state) {
      state.deletedFilters = [];
    },
    changeBoundDeletedFilters(state, action) {
      state.deletedFilters = state.deletedFilters.concat(action.payload);
    },
    setBoundFilters(state, action) {
      state.filters = action.payload;
    },
  },
});

export const { setBoundFilters, changeBoundDeletedFilters, clearBoundDeletedFilters } =
  filtersSlice.actions;

export const useBoundFiltersData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.cbd.bounds.filters) };
};
