import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortDirection } from 'common/types';
import { UploadsColumns } from 'pages/cbd/Uploads/hooks/consts';
import { IUploadsColumnSort } from 'pages/cbd/Uploads/Uploads.types';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

type SortValues = { field: IUploadsColumnSort; sortDirection: SortDirection };
export type UploadsSortedField = null | SortValues;
export type UploadsColumn = {
  key: UploadsColumns;
  isShown: boolean;
};

interface SliceState {
  columns: UploadsColumn[];
  sortedField: UploadsSortedField;
}

export const columnsSlice = createSlice({
  initialState: {
    sortedField: null,
    columns: Object.values(UploadsColumns).map((item) => ({
      key: item,
      isShown: true,
    })),
  },
  name: 'columns',
  reducers: {
    setUploadsSortable(state: SliceState, action: PayloadAction<SortValues>) {
      state.sortedField = action.payload;
    },
    setVisibleUploadsColumns(
      state: SliceState,
      action: PayloadAction<Record<UploadsColumns, boolean>>
    ) {
      state.columns = state.columns.map((column) => {
        return { ...column, isShown: action.payload[column.key] };
      });
    },
  },
});

export const { setVisibleUploadsColumns, setUploadsSortable } = columnsSlice.actions;

export const useColumnsData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.cbd.uploads.columns) };
};
