import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCostTypeRequest, ICostType, ICostTypeRequest } from 'api/cbd/externalSource';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

interface SliceState {
  list: ICostType[];
}

const initialState = {
  list: [],
};

export const getCostType = createAsyncThunk(
  'cbd/costTypes/getCostType',
  async (_, { rejectWithValue }) => {
    try {
      return await getCostTypeRequest();
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const costTypesSlice = createSlice({
  initialState,
  name: 'costTypes',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getCostType.fulfilled,
      (state: SliceState, action: PayloadAction<ICostTypeRequest>) => {
        state.list = action.payload.data;
      }
    );
  },
});

export const useCostTypes = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.costTypes);
};
