import React from 'react';
import { Flex, IconButton, Text, Toggle, View } from '@mozaic-ds/react';
import { DisplaySetting24 } from '@mozaic-ds/icons/react';
import Layer from 'components/Layer/Layer';
import { setVisibleCurrencies, useCurrencyRate } from 'store/cbd/externalData/currencyRate';
import { useAppDispatch } from 'store/hooks';
import { useAppTranslations } from 'translations';
import useExchangeRatesPopupForm from '../hooks/useExchangeRatesPopupForm';

const ExchangeRatesPopup = () => {
  const translations = useAppTranslations();
  const dispatch = useAppDispatch();
  const { list } = useCurrencyRate();
  const { checked } = useExchangeRatesPopupForm();

  const onToggleChange = (id: number) => {
    checked.onChange(
      checked.value.includes(id)
        ? checked.value.filter((item) => item !== id)
        : [...checked.value, id]
    );
  };

  return (
    <Layer
      id="exchangeRatesPopup"
      icon={<DisplaySetting24 fill="#71706b" />}
      resetItems={() => checked.onChange(list.map((item) => item.id))}
      saveItems={() => dispatch(setVisibleCurrencies(checked.value))}
      title={translations('currencies')}
      button={
        <IconButton theme="neutral" size="s" variant="bordered" isDisabled={!list.length}>
          <DisplaySetting24 />
        </IconButton>
      }
    >
      <View as="form" paddingLeft="mu025" marginTop="mu100">
        <Text>{translations('editingCurrency')}</Text>
        {list.map(({ id, currencyId1, currencyId2 }) => {
          return (
            <View key={id} marginTop="mu150" marginBottom="mu150">
              <Flex justifyContent="space-between">
                <Text>{currencyId1.toUpperCase()}</Text>
                <Text>{currencyId2.toUpperCase()}</Text>
                <Toggle
                  isChecked={checked.value.includes(id)}
                  onChange={() => onToggleChange(id)}
                />
              </Flex>
            </View>
          );
        })}
      </View>
    </Layer>
  );
};

export default ExchangeRatesPopup;
