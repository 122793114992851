import React from 'react';
import { Flex, Loader } from '@mozaic-ds/react';
import { clearNomenclatureStore } from 'store/dashboard/rightsManagement/nomenclature';
import { useAppDispatch } from 'store/hooks';
import { clearAll as clearSuppliersState } from 'store/dashboard/rightsManagement/supplier';
import { getRolesData, useRoles } from 'store/roles/roles';
import { useMount } from 'hook/useMount';
import { useParams } from 'react-router-dom';
import { getReplacement, useReplacementData } from 'store/replacement/replacement';
import {
  getSupplierLinkData,
  useSupplierLink,
} from 'store/dashboard/rightsManagement/supplierLinks';
import {
  getNomenclatureByLdap,
  useNomenclatureLink,
} from 'store/dashboard/rightsManagement/nomenclatureLinks';
import { isEmpty } from 'lodash';
import Replacement from './partials/Replacement/Replacement';
import Rights from './partials/Rights/Rights';

const ProfileRightsEditor = () => {
  const dispatch = useAppDispatch();
  const { ldap } = useParams();

  const { roles, isLoading: isLoadingRole } = useRoles().data;
  const { data: supplierLink, isLoading: isLoadingSupplierLinks } = useSupplierLink().supplierLink;
  const { data: nomenclatureLink, isLoading: isLoadingNomenclatureLinks } =
    useNomenclatureLink().nomenclatureLink;
  const { isLoading: isLoadingReplacement } = useReplacementData().replacement;

  useMount(() => {
    if (roles.length === 0) dispatch(getRolesData());
    dispatch(getReplacement({ ldap }));
    dispatch(clearSuppliersState({ isClearRole: false }));
    dispatch(clearNomenclatureStore());
    dispatch(getSupplierLinkData({ ldap: ldap as string }));
    dispatch(getNomenclatureByLdap({ ldap: ldap as string }));
  });
  return (
    <Flex justifyContent="space-between" direction="row" paddingTop="mu200" paddingBottom="mu150">
      {isLoadingRole ||
      !roles.length ||
      (isLoadingNomenclatureLinks && isEmpty(nomenclatureLink)) ||
      (isLoadingSupplierLinks && !supplierLink.length) ? (
        <Loader />
      ) : (
        <Rights />
      )}
      {isLoadingRole || isLoadingReplacement ? <Loader /> : <Replacement />}
    </Flex>
  );
};
export default ProfileRightsEditor;
