import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CheckPossibilityCreationPricelist } from '@ppm/ppm-platform-api';
import { checkPossibilityCreationPricelist } from 'api/pricelist';
import { setFailure, setLoading } from '../../helpers';
import { RequiredStateFields, RootState } from '../../types';
import { useAppSelector } from '../../hooks';

interface SliceState extends RequiredStateFields {
  error: CheckPossibilityCreationPricelist.Response['error'];
}

export const getPossibilityCreationPriceList = createAsyncThunk(
  'possibilityToCreation/getStartDateValidation',
  async (id: string, { rejectWithValue }) => {
    try {
      return await checkPossibilityCreationPricelist({ urlParameters: [id] });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const possibilityCreationSlice = createSlice({
  initialState: { error: null, isLoading: false, isFailure: false },
  name: 'possibilityToCreation',
  reducers: {
    resetPossibilityCreationPriceListStore(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPossibilityCreationPriceList.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getPossibilityCreationPriceList.fulfilled,
        (state: SliceState, action: PayloadAction<CheckPossibilityCreationPricelist.Response>) => {
          state.error = action.payload.error;
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getPossibilityCreationPriceList.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const { resetPossibilityCreationPriceListStore } = possibilityCreationSlice.actions;

export const usePossibilityCreationPriceList = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.priceList.possibility.creation) };
};
