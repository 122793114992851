import { getRequest, useRequestData } from 'store/request/request';
import { useController, useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { updateSubRequestsContainer } from 'api/request/subRequests';
import { useAppDispatch } from 'store/hooks';
import { ApplyDate, SigningDate, Dates } from './types';

export const useSubRequestDateForm = (id: string, filedName: SigningDate | ApplyDate) => {
  const dispatch = useAppDispatch();
  const { selectedContainers } = useRequestData().data;
  const { control, formState, setValue } = useForm<Record<Dates, string>>({
    criteriaMode: 'all',
    mode: 'onChange',
    defaultValues: {
      [filedName]: selectedContainers[0][filedName] || '',
    },
  });
  const { field } = useController({ name: filedName, control });

  const handleUpdate = useCallback(async () => {
    if (formState.isDirty) {
      await updateSubRequestsContainer({
        urlParameters: [id, selectedContainers[0].id],
        body: { dto: { [filedName]: field.value }, containerId: selectedContainers[0].id },
      }).then(() => dispatch(getRequest(id)));
    }
  }, [formState.isDirty, id, selectedContainers, filedName, field.value, dispatch]);
  return { field, handleUpdate, setValue };
};
