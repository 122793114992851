import { getCoords } from 'common/helpers';
import { Coords } from 'common/types';
import { useLayoutEffect, useState } from 'react';

interface IUseCoords {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  controlRef: React.RefObject<HTMLDivElement>;
}

const useCoords = ({ isOpen, setIsOpen, controlRef }: IUseCoords) => {
  const [coords, setCoords] = useState<Coords | null>(null);

  useLayoutEffect(() => {
    if (!isOpen) return undefined;

    const closeMenu = () => {
      setCoords(null);
      setIsOpen(false);
    };

    const newCoords = getCoords(controlRef.current);

    if (newCoords && newCoords.left + newCoords.width >= document.body.clientWidth) {
      newCoords.left = document.body.clientWidth - newCoords.width;
    }

    setCoords(newCoords);
    const scrollableTableElement = document.getElementsByClassName('mc-datatable__main')[0];
    const scrollableLayerElement = document.getElementsByClassName('mc-layer__content')[0];
    scrollableTableElement.addEventListener('scroll', closeMenu);
    if (scrollableLayerElement) {
      scrollableLayerElement.addEventListener('scroll', closeMenu);
    }

    return () => {
      scrollableTableElement.removeEventListener('scroll', closeMenu);
      if (scrollableLayerElement) {
        scrollableLayerElement.removeEventListener('scroll', closeMenu);
      }
    };
  }, [controlRef, isOpen, setIsOpen]);

  return { coords };
};

export default useCoords;
