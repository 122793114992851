import { useController, useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { Translations, useAppTranslations } from 'translations';
import {
  clearPurchasePriceDeletedFilters,
  setPurchasePriceFilters,
} from 'store/cbd/cbdAnalysis/compare/purchasePrice/filters';
import { ITraceableComponent } from 'api/cbd/cbdAnalysis';

export type PurchasePriceFilterName = 'withCurrencies' | 'traceableComponent';

const getLabel = (key: PurchasePriceFilterName, t: Translations) => {
  return {
    withCurrencies: t('withCurrencies'),
    traceableComponent: t('traceableComponent'),
  }[key];
};

export const usePurchasePriceFiltersForm = () => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const defaultValues = {
    traceableComponent: [{ categoryId: '', typeId: '', subtypeId: '' }],
    withCurrencies: false,
  };

  const form = useForm({
    defaultValues,
    mode: 'onChange',
  });

  const { control, handleSubmit } = form;

  const { field: withCurrencies } = useController({ name: 'withCurrencies', control });

  const onSubmit = useCallback(() => {
    handleSubmit(({ traceableComponent, ...data }) => {
      const mappedData = {
        ...data,
        traceableComponent: traceableComponent.reduce(
          (previous: ITraceableComponent[], current) => {
            const traceableValues: ITraceableComponent = Object.keys(current).reduce(
              (prev, curr) => {
                if (current[curr as keyof ITraceableComponent]) {
                  return {
                    ...prev,
                    [curr]: current[curr as keyof ITraceableComponent],
                  };
                }

                return prev;
              },
              {}
            );

            if (traceableValues.categoryId || traceableValues.subtypeId || traceableValues.typeId) {
              return [...previous, traceableValues];
            }

            return previous;
          },
          []
        ),
      };

      const keys = Object.keys(mappedData);

      dispatch(clearPurchasePriceDeletedFilters());
      dispatch(
        setPurchasePriceFilters(
          keys.map((key) => ({
            id: key,
            value: mappedData[key as PurchasePriceFilterName],
            label: getLabel(key as PurchasePriceFilterName, translations),
          }))
        )
      );
    })();
  }, [dispatch, handleSubmit, translations]);

  form.watch();

  return {
    form,
    withCurrencies,
    onSubmit,
  };
};
