import React, { FC, useEffect } from 'react';
import CbdView, { ICbdView } from 'pages/cbd/hok/CbdView';
import { useAppDispatch } from 'store/hooks';
import { setSelectedRowsId } from 'store/cbd/compare/list';
import { IFilter } from 'common/types';
import { IExternalData } from 'api/cbd/externalSource';
import { clearChartData, getExtChartData } from 'store/cbd/externalData/analysis/chartData';
import { extDataNamesList } from 'store/cbd/extDataNames';
import { getScheduler } from 'store/cbd/scheduler/scheduler';
import {
  clearExternalState,
  getExternalSource,
  useExternalSourceTableData,
} from 'store/cbd/externalData/analysis/tableData';
import { useNotifications } from '@mozaic-ds/react';
import { useAppTranslations } from 'translations';
import {
  changeExternalSourceDeletedFilters,
  clearExternalSourceDeletedFilters,
  setExternalSourceFilters,
  useExternalSourceFiltersData,
} from 'store/cbd/externalData/analysis/filters';
import { getCostType } from 'store/cbd/costTypes';
import { AxiosError } from 'axios';
import { IArrayError } from 'components/AxiosInterceptors/AxiosInterceptors';
import { getAveragePrices, resetAveragePricesStoreData } from 'store/cbd/averagePrices/list';
import { getMeasureUnitsList } from 'store/cbd/measureUnits';
import { ExternalSourceColumn, useColumnsData } from 'store/cbd/externalData/analysis/columns';
import { useGetColumns, sortConfig, useColumnsForm } from './hooks/columns';
import { ExternalSourceColumns, externalSourceFiltersName } from './hooks/consts';
import { ExtendsFilters } from '../hok/partials/consts';

const ExternalSource: FC = () => {
  const translations = useAppTranslations();
  const dispatch = useAppDispatch();
  const { danger } = useNotifications();
  const { columns } = useGetColumns();
  const { filters } = useExternalSourceFiltersData().data;
  const { columns: columnsFilter } = useColumnsData().data;
  const { form, saveColumnsFilter, resetColumnsFilters } = useColumnsForm();
  const { data, isLoading, pageable, selectedRowsId } = useExternalSourceTableData();

  useEffect(() => {
    dispatch(extDataNamesList());
    dispatch(getScheduler());
    dispatch(getCostType());
    dispatch(getMeasureUnitsList());

    return () => {
      dispatch(clearExternalSourceDeletedFilters());
    };
  }, [dispatch]);

  const config: ICbdView<
    IExternalData,
    ExternalSourceColumns,
    ExternalSourceColumn,
    IExternalData
  > = {
    isLoading,
    externalSourceFiltersVisible: true,
    pageFilters: filters,
    onRemoveTag: (idForDelete: string[], filtersForDelete: IFilter<ExtendsFilters>[]) => {
      dispatch(changeExternalSourceDeletedFilters(idForDelete));
      dispatch(setExternalSourceFilters(filtersForDelete));
    },
    resetFilters: () => {
      dispatch(changeExternalSourceDeletedFilters(externalSourceFiltersName));
      dispatch(setExternalSourceFilters([]));
    },
    data: {
      data,
      getData: async ({ page, size, ...params }) => {
        const paramsForRequest = {
          page,
          size,
          ...params,
        };

        if (Object.keys(params).length) {
          const response = await Promise.all([
            dispatch(getExternalSource(paramsForRequest)),
            dispatch(getExtChartData(paramsForRequest)),
            dispatch(getAveragePrices(params)),
          ]);

          const error = response.find((item) => item.meta.requestStatus === 'rejected');

          if (error) {
            danger({
              title: translations('dangerNotification'),
              message: (error.payload as AxiosError<IArrayError>).response?.data.errors[0].message,
            });
          }
        } else {
          dispatch(clearExternalState());
          dispatch(clearChartData());
          dispatch(resetAveragePricesStoreData());
        }
      },
      pageable,
    },
    columnsPopup: { columns, columnsFilter, form, saveColumnsFilter, resetColumnsFilters },
    table: {
      sortConfig,
      columns,
      columnsFilter,
      selectedRowsId,
      emptyDataText: 'toDisplayDataFillRequiredFilters',
      setSelectedRowsId: (selected: string[]) => {
        dispatch(setSelectedRowsId(selected));
      },
    },
  };

  return (
    <CbdView<IExternalData, ExternalSourceColumns, ExternalSourceColumn, IExternalData>
      {...config}
    />
  );
};

export default ExternalSource;
