import {
  ListBox,
  ListBoxItem,
  TTextInputSize,
  TextInput,
  useListBoxValues,
} from '@mozaic-ds/react';
import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import useOutsideAlerter from 'hook/useOutsideAlerter';
import { useAppTranslations } from 'translations';
import { StringSelectItem } from 'common/types';
import useCoords from 'hook/useCoords';
import useSearchInput from './useSearchInput';
import styles from './SearchInput.module.scss';

interface ISearchInput {
  value: string;
  onChange: (e: string) => void;
  isDisabled?: boolean;
  options: StringSelectItem[];
  className?: string;
  size?: TTextInputSize;
  isValid?: boolean;
}
const SearchInput: React.FC<ISearchInput> = ({
  value,
  onChange,
  isDisabled,
  options,
  className,
  size = 'm',
  isValid,
}) => {
  const translate = useAppTranslations();
  const [isOpen, setIsOpen] = useState(false);
  const [values] = useListBoxValues();
  const controlRef = useRef<HTMLDivElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { coords } = useCoords({
    isOpen,
    setIsOpen,
    controlRef,
  });

  const { handleFilterValueOnChange, handleClick } = useSearchInput({
    onChange,
    setIsOpen,
  });

  useOutsideAlerter(componentRef, () => {
    setIsOpen(false);
  });

  return (
    <div ref={componentRef} className={classNames(className, styles.wrapper)}>
      <div ref={controlRef}>
        <TextInput
          ref={inputRef}
          size={size}
          value={value}
          isValid={
            isValid === undefined || inputRef.current === document.activeElement
              ? undefined
              : isValid
          }
          isInvalid={
            isValid === undefined || inputRef.current === document.activeElement
              ? undefined
              : !isValid
          }
          onChange={(e) => handleFilterValueOnChange(e.target.value)}
          isDisabled={isDisabled}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(true);
          }}
        />
      </div>
      {isOpen && (
        <div
          style={{
            top: coords?.top,
            width: coords?.width,
          }}
          className={classNames(styles.listWrapper, 'mu-mt-050')}
        >
          <ListBox values={values} onClick={(e) => handleClick(e)}>
            {!options.length ? (
              <ListBoxItem className={styles.listBoxItem} value="notFound" isDisabled>
                {translate('notFound')}
              </ListBoxItem>
            ) : (
              <>
                {[{ value: undefined, label: '-- --' }, ...options].map((item) => (
                  <ListBoxItem
                    className={styles.listBoxItem}
                    key={item.value}
                    value={item.value?.toString() || undefined}
                  >
                    {item.label}
                  </ListBoxItem>
                ))}
              </>
            )}
          </ListBox>
        </div>
      )}
    </div>
  );
};

export default SearchInput;
