import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { NomenclatureState } from 'common/cbd/types';
import { useSuppliers } from 'store/cbd/suppliers';
import { initialNomenclature } from 'common/consts';

const useDefaultFormValue = (): Omit<NomenclatureState, 'comment'> => {
  const { suppliers } = useSuppliers();

  return useMemo(() => {
    if (!suppliers.length) {
      return initialNomenclature;
    }

    const [{ deptId }] = suppliers;

    return {
      ...initialNomenclature,
      dep: deptId.toString(),
    };
  }, [suppliers]);
};

export const useEditFormNomenclature = () => {
  const defaultValues = useDefaultFormValue();

  return useForm<NomenclatureState>({
    criteriaMode: 'all',
    mode: 'onChange',
    defaultValues,
  });
};
