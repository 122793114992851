import { useCallback } from 'react';
import { useAppTranslations } from 'translations';
import { createColumns } from 'common/utils';
import { useAppDispatch } from 'store/hooks';

import { useForm } from 'react-hook-form';
import { IDataTableColumn } from 'common/types';
import { ICurrencySubscription } from 'api/cbd/scheduler';
import { setVisibleAlertCurrencyColumns } from 'store/cbd/scheduler/currencySubscriptions/columns';
import { AlertCurrencyColumns } from './consts';
import { useGetColumnTemplate } from './helpers';

export const useGetColumns = (): {
  columns: Record<AlertCurrencyColumns, IDataTableColumn<ICurrencySubscription>>;
} => {
  const translations = useAppTranslations();

  const columnTemplate = useGetColumnTemplate();

  return {
    columns: createColumns<AlertCurrencyColumns, ICurrencySubscription, null>({
      columnTemplate,
      translations,
    }),
  };
};

export const useColumnsForm = () => {
  const dispatch = useAppDispatch();

  const form = useForm({
    defaultValues: {
      login: true,
      dateStart: true,
      dateFinish: true,
      currency1: true,
      currency2: true,
      subscriptionType: true,
      value: true,
      term: true,
    },
  });

  const saveColumnsFilter = useCallback(() => {
    form.handleSubmit((data) => {
      dispatch(setVisibleAlertCurrencyColumns(data));
    })();
  }, [dispatch, form]);

  const resetColumnsFilters = () => {
    dispatch(setVisibleAlertCurrencyColumns(form.getValues()));
    form.reset();
  };

  return { form, saveColumnsFilter, resetColumnsFilters };
};
