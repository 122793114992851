import React, { FC } from 'react';
import { PageSelector } from 'components/Paging/partial/PageSelector';
import { RowSelector } from 'components/Paging/partial/RowSelector';
import { PagingContextProvider } from 'components/Paging/partial/PagingContext';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

export type PagingProps = {
  itemsCount: number;
  page: number;
  setPage: ActionCreatorWithPayload<number>;
  limit: number;
  setLimit: ActionCreatorWithPayload<number>;
};

export const Paging: FC<PagingProps> = ({ itemsCount, limit, page, setLimit, setPage }) => {
  return (
    <PagingContextProvider
      itemsCount={itemsCount}
      limit={limit}
      page={page}
      setPage={setPage}
      setLimit={setLimit}
    >
      <RowSelector />
      <PageSelector />
    </PagingContextProvider>
  );
};
