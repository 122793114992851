import React, { FC, useState } from 'react';
import { Flex, Text, View } from '@mozaic-ds/react';
import { ArrowArrowBottom24, ArrowArrowTop24 } from '@mozaic-ds/icons/react';
import styles from './CustomAccordion.module.scss';

type Props = {
  header: string;
  isOpen?: boolean;
};

export const CustomAccordion: FC<Props> = ({ header, children, isOpen = false }) => {
  const [isShown, setIsShown] = useState(isOpen);
  return (
    <View className={styles.main} shadow="s" radius="s">
      <Flex
        onClick={() => setIsShown(!isShown)}
        justifyContent="space-between"
        alignContent="center"
        paddingLeft="mu200"
        paddingRight="mu100"
        paddingBottom="mu125"
        paddingTop="mu125"
      >
        <Text>{header}</Text>
        {isShown ? (
          <ArrowArrowTop24 className={styles.icon} />
        ) : (
          <ArrowArrowBottom24 className={styles.icon} />
        )}
      </Flex>
      {isShown && <View className={styles.separationChildren}>{children}</View>}
    </View>
  );
};
