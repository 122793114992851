import { useMemo } from 'react';
import { useComparePurchasePriceData } from 'store/cbd/cbdAnalysis/compare/purchasePrice/purchasePrice';
import { ChartType } from 'chart.js';
import { ChartProps } from 'react-chartjs-2';
import { useChartConfig } from 'components/Chart/hooks/useChartConfig';
import { useAppTranslations } from 'translations';
import { priceChartLabels } from './consts';
import { IChartData, getChartDataForConfig } from './helpers';

const usePurchasePriceChartData = () => {
  const translations = useAppTranslations();
  const { data: purchasePriceData } = useComparePurchasePriceData();

  const purchasePriceChartData: IChartData = useMemo(
    () =>
      getChartDataForConfig({
        data: purchasePriceData,
        datasetLabels: priceChartLabels,
        getValueCallBack: () => [
          purchasePriceData.data?.map((item) => item.value) || [],
          purchasePriceData.simulationData?.map((item) => item.value) || [],
        ],
      }),
    [purchasePriceData]
  );

  const purchasePriceChartConfig: ChartProps<ChartType> = useChartConfig({
    type: 'line',
    legendPosition: 'bottom',
    axisTitle: [translations('cbdNumber'), translations('cost')],
    chartData: purchasePriceChartData,
    needToShowLabels: false,
    needToShowLegend: false,
  });

  return {
    purchasePriceChartConfig,
  };
};

export default usePurchasePriceChartData;
