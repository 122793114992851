import { RequestModelView, SubrequestContainerWithRights } from '@ppm/ppm-platform-api';
import { RequestFlowRights, SubrequestFlowRights } from '@ppm/ppm-platform-request-service';
import { getOtherSelectValue } from 'store/dictionary/reasons';
import { Translations } from 'translations';
import { SelectItem } from 'common/types';
import { isEmpty, mapValues } from 'lodash';
import { UserGrants } from '@ppm/ppm-platform-user-service';
import { CreateFormFields } from 'pages/CreatingRequest/types';
import { parseDateForMozaicComponent } from 'common/utils';
import { RequestFormFields } from './types';

export const formatDirtyFieldToStore = (
  formValueForBody: RequestFormFields,
  defaultValuesForComparing: RequestFormFields,
  t: Translations
) => {
  const updatedKey = (Object.keys(formValueForBody) as (keyof RequestFormFields)[]).filter(
    (key) =>
      JSON.stringify(formValueForBody[key]) !== JSON.stringify(defaultValuesForComparing[key])
  );

  return updatedKey.reduce((fields: Record<string, unknown>, fieldName) => {
    fields[fieldName] = formValueForBody[fieldName];

    if (fieldName === 'reasonListId') {
      const reasons = formValueForBody.reasonListId;
      const reasonsWithoutOther = reasons
        .map(({ value }) => Number(value))
        .filter((item) => item !== getOtherSelectValue(t).value);
      const isOnlyOther = reasons.length === 1 && reasons.includes(getOtherSelectValue(t));
      fields[fieldName] = isOnlyOther ? null : reasonsWithoutOther;
    }

    if (fieldName === 'customReason') {
      const customReasonValue = formValueForBody.customReason;
      fields[fieldName] = customReasonValue?.length === 0 ? null : customReasonValue;
    }

    if (fieldName === 'dueDate') {
      fields[fieldName] = new Date(formValueForBody.dueDate);
    }

    return fields;
  }, {});
};

export const createDefaultValue = (
  requestData: RequestModelView,
  reasons: SelectItem[],
  t: Translations
): CreateFormFields => ({
  customReason: requestData.customReason || '',
  reasonListId: requestData.reasonListId?.length
    ? reasons
        .filter(({ value }) => (requestData.reasonListId || []).find((item) => item === value))
        .concat(requestData.customReason ? [getOtherSelectValue(t)] : [])
    : [getOtherSelectValue(t)],
  dueDate: parseDateForMozaicComponent(new Date(requestData.dueDate)),
  supplierId: {
    value: requestData.supplierId,
    label: requestData.supplierName ? `${requestData.supplierName}  ${requestData.supplierId}` : '',
  },
});

export const calculatePermissions = <
  T extends SubrequestContainerWithRights,
  K extends keyof SubrequestFlowRights
>(
  data: T[],
  permissionKey: K
): K extends 'grants' ? UserGrants : RequestFlowRights['transitions'] => {
  return data.reduce((acc, curr) => {
    if (isEmpty(acc) || JSON.stringify(curr.flowRights[permissionKey]) === JSON.stringify(acc))
      return curr.flowRights[permissionKey];
    // eslint-disable-next-line eslint-comments/disable-enable-pair
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    return mapValues(acc, (value: any, key: any) => {
      if (value.isValid) return (curr.flowRights[permissionKey] as any)[key];

      return value;
    });
  }, {});
};

export const parseRequestErrorMessage = (message: string | null): string[] => {
  if (!message) return [];

  return message.split('\n');
};
