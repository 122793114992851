import React, { useEffect } from 'react';
import { Flex, View, Text } from '@mozaic-ds/react';
import { useAppTranslations } from 'translations';
import { useAppDispatch } from 'store/hooks';
import { getSuppliersData } from 'store/cbd/suppliers';
import { useCbdInfo } from 'store/cbd/info';
import classNames from 'classnames';
import styles from './InfoBlock.module.scss';
import useInfoConfig from './useInfoConfig';

const InfoBlock = () => {
  const translations = useAppTranslations();
  const dispatch = useAppDispatch();
  const { data } = useCbdInfo();
  const { infoConfig } = useInfoConfig();

  useEffect(() => {
    if (data) {
      dispatch(getSuppliersData(data.supplierId));
    }
  }, [data, dispatch]);

  return (
    <View
      paddingTop="mu150"
      paddingBottom="mu150"
      paddingLeft="mu200"
      paddingRight="mu200"
      marginBottom="mu150"
      radius="s"
      shadow="m"
      className={styles.infoBlock}
    >
      <Flex marginBottom="mu100" justifyContent="space-between">
        <Flex
          className={styles.columnsBlock}
          wrap="wrap"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          {infoConfig.map((item) => (
            <Flex direction="column" className={classNames(styles.column, 'mu-mb-100')}>
              <Text className={classNames(styles.headText, 'mu-mb-025')}>{item.header}</Text>
              <Text weight="semi-bold" className={styles.contentText}>
                {item.text}
              </Text>
            </Flex>
          ))}
          <Flex direction="column" className={classNames(styles.column, 'mu-mb-100')} />
        </Flex>
        <Flex className={styles.curencyBlock} wrap="wrap">
          {data?.currency.length === 0 && translations('currenciesNotFound')}
          {data?.currency.map((curr) => {
            return (
              <React.Fragment key={curr.currencyId}>
                <Flex direction="column" marginBottom="mu100" className={styles.curency}>
                  <Text className={classNames(styles.headText, 'mu-mb-025')}>
                    {translations('currency')}
                  </Text>
                  <Text weight="semi-bold" className={styles.contentText}>
                    {curr.currencyId}
                  </Text>
                </Flex>
                <Flex direction="column" marginBottom="mu100" className={styles.price}>
                  <Text className={classNames(styles.headText, 'mu-mb-025')}>
                    {translations('exchangeRate')}
                  </Text>
                  <Text weight="semi-bold" className={styles.contentText}>
                    {curr.rate}
                  </Text>
                </Flex>
              </React.Fragment>
            );
          })}
        </Flex>
      </Flex>
    </View>
  );
};

export default InfoBlock;
