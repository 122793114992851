import React, { FC, useEffect } from 'react';
import CbdView, { ICbdView } from 'pages/cbd/hok/CbdView';
import { useAppDispatch } from 'store/hooks';
import {
  getCbdListWithItemGroupsData,
  setItemsForCompare,
  setSelectedRowsId,
  useCbdWithItemGroupsList,
} from 'store/cbd/compare/list';
import { ICbdWithArrayItemGroups, IGetCbdListPayload } from 'common/cbd/types';
import { IFilter } from 'common/types';
import { View } from '@mozaic-ds/react';
import { CompareDataColumn, useColumnsData } from 'store/cbd/compare/columns';
import {
  changeCompareDeletedFilters,
  setCompareFilters,
  useCompareFiltersData,
} from 'store/cbd/compare/filters';
import { useGetColumns, sortConfig } from './hooks/columns';
import { CompareColumns, compareFiltersName } from './hooks/consts';
import { ICompareTable } from './CompareTable.types';
import { ExtendsFilters } from '../hok/partials/consts';

const CompareTable: FC = () => {
  const dispatch = useAppDispatch();
  const { filters } = useCompareFiltersData().data;
  const { columns } = useGetColumns();
  const { columns: columnsFilter, sortedField } = useColumnsData().data;
  const { data, isLoading, pageable, selectedRowsId } = useCbdWithItemGroupsList();

  const config: ICbdView<
    ICbdWithArrayItemGroups,
    CompareColumns,
    CompareDataColumn,
    ICompareTable
  > = {
    needToWrap: false,
    compareFilterVisible: true,
    isLoading,
    pageFilters: filters,
    onRemoveTag: (idForDelete: string[], filtersForDelete: IFilter<ExtendsFilters>[]) => {
      dispatch(changeCompareDeletedFilters(idForDelete));
      dispatch(setCompareFilters(filtersForDelete));
    },
    resetFilters: () => {
      dispatch(changeCompareDeletedFilters(compareFiltersName));
      dispatch(setCompareFilters([]));
    },
    data: {
      data,
      getData: (params: IGetCbdListPayload) => {
        dispatch(getCbdListWithItemGroupsData(params));
      },
      pageable,
    },
    table: {
      sortConfig,
      columns,
      columnsFilter,
      sortedField,
      selectedRowsId,
      withCheckboxes: true,
      setSelectedRowsId: (selected: string[]) => {
        dispatch(setSelectedRowsId(selected));
      },
    },
  };

  useEffect(() => {
    return () => {
      dispatch(setItemsForCompare([]));
    };
  }, [dispatch]);

  return (
    <View marginBottom="mu150">
      <CbdView<ICbdWithArrayItemGroups, CompareColumns, CompareDataColumn, ICompareTable>
        {...config}
      />
    </View>
  );
};

export default CompareTable;
