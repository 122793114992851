import React, { forwardRef } from 'react';
import cn from 'classnames';
import { IFileUploaderProps } from './types';
import styles from './FileUploader.module.scss';
import {
  FileUploaderContainer,
  FileUploaderIndicator,
  FileUploaderLabel,
  FileUploaderList,
} from './partials';

const FileUploader = forwardRef<HTMLInputElement, IFileUploaderProps>(
  (
    {
      className,
      onDeleteFile,
      files,
      children,
      isScrollable,
      onDownloadFile,
      hasWrapper,
      ...props
    },
    ref
  ) => (
    <FileUploaderContainer className={cn(styles.uploader, className)}>
      <FileUploaderIndicator {...props} ref={ref} />
      <FileUploaderLabel id={props.id}>{children}</FileUploaderLabel>
      {Boolean(files.length) && (
        <FileUploaderList
          files={files}
          isScrollable={isScrollable}
          hasWrapper={hasWrapper}
          onDeleteFile={onDeleteFile}
          onDownloadFile={onDownloadFile}
        />
      )}
    </FileUploaderContainer>
  )
);

export default FileUploader;
