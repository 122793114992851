import React from 'react';
import { Flex } from '@mozaic-ds/react';
import { useMount } from 'hook/useMount';
import { useAppDispatch } from 'store/hooks';
import { getLoadErrorCodes } from 'store/cbd/loadErrorCodes';
import EditTemplateForm from './components/EditTemplateForm/EditTemplateForm';
import ParseTable from './components/ParseTable/ParseTable';
import styles from './EditTemplate.module.scss';

const EditTemplate = () => {
  const dispatch = useAppDispatch();

  useMount(() => {
    dispatch(getLoadErrorCodes());
  });

  return (
    <Flex marginTop="mu150" className={styles.wrapper} direction="column">
      <EditTemplateForm />
      <ParseTable />
    </Flex>
  );
};

export default EditTemplate;
