import { cbdApiClient } from 'api';
import { AxiosResponse } from 'axios';
import { ICbdAnalysisInfo, IChartsExtends, ICostGroups, IPurchasePrice } from 'common/cbd/types';

export interface IGetCbdAnalysisPayload {
  id: string;
  costStructure: string;
}

export interface IGetComparePurchasePayload {
  cbdIds?: string[];
  costStructure?: string;
  traceableComponent?: ITraceableComponent[];
  withCurrencies?: boolean;
}

export interface ITraceableComponent {
  categoryId?: string;
  typeId?: string;
  subtypeId?: string;
}

export interface IGetCompareCommonPayload {
  cbdIds?: string[];
  costStructure?: string;
  traceableComponent?: ITraceableComponent;
}

export const getCbdAnalysisInfoReq = async (payload: IGetCbdAnalysisPayload) => {
  const { id, ...params } = payload;
  const { data }: AxiosResponse<ICbdAnalysisInfo> = await cbdApiClient.get(
    `/api/cbd-analysis/${id}/cbd-info`,
    {
      params,
    }
  );
  return data;
};

export const getCompareCommonReq = async (params: IGetCompareCommonPayload) => {
  const { data }: AxiosResponse<IChartsExtends[]> = await cbdApiClient.get(
    `/api/cbd-analysis/charts/common`,
    {
      params,
    }
  );
  return data;
};

export const getComparePurchasePriceReq = async (params: IGetComparePurchasePayload) => {
  const { data }: AxiosResponse<IPurchasePrice> = await cbdApiClient.post(
    `/api/cbd-analysis/charts/purchase-price`,
    params
  );
  return data;
};

export const getCompareComponentsCostReq = async (params: IGetCompareCommonPayload) => {
  const { data }: AxiosResponse<IPurchasePrice> = await cbdApiClient.post(
    `/api/cbd-analysis/charts/component-cost`,
    params
  );
  return data;
};

export const getCostGroupsReq = async (params: IGetCompareCommonPayload) => {
  const { data }: AxiosResponse<ICostGroups> = await cbdApiClient.get(
    `/api/cbd-analysis/charts/cost-groups`,
    {
      params: {
        ...params,
        traceableComponent: 1,
      },
    }
  );
  return data;
};
