import {
  Button,
  DateInput,
  Flex,
  IconButton,
  Select,
  Text,
  TextArea,
  TextInput,
  Toggle,
  useModals,
  View,
} from '@mozaic-ds/react';
import { PublishEdit24, ControlMore24 } from '@mozaic-ds/icons/react';
import { Dividers } from 'components/Dividers/Dividers';
import { Label } from 'components/Label/Label';
import { useAppTranslations } from 'translations';
import React from 'react';
import { useScheduler } from 'store/cbd/scheduler/scheduler';
import Modal from 'components/Modal/Modal';
import classNames from 'classnames';
import styles from './SchedulerForm.module.scss';
import { useSchedulerForm } from './hook/useSchedulerForm';
import {
  dayMonthOptions,
  destinationOptions,
  extDataTypeOptions,
  keyOptions,
  relationPeriodTypeOptions,
} from './consts';
import useSchedulerFormActions from './hook/useSchedulerFormActions';

const SchedulerForm = () => {
  const translations = useAppTranslations();
  const { data } = useScheduler();
  const {
    form,
    formState,
    nameInput,
    nameSelect,
    extDataType,
    source,
    destination,
    mapping,
    overwrite,
    apiPeriodType,
    relationPeriodType,
    overWriteKey,
    schedulerPeriodType,
    schedulerPeriod,
    schedulerTime,
    schedulerStart,
    schedulerFinish,
  } = useSchedulerForm();
  const { errors } = formState;
  const { isAdd, isEdit, onNameChange, onAddOrEditClick, onCancel, onManualScheduler, onSubmit } =
    useSchedulerFormActions({
      form,
      nameInput,
      nameSelect,
    });
  const { open, close } = useModals();

  return (
    <>
      <View
        paddingTop="mu150"
        paddingLeft="mu200"
        paddingRight="mu200"
        paddingBottom="mu200"
        marginBottom="mu150"
        className={styles.wrapper}
      >
        <Flex marginBottom="mu200">
          <Text weight="semi-bold">{translations('scheduler')}</Text>
        </Flex>
        <Flex wrap="wrap" justifyContent="space-between" marginBottom="mu200">
          <Flex alignItems="flex-end">
            <IconButton
              className={classNames(styles.icon, styles.field)}
              size="m"
              theme="primary"
              variant="bordered"
              onClick={() => onAddOrEditClick(false)}
              type="button"
            >
              <ControlMore24 />
            </IconButton>
            <Label
              className={styles.field}
              text={translations('schedulerName')}
              hasMarginTop={false}
            >
              {isAdd || isEdit ? (
                <TextInput {...nameInput} size="m" />
              ) : (
                <Select
                  {...nameSelect}
                  options={data.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  placeholder={translations('chooseAnOption')}
                  size="m"
                  onChange={(e) => onNameChange(e)}
                />
              )}
            </Label>
            <IconButton
              className={classNames(styles.icon, styles.field)}
              size="m"
              theme="primary"
              variant="bordered"
              onClick={() => onAddOrEditClick(true)}
              type="button"
            >
              <PublishEdit24 />
            </IconButton>
          </Flex>
          <Label text={translations('externalDataType')} className={styles.field}>
            <Select
              {...extDataType}
              isDisabled={!isAdd && !isEdit && !nameSelect.value}
              options={extDataTypeOptions.map((item) => ({
                ...item,
                label: translations(item.label),
              }))}
              placeholder={translations('chooseAnOption')}
              size="m"
            />
          </Label>
          <Label text={translations('recipient')} className={styles.field}>
            <Select
              {...destination}
              isDisabled={!isAdd && !isEdit && !nameSelect.value}
              placeholder={translations('chooseAnOption')}
              size="m"
              options={destinationOptions}
            />
          </Label>
          <Label text={translations('overwriteData')} className={styles.field}>
            <Toggle
              {...overwrite}
              value={overwrite.name}
              className="mu-mr-100"
              size="s"
              isChecked={overwrite.value}
            />
          </Label>
          <Label text={translations('key')} className={styles.field}>
            <Select
              {...overWriteKey}
              isDisabled={!isAdd && !isEdit && !nameSelect.value}
              placeholder={translations('chooseAnOption')}
              size="m"
              options={keyOptions[destination.value] || []}
            />
            {errors && errors.overWriteKey && (
              <Flex className={styles.errorWrapper}>
                <Text as="p" size="s" theme="danger" className="mu-mt-025">
                  {translations(errors.overWriteKey.message || '')}
                </Text>
              </Flex>
            )}
          </Label>
        </Flex>
        <Dividers className="mu-mb-100" />
        <Flex marginBottom="mu100">
          <Text weight="semi-bold">{translations('launch')}</Text>
        </Flex>
        <Flex wrap="wrap" justifyContent="space-between" marginBottom="mu200">
          <Flex wrap="wrap">
            <Label className={styles.field} text={translations('periodicity')}>
              <TextInput
                {...schedulerPeriod}
                isDisabled={!isAdd && !isEdit && !nameSelect.value}
                placeholder={translations('input')}
                size="m"
                type="number"
              />
            </Label>
            <Label text={translations('schedulerPeriodType')} className={styles.field}>
              <Select
                {...schedulerPeriodType}
                placeholder={translations('chooseAnOption')}
                options={dayMonthOptions.map((item) => ({
                  ...item,
                  label: translations(item.label),
                }))}
                isDisabled={!isAdd && !isEdit && !nameSelect.value}
                size="m"
              />
            </Label>
          </Flex>
          <Flex wrap="wrap">
            <Label className={styles.field} text={translations('launchDay')}>
              <DateInput
                {...schedulerStart}
                isDisabled={!isAdd && !isEdit && !nameSelect.value}
                size="m"
                max={schedulerFinish.value}
              />
            </Label>
            <Label className={styles.field} text={translations('launchTime')}>
              <TextInput
                {...schedulerTime}
                isDisabled={!isAdd && !isEdit && !nameSelect.value}
                type="time"
                size="m"
              />
            </Label>
            <Label text={translations('terminationDate')} className={styles.field}>
              <DateInput
                {...schedulerFinish}
                isDisabled={!isAdd && !isEdit && !nameSelect.value}
                size="m"
                min={schedulerStart.value}
              />
            </Label>
          </Flex>
        </Flex>
        <Dividers className="mu-mb-100" />
        <Flex marginBottom="mu100">
          <Text weight="semi-bold">API</Text>
        </Flex>
        <Flex wrap="wrap" justifyContent="space-between" marginBottom="mu200">
          <Label text={translations('apiDownloadPeriod')} className={styles.field}>
            <Select
              {...relationPeriodType}
              isDisabled={!isAdd && !isEdit && !nameSelect.value}
              options={relationPeriodTypeOptions.map((item) => ({
                ...item,
                label: translations(item.label),
              }))}
              size="m"
              placeholder={translations('chooseAnOption')}
            />
          </Label>
          <Label text={translations('apiDataPeriodType')} className={styles.field}>
            <Select
              {...apiPeriodType}
              placeholder={translations('chooseAnOption')}
              options={dayMonthOptions.map((item) => ({
                ...item,
                label: translations(item.label),
              }))}
              isDisabled={!isAdd && !isEdit && !nameSelect.value}
              size="m"
            />
          </Label>
          <Label
            className={classNames(styles.longField, styles.field)}
            text={translations('InformationFromApi')}
          >
            <TextInput
              {...source}
              isDisabled={!isAdd && !isEdit && !nameSelect.value}
              placeholder={translations('input')}
            />
          </Label>
        </Flex>
        <Dividers className="mu-mb-100" />
        <Flex wrap="wrap" justifyContent="space-between">
          <Flex>
            <Button
              isDisabled={!nameInput.value && !nameSelect.value}
              className="mu-mr-100"
              size="m"
              theme="primary"
              variant="solid"
              width="fit"
              onClick={() => open('mappingFieldModal')}
            >
              {translations('displaySetting')}
            </Button>
            <Button
              isDisabled={!nameSelect.value || isAdd || isEdit}
              size="m"
              theme="primary"
              variant="solid"
              width="fit"
              onClick={onManualScheduler}
            >
              {translations('runManually')}
            </Button>
          </Flex>
          <Flex>
            <Button
              isDisabled={!isAdd && !isEdit && !nameSelect.value}
              className="mu-mr-100"
              size="m"
              theme="primary"
              variant="solid"
              width="fit"
              onClick={onSubmit}
            >
              {translations('apply')}
            </Button>
            <Button
              isDisabled={!isAdd && !isEdit && !nameSelect.value}
              size="m"
              theme="primary"
              variant="bordered"
              width="fit"
              onClick={onCancel}
            >
              {translations('cancel')}
            </Button>
          </Flex>
        </Flex>
      </View>
      <Modal
        id="mappingFieldModal"
        title={translations('displaySetting')}
        okBtnText={translations('close')}
        text=""
        isLoading={false}
        handleOk={() => close('mappingFieldModal')}
      >
        <View>
          <TextArea {...mapping} />
        </View>
      </Modal>
    </>
  );
};

export default SchedulerForm;
