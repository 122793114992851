const FILE_UPLOADED_ACTION = 'file_updated';
const CONTAINER_UPLOADED_ACTION = 'container_updated';
const REQUEST_UPLOADED_ACTION = 'request_updated';
const MODAL_RATE_POPUP_OPENED_ACTIONS = 'new_quiz_request';
const PRICELIST_MODAL_RATE_POPUP_OPENED_ACTIONS = 'new_quiz_pricelist';

export {
  FILE_UPLOADED_ACTION,
  CONTAINER_UPLOADED_ACTION,
  REQUEST_UPLOADED_ACTION,
  MODAL_RATE_POPUP_OPENED_ACTIONS,
  PRICELIST_MODAL_RATE_POPUP_OPENED_ACTIONS,
};
