import React, { ChangeEvent, useCallback, useState } from 'react';

export interface IUseInput {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

function useInput(initialValue: string): IUseInput {
  const [value, setValue] = useState(initialValue);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  return {
    value,
    setValue,
    onChange: handleChange,
  };
}

export default useInput;
