import React, { useCallback } from 'react';
import { CheckBox, IconButton, Select, TextInput } from '@mozaic-ds/react';
import { PublishEdit24 } from '@mozaic-ds/icons/react';
import { ICatalogCostTreeItem } from 'common/cbd/types';
import { useAppDispatch } from 'store/hooks';
import { IColumnTemplate } from 'common/utils';
import { isNull } from 'lodash';
import { ICreateAttribute } from 'api/cbd/templates';
import { useLoadErrorCodes } from 'store/cbd/loadErrorCodes';
import { useAppTranslations } from 'translations';
import { changeTableRow, useStructureList } from 'store/cbd/templateStructure/list';
import { TemplateStructureColumns } from './consts';

export type FnMappingEditClick = {
  value: string;
  title: string;
  callBack: (value: string) => void;
};

export type FnAttributesEditClick = {
  callBack: (value: string) => void;
  attributes: Array<ICreateAttribute>;
  parentId: number;
  parentCode: string;
};

export const useGetColumnTemplate = (
  onMappingEditClick: (arg: FnMappingEditClick) => void,
  onAttributesEditClick: (arg: FnAttributesEditClick) => void
): {
  [key in TemplateStructureColumns]: IColumnTemplate<ICatalogCostTreeItem>;
} => {
  const translations = useAppTranslations();
  const dispatch = useAppDispatch();
  const { editItems } = useStructureList();
  const { list } = useLoadErrorCodes();

  const onFieldChange = useCallback(
    (id: string, fieldName: keyof ICatalogCostTreeItem, value: string | boolean | number) => {
      dispatch(
        changeTableRow({
          id,
          fieldName,
          value,
        })
      );
    },
    [dispatch]
  );

  const isEditModeEnable = useCallback(
    (id: string) => {
      return editItems.includes(id.toString());
    },
    [editItems]
  );

  const columnTemplate = {
    [TemplateStructureColumns.CODE]: {
      message: 'catalogCostCode',
      isSortable: false,
      render: (record: ICatalogCostTreeItem) =>
        isEditModeEnable(record.id) ? (
          <TextInput
            size="s"
            value={record.code}
            onChange={(e) => onFieldChange(record.id, 'code', e.target.value)}
          />
        ) : (
          record.code
        ),
    },
    [TemplateStructureColumns.NAME]: {
      message: 'catalogCostName',
      isSortable: false,
      render: (record: ICatalogCostTreeItem) =>
        isEditModeEnable(record.id) ? (
          <TextInput
            size="s"
            value={record.name}
            onChange={(e) => onFieldChange(record.id, 'name', e.target.value)}
          />
        ) : (
          record.name
        ),
    },
    [TemplateStructureColumns.MAPPING_SUM]: {
      message: 'catalocCostMappingSum',
      isSortable: false,
      render: (row: ICatalogCostTreeItem) => (
        <IconButton
          isDisabled={!isEditModeEnable(row.id)}
          onClick={(e) => {
            e.stopPropagation();
            onMappingEditClick({
              title:
                typeof row.mappingSum === 'string'
                  ? row.mappingSum
                  : JSON.stringify(row.mappingSum),
              value: translations('editingAmountMapping'),
              callBack: (value) => onFieldChange(row.id, 'mappingSum', value),
            });
          }}
          size="s"
          theme="neutral"
          variant="bordered"
        >
          <PublishEdit24 />
        </IconButton>
      ),
    },
    [TemplateStructureColumns.MAPPING_CUR]: {
      message: 'catalogCostMappingCur',
      isSortable: false,
      render: (row: ICatalogCostTreeItem) => (
        <IconButton
          isDisabled={!isEditModeEnable(row.id)}
          onClick={(e) => {
            e.stopPropagation();
            onMappingEditClick({
              title:
                typeof row.mappingCur === 'string'
                  ? row.mappingCur
                  : JSON.stringify(row.mappingCur),
              value: translations('editingCurrencyMapping'),
              callBack: (value) => onFieldChange(row.id, 'mappingCur', value),
            });
          }}
          size="s"
          theme="neutral"
          variant="bordered"
        >
          <PublishEdit24 />
        </IconButton>
      ),
    },
    [TemplateStructureColumns.REQUIRED]: {
      message: 'catalogCostRequired',
      isSortable: false,
      render: (record: ICatalogCostTreeItem) => (
        <CheckBox
          isChecked={Boolean(record.required)}
          onChange={() => {
            if (isEditModeEnable(record.id)) {
              onFieldChange(record.id, 'required', !record.required);
            }
          }}
          onClick={(e) => e.stopPropagation()}
        />
      ),
    },
    [TemplateStructureColumns.UNIQUE]: {
      message: 'catalogCostUnique',
      isSortable: false,
      render: (record: ICatalogCostTreeItem) => (
        <CheckBox
          isChecked={Boolean(record.unique)}
          onChange={() => {
            if (isEditModeEnable(record.id)) {
              onFieldChange(record.id, 'unique', !record.unique);
            }
          }}
          onClick={(e) => e.stopPropagation()}
        />
      ),
    },
    [TemplateStructureColumns.ERR_REQUIRED_CUR_ID]: {
      message: 'catalogCostErrRequiredSumId',
      isSortable: false,
      render: (record: ICatalogCostTreeItem) =>
        isEditModeEnable(record.id) ? (
          <Select
            placeholder=" "
            size="s"
            value={isNull(record.errRequiredCurId) ? '' : record.errRequiredCurId}
            onChange={(e) => onFieldChange(record.id, 'errRequiredCurId', Number(e.target.value))}
            options={list}
          />
        ) : (
          record.errRequiredCurId
        ),
    },
    [TemplateStructureColumns.ERR_REQUIRED_SUM_ID]: {
      message: 'catalogCostErrRequiredCurId',
      isSortable: false,
      render: (record: ICatalogCostTreeItem) =>
        isEditModeEnable(record.id) ? (
          <Select
            placeholder=" "
            size="s"
            value={isNull(record.errRequiredSumId) ? '' : record.errRequiredSumId}
            onChange={(e) => onFieldChange(record.id, 'errRequiredSumId', Number(e.target.value))}
            options={list}
          />
        ) : (
          record.errRequiredSumId
        ),
    },
    [TemplateStructureColumns.ATTRIBUTES]: {
      message: 'attributes',
      isSortable: false,
      render: (record: ICatalogCostTreeItem) =>
        Array.isArray(record.attributes) ? (
          <IconButton
            isDisabled={!isEditModeEnable(record.id)}
            onClick={(e) => {
              e.stopPropagation();
              onAttributesEditClick({
                callBack: (value: string) => onFieldChange(record.id, 'mappingCur', value),
                attributes: record.attributes,
                parentId: Number(record.id),
                parentCode: record.code,
              });
            }}
            size="s"
            theme="neutral"
            variant="bordered"
          >
            <PublishEdit24 />
          </IconButton>
        ) : null,
    },
  };

  return columnTemplate;
};
