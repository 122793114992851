import { format } from 'date-fns';
import { DATE_FORMAT } from 'common/consts';
import { getOtherSelectValue } from 'store/dictionary/reasons';
import { CreateFormFields } from 'pages/CreatingRequest/types';
import { Translations } from 'translations';

export const formatCreatingData = (formData: CreateFormFields, t: Translations) => {
  return {
    ...formData,
    supplierId: Number(formData.supplierId.value),
    reasonListId: formData.reasonListId
      .filter(({ value }) => value !== getOtherSelectValue(t).value)
      .map(({ value }) => Number(value)),
    dueDate: format(new Date(formData.dueDate), DATE_FORMAT),
  };
};
