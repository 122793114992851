import React, { useCallback, useMemo } from 'react';
import { IColumnTemplate } from 'common/utils';
import { useAppDispatch } from 'store/hooks';
import {
  changeTableRow,
  useCurrencySubscriptionsList,
} from 'store/cbd/scheduler/currencySubscriptions/currencySubscriptionsList';
import { DateInput, Select, TextInput } from '@mozaic-ds/react';
import { useExtCurrency } from 'store/cbd/extCurrency';
import { useAppTranslations } from 'translations';
import { ICurrencySubscription } from 'api/cbd/scheduler';
import { AlertCurrencyColumns } from './consts';
import { subscriptionTypeOptions } from '../../SchedulerForm/consts';

export const useGetColumnTemplate = (): {
  [key in AlertCurrencyColumns]: IColumnTemplate<ICurrencySubscription>;
} => {
  const dispatch = useAppDispatch();
  const { editItems } = useCurrencySubscriptionsList();
  const { fromCurrenciesList, toCurrenciesList } = useExtCurrency();
  const translate = useAppTranslations();

  const isEditModeEnable = useCallback(
    (id: string) => {
      return editItems.includes(id);
    },
    [editItems]
  );

  const onFieldChange = useCallback(
    (id: number, fieldName: keyof ICurrencySubscription, value: string) => {
      dispatch(
        changeTableRow({
          id,
          fieldName,
          value,
        })
      );
    },
    [dispatch]
  );

  const columnTemplate = useMemo(
    () => ({
      [AlertCurrencyColumns.LOGIN]: {
        message: 'LDAP',
        isSortable: false,
        render: (record: ICurrencySubscription) => {
          if (isEditModeEnable(record.id.toString())) {
            return (
              <TextInput
                size="s"
                value={record.login || ''}
                onChange={(e) => onFieldChange(record.id, 'login', e.target.value)}
              />
            );
          }

          return record.login;
        },
      },
      [AlertCurrencyColumns.DATE_START]: {
        message: 'subscriptionStartDate',
        isSortable: false,
        render: (record: ICurrencySubscription) => {
          if (isEditModeEnable(record.id.toString())) {
            const dateStringValue = record.dateStart
              ? new Date(record.dateStart).toLocaleDateString('fr-CA')
              : '';

            return (
              <DateInput
                size="s"
                max={record.dateFinish}
                value={dateStringValue}
                onChange={(e) => onFieldChange(record.id, 'dateStart', e.target.value)}
              />
            );
          }

          return record.dateStart ? new Date(record.dateStart).toLocaleDateString('ru-RU') : '';
        },
      },
      [AlertCurrencyColumns.DATE_FINISH]: {
        message: 'subscriptionEndDate',
        isSortable: false,
        render: (record: ICurrencySubscription) => {
          if (isEditModeEnable(record.id.toString())) {
            const dateStringValue = record.dateFinish
              ? new Date(record.dateFinish).toLocaleDateString('fr-CA')
              : '';

            return (
              <DateInput
                size="s"
                min={record.dateStart}
                value={dateStringValue}
                onChange={(e) => onFieldChange(record.id, 'dateFinish', e.target.value)}
              />
            );
          }

          return record.dateFinish ? new Date(record.dateFinish).toLocaleDateString('ru-RU') : '';
        },
      },
      [AlertCurrencyColumns.CURRENCY1]: {
        message: `${translate('currency')} 1`,
        isSortable: false,
        render: (record: ICurrencySubscription) => {
          if (isEditModeEnable(record.id.toString())) {
            return (
              <Select
                size="s"
                value={record.currency1}
                onChange={(e) => onFieldChange(record.id, 'currency1', e.target.value)}
                options={fromCurrenciesList}
                placeholder=" "
              />
            );
          }

          return record.currency1.toUpperCase();
        },
      },
      [AlertCurrencyColumns.CURRENCY2]: {
        message: `${translate('currency')} 2`,
        isSortable: false,
        render: (record: ICurrencySubscription) => {
          if (isEditModeEnable(record.id.toString())) {
            return (
              <Select
                size="s"
                value={record.currency2}
                onChange={(e) => onFieldChange(record.id, 'currency2', e.target.value)}
                options={toCurrenciesList}
                placeholder=" "
              />
            );
          }

          return record.currency2.toUpperCase();
        },
      },
      [AlertCurrencyColumns.SUBSCRIPTION_TYPE]: {
        message: 'subscriptionType',
        isSortable: false,
        render: (record: ICurrencySubscription) => {
          if (isEditModeEnable(record.id.toString())) {
            return (
              <Select
                size="s"
                value={record.subscriptionType}
                onChange={(e) => onFieldChange(record.id, 'subscriptionType', e.target.value)}
                options={subscriptionTypeOptions.map((item) => ({
                  ...item,
                  label: translate(item.label),
                }))}
                placeholder=" "
              />
            );
          }

          return translate(
            subscriptionTypeOptions.find((item) => item.value === record.subscriptionType)?.label ||
              ''
          );
        },
      },
      [AlertCurrencyColumns.VALUE]: {
        message: 'indicatorValue',
        isSortable: false,
        render: (record: ICurrencySubscription) => {
          if (isEditModeEnable(record.id.toString())) {
            return (
              <TextInput
                size="s"
                value={record.value}
                onChange={(e) => onFieldChange(record.id, 'value', e.target.value)}
              />
            );
          }

          return record.value;
        },
      },
      [AlertCurrencyColumns.TERM]: {
        message: 'analyzedDaysPeriod',
        isSortable: false,
        render: (record: ICurrencySubscription) => {
          if (isEditModeEnable(record.id.toString())) {
            return (
              <TextInput
                size="s"
                value={record.term}
                onChange={(e) => onFieldChange(record.id, 'term', e.target.value)}
              />
            );
          }

          return record.term;
        },
      },
    }),
    [fromCurrenciesList, isEditModeEnable, onFieldChange, toCurrenciesList, translate]
  );

  return columnTemplate;
};
