import { SelectItem, StringSelectItem } from 'common/types';
import { SetNomenclatureOptionsPayload } from './cbd/nomenclature';
import { IExtension, RequiredStateFields } from './types';

interface RequiredFields {
  id: string;
  groupId?: string;
  departmentId?: string;
  classId?: string;
  label: string;
}

export const setFailure = <T extends RequiredStateFields>(state: T) => {
  state.isLoading = false;
  state.isFailure = true;
};

export const setLoading = <T extends RequiredStateFields>(state: T) => {
  state.isLoading = true;
  state.isFailure = false;
};

export const formatToSelectValue = <T extends IExtension[]>(data: T): SelectItem[] => {
  return data.map(({ id, name, properties }) => ({
    value: Number(properties?.login) || Number(id),
    label: name || id.toString(),
  }));
};

export const formNomenclatureOptions = <T extends RequiredFields>(
  nomenclatureOptions: T[],
  config: SetNomenclatureOptionsPayload
) => {
  return nomenclatureOptions.reduce((prev: StringSelectItem[], curr: T) => {
    const configKeys = Object.keys(config) as (keyof SetNomenclatureOptionsPayload)[];

    const isPropertyExistAndEqualsArrayElement = configKeys.every((item) => {
      return config[item] === curr[item];
    });

    if (isPropertyExistAndEqualsArrayElement) {
      return [...prev, { label: `${curr.id}-${curr.label}`, value: curr.id }];
    }
    return prev;
  }, []);
};
