import { IExternalData } from 'api/cbd/externalSource';
import { useAppTranslations } from 'translations';
import { IColumnTemplate } from 'common/utils';
import { ExternalSourceColumns } from './consts';

export const useGetColumnTemplate = (): {
  [key in ExternalSourceColumns]: IColumnTemplate<IExternalData>;
} => {
  const translations = useAppTranslations();

  const columnTemplate = {
    [ExternalSourceColumns.SOURCE]: {
      message: translations('source'),
      isSortable: true,
      render: (record: IExternalData) => record.source,
    },
    [ExternalSourceColumns.CURRENCY_CODE]: {
      message: translations('currency'),
      isSortable: true,
      render: (record: IExternalData) => record.currencyCode.toUpperCase(),
    },
    [ExternalSourceColumns.CATEGORY]: {
      message: translations('category'),
      isSortable: true,
      render: (record: IExternalData) => record.category?.name || '',
    },
    [ExternalSourceColumns.TYPE]: {
      message: translations('type'),
      isSortable: true,
      render: (record: IExternalData) => record.type?.name || '',
    },
    [ExternalSourceColumns.SUBTYPE]: {
      message: translations('subType'),
      isSortable: true,
      render: (record: IExternalData) => record.subtype?.name || '',
    },
    [ExternalSourceColumns.NAME]: {
      message: translations('nomination'),
      isSortable: true,
      render: (record: IExternalData) => record.name,
    },
    [ExternalSourceColumns.NAME2]: {
      message: translations('newNomination'),
      isSortable: true,
      render: (record: IExternalData) => record.name2,
    },
    [ExternalSourceColumns.DATE]: {
      message: translations('date'),
      isSortable: true,
      render: (record: IExternalData) => record.date,
    },
    [ExternalSourceColumns.VALUE]: {
      message: translations('value'),
      isSortable: true,
      render: (record: IExternalData) => record.value,
    },
    [ExternalSourceColumns.REGION]: {
      message: translations('region'),
      isSortable: true,
      render: (record: IExternalData) => record.region,
    },
    [ExternalSourceColumns.MEASURE]: {
      message: translations('unit'),
      isSortable: true,
      render: (record: IExternalData) => record.measure,
    },
    [ExternalSourceColumns.DIAMETER]: {
      message: translations('diameter'),
      isSortable: true,
      render: (record: IExternalData) => record.info?.diameter || '',
    },
    [ExternalSourceColumns.THICKNESS]: {
      message: translations('thickness'),
      isSortable: true,
      render: (record: IExternalData) => record.info?.thickness || '',
    },
    [ExternalSourceColumns.CONTRACT]: {
      message: translations('contractType'),
      isSortable: true,
      render: (record: IExternalData) => record.info?.contract || '',
    },
    [ExternalSourceColumns.STANDARD]: {
      message: translations('materialStandard'),
      isSortable: true,
      render: (record: IExternalData) => record.info?.standard || '',
    },
    [ExternalSourceColumns.GRADE]: {
      message: translations('mark'),
      isSortable: true,
      render: (record: IExternalData) => record.info?.grade || '',
    },
    [ExternalSourceColumns.PRICE_TYPE_NAME]: {
      message: translations('priceType'),
      isSortable: true,
      render: (record: IExternalData) => record.info?.priceTypeName || '',
    },
  };

  return columnTemplate;
};
