import React, { useEffect } from 'react';
import { Flex, Notification } from '@mozaic-ds/react';
import {
  getBuyerSiteGroupHierarchyData,
  resetBuyerSiteGroupLinks,
} from 'store/buyerSiteGroup/buyerSiteGroupHierarchy';
import { useAppDispatch } from 'store/hooks';
import { useMount } from 'hook/useMount';
import {
  getSupplierBuyerSiteGroupLinksData,
  resetSupplierBuyerSiteGroupLinks,
} from 'store/buyerSiteGroup/supplierBuyerSiteGroupLinks';
import { useAppTranslations } from 'translations';
import FieldWrapper from 'components/FieldWrapper/FieldWrapper';
import { useSupplierIdsData, setSearchValue, getSupplierIds } from 'store/dictionary/supplierIds';
import { useController, useForm } from 'react-hook-form';
import { SelectItem } from 'common/types';
import AutocompleteWithSearch from 'components/Autocomplete/AutocompleteWithSearch';
import BuyerSiteGroupTable from './BuyerSiteGroupTable';
import styles from './BuyerSiteGroup.module.scss';

export const BuyerSiteGroup = () => {
  const translations = useAppTranslations();
  const dispatch = useAppDispatch();
  const { control, formState } = useForm<{ suppliers: SelectItem[] }>({
    defaultValues: { suppliers: [] },
  });
  const { isDirty } = formState;
  const { field: suppliers } = useController({ name: 'suppliers', control });
  const { suppliers: suppliersList, searchValue } = useSupplierIdsData().data;

  useMount(() => {
    dispatch(getBuyerSiteGroupHierarchyData());
    return () => {
      dispatch(resetSupplierBuyerSiteGroupLinks());
      dispatch(resetBuyerSiteGroupLinks());
    };
  });

  useEffect(() => {
    dispatch(getSupplierIds({ searchString: searchValue, offset: 0 }));
  }, [dispatch, searchValue]);

  useEffect(() => {
    if (suppliers.value.length > 0)
      dispatch(getSupplierBuyerSiteGroupLinksData(suppliers.value.map(({ value }) => value)));
  }, [dispatch, suppliers.value]);

  return (
    <>
      <Flex marginBottom="mu100">
        <FieldWrapper
          label={translations('department')}
          id="suppliers"
          className={styles.flexBasis}
        >
          <AutocompleteWithSearch
            {...suppliers}
            handleSearch={setSearchValue}
            placeholder={translations('chooseAnOption')}
            listBoxItems={suppliersList}
          />
        </FieldWrapper>
      </Flex>
      {!isDirty && (
        <Notification
          className="mu-mb-125"
          message={translations('bsgSelectSuppliersNotification')}
        />
      )}
      <BuyerSiteGroupTable suppliers={suppliers.value} />
    </>
  );
};
