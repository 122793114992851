import { Coords, ExistingGrantsType, GrantsKey } from './types';

export const saveByUrl = (url: string, name = '') => {
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
};

export const getCoords = (control: HTMLDivElement | null): Coords | null => {
  const box = control?.getBoundingClientRect();
  if (!box) return null;

  return {
    left: box.left,
    top: box.top + box.height,
    width: box.width,
    height: box.height,
  };
};

export const getGrantInfo = <TStatusKeys extends Partial<GrantsKey>[]>(
  grants: ExistingGrantsType,
  statuses: TStatusKeys
): GrantsKey => {
  return statuses.find((item) => grants[item]) as GrantsKey;
};

export const getGrantsInfo = <TStatusKeys extends Partial<GrantsKey>[]>(
  grants: ExistingGrantsType,
  statuses: TStatusKeys
): GrantsKey[] => {
  return statuses.filter((item) => grants[item]);
};
