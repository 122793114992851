import React, { FC } from 'react';
import { Text, View } from '@mozaic-ds/react';
import { useRoles } from 'store/roles/roles';
import { GetNomenclatureLinkUsers, GetSupplierLinkUsersResponse } from '@ppm/ppm-platform-api';
import { Role } from '@ppm/ppm-platform-user-service';
import Card from './Card/Card';

type UserCardsProps = {
  roleId: number;
  links: GetNomenclatureLinkUsers.Response | GetSupplierLinkUsersResponse['data'];
};

const UserCards: FC<UserCardsProps> = ({ roleId, links }) => {
  const { roles } = useRoles().data;
  const roleName = (roles.find((role) => role.id === roleId) as Role)?.description;

  return (
    <View marginTop="mu050">
      <Text theme="light">{roleName}</Text>
      {links[roleId].map((item) => (
        <Card {...item} ldap={item?.ldap} key={item?.ldap} />
      ))}
    </View>
  );
};
export default UserCards;
