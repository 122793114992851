import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { useAppTranslations } from 'translations';
import { useController, useForm } from 'react-hook-form';
import { clearDeletedFilters, setFilters } from 'store/cbd/templateStructure/filters';
import { FilterName } from '../ParseTable.types';
import { getLabel } from './consts';

export type TParseTableFilters = {
  costName: string;
  codeCost: string;
  costRequired: boolean;
  costUnique: boolean;
};

export const useFiltersForm = () => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const form = useForm<TParseTableFilters>({
    defaultValues: {
      costName: '',
      codeCost: '',
      costRequired: false,
      costUnique: false,
    },
  });

  const { control, handleSubmit } = form;

  const { field: costName } = useController({ name: 'costName', control });
  const { field: codeCost } = useController({ name: 'codeCost', control });
  const { field: costRequired } = useController({ name: 'costRequired', control });
  const { field: costUnique } = useController({ name: 'costUnique', control });

  const onSubmit = useCallback(() => {
    handleSubmit((data) => {
      const keys = Object.keys(data);
      dispatch(clearDeletedFilters());
      dispatch(
        setFilters(
          keys
            .map((key) => ({
              id: key,
              value: data[key as FilterName],
              label: getLabel(key as FilterName, translations),
            }))
            .filter(({ value }) => (Array.isArray(value) ? value.length > 0 : Boolean(value)))
        )
      );
    })();
  }, [dispatch, handleSubmit, translations]);

  return {
    form,
    costName,
    codeCost,
    costRequired,
    costUnique,
    onSubmit,
  };
};
