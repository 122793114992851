import { NomenclatureLink } from 'store/dashboard/rightsManagement/nomenclatureLinks';
import { DEFAULT_PATH_ITERATION_VALUE } from 'store/dashboard/consts';
import { Leaf, LeafDataType } from './leaf';

export type RootType = { tree: Leaf[] };

export class Tree {
  private readonly root: RootType;

  constructor(root: RootType) {
    this.root = root;
  }

  get(): RootType {
    return this.root;
  }

  clone(): Tree {
    return new Tree(this.root);
  }

  add({ nodes, path }: { nodes: LeafDataType[]; path: number[] }): this {
    this.root.tree.forEach((departmentNode) => {
      if (departmentNode.data.path[0] === path[0])
        departmentNode.addChildren(nodes, path.slice(1), 1);
    });

    return this;
  }

  clearTree(): this {
    if (this.root.tree) {
      this.root.tree.forEach((departmentNode) => departmentNode.clearNode());
    }

    return this;
  }

  change(value: boolean, path: number[]): this {
    if (this.root.tree) {
      this.root.tree.forEach((departmentNode) => {
        if (departmentNode.data.path[0] === path[0]) {
          departmentNode.changeValue({ value, pathIteration: DEFAULT_PATH_ITERATION_VALUE, path });
        }
      });
    }

    return this;
  }

  findAllCheckedNodes(): Array<number[]> {
    const checkedNodes: Array<number[]> = [];

    if (this.root.tree) {
      this.root.tree.forEach((departmentNode) => {
        departmentNode.findSelectedItems(checkedNodes);
      });
    }

    return checkedNodes;
  }

  fillDefaultValue(defaultValue: NomenclatureLink, role: number) {
    if (this.root.tree && defaultValue && defaultValue[role]) {
      defaultValue[role].forEach((defaultPath) => {
        this.root.tree.forEach((departmentNode) => {
          if (departmentNode.data.path[0] === defaultPath[0])
            departmentNode.fillDefaultNodeValue({
              value: true,
              pathIteration: DEFAULT_PATH_ITERATION_VALUE,
              path: defaultPath,
            });
        });
      });
    }

    return this;
  }
}
