import { CbdStatus } from 'common/cbd/types';
import { Translations } from 'translations';
import { FilterName } from '../AllUploads.types';

export enum AllUploadsColumns {
  NAV_CBD_INFO = 'navCbdInfo',
  NAV_CBD_ANALYSIS = 'navCbdAnalysis',
  ID = 'id',
  REQUEST_ID = 'requestId',
  SUPPLIER_ID = 'supplierId',
  FILE_LINK = 'fileLink',
  STATUS = 'status',
  REQUEST_STATUS = 'requestStatus',
  GROUP = 'group',
  GROUPS_NAME = 'groupsName',
  DEP_NAME = 'depName',
  CLASS_NAME = 'className',
  SUB_CLASS_NAME = 'subclassName',
  ITEM = 'item',
  CREATED_AT = 'createdAt',
  DATE_CBD = 'dateCbd',
}

export const statusOptions = [
  { value: CbdStatus.Approved, label: 'approved' },
  { value: CbdStatus.Declined, label: 'declined' },
  { value: CbdStatus.NotSeen, label: 'not_seen' },
];

export const getLabel = (key: FilterName, t: Translations) => {
  return {
    cbdSupplierId: t('supplier'),
    cbdDate: t('dateCbd'),
    cbdId: t('cbdNumber'),
    loadFileName: t('fileName'),
    cbdCreatedAt: t('cbdLoadDate'),
    itemGroup: t('devision'),
    itemDep: t('subDevision'),
    itemClass: t('type'),
    itemSubclass: t('subType'),
    attributeDep: t('industry'),
    attributeSubDep: t('subIndustry'),
    attributeGroup: t('materialGroup'),
    cbdStatus: t('statusCbd'),
    requestStatusId: t('requestStatus'),
  }[key];
};

export const filtersName: FilterName[] = [
  'cbdSupplierId',
  'cbdDate',
  'cbdId',
  'loadFileName',
  'cbdCreatedAt',
  'itemGroup',
  'itemDep',
  'itemClass',
  'itemSubclass',
  'attributeDep',
  'attributeSubDep',
  'attributeGroup',
  'cbdStatus',
  'requestStatusId',
];
