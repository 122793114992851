import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetPricelistResponse } from '@ppm/ppm-platform-api';
import { getCurrentPricelist } from 'api/pricelist';
import { setFailure, setLoading } from 'store/helpers';
import { RootState, RequiredStateFields } from '../types';
import { useAppSelector } from '../hooks';

type CurrentPriceListFlowGrants = GetPricelistResponse['flowRights']['grants'];
export type CurrentPriceListSliceData = GetPricelistResponse['pricelist'];
export type CurrentPriceListFlowTransitions = GetPricelistResponse['flowRights']['transitions'];

interface SliceState extends RequiredStateFields {
  priceList: CurrentPriceListSliceData;
  grants: CurrentPriceListFlowGrants;
  transitions: CurrentPriceListFlowTransitions;
  isEdited: boolean;
  needToGenerateAgain: boolean;
}

export const getCurrentPricelistData = createAsyncThunk(
  'currentPriceList/getCurrentPricelistData',
  async (args: { requestId: string; priceListId: string }, { rejectWithValue }) => {
    try {
      return await getCurrentPricelist({ urlParameters: [args.requestId, args.priceListId] });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const currentPriceListSlice = createSlice({
  initialState: {
    priceList: {} as CurrentPriceListSliceData,
    grants: {},
    transitions: {},
    needToGenerateAgain: false,
    isEdited: false,
    isLoading: false,
    isFailure: false,
  },
  name: 'currentPriceList',
  reducers: {
    setEditState: (state, action: PayloadAction<boolean>) => {
      state.isEdited = action.payload;
    },
    setNeededGeneration: (state, action) => {
      state.needToGenerateAgain = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentPricelistData.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(getCurrentPricelistData.fulfilled, (state: SliceState, action) => {
        state.priceList = action.payload.pricelist;
        state.grants = action.payload.flowRights.grants;
        state.transitions = action.payload.flowRights.transitions;
        state.isLoading = false;
        state.isFailure = false;
      })
      .addCase(getCurrentPricelistData.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const { setEditState, setNeededGeneration } = currentPriceListSlice.actions;

export const useCurrentPriceListData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.priceList.currentPriceList) };
};
