import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getExternalDataNames } from 'api/cbd/selects';
import { initPageable } from 'common/consts';
import { IGetExternalDataNames, IGetSelectsPayload, IPageable } from 'common/cbd/types';
import { StringSelectItem } from 'common/types';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

interface SliceState {
  list: StringSelectItem[];
  pageable: IPageable;
  searchValue: string;
}

const initialState: SliceState = {
  list: [],
  pageable: initPageable,
  searchValue: '',
};

export const extDataNamesList = createAsyncThunk(
  'extDataNames/extDataNamesList',
  async (payload: IGetSelectsPayload | undefined, { rejectWithValue }) => {
    try {
      return await getExternalDataNames(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const extDataNamesSlice = createSlice({
  initialState,
  name: 'extDataNames',
  reducers: {
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    clearExtDataNamesSlice() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      extDataNamesList.fulfilled,
      (state: SliceState, action: PayloadAction<IGetExternalDataNames>) => {
        state.list = action.payload.data;
        state.pageable = action.payload.pageable;
      }
    );
  },
});

export const { setSearchValue, clearExtDataNamesSlice } = extDataNamesSlice.actions;

export const useExtDataNames = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.extDataNames);
};
