import React, { FC } from 'react';
import { CheckBox, IconButton, View } from '@mozaic-ds/react';
import { DisplaySetting24 } from '@mozaic-ds/icons/react';
import Layer from 'components/Layer/Layer';
import { Controller } from 'react-hook-form';
import { useAppTranslations } from 'translations';
import { useColumnsForm, useGetColumns } from 'pages/Requests/hooks/columns';
import { useColumnsData } from 'store/requests/columns';

const ColumnsPopup: FC<{ className?: string }> = ({ className }) => {
  const translations = useAppTranslations();
  const { columns } = useGetColumns();
  const { columns: columnsFilter } = useColumnsData().data;
  const { form, saveColumnsFilter, resetColumnsFilters } = useColumnsForm();

  return (
    <Layer
      id="popupColumns"
      icon={<DisplaySetting24 fill="#71706b" />}
      resetItems={resetColumnsFilters}
      saveItems={saveColumnsFilter}
      title={translations('customizeColumns')}
      button={
        <IconButton theme="neutral" size="s" variant="bordered" className={className}>
          <DisplaySetting24 />
        </IconButton>
      }
    >
      <View as="form" paddingLeft="mu025">
        {columnsFilter.map(({ key }) => {
          return (
            <View key={key} marginTop="mu150" marginBottom="mu150">
              <Controller
                render={({ field }) => (
                  <CheckBox {...field} isChecked={field.value} value={field.name}>
                    {columns[key].label}
                  </CheckBox>
                )}
                name={key}
                control={form.control}
              />
            </View>
          );
        })}
      </View>
    </Layer>
  );
};

export default ColumnsPopup;
