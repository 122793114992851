import { useAppTranslations } from 'translations';
import { createColumns } from 'common/utils';
import { useAppDispatch } from 'store/hooks';

import { setCompareSortable } from 'store/cbd/compare/columns';
import { IDataTableColumn, SortDirection } from 'common/types';
import { CompareColumns } from './consts';
import { ICompareTable, ICompareColumnSort } from '../CompareTable.types';
import { useGetColumnTemplate } from './helpers';

type CompareSortConfig = {
  [key in CompareColumns]: ICompareColumnSort | null;
};

export const sortConfig: CompareSortConfig = {
  [CompareColumns.ID]: 'cbd.id',
  [CompareColumns.DATE_CBD]: 'cbd.dateCbd',
  [CompareColumns.SUPPLIER_NAME]: null,
  [CompareColumns.SUPPLIER_ID]: null,
  [CompareColumns.GROUP]: null,
  [CompareColumns.DEP_NAME]: null,
  [CompareColumns.SUB_DEP_NAME]: null,
  [CompareColumns.CLASS_NAME]: null,
  [CompareColumns.SUB_CLASS_NAME]: null,
};

export const useGetColumns = (): {
  columns: Record<CompareColumns, IDataTableColumn<ICompareTable>>;
} => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const handleSort = (field: ICompareColumnSort, sortDirection: SortDirection) => {
    dispatch(setCompareSortable({ field, sortDirection }));
  };

  const columnTemplate = useGetColumnTemplate();

  return {
    columns: createColumns<CompareColumns, ICompareTable, ICompareColumnSort>({
      columnTemplate,
      translations,
      handleSort,
      sortConfig,
    }),
  };
};
