import { useController, useForm } from 'react-hook-form';
import useUploadCbdFilesFormActions from 'pages/cbd/hok/hooks/useUploadCbdFilesFormActions';
import { downloadCbdTemplate } from 'common/utils';
import { IUploadTemplateCbdFormFields } from '../types';

const useEditTemplateActions = () => {
  const { control } = useForm<IUploadTemplateCbdFormFields>({
    criteriaMode: 'all',
    defaultValues: {
      templateCbdFiles: [],
    },
  });

  const { field: templateCbdFiles } = useController({ name: 'templateCbdFiles', control });

  const { templateCbdUploadFile, onDelete, handleChange, handleDelete } =
    useUploadCbdFilesFormActions({
      controller: templateCbdFiles,
    });

  return {
    onDownloadTemplate: downloadCbdTemplate,
    templateCbdFiles,
    templateCbdUploadFile,
    onDelete,
    handleChange,
    handleDelete,
  };
};

export default useEditTemplateActions;
