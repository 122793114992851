import { useMemo } from 'react';
import { useCbdAnalysisInfoInfoData } from 'store/cbd/cbdAnalysis/cbdAnalysisInfo';
import { ChartProps } from 'react-chartjs-2';
import { useChartConfig } from 'components/Chart/hooks/useChartConfig';
import { ChartType } from 'chart.js';
import { useAppTranslations } from 'translations';
import { getChartDataValues } from '../../helpers';

const useChartData = () => {
  const translations = useAppTranslations();
  const { data } = useCbdAnalysisInfoInfoData();

  const expensesChartData = useMemo(() => {
    const { expensesChartDataValue } = getChartDataValues(data);

    return {
      labels: [translations('shareFixedCosts'), translations('shareVariableCosts')],
      values: [expensesChartDataValue],
    };
  }, [data, translations]);

  const currencyChartData = useMemo(() => {
    const { currencyChartDataValue } = getChartDataValues(data);

    return {
      labels: ['RUR', 'USD', 'EUR'],
      values: [currencyChartDataValue],
    };
  }, [data]);

  const chartData = useMemo(() => {
    const { groupsChartDataValue } = getChartDataValues(data);

    return {
      labels: [
        translations('procurement'),
        translations('production'),
        translations('logistics'),
        translations('other'),
      ],
      values: [groupsChartDataValue],
    };
  }, [data, translations]);

  const expensesConfig: ChartProps<ChartType> = useChartConfig({
    type: 'doughnut',
    legendPosition: 'bottom',
    chartData: expensesChartData,
    needToShowLabels: false,
    needToShowLegend: true,
  });

  const currencyConfig: ChartProps<ChartType> = useChartConfig({
    type: 'doughnut',
    legendPosition: 'bottom',
    chartData: currencyChartData,
    needToShowLabels: false,
    needToShowLegend: true,
  });

  const config: ChartProps<ChartType> = useChartConfig({
    type: 'doughnut',
    legendPosition: 'bottom',
    chartData,
    needToShowLabels: false,
    needToShowLegend: true,
  });

  return {
    expensesConfig,
    currencyConfig,
    config,
  };
};

export default useChartData;
