import { combineReducers } from '@reduxjs/toolkit';
import { structureSlice } from './list';
import { columnsSlice } from './columns';
import { filtersSlice } from './filters';

export const templateStructure = combineReducers({
  list: structureSlice.reducer,
  columns: columnsSlice.reducer,
  filters: filtersSlice.reducer,
});
