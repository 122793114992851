export const emptySubscriptionsRow = {
  id: -1,
  login: null,
  dateStart: '',
  dateFinish: '',
  sourceId: null,
  categoryId: null,
  typeId: null,
  subtypeId: null,
  subscriptionType: '',
  value: 0,
  term: 0,
  source: null,
  category: null,
  type: null,
  subtype: null,
};
