import { getCbdItemInfo } from 'api/cbd/cbd';
import { ICbdItemInfo, NomenclatureState } from 'common/cbd/types';
import { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useSelectedLoad } from 'store/cbd/selectedLoad';

const useNomenclatureForm = () => {
  const form = useFormContext<NomenclatureState>();
  const { supplierId } = useSelectedLoad();

  const { control, setValue } = form;

  const { field: dep } = useController({ name: 'dep', control, rules: { required: true } });
  const { field: subDep } = useController({ name: 'subDep', control, rules: { required: true } });
  const { field: classData } = useController({ name: 'classData', control });
  const { field: subClass } = useController({ name: 'subClass', control });
  const { field: item } = useController({ name: 'item', control });

  const handleAutoFill = useCallback(
    (cbdItemInfo: ICbdItemInfo) => {
      const { deptId, classId, subclassId } = cbdItemInfo;

      if (deptId) setValue('subDep', deptId.toString());
      if (classId) setValue('classData', classId.toString());
      if (subclassId) setValue('subClass', subclassId.toString());
    },
    [setValue]
  );

  const itemValidate = useCallback(async (): Promise<boolean> => {
    const data = form.getValues();
    if (!data.item) return true;

    try {
      const itemInfo = await getCbdItemInfo({
        itemId: Number(data.item),
        supplierId,
      });

      if (
        itemInfo.item?.length &&
        itemInfo.item[0].itemId === data.item &&
        Boolean(itemInfo.supplier)
      ) {
        if (Number(dep.value) === itemInfo.item[0]?.groupId) handleAutoFill(itemInfo.item[0]);
        return true;
      }

      return false;
    } catch (e) {
      return false;
    }
  }, [dep.value, form, handleAutoFill, supplierId]);

  return {
    form,
    dep,
    subDep,
    classData,
    subClass,
    item,
    itemValidate,
  };
};

export default useNomenclatureForm;
