import { NomenclatureHierarchyNodeType } from '@ppm/ppm-platform-dictionary-service';

export type LeafDataType = {
  name: string;
  type: NomenclatureHierarchyNodeType;
  path: number[];
  isChecked: boolean;
  isFullSelected: boolean | null;
  isDirty: boolean;
  defaultSelectedValue: Array<number[]>; // если нет детей и isFullSelected === false чтобы при проходе findSelectedItems забирать актуальное значене
};

export class Leaf {
  data: LeafDataType;

  children: Array<Leaf>;

  constructor(data: LeafDataType) {
    this.data = data;
    this.children = [];
  }

  private setDefaultSelectedValue(path: number[]) {
    this.data.defaultSelectedValue = this.data.defaultSelectedValue.find(
      (item) => item.join() === path.join()
    )
      ? this.data.defaultSelectedValue
      : this.data.defaultSelectedValue.concat([path.slice()]);
  }

  addChildren(children: LeafDataType[], path: number[], pathIterationIndex: number): void {
    if (!path.length) {
      this.children = this.children.concat(children.map((child) => new Leaf(child)));
    } else {
      this.children = this.children.map((childNode) => {
        if (childNode.data.path[pathIterationIndex] === path[0])
          childNode.addChildren(children, path.slice(1), pathIterationIndex + 1);
        return childNode;
      });
    }
  }

  findSelectedItems(checkedNodes: Array<number[]>): Array<number[]> {
    if (this.data.isFullSelected === null) {
      return checkedNodes;
    }

    if (this.data.isFullSelected) {
      checkedNodes.push(this.data.path);
    } else if (this.children.length !== 0) {
      this.children.forEach((child) => child.findSelectedItems(checkedNodes));
    } else if (this.data.defaultSelectedValue.length) {
      checkedNodes.push(...this.data.defaultSelectedValue);
    }

    return checkedNodes;
  }

  clearNode(): void {
    this.data.isDirty = false;
    this.data.isChecked = false;
    this.data.isFullSelected = null;
    if (this.children.length !== 0) this.children.map((child) => child.clearNode());
  }

  fillDefaultNodeValue({
    value,
    pathIteration,
    path,
  }: {
    value: boolean;
    pathIteration: number;
    path?: number[];
  }) {
    if (this.data.isDirty) {
      this.changeValue({
        value,
        pathIteration,
        path: !this.children.length ? this.data.path : path,
      });
    } else if (!path || (path && this.data.path.join() === path.join())) {
      this.data.isChecked = value;
      if (this.children.length !== 0) {
        this.children.map((child) =>
          child.fillDefaultNodeValue({
            value,
            pathIteration,
          })
        );
        this.data.isFullSelected = this.calculateIsFullSelectedField();
        return;
      }

      this.data.isFullSelected = value || null;
    } else if (!this.children.length) {
      this.setDefaultSelectedValue(path);
      this.data.isFullSelected = false;
    } else {
      this.children.map((childNode) => {
        if (
          childNode.data.path.slice(0, pathIteration + 1).join() ===
          path.slice(0, pathIteration + 1).join()
        ) {
          this.setDefaultSelectedValue(path);
          childNode.fillDefaultNodeValue({
            value,
            pathIteration: pathIteration + 1,
            path,
          });
          this.data.isFullSelected = this.calculateIsFullSelectedField();
          this.data.isChecked = !!this.data.isFullSelected;
        }
        return childNode;
      });
    }
  }

  changeValue({
    value,
    pathIteration,
    path,
  }: {
    value: boolean;
    pathIteration: number;
    path?: number[];
  }) {
    if (!path || (path && this.data.path.join() === path.join())) {
      this.data.isDirty = true;
      this.data.isChecked = value;
      if (this.children.length !== 0) {
        this.children.map((child) =>
          child.changeValue({
            value,
            pathIteration,
          })
        );
        this.data.isFullSelected = this.calculateIsFullSelectedField();
        return;
      }

      this.data.isFullSelected = value || null;
    } else {
      this.children.map((childNode) => {
        if (
          childNode.data.path.slice(0, pathIteration).join() === path.slice(0, pathIteration).join()
        ) {
          childNode.changeValue({
            value,
            pathIteration: pathIteration + 1,
            path,
          });
          this.data.isFullSelected = this.calculateIsFullSelectedField();
          this.data.isChecked = !!this.data.isFullSelected;
          this.data.isDirty = true;
        }
        return childNode;
      });
    }
  }

  private calculateIsFullSelectedField(): LeafDataType['isFullSelected'] {
    if (this.children.every((child) => child.data.isChecked)) return true;
    if (
      this.children.some((child) => child.data.isChecked) ||
      this.children.some((child) => child.data.isFullSelected === false)
    )
      return false;

    return null;
  }
}
