import { useController, useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { SelectItem } from 'common/types';
import { useAppDispatch } from 'store/hooks';
import { useAppTranslations } from 'translations';
import { clearDeletedFilters, setFilters } from 'store/cbd/externalData/filters';
import { getLabel } from './consts';

interface IDefaultValues {
  region: SelectItem | null;
  extDataType: string;
  sourceType: string;
  source: string;
  periodType: string;
  categoryId: string;
  typeId: string;
  subtypeId: string;
  name: string;
  name2: string;
  externalSourceDate: string;
  value: string;
  measure: string;
}

export const useFiltersForm = () => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const defaultValues: IDefaultValues = {
    extDataType: '',
    sourceType: '',
    source: '',
    periodType: '',
    categoryId: '',
    typeId: '',
    subtypeId: '',
    name: '',
    name2: '',
    externalSourceDate: '',
    value: '',
    region: null,
    measure: '',
  };

  const form = useForm({
    defaultValues,
  });

  const { control, handleSubmit } = form;

  const { field: extDataType } = useController({ name: 'extDataType', control });
  const { field: sourceType } = useController({ name: 'sourceType', control });
  const { field: source } = useController({ name: 'source', control });
  const { field: periodType } = useController({ name: 'periodType', control });
  const { field: categoryId } = useController({ name: 'categoryId', control });
  const { field: typeId } = useController({ name: 'typeId', control });
  const { field: subtypeId } = useController({ name: 'subtypeId', control });
  const { field: name } = useController({ name: 'name', control });
  const { field: name2 } = useController({ name: 'name2', control });
  const { field: externalSourceDate } = useController({ name: 'externalSourceDate', control });
  const { field: value } = useController({ name: 'value', control });
  const { field: region } = useController({ name: 'region', control });
  const { field: measure } = useController({ name: 'measure', control });

  const onSubmit = useCallback(() => {
    handleSubmit((data: IDefaultValues) => {
      const entries = Object.entries(data);
      dispatch(clearDeletedFilters());
      dispatch(
        setFilters(
          entries
            .map(([key, val]) => {
              let finalValue = val || null;
              if (finalValue && typeof finalValue === 'object')
                finalValue = finalValue.value?.toString();
              return {
                id: key,
                value: finalValue,
                label: getLabel(key, translations),
              };
            })
            .filter(({ value: filterValue }) =>
              Array.isArray(filterValue) ? filterValue.length > 0 : Boolean(filterValue)
            )
        )
      );
    })();
  }, [dispatch, handleSubmit, translations]);

  return {
    form,
    extDataType,
    sourceType,
    source,
    periodType,
    categoryId,
    typeId,
    subtypeId,
    name,
    name2,
    externalSourceDate,
    value,
    region,
    measure,
    onSubmit,
  };
};
