import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSupplierBuyerSiteGroupLinks } from 'api/buyerSiteGroup';
import { GetSupplierBsgLinks } from '@ppm/ppm-platform-api';
import { RequiredStateFields, RootState } from '../types';
import { useAppSelector } from '../hooks';
import { setFailure, setLoading } from '../helpers';

type SupplierBsgLinks = GetSupplierBsgLinks.Response['data'];

interface SliceState extends RequiredStateFields {
  data: SupplierBsgLinks;
}

export const getSupplierBuyerSiteGroupLinksData = createAsyncThunk<
  GetSupplierBsgLinks.Response,
  GetSupplierBsgLinks.Query['supplierIds'] | undefined
>('bsg/getSupplierBuyerSiteGroupLinksData', async (supplierIds, { rejectWithValue }) => {
  try {
    return await getSupplierBuyerSiteGroupLinks({ conf: { params: { supplierIds } } });
  } catch (e) {
    return rejectWithValue(e);
  }
});

const initialState = {
  data: [],
  isFailure: false,
  isLoading: false,
};

export const supplierBuyerSiteGroupLinksSlice = createSlice({
  initialState,
  name: 'supplierBuyerSiteGroupLinks',
  reducers: {
    resetSupplierBuyerSiteGroupLinks() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSupplierBuyerSiteGroupLinksData.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getSupplierBuyerSiteGroupLinksData.fulfilled,
        (state: SliceState, action: PayloadAction<GetSupplierBsgLinks.Response>) => {
          state.data = action.payload.data;
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getSupplierBuyerSiteGroupLinksData.rejected, (state: SliceState) => {
        state.data = [];
        setFailure(state);
      });
  },
});

export const { resetSupplierBuyerSiteGroupLinks } = supplierBuyerSiteGroupLinksSlice.actions;

export const useSupplierBuyerSiteGroupLinks = (): { data: SliceState } => {
  return {
    data: useAppSelector((state: RootState) => state.buyerSiteGroup.supplierBuyerSiteGroupLinks),
  };
};
