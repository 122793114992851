import { AxiosResponse } from 'axios';
import { IGetResponse, IPageSize } from 'common/cbd/types';
import { cbdApiClient } from '../index';

interface Category {
  id: number;
  type: string;
  name: string;
  parentId: number | null;
}

interface ICreateNewScheduler extends ICreateNewSchedulerFields {
  name: string;
}

interface Source {
  id: number;
  name: string;
  extDataType: string;
  source: string;
  destination: string;
  mapping: string;
  apiPeriodType: string;
  relationPeriodType: string;
  overwrite: boolean;
  overWriteKey: string;
  schedulerPeriodType: string;
  schedulerPeriod: number;
  offset: number;
  schedulerTime: string;
  schedulerStart: string;
  schedulerFinish: string;
  lastLaunch: string;
  createdAt: string;
  updatedAt: string;
}

interface Type {
  id: number;
  type: string;
  name: string;
  parentId: number;
}

export interface ICreateNewSchedulerFields {
  extDataType: string;
  source: string;
  destination: string;
  mapping: string;
  apiPeriodType: string;
  relationPeriodType: string;
  overwrite: boolean;
  overWriteKey: string;
  schedulerPeriodType: string;
  schedulerPeriod: number;
  schedulerTime: string;
  schedulerStart: string;
  schedulerFinish: string;
  lastLaunch: string;
}

export interface IScheduler {
  id: number;
  name: string;
  extDataType: string;
  source: string;
  destination: string;
  mapping: string;
  apiPeriodType: string;
  relationPeriodType: string;
  overwrite: boolean;
  overWriteKey: string;
  schedulerPeriodType: string;
  schedulerPeriod: number;
  schedulerTime: string;
  schedulerStart: string;
  schedulerFinish: string;
  lastLaunch: string;
  createdAt: string;
  updatedAt: string;
}

export interface IGetScheduler {
  data: IScheduler[];
}

export interface ISubscription {
  id: number;
  login: string | null;
  dateStart: string;
  dateFinish: string;
  sourceId: number | null;
  categoryId: number | null;
  typeId: number | null;
  subtypeId: number | null;
  subscriptionType: string;
  value: number;
  term: number;
  source: Source | null;
  category: Category | null;
  type: Type | null;
  subtype: Type | null;
  childrens?: ISubscription[];
  level?: number;
}

export interface IGetSubscriptions extends IGetResponse {
  data: ISubscription[];
}

export interface ICurrencySubscription {
  id: number;
  login: string;
  dateStart: string;
  dateFinish: string;
  currency1: string;
  currency2: string;
  subscriptionType: string;
  value: number;
  term: number;
  childrens?: ICurrencySubscription[];
  level?: number;
}

export interface IPostSubscriptions {
  id?: number;
  login: string | null;
  dateStart: string;
  dateFinish: string;
  sourceId: number | null;
  categoryId: number | null;
  typeId: number | null;
  subtypeId: number | null;
  subscriptionType: string;
  value: number;
  term: number;
}

export interface IPostCurrencySubscriptions {
  id?: number;
  login: string;
  dateStart: string;
  dateFinish: string;
  currencyId1: string;
  currencyId2: string;
  subscriptionType: string;
  value: number;
  term: number;
}

export interface IUpdateScheduler extends ICreateNewScheduler {
  id: number;
}

export interface IGetCurrencySubscription extends IGetResponse {
  data: ICurrencySubscription[];
}

export const getSchedulerReq = async () => {
  const { data }: AxiosResponse<IGetScheduler> = await cbdApiClient.get('/api/api-scheduler');
  return data;
};

export const getOneSchedulerReq = async (payload: string) => {
  const { data }: AxiosResponse<IScheduler> = await cbdApiClient.get(
    `/api/api-scheduler/${payload}`
  );
  return data;
};

export const createNewScheduler = async (payload: ICreateNewScheduler) => {
  const { data }: AxiosResponse<IGetScheduler> = await cbdApiClient.post(
    '/api/api-scheduler',
    payload
  );
  return data;
};

export const updateScheduler = async ({ id, ...payload }: IUpdateScheduler) => {
  const { data }: AxiosResponse<IGetScheduler> = await cbdApiClient.patch(
    `/api/api-scheduler/${id}`,
    payload
  );
  return data;
};

export const manualScheduler = async (id: string) => {
  const { data } = await cbdApiClient.post(`/api/api-scheduler/${id}/manual`);
  return data;
};

export const getSubscriptionsReq = async (params: IPageSize) => {
  const { data }: AxiosResponse<IGetSubscriptions> = await cbdApiClient.get(`/api/subscriptions`, {
    params,
  });
  return data;
};

export const getCurrencySubscriptionsReq = async (params: IPageSize) => {
  const { data }: AxiosResponse<IGetCurrencySubscription> = await cbdApiClient.get(
    `/api/cur-subscriptions/`,
    { params }
  );
  return data;
};

export const bulkDeleteSubscriptions = async (ids: string[]) => {
  const { data } = await cbdApiClient.delete('/api/subscriptions/bulkDelete', {
    params: {
      ids,
    },
  });
  return data;
};

export const bulkDeleteCurrencySubscriptions = async (ids: string[]) => {
  const { data } = await cbdApiClient.delete('/api/cur-subscriptions/bulkDelete', {
    params: {
      ids,
    },
  });
  return data;
};

export const postSubscriptions = async (payload: IPostSubscriptions) => {
  const { data } = await cbdApiClient.post('/api/subscriptions', payload);
  return data;
};

export const postCurrencySubscriptions = async (payload: IPostCurrencySubscriptions) => {
  const { data } = await cbdApiClient.post('/api/cur-subscriptions', payload);
  return data;
};

export const bulkUpdateSubscriptions = async (payload: IPostSubscriptions[]) => {
  const { data } = await cbdApiClient.patch('/api/subscriptions/bulkUpdate', {
    subscriptions: payload,
  });
  return data;
};

export const bulkUpdateCurrencySubscriptions = async (payload: IPostCurrencySubscriptions[]) => {
  const { data } = await cbdApiClient.patch('/api/cur-subscriptions/bulkUpdate', {
    subscriptions: payload,
  });
  return data;
};
