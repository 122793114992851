import { useCallback, useEffect } from 'react';
import { useAppTranslations } from 'translations';
import { setAllUploadsSortable, setVisibleAllUploadsColumns } from 'store/cbd/allUploads/columns';
import { createColumns, getHasGrants } from 'common/utils';
import { useAppDispatch } from 'store/hooks';

import { useForm } from 'react-hook-form';
import { useUserData } from 'store/auth/user';
import { IDataTableColumn, SortDirection } from 'common/types';
import { AllUploadsColumns } from './consts';
import { ICbdTable, IAllUploadsColumnSort } from '../AllUploads.types';
import { useGetColumnTemplate } from './helpers';

type AllUploadsSortConfig = {
  [key in AllUploadsColumns]: IAllUploadsColumnSort | null;
};

export const sortConfig: AllUploadsSortConfig = {
  [AllUploadsColumns.NAV_CBD_INFO]: null,
  [AllUploadsColumns.NAV_CBD_ANALYSIS]: null,
  [AllUploadsColumns.REQUEST_ID]: 'load.requestId',
  [AllUploadsColumns.SUPPLIER_ID]: 'cbd.supplierId',
  [AllUploadsColumns.DATE_CBD]: 'cbd.dateCbd',
  [AllUploadsColumns.ID]: 'cbd.id',
  [AllUploadsColumns.FILE_LINK]: 'load.fileName',
  [AllUploadsColumns.CREATED_AT]: 'cbd.createdAt',
  [AllUploadsColumns.GROUP]: 'itemGroup.groups',
  [AllUploadsColumns.GROUPS_NAME]: 'cbd.division',
  [AllUploadsColumns.DEP_NAME]: 'itemGroup.department',
  [AllUploadsColumns.CLASS_NAME]: 'itemGroup.class',
  [AllUploadsColumns.SUB_CLASS_NAME]: 'itemGroup.subclass',
  [AllUploadsColumns.ITEM]: 'itemGroup.item',
  [AllUploadsColumns.STATUS]: 'cbd.status',
  [AllUploadsColumns.REQUEST_STATUS]: 'load.requestStatusId',
};

export const useGetColumns = (): {
  columns: Record<AllUploadsColumns, IDataTableColumn<ICbdTable>>;
} => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const handleSort = (field: IAllUploadsColumnSort, sortDirection: SortDirection) => {
    dispatch(setAllUploadsSortable({ field, sortDirection }));
  };

  const columnTemplate = useGetColumnTemplate();

  return {
    columns: createColumns<AllUploadsColumns, ICbdTable, IAllUploadsColumnSort>({
      columnTemplate,
      translations,
      handleSort,
      sortConfig,
    }),
  };
};

export const useColumnsForm = () => {
  const dispatch = useAppDispatch();
  const { userGrants } = useUserData().user;
  const { CBDAnalyse } = getHasGrants(userGrants);

  const form = useForm({
    defaultValues: {
      navCbdInfo: true,
      navCbdAnalysis: !!CBDAnalyse,
      id: true,
      requestId: true,
      supplierId: true,
      fileLink: true,
      status: true,
      requestStatus: true,
      group: true,
      groupsName: true,
      depName: true,
      className: true,
      subclassName: true,
      item: true,
      createdAt: true,
      dateCbd: true,
    },
  });

  const saveColumnsFilter = useCallback(() => {
    form.handleSubmit((data) => {
      dispatch(setVisibleAllUploadsColumns(data));
    })();
  }, [dispatch, form]);

  const resetColumnsFilters = () => {
    form.reset();
    dispatch(setVisibleAllUploadsColumns(form.getValues()));
  };

  useEffect(() => {
    if (!CBDAnalyse) {
      saveColumnsFilter();
    }
  }, [dispatch, saveColumnsFilter, CBDAnalyse]);

  return { form, saveColumnsFilter, resetColumnsFilters };
};
