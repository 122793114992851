import { JSX } from 'react';
import { RequestStatusId, RequestStatusCode } from '@ppm/ppm-platform-request-service';
import { Grants } from '@ppm/ppm-platform-user-service';
import { TBadgeTheme } from '@mozaic-ds/react';
import { Locale, format } from 'date-fns';
import { RequestFlowGrants, RequestFlowTransitions } from 'store/request/request';
import {
  ExistingGrantsType,
  GrantsKey,
  IDataTableColumn,
  IFilter,
  SortDirection,
} from 'common/types';
import { isEmpty } from 'lodash';
import { Translations } from 'translations';
import { CurrentPriceListFlowTransitions } from 'store/priceList/currentPriceList';
import { DownloadFile } from '@ppm/ppm-platform-api';
import { PricelistStatusId, PricelistStatusCode } from '@ppm/ppm-platform-pricelist-service';
import { ExtendsColumns } from 'pages/cbd/hok/partials/consts';
import { isSupplierEnvironment, existedLocale } from './consts';

export interface IColumnTemplate<T> {
  message: string;
  isSortable: boolean;
  render: (record: T) => JSX.Element | string | number | null;
}

interface ICreateTemplate<Table, ColumnSort> {
  columnTemplate: Record<string, IColumnTemplate<Table>>;
  translations: Translations;
  handleSort?: (columnsSort: ColumnSort, sortDirection: SortDirection) => void;
  sortConfig?: Record<string, ColumnSort | null>;
}

type Transitions<TFlow, TStatus extends keyof TFlow> = {
  transitionStatus: TStatus | undefined;
  transitionState: TFlow[TStatus];
};

// todo можно сделать не через перегрузку, но для этого нужно править либо экспорты на бекенде
// (чтобы не хардкодить типы на фронте, а потом бежать исправлять их если они поменяются на беке),
// либо править сигнатуру типа Partial<Record<RequestStatusCode, ValidTransition | InvalidTransition>>(в примере ниже буде Feature)
// где RequestStatusCode должен быть дженериков, и желательно полный тип вынесен в интерфейс, который можно импортнуть на фронте,
// а не при помощи бесконечного проваливания по свойству доставать нужный тип если сделать все эти приготовления, сигнатура
// получившейся функции на фронте получится следующая const getTransitionKeyByGrant =
// <TFlow extends Feature<TStatus>, TStatus extends keyof TFlow>(): Transitions<TFlow, TStatus> => {...}
// P.S можно это все сделать на фронте, но выглядит еще хуже чем перегрузка
export function getTransitionKeyByGrant(
  transition: RequestFlowTransitions,
  value: Grants | undefined
): Transitions<RequestFlowTransitions, RequestStatusCode>;

export function getTransitionKeyByGrant(
  transition: CurrentPriceListFlowTransitions,
  value: Grants | undefined
): Transitions<CurrentPriceListFlowTransitions, PricelistStatusCode>;

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable  @typescript-eslint/no-explicit-any */
export function getTransitionKeyByGrant(transition: any, grantValue: any): any {
  if (isEmpty(transition)) return { transitionStatus: undefined, transitionState: undefined };

  const transitionKeys = Object.keys(transition);
  const transitionStatus = transitionKeys.find((key) => transition[key]?.grant === grantValue);

  return {
    transitionStatus,
    transitionState: transitionStatus ? transition[transitionStatus] : undefined,
  };
}

const hasOwnGrant = (existingGrants: RequestFlowGrants, grant: Grants): boolean => {
  if (isEmpty(existingGrants) || !grant) return false;
  return Object.prototype.hasOwnProperty.call(existingGrants, grant);
};

export const getHasGrants = (existingGrants: RequestFlowGrants): ExistingGrantsType => {
  if (isEmpty(existingGrants)) return {};

  const checkHasGrant = (grant: Grants) => {
    return hasOwnGrant(existingGrants, grant);
  };

  return (Object.keys(Grants) as Array<GrantsKey>).reduce<ExistingGrantsType>(
    (prev, currentValue) => {
      return { ...prev, [currentValue]: checkHasGrant(Grants[currentValue]) };
    },
    {}
  );
};

export const getPriceListStatusBadgeTheme = (status: number): TBadgeTheme => {
  switch (status) {
    case PricelistStatusId.Sent:
      return 'warning';

    case PricelistStatusId.XLSXFormed:
    case PricelistStatusId.PDFFormed:
      return 'info';

    case PricelistStatusId.SignedByAllSides:
      return 'success';

    case PricelistStatusId.Error:
    case PricelistStatusId.Reject:
    case PricelistStatusId.Cancelled:
    case PricelistStatusId.GroupingPricesError:
      return 'danger';

    default:
      return 'neutral';
  }
};

export const getRequestStatusBadgeTheme = (status: number): TBadgeTheme => {
  switch (status) {
    case RequestStatusId.OnLmValidation:
      return isSupplierEnvironment ? 'info' : 'warning';

    case RequestStatusId.OnSupplierValidation:
    case RequestStatusId.RequiresDetailingFromSupplier:
    case RequestStatusId.WaitingForPriceListApproval:
    case RequestStatusId.PricesNotUploaded:
      return 'warning';

    case RequestStatusId.InProgress:
    case RequestStatusId.RequiresDetailingFromLMUser:
    case RequestStatusId.OnPurchasePriceValidation:
    case RequestStatusId.OnRecommendedRetailPriceValidation:
    case RequestStatusId.OnValidationWithPurchasingDirector:
    case RequestStatusId.OnValidationWithDepartmentDirector:
    case RequestStatusId.PriceListFormation:
    case RequestStatusId.PriceListFormed:
    case RequestStatusId.PricelistSigned:
    case RequestStatusId.PricesUploaded:
      return 'info';

    case RequestStatusId.Validated:
    case RequestStatusId.Completed:
    case RequestStatusId.PricesApplied:
      return 'success';

    case RequestStatusId.Rejected:
    case RequestStatusId.ErrorOnPriceListApproval:
    case RequestStatusId.PricelistNotSigned:
      return 'danger';

    default:
      return 'neutral';
  }
};

export const getLocale = (lang: string): Locale => {
  return existedLocale[lang];
};

export const createColumns = <Columns extends ExtendsColumns, Table, ColumnSort>({
  columnTemplate,
  translations,
  handleSort,
  sortConfig,
}: ICreateTemplate<Table, ColumnSort>): Record<Columns, IDataTableColumn<Table>> => {
  return Object.keys(columnTemplate).reduce(
    (prev: Record<Columns, IDataTableColumn<Table>>, curr) => {
      return {
        ...prev,
        [curr]: {
          message: translations(columnTemplate[curr].message),
          isSortable: columnTemplate[curr].isSortable,
          render: columnTemplate[curr].render,
          onSort:
            handleSort && sortConfig
              ? (sortDirection: SortDirection) => {
                  const sortField = sortConfig[curr];

                  if (sortField) {
                    handleSort(sortField, sortDirection);
                  }
                }
              : null,
        },
      };
    },
    {} as Record<Columns, IDataTableColumn<Table>>
  );
};

export const appendFileToFormData = (file: File): FormData => {
  const formData = new FormData();

  formData.append('file', file);

  return formData;
};

export const downloadFileByLink = (href: string, name?: string): void => {
  const a = document.createElement('a');
  a.href = href;
  if (name) a.download = name;
  a.click();
  a.remove();
};

export const downloadFile = (requestId: string, fileId: number) => {
  return downloadFileByLink(
    `https://${process.env.REACT_APP_API_HOST}${DownloadFile.url(requestId, fileId.toString())}`
  );
};

export const downloadCbdTemplate = () => {
  return downloadFileByLink(
    `${process.env.REACT_APP_CBD_API_HOST}/api/templates/latest-download`,
    'cbd-template'
  );
};

export const downloadEdcTemplate = () => {
  return downloadFileByLink(
    `${process.env.REACT_APP_CBD_API_HOST}/api/templates/external`,
    'ext-data-template'
  );
};

export const getPercentage = (value: number, sum: number) => {
  return Number(((value * 100) / sum).toFixed(2));
};
export const parseDate = (date: Date, locale: Locale): string => {
  return format(date, 'P', { locale });
};

export const parseDateForMozaicComponent = (
  date: Date | string,
  customFormat = 'yyyy-MM-dd'
): string => {
  return format(date, customFormat);
};

export const parseDateTime = (date: Date, locale: Locale): string => {
  return format(date, 'Pp', { locale });
};

export const getTagValueForDate = (tag: IFilter<string>, locale: Locale): string => {
  const { value, label } = tag;
  let tagValue = '';

  const [start, end] = value;

  const startDate = new Date(start);
  const endDate = new Date(end);

  if (startDate) {
    tagValue = `${label}: ${parseDate(startDate, locale)}`;
  }

  if (endDate) {
    const parseEndDate =
      parseDate(endDate, locale) === parseDate(startDate, locale)
        ? ''
        : ` - ${parseDate(endDate, locale)}`;

    tagValue += parseEndDate;
  }

  return tagValue;
};
