import React, { useCallback, useState } from 'react';
import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableContainer,
  TableBody,
  TableCell,
  Loader,
  Flex,
  View,
  Text,
  DataTableFooter,
  Pagination,
  Select,
  CheckBox,
  TMagicUnit,
  TableExpandButton,
} from '@mozaic-ds/react';
import { useAppTranslations } from 'translations';
import classNames from 'classnames';
import { IUsePagination } from 'hook/usePagination';
import { IDataTableColumn } from 'common/types';
import { paginationSizeOptions } from 'common/consts';
import { IUseScrollPagination, useScrollPagination } from 'hook/useScrollPagination';
import styles from '../../CbdView.module.scss';
import { ExtendsColumns, ExtendsSortConfig, ExtendsSortedField, ExtendsTableRows } from '../consts';

interface ColumnsFilter<Columns extends ExtendsColumns> {
  key: Columns;
  isShown: boolean;
}

interface ITableProps<Columns extends ExtendsColumns, TableRows extends ExtendsTableRows>
  extends IColumnsTableProps<Columns, TableRows> {
  isLoading: boolean;
  rows: Array<ExtendsTableRows>;
  className?: string;
  customRowClick?: (row: ExtendsTableRows) => void;
  customChildrenRowClick?: (row: ExtendsTableRows) => void;
  pagination?: IUsePagination;
  scrollPagination?: IUseScrollPagination;
  infinityScroll?: boolean;
}

export interface IColumnsTableProps<Columns extends ExtendsColumns, TableRows> {
  columns: Record<Columns, IDataTableColumn<TableRows>>;
  sortConfig?: {
    [key in Columns]: ExtendsSortConfig;
  };
  sortedField?: ExtendsSortedField;
  columnsFilter: ColumnsFilter<Columns>[];
  selectedRowsId?: string[];
  setSelectedRowsId?: (selected: string[]) => void;
  mainCheckboxHandler?: () => void;
  withCheckboxes?: boolean;
  paginationVisible?: boolean;
  emptyDataText?: string;
}

const CbdTable = <Columns extends ExtendsColumns, TableRows extends ExtendsTableRows>({
  sortConfig,
  columns,
  columnsFilter,
  sortedField,
  selectedRowsId,
  withCheckboxes,
  className,
  setSelectedRowsId,
  mainCheckboxHandler,
  rows,
  isLoading,
  pagination,
  scrollPagination,
  customRowClick,
  customChildrenRowClick,
  paginationVisible,
  emptyDataText = 'noData',
}: ITableProps<Columns, TableRows>) => {
  const translation = useAppTranslations();
  const [expired, setExpired] = useState<string[]>([]);
  const { ref } = useScrollPagination({ scrollPagination });

  const onRowClick = useCallback(
    (row: ExtendsTableRows) => {
      if (setSelectedRowsId && selectedRowsId) {
        const isExist = selectedRowsId.find((item) => item === row.id?.toString());

        if (isExist) {
          setSelectedRowsId(selectedRowsId.filter((item) => item !== row.id?.toString()));
          return;
        }

        setSelectedRowsId([...selectedRowsId, row.id?.toString() || '']);
      }
    },
    [selectedRowsId, setSelectedRowsId]
  );

  const onExpiredClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: string) => {
    e.stopPropagation();
    setExpired((state) => {
      if (state.includes(value)) {
        return state.filter((item) => item !== value);
      }

      return [...state, value];
    });
  };

  const renderChildrenRow = (row: ExtendsTableRows, level: number): JSX.Element[] | null => {
    const { childrens } = row;

    return childrens
      ? childrens?.map((childrenRow) => (
          <>
            <TableRow
              key={childrenRow.id?.toString() || ''}
              className={classNames({
                [styles.selected]: selectedRowsId?.find(
                  (item) => item === childrenRow.id?.toString()
                ),
              })}
              onClick={() =>
                customChildrenRowClick
                  ? customChildrenRowClick(childrenRow)
                  : onRowClick(childrenRow)
              }
            >
              <TableCell
                key={`${childrenRow.id}-story-action-column`}
                className="story-action-column"
              >
                <View paddingLeft={`mu${level * 100}` as TMagicUnit}>
                  {childrenRow.childrens?.length ? (
                    <TableExpandButton
                      className={classNames({
                        [styles.rotatedButton]: expired.includes(childrenRow.id?.toString() || ''),
                      })}
                      onClick={(e) => onExpiredClick(e, childrenRow.id?.toString() || '')}
                    />
                  ) : (
                    <Flex justifyContent="center" className={styles.emptyButton}>
                      -
                    </Flex>
                  )}
                </View>
              </TableCell>
              {columnsFilter.map(({ key, isShown }) => {
                return isShown ? (
                  <TableCell key={`row-${childrenRow.id}-${key}`}>
                    {columns[key].render(childrenRow as TableRows)}
                  </TableCell>
                ) : null;
              })}
            </TableRow>
            {expired.includes(childrenRow.id?.toString() || '') &&
              renderChildrenRow(childrenRow, level + 1)}
          </>
        ))
      : null;
  };

  return (
    <TableContainer className={classNames('mc-datatable--sticky-header', styles.table, className)}>
      <Table>
        <TableHeader>
          <TableRow>
            {withCheckboxes && (
              <TableHeaderCell key="checkbox-cell" className="story-action-column">
                {mainCheckboxHandler && (
                  <CheckBox
                    onClick={(e) => {
                      e.stopPropagation();
                      mainCheckboxHandler();
                    }}
                    isChecked={rows.length > 0 && (selectedRowsId || []).length === rows.length}
                  />
                )}
              </TableHeaderCell>
            )}
            {rows.find((item) => item.childrens) && (
              <TableHeaderCell key="story-action-column" className="story-action-column" />
            )}
            {columnsFilter.map(({ key, isShown }) => {
              return isShown ? (
                <TableHeaderCell
                  key={key}
                  isSortable={columns[key].isSortable}
                  onSort={columns[key].onSort}
                  sortDirection={
                    sortedField && sortConfig && sortConfig[key] === sortedField.field
                      ? sortedField.sortDirection
                      : undefined
                  }
                >
                  {columns[key].message}
                </TableHeaderCell>
              ) : null;
            })}
          </TableRow>
        </TableHeader>
        <TableBody>
          {rows.length > 0 ? (
            rows.map((row) => {
              return (
                <>
                  <TableRow
                    key={row.id?.toString() || ''}
                    className={classNames({
                      [styles.selected]: selectedRowsId?.find(
                        (item) => item === row.id?.toString()
                      ),
                    })}
                    onClick={() => (customRowClick ? customRowClick(row) : onRowClick(row))}
                  >
                    {withCheckboxes && (
                      <TableCell key={`${row.id}-checkbox-cell`} className="story-action-column">
                        <CheckBox
                          onClick={(e) => {
                            e.stopPropagation();
                            if (customRowClick) {
                              customRowClick(row);
                              return;
                            }
                            onRowClick(row);
                          }}
                          isChecked={Boolean(
                            selectedRowsId?.find((item) => item === row.id?.toString())
                          )}
                        />
                      </TableCell>
                    )}
                    {row.childrens && (
                      <TableCell
                        key={`${row.id}-story-action-column`}
                        className="story-action-column"
                      >
                        {row.childrens?.length ? (
                          <TableExpandButton
                            className={classNames({
                              [styles.rotatedButton]: expired.includes(row.id?.toString() || ''),
                            })}
                            onClick={(e) => onExpiredClick(e, row.id?.toString() || '')}
                          />
                        ) : (
                          <Flex justifyContent="center" className={styles.emptyButton}>
                            -
                          </Flex>
                        )}
                      </TableCell>
                    )}
                    {columnsFilter.map(({ key, isShown }) => {
                      return isShown ? (
                        <TableCell key={`row-${row.id}-${key}`}>
                          {columns[key].render(row as TableRows)}
                        </TableCell>
                      ) : null;
                    })}
                  </TableRow>
                  {expired.includes(row.id?.toString() || '') && renderChildrenRow(row, 1)}
                </>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={13}>
                <View margin="mu200">
                  <Flex justifyContent="center" alignItems="center" direction="column">
                    {isLoading ? <Loader /> : <Text as="span">{translation(emptyDataText)}</Text>}
                  </Flex>
                </View>
              </TableCell>
            </TableRow>
          )}
          {scrollPagination && ref && (
            <TableRow className={styles.scrollPaginationRow}>
              <TableCell colSpan={13}>
                <div ref={ref} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {paginationVisible && pagination && (
        <DataTableFooter>
          <Flex justifyContent="space-between" className={styles.footerTableWrapper}>
            <Flex alignItems="center">
              <Text className={classNames(styles.footerTableText, 'mu-mr-100')}>
                {translation('rowsPerPage')}
              </Text>
              <Select
                options={paginationSizeOptions}
                value={pagination.size}
                onChange={(e) => pagination.onSizeChange(Number(e.target.value))}
              />
              <Text className={classNames(styles.footerTableText, 'mu-ml-100')}>
                {pagination.getPaginationInfo()}
              </Text>
            </Flex>
            <Pagination
              {...pagination.paginationProps}
              onChange={pagination.onPageChange}
              onNext={pagination.onNext}
              onPrevious={pagination.onPrevious}
            />
          </Flex>
        </DataTableFooter>
      )}
    </TableContainer>
  );
};

export default CbdTable;
