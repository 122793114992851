import React, { FC } from 'react';
import { Button, Flex, Notification, Text, Tooltip, Loader } from '@mozaic-ds/react';
import { DownloadWeb24, NotificationInformation24 } from '@mozaic-ds/icons/react';
import cn from 'classnames';
import { useAppTranslations } from 'translations';
import { useDownloadTemplate } from './hooks';
import styles from './DownloadingTemplate.module.scss';

type Props = {
  id: string;
  label: string;
  text: string;
  isHideNotification?: boolean;
};

export const DownloadingTemplate: FC<Props> = (props) => {
  const translations = useAppTranslations();

  const { id, children, label, text, isHideNotification } = props;
  const { isLoading, downloadTemplate } = useDownloadTemplate(id);

  return (
    <>
      {!isHideNotification && (
        <Notification className="mu-mb-125" message={translations('templateNotification')} />
      )}
      <Flex className="mu-mb-050" alignItems="flex-end">
        <Text size="s">{translations(text)}</Text>
        <Tooltip label={translations(label)} placement="top" className="mu-ml-025">
          <NotificationInformation24 />
        </Tooltip>
      </Flex>
      <Flex>
        <Button
          leftIcon={isLoading ? <Loader className={styles.loader} /> : <DownloadWeb24 />}
          variant="bordered"
          size="m"
          className={cn(styles.download, 'mu-mb-100 mu-mr-100')}
          onClick={downloadTemplate}
          isDisabled={isLoading}
        >
          {translations('downloadTemplate')}
        </Button>
        {children}
      </Flex>
    </>
  );
};
