import { GetCurrentLanguageResponse } from '@ppm/ppm-platform-api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCurrentLanguage } from 'api/language';
import { setFailure, setLoading } from '../helpers';
import { RequiredStateFields, RootState } from '../types';
import { useAppSelector } from '../hooks';

type TranslationsData = GetCurrentLanguageResponse['data'];

interface SliceState extends RequiredStateFields {
  translations: TranslationsData;
}

export const getTranslations = createAsyncThunk<GetCurrentLanguageResponse>(
  'languages/getCurrentLanguage',
  async (_, { rejectWithValue }) => {
    try {
      return await getCurrentLanguage({});
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const translationsSlice = createSlice({
  initialState: { translations: {}, isLoading: false, isFailure: false },
  name: 'translations',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTranslations.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(getTranslations.fulfilled, (state: SliceState, action) => {
        state.translations = action.payload.data;
        state.isLoading = false;
        state.isFailure = false;
      })
      .addCase(getTranslations.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const useTranslationsData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.languages.translations) };
};
