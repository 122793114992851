import React, { FC } from 'react';
import { Badge, Flex, Text, Tooltip } from '@mozaic-ds/react';
import { NotificationQuestion24 } from '@mozaic-ds/icons/react';
import { SubrequestContainerWithRights } from '@ppm/ppm-platform-api';
import { BusinessTag, RequestStatusCode } from '@ppm/ppm-platform-request-service';
import { getRequestStatusBadgeTheme } from 'common/utils';
import { PriceDownTag } from 'components/PriceDownTag/PriceDownTag';
import { useAppTranslations } from 'translations';
import { useGetResponsibilities } from './hooks';
import styles from './SubRequests.module.scss';

type Props = {
  container: SubrequestContainerWithRights;
};
export const SubRequest: FC<Props> = ({ container }) => {
  const translations = useAppTranslations();

  const { statusName, statusId, containerNumber, tags, statusCode } = container;
  const { responsibilities } = useGetResponsibilities(container);

  return (
    <Flex direction="column" padding="mu100">
      <Flex justifyContent="space-between" alignItems="center" marginBottom="mu050">
        <Flex justifyContent="center">
          <Text weight="semi-bold" theme="dark">
            n° {containerNumber}
          </Text>
          <Badge theme={getRequestStatusBadgeTheme(statusId)} className="mu-ml-050">
            {statusName}
          </Badge>
          {statusCode === RequestStatusCode.SendPricelistForSignature && (
            <Tooltip
              label={translations('automaticPriceSendingToRmsInfo')}
              placement="top"
              className="mu-ml-025"
            >
              <NotificationQuestion24 className="mu-ml-025" />
            </Tooltip>
          )}
          {tags && !tags.includes(BusinessTag.HasNewPrices) && (
            <Badge theme="warning" className="mu-ml-050">
              {translations('priceHaveNoChanged')}
            </Badge>
          )}
          {tags && tags.includes(BusinessTag.Decrease) && <PriceDownTag />}
        </Flex>
      </Flex>
      <Flex direction="column">
        {responsibilities.map(([roleName, users]) => (
          <Flex key={roleName}>
            <Text size="s" theme="light" className={styles.with}>
              {roleName}
            </Text>
            <Text size="s">{users.join(', ')}</Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
