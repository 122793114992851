import React, { useCallback, useEffect } from 'react';
import { Button, IconButton, Select, TextInput, View } from '@mozaic-ds/react';
import Flatpickr from 'react-flatpickr';
import { DisplayFilter24, ControlMore24 } from '@mozaic-ds/icons/react';
import Layer from 'components/Layer/Layer';
import { useAppTranslations } from 'translations';
import { useFiltersForm } from 'pages/cbd/ExternalSource/hooks/filters';
import { externalSourceFiltersName } from 'pages/cbd/ExternalSource/hooks/consts';
import FieldWrapper from 'components/FieldWrapper/FieldWrapper';
import {
  clearExtDataNamesSlice,
  extDataNamesList,
  setSearchValue,
  useExtDataNames,
} from 'store/cbd/extDataNames';
import { Controller } from 'react-hook-form';
import { useScheduler } from 'store/cbd/scheduler/scheduler';
import { useExternalSourceFiltersData } from 'store/cbd/externalData/analysis/filters';
import classNames from 'classnames';
import { useAppDispatch } from 'store/hooks';
import { Dividers } from 'components/Dividers/Dividers';
import SearchInput from 'components/SearchInput/SearchInput';
import useCostTypesOptions from 'pages/cbd/hooks/useCostTypesOptions';
import { MultipleDataNames } from 'pages/cbd/ExternalSource/ExternalSource.types';
import {
  clearRegionsSlice,
  getRegionsList,
  setSearchValue as setRegionSearchValue,
  useRegions,
} from 'store/cbd/regions';
import useDebounce from 'hook/useDebounce';
import { useMeasureUnits } from 'store/cbd/measureUnits';
import styles from '../../CbdView.module.scss';

const ExternalSourceFiltersPopup = () => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();
  const { data: sourceOptions } = useScheduler();
  const { deletedFilters } = useExternalSourceFiltersData().data;
  const {
    form,
    name2,
    externalSourceDate,
    value,
    region,
    measure,
    infoGrade,
    infoDiameter,
    infoThickness,
    infoContract,
    infoStandard,
    priceTypeName,
    onSubmit,
  } = useFiltersForm();
  const { categoryCostTypesOptions, getTypeCostTypesOptions, getSubtypeCostTypesOptions } =
    useCostTypesOptions();
  const { list, searchValue } = useExtDataNames();
  const { list: regionList, searchValue: regionSearchValue } = useRegions();
  const { list: measureUnitList } = useMeasureUnits();
  const nameQueryValue = useDebounce(searchValue, 1000);
  const regionQueryValue = useDebounce(regionSearchValue, 1000);

  useEffect(() => {
    dispatch(
      extDataNamesList({
        query: nameQueryValue,
      })
    );
  }, [dispatch, nameQueryValue]);

  useEffect(() => {
    dispatch(
      getRegionsList({
        query: regionQueryValue,
      })
    );
  }, [dispatch, regionQueryValue]);

  useEffect(() => {
    return () => {
      dispatch(clearExtDataNamesSlice());
      dispatch(clearRegionsSlice());
    };
  }, [dispatch]);

  const clearMultipleData = useCallback(
    (id: MultipleDataNames) => {
      form.getValues('multipleData').forEach((_, index) => {
        form.setValue(`multipleData.${index}.${id}`, '');
      });
    },
    [form]
  );

  useEffect(() => {
    deletedFilters.forEach((id) => {
      if (id === 'multipleData') {
        form.setValue(id, [
          {
            source: '',
            categoryId: '',
            typeId: '',
            subtypeId: '',
            name: '',
          },
        ]);
        return;
      }

      if (id.includes('source')) {
        clearMultipleData('source');
      }

      if (id.includes('categoryId')) {
        clearMultipleData('categoryId');
      }

      if (id.includes('typeId')) {
        clearMultipleData('typeId');
      }

      if (id.includes('subtypeId')) {
        clearMultipleData('subtypeId');
      }

      if (id.includes('name')) {
        clearMultipleData('name');
      }

      if (id === 'externalSourceDate') {
        form.setValue(id, []);
        return;
      }

      form.setValue(id, '');
    });
  }, [clearMultipleData, deletedFilters, form]);

  const onResetItems = () => {
    form.reset();
    externalSourceFiltersName.forEach((id) => {
      if (id === 'multipleData') {
        form.setValue(id, [
          {
            source: '',
            categoryId: '',
            typeId: '',
            subtypeId: '',
            name: '',
          },
        ]);
        return;
      }

      if (id === 'externalSourceDate') {
        form.setValue(id, []);
        return;
      }

      form.setValue(id, '');
    });
  };

  return (
    <Layer
      id="filtersPopup"
      icon={<DisplayFilter24 fill="#71706b" />}
      title={translations('filterPopupHeader')}
      resetItems={onResetItems}
      saveItems={onSubmit}
      button={
        <Button leftIcon={<DisplayFilter24 />} variant="bordered" size="s">
          {translations('filters')}
        </Button>
      }
    >
      <View as="form" paddingLeft="mu025" paddingRight="mu025">
        <fieldset className={classNames('mu-mt-100', 'mu-p-100', styles.fieldset)}>
          <legend>{translations('nomenclatureOfRawMaterials')}</legend>
          {form.getValues().multipleData.map((item, index) => (
            <>
              {index !== 0 && <Dividers />}
              <FieldWrapper
                className="mu-mb-100"
                label={`${translations('source')}*`}
                id={`multipleData.${index}.source`}
              >
                <Controller
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder={translations('chooseAnOption')}
                      options={sourceOptions.map((elem) => ({
                        value: elem.id,
                        label: elem.name,
                      }))}
                    />
                  )}
                  name={`multipleData.${index}.source`}
                  control={form.control}
                />
              </FieldWrapper>
              <FieldWrapper
                className="mu-mb-100"
                label={`${translations('category')}*`}
                id={`multipleData.${index}.categoryId`}
              >
                <Controller
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder={translations('chooseAnOption')}
                      options={categoryCostTypesOptions}
                    />
                  )}
                  name={`multipleData.${index}.categoryId`}
                  control={form.control}
                />
              </FieldWrapper>
              <FieldWrapper
                className="mu-mb-100"
                label={`${translations('type')}*`}
                id={`multipleData.${index}.typeId`}
              >
                <Controller
                  render={({ field }) => (
                    <Select
                      {...field}
                      isDisabled={!item.categoryId}
                      placeholder={translations('chooseAnOption')}
                      options={getTypeCostTypesOptions(item.categoryId)}
                    />
                  )}
                  name={`multipleData.${index}.typeId`}
                  control={form.control}
                />
              </FieldWrapper>
              <FieldWrapper
                className="mu-mb-100"
                label={`${translations('subType')}*`}
                id={`multipleData.${index}.subtypeId`}
              >
                <Controller
                  render={({ field }) => (
                    <Select
                      {...field}
                      isDisabled={!item.typeId}
                      placeholder={translations('chooseAnOption')}
                      options={getSubtypeCostTypesOptions(item.categoryId, item.typeId)}
                    />
                  )}
                  name={`multipleData.${index}.subtypeId`}
                  control={form.control}
                />
              </FieldWrapper>
              <FieldWrapper
                className="mu-mb-100"
                label={`${translations('nomination')}*`}
                id={`multipleData.${index}.name`}
              >
                <Controller
                  render={({ field }) => (
                    <SearchInput
                      {...field}
                      options={list}
                      onChange={(nameValue) => {
                        field.onChange(nameValue);
                        dispatch(setSearchValue(nameValue));
                      }}
                    />
                  )}
                  name={`multipleData.${index}.name`}
                  control={form.control}
                />
              </FieldWrapper>
            </>
          ))}
          <IconButton
            type="button"
            size="s"
            className={styles.addButton}
            onClick={(e) => {
              e.preventDefault();
              form.setValue('multipleData', [
                ...form.getValues().multipleData,
                {
                  source: '',
                  categoryId: '',
                  typeId: '',
                  subtypeId: '',
                  name: '',
                },
              ]);
            }}
          >
            <ControlMore24 />
          </IconButton>
        </fieldset>
        <FieldWrapper
          className="mu-mb-100"
          label={`${translations('period')}*`}
          id="externalSourceDate"
        >
          <Flatpickr
            {...externalSourceDate}
            className="mc-text-input"
            options={{
              mode: 'range',
              dateFormat: 'm/d/Y',
              locale: { firstDayOfWeek: 1 },
            }}
            placeholder={translations('chooseAnOption')}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('newNomination')} id="name2">
          <TextInput {...name2} size="m" placeholder={translations('newNomination')} />
        </FieldWrapper>
        <FieldWrapper label={translations('value')} id="value">
          <TextInput {...value} size="m" placeholder={translations('value')} />
        </FieldWrapper>
        <FieldWrapper label={translations('Регион')} id="region">
          <SearchInput
            {...region}
            options={regionList}
            onChange={(regionValue) => {
              region.onChange(regionValue);
              dispatch(setRegionSearchValue(regionValue));
            }}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('unit')} id="measure">
          <Select
            {...measure}
            size="m"
            placeholder={translations('chooseAnOption')}
            options={measureUnitList}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('mark')} id="infoGrade">
          <TextInput {...infoGrade} size="m" placeholder={translations('mark')} />
        </FieldWrapper>
        <FieldWrapper label={translations('diameter')} id="infoDiameter">
          <TextInput {...infoDiameter} size="m" placeholder={translations('diameter')} />
        </FieldWrapper>
        <FieldWrapper label={translations('thickness')} id="infoThickness">
          <TextInput {...infoThickness} size="m" placeholder={translations('thickness')} />
        </FieldWrapper>
        <FieldWrapper label={translations('contractType')} id="infoContract">
          <TextInput {...infoContract} size="m" placeholder={translations('contractType')} />
        </FieldWrapper>
        <FieldWrapper label={translations('materialStandard')} id="infoStandard">
          <TextInput {...infoStandard} size="m" placeholder={translations('materialStandard')} />
        </FieldWrapper>
        <FieldWrapper label={translations('priceType')} id="priceTypeName">
          <TextInput {...priceTypeName} size="m" placeholder={translations('priceType')} />
        </FieldWrapper>
      </View>
    </Layer>
  );
};

export default ExternalSourceFiltersPopup;
