import { Translations } from 'translations';
import { CompareFilterName } from '../CompareTable.types';

export enum CompareColumns {
  ID = 'id',
  DATE_CBD = 'dateCbd',
  SUPPLIER_NAME = 'supplierName',
  SUPPLIER_ID = 'supplierId',
  GROUP = 'group',
  DEP_NAME = 'depName',
  SUB_DEP_NAME = 'subDepName',
  CLASS_NAME = 'className',
  SUB_CLASS_NAME = 'subclassName',
}

export const getLabel = (key: CompareFilterName, t: Translations) => {
  return {
    cbdSupplierId: t('supplier'),
    cbdDate: t('dateCbd'),
    itemGroup: t('devision'),
    itemDep: t('subDevision'),
    itemClass: t('type'),
    itemSubclass: t('subType'),
  }[key];
};

export const compareFiltersName: CompareFilterName[] = [
  'cbdSupplierId',
  'cbdDate',
  'itemGroup',
  'itemDep',
  'itemClass',
  'itemSubclass',
];
