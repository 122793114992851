import { configureStore } from '@reduxjs/toolkit';
import { replacement } from './replacement';
import { subrequests } from './subrequests';
import { cbd } from './cbd';
import { auth } from './auth';
import { dictionary } from './dictionary';
import { requests } from './requests';
import { request } from './request';
import { languages } from './languages';
import { dashboard } from './dashboard';
import { roles } from './roles';
import { buyerSiteGroup } from './buyerSiteGroup';
import { users } from './users';
import { priceList } from './priceList';
import { chatMessages } from './chat';
import { requestCreation } from './requestCreation';
import { modalRate } from './modalRate';

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development' ? { trace: true } : false,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  reducer: {
    auth,
    buyerSiteGroup,
    cbd,
    dashboard,
    dictionary,
    languages,
    roles,
    replacement,
    request,
    requestCreation,
    requests,
    users,
    subrequests,
    priceList,
    chatMessages,
    modalRate,
  },
});
