import React from 'react';
import { Loader, Text, View } from '@mozaic-ds/react';
import { useAppTranslations } from 'translations';
import { useCurrentPriceListData } from 'store/priceList/currentPriceList';
import { SubrequestNumberElement } from 'components/SubrequestNumberElement/SubrequestNumberElement';
import { useRequestData } from 'store/request/request';
import styles from '../PriceList.module.scss';

export const Structure = () => {
  const translations = useAppTranslations();

  const { priceList, isLoading } = useCurrentPriceListData().data;
  const { containers } = useRequestData().data;

  return (
    <View
      className={styles.wrapper}
      shadow="s"
      radius="l"
      paddingTop="mu150"
      paddingBottom="mu200"
      paddingRight="mu200"
      paddingLeft="mu200"
    >
      <Text weight="semi-bold" theme="dark" size="xl" as="p" className="mu-mb-125">
        {translations('priceListStructure')}
      </Text>
      {isLoading && <Loader />}
      {!isLoading && (
        <SubrequestNumberElement
          data={priceList.containers}
          containers={containers}
          textProps={{ theme: 'dark', weight: 'semi-bold' }}
          iconProps={{ className: 'mu-mr-050' }}
        />
      )}
    </View>
  );
};
