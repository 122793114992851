import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSubscriptionsReq, IGetSubscriptions, ISubscription } from 'api/cbd/scheduler';
import { initPageable } from 'common/consts';
import { IPageSize } from 'common/cbd/types';
import { useAppSelector } from 'store/hooks';
import { RootState } from '../../../types';
import { emptySubscriptionsRow } from './consts';

interface SliceState extends IGetSubscriptions {
  isAdd: boolean;
  editItems: string[];
  selectedRowsId: string[];
}

const initialState: SliceState = {
  isAdd: false,
  editItems: [],
  selectedRowsId: [],
  data: [],
  pageable: initPageable,
};

interface IChangeTableRowPayload {
  id: number;
  fieldName: keyof ISubscription;
  value: string;
}

export const getSubscriptionsData = createAsyncThunk(
  'cbd/scheduler/getSubscriptionsData',
  async (payload: IPageSize, { rejectWithValue }) => {
    try {
      return await getSubscriptionsReq(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const subscriptionsListSlice = createSlice({
  initialState,
  name: 'subscriptionsList',
  reducers: {
    setEditItems: (state, action: PayloadAction<string[]>) => {
      state.editItems = action.payload;
    },
    setIsAdd: (state, action: PayloadAction<boolean>) => {
      state.isAdd = action.payload;
      if (action.payload) {
        state.data = [emptySubscriptionsRow, ...state.data];
        state.editItems = ['-1'];
      }
    },
    setSelectedRowsId: (state, action: PayloadAction<string[]>) => {
      state.selectedRowsId = action.payload;
    },
    changeTableRow: (state, action: PayloadAction<IChangeTableRowPayload>) => {
      const rowIndex = state.data.findIndex((item) => item.id === action.payload.id);

      state.data[rowIndex] = {
        ...state.data[rowIndex],
        [action.payload.fieldName]: action.payload.value,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getSubscriptionsData.fulfilled,
      (state: SliceState, action: PayloadAction<IGetSubscriptions>) => {
        state.data = action.payload.data;
        state.pageable = action.payload.pageable;
      }
    );
  },
});

export const { setSelectedRowsId, setEditItems, setIsAdd, changeTableRow } =
  subscriptionsListSlice.actions;

export const useSubscriptionsList = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.scheduler.subscriptions.list);
};
