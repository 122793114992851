import React from 'react';
import { Loader } from '@mozaic-ds/react';
import { useMount } from 'hook/useMount';
import { getDueDate } from 'store/dictionary/dueDate';
import { resetStore as resetRequestStore, useRequestData } from 'store/request/request';
import { getReasons, useReasonsData } from 'store/dictionary/reasons';
import { getSupplierIds, clear } from 'store/dictionary/supplierIds';
import { useAppDispatch } from 'store/hooks';
import { useAppTranslations } from 'translations';
import { getRolesData } from 'store/roles/roles';
import { Request } from './Request';

export const RequestContainer = ({ id }: { id: string }) => {
  const dispatch = useAppDispatch();
  const t = useAppTranslations();

  const { reasons } = useReasonsData().data;
  const { request, isLoading } = useRequestData().data;

  useMount(() => {
    dispatch(getDueDate());
    if (reasons.length === 0) dispatch(getReasons(t));
    dispatch(getSupplierIds());
    dispatch(getRolesData());

    return () => {
      dispatch(clear());
      dispatch(resetRequestStore());
    };
  });

  if (isLoading) return <Loader />;
  if (!(request && id)) return null;

  return <Request requestId={id} />;
};
