import React from 'react';
import { Button, IconButton, Select, Toggle, View } from '@mozaic-ds/react';
import { DisplayFilter24, ControlMore24 } from '@mozaic-ds/icons/react';
import classNames from 'classnames';
import Layer from 'components/Layer/Layer';
import { useAppTranslations } from 'translations';
import FieldWrapper from 'components/FieldWrapper/FieldWrapper';
import { Dividers } from 'components/Dividers/Dividers';
import useCostTypesOptions from 'pages/cbd/hooks/useCostTypesOptions';
import { Controller } from 'react-hook-form';
import { usePurchasePriceFiltersForm } from './usePurchasePriceFiltersForm';
import styles from '../../Comparison.module.scss';

const PurchasePriceFilters = (): JSX.Element => {
  const translations = useAppTranslations();
  const { form, withCurrencies, onSubmit } = usePurchasePriceFiltersForm();
  const { categoryCostTypesOptions, getTypeCostTypesOptions, getSubtypeCostTypesOptions } =
    useCostTypesOptions();

  return (
    <Layer
      id="purchasePriceFilterPopup"
      icon={<DisplayFilter24 fill="#71706b" />}
      title={translations('filterPopupHeader')}
      resetItems={() => {
        form.reset();
      }}
      saveItems={onSubmit}
      button={
        <Button leftIcon={<DisplayFilter24 />} variant="bordered" size="s">
          {translations('filters')}
        </Button>
      }
    >
      <View as="form" paddingLeft="mu025" paddingRight="mu025">
        <fieldset className={classNames('mu-mt-100', 'mu-p-100', styles.fieldset)}>
          <legend>{translations('nomenclatureOfRawMaterials')}</legend>
          {form.getValues().traceableComponent.map((item, index) => (
            <>
              {index !== 0 && <Dividers />}
              <FieldWrapper
                className="mu-mb-100"
                label={translations('category')}
                id={`traceableComponent.${index}.categoryId`}
              >
                <Controller
                  render={({ field }) => (
                    <Select {...field} options={categoryCostTypesOptions} placeholder=" " />
                  )}
                  name={`traceableComponent.${index}.categoryId`}
                  control={form.control}
                />
              </FieldWrapper>
              <FieldWrapper
                className="mu-mb-100"
                label={translations('type')}
                id={`traceableComponent.${index}.typeId`}
              >
                <Controller
                  render={({ field }) => (
                    <Select
                      {...field}
                      isDisabled={!item.categoryId}
                      options={getTypeCostTypesOptions(item.categoryId)}
                      placeholder=" "
                    />
                  )}
                  name={`traceableComponent.${index}.typeId`}
                  control={form.control}
                />
              </FieldWrapper>
              <FieldWrapper
                className="mu-mb-100"
                label={translations('subType')}
                id={`traceableComponent.${index}.subtypeId`}
              >
                <Controller
                  render={({ field }) => (
                    <Select
                      {...field}
                      isDisabled={!item.typeId}
                      options={getSubtypeCostTypesOptions(item.categoryId, item.typeId)}
                      placeholder=" "
                    />
                  )}
                  name={`traceableComponent.${index}.subtypeId`}
                  control={form.control}
                />
              </FieldWrapper>
            </>
          ))}
          <IconButton
            size="s"
            className={styles.addButton}
            onClick={(e) => {
              e.preventDefault();
              form.setValue('traceableComponent', [
                ...form.getValues().traceableComponent,
                {
                  categoryId: '',
                  typeId: '',
                  subtypeId: '',
                },
              ]);
            }}
          >
            <ControlMore24 />
          </IconButton>
        </fieldset>
        <FieldWrapper label={translations('basedOnExchangeRate')} id="withCurrencies">
          <Toggle
            {...withCurrencies}
            value={withCurrencies.name}
            isChecked={withCurrencies.value}
          />
        </FieldWrapper>
      </View>
    </Layer>
  );
};

export default PurchasePriceFilters;
