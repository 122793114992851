import React from 'react';
import { Flex } from '@mozaic-ds/react';
import { FileTabs } from 'components/FileTabs/FileTabs';
import { CbdUploader } from 'pages/CreatingRequest/Steps/FileContainer/CbdForm/CbdUploader';
import { getLoadList, useLoadList } from 'store/cbd/uploads/loadList';
import { useMount } from 'hook/useMount';
import { useAppDispatch } from 'store/hooks';
import { OtherFileUploader } from './OtherFileUploader/OtherFileUploader';

type RequestFileBlockProps = {
  id: string;
};
export const RequestFileBlock = ({ id }: RequestFileBlockProps) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useLoadList();

  useMount(() => dispatch(getLoadList({ reqId: id, page: 1, size: 100 })));
  return (
    <Flex direction="column" marginTop="mu150" shadow="s" radius="l">
      <FileTabs
        id={id}
        data={{
          initialTabName: 'uploadCBD',
          tabs: [
            isLoading
              ? null
              : {
                  name: 'uploadCBD',
                  message: 'uploadCBD',
                  component: <CbdUploader id={id} accessibilityToRedirect />,
                },
            {
              name: 'otherFiles',
              message: 'otherDocuments',
              component: <OtherFileUploader id={id} />,
            },
          ],
        }}
      />
    </Flex>
  );
};
