import React, { FC } from 'react';
import { TabsContextProvider } from '@mozaic-ds/react/lib/components/Tabs/TabsContext';
import { useRoles } from 'store/roles/roles';

const RoleTabsWrap: FC = ({ children }) => {
  const { linkableRoles } = useRoles().data;
  return (
    <TabsContextProvider defaultTab={linkableRoles[0]?.id.toString()}>
      {children}
    </TabsContextProvider>
  );
};
export default RoleTabsWrap;
