import { changeTemplateStructure } from 'api/cbd/templates';
import { useCallback } from 'react';
import { useUserData } from 'store/auth/user';
import { UseFormReturn } from 'react-hook-form';
import { useNotifications } from '@mozaic-ds/react';
import {
  clearAdditionEditTemplateConfig,
  setAdditionEditTemplateConfig,
  useAdditionEditTemplateConfig,
} from 'store/cbd/additionEditTemplateConfig';
import { useAppTranslations } from 'translations';
import { useAppDispatch } from 'store/hooks';
import { IAdditionalTemplateForm } from './useEditTemplateForm';
import {
  onAddFormat,
  onAddRange,
  onColumnChange,
  onNameChange,
  onRowChange,
} from '../../../helpers';

const useEditTemplateActions = (form: UseFormReturn<IAdditionalTemplateForm>) => {
  const dispatch = useAppDispatch();
  const { danger, success } = useNotifications();
  const translations = useAppTranslations();
  const { data: config } = useAdditionEditTemplateConfig();
  const { data: userData } = useUserData().user;

  form.watch((data, { name, type }) => {
    if (type === 'change') {
      switch (name) {
        case 'row': {
          dispatch(setAdditionEditTemplateConfig(onRowChange(data.row || '', config)));
          break;
        }
        case 'column': {
          dispatch(setAdditionEditTemplateConfig(onColumnChange(data.column || '', config)));
          break;
        }
        case 'name': {
          dispatch(setAdditionEditTemplateConfig(onNameChange(data.name || '', config)));
          break;
        }
        case 'format': {
          dispatch(setAdditionEditTemplateConfig(onAddFormat(data.format || '', config)));
          break;
        }
        case 'range': {
          dispatch(setAdditionEditTemplateConfig(onAddRange(data.range || '', config)));
          break;
        }
        default: {
          break;
        }
      }
    }
  });

  const onAddStructureSubmit = useCallback(async () => {
    try {
      const { row, column, name, format, range } = config;

      if (row || column || name || format) {
        await changeTemplateStructure({
          userId: userData?.id,
          structure: { row, column, name, format, range },
        });

        success({
          title: translations('successNotification'),
          message: translations('changesSuccessCBDTemplate'),
        });
        return;
      }
      danger({
        title: translations('dangerNotification'),
        message: translations('unableChangesCBDTemplate'),
      });
    } catch {
      danger({
        title: translations('dangerNotification'),
        message: translations('invalidPasteOptions'),
      });
    }
  }, [config, danger, success, translations, userData?.id]);

  const onClear = () => {
    form.reset();
    dispatch(clearAdditionEditTemplateConfig());
  };

  return {
    onAddStructureSubmit,
    onClear,
  };
};

export default useEditTemplateActions;
