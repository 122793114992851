import { Translations } from 'translations';
import { FilterName } from '../ParseTable.types';

export enum TemplateStructureColumns {
  CODE = 'code',
  NAME = 'name',
  MAPPING_SUM = 'mappingSum',
  MAPPING_CUR = 'mappingCur',
  REQUIRED = 'required',
  UNIQUE = 'unique',
  ERR_REQUIRED_SUM_ID = 'errRequiredSumId',
  ERR_REQUIRED_CUR_ID = 'errRequiredCurId',
  ATTRIBUTES = 'attributes',
}

export const getLabel = (key: FilterName, t: Translations) => {
  return {
    costName: t('costName'),
    codeCost: t('codeCost'),
    costRequired: t('costRequired'),
    costUnique: t('costUnique'),
  }[key];
};

export const filtersName: FilterName[] = ['costName', 'codeCost', 'costRequired', 'costUnique'];
