import axios, { AxiosRequestConfig } from 'axios';
import {
  BodyType,
  Config,
  UrlType,
  BodyFileParameter,
  NameSpaceBaseImplementation,
} from 'api/utility/types';
import { apiClient } from 'api/utility/consts';
import { getUrl } from 'api/utility/helpers';

const cbdApiConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_CBD_API_HOST,
  withCredentials: true,
};

export const cbdApiClient = axios.create(cbdApiConfig);

export const apiCreator = <
  TNameSpace extends NameSpaceBaseImplementation<TUrl>,
  TResponse,
  TUrl = undefined,
  TBody = undefined
>(
  nameSpace: TNameSpace,
  options?: AxiosRequestConfig
) => {
  return {
    get: async ({ urlParameters, conf }: UrlType<TUrl> & Config) => {
      const { data } = await apiClient.get<TResponse>(getUrl<TUrl>(nameSpace.url, urlParameters), {
        ...conf,
        ...options,
      });
      return data;
    },
    post: async ({ urlParameters, conf, body }: UrlType<TUrl> & BodyType<TBody> & Config) => {
      const { data } = await apiClient.post<TResponse>(
        getUrl<TUrl>(nameSpace.url, urlParameters),
        body,
        {
          ...conf,
          ...options,
        }
      );
      return data;
    },
    put: async ({ urlParameters, conf, body }: UrlType<TUrl> & BodyType<TBody> & Config) => {
      const { data } = await apiClient.put<TResponse>(
        getUrl<TUrl>(nameSpace.url, urlParameters),
        body,
        {
          ...conf,
          ...options,
        }
      );
      return data;
    },
    delete: async ({ urlParameters, conf }: UrlType<TUrl> & Config<TBody>) => {
      const { data } = await apiClient.delete<TResponse>(
        getUrl<TUrl>(nameSpace.url, urlParameters),
        {
          ...conf,
          ...options,
        }
      );
      return data;
    },
    file: async ({ urlParameters, conf, file }: UrlType<TUrl> & BodyFileParameter & Config) => {
      const { data } = await apiClient.post<TResponse>(
        getUrl<TUrl>(nameSpace.url, urlParameters),
        file,
        {
          ...conf,
          ...options,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return data;
    },
  };
};
