import React, { FC, useEffect, useState } from 'react';
import { Flex, Text, View } from '@mozaic-ds/react';
import { ChatType } from '@ppm/ppm-platform-chat-service';
import { useAppTranslations } from 'translations';
import { isLmEnvironment } from 'common/consts';
import { getHasGrants } from 'common/utils';
import { useRequestData } from 'store/request/request';
import Tabs from 'components/Chat/Tabs';
import ManageChat from 'components/Chat/ManageChat';
import { getMessages } from 'store/chat/chat';
import { useAppDispatch } from 'store/hooks';
import Messages from 'components/Chat/Messages';
import styles from './Chat.module.scss';

type ChatProps = {
  id: string;
};

export const Chat: FC<ChatProps> = ({ id }) => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const { requestGrants } = useRequestData().data;

  const {
    RequestExternalChatRead,
    RequestInternalChatRead,
    RequestInternalChatWrite,
    RequestExternalChatWrite,
  } = getHasGrants(requestGrants);

  const [isSupplierChat, setTypeOfChat] = useState(Boolean(RequestExternalChatRead));

  useEffect(() => {
    if (RequestInternalChatRead || RequestExternalChatRead)
      dispatch(
        getMessages({ id, chatType: isSupplierChat ? ChatType.External : ChatType.Internal })
      );
  }, [
    RequestExternalChatRead,
    RequestInternalChatRead,
    dispatch,
    id,
    isSupplierChat,
    requestGrants,
  ]);

  if (!(RequestExternalChatRead || RequestInternalChatRead)) return null;

  const chatHeaderVariant = isLmEnvironment ? translations('supplier') : translations('lmUser');

  return (
    <Flex justifyContent="space-between" className="mu-mt-200">
      <Tabs isSupplierChat={isSupplierChat} setType={setTypeOfChat} />
      <Flex direction="column" className={styles.chatWrapper}>
        <View
          shadow="s"
          radius="l"
          paddingLeft="mu200"
          paddingBottom="mu100"
          paddingTop="mu150"
          className={styles.chatHeader}
        >
          <Text weight="semi-bold">
            {translations('talkingTo')}{' '}
            <Text weight="semi-bold" theme="primary-02">
              {isSupplierChat ? chatHeaderVariant : translations('internal')}
            </Text>
          </Text>
        </View>

        <hr />

        <Flex
          className={styles.chatBody}
          shadow="s"
          radius="l"
          paddingLeft="mu200"
          paddingRight="mu200"
          paddingBottom="mu200"
          paddingTop="mu150"
          direction="column"
        >
          <Messages />
          {(RequestInternalChatWrite || RequestExternalChatWrite) && (
            <ManageChat id={id} isSupplierChat={isSupplierChat} />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
