export enum COLUMNS {
  ID = 'id',
  SUPPLIER_ID = 'supplierId',
  SUPPLIER_NAME = 'supplierName',
  REASON_LIST_ID = 'reasonListId',
  STATUS_ID = 'statusId',
  DUE_DATE = 'dueDate',
  CREATE_DATE = 'createDate',
  DEADLINE_DATE = 'deadlineDate',
}
