import React, { createContext, useContext, useMemo, PropsWithChildren } from 'react';
import { useStepperState } from './useStepperState';
import { StepperContextProps, StepperContextProviderProps } from './types';

const StepperContext = createContext({});

export function useStepperContext<T>(): StepperContextProps<T> {
  const context = useContext<StepperContextProps<T>>(
    StepperContext as unknown as React.Context<StepperContextProps<T>>
  );
  if (!context) {
    throw new Error('useMyContext must be used under MyContextProvider');
  }
  return context;
}

export const StepperContextProvider = <T,>({
  children,
  initStepId,
  steps,
}: PropsWithChildren<StepperContextProviderProps<T>>) => {
  const [currentStepId, { validatedStep }] = useStepperState<T>(initStepId);

  const contextValue = useMemo(
    () => ({ validatedStep, currentStepId, steps }),
    [currentStepId, validatedStep, steps]
  );

  return <StepperContext.Provider value={contextValue}>{children}</StepperContext.Provider>;
};
