import React from 'react';
import { View } from '@mozaic-ds/react';
import { FormProvider } from 'react-hook-form';
import { Chat } from 'components/Chat/Chat';
import { isLmEnvironment } from 'common/consts';
import { useEditFormRequest } from './hooks';
import { useSubRequestsForm } from './partials/Modif/hooks';
import {
  ActionButtons,
  PriceListBlock,
  RequestFileBlock,
  RequestForm,
  SubRequests,
  Modif,
  Notifications,
} from './partials';

export const Request = ({ requestId }: { requestId: string }) => {
  const subRequestForm = useSubRequestsForm();
  const form = useEditFormRequest();

  return (
    <View>
      <FormProvider {...form}>
        <ActionButtons id={requestId} />
        <Notifications />
        <SubRequests id={requestId} />
        {isLmEnvironment && (
          <FormProvider {...subRequestForm}>
            <Modif id={requestId} />
          </FormProvider>
        )}
        <RequestForm id={requestId} />
      </FormProvider>
      <PriceListBlock id={requestId} />
      <RequestFileBlock id={requestId} />
      <Chat id={requestId} />
    </View>
  );
};
