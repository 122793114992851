import React, { useEffect } from 'react';
import { DateInput, Flex, Text, TextInput, View } from '@mozaic-ds/react';
import classNames from 'classnames';
import { useAppTranslations } from 'translations';
import FieldWrapper from 'components/FieldWrapper/FieldWrapper';
import { getCurrencyRate, useCurrencyRate } from 'store/cbd/externalData/currencyRate';
import { useAppDispatch } from 'store/hooks';
import styles from './ExchangeRates.module.scss';
import useCurrencyRatesForm from './hooks/useExchangeRatesForm';
import ExchangeRatesPopup from './partials/ExchangeRatesPopup';

const ExchangeRates = () => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();
  const { currencyRateDate } = useCurrencyRatesForm();
  const { list, visibleCurrencies } = useCurrencyRate();

  useEffect(() => {
    if (currencyRateDate.value) {
      dispatch(getCurrencyRate(currencyRateDate.value));
    }
  }, [currencyRateDate.value, dispatch]);

  return (
    <View className={classNames('mu-p-100', styles.wrapper)}>
      <Flex marginBottom="mu150" justifyContent="space-between">
        <Text weight="semi-bold">{translations('exchangeRates')}</Text>
        <ExchangeRatesPopup />
      </Flex>
      <Flex marginBottom="mu150">
        <FieldWrapper label={translations('date')} id="">
          <DateInput {...currencyRateDate} />
        </FieldWrapper>
      </Flex>
      <View className={styles.grid}>
        {list.reduce(
          (prev: JSX.Element[], curr) =>
            visibleCurrencies.includes(curr.id)
              ? [
                  ...prev,
                  <Flex key={curr.id} alignItems="center">
                    <FieldWrapper
                      label={translations('currency')}
                      id="currencyId2"
                      className="mu-mr-100"
                    >
                      <Text as="div" weight="semi-bold">
                        {curr.currencyId2.toUpperCase()}
                      </Text>
                    </FieldWrapper>
                    <FieldWrapper
                      label={translations('currency')}
                      id="currencyId1"
                      className="mu-mr-100"
                    >
                      <Text as="div" weight="semi-bold">
                        {curr.currencyId1.toUpperCase()}
                      </Text>
                    </FieldWrapper>
                    <FieldWrapper
                      label={translations('exchangeRate')}
                      id="rate"
                      className="mu-mr-100"
                    >
                      <TextInput value={curr.rate} size="m" />
                    </FieldWrapper>
                  </Flex>,
                ]
              : prev,
          []
        )}
      </View>
    </View>
  );
};

export default ExchangeRates;
