import { ICbd, ICbdLoad, ICbdWithArrayItemGroups } from 'common/cbd/types';
import { ICbdTable } from '../AllUploads/AllUploads.types';
import { IUploadTable } from '../Uploads/Uploads.types';
import { ICompareTable } from '../CompareTable/CompareTable.types';

export const mapAllUploads = (oldData: ICbd[]): ICbdTable[] => {
  return oldData.map((item) => ({
    requestId: item.load.requestId,
    supplierId: item.supplierId,
    dateCbd: item.dateCbd,
    id: item.itemGroup?.id ? `${item.id}-${item.itemGroup?.id}` : item.id.toString(),
    fileName:
      item.load.fileName && item.load.fileName.length > 30
        ? `${item.load.fileName.substring(0, 30)}...`
        : item.load.fileName,
    fileId: item.load.fileId,
    createdAt: item.createdAt,
    group: item.group,
    itemGroupId: item.itemGroup?.id,
    groupName: item.itemGroup?.groupName,
    groups: item.itemGroup?.groups,
    depName: item.itemGroup?.departmentName,
    dep: item.itemGroup?.department,
    className: item.itemGroup?.className,
    class: item.itemGroup?.class,
    subclassName: item.itemGroup?.subclassName,
    subclass: item.itemGroup?.subclass,
    item: item.itemGroup?.item,
    status: item.status,
    requestStatus: item.load.requestStatusCode,
    isLoaded: item.load.isLoaded,
  }));
};

export const mapCompareData = (oldData: ICbdWithArrayItemGroups[]): ICompareTable[] => {
  return oldData.map((item) => ({
    id: item.id.toString(),
    dateCbd: item.dateCbd,
    supplierId: item.supplierId,
    group: item.group,
    depName: '',
    subDepName: '',
    className: '',
    subClassName: '',
    children: item.itemGroup.map((elem) => ({
      id: '',
      dateCbd: '',
      supplierId: 0,
      group: '',
      depName: elem.groupName,
      subDepName: elem.departmentName,
      className: elem.className,
      subClassName: elem.subclassName,
      children: [],
    })),
  }));
};

export const mapUploads = (oldData: ICbdLoad[]): IUploadTable[] => {
  return oldData.map((item) => ({
    itemGroup: item.cbd?.itemGroup,
    requestId: item.requestId,
    cbdId: item.cbdId,
    dateCbd: item.cbd?.dateCbd,
    id: item.id,
    fileName: item.fileName.length > 30 ? `${item.fileName.substring(0, 30)}...` : item.fileName,
    fileId: item.fileId,
    createdAt: item.cbd?.createdAt,
    group: item.cbd?.group,
    status: item.cbd?.status,
    description: item.error?.description,
    errorId: item.errorId,
    supplierId: item.cbd?.supplierId,
    isLoaded: item.isLoaded,
  }));
};
