import { useController, useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { useAppTranslations } from 'translations';
import { clearUploadsDeletedFilters, setUploadsFilters } from 'store/cbd/uploads/filters';
import { UploadsFilterName } from '../Uploads.types';
import { getLabel } from './consts';

export const useFiltersForm = () => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const form = useForm({
    defaultValues: {
      cbdId: '',
      cbdCreatedAt: '',
      cbdDate: '',
      fileName: '',
      cbdStatus: '',
    },
  });

  const { control, handleSubmit } = form;

  const { field: cbdId } = useController({ name: 'cbdId', control });
  const { field: cbdCreatedAt } = useController({ name: 'cbdCreatedAt', control });
  const { field: cbdDate } = useController({ name: 'cbdDate', control });
  const { field: fileName } = useController({ name: 'fileName', control });
  const { field: cbdStatus } = useController({ name: 'cbdStatus', control });

  const onSubmit = useCallback(() => {
    handleSubmit((data) => {
      const keys = Object.keys(data);
      dispatch(clearUploadsDeletedFilters());
      dispatch(
        setUploadsFilters(
          keys
            .map((key) => ({
              id: key,
              value: data[key as UploadsFilterName],
              label: getLabel(key as UploadsFilterName, translations),
            }))
            .filter(({ value }) => (Array.isArray(value) ? value.length > 0 : Boolean(value)))
        )
      );
    })();
  }, [dispatch, handleSubmit, translations]);

  return {
    form,
    cbdId,
    cbdCreatedAt,
    cbdDate,
    fileName,
    cbdStatus,
    onSubmit,
  };
};
