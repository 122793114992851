import React, { FC, useCallback, useMemo } from 'react';
import { Flex, Text, Link } from '@mozaic-ds/react';
import {
  clearNomenclatureStore,
  useNomenclatureData,
} from 'store/dashboard/rightsManagement/nomenclature';
import { clearAll as clearAllSuppliers } from 'store/dashboard/rightsManagement/supplier';
import { clearAll as clearAllNomenclatureLinksUser } from 'store/dashboard/rightsManagement/nomenclatureLinkUsers';
import { clearAll as clearAllSupplierLinksUser } from 'store/dashboard/rightsManagement/supplierLinkUsers';
import { useAppDispatch } from 'store/hooks';
import { useAppTranslations } from 'translations';
import { NomenclatureLink } from 'store/dashboard/rightsManagement/nomenclatureLinks';
import { SupplierLinkType } from 'store/dashboard/rightsManagement/supplierLinks';
import { DEFAULT_UNAVAILABLE_ROLE } from 'store/dashboard/consts';

type CheckboxManagementProps = {
  selectedSuppliersLinks: SupplierLinkType[];
  selectedNomenclatureLinks: NomenclatureLink;
  className?: string;
};

const CheckboxManagement: FC<CheckboxManagementProps> = ({
  selectedSuppliersLinks,
  selectedNomenclatureLinks,
  className,
}) => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();
  const { selectedRole } = useNomenclatureData().nomenclature;

  const clearAll = useCallback(() => {
    dispatch(clearAllSuppliers({ isClearRole: false }));
    dispatch(clearNomenclatureStore());
    dispatch(clearAllNomenclatureLinksUser());
    dispatch(clearAllSupplierLinksUser());
  }, [dispatch]);

  const { allSelected, selectedSuppliersForCurrentRol, selectedNomenclatureForCurrentRole } =
    useMemo(() => {
      if (!selectedSuppliersLinks.length && selectedNomenclatureLinks) {
        return {
          selectedNomenclatureForCurrentRole: selectedNomenclatureLinks[selectedRole]?.length || 0,
          allSelected: Object.keys(selectedNomenclatureLinks).reduce((acc, key) => {
            return acc + (selectedNomenclatureLinks[key] || []).length;
          }, 0),
        };
      }
      if (selectedSuppliersLinks.length) {
        return {
          allSelected: selectedSuppliersLinks.length,
          selectedSuppliersForCurrentRol: selectedSuppliersLinks.filter(
            (supplier) => supplier.roleId === selectedRole
          ).length,
        };
      }
      return {
        allSelected: 0,
      };
    }, [selectedSuppliersLinks, selectedNomenclatureLinks, selectedRole]);

  return (
    <Flex justifyContent="space-between" className={className}>
      <Text>
        {selectedRole !== DEFAULT_UNAVAILABLE_ROLE && (
          <>
            Для этой роли выбрано{' '}
            {selectedSuppliersForCurrentRol || selectedNomenclatureForCurrentRole} значений,{' '}
          </>
        )}
        <>всего выбрано {allSelected} значений</>
      </Text>
      {allSelected > 0 && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link onClick={clearAll}>{translations('clearAll')}</Link>
      )}
    </Flex>
  );
};
export default CheckboxManagement;
