import React from 'react';
import { Flex, Loader, Text } from '@mozaic-ds/react';
import { useAppTranslations } from 'translations';
import { useCurrentPriceListData } from 'store/priceList/currentPriceList';
import { useRequestData } from 'store/request/request';
import { PricelistStatusCode } from '@ppm/ppm-platform-pricelist-service';
import { parseDateForMozaicComponent } from 'common/utils';

export const Placeholder = () => {
  const t = useAppTranslations();

  const { priceList, isLoading: priceListLoading, isFailure } = useCurrentPriceListData().data;
  const { request, isLoading } = useRequestData().data;
  const {
    statusCode,
    contractNumber,
    contractStartDate,
    pricelistNumber,
    pricelistStartDate,
    pricelistEndDate,
    signingDate,
  } = priceList;

  return (
    <Flex className="mu-pt-125">
      {isLoading && <Loader />}
      {!isFailure && !priceListLoading && priceList && (
        <>
          <Flex direction="column" marginRight="mu150">
            <Text theme="primary-02" size="s" className="mu-pb-050">
              {t('supplierName')}
            </Text>
            <Text theme="primary-02" size="s" className="mu-pb-050">
              {t('contractNumber')}
            </Text>
            <Text theme="primary-02" size="s" className="mu-pb-050">
              {t('from')}
            </Text>
            <Text theme="primary-02" size="s" className="mu-pb-050">
              {t('devision')}
            </Text>
            <Text theme="primary-02" size="s" className="mu-pb-050">
              {t('pricelistNumber')}
            </Text>
            <Text theme="primary-02" size="s" className="mu-pb-050">
              {t('priceListPeriodOfValidity')}
            </Text>
            {signingDate && statusCode === PricelistStatusCode.SignedByAllSides && (
              <Text theme="primary-02" size="s" className="mu-pb-050">
                {t('priceListSigningDate')}
              </Text>
            )}
          </Flex>
          <Flex direction="column">
            <Text className="mu-pb-050" size="s">
              {request?.supplierName}
            </Text>
            <Text className="mu-pb-050" size="s">
              {contractNumber}
            </Text>
            <Text className="mu-pb-050" size="s">
              {parseDateForMozaicComponent(contractStartDate, 'dd-MM-yyyy')}
            </Text>
            <Text className="mu-pb-050" size="s">
              {request?.departmentNumber}
            </Text>
            <Text className="mu-pb-050" size="s">
              {pricelistNumber}
            </Text>
            <Text className="mu-pb-050" size="s">
              {`${t('with')} ${parseDateForMozaicComponent(pricelistStartDate, 'dd-MM-yyyy')} ${t(
                'to'
              )} ${parseDateForMozaicComponent(pricelistEndDate, 'dd-MM-yyyy')}`}
            </Text>
            {signingDate && statusCode === PricelistStatusCode.SignedByAllSides && (
              <Text className="mu-pb-050" size="s">
                {parseDateForMozaicComponent(signingDate, 'dd-MM-yyyy')}
              </Text>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};
