import React, { FC } from 'react';
import { TabsButton, TabsList, Text } from '@mozaic-ds/react';
import { useRoles } from 'store/roles/roles';
import cn from 'classnames';
import styles from './RoleTabs.module.scss';

type RoleTabsProps = {
  rolesWithLinks?: number[];
};

const RoleTabs: FC<RoleTabsProps> = ({ rolesWithLinks = [] }) => {
  const { linkableRoles } = useRoles().data;

  return (
    <>
      {linkableRoles.length > 0 && (
        <TabsList width="full">
          {linkableRoles.map(({ id, description }) => (
            <TabsButton key={id} tab={id.toString()}>
              <Text
                as="span"
                size="s"
                className={cn({
                  [styles.textNormal]: !rolesWithLinks.find((item) => item === id),
                })}
              >
                {description}
              </Text>
            </TabsButton>
          ))}
        </TabsList>
      )}
    </>
  );
};
export default RoleTabs;
