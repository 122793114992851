import { combineReducers } from '@reduxjs/toolkit';
import { accessSlice } from './access';
import { userSlice } from './user';
import { loginSlice } from './login';

export const auth = combineReducers({
  user: userSlice.reducer,
  access: accessSlice.reducer,
  login: loginSlice.reducer,
});
