import React from 'react';
import { FileType } from '@ppm/ppm-platform-file-service/build/enums';
import FileUploader from 'components/FileUploader/FileUploader';
import { useAppTranslations } from 'translations';
import { useGetGrants } from 'pages/Request/hooks';
import { useActionsFileForm } from 'hook/useActionsFileForm';
import { useDownloadFile } from 'hook/useDownloadFile';
import { isProd } from 'common/consts';
import { gtmOtherFileHandleClick } from './gtm';
import { useOtherFilesForm } from './hooks';

type OtherFileProps = {
  id: string;
  className?: string;
  hasWrapper?: boolean;
  isScrollable?: boolean;
};

export const OtherFileUploader = ({
  id,
  className = 'mu-mr-100',
  hasWrapper = true,
  isScrollable = false,
}: OtherFileProps) => {
  const translations = useAppTranslations();
  const { otherFiles } = useOtherFilesForm();
  const { handleUploadFiles, handleDeleteFiles } = useActionsFileForm(
    id,
    otherFiles,
    FileType.OtherFiles
  );
  const { hasRequestGrants } = useGetGrants();

  const { RequestUploadOtherFiles, RequestDeleteOtherFiles, RequestDownloadOtherFiles } =
    hasRequestGrants;
  const handelDownloadFile = useDownloadFile(id);

  if (!(id && (RequestDownloadOtherFiles || RequestUploadOtherFiles))) return null;

  return (
    <FileUploader
      id="otherFiles"
      files={otherFiles.value}
      onChange={handleUploadFiles}
      onDeleteFile={RequestDeleteOtherFiles ? handleDeleteFiles : undefined}
      onDownloadFile={RequestDownloadOtherFiles ? handelDownloadFile : undefined}
      className={className}
      isDisabled={!RequestUploadOtherFiles}
      hasWrapper={hasWrapper}
      isScrollable={isScrollable}
      onClick={
        isProd
          ? () => {
              gtmOtherFileHandleClick({
                t: translations,
                id,
              });
            }
          : undefined
      }
    >
      {translations('uploadFile')}
    </FileUploader>
  );
};
