import React from 'react';
import { Flex, Text, Tooltip } from '@mozaic-ds/react';
import { NotificationInformation24 } from '@mozaic-ds/icons/react';
import { ChartProps, Line } from 'react-chartjs-2';
import { chartColors } from 'components/Chart/hooks/useChartConfig';
import useChartData from '../hooks/useChartData';
import styles from './Dynamics.module.scss';

const Dynamics = () => {
  const { chartConfig, datasetLabels } = useChartData();

  return (
    <Flex className={styles.chart} marginTop="mu100" padding="mu100" direction="column">
      <Flex justifyContent="flex-end">
        <Tooltip
          placement="left"
          label={
            <Flex alignItems="flex-start" direction="column">
              {datasetLabels.map((item, index) => (
                <Flex alignItems="center">
                  <span
                    className={styles.legendCircle}
                    style={{
                      background: chartColors[index],
                    }}
                  />
                  <Text>{item}</Text>
                </Flex>
              ))}
            </Flex>
          }
        >
          <NotificationInformation24 />
        </Tooltip>
      </Flex>
      <Line {...(chartConfig as ChartProps<'line'>)} />
    </Flex>
  );
};

export default Dynamics;
