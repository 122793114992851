import { bindCbd, deleteItemGroup } from 'api/cbd/cbd';
import { NomenclatureState } from 'common/cbd/types';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMount } from 'hook/useMount';
import { useParams } from 'react-router-dom';
import { useBoundList, setSelectedRowsId } from 'store/cbd/bound/boundList';
import { useAppDispatch } from 'store/hooks';
import { formatBindData } from './helpers';

export interface ISubmitForm {
  updateTableData: () => void;
}

export const useActions = ({ updateTableData }: ISubmitForm) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { selectedRowsId } = useBoundList();

  const { handleSubmit, reset } = useFormContext<NomenclatureState>();

  const onDelete = useCallback(async () => {
    const { cbdId } = params;

    await Promise.all(
      selectedRowsId.map((item) => deleteItemGroup({ cbdId: cbdId || '', id: item }))
    );
    dispatch(setSelectedRowsId([]));
    updateTableData();
  }, [dispatch, params, selectedRowsId, updateTableData]);

  const onReset = () => {
    reset((formValues) => ({
      ...formValues,
      subDep: '',
      classData: '',
      subClass: '',
      item: '',
    }));
  };

  const onSubmit = async (formData: NomenclatureState) => {
    const formattedData = formatBindData(formData, params.cbdId);
    await bindCbd(formattedData);
    updateTableData();
    onReset();
  };

  useMount(() => {
    return () => {
      dispatch(setSelectedRowsId([]));
    };
  });

  return { onDelete, onReset, onSubmit, handleSubmit };
};
