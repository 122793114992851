import { useCallback } from 'react';
import { downloadFile } from 'common/utils';

export const useDownloadFile = (requestId: string) => {
  return useCallback(
    (fileId: number) => {
      downloadFile(requestId, fileId);
    },
    [requestId]
  );
};
