import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/types';
import { useAppSelector } from 'store/hooks';
import { AlertAttributeColumns } from 'pages/cbd/Scheduler/partials/AlertAttribute/hook/consts';

export type AlertAttributeColumn = {
  key: AlertAttributeColumns;
  isShown: boolean;
};

interface SliceState {
  columns: AlertAttributeColumn[];
}

export const columnsSlice = createSlice({
  initialState: {
    sortedField: null,
    columns: Object.values(AlertAttributeColumns).map((item) => ({
      key: item,
      isShown: true,
    })),
  },
  name: 'columns',
  reducers: {
    setVisibleAlertAttributeColumns(
      state: SliceState,
      action: PayloadAction<Record<AlertAttributeColumns, boolean>>
    ) {
      state.columns = state.columns.map((column) => {
        return { ...column, isShown: action.payload[column.key] };
      });
    },
  },
});

export const { setVisibleAlertAttributeColumns } = columnsSlice.actions;

export const useColumnsData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.cbd.scheduler.subscriptions.columns) };
};
