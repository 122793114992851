import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getRegions } from 'api/cbd/selects';
import { IGetSelectsPayload, IGetSelectsResponse } from 'common/cbd/types';
import { StringSelectItem } from 'common/types';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

interface SliceState {
  list: StringSelectItem[];
  searchValue: string;
}

const initialState: SliceState = {
  list: [],
  searchValue: '',
};

export const getRegionsList = createAsyncThunk(
  'regions/getRegionsList',
  async (payload: IGetSelectsPayload | undefined, { rejectWithValue }) => {
    try {
      return await getRegions(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const regionsSlice = createSlice({
  initialState,
  name: 'regions',
  reducers: {
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    clearRegionsSlice() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getRegionsList.fulfilled,
      (state: SliceState, action: PayloadAction<IGetSelectsResponse>) => {
        state.list = action.payload.data;
      }
    );
  },
});

export const { setSearchValue, clearRegionsSlice } = regionsSlice.actions;

export const useRegions = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.regions);
};
