import { RequestStatusCode } from '@ppm/ppm-platform-request-service';
import { FileType } from '@ppm/ppm-platform-file-service/build/enums';
import { Steps } from 'pages/CreatingRequest/types';
import { RequestModelView } from '@ppm/ppm-platform-api';
import { ParsingStatus } from '@ppm/ppm-platform-common';

export const getTemplateInfo = (data: RequestModelView) => {
  const { filesInfo } = data;
  const supplierTemplates = filesInfo.filter(
    ({ fileType }) => fileType === FileType.SupplierTemplate
  );
  const templateIsExist = Boolean(
    filesInfo.find(({ fileType }) => fileType === FileType.SupplierTemplate)
  );
  const supplierTemplatesHaveNoError = supplierTemplates.every(
    (file) => file.status !== ParsingStatus.Failed
  );

  return {
    supplierTemplatesHaveNoError,
    templateIsExist,
    hasCorrectTemplate: templateIsExist && supplierTemplatesHaveNoError,
  };
};

export const getStep = (data: RequestModelView) => {
  const { statusCode, filesInfo } = data;
  const isDraft = statusCode === RequestStatusCode.Draft;
  const { hasCorrectTemplate } = getTemplateInfo(data);
  const hasCBD = Boolean(filesInfo.find((file) => file.fileType === FileType.CBD));

  if (isDraft && !hasCorrectTemplate) return Steps.template;
  if (isDraft && hasCorrectTemplate) return Steps.cbd;
  if (isDraft && hasCBD) return Steps.finish;

  return Steps.start;
};
