import { useCallback, useState } from 'react';
import { getRequestById } from 'api/request';
import AsyncRetry from 'async-retry';
import { FileType } from '@ppm/ppm-platform-file-service/build/enums';
import { FileInfo } from '@ppm/ppm-platform-api';
import { useCancelRequest } from 'hook/useCancelRequest';
import { isProd, isSupplierEnvironment } from 'common/consts';
import { gtmDownloadTemplate } from 'pages/Request/partials/DownloadingTemplate/gtm';
import { useAppTranslations } from 'translations';
import { downloadFile } from 'common/utils';

export const useDownloadTemplate = (id: string) => {
  const t = useAppTranslations();
  const controller = useCancelRequest();

  const [isLoading, setLoading] = useState(false);

  const downloadTemplate = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        setLoading(true);
        await AsyncRetry(
          async () => {
            const { data } = await getRequestById({
              urlParameters: [id],
              conf: { signal: controller.signal },
            });
            if (isSupplierEnvironment && isProd) gtmDownloadTemplate(t, id);
            if (data.request.filesInfo.length > 0) {
              setLoading(false);
              const file = data.request.filesInfo
                .filter((item) => item.fileType === FileType.EmptySupplierTemplate)
                .pop() as FileInfo;
              return downloadFile(id, file.id);
            }
            throw new Error('Not ready yet');
          },
          {
            minTimeout: 2000,
            retries: 2,
          }
        );
      } catch (error) {
        setLoading(false);
      }
      return null;
    },
    [controller.signal, id, t]
  );

  return { isLoading, downloadTemplate };
};
