import {
  IAddTemplateStructure,
  IChangeTemplateStructureData,
  IDeleteTemplateStructure,
} from 'api/cbd/templates';

export const onRowChange = (watcherValue: string, config: IAddTemplateStructure) => {
  let value = { ...config };
  const [rowList, rowNumber] = watcherValue.split(';').map((item) => item.trim());

  if (rowList && rowNumber) {
    value = {
      ...value,
      row: {
        list: rowList,
        rowNumber: Number(rowNumber),
      },
    };
  } else {
    const { row, ...stateWithoutRow } = value;
    value = stateWithoutRow;
  }

  return value;
};

export const onDeleteRowChange = (watcherValue: string, config: IDeleteTemplateStructure) => {
  let value = { ...config };
  const [deleteRowList, deleteRowNumber] = watcherValue.split(';').map((item) => item.trim());

  if (deleteRowList && deleteRowNumber) {
    value = {
      ...value,
      row: {
        list: deleteRowList,
        rowNumber: Number(deleteRowNumber),
      },
    };
  } else {
    const { row, ...stateWithoutDeleteRow } = value;
    value = stateWithoutDeleteRow;
  }

  return value;
};

export const onColumnChange = (watcherValue: string, config: IAddTemplateStructure) => {
  let value = { ...config };
  const [columnList, columnFieldValue] = watcherValue.split(';').map((item) => item.trim());

  if (columnList && columnFieldValue) {
    value = {
      ...value,
      column: {
        list: columnList,
        column: columnFieldValue,
      },
    };
  } else {
    const { column, ...stateWithoutColumn } = value;
    value = stateWithoutColumn;
  }

  return value;
};

export const onDeleteColumnChange = (watcherValue: string, config: IDeleteTemplateStructure) => {
  let value = { ...config };
  const [deleteColumnList, deleteColumnFieldValue] = watcherValue
    .split(';')
    .map((item) => item.trim());

  if (deleteColumnList && deleteColumnFieldValue) {
    value = {
      ...value,
      column: {
        list: deleteColumnList,
        column: deleteColumnFieldValue,
      },
    };
  } else {
    const { column, ...stateWithoutDeleteColumn } = value;
    value = stateWithoutDeleteColumn;
  }

  return value;
};

export const onNameChange = (watcherValue: string, config: IAddTemplateStructure) => {
  let value = { ...config };
  const [list, range, nameValue] = watcherValue.split(';').map((item) => item.trim());

  if (list && range && nameValue) {
    value = {
      ...value,
      name: {
        list,
        range,
        name: nameValue,
      },
    };
  } else {
    const { name, ...stateWithoutName } = value;

    value = stateWithoutName;
  }

  return value;
};

export const onAddCellText = (watcherValue: string, config: IChangeTemplateStructureData) => {
  let value = { ...config };
  const [list, cellName, textValue] = watcherValue.split(';').map((item) => item.trim());

  if (list && cellName && textValue) {
    value = {
      ...value,
      text: {
        list,
        cellName,
        value: textValue,
      },
    };
  } else {
    const { text, ...stateWithoutText } = value;

    value = stateWithoutText;
  }

  return value;
};

export const onAddCellNumber = (watcherValue: string, config: IChangeTemplateStructureData) => {
  let value = { ...config };
  const [list, cellName, numberValue] = watcherValue.split(';').map((item) => item.trim());

  if (list && cellName && numberValue) {
    value = {
      ...value,
      number: {
        list,
        cellName,
        value: Number(numberValue),
      },
    };
  } else {
    const { number, ...stateWithoutNumber } = value;

    value = stateWithoutNumber;
  }

  return value;
};

export const onAddCellFormula = (watcherValue: string, config: IChangeTemplateStructureData) => {
  let value = { ...config };
  const [list, cellName, formulaValue] = watcherValue.split(';').map((item) => item.trim());

  if (list && cellName && formulaValue) {
    value = {
      ...value,
      formula: {
        list,
        cellName,
        formula: formulaValue,
      },
    };
  } else {
    const { formula, ...stateWithoutFormula } = value;

    value = stateWithoutFormula;
  }

  return value;
};

export const onAddFormat = (watcherValue: string, config: IAddTemplateStructure) => {
  let value = { ...config };
  const [srcList, srcCell, targetList, target] = watcherValue.split(';').map((item) => item.trim());

  if (srcList && srcCell && targetList && target) {
    value = {
      ...value,
      format: {
        srcList,
        srcCell,
        targetList,
        target,
      },
    };
  } else {
    const { format, ...stateWithoutFormat } = value;

    value = stateWithoutFormat;
  }

  return value;
};

export const onAddRange = (watcherValue: string, config: IAddTemplateStructure) => {
  let value = { ...config };
  const [srcList, srcCell, targetList, target] = watcherValue.split(';').map((item) => item.trim());

  if (srcList && srcCell && targetList && target) {
    value = {
      ...value,
      range: {
        srcList,
        srcCell,
        targetList,
        target,
      },
    };
  } else {
    const { range, ...stateWithoutFormat } = value;

    value = stateWithoutFormat;
  }

  return value;
};
