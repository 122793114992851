import React, { FC, useMemo } from 'react';
import {
  getNomenclatureData,
  useNomenclatureData,
} from 'store/dashboard/rightsManagement/nomenclature';
import { useAppTranslations } from 'translations';
import { useAppDispatch } from 'store/hooks';
import { useMount } from 'hook/useMount';
import { useSuppliersData } from 'store/dashboard/rightsManagement/supplier';
import { Accordion, AccordionContent, AccordionHeader, AccordionHeading } from '@mozaic-ds/react';
import { NomenclatureProvider } from './NomenclatureContext';
import { Department } from './Department';

type NomenclatureProps = {
  isInterchangeable?: boolean; // проп в значении true, который запрещает выбирать и номеклатуры и поставщиков одновренно
};

const Nomenclature: FC<NomenclatureProps> = ({ isInterchangeable = true }) => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();
  const { data } = useNomenclatureData().nomenclature;
  const { checkedSuppliersGroupedByRole } = useSuppliersData().supplier;

  useMount(() => {
    if (!data) dispatch(getNomenclatureData({ path: [] }));
  });

  const isDisabledAccordion = useMemo(() => {
    return checkedSuppliersGroupedByRole.length > 0;
  }, [checkedSuppliersGroupedByRole]);

  return (
    <NomenclatureProvider initialProps={{ isInterchangeable, isDisabledAccordion }}>
      <Accordion isDisabled={isDisabledAccordion}>
        <AccordionHeader>
          <AccordionHeading>{translations('nomenclatureAccordionHeader')}</AccordionHeading>
        </AccordionHeader>
        <AccordionContent>
          {data &&
            data.tree.map((node) => <Department key={node.data.path.join('')} item={node} />)}
        </AccordionContent>
      </Accordion>
    </NomenclatureProvider>
  );
};
export default Nomenclature;
