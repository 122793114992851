// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { forwardRef, InputHTMLAttributes, useCallback, useState } from 'react';
import {
  ListBox,
  ListBoxItem,
  TextInput,
  useListBoxValues,
  Text,
  Loader,
  TTextInputSize,
} from '@mozaic-ds/react';
import { DisplaySearch24 } from '@mozaic-ds/icons/react';
import { useAppDispatch } from 'store/hooks';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import cn from 'classnames';
import { DropdownProps } from 'components/Dropdown/types';
import { isEmpty, isEqual, debounce } from 'lodash';

type AutocompleteProps = {
  value: any;
  autoSubmit?: () => void;
  size?: TTextInputSize;
  handleSearch: ActionCreatorWithPayload<string>;
  emptyMessageText?: string;
  isLoading?: boolean;
  isShownListBoxOnClick?: boolean;
} & DropdownProps &
  Pick<InputHTMLAttributes<HTMLInputElement>, 'onFocus'>;

const Autocomplete = forwardRef(
  (
    {
      isLoading,
      placeholder,
      listBoxItems,
      isDisabled = false,
      handleSearch,
      className,
      value,
      onChange,
      autoSubmit,
      emptyMessageText,
      onFocus,
      isShownListBoxOnClick = false,
      size = 'm',
    }: AutocompleteProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const dispatch = useAppDispatch();
    const [values, { add, clear }] = useListBoxValues(); // values не строка, а SelectItem
    const [focusValue, setFocus] = useState(false);

    const handleClick = useCallback(
      (listBoxValue: any) => {
        if (!listBoxValue) return;
        clear();
        add(listBoxValue);
        onChange(listBoxValue);
        if (autoSubmit) autoSubmit();
        setFocus(false);
      },
      [clear, add, onChange, autoSubmit]
    );
    const isFocusOpened = isShownListBoxOnClick
      ? (isShownListBoxOnClick && focusValue) || !isEmpty(value)
      : focusValue;

    return (
      <>
        <TextInput
          ref={ref}
          value={isEmpty(value) ? '' : value.label}
          onClick={() => {
            setFocus(!focusValue);
          }}
          onChange={(e) => {
            onChange(e);
            debounce(() => {
              if (!e.target.value) dispatch(handleSearch(''));
              else dispatch(handleSearch((e.target as HTMLInputElement).value));
            }, 1000)();
          }}
          className={cn(className)}
          icon={isLoading ? <Loader /> : <DisplaySearch24 />}
          size={size}
          placeholder={placeholder}
          isDisabled={isDisabled}
          type="search"
          onFocus={onFocus}
        />
        {!isDisabled &&
          !isEqual(
            value,
            values.find((item: any) => item.value === value.value)
          ) &&
          isFocusOpened &&
          Boolean(listBoxItems.length) && (
            <ListBox values={values} mode="single" onClick={handleClick}>
              {listBoxItems.map((item) => (
                <ListBoxItem
                  key={item.value}
                  value={{ value: item.value, label: item.label } as unknown as any}
                >
                  {item.label}
                </ListBoxItem>
              ))}
            </ListBox>
          )}
        {focusValue && !listBoxItems.length && emptyMessageText && (
          <Text theme="danger">{emptyMessageText}</Text>
        )}
      </>
    );
  }
);

export default Autocomplete;
