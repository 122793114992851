import React from 'react';
import { Flex, Text, View } from '@mozaic-ds/react';

import ExternalSource from 'pages/cbd/ExternalSource/ExternalSource';
import AveragePrices from 'pages/cbd/AveragePrices/AveragePrices';
import { useAppTranslations } from 'translations';
import Dynamics from './partials/Dynamics';

const Analysis = () => {
  const translations = useAppTranslations();

  return (
    <View marginTop="mu150">
      <Flex marginBottom="mu150" direction="column">
        <Text weight="semi-bold" size="xl">
          {translations('externalSourcesData')}
        </Text>
        <ExternalSource />
      </Flex>
      <Flex marginBottom="mu150" direction="column">
        <Text weight="semi-bold" size="xl">
          {translations('dynamics')}
        </Text>
        <Dynamics />
      </Flex>
      <Flex marginBottom="mu150" direction="column">
        <Text weight="semi-bold" size="xl">
          {translations('value')}
        </Text>
        <AveragePrices />
      </Flex>
    </View>
  );
};

export default Analysis;
