import React, { useEffect } from 'react';
import { Button, DateInput, Select, TextInput, View } from '@mozaic-ds/react';
import { DisplayFilter24 } from '@mozaic-ds/icons/react';
import Layer from 'components/Layer/Layer';
import { useAppTranslations } from 'translations';
import { useFiltersData } from 'store/cbd/scheduler/subscriptions/filters';
import FieldWrapper from 'components/FieldWrapper/FieldWrapper';
import useCostTypesOptions from 'pages/cbd/hooks/useCostTypesOptions';
import { useScheduler } from 'store/cbd/scheduler/scheduler';
import { filtersName } from '../hook/consts';
import { useFiltersForm } from '../hook/filters';
import { subscriptionTypeOptions } from '../../SchedulerForm/consts';

const FiltersPopup = (): JSX.Element => {
  const translations = useAppTranslations();
  const {
    form,
    login,
    dateStart,
    dateFinish,
    sourceId,
    categoryId,
    typeId,
    subtypeId,
    subscriptionType,
    value,
    term,
    onSubmit,
  } = useFiltersForm();
  const { categoryCostTypesOptions, getTypeCostTypesOptions, getSubtypeCostTypesOptions } =
    useCostTypesOptions();
  const { data: sourceOptions } = useScheduler();
  const { deletedFilters } = useFiltersData();

  useEffect(() => {
    deletedFilters.forEach((id) => form.setValue(id, ''));
  }, [deletedFilters, form]);

  return (
    <Layer
      id="filtersAttributePopup"
      icon={<DisplayFilter24 fill="#71706b" />}
      title={translations('filterPopupHeader')}
      resetItems={() => {
        form.reset();
        filtersName.forEach((id) => form.setValue(id, ''));
      }}
      saveItems={onSubmit}
      button={
        <Button leftIcon={<DisplayFilter24 />} variant="bordered" size="s">
          {translations('filters')}
        </Button>
      }
    >
      <View as="form" paddingLeft="mu025" paddingRight="mu025">
        <FieldWrapper label={translations('LDAP')} id="login">
          <TextInput {...login} size="m" placeholder={translations('LDAP')} />
        </FieldWrapper>
        <FieldWrapper label={translations('subscriptionStartDate')} id="dateStart">
          <DateInput {...dateStart} size="m" placeholder={translations('subscriptionStartDate')} />
        </FieldWrapper>
        <FieldWrapper label={translations('subscriptionEndDate')} id="dateFinish">
          <DateInput {...dateFinish} size="m" placeholder={translations('subscriptionEndDate')} />
        </FieldWrapper>
        <FieldWrapper label={translations('source')} id="sourceId">
          <Select
            {...sourceId}
            size="m"
            placeholder={translations('source')}
            options={sourceOptions.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('category')} id="categoryId">
          <Select
            {...categoryId}
            size="m"
            placeholder={translations('category')}
            options={categoryCostTypesOptions}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('type')} id="typeId">
          <Select
            {...typeId}
            isDisabled={!categoryId.value}
            size="m"
            placeholder={translations('type')}
            options={getTypeCostTypesOptions(categoryId.value)}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('subType')} id="subtypeId">
          <Select
            {...subtypeId}
            isDisabled={!typeId.value}
            size="m"
            placeholder={translations('subType')}
            options={getSubtypeCostTypesOptions(categoryId.value, typeId.value)}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('subscriptionType')} id="subscriptionType">
          <Select
            {...subscriptionType}
            size="m"
            placeholder={translations('subscriptionType')}
            options={subscriptionTypeOptions.map((item) => ({
              ...item,
              label: translations(item.label),
            }))}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('indicatorValue')} id="value">
          <TextInput
            {...value}
            size="m"
            placeholder={translations('indicatorValue')}
            type="number"
          />
        </FieldWrapper>
        <FieldWrapper label={translations('analyzedDaysPeriod')} id="term">
          <TextInput
            {...term}
            size="m"
            placeholder={translations('analyzedDaysPeriod')}
            type="number"
          />
        </FieldWrapper>
      </View>
    </Layer>
  );
};

export default FiltersPopup;
