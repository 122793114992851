import {
  CreateRequestBody,
  CreateRequestResponse,
  CreateRequest,
  CheckPossibilityCreatingNewRequest,
} from '@ppm/ppm-platform-api';
import { apiCreator } from 'api/index';

export const createRequest = apiCreator<
  typeof CreateRequest,
  CreateRequestResponse,
  undefined,
  CreateRequestBody
>(CreateRequest)[CreateRequest.method];

export const getCheckPossibilityCreatingNewRequest = apiCreator<
  typeof CheckPossibilityCreatingNewRequest,
  CheckPossibilityCreatingNewRequest.Request,
  Parameters<(typeof CheckPossibilityCreatingNewRequest)['url']>
>(CheckPossibilityCreatingNewRequest)[CheckPossibilityCreatingNewRequest.method];
