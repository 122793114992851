import { JSX } from 'react';
import { RequestCreationForm } from './RequestCreationForm';
import { FileContainer } from './FileContainer';
import { FinishRequestCreation } from './FinishRequestCreation';
import { StepsKey } from '../types';

export const stepsComponent: Record<StepsKey, () => JSX.Element> = {
  start: RequestCreationForm,
  template: FileContainer,
  cbd: FileContainer,
  finish: FinishRequestCreation,
};
