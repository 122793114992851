import React, { FC, useCallback, useEffect } from 'react';
import {
  Button,
  CheckBox,
  Flex,
  Text,
  View,
  AccordionContent,
  useAccordionContext,
} from '@mozaic-ds/react';
import { ControlCircleMore24 } from '@mozaic-ds/icons/react';
import { clearNomenclatureStore } from 'store/dashboard/rightsManagement/nomenclature';
import { useAppDispatch } from 'store/hooks';
import {
  setIsChecked,
  SupplierSliceData,
  useSuppliersData,
} from 'store/dashboard/rightsManagement/supplier';
import { addMore, clearOffset, setSearchValue } from 'store/dictionary/supplierIds';
import { useAppTranslations } from 'translations';
import { clearAll } from 'store/dashboard/rightsManagement/nomenclatureLinkUsers';
import { Search } from 'components/Search/Search';
import { useUserData } from 'store/auth/user';
import { getHasGrants } from 'common/utils';

type SupplierProps = {
  isInterchangeable?: boolean;
  isDisabledAccordion?: boolean;
};

const SuppliersContent: FC<SupplierProps> = ({ isInterchangeable, isDisabledAccordion }) => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();
  const { data: suppliers, selectedRole } = useSuppliersData().supplier;
  const { userGrants } = useUserData().user;
  const hasGrant = getHasGrants(userGrants);
  const { AdminNomenclatureLinksWrite } = hasGrant;
  const { close } = useAccordionContext();

  useEffect(() => {
    close();
  }, [close, selectedRole, isDisabledAccordion]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, selectedItem: SupplierSliceData) => {
      dispatch(setIsChecked({ selectedItem, value: e.target.checked }));
      if (isInterchangeable) {
        dispatch(clearNomenclatureStore());
        dispatch(clearAll());
      }
    },
    [dispatch, isInterchangeable]
  );
  const handleAddMoreSuppliers = useCallback(() => dispatch(addMore()), [dispatch]);

  return (
    <AccordionContent>
      <View>
        <Search
          size="m"
          className="mu-p-150"
          placeholder={translations('searchBySupplier')}
          clearOffset={clearOffset}
          setStoreValue={setSearchValue}
        />
        {suppliers &&
          suppliers.map((item) => {
            const { label, value, isChecked } = item;
            return (
              <Flex direction="row" paddingLeft="mu150" paddingTop="mu100" key={value}>
                <CheckBox
                  key={value}
                  isChecked={isChecked}
                  onChange={(e) => handleChange(e, item)}
                  isDisabled={isInterchangeable ? false : !AdminNomenclatureLinksWrite}
                />
                <Text>{label}</Text>
              </Flex>
            );
          })}
        <Button
          className="mu-mt-150"
          width="full"
          leftIcon={<ControlCircleMore24 />}
          size="s"
          theme="neutral"
          variant="bordered"
          onClick={handleAddMoreSuppliers}
        >
          {translations('showMoreSuppliers')}
        </Button>
      </View>
    </AccordionContent>
  );
};
export default SuppliersContent;
