import React, { useCallback, useMemo } from 'react';
import { IColumnTemplate } from 'common/utils';
import { DateInput, Select, TextInput } from '@mozaic-ds/react';
import { useAppDispatch } from 'store/hooks';
import {
  changeTableRow,
  useSubscriptionsList,
} from 'store/cbd/scheduler/subscriptions/subscriptionsList';
import { useScheduler } from 'store/cbd/scheduler/scheduler';
import { useAppTranslations } from 'translations';
import useCostTypesOptions from 'pages/cbd/hooks/useCostTypesOptions';
import { ISubscription } from 'api/cbd/scheduler';
import { AlertAttributeColumns } from './consts';
import { subscriptionTypeOptions } from '../../SchedulerForm/consts';

export const useGetColumnTemplate = (): {
  [key in AlertAttributeColumns]: IColumnTemplate<ISubscription>;
} => {
  const dispatch = useAppDispatch();
  const { editItems } = useSubscriptionsList();
  const { categoryCostTypesOptions, getTypeCostTypesOptions, getSubtypeCostTypesOptions } =
    useCostTypesOptions();
  const { data: sourceOptions } = useScheduler();
  const translate = useAppTranslations();

  const isEditModeEnable = useCallback(
    (id: string) => {
      return editItems.includes(id);
    },
    [editItems]
  );

  const onFieldChange = useCallback(
    (id: number, fieldName: keyof ISubscription, value: string) => {
      dispatch(
        changeTableRow({
          id,
          fieldName,
          value,
        })
      );
    },
    [dispatch]
  );

  const columnTemplate = useMemo(
    () => ({
      [AlertAttributeColumns.LOGIN]: {
        message: 'LDAP',
        isSortable: false,
        render: (record: ISubscription) => {
          if (isEditModeEnable(record.id.toString())) {
            return (
              <TextInput
                size="s"
                value={record.login || ''}
                onChange={(e) => onFieldChange(record.id, 'login', e.target.value)}
              />
            );
          }

          return record.login;
        },
      },
      [AlertAttributeColumns.DATE_START]: {
        message: 'subscriptionStartDate',
        isSortable: false,
        render: (record: ISubscription) => {
          if (isEditModeEnable(record.id.toString())) {
            const dateStringValue = record.dateStart
              ? new Date(record.dateStart).toLocaleDateString('fr-CA')
              : '';

            return (
              <DateInput
                size="s"
                max={record.dateFinish}
                value={dateStringValue}
                onChange={(e) => onFieldChange(record.id, 'dateStart', e.target.value)}
              />
            );
          }

          return record.dateStart ? new Date(record.dateStart).toLocaleDateString('ru-RU') : '';
        },
      },
      [AlertAttributeColumns.DATE_FINISH]: {
        message: 'subscriptionEndDate',
        isSortable: false,
        render: (record: ISubscription) => {
          if (isEditModeEnable(record.id.toString())) {
            const dateStringValue = record.dateFinish
              ? new Date(record.dateFinish).toLocaleDateString('fr-CA')
              : '';

            return (
              <DateInput
                size="s"
                min={record.dateStart}
                value={dateStringValue}
                onChange={(e) => onFieldChange(record.id, 'dateFinish', e.target.value)}
              />
            );
          }

          return record.dateFinish ? new Date(record.dateFinish).toLocaleDateString('ru-RU') : '';
        },
      },
      [AlertAttributeColumns.SOURCE]: {
        message: 'source',
        isSortable: false,
        render: (record: ISubscription) => {
          if (isEditModeEnable(record.id.toString())) {
            return (
              <Select
                size="s"
                value={record.sourceId || ''}
                onChange={(e) => onFieldChange(record.id, 'sourceId', e.target.value)}
                options={sourceOptions.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                placeholder=" "
              />
            );
          }

          return record.source?.name || '';
        },
      },
      [AlertAttributeColumns.CATEGORY_ID]: {
        message: 'category',
        isSortable: false,
        render: (record: ISubscription) => {
          if (isEditModeEnable(record.id.toString())) {
            return (
              <Select
                size="s"
                value={record.categoryId || ''}
                onChange={(e) => onFieldChange(record.id, 'categoryId', e.target.value)}
                options={categoryCostTypesOptions}
                placeholder=" "
              />
            );
          }

          return record.category?.name || '';
        },
      },
      [AlertAttributeColumns.TYPE_ID]: {
        message: 'type',
        isSortable: false,
        render: (record: ISubscription) => {
          if (isEditModeEnable(record.id.toString())) {
            return (
              <Select
                isDisabled={!record.categoryId}
                size="s"
                value={record.typeId || ''}
                onChange={(e) => onFieldChange(record.id, 'typeId', e.target.value)}
                options={getTypeCostTypesOptions(record.categoryId || '')}
                placeholder=" "
              />
            );
          }

          return record.type?.name || '';
        },
      },
      [AlertAttributeColumns.SUBTYPE_ID]: {
        message: 'subType',
        isSortable: false,
        render: (record: ISubscription) => {
          if (isEditModeEnable(record.id.toString())) {
            return (
              <Select
                isDisabled={!record.typeId}
                size="s"
                value={record.subtypeId || ''}
                onChange={(e) => onFieldChange(record.id, 'subtypeId', e.target.value)}
                options={getSubtypeCostTypesOptions(record.categoryId || '', record.typeId || '')}
                placeholder=" "
              />
            );
          }

          return record.subtype?.name || '';
        },
      },
      [AlertAttributeColumns.SUBSCRIPTION_TYPE]: {
        message: 'subscriptionType',
        isSortable: false,
        render: (record: ISubscription) => {
          if (isEditModeEnable(record.id.toString())) {
            return (
              <Select
                size="s"
                value={record.subscriptionType}
                onChange={(e) => onFieldChange(record.id, 'subscriptionType', e.target.value)}
                options={subscriptionTypeOptions.map((item) => ({
                  ...item,
                  label: translate(item.label),
                }))}
                placeholder=" "
              />
            );
          }

          return translate(
            subscriptionTypeOptions.find((item) => item.value === record.subscriptionType)?.label ||
              ''
          );
        },
      },
      [AlertAttributeColumns.VALUE]: {
        message: 'indicatorValue',
        isSortable: false,
        render: (record: ISubscription) => {
          if (isEditModeEnable(record.id.toString())) {
            return (
              <TextInput
                size="s"
                value={record.value}
                onChange={(e) => onFieldChange(record.id, 'value', e.target.value)}
              />
            );
          }

          return record.value;
        },
      },
      [AlertAttributeColumns.TERM]: {
        message: 'analyzedDaysPeriod',
        isSortable: false,
        render: (record: ISubscription) => {
          if (isEditModeEnable(record.id.toString())) {
            return (
              <TextInput
                size="s"
                value={record.term}
                onChange={(e) => onFieldChange(record.id, 'term', e.target.value)}
              />
            );
          }

          return record.term;
        },
      },
    }),
    [
      categoryCostTypesOptions,
      getSubtypeCostTypesOptions,
      getTypeCostTypesOptions,
      isEditModeEnable,
      onFieldChange,
      sourceOptions,
      translate,
    ]
  );

  return columnTemplate;
};
