import React, { PropsWithChildren } from 'react';
import { Tooltip } from '@mozaic-ds/react';

export type TooltipButtonWrapperProps = {
  isValid: boolean | undefined;
  message: string | undefined;
};

export const TooltipButtonWrapper = ({
  isValid,
  message,
  children,
}: PropsWithChildren<TooltipButtonWrapperProps>) => {
  return !isValid && message ? (
    <Tooltip label={message} placement="bottom" className="mu-ml-025">
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};
