import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';
import { IFilter } from 'common/types';
import {
  ExternalSourceDeletedFilterName,
  ExternalSourceFilterName,
} from 'pages/cbd/ExternalSource/ExternalSource.types';

interface SliceState {
  filters: IFilter<ExternalSourceFilterName>[];
  deletedFilters: ExternalSourceDeletedFilterName[];
  searchValue: string;
}

export const externalSourceFiltersSlice = createSlice({
  initialState: {
    filters: [],
    deletedFilters: [],
    searchValue: '',
  },
  name: 'externalSourceFilters',
  reducers: {
    clearExternalSourceDeletedFilters(state) {
      state.deletedFilters = [];
    },
    changeExternalSourceDeletedFilters(state, action) {
      state.deletedFilters = state.deletedFilters.concat(action.payload);
    },
    setExternalSourceFilters(state, action) {
      state.filters = action.payload;
    },
  },
});

export const {
  setExternalSourceFilters,
  changeExternalSourceDeletedFilters,
  clearExternalSourceDeletedFilters,
} = externalSourceFiltersSlice.actions;

export const useExternalSourceFiltersData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.cbd.externalData.analysis.filters) };
};
