import { Translations } from 'translations';

export function gtmDownloadTemplate(t: Translations, id: string) {
  window.dataLayer.push({
    event: 'LeroyMerlin',
    eventCategory: 'click-button', // действие-элемент
    eventAction: t('request'), // блок, в котором находится кнопка
    eventLabel: t('downloadTemplate'), // текст на кнопке
    eventRequestID: id, // ID запроса
  });
}
