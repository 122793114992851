import React, { useCallback, useEffect, useRef } from 'react';
import {
  Button,
  DateInput,
  Flex,
  Loader,
  Notification,
  Text,
  TextArea,
  TextInput,
  View,
} from '@mozaic-ds/react';
import { Label } from 'components/Label/Label';
import Dropdown from 'components/Dropdown/Dropdown';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import {
  getLinkedSuppliersId,
  setSearchValue,
  useLinkedSuppliersIdData,
} from 'store/dictionary/linkedSuppliersId';
import cn from 'classnames';
import { useAppTranslations } from 'translations';
import { useAppDispatch } from 'store/hooks';
import { getOtherSelectValue } from 'store/dictionary/reasons';
import { isProd, isSupplierEnvironment, technicalRequestStatuses } from 'common/consts';
import { useNavigate } from 'react-router-dom';
import OverlayCustom from 'components/OverlayCustom/Overlay';
import { setError, useRequestCreationData } from 'store/requestCreation/requestCreation';
import { useMount } from 'hook/useMount';
import { DeleteRequest } from 'components/DeleteRequest/DeleteRequest';
import { getHasGrants } from 'common/utils';
import { parseRequestErrorMessage } from 'pages/Request/helpers';
import { gtmFocusField } from '../../gtm';
import { useCreateFormRequest, useGetCreationRequestErrors, useGetFormData } from './hooks';
import styles from '../../CreatingRequest.module.scss';

export const RequestCreationForm = () => {
  const translations = useAppTranslations();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const controller = useRef<AbortController>(new AbortController());

  const { reasons, dueDate: date } = useGetFormData();
  const {
    request,
    isLoading,
    isFailure,
    error: requestCreationError,
    grants,
  } = useRequestCreationData().data;
  const { linkedSuppliersId, searchValue } = useLinkedSuppliersIdData().data;
  const { min, max } = date;
  const {
    reasonListId,
    customReason,
    dueDate,
    supplierId,
    comment,
    watch,
    handleSubmit,
    submitForm,
    formState,
    getValues,
    resetField,
  } = useCreateFormRequest();

  const { errors } = useGetCreationRequestErrors(supplierId.value.value);
  const { RequestDelete } = getHasGrants(grants);

  const isOtherReason = watch('reasonListId').find(
    ({ value }) => value === getOtherSelectValue(translations).value
  );
  const { isValid } = formState;

  useEffect(() => {
    controller.current.abort();
    controller.current = new AbortController();
    dispatch(
      getLinkedSuppliersId({ searchString: searchValue, signal: controller.current.signal })
    );
  }, [dispatch, searchValue]);

  useMount(() => {
    return () => {
      dispatch(setSearchValue(''));
    };
  });

  useEffect(() => {
    if (!isOtherReason && getValues('customReason')) {
      resetField('customReason');
    }
  }, [getValues, isOtherReason, resetField]);

  const gtmHandleOnFocus = useCallback(
    (label: string, reasonsIds?: string[]) => {
      if (isSupplierEnvironment && isProd)
        gtmFocusField({
          t: translations,
          label: translations(label),
          reasons: reasonsIds || null,
        });
    },
    [translations]
  );

  return (
    <>
      <OverlayCustom isVisible={isLoading}>
        <Flex direction="column" justifyContent="center" alignItems="center">
          <Loader />
          <Text>{translations('initializationRequestCreationProcess')}</Text>
        </Flex>
      </OverlayCustom>
      <Text weight="semi-bold" theme="dark" as="p" className="mu-mb-150" size="xl">
        {translations('requestCreationFormTitle')}
      </Text>
      {Boolean(requestCreationError) && (
        <Notification
          onClose={isFailure ? () => dispatch(setError('')) : undefined}
          isClosable
          className="mu-mb-125"
          message={parseRequestErrorMessage(requestCreationError).map((message) => (
            <Text as="p" key={message}>
              {message}
            </Text>
          ))}
          theme="danger"
        />
      )}
      {request && technicalRequestStatuses.includes(request.statusCode) && (
        <Notification
          isClosable
          className="mu-mb-125"
          message={translations('waitingInitializeRequestNotification')}
          theme="warning"
        />
      )}
      <Flex direction="row" justifyContent="space-between" marginBottom="mu250">
        <Flex direction="column" className={styles.basis}>
          <Label text={translations('requestReasonLabel')} subText={translations('requiredFiled')}>
            <Dropdown
              isDisabled={Boolean(request?.reasonListId)}
              {...reasonListId}
              onFocus={() =>
                gtmHandleOnFocus(
                  'requestReasonLabel',
                  getValues('reasonListId').map(({ label }) => label)
                )
              }
              placeholder={translations('selectPlaceholder')}
              listBoxItems={reasons}
              mode="multi"
            />
          </Label>
          {isOtherReason && (
            <Label text={translations('otherReason')}>
              <TextInput
                className="mu-mt-025 mu-mb-100"
                isDisabled={Boolean(request?.customReason)}
                {...customReason}
                placeholder={translations('otherReasonPlaceholder')}
                size="m"
                onFocus={() => gtmHandleOnFocus('otherReason')}
              />
            </Label>
          )}
          <Label text={translations('department')} subText={translations('requiredFiled')}>
            <Autocomplete
              isDisabled={Boolean(request?.supplierId)}
              {...supplierId}
              emptyMessageText={translations('emptySupplierLinks')}
              placeholder={translations('selectPlaceholder')}
              listBoxItems={linkedSuppliersId}
              handleSearch={setSearchValue}
              isShownListBoxOnClick
              onFocus={() => gtmHandleOnFocus('department')}
            />
            {supplierId.value?.value && errors && errors.length > 0 && (
              <Notification
                className={cn('mu-mt-100', styles.notification)}
                theme="danger"
                message={
                  <>
                    {errors &&
                      errors.map(({ error, requestId }) => (
                        <Text size="s" as="p" key={requestId}>
                          {error}
                        </Text>
                      ))}
                  </>
                }
              />
            )}
          </Label>
          <Label
            text={translations('requestApplicationDateLabel')}
            subText={translations('requiredFiled')}
          >
            <DateInput
              className="mu-mt-025 mu-mb-025"
              isDisabled={Boolean(request?.dueDate)}
              {...dueDate}
              min={min}
              max={max}
              onFocus={() => gtmHandleOnFocus('requestApplicationDateLabel')}
            />
            <Text theme="danger" size="s">
              {formState.errors.dueDate?.message}
            </Text>
          </Label>
        </Flex>
        <Flex direction="column" className={styles.basis}>
          <Label text={translations('requestCommentLabel')}>
            <TextArea
              className={cn(styles.textAria, 'mu-mt-025')}
              id="text-area"
              placeholder={translations('commentPlaceholder')}
              {...comment}
              onFocus={() => gtmHandleOnFocus('requestCommentLabel')}
            />
          </Label>
        </Flex>
      </Flex>
      <hr />
      <Flex justifyContent={RequestDelete ? 'space-between' : 'flex-end'} marginTop="mu250">
        {RequestDelete && request?.id && <DeleteRequest id={request.id} theme="neutral" />}
        <View>
          <Button
            isDisabled={!isValid || Boolean(request)}
            type="submit"
            className="mu-mr-100"
            size="m"
            width="fit"
            onClick={handleSubmit((data) => submitForm(data))}
          >
            {translations(isSupplierEnvironment ? 'continue' : 'saveAsDraft')}
          </Button>
          {!request && (
            <Button
              type="button"
              variant="bordered"
              theme="danger"
              size="m"
              width="fit"
              onClick={() => navigate('/')}
            >
              {translations('cancelRequestCreation')}
            </Button>
          )}
        </View>
      </Flex>
    </>
  );
};
