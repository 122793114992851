import { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { getReasons, useReasonsData } from 'store/dictionary/reasons';
import { getStatus, useStatusData } from 'store/dictionary/status';
import { resetStore as resetRequestsStore } from 'store/requests/requests';
import { useAppTranslations } from 'translations';
import { clear } from 'store/dictionary/supplierIds';
import { resetFilterStore } from 'store/requests/filters';
import { resetPossibilityCreationPriceListStore } from 'store/priceList/possibility/creation';

export const useRequests = () => {
  const dispatch = useAppDispatch();
  const t = useAppTranslations();

  const { reasons } = useReasonsData().data;
  const { status } = useStatusData().data;

  useEffect(() => {
    if (!reasons.length) dispatch(getReasons(t));
    if (!status.length) dispatch(getStatus());

    return () => {
      dispatch(clear());
      dispatch(resetRequestsStore());
      dispatch(resetFilterStore());
      dispatch(resetFilterStore());
      dispatch(resetPossibilityCreationPriceListStore());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
};
