import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Header } from 'components/Header/Header';
import { getNavigation } from 'components/Header/helpers';

const Layout = (): JSX.Element => {
  const { pathname } = useLocation();

  return (
    <>
      <Header path={pathname} navigation={getNavigation(pathname)} />
      <main className="mu-ml-300 mu-mr-300">
        <Outlet />
      </main>
    </>
  );
};

export default Layout;
