import {
  GetReplacementLink,
  GetReplacementLinkResponse,
  CreateReplacementLink,
  CreateReplacementLinkBody,
  CreateReplacementLinkResponse,
  DeleteReplacementLink,
} from '@ppm/ppm-platform-api';
import { apiCreator } from 'api/index';

export const getReplacement = apiCreator<typeof GetReplacementLink, GetReplacementLinkResponse>(
  GetReplacementLink
)[GetReplacementLink.method];

export const createReplacement = apiCreator<
  typeof CreateReplacementLink,
  CreateReplacementLinkResponse,
  undefined,
  CreateReplacementLinkBody
>(CreateReplacementLink)[CreateReplacementLink.method];

export const deleteReplacement = apiCreator<
  typeof DeleteReplacementLink,
  DeleteReplacementLink.Response,
  undefined,
  DeleteReplacementLink.Request
>(DeleteReplacementLink)[DeleteReplacementLink.method];
