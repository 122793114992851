import React, { createContext, useContext, useMemo, PropsWithChildren } from 'react';
import { io } from 'socket.io-client';
import { useSocketConnection } from 'components/SocketContainer/useSocketConnection';
import { SocketContextProps, SocketContextProviderProps } from './types';

const SocketContext = createContext({});

export function useSocketContext(): SocketContextProps {
  const context = useContext<SocketContextProps>(
    SocketContext as unknown as React.Context<SocketContextProps>
  );
  if (!context) {
    throw new Error('useMyContext must be used under MyContextProvider');
  }
  return context;
}

export const SocketContextProvider = ({
  children,
  id,
  namespace,
  keyParam,
  path,
}: PropsWithChildren<SocketContextProviderProps>) => {
  const socket = useMemo(() => {
    const ws = io(`wss://${process.env.REACT_APP_API_HOST}${namespace}?${keyParam}=${id}`, {
      transports: ['websocket'],
      retries: 3,
      autoConnect: false,
      path,
    });
    return { socket: ws };
  }, [namespace, keyParam, id, path]);

  useSocketConnection({ socket: socket.socket, id, namespace, keyParam });

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
