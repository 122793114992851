import React, { FC } from 'react';
import { Flex } from '@mozaic-ds/react';
import classNames from 'classnames';
import styles from './ToggleWithLabels.module.scss';

interface IToggleWithLabels {
  activeValue: string;
  values: [string, string];
  labels: [string, string];
  onChange: () => void;
}

const ToggleWithLabels: FC<IToggleWithLabels> = ({ activeValue, values, labels, onChange }) => (
  <Flex marginLeft="mu150" onClick={onChange} className={styles.toggle}>
    <Flex
      className={classNames({
        [styles['toggle-isActive']]: activeValue === values[0],
        [styles['toggle-element']]: true,
      })}
    >
      {labels[0]}
    </Flex>
    <Flex
      className={classNames({
        [styles['toggle-isActive']]: activeValue === values[1],
        [styles['toggle-element']]: true,
      })}
    >
      {labels[1]}
    </Flex>
  </Flex>
);

export default ToggleWithLabels;
