import { IColumnTemplate } from 'common/utils';
import { useSuppliers } from 'store/cbd/suppliers';
import { CompareColumns } from './consts';
import { ICompareTable } from '../CompareTable.types';

export const useGetColumnTemplate = (): {
  [key in CompareColumns]: IColumnTemplate<ICompareTable>;
} => {
  const { suppliers } = useSuppliers();

  const columnTemplate = {
    [CompareColumns.ID]: {
      message: 'cbdNumber',
      isSortable: true,
      render: (record: ICompareTable) => record.id,
    },
    [CompareColumns.DATE_CBD]: {
      message: 'dateCbd',
      isSortable: true,
      render: (record: ICompareTable) =>
        record.dateCbd ? new Date(record.dateCbd).toLocaleDateString() : '',
    },
    [CompareColumns.SUPPLIER_NAME]: {
      message: 'supplierName',
      isSortable: false,
      render: (record: ICompareTable) =>
        suppliers.find((item) => item.supplierId === record.supplierId)?.name || '',
    },
    [CompareColumns.SUPPLIER_ID]: {
      message: 'supplierIds',
      isSortable: false,
      render: (record: ICompareTable) => (record.supplierId ? record.supplierId : ''),
    },
    [CompareColumns.GROUP]: {
      message: 'productGroup',
      isSortable: false,
      render: (record: ICompareTable) => record.group,
    },
    [CompareColumns.DEP_NAME]: {
      message: 'devision',
      isSortable: false,
      render: (record: ICompareTable) => record.depName,
    },
    [CompareColumns.SUB_DEP_NAME]: {
      message: 'subDevision',
      isSortable: false,
      render: (record: ICompareTable) => record.subDepName,
    },
    [CompareColumns.CLASS_NAME]: {
      message: 'type',
      isSortable: false,
      render: (record: ICompareTable) => record.className,
    },
    [CompareColumns.SUB_CLASS_NAME]: {
      message: 'subType',
      isSortable: false,
      render: (record: ICompareTable) => record.subClassName,
    },
  };

  return columnTemplate;
};
