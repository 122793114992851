import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllUploadsColumns } from 'pages/cbd/AllUploads/hooks/consts';
import { RootState } from 'store/types';
import { useAppSelector } from 'store/hooks';
import { IAllUploadsColumnSort } from 'pages/cbd/AllUploads/AllUploads.types';
import { SortDirection } from 'common/types';

type SortValues = { field: IAllUploadsColumnSort; sortDirection: SortDirection };
export type AllUploadsSortedField = null | SortValues;
export type AllUploadsColumn = {
  key: AllUploadsColumns;
  isShown: boolean;
};

interface SliceState {
  columns: AllUploadsColumn[];
  sortedField: AllUploadsSortedField;
}

export const columnsSlice = createSlice({
  initialState: {
    sortedField: null,
    columns: Object.values(AllUploadsColumns).map((item) => ({
      key: item,
      isShown: true,
    })),
  },
  name: 'columns',
  reducers: {
    setAllUploadsSortable(state: SliceState, action: PayloadAction<SortValues>) {
      state.sortedField = action.payload;
    },
    setVisibleAllUploadsColumns(
      state: SliceState,
      action: PayloadAction<Record<AllUploadsColumns, boolean>>
    ) {
      state.columns = state.columns.map((column) => {
        return { ...column, isShown: action.payload[column.key] };
      });
    },
  },
});

export const { setVisibleAllUploadsColumns, setAllUploadsSortable } = columnsSlice.actions;

export const useColumnsData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.cbd.allUploads.columns) };
};
