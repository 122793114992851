import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';
import { IFilter } from 'common/types';
import { PurchasePriceFilterName } from 'pages/cbd/CbdAnalysis/components/Comparison/filters/PurchasePriceFilters/usePurchasePriceFiltersForm';

interface SliceState {
  filters: IFilter<PurchasePriceFilterName>[];
  deletedFilters: PurchasePriceFilterName[];
}

const initialState: SliceState = {
  filters: [
    {
      id: 'traceableComponent',
      value: [],
      label: '',
    },
  ],
  deletedFilters: [],
};

export const filtersSlice = createSlice({
  initialState,
  name: 'purchasePriceFilters',
  reducers: {
    clearPurchasePriceDeletedFilters(state) {
      state.deletedFilters = [];
    },
    setPurchasePriceFilters(state, action) {
      state.filters = action.payload;
    },
    clearPurchasePriceFilters() {
      return initialState;
    },
  },
});

export const {
  setPurchasePriceFilters,
  clearPurchasePriceDeletedFilters,
  clearPurchasePriceFilters,
} = filtersSlice.actions;

export const usePurchasePriceFiltersData = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.cbdAnalysis.compare.purchasePrice.filters);
};
