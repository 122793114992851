import { useController, useForm } from 'react-hook-form';
import { lastDayOfYear } from 'date-fns';
import { useUserData } from 'store/auth/user';
import { useSubrequests } from 'store/subrequests/subrequests';
import { yupResolver } from '@hookform/resolvers/yup';
import { parseDateForMozaicComponent } from 'common/utils';
import { schema } from './schema';
import { CreatePricelistFields } from './types';

const useDefaultFormValue = (): CreatePricelistFields | undefined => {
  const { data } = useUserData().user;
  const { subrequests } = useSubrequests().data;

  return {
    ldap: data.login,
    subrequests: subrequests.map(({ id }) => ({ isChecked: false, id })),
    contractNumber: '',
    contractStartDate: '',
    pricelistNumber: '',
    pricelistStartDate: '',
    pricelistEndDate: parseDateForMozaicComponent(lastDayOfYear(new Date())),
  };
};

export const useCreatePriceListForm = () => {
  const defaultValues = useDefaultFormValue();
  const form = useForm<CreatePricelistFields>({
    criteriaMode: 'all',
    mode: 'all',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { control, handleSubmit } = form;

  const { field: ldap } = useController({ name: 'ldap', control });
  const { field: contractNumber } = useController({ name: 'contractNumber', control });
  const { field: contractStartDate } = useController({ name: 'contractStartDate', control });
  const { field: pricelistNumber } = useController({ name: 'pricelistNumber', control });
  const { field: pricelistStartDate } = useController({
    name: 'pricelistStartDate',
    control,
    rules: { deps: 'pricelistEndDate' },
  });
  const { field: pricelistEndDate } = useController({
    name: 'pricelistEndDate',
    control,
    rules: { deps: 'pricelistStartDate' },
  });

  return {
    form,
    handleSubmit,
    ldap,
    contractNumber,
    contractStartDate,
    pricelistNumber,
    pricelistStartDate,
    pricelistEndDate,
  };
};
