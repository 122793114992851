import {
  GetNomenclatureHierarchyTree,
  GetNomenclatureHierarchyTreeResponse,
} from '@ppm/ppm-platform-api';
import { apiCreator } from 'api/index';

export const getNomenclature = apiCreator<
  typeof GetNomenclatureHierarchyTree,
  GetNomenclatureHierarchyTreeResponse
>(GetNomenclatureHierarchyTree)[GetNomenclatureHierarchyTree.method];
