import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';
import { getFirstExtCurrency, getSecondExtCurrency } from 'api/cbd/selects';
import { IGetSelectsResponse } from 'common/cbd/types';
import { StringSelectItem } from 'common/types';

interface SliceState {
  fromCurrenciesList: StringSelectItem[];
  toCurrenciesList: StringSelectItem[];
}

const initialState: SliceState = {
  fromCurrenciesList: [],
  toCurrenciesList: [],
};

export const getFromCurrenciesList = createAsyncThunk(
  'extCurrency/getFromCurrenciesList',
  async (_, { rejectWithValue }) => {
    try {
      return await getFirstExtCurrency();
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getToCurrenciesList = createAsyncThunk(
  'extCurrency/getToCurrenciesList',
  async (_, { rejectWithValue }) => {
    try {
      return await getSecondExtCurrency();
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const extCurrencySlice = createSlice({
  initialState,
  name: 'extCurrency',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getFromCurrenciesList.fulfilled,
        (state: SliceState, action: PayloadAction<IGetSelectsResponse>) => {
          state.fromCurrenciesList = action.payload.data;
        }
      )
      .addCase(
        getToCurrenciesList.fulfilled,
        (state: SliceState, action: PayloadAction<IGetSelectsResponse>) => {
          state.toCurrenciesList = action.payload.data;
        }
      );
  },
});

export const useExtCurrency = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.extCurrency);
};
