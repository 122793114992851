import { isSupplierEnvironment } from 'common/consts';
import { Translations } from 'translations';

export function gtmPageView(t: Translations, userId: number) {
  window.dataLayer.push({
    event: 'LeroyMerlin_Pageview',
    userId,
    role: t(`${isSupplierEnvironment ? 'supplier' : 'internal'}`),
  });
}
