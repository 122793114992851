import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IGetCbdAnalysisPayload, getCbdAnalysisInfoReq } from 'api/cbd/cbdAnalysis';
import { ICbdAnalysisInfo } from 'common/cbd/types';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

export const cbdTypeValues: [string, string] = ['competitor', 'supplier'];
export const structureTypeValues: [string, string] = ['prime_cost', 'purchase_price'];

interface SliceState {
  data: ICbdAnalysisInfo | null;
  cbdType: string;
  structureType: string;
}

const initialState: SliceState = {
  data: null,
  cbdType: cbdTypeValues[0],
  structureType: structureTypeValues[0],
};

export const getCbdAnalysisInfo = createAsyncThunk(
  'cbd/cbdAnalysis/getCbdAnalysisInfo',
  async (payload: IGetCbdAnalysisPayload, { rejectWithValue }) => {
    try {
      return await getCbdAnalysisInfoReq(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const cbdAnalysisInfoSlice = createSlice({
  initialState,
  name: 'cbdAnalysisInfo',
  reducers: {
    setCbdType: (state, action: PayloadAction<string>) => {
      state.cbdType = action.payload;
    },
    setStructureType: (state, action: PayloadAction<string>) => {
      state.structureType = action.payload;
    },
    clearCbdAnalysisInfoSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCbdAnalysisInfo.fulfilled,
      (state: SliceState, action: PayloadAction<ICbdAnalysisInfo>) => {
        state.data = action.payload;
      }
    );
  },
});

export const { setCbdType, setStructureType, clearCbdAnalysisInfoSlice } =
  cbdAnalysisInfoSlice.actions;

export const useCbdAnalysisInfoInfoData = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.cbdAnalysis.cbdAnalysisInfo);
};
