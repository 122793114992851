import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RequestModelView } from '@ppm/ppm-platform-api';
import { useRequestData } from 'store/request/request';
import { useReasonsData } from 'store/dictionary/reasons';
import { useMemo } from 'react';
import { getHasGrants } from 'common/utils';
import { useAppTranslations } from 'translations';
import { calculatePermissions, createDefaultValue } from 'pages/Request/helpers';
import { Grants } from '@ppm/ppm-platform-user-service';
import { getSchema } from './schema';
import { RequestFormFields } from './types';

export const useDefaultFormValue = (): RequestFormFields => {
  const t = useAppTranslations();
  const { request } = useRequestData().data;
  const { reasons } = useReasonsData().data;
  const requestData = request as RequestModelView;
  return useMemo(() => {
    return createDefaultValue(requestData, reasons, t);
  }, [reasons, requestData, t]);
};

export const useEditFormRequest = () => {
  const t = useAppTranslations();
  const { reasons } = useReasonsData().data;
  const defaultValues = useDefaultFormValue();

  return useForm<RequestFormFields>({
    criteriaMode: 'all',
    resolver: yupResolver(getSchema(reasons, t)),
    mode: 'onChange',
    defaultValues,
  });
};

export const useGetGrants = () => {
  const { requestGrants, selectedContainers, containers } = useRequestData().data;

  const hasSubRequestGrant = useMemo(() => {
    if (selectedContainers.length === 0) return getHasGrants({});
    return getHasGrants(calculatePermissions(selectedContainers, 'grants'));
  }, [selectedContainers]);

  const hasRequestGrants = useMemo(() => {
    return getHasGrants(requestGrants);
  }, [requestGrants]);

  const isRequestDeleteGrant = useMemo(() => {
    return (
      Grants.RequestDelete in requestGrants ||
      containers.some(({ flowRights }) => Grants.RequestDelete in flowRights)
    );
  }, [containers, requestGrants]);

  return { hasSubRequestGrant, hasRequestGrants, isRequestDeleteGrant };
};
