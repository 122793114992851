import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestSearchableFields } from '@ppm/ppm-platform-request-service';
import { COLUMNS } from 'pages/Requests/hooks/consts';
import { RootState } from '../types';
import { useAppSelector } from '../hooks';

type Column = {
  key: COLUMNS;
  isShown: boolean;
};
type SortDirection = 'asc' | 'desc' | undefined;
type SortedField = null | { field: RequestSearchableFields; sortDirection: SortDirection };

interface SliceState {
  columns: Column[];
  sortedField: SortedField;
}

export const columnsSlice = createSlice({
  initialState: {
    sortedField: null,
    columns: [
      {
        key: COLUMNS.ID,
        isShown: true,
      },
      {
        key: COLUMNS.SUPPLIER_ID,
        isShown: true,
      },
      {
        key: COLUMNS.SUPPLIER_NAME,
        isShown: true,
      },
      {
        key: COLUMNS.REASON_LIST_ID,
        isShown: true,
      },
      {
        key: COLUMNS.STATUS_ID,
        isShown: true,
      },
      {
        key: COLUMNS.DUE_DATE,
        isShown: true,
      },
      {
        key: COLUMNS.DEADLINE_DATE,
        isShown: true,
      },
      {
        key: COLUMNS.CREATE_DATE,
        isShown: true,
      },
    ],
  },
  name: 'columns',
  reducers: {
    setSortable(
      state: SliceState,
      action: PayloadAction<{
        field: RequestSearchableFields;
        sortDirection: SortDirection;
      }>
    ) {
      state.sortedField = action.payload;
    },
    setVisibleColumns(state: SliceState, action: PayloadAction<Record<COLUMNS, boolean>>) {
      state.columns = state.columns.map((column) => {
        return { ...column, isShown: action.payload[column.key] };
      });
    },
  },
});

export const { setVisibleColumns, setSortable } = columnsSlice.actions;

export const useColumnsData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.requests.columns) };
};
