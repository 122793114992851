import { useController, useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { useAppTranslations } from 'translations';
import { clearBoundDeletedFilters, setBoundFilters } from 'store/cbd/bound/filters';
import { BoundFilterName } from '../Bound.types';
import { getLabel } from './consts';

export const useFiltersForm = () => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const form = useForm({
    defaultValues: {
      itemGroup: '',
      itemDep: '',
      itemClass: '',
      itemSubclass: '',
    },
  });

  const { control, handleSubmit } = form;

  const { field: itemGroup } = useController({ name: 'itemGroup', control });
  const { field: itemDep } = useController({ name: 'itemDep', control });
  const { field: itemClass } = useController({ name: 'itemClass', control });
  const { field: itemSubclass } = useController({ name: 'itemSubclass', control });

  const onSubmit = useCallback(() => {
    handleSubmit((data) => {
      const keys = Object.keys(data);
      dispatch(clearBoundDeletedFilters());
      dispatch(
        setBoundFilters(
          keys
            .map((key) => ({
              id: key,
              value: data[key as BoundFilterName],
              label: getLabel(key as BoundFilterName, translations),
            }))
            .filter(({ value }) => (Array.isArray(value) ? value.length > 0 : Boolean(value)))
        )
      );
    })();
  }, [dispatch, handleSubmit, translations]);

  return {
    form,
    itemGroup,
    itemDep,
    itemClass,
    itemSubclass,
    onSubmit,
  };
};
