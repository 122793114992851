import React from 'react';
import { IconButton } from '@mozaic-ds/react';
import { PublishEdit24, NotificationAvailable24, ControlCross24 } from '@mozaic-ds/icons/react';

type FnEditAttributeButton = {
  isEditing: boolean;
  isFormOpeningDisabled: boolean;
  openForm: () => void;
  closeForm: () => void;
  updateAttribute: () => void;
};

const EditAttributeButton = ({
  isEditing,
  isFormOpeningDisabled,
  openForm,
  closeForm,
  updateAttribute,
}: FnEditAttributeButton) => {
  if (!isEditing) {
    return (
      <IconButton
        isDisabled={isFormOpeningDisabled}
        className="mu-mr-100"
        onClick={openForm}
        theme="primary"
        variant="bordered"
        size="s"
      >
        <PublishEdit24 />
      </IconButton>
    );
  }

  return (
    <>
      <IconButton
        className="mu-mr-100"
        onClick={updateAttribute}
        theme="primary"
        variant="solid"
        size="s"
      >
        <NotificationAvailable24 />
      </IconButton>
      <IconButton
        className="mu-mr-100"
        onClick={closeForm}
        theme="danger"
        variant="bordered"
        size="s"
      >
        <ControlCross24 />
      </IconButton>
    </>
  );
};

export default EditAttributeButton;
