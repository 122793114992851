import { SupplierBsgLink } from '@ppm/ppm-platform-api';
import { BuyerSiteGroupHierarchy } from 'store/buyerSiteGroup/buyerSiteGroupHierarchy';
import { FEDERAL_TYPE_NUMBER, HUB_TYPE_NUMBER } from './consts';

export const formatLinksToFormFields = ({
  links,
  hierarchy,
}: {
  links: SupplierBsgLink[];
  hierarchy: BuyerSiteGroupHierarchy;
}) => {
  return links.map(({ supplierDepartmentId, supplierId, buyerSiteGroupIds, supplierName }) => ({
    supplierName,
    supplierId,
    supplierDepartmentId,
    link: hierarchy.map(({ buyerGroupSite }) => ({
      id: buyerGroupSite.id,
      isFederal: buyerGroupSite.buyerGroupSiteType.externalId === FEDERAL_TYPE_NUMBER,
      isHub: buyerGroupSite.buyerGroupSiteType.externalId === HUB_TYPE_NUMBER,
      isChecked: buyerSiteGroupIds.includes(buyerGroupSite.id),
    })),
  }));
};
