import { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { getRequests, setPageNumber, useRequestsData } from 'store/requests/requests';
import { useTrackVisibility } from 'react-intersection-observer-hook';
import { DOWNLOAD_LIMIT_REQUESTS } from 'pages/Requests/Requests.const';
import { GetRequestListQuery } from '@ppm/ppm-platform-api';

export const usePaginationRequests = (queryParams: GetRequestListQuery) => {
  const dispatch = useAppDispatch();

  const { pageNumber, isLastPage, isLoading } = useRequestsData().data;

  const [ref, { isVisible }] = useTrackVisibility();
  useEffect(() => {
    if (isLastPage) return;
    if (isVisible && !isLoading) {
      dispatch(setPageNumber(pageNumber + 1));
      dispatch(
        getRequests({ ...queryParams, page: pageNumber + 1, limit: DOWNLOAD_LIMIT_REQUESTS })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return { ref };
};
