import React, { useCallback } from 'react';
import { Button, Flex } from '@mozaic-ds/react';
import { useAppTranslations } from 'translations';
import { setNeededGeneration, useCurrentPriceListData } from 'store/priceList/currentPriceList';
import { getHasGrants, getTransitionKeyByGrant } from 'common/utils';
import { Grants } from '@ppm/ppm-platform-user-service';
import { executePricelistFlow } from 'api/pricelist';
import { useAppDispatch } from 'store/hooks';
import { useNavigate } from 'react-router-dom';
import { getRequest } from 'store/request/request';

export const DeletePriceListButton = () => {
  const dispatch = useAppDispatch();
  const t = useAppTranslations();

  const navigate = useNavigate();

  const { priceList, grants, transitions } = useCurrentPriceListData().data;
  const { requestId: id, id: priceListId } = priceList;
  const hasGrant = getHasGrants(grants);
  const { PricelistTransitionToArchive } = hasGrant;
  const pricelistArchiveTransitionState = getTransitionKeyByGrant(
    transitions,
    Grants.PricelistTransitionToArchive
  );

  const handlePriceListFlow = useCallback(async () => {
    if (!pricelistArchiveTransitionState.transitionStatus) return null;
    try {
      return await executePricelistFlow({
        urlParameters: [id, priceListId, pricelistArchiveTransitionState.transitionStatus],
      }).then(() => {
        dispatch(setNeededGeneration(false));
        navigate(`/request/${id}`);
        dispatch(getRequest(id));
        return null;
      });
    } catch (e) {
      return e;
    }
  }, [dispatch, id, navigate, priceListId, pricelistArchiveTransitionState.transitionStatus]);

  return (
    <>
      {PricelistTransitionToArchive && (
        <Flex alignItems="center">
          <Button theme="danger" size="s" onClick={handlePriceListFlow}>
            {t('deletePricelist')}
          </Button>
        </Flex>
      )}
    </>
  );
};
