import React, { FC } from 'react';
import { Button, Flex, Loader, Text } from '@mozaic-ds/react';
import { useAppTranslations } from 'translations';
import { useStepperContext } from 'components/Stepper/StepperContext';
import { DeleteRequest } from 'components/DeleteRequest/DeleteRequest';
import { Steps } from 'pages/CreatingRequest/types';
import { Block, FileTemplate } from 'pages/CreatingRequest/Steps/FileContainer/FileTemplate';
import { DownloadWeb24 } from '@mozaic-ds/icons/react';
import { downloadCbdTemplate } from 'common/utils';
import cn from 'classnames';
import { OtherFileUploader } from 'pages/Request/partials/OtherFileUploader/OtherFileUploader';
import { useMount } from 'hook/useMount';
import { getLoadList, useLoadList } from 'store/cbd/uploads/loadList';
import { useAppDispatch } from 'store/hooks';
import { isProd } from 'common/consts';
import { gtmCbdHandleClick } from './gtm';
import { CbdUploader } from './CbdUploader';
import styles from '../../../CreatingRequest.module.scss';

export const CbdForm: FC<{ id: string }> = ({ id }) => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const { isLoading } = useLoadList();

  const { validatedStep } = useStepperContext<Steps>();

  useMount(() => dispatch(getLoadList({ reqId: id, page: 1, size: 100 })));

  if (isLoading) return <Loader />;

  const blocks: Block[] = [
    {
      title: translations('downloadCbdTip'),
      component: (
        <Button
          type="button"
          className={cn('mu-mt-100', styles.btn)}
          onClick={() => {
            downloadCbdTemplate();
            if (isProd)
              gtmCbdHandleClick({
                t: translations,
                id,
                buttonLabel: 'downloadTemplate',
              });
          }}
          leftIcon={<DownloadWeb24 />}
        >
          {translations('downloadTemplate')}
        </Button>
      ),
    },
    {
      title: translations('uploadCbdTip'),
      component: <CbdUploader id={id} isScrollable />,
    },
    {
      title: translations('uploadOtherFilesTip'),
      component: <OtherFileUploader id={id} className="mu-mt-100" isScrollable />,
    },
  ];

  return (
    <>
      <Text weight="semi-bold" theme="dark" as="p" className="mu-mb-150" size="xl">
        {translations('fillCbdHeader')}
      </Text>
      <FileTemplate blocks={blocks} />
      <hr />
      <Flex justifyContent="space-between" marginTop="mu250">
        <Flex>
          <Button
            type="button"
            variant="bordered"
            className="mu-mr-100"
            theme="neutral"
            onClick={() => validatedStep(Steps.template)}
          >
            {translations('back')}
          </Button>
          <DeleteRequest id={id} theme="neutral" />
        </Flex>
        <Button type="button" size="m" width="fit" onClick={() => validatedStep(Steps.finish)}>
          {translations('continue')}
        </Button>
      </Flex>
    </>
  );
};
