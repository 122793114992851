import React, { useEffect } from 'react';
import { Button, FileUploaderInput, Flex, IconButton, Text, Tooltip, View } from '@mozaic-ds/react';
import {
  DownloadWeb24,
  Upload24,
  NotificationAvailable24,
  ControlMore24,
  PublishTrashbin24,
  PublishEdit24,
  ControlCross24,
} from '@mozaic-ds/icons/react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import {
  getExtData,
  setPageNumber,
  setSelectedRowsId,
  useExtTableData,
} from 'store/cbd/externalData/tableData';
import CbdTable from 'pages/cbd/hok/partials/CbdTable/CbdTable';
import { useAppDispatch } from 'store/hooks';
import { ExternalDataColumn, useColumnsData } from 'store/cbd/externalData/columns';
import { IExternalData } from 'api/cbd/externalSource';
import { getCostType } from 'store/cbd/costTypes';
import ColumnsPopup from 'pages/cbd/hok/partials/ColumnsPopup/ColumnsPopup';
import { getRegionsList } from 'store/cbd/regions';
import { getMeasureUnitsList } from 'store/cbd/measureUnits';
import { getFromCurrenciesList } from 'store/cbd/extCurrency';
import { useFiltersData, changeDeletedFilters, setFilters } from 'store/cbd/externalData/filters';
import { IFilter } from 'common/types';
import Tags from 'pages/cbd/hok/partials/Tags/Tags';
import { useAppTranslations } from 'translations';
import { getScheduler } from 'store/cbd/scheduler/scheduler';
import styles from './PriceOnComponents.module.scss';
import { ExternalDataColumns, filtersName } from './hook/consts';
import { sortConfig, useColumnsForm, useGetColumns } from './hook/columns';
import FiltersPopup from './FiltersPopup/FiltersPopup';
import useExtDataActions from './hook/useExtDataActions';
import { useQueryParams } from './hook/useQueryParams';

const PriceOnComponents = () => {
  const translations = useAppTranslations();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { queryParams } = useQueryParams();
  const {
    data: rows,
    selectedRowsId,
    isAdding,
    editItems,
    isLoading,
    pageNumber,
    isLastPage,
  } = useExtTableData();
  const { columns } = useGetColumns();
  const { columns: columnsFilter, sortedField } = useColumnsData().data;
  const { form, saveColumnsFilter, resetColumnsFilters } = useColumnsForm();
  const { filters } = useFiltersData();
  const {
    hiddenFileInput,
    deleteExtDataItems,
    onEditClick,
    onAddClick,
    onDownloadExternalTemplate,
    onUploadExternalTemplateClick,
    onUploadExternalTemplate,
    onCancel,
  } = useExtDataActions(queryParams);

  const table = {
    sortConfig,
    columns,
    sortedField,
    columnsFilter,
    selectedRowsId,
    scrollPagination: {
      isLoading,
      pageNumber,
      isLastPage,
      queryParams,
      setPageNumber,
      getData: getExtData,
    },
    className: classnames(styles.tableWrapper, styles.table),
    withCheckboxes: true,
    setSelectedRowsId: (selected: string[]) => {
      if (editItems.length) return;
      dispatch(setSelectedRowsId(selected));
    },
    mainCheckboxHandler: () => {
      if (editItems.length) return;

      const isFilled = (selectedRowsId || []).length < rows.length;
      if (isFilled) dispatch(setSelectedRowsId(rows.map((item) => `${item.id}`)));
      else dispatch(setSelectedRowsId([]));
    },
  };
  const columnsPopup = { columns, columnsFilter, form, saveColumnsFilter, resetColumnsFilters };

  useEffect(() => {
    dispatch(getCostType());
    dispatch(getScheduler());
    dispatch(getRegionsList());
    dispatch(getMeasureUnitsList());
    dispatch(getFromCurrenciesList());
  }, [dispatch]);

  const onRemoveTags = (idForDelete: string[], filtersForDelete: IFilter<string>[]) => {
    dispatch(changeDeletedFilters(idForDelete));
    dispatch(setFilters(filtersForDelete));
  };

  const resetFilters = () => {
    dispatch(changeDeletedFilters(filtersName));
    dispatch(setFilters([]));
  };

  return (
    <>
      <Flex marginTop="mu100" marginBottom="mu150">
        <Text weight="semi-bold">{translations('componentPrices')}</Text>
      </Flex>
      <Flex justifyContent="space-between" marginBottom="mu150">
        <Flex>
          <Tooltip label={translations('templateUnloading')}>
            <IconButton
              className="mu-mr-100"
              theme="neutral"
              variant="bordered"
              size="s"
              onClick={onDownloadExternalTemplate}
            >
              <DownloadWeb24 />
            </IconButton>
          </Tooltip>
          <Tooltip label={translations('loadingPrices')}>
            <IconButton
              className="mu-mr-100"
              theme="neutral"
              variant="bordered"
              size="s"
              onClick={onUploadExternalTemplateClick}
            >
              <Upload24 />
            </IconButton>
          </Tooltip>
          <FileUploaderInput
            id="externalCbdFile"
            ref={hiddenFileInput}
            className={styles.fileInput}
            onChange={onUploadExternalTemplate}
          />
          <Button onClick={() => navigate('/scheduler')} theme="primary" variant="solid" size="s">
            {translations('scheduler')}
          </Button>
        </Flex>
        <Flex>
          <FiltersPopup />
          <View marginLeft="mu100" marginRight="mu100">
            <ColumnsPopup<ExternalDataColumns, ExternalDataColumn, IExternalData>
              {...columnsPopup}
            />
          </View>
          <IconButton
            className="mu-mr-100"
            onClick={onAddClick}
            theme="primary"
            isDisabled={Boolean(editItems.length) && !editItems.includes('-1')}
            variant={isAdding ? 'solid' : 'bordered'}
            size="s"
          >
            {isAdding ? <NotificationAvailable24 /> : <ControlMore24 />}
          </IconButton>
          {isAdding && (
            <IconButton
              className="mu-mr-100"
              onClick={onCancel}
              theme="danger"
              variant="bordered"
              size="s"
            >
              <ControlCross24 />
            </IconButton>
          )}
          <IconButton
            isDisabled={isAdding || selectedRowsId.length !== 1}
            className="mu-mr-100"
            onClick={onEditClick}
            theme="primary"
            variant={editItems.length ? 'solid' : 'bordered'}
            size="s"
          >
            {Boolean(editItems.length) && !isAdding ? (
              <NotificationAvailable24 />
            ) : (
              <PublishEdit24 />
            )}
          </IconButton>
          {Boolean(editItems.length) && !isAdding && (
            <IconButton
              className="mu-mr-100"
              onClick={onCancel}
              theme="danger"
              variant="bordered"
              size="s"
            >
              <ControlCross24 />
            </IconButton>
          )}
          <IconButton
            isDisabled={!selectedRowsId.length || isAdding || Boolean(editItems.length)}
            theme="danger"
            variant="bordered"
            size="s"
            onClick={deleteExtDataItems}
          >
            <PublishTrashbin24 />
          </IconButton>
        </Flex>
      </Flex>
      {filters.length > 0 && (
        <>
          <hr />
          <View marginBottom="mu100" marginTop="mu100">
            <Flex justifyContent="space-between" alignItems="center">
              <View className="mu-mr-100">
                <Tags filters={filters} onRemoveTag={onRemoveTags} />
              </View>
              <View onClick={resetFilters} className="mu-pl-100">
                <Text className={styles.control_clearTags} as="span">
                  {translations('clear')}
                </Text>
              </View>
            </Flex>
          </View>
        </>
      )}
      <CbdTable<ExternalDataColumns, IExternalData> rows={rows} isLoading={isLoading} {...table} />
    </>
  );
};

export default PriceOnComponents;
