import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getNextPricelistStartDate } from 'api/pricelist';
import { GetNextPricelistStartDate } from '@ppm/ppm-platform-api/build/request/pricelist/get-next-pricelist-start-date';
import { setFailure, setLoading } from '../helpers';
import { RequiredStateFields, RootState } from '../types';
import { useAppSelector } from '../hooks';

interface SliceState extends RequiredStateFields {
  nextPricelistDate: string;
}

export const getStartDateValidation = createAsyncThunk(
  'validation/getStartDateValidation',
  async (args: { id: string; query?: GetNextPricelistStartDate.Query }, { rejectWithValue }) => {
    try {
      return await getNextPricelistStartDate({
        urlParameters: [args.id],
        conf: { params: args.query },
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const validationSlice = createSlice({
  initialState: { nextPricelistDate: '', isLoading: false, isFailure: false },
  name: 'validation',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStartDateValidation.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getStartDateValidation.fulfilled,
        (state: SliceState, action: PayloadAction<GetNextPricelistStartDate.Response>) => {
          state.nextPricelistDate = action.payload.nextPricelistDate;
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getStartDateValidation.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const useValidationPriselistDate = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.priceList.validation) };
};
