import { unionBy } from 'lodash';
import { PriceListItemsData } from 'store/priceList/priceListItems';
import { IDataTableColumn } from '@mozaic-ds/react';

export const findUniqBsgHeaders = (data: PriceListItemsData) => {
  return unionBy(
    data.reduce((acc: Array<IDataTableColumn<PriceListItemsData[number]>>, { prices }) => {
      acc.push(
        ...prices.map(({ bsgName, bsgTypeName }, index) => ({
          key: bsgName as keyof PriceListItemsData[number],
          label: `${bsgTypeName} ${bsgName}`,
          render: (row: PriceListItemsData[number]) => {
            if (row.prices[index].price) return row.prices[index].price;
            return '-';
          },
        }))
      );
      return acc;
    }, []),
    'label'
  );
};
