import React, { useEffect } from 'react';
import { Button, DateInput, Flex, Text, TextInput, View } from '@mozaic-ds/react';
import { FormProvider } from 'react-hook-form';
import { useAppDispatch } from 'store/hooks';
import cn from 'classnames';
import { useAppTranslations } from 'translations';
import { Label } from 'components/Label/Label';
import { useRequestData } from 'store/request/request';
import { createPricelist } from 'api/pricelist';
import { useNavigate } from 'react-router-dom';
import { SubRequests } from 'pages/CreatingPriceList/partials/SubRequests';
import { parseDateForMozaicComponent } from 'common/utils';
import { getStartDateValidation, useValidationPriselistDate } from 'store/priceList/validation';
import { getPossibilityCreationPriceList } from 'store/priceList/possibility/creation';
import { useCreatePriceListForm } from './hooks';
import styles from './CreatingPriceList.module.scss';

const CreatingPriceList = () => {
  const translations = useAppTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { request } = useRequestData().data;
  const { nextPricelistDate } = useValidationPriselistDate().data;
  const {
    form,
    handleSubmit,
    contractNumber,
    contractStartDate,
    pricelistNumber,
    pricelistStartDate,
    pricelistEndDate,
  } = useCreatePriceListForm();

  const {
    watch,
    formState: { isValid, errors },
  } = form;

  useEffect(() => {
    if (request?.id) {
      dispatch(getPossibilityCreationPriceList(request.id))
        .unwrap()
        .then(({ error }) => {
          if (error) {
            navigate(`/request/${request.id}`);
            return null;
          }
          return null;
        });
      dispatch(getStartDateValidation({ id: request.id }));
    }
  }, [request?.id, dispatch, navigate]);

  const isChosenSubrequest = watch('subrequests').some((item) => item.isChecked);

  return (
    <Flex direction="column" marginTop="mu250">
      <Flex marginTop="mu250" direction="column">
        <View
          className={cn(styles.wrapper)}
          paddingTop="mu200"
          paddingBottom="mu200"
          paddingRight="mu200"
          paddingLeft="mu200"
          radius="l"
        >
          <Text weight="semi-bold" theme="dark" as="p" className="mu-mb-100">
            {translations('newPriceList')}
          </Text>
          <Flex direction="row">
            <Flex direction="column" marginRight="mu100" className={styles.flex}>
              {request?.supplierName && (
                <Label text={translations('supplier')}>
                  <TextInput value={request.supplierName} isDisabled />
                </Label>
              )}
              {request?.departmentNumber && (
                <Label text={translations('devision')}>
                  <TextInput value={request.departmentNumber} isDisabled />
                </Label>
              )}
            </Flex>
            <Flex direction="column" marginRight="mu100" className={styles.flex}>
              <Flex className={styles.flex2}>
                <Label
                  text={translations('contractNumber')}
                  className={cn(styles.flex, 'mu-mr-050')}
                >
                  <TextInput
                    {...contractNumber}
                    isInvalid={Boolean(errors && errors.contractNumber)}
                  />
                  {errors && errors.contractNumber && (
                    <Text as="p" size="s" theme="danger" className="mu-mt-025">
                      {errors.contractNumber.message}
                    </Text>
                  )}
                </Label>
                <Label text={translations('from')} className={styles.flex}>
                  <DateInput
                    {...contractStartDate}
                    isInvalid={Boolean(errors && errors.contractStartDate)}
                    className="mc-text-input"
                    max={parseDateForMozaicComponent(new Date())}
                  />
                  {errors && errors.contractStartDate && (
                    <Text as="p" size="s" theme="danger" className="mu-mt-025">
                      {errors.contractStartDate.message}
                    </Text>
                  )}
                </Label>
              </Flex>
              <Label text={translations('pricelistNumber')}>
                <TextInput
                  {...pricelistNumber}
                  isInvalid={Boolean(errors && errors.pricelistNumber)}
                />
                {errors && errors.pricelistNumber && (
                  <Text size="s" theme="danger" className={styles.tooltipText}>
                    {errors.pricelistNumber.message}
                  </Text>
                )}
              </Label>
            </Flex>
            <Flex direction="column" className={styles.flex}>
              <Label text={translations('pricelistStartDate')}>
                <DateInput
                  {...pricelistStartDate}
                  className="mc-text-input"
                  min={
                    nextPricelistDate
                      ? parseDateForMozaicComponent(new Date(nextPricelistDate))
                      : undefined
                  }
                />
              </Label>
              <Label text={translations('pricelistEndDate')}>
                <DateInput {...pricelistEndDate} className="mc-text-input" />
              </Label>
            </Flex>
          </Flex>
          <Text weight="semi-bold" theme="dark" as="p" className="mu-mt-300">
            {translations('subrequestsShouldIncludedToPriceList')}
          </Text>
          <FormProvider {...form}>
            <SubRequests />
          </FormProvider>
          <Text size="s" as="p" className="mu-mt-250">
            {translations('priceListGenerationInfo')}
          </Text>
          {request?.id && (
            <Button
              onClick={handleSubmit((values) =>
                createPricelist({
                  urlParameters: [request.id],
                  body: {
                    ...values,
                    pricelistEndDate: parseDateForMozaicComponent(values.pricelistEndDate),
                    pricelistStartDate: parseDateForMozaicComponent(values.pricelistStartDate),
                    containers: values.subrequests
                      .filter(({ isChecked }) => isChecked)
                      .map(({ id }) => id),
                  },
                }).then((data) => navigate(`/${data.requestId}/price-list/${data.id}`))
              )}
              type="submit"
              className="mu-mt-100"
              isDisabled={!(isValid && isChosenSubrequest)}
            >
              {translations('generatePriceList')}
            </Button>
          )}
        </View>
      </Flex>
    </Flex>
  );
};

export default CreatingPriceList;
