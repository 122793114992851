import React, { useEffect } from 'react';
import { Button, Select, View } from '@mozaic-ds/react';
import { DisplayFilter24 } from '@mozaic-ds/icons/react';
import Layer from 'components/Layer/Layer';
import { useAppTranslations } from 'translations';
import FieldWrapper from 'components/FieldWrapper/FieldWrapper';
import { getNomenclature } from 'store/cbd/nomenclature';
import { useFiltersForm } from 'pages/cbd/Bound/hooks/filters';
import { useAppDispatch } from 'store/hooks';
import { useMount } from 'hook/useMount';
import { boundFiltersName } from 'pages/cbd/Bound/hooks/consts';
import { useBoundFiltersData } from 'store/cbd/bound/filters';
import useNomenclatureOptions from 'pages/cbd/hooks/useNomenclatureOptions';

const BoundFiltersPopup = (): JSX.Element => {
  const translations = useAppTranslations();
  const dispatch = useAppDispatch();

  useMount(() => {
    dispatch(getNomenclature());
  });

  const { deletedFilters } = useBoundFiltersData().data;
  const { form, itemGroup, itemDep, itemClass, itemSubclass, onSubmit } = useFiltersForm();

  const itemGroupOptionsWatcher = form.watch(['itemGroup', 'itemDep', 'itemClass']);

  const { groupsOptions, depOptions, classDataOptions, subClassOptions } =
    useNomenclatureOptions(itemGroupOptionsWatcher);

  form.watch((_data, { name, type }) => {
    if (type === 'change') {
      switch (name) {
        case 'itemGroup': {
          form.setValue('itemDep', '');
          form.setValue('itemClass', '');
          form.setValue('itemSubclass', '');
          break;
        }
        case 'itemDep': {
          form.setValue('itemClass', '');
          form.setValue('itemSubclass', '');
          break;
        }
        case 'itemClass': {
          form.setValue('itemSubclass', '');
          break;
        }
        default: {
          break;
        }
      }
    }
  });

  useEffect(() => {
    deletedFilters.forEach((id) => form.setValue(id, ''));
  }, [deletedFilters, form]);

  return (
    <Layer
      id="filtersPopup"
      icon={<DisplayFilter24 fill="#71706b" />}
      title={translations('filterPopupHeader')}
      resetItems={() => {
        form.reset();
        boundFiltersName.forEach((id) => form.setValue(id, ''));
      }}
      saveItems={onSubmit}
      button={
        <Button leftIcon={<DisplayFilter24 />} variant="bordered" size="s">
          {translations('filters')}
        </Button>
      }
    >
      <View as="form" paddingLeft="mu025" paddingRight="mu025">
        <FieldWrapper label={translations('devision')} id="statusIds">
          <Select
            {...itemGroup}
            size="m"
            options={groupsOptions}
            placeholder={translations('chooseAnOption')}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('subDevision')} id="statusIds">
          <Select
            {...itemDep}
            isDisabled={!itemGroup.value}
            size="m"
            options={depOptions}
            placeholder={translations('chooseAnOption')}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('type')} id="statusIds">
          <Select
            {...itemClass}
            isDisabled={!itemDep.value}
            size="m"
            options={classDataOptions}
            placeholder={translations('chooseAnOption')}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('subType')} id="statusIds">
          <Select
            {...itemSubclass}
            isDisabled={!itemClass.value}
            size="m"
            options={subClassOptions}
            placeholder={translations('chooseAnOption')}
          />
        </FieldWrapper>
      </View>
    </Layer>
  );
};

export default BoundFiltersPopup;
