import { Socket } from 'socket.io-client';
import { useAppDispatch } from 'store/hooks';
import { useEffect } from 'react';
import { KeyParams, Namespace } from './types';

export const useSocketConnection = ({
  socket,
  id,
  namespace,
  keyParam,
}: {
  socket: Socket;
  id: string;
  namespace: Namespace;
  keyParam: KeyParams;
}): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, [dispatch, socket, id, keyParam, namespace]);
};
