import * as React from 'react';
import { Flex, Select, Text, View } from '@mozaic-ds/react';
import { usePagingContext } from 'components/Paging/partial/PagingContext';
import { useAppTranslations } from 'translations';

export const RowSelector = () => {
  const t = useAppTranslations();

  const { rowPerPageOptions, itemsCount, rowPerPageValue, currentPageValue, setRowPerPage } =
    usePagingContext();

  const elementsCount = currentPageValue * rowPerPageValue;
  const from = elementsCount - rowPerPageValue + 1;
  const to = elementsCount > itemsCount ? itemsCount : elementsCount;

  return (
    <Flex alignItems="center">
      <Text as="span" size="s">
        {t('rowsPerPage')}
      </Text>
      <View className="mc-pagination__field mu-ml-050 mu-mr-050">
        <Select
          className="mc-pagination__select"
          size="s"
          options={rowPerPageOptions}
          onChange={(e) => setRowPerPage(Number(e.target.value))}
        />
      </View>
      <Text as="span" size="s">
        {`${from} - ${to} ${t('of')} ${itemsCount}`}
      </Text>
    </Flex>
  );
};
