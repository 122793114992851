import { useController, useForm } from 'react-hook-form';
import {
  getCurrentPricelistData,
  setEditState,
  setNeededGeneration,
  useCurrentPriceListData,
} from 'store/priceList/currentPriceList';
import { updatePricelist } from 'api/pricelist';
import { useParams } from 'react-router-dom';
import { EditPriceListSettingsFields } from 'pages/PriceList/types';
import { useAppDispatch } from 'store/hooks';
import { getPricelistItemsData } from 'store/priceList/priceListItems';
import { yupResolver } from '@hookform/resolvers/yup';
import { parseDateForMozaicComponent } from 'common/utils';
import { omit } from 'lodash';
import { schema } from './schema';

const useDefaultFormValue = (): EditPriceListSettingsFields | undefined => {
  const { priceList } = useCurrentPriceListData().data;
  const {
    contractNumber,
    contractStartDate,
    pricelistStartDate,
    pricelistEndDate,
    pricelistNumber,
  } = priceList;

  return {
    pricelistNumber,
    contractNumber,
    contractStartDate: parseDateForMozaicComponent(new Date(contractStartDate)),
    pricelistDates: [
      parseDateForMozaicComponent(new Date(pricelistStartDate), 'MM-dd-yyyy'),
      parseDateForMozaicComponent(new Date(pricelistEndDate), 'MM-dd-yyyy'),
    ],
  };
};

export const useEditPriceListSettingsForm = () => {
  const dispatch = useAppDispatch();

  const { requestId } = useParams();
  const { id: priceListId } = useCurrentPriceListData().data.priceList;
  const defaultValues = useDefaultFormValue();
  const { formState, control, handleSubmit, reset, watch } = useForm<EditPriceListSettingsFields>({
    criteriaMode: 'all',
    mode: 'all',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { field: contractNumber } = useController({ name: 'contractNumber', control });
  const { field: contractStartDate } = useController({ name: 'contractStartDate', control });
  const { field: pricelistNumber } = useController({ name: 'pricelistNumber', control });
  const { field: pricelistDates } = useController({
    name: 'pricelistDates',
    control,
  });

  const submitForm = async (values: EditPriceListSettingsFields) => {
    try {
      return await updatePricelist({
        urlParameters: [requestId as string, priceListId],
        body: {
          ...omit(values, 'pricelistDates'),
          pricelistStartDate: parseDateForMozaicComponent(values.pricelistDates[0]),
          pricelistEndDate: parseDateForMozaicComponent(values.pricelistDates[1]),
        },
      }).then((data) => {
        dispatch(getCurrentPricelistData({ requestId: data.requestId, priceListId: data.id }));
        dispatch(getPricelistItemsData({ requestId: data.requestId, priceListId: data.id }));
        dispatch(setEditState(false));
        dispatch(setNeededGeneration(true));
        return undefined;
      });
    } catch (e) {
      return e;
    }
  };

  return {
    watch,
    formState,
    reset,
    submitForm,
    handleSubmit,
    contractNumber,
    contractStartDate,
    pricelistDates,
    pricelistNumber,
  };
};
