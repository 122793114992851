import { useEffect, useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import { GetRequestListQuery } from '@ppm/ppm-platform-api';
import { BusinessTag } from '@ppm/ppm-platform-request-service';
import { getOtherSelectValue } from 'store/dictionary/reasons';
import { useFiltersData } from 'store/requests/filters';
import { DATE_FORMAT } from 'common/consts';
import { format } from 'date-fns';
import { getRequests, setPageNumber, setRequestsStore } from 'store/requests/requests';
import { useColumnsData } from 'store/requests/columns';
import { useAppTranslations } from 'translations';
import { useController, useForm } from 'react-hook-form';
import { SelectItem } from 'common/types';
import { DOWNLOAD_LIMIT_REQUESTS, FILTERS_ID } from '../Requests.const';
import { ToggleFormFields } from '../types';

export const useGetToggleFields = () => {
  const form = useForm<ToggleFormFields>({
    mode: 'onChange',
    defaultValues: { isHiddenCompleted: true, isReductionOfPurchasePrice: false },
  });
  const { field: isHiddenCompleted } = useController({
    name: 'isHiddenCompleted',
    control: form.control,
  });
  const { field: isReductionOfPurchasePrice } = useController({
    name: 'isReductionOfPurchasePrice',
    control: form.control,
  });

  return { form, isHiddenCompleted, isReductionOfPurchasePrice };
};

const useFilters = () => {
  const t = useAppTranslations();

  const { filters } = useFiltersData().data;

  const queryFilters = useMemo(() => {
    return filters.reduce((acc, curr) => {
      if (!curr.value) return acc;
      const result: GetRequestListQuery = acc;

      switch (curr.id) {
        case FILTERS_ID.responsibleLdap: {
          const responsibleLdapField = curr.value as unknown as SelectItem;
          return { ...result, [curr.id]: responsibleLdapField.value };
        }

        case FILTERS_ID.createDate:
        case FILTERS_ID.dueDate:
        case FILTERS_ID.deadlineDate: {
          const [startDate, endDate] = curr.value;
          return {
            ...result,
            [curr.id]: [
              format(new Date(startDate), DATE_FORMAT),
              format(new Date(endDate).setHours(23, 59, 59), DATE_FORMAT),
            ],
          };
        }

        case FILTERS_ID.reasonListId: {
          const reasonListIds = curr.value as unknown as SelectItem[];
          const otherReason = reasonListIds.find(
            ({ value }) => value === getOtherSelectValue(t).value
          )
            ? true
            : undefined;
          const reasons = {
            otherReason,
            [curr.id]: reasonListIds
              .filter(({ value }) => value !== getOtherSelectValue(t).value)
              .map(({ value }) => Number(value)),
          };

          return { ...result, ...reasons };
        }

        case FILTERS_ID.supplierId: {
          return {
            ...result,
            [curr.id]: (curr.value as unknown as SelectItem).value,
          };
        }

        default:
          return {
            ...result,
            [curr.id]: Array.isArray(curr.value) ? curr.value : curr.value.split(','),
          };
      }
    }, {});
  }, [filters, t]);

  return { queryFilters };
};

export const useRequestsQuery = (togglesForm: ToggleFormFields) => {
  const dispatch = useAppDispatch();

  const { sortedField } = useColumnsData().data;
  const { searchValue: searchValueStore } = useFiltersData().data;

  const { queryFilters } = useFilters();

  const queryParams = useMemo(() => {
    const { field, sortDirection } = sortedField || {};
    const tags: BusinessTag[] = [];

    if (togglesForm.isReductionOfPurchasePrice) tags.push(BusinessTag.Decrease);

    return {
      ...queryFilters,
      excludeCompleted: togglesForm.isHiddenCompleted ? true : undefined,
      orderBy: field,
      order: sortDirection,
      id: searchValueStore || undefined,
      tags: tags.length ? tags : undefined,
    };
  }, [
    togglesForm.isReductionOfPurchasePrice,
    togglesForm.isHiddenCompleted,
    queryFilters,
    searchValueStore,
    sortedField,
  ]);

  useEffect(() => {
    dispatch(setPageNumber(1));
    dispatch(setRequestsStore([]));
    dispatch(getRequests({ ...queryParams, page: 1, limit: DOWNLOAD_LIMIT_REQUESTS }));
  }, [dispatch, queryParams]);

  return { queryParams };
};
