import { combineReducers } from '@reduxjs/toolkit';
import { columnsSlice } from './columns';
import { currencyRateSlice } from './currencyRate';
import { filtersSlice } from './filters';
import { extTableDataSlice } from './tableData';
import { analysis } from './analysis';
import { extChartDataSlice } from './analysis/chartData';

export const externalData = combineReducers({
  columns: columnsSlice.reducer,
  tableData: extTableDataSlice.reducer,
  filters: filtersSlice.reducer,
  currencyRate: currencyRateSlice.reducer,
  analysis,
  chartData: extChartDataSlice.reducer,
});
