import { Translations } from 'translations';
import { BoundFilterName } from '../Bound.types';

export enum BoundColumns {
  GROUPS_NAME = 'groupsName',
  DEPARTMENT_NAME = 'departmentName',
  CLASS_NAME = 'className',
  SUB_CLASS_NAME = 'subclassName',
  ITEM = 'item',
  CBD_GROUP = 'cbdGroup',
}

export const getLabel = (key: BoundFilterName, t: Translations) => {
  return {
    itemGroup: t('devision'),
    itemDep: t('subDevision'),
    itemClass: t('type'),
    itemSubclass: t('subType'),
  }[key];
};

export const boundFiltersName: BoundFilterName[] = [
  'itemGroup',
  'itemDep',
  'itemClass',
  'itemSubclass',
];
