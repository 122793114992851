import React, { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import { SelectItem } from 'common/types';
import { PagingProps } from 'components/Paging/Paging';
import { useAppDispatch } from 'store/hooks';
import { useAppTranslations } from 'translations';

type PagingContextProps = {
  pageOptions: SelectItem[];
  rowPerPageOptions: SelectItem[];
  itemsCount: number;
  totalPages: number;
  rowPerPageValue: number;
  currentPageValue: number;
  setRowPerPage: (row: number) => void;
  setCurrentPage: (page: number) => void;
};

const PagingContext = createContext<PagingContextProps>({} as PagingContextProps);

export function usePagingContext(): PagingContextProps {
  return useContext(PagingContext);
}

export const PagingContextProvider: FC<PagingProps> = ({
  children,
  itemsCount,
  limit,
  page,
  setPage,
  setLimit,
}) => {
  const dispatch = useAppDispatch();
  const t = useAppTranslations();
  const [rowPerPageValue, setRowPerPageValue] = useState(() => limit);
  const [currentPageValue, setCurrentPageValue] = useState(() => page);

  const setCurrentPage = useCallback((arg: number) => {
    setCurrentPageValue(arg);
    dispatch(setPage(arg));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setRowPerPage = useCallback(
    (countRow: number) => {
      if (countRow >= itemsCount) {
        setCurrentPageValue(1);
        dispatch(setPage(1));
      } else {
        setCurrentPageValue((value) => {
          const to = Math.ceil((rowPerPageValue * value) / countRow);
          dispatch(setPage(to));
          return to;
        });
      }
      dispatch(setLimit(countRow));
      setRowPerPageValue(countRow);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemsCount]
  );

  const totalPages = Math.ceil(itemsCount / rowPerPageValue);

  const rowPerPageOptions: SelectItem[] = useMemo(
    () => [
      { value: 10, label: '10' },
      { value: 50, label: '50' },
      { value: 100, label: '100' },
    ],
    []
  );

  const pageOptions: SelectItem[] = useMemo(
    () =>
      Array.from({ length: totalPages }).map((_: unknown, index: number) => ({
        value: index + 1,
        label: `${t('page')} ${index + 1} ${t('paginationOf')} ${totalPages}`,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [totalPages]
  );

  const contextValue = useMemo(
    () => ({
      itemsCount,
      rowPerPageValue,
      pageOptions,
      rowPerPageOptions,
      totalPages,
      currentPageValue,
      setRowPerPage,
      setCurrentPage,
    }),
    [
      rowPerPageValue,
      itemsCount,
      pageOptions,
      rowPerPageOptions,
      totalPages,
      currentPageValue,
      setRowPerPage,
      setCurrentPage,
    ]
  );

  return <PagingContext.Provider value={contextValue}>{children}</PagingContext.Provider>;
};
