import { apiCreator } from 'api';
import {
  GetLanguageList,
  GetLanguageListResponse,
  SetLanguage,
  GetCurrentLanguage,
  GetCurrentLanguageResponse,
} from '@ppm/ppm-platform-api';

export const setLanguage = apiCreator<typeof SetLanguage, undefined, undefined, SetLanguage.Body>(
  SetLanguage
)[SetLanguage.method];

export const getLanguage = apiCreator<typeof GetLanguageList, GetLanguageListResponse>(
  GetLanguageList
)[GetLanguageList.method];

export const getCurrentLanguage = apiCreator<typeof GetCurrentLanguage, GetCurrentLanguageResponse>(
  GetCurrentLanguage
)[GetCurrentLanguage.method];
