import { useCallback } from 'react';
import { useAppTranslations } from 'translations';
import { useAppDispatch } from 'store/hooks';
import { useForm } from 'react-hook-form';
import { ICbdItemGroup } from 'common/cbd/types';
import { IDataTableColumn, SortDirection } from 'common/types';
import { setVisibleBoundColumns, setBoundSortable } from 'store/cbd/bound/columns';
import { useMount } from 'hook/useMount';
import { createColumns } from 'common/utils';
import { BoundColumns } from './consts';
import { IBoundColumnSort } from '../Bound.types';
import { getColumnTemplate } from './helpers';

type BoundSortConfig = { [key in BoundColumns]: IBoundColumnSort };

export const sortConfig: BoundSortConfig = {
  [BoundColumns.GROUPS_NAME]: 'groups',
  [BoundColumns.DEPARTMENT_NAME]: 'department',
  [BoundColumns.CLASS_NAME]: 'class',
  [BoundColumns.SUB_CLASS_NAME]: 'subclass',
  [BoundColumns.ITEM]: 'item',
  [BoundColumns.CBD_GROUP]: 'cbdGroup',
};

export const useGetColumns = (): {
  columns: Record<BoundColumns, IDataTableColumn<ICbdItemGroup>>;
} => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const handleSort = (field: IBoundColumnSort, sortDirection: SortDirection) => {
    dispatch(setBoundSortable({ field, sortDirection }));
  };

  const columnTemplate = getColumnTemplate();

  return {
    columns: createColumns<BoundColumns, ICbdItemGroup, IBoundColumnSort>({
      columnTemplate,
      translations,
      handleSort,
      sortConfig,
    }),
  };
};

export const useColumnsForm = (overrideDefaultValues = {}) => {
  const dispatch = useAppDispatch();
  const defaultValues = {
    groupsName: false,
    departmentName: true,
    className: true,
    subclassName: true,
    item: true,
    cbdGroup: false,
    ...overrideDefaultValues,
  };

  const form = useForm({
    defaultValues,
  });

  useMount(() => {
    dispatch(setVisibleBoundColumns(defaultValues));
  });

  const saveColumnsFilter = useCallback(() => {
    form.handleSubmit((data) => {
      dispatch(setVisibleBoundColumns(data));
    })();
  }, [dispatch, form]);

  const resetColumnsFilters = () => {
    form.reset();
    dispatch(setVisibleBoundColumns(form.getValues()));
  };

  return { form, saveColumnsFilter, resetColumnsFilters };
};
