import React, { FC, useEffect, useRef } from 'react';
import { Button, Select, View } from '@mozaic-ds/react';
import { DisplayFilter24 } from '@mozaic-ds/icons/react';
import Layer from 'components/Layer/Layer';
import { useAppTranslations } from 'translations';
import { useAppDispatch } from 'store/hooks';
import { getSupplierIds, setSearchValue, useSupplierIdsData } from 'store/dictionary/supplierIds';
import Dropdown from 'components/Dropdown/Dropdown';
import FieldWrapper from 'components/FieldWrapper/FieldWrapper';
import { useReasonsData } from 'store/dictionary/reasons';
import { useStatusData } from 'store/dictionary/status';
import styles from 'pages/Requests/Requests.module.scss';
import { useDefaultFormValue, useFiltersForm } from 'pages/Requests/hooks/filters';
import { useFiltersData } from 'store/requests/filters';
import { Flatpicker } from 'components/Flatpicker/Flatpicker';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import { setSearchValue as setSearchUsersValue, useUsers } from 'store/users/users';

const FiltersPopup: FC<{ className?: string }> = ({ className }) => {
  const translations = useAppTranslations();
  const dispatch = useAppDispatch();
  const controller = useRef<AbortController>(new AbortController());

  const { users } = useUsers().data;
  const { reasons } = useReasonsData().data;
  const { supplierIds, searchValue } = useSupplierIdsData().data;
  const { status } = useStatusData().data;
  const { deletedFilters } = useFiltersData().data;
  const defaultValues = useDefaultFormValue();
  const {
    setValue,
    reset,
    supplierId,
    statusIds,
    reasonListId,
    dueDate,
    createDate,
    deadlineDate,
    responsibleLdap,
    handelSubmit,
    formState,
  } = useFiltersForm();

  useEffect(() => {
    deletedFilters.forEach((id) => setValue(id, defaultValues[id]));
  }, [defaultValues, deletedFilters, setValue]);

  useEffect(() => {
    controller.current.abort();
    controller.current = new AbortController();
    dispatch(getSupplierIds({ searchString: searchValue, signal: controller.current.signal }));
  }, [searchValue, dispatch, controller]);

  return (
    <Layer
      id="filtersPopup"
      icon={<DisplayFilter24 fill="#71706b" />}
      title={translations('filterPopupHeader')}
      resetItems={() => {
        reset(() => ({ ...defaultValues }));
      }}
      saveItems={handelSubmit}
      isDisabled={!formState.isValid}
      button={
        <Button leftIcon={<DisplayFilter24 />} variant="bordered" size="s" className={className}>
          {translations('filters')}
        </Button>
      }
    >
      <View as="form" paddingLeft="mu025" paddingRight="mu025">
        <FieldWrapper label={translations('department')} id="supplierId">
          <Autocomplete
            {...supplierId}
            className={styles.dropdown}
            emptyMessageText={translations('emptySupplierLinks')}
            placeholder={translations('chooseAnOption')}
            listBoxItems={supplierIds}
            handleSearch={setSearchValue}
          />
        </FieldWrapper>
        <FieldWrapper label={translations('reason')} fieldLayout="custom" id="reasonListId">
          <Dropdown
            {...reasonListId}
            className={styles.dropdownList}
            placeholder={translations('chooseAnOption')}
            listBoxItems={reasons}
            mode="multi"
          />
        </FieldWrapper>
        <FieldWrapper label={translations('status')} id="statusIds">
          <Select
            {...statusIds}
            size="m"
            options={status}
            placeholder={translations('chooseAnOption')}
          />
        </FieldWrapper>
        <Flatpicker
          id="dueDate"
          label={translations('dueDateFilter')}
          placeholder={translations('dueDateFilter')}
          field={dueDate}
        />
        <Flatpicker
          id="createDate"
          label={translations('createDate')}
          placeholder={translations('createDate')}
          field={createDate}
        />
        <Flatpicker
          id="deadlineDate"
          label={translations('responseTime')}
          placeholder={translations('responseTime')}
          field={deadlineDate}
        />
        <FieldWrapper label={translations('responsibleLdapFilter')} id="responsibleLdap">
          <Autocomplete
            {...responsibleLdap}
            handleSearch={setSearchUsersValue}
            placeholder={translations('selectPlaceholder')}
            listBoxItems={users.map(({ name, properties }) => ({
              label: name,
              value: Number(properties.login),
            }))}
          />
        </FieldWrapper>
      </View>
    </Layer>
  );
};

export default FiltersPopup;
