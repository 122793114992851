import { UploadFile, DeleteFile, UploadFileResponse } from '@ppm/ppm-platform-api';
import { apiCreator } from 'api/index';

export const uploadFile = apiCreator<
  typeof UploadFile,
  UploadFileResponse,
  Parameters<(typeof UploadFile)['url']>
>(UploadFile)[UploadFile.formDataFileKey];

export const deleteFile = apiCreator<
  typeof DeleteFile,
  undefined,
  Parameters<(typeof DeleteFile)['url']>
>(DeleteFile)[DeleteFile.method];
