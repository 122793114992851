import { Translations } from 'translations';

export function gtmCbdHandleClick({
  t,
  id,
  buttonLabel,
}: {
  t: Translations;
  id: string;
  buttonLabel: string;
}) {
  window.dataLayer.push({
    event: 'LeroyMerlin',
    eventCategory: 'click-button', // действие-элемент
    eventAction: t('request'), // блок, в котором находится кнопка
    eventLabel: t(buttonLabel), // текст на кнопке
    eventVariant: t('uploadCBD'),
    eventRequestID: id, // ID запроса
  });
}
