import React, { useEffect } from 'react';
import { Flex } from '@mozaic-ds/react';
import { useAppDispatch } from 'store/hooks';
import { getRolesData, useRoles } from 'store/roles/roles';
import AccordionsView from './partials/AccordionsView/AccordionsView';
import NomenclatureLinksManagement from './partials/NomenclatureLinksManagement/NomenclatureLinksManagement';

export const RightsManagement = () => {
  const dispatch = useAppDispatch();
  const { roles } = useRoles().data;

  useEffect(() => {
    if (roles.length === 0) dispatch(getRolesData());
  }, [roles, dispatch]);

  return (
    <Flex radius="m" direction="row" justifyContent="space-between" wrap="nowrap">
      <AccordionsView />
      <NomenclatureLinksManagement />
    </Flex>
  );
};
