import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initPageable } from 'common/consts';
import { ICatalogCostTreeItem, IGetTemplateStructureResponse } from 'common/cbd/types';
import { IGetTemplateStructureQuery } from 'pages/cbd/EditTemplate/components/ParseTable/ParseTable.types';
import { SelectItem } from 'common/types';
import { useAppSelector } from 'store/hooks';
import { getTemplateStructure } from 'api/cbd/templates';
import { setFailure, setLoading } from '../../helpers';
import { RequiredStateFields, RootState } from '../../types';
import { emptyCatalogCostRow } from './consts';

interface SliceState extends RequiredStateFields, IGetTemplateStructureResponse {
  isAdding: boolean;
  editItems: string[];
  selectedRowsId: string[];
  selectedParent: ISelectedParent[];
  data: Array<ICatalogCostTreeItem>;
}

interface ISelectedParent {
  id: string;
  childrenOptions: SelectItem[];
}

interface IChangeTableRowPayload {
  id: string;
  fieldName: keyof ICatalogCostTreeItem;
  value: string | boolean | number;
}

const initialState: SliceState = {
  isAdding: false,
  editItems: [],
  selectedRowsId: [],
  selectedParent: [],
  data: [],
  pageable: initPageable,
  isLoading: false,
  isFailure: false,
};

export const getStructure = createAsyncThunk(
  'template/getStructure',
  async (payload: IGetTemplateStructureQuery, { rejectWithValue }) => {
    try {
      return await getTemplateStructure(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getChangedData = (
  data: Array<ICatalogCostTreeItem>,
  payload: IChangeTableRowPayload
): Array<ICatalogCostTreeItem> => {
  return data.reduce((prev: Array<ICatalogCostTreeItem>, curr) => {
    curr.childrens = getChangedData(curr.childrens, payload);

    prev.push({
      ...curr,
      [payload.fieldName]: curr.id === payload.id ? payload.value : curr[payload.fieldName],
    });

    return prev;
  }, []);
};

export const structureSlice = createSlice({
  initialState,
  name: 'templateStructure',
  reducers: {
    setEditItems: (state, action: PayloadAction<string[]>) => {
      state.editItems = action.payload;
    },
    setIsAdd: (state, action: PayloadAction<boolean>) => {
      state.isAdding = action.payload;
      if (action.payload) {
        state.data = [emptyCatalogCostRow, ...state.data];
        state.editItems = ['-1'];
      }
    },
    setSelectedRowsId: (state, action) => {
      state.selectedRowsId = action.payload;
    },
    setSelectedParent: (state, action) => {
      state.selectedParent = action.payload;
    },
    changeTableRow: (state, action: PayloadAction<IChangeTableRowPayload>) => {
      state.data = getChangedData(state.data, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStructure.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getStructure.fulfilled,
        (state: SliceState, action: PayloadAction<ICatalogCostTreeItem[]>) => {
          state.data = action.payload;
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getStructure.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const { setSelectedRowsId, setIsAdd, changeTableRow, setEditItems } = structureSlice.actions;

export const useStructureList = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.templateStructure.list);
};
