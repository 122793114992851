import { useRequestData } from 'store/request/request';
import { useController, useForm } from 'react-hook-form';
import { FileType } from '@ppm/ppm-platform-file-service/build/enums';
import { SupplierTemplateFilesFormFields } from './types';

export const useTemplateFilesForm = () => {
  const { request } = useRequestData().data;
  const { control } = useForm<SupplierTemplateFilesFormFields>({
    mode: 'onChange',
    defaultValues: {
      templateFiles: request
        ? request.filesInfo.filter((fileInfo) => fileInfo.fileType === FileType.SupplierTemplate)
        : [],
    },
  });
  const { field: templateFiles } = useController({ name: 'templateFiles', control });

  return { templateFiles };
};
