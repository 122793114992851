import React, { Dispatch, FC, SetStateAction } from 'react';
import { Flex, Radio, RadioGroup, Text, Tooltip } from '@mozaic-ds/react';
import { NotificationInformation24 } from '@mozaic-ds/icons/react';
import { chartViewTypes } from 'common/consts';
import { Bar, ChartProps } from 'react-chartjs-2';
import { ChartType } from 'chart.js';
import { useAppTranslations } from 'translations';
import { chartColors } from 'components/Chart/hooks/useChartConfig';
import classNames from 'classnames';
import styles from './Comparison.module.scss';

interface IBarChart {
  title: string;
  chartViewType: string;
  chartLabels: string[];
  chartConfig: ChartProps<ChartType>;
  radioGroupName: string;
  onRadioChange: Dispatch<SetStateAction<string>>;
  className?: string;
}

const BarChart: FC<IBarChart> = ({
  className,
  title,
  chartViewType,
  chartLabels,
  chartConfig,
  radioGroupName,
  onRadioChange,
}) => {
  const translations = useAppTranslations();

  return (
    <Flex
      marginBottom="mu150"
      className={classNames(styles.chart, className || '')}
      direction="column"
      padding="mu100"
    >
      <Flex justifyContent="space-between">
        <Flex>
          <Text className="mu-mr-100" weight="semi-bold">
            {title}
          </Text>
          <RadioGroup
            name={radioGroupName}
            value={chartViewType}
            onChange={(e) => onRadioChange(e.target.value)}
          >
            {chartViewTypes.map((item) => (
              <Radio className="mu-mr-100" key={item} value={item}>
                {translations(item)}
              </Radio>
            ))}
          </RadioGroup>
        </Flex>
        <Tooltip
          placement="left"
          label={
            <Flex alignItems="flex-start" direction="column">
              {chartLabels.map((item, index) => (
                <Flex alignItems="center">
                  <span
                    className={classNames(styles.legendCircle, 'mu-mr-100', 'mu-p-050')}
                    style={{
                      background: chartColors[index],
                    }}
                  />
                  <Text>{translations(item)}</Text>
                </Flex>
              ))}
            </Flex>
          }
        >
          <NotificationInformation24 />
        </Tooltip>
      </Flex>
      <Bar {...(chartConfig as ChartProps<'bar'>)} />
    </Flex>
  );
};

export default BarChart;
