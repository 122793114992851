import React, { useCallback, useState, JSX } from 'react';
import { useAppDispatch } from 'store/hooks';
import { getUserData } from 'store/auth/user';
import { Loader } from '@mozaic-ds/react';
import { useMount } from 'hook/useMount';
import { getAuthData, useAccessData } from 'store/auth/access';
import { getAuthLogin } from 'store/auth/login';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'hook/useQuery';
import useLocalStorage from 'hook/useLocaleStorage';
import { Routes } from 'router/Routes';

export const App = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const buCode = useQuery().get('bu');
  const [isLoading, setLoading] = useState(true);
  const [storedValue, setValue] = useLocalStorage('buCode', buCode);
  const { pathname } = useLocation();

  const { isAccess } = useAccessData().data;

  const handleLoadTranslationData = useCallback(async () => {
    await dispatch(getUserData()).unwrap();
    setLoading(false);
  }, [dispatch]);

  const handleAuthLogin = useCallback(async () => {
    document.location.href = await dispatch(
      getAuthLogin({
        redirectUri: `${window.location.origin}${pathname}`,
        supplierBuCode: buCode || storedValue || undefined,
      })
    ).unwrap();
  }, [buCode, dispatch, pathname, storedValue]);

  useMount(() => {
    if (!isAccess) {
      dispatch(getAuthData())
        .unwrap()
        .then((data) => {
          if (data.isAccess) {
            handleLoadTranslationData();
          } else {
            setValue(buCode);
            handleAuthLogin();
          }
          return null;
        });
    }
  });

  if (isLoading) return <Loader />;

  return <Routes />;
};
