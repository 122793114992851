import { useMemo } from 'react';
import { useCompareComponentsCostData } from 'store/cbd/cbdAnalysis/compare/componentsCost/componentsCost';
import { ChartType } from 'chart.js';
import { ChartProps } from 'react-chartjs-2';
import { useChartConfig } from 'components/Chart/hooks/useChartConfig';
import { useAppTranslations } from 'translations';
import { priceChartLabels } from './consts';
import { IChartData, getChartDataForConfig } from './helpers';

const useComponentsCostChartConfig = () => {
  const translations = useAppTranslations();
  const { data: componentsCostData } = useCompareComponentsCostData();

  const componentsCostChartData: IChartData = useMemo(
    () =>
      getChartDataForConfig({
        data: componentsCostData,
        datasetLabels: priceChartLabels,
        getValueCallBack: () => [
          componentsCostData.data?.map((item) => item.value) || [],
          componentsCostData.simulationData?.map((item) => item.value) || [],
        ],
      }),
    [componentsCostData]
  );

  const componentsCostChartConfig: ChartProps<ChartType> = useChartConfig({
    type: 'line',
    legendPosition: 'bottom',
    axisTitle: [translations('cbdNumber'), translations('cost')],
    chartData: componentsCostChartData,
    needToShowLabels: false,
    needToShowLegend: false,
  });

  return {
    componentsCostChartConfig,
  };
};

export default useComponentsCostChartConfig;
