import React, { useState, useMemo, useCallback } from 'react';
import { IDataTableColumn, TableExpandButton, View, Text, TMagicUnit } from '@mozaic-ds/react';
import { CostEntity } from 'api/cbd/cbdInfo';
import classNames from 'classnames';
import { ICbdInfoData, IExpandedInfo, IAttribute } from 'common/cbd/types';
import { processItems } from './helpers';

import styles from '../CbdInfoDataTable.module.scss';

const useTableData = ({ cost }: IExpandedInfo) => {
  const [expandedRowIds, setExpandedRowIds] = useState<Array<string>>([]);

  const treeData: ICbdInfoData[] = useMemo(() => {
    const costsByParents = cost.reduce((prev: Record<number, CostEntity[]>, curr) => {
      const id = curr.costDictionary.parentId || 0;

      prev[id] = !prev[id] ? [curr] : [...prev[id], curr];

      return prev;
    }, {});

    return processItems({ items: costsByParents[0], level: 0, costsByParents });
  }, [cost]);

  const selectCustomRow = useCallback(
    (row: ICbdInfoData): boolean => expandedRowIds.includes(row.id),
    [expandedRowIds]
  );

  const handleExpand = useCallback(
    (rowId: string) => () => {
      setExpandedRowIds((prevRowIds): Array<string> => {
        if (prevRowIds.includes(rowId)) {
          return prevRowIds.filter((id) => id !== rowId);
        }

        return [...prevRowIds, rowId];
      });
    },
    []
  );

  const columns: Array<IDataTableColumn<ICbdInfoData>> = useMemo(
    () => [
      {
        className: styles.buttonsBlock,
        render: (row: ICbdInfoData) => (
          <View paddingLeft={`mu${row.level * 100}` as TMagicUnit}>
            {row.children.length > 0 ? (
              <TableExpandButton
                className={classNames({ [styles.rotatedButton]: expandedRowIds.includes(row.id) })}
                onClick={handleExpand(row.id)}
              />
            ) : (
              <View className={styles.emptyButton} />
            )}
          </View>
        ),
      },
      {
        className: styles.nameBlock,
        label: 'Name',
        key: 'name',
        render: (row: ICbdInfoData) => (
          <View>
            {row.name}
            {row.attributes.map((attr: IAttribute) => (
              <View key={attr.id} paddingLeft="mu150">
                <Text size="s">
                  {attr.label}: <Text weight="semi-bold">{attr.value}</Text>
                </Text>
              </View>
            ))}
          </View>
        ),
      },
      {
        className: styles.currBlock,
        label: 'Currency',
        key: 'currency',
      },
      {
        className: styles.priceBlock,
        label: 'Price',
        key: 'price',
        variant: 'number',
      },
    ],
    [handleExpand, expandedRowIds]
  );

  return {
    treeData,
    columns,
    selectCustomRow,
    expandedRowIds,
  };
};

export default useTableData;
