import React, { FC } from 'react';
import { Tabs, TabsButton, TabsList, TabsPanel } from '@mozaic-ds/react';
import cn from 'classnames';
import { useAppTranslations } from 'translations';
import { gtmClickFileTab } from 'components/FileTabs/gtm';
import { isProd } from 'common/consts';
import styles from './FileTabs.module.scss';
import { FileTabsProps } from './types';

export const FileTabs: FC<FileTabsProps> = ({ data, id }) => {
  const translations = useAppTranslations();

  return (
    <Tabs defaultTab={data.initialTabName}>
      <TabsList className="mu-pl-100">
        {data.tabs.map((item) => {
          if (!item) return null;

          const { name, message } = item;

          return (
            <TabsButton
              key={name}
              tab={name}
              onClick={
                isProd
                  ? () => {
                      gtmClickFileTab({
                        t: translations,
                        id,
                        blockLabel: 'request',
                        buttonLabel: message,
                      });
                    }
                  : undefined
              }
            >
              {translations(message)}
            </TabsButton>
          );
        })}
      </TabsList>
      <hr />
      {data.tabs.map((item) => {
        if (!item) return null;
        const { name, component } = item;

        return (
          <TabsPanel
            key={name}
            tab={name}
            className={cn('mu-pt-150 mu-pb-150 mu-pl-200', styles.tabsPanel)}
          >
            {component}
          </TabsPanel>
        );
      })}
    </Tabs>
  );
};
