import { Translations } from 'translations';

export function gtmOtherFileHandleClick({ t, id }: { t: Translations; id: string }) {
  window.dataLayer.push({
    event: 'LeroyMerlin',
    eventCategory: 'click-button', // действие-элемент
    eventAction: t('request'), // блок, в котором находится кнопка
    eventLabel: t('uploadFile'), // текст на кнопке
    eventVariant: t('otherDocuments'),
    eventRequestID: id, // ID запроса
  });
}
