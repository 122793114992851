import React from 'react';
import { CheckBox, IconButton, View } from '@mozaic-ds/react';
import { DisplaySetting24 } from '@mozaic-ds/icons/react';
import Layer from 'components/Layer/Layer';
import { Controller, Path, UseFormReturn } from 'react-hook-form';
import { useAppTranslations } from 'translations';
import { IDataTableColumn } from 'common/types';
import { ExtendsColumns } from '../consts';

export interface RequiredFilterColumnFields<Columns extends ExtendsColumns> {
  key: Path<Record<Columns, boolean>>;
}

export interface IColumnsPopup<Columns extends ExtendsColumns, FilterColumn, TableRows> {
  id?: string;
  columns: Record<Path<Record<Columns, boolean>>, IDataTableColumn<TableRows>>;
  columnsFilter: FilterColumn[];
  form: UseFormReturn<Record<Columns, boolean>>;
  saveColumnsFilter: () => void;
  resetColumnsFilters: () => void;
}

const ColumnsPopup = <
  Columns extends ExtendsColumns,
  FilterColumn extends RequiredFilterColumnFields<Columns>,
  TableRows
>({
  id,
  columns,
  columnsFilter,
  form,
  saveColumnsFilter,
  resetColumnsFilters,
}: IColumnsPopup<Columns, FilterColumn, TableRows>): JSX.Element => {
  const translations = useAppTranslations();

  return (
    <Layer
      id={id || 'popupColumns'}
      icon={<DisplaySetting24 fill="#71706b" />}
      resetItems={resetColumnsFilters}
      saveItems={saveColumnsFilter}
      title={translations('customizeColumns')}
      button={
        <IconButton theme="neutral" size="s" variant="bordered">
          <DisplaySetting24 />
        </IconButton>
      }
    >
      <View as="form" paddingLeft="mu025">
        {columnsFilter.map(({ key }) => {
          return (
            <View key={key} marginTop="mu150" marginBottom="mu150">
              <Controller
                render={({ field }) => (
                  <CheckBox {...field} isChecked={Boolean(field.value)} value={field.name}>
                    {columns[key].message}
                  </CheckBox>
                )}
                name={key}
                control={form.control}
              />
            </View>
          );
        })}
      </View>
    </Layer>
  );
};

export default ColumnsPopup;
