import { StringSelectItem } from 'common/types';

export const extDataTypeOptions = [
  {
    label: 'price',
    value: 'price',
  },
  {
    label: 'productionIndex',
    value: 'index',
  },
  {
    label: 'averageSalary',
    value: 'salary',
  },
];

export const relationPeriodTypeOptions = [
  {
    label: 'current',
    value: 'C',
  },
  {
    label: 'past',
    value: 'P',
  },
];

export const dayMonthOptions = [
  {
    label: 'day',
    value: 'D',
  },
  {
    label: 'month',
    value: 'M',
  },
];

export const destinationOptions = [
  {
    value: 'currency_rate',
    label: 'currency_rate',
  },
  {
    value: 'ext_data_cost',
    label: 'ext_data_cost',
  },
];

export const subscriptionTypeOptions = [
  {
    value: 'absolute',
    label: 'absoluteSubscriptionType',
  },
  {
    value: 'percentage',
    label: 'percentageSubscriptionType',
  },
  {
    value: 'value',
    label: 'valueSubscriptionType',
  },
];

export const sourceTypeOptions = [
  {
    label: 'api',
    value: 'api',
  },
  {
    label: 'excel',
    value: 'excel',
  },
  {
    label: 'user',
    value: 'user',
  },
];

export const keyOptions: Record<string, StringSelectItem[]> = {
  ext_data_cost: [
    {
      value: 'category_id',
      label: 'category_id',
    },
    {
      value: 'type_id',
      label: 'type_id',
    },
    {
      value: 'subtype_id',
      label: 'subtype_id',
    },
    {
      value: 'name',
      label: 'name',
    },
    {
      value: 'name2',
      label: 'name2',
    },
    {
      value: 'date',
      label: 'date',
    },
    {
      value: 'value',
      label: 'value',
    },
    {
      value: 'region',
      label: 'region',
    },
    {
      value: 'measure',
      label: 'measure',
    },
    {
      value: 'currency_code',
      label: 'currency_code',
    },
  ],

  currency_rate: [
    {
      value: 'rule',
      label: 'rule',
    },
    {
      value: 'currency_id1',
      label: 'currency_id1',
    },
    {
      value: 'currency_id2',
      label: 'currency_id2',
    },
    {
      value: 'date',
      label: 'date',
    },
    {
      value: 'rate',
      label: 'rate',
    },
  ],
};
