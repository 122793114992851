import { combineReducers } from '@reduxjs/toolkit';
import { boundList } from './boundList';
import { columnsSlice } from './columns';
import { filtersSlice } from './filters';

export const bounds = combineReducers({
  list: boundList.reducer,
  filters: filtersSlice.reducer,
  columns: columnsSlice.reducer,
});
