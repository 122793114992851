import { cbdApiClient } from 'api';
import { AxiosResponse } from 'axios';
import { IGetExternalDataListQuery } from 'pages/cbd/ExternalData/ExternalData.types';
import { IGetCbdListQuery } from 'pages/cbd/AllUploads/AllUploads.types';
import { IGetResponse, IPageSize } from 'common/cbd/types';
import { IScheduler } from './scheduler';

interface IExternalDataCategory {
  id: number;
  type: string;
  name: string;
  parentId: number;
  parent: string;
}

type IExternalDataSubtype = IExternalDataCategory;

interface IExternalChartDataEntityData {
  date: string;
  rawValueGroup: string;
  valueGroup: string;
}

type IExternalDataType = IExternalDataCategory;

interface IGetExternalDataPayload extends IPageSize, IGetExternalDataListQuery {}

interface Info {
  id?: number;
  contract: string | null;
  diameter: string | null;
  grade: string | null;
  priceTypeId: string | null;
  priceTypeName: string | null;
  standard: string | null;
  thickness: string | null;
}

export interface IExternalDataRequest extends IGetResponse {
  data: IExternalData[];
}

export interface IPatchExtData {
  id?: number;
  extDataType: string;
  source: string;
  categoryId: number | null;
  typeId: number | null;
  subtypeId: number | null;
  name: string;
  name2: string;
  date: string;
  value: string;
  region: string;
  measure: string;
  info: Info;
}

export interface IExternalData extends IPatchExtData {
  sourceType: string;
  currencyCode: string;
  periodType: string;
  createdAt: string;
  updatedAt: string;
  category: IExternalDataCategory | null;
  type: IExternalDataType | null;
  subtype: IExternalDataSubtype | null;
  scheduler: IScheduler | null;
  childrens?: IExternalData[];
  level?: number;
}

export interface ICostTypeRequest {
  data: ICostType[];
}

export interface ICostType {
  id: number;
  type: string;
  name: string;
  parentId?: number;
  children?: ICostType[];
}

export interface ICurrencyRate {
  id: number;
  rule: string;
  currencyId1: string;
  currencyId2: string;
  date: string;
  rate: number;
}

export interface IGetCurrencyRate {
  data: ICurrencyRate[];
}

export interface IExternalChartData {
  name: string;
  category: string;
  type: string;
  subtype: string;
  data: IExternalChartDataEntityData[];
}

export interface IAveragePricesResponse extends IGetResponse {
  data: IAveragePricesData[];
}

export interface IAveragePricesData {
  id: string;
  name: string;
  date: string;
  value: string;
  valueYTD: string;
  value3M: string;
  value6M: string;
  value9M: string;
  value12M: string;
  childrens?: IAveragePricesData[];
  level?: number;
}

export const getExternalDataRequest = async (payload: IGetExternalDataPayload) => {
  const { data }: AxiosResponse<IExternalDataRequest> = await cbdApiClient.get(
    `/api/ext-data-cost`,
    {
      params: payload,
    }
  );
  return data;
};

export const postExternalData = async (payload: IPatchExtData) => {
  const { data }: AxiosResponse<IExternalDataRequest> = await cbdApiClient.post(
    `/api/ext-data-cost`,
    payload
  );
  return data;
};

export const patchExternalData = async (payload: IPatchExtData) => {
  const { id, ...body } = payload;
  const { data }: AxiosResponse<IExternalDataRequest> = await cbdApiClient.patch(
    `/api/ext-data-cost/${id}`,
    body
  );
  return data;
};

export const deleteExternalData = async (id: string) => {
  const { data }: AxiosResponse<IExternalDataRequest> = await cbdApiClient.delete(
    '/api/ext-data-cost',
    {
      params: {
        id,
      },
    }
  );
  return data;
};

export const getCostTypeRequest = async () => {
  const { data }: AxiosResponse<ICostTypeRequest> = await cbdApiClient.get('/api/cost-type');
  return data;
};

export const downloadExternalTemplate = async () => {
  const { data }: AxiosResponse<ArrayBuffer> = await cbdApiClient.get('/api/templates/external', {
    responseType: 'arraybuffer',
  });
  return data;
};

export const uploadExternalFile = async (payload: FormData) => {
  const { data }: AxiosResponse<ICostTypeRequest> = await cbdApiClient.post(
    '/api/ext-data-cost/external',
    payload
  );
  return data;
};

export const getCurrencyRateRequest = async (payload: string) => {
  const { data }: AxiosResponse<IGetCurrencyRate> = await cbdApiClient.get(
    `/api/currency-rate/${payload}`
  );
  return data;
};

export const getChartDataRequest = async (params: IPageSize) => {
  const { data }: AxiosResponse<IExternalChartData[]> = await cbdApiClient.get(
    `/api/ext-data-cost-analysis/chartData`,
    {
      params,
    }
  );
  return data;
};

export const getExternalSourceRequest = async (params: IPageSize) => {
  const { data }: AxiosResponse<IExternalDataRequest> = await cbdApiClient.get(
    `/api/ext-data-cost-analysis/tableData`,
    {
      params,
    }
  );
  return data;
};

export const getAveragePricesRequest = async (params: IGetCbdListQuery) => {
  const { data }: AxiosResponse<IAveragePricesResponse> = await cbdApiClient.get(
    `/api/ext-data-cost-analysis/averagePrices`,
    {
      params,
    }
  );
  return data;
};
