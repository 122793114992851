import { combineReducers } from '@reduxjs/toolkit';
import { nomenclatureLinkUsersSlice } from 'store/dashboard/rightsManagement/nomenclatureLinkUsers';
import { supplierLinkUsersSlice } from 'store/dashboard/rightsManagement/supplierLinkUsers';
import { nomenclatureSlice } from 'store/dashboard/rightsManagement/nomenclature';
import { supplierSlice } from 'store/dashboard/rightsManagement/supplier';
import { nomenclatureLinkSlice } from 'store/dashboard/rightsManagement/nomenclatureLinks';
import { supplierLinkSlice } from 'store/dashboard/rightsManagement/supplierLinks';

export const dashboard = combineReducers({
  nomenclature: nomenclatureSlice.reducer,
  nomenclatureLink: nomenclatureLinkSlice.reducer,
  nomenclatureLinkUsers: nomenclatureLinkUsersSlice.reducer,

  supplier: supplierSlice.reducer,
  supplierLink: supplierLinkSlice.reducer,
  supplierLinkUsers: supplierLinkUsersSlice.reducer,
});
