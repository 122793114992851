import { PricelistStatusCode } from '@ppm/ppm-platform-pricelist-service';
import { NotificationStatuses, NotificationStatusType } from './types';

export const getNotificationStatus = ({
  isEdited,
  priceList,
  needToGenerateAgain,
}: NotificationStatusType): keyof typeof NotificationStatuses | null => {
  const { statusCode } = priceList;
  const hasComment = priceList.ediData?.statusComment;
  const sign =
    statusCode === PricelistStatusCode.SignedByAllSides && hasComment
      ? NotificationStatuses.sign
      : null;
  const reject =
    statusCode === PricelistStatusCode.Reject && hasComment ? NotificationStatuses.reject : null;
  const cancel =
    statusCode === PricelistStatusCode.Cancelled && hasComment ? NotificationStatuses.cancel : null;
  const gropingError =
    statusCode === PricelistStatusCode.GroupingPricesError
      ? NotificationStatuses.gropingError
      : null;
  const initializeError =
    statusCode === PricelistStatusCode.InitializeError
      ? NotificationStatuses.initializeError
      : null;
  const error = statusCode === PricelistStatusCode.Error ? NotificationStatuses.error : null;
  const edit = isEdited ? NotificationStatuses.edit : null;
  const generate = needToGenerateAgain ? NotificationStatuses.generate : null;

  return edit || generate || gropingError || error || sign || cancel || reject || initializeError;
};
