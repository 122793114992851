import { useNotifications } from '@mozaic-ds/react';
import {
  createNewScheduler,
  getOneSchedulerReq,
  IScheduler,
  manualScheduler,
  updateScheduler,
} from 'api/cbd/scheduler';
import React, { useCallback, useState } from 'react';
import { ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { getScheduler, useScheduler } from 'store/cbd/scheduler/scheduler';
import { useAppDispatch } from 'store/hooks';
import { useAppTranslations } from 'translations';
import { ISchedulerForm } from './useSchedulerForm';

interface IUseSchedulerFormActions {
  nameInput: ControllerRenderProps<ISchedulerForm, 'nameInput'>;
  nameSelect: ControllerRenderProps<ISchedulerForm, 'nameSelect'>;
  form: UseFormReturn<ISchedulerForm>;
}

const useSchedulerFormActions = ({ nameInput, nameSelect, form }: IUseSchedulerFormActions) => {
  const dispatch = useAppDispatch();
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { data: schedulerData } = useScheduler();
  const { success } = useNotifications();
  const translations = useAppTranslations();

  const onCancel = () => {
    setIsAdd(false);
    setIsEdit(false);
    form.reset();
  };

  const onAddOrEditClick = (needToEdit: boolean) => {
    nameInput.onChange(
      needToEdit
        ? schedulerData.find((el) => el.id.toString() === nameSelect.value)?.name || ''
        : ''
    );
    if (needToEdit) {
      setIsEdit(true);
    } else {
      setIsAdd(true);
    }
    if (!needToEdit) {
      form.reset();
    }
  };

  const onNameChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === '') {
      onCancel();
      return;
    }

    nameSelect.onChange(e);
    const res = await getOneSchedulerReq(e.target.value);

    Object.keys(res).forEach((item) => {
      const value = res[item as keyof IScheduler];
      const formField = item as keyof ISchedulerForm;

      if (item === 'schedulerStart' || item === 'schedulerFinish') {
        form.setValue(
          formField,
          value ? new Date(value.toString()).toLocaleDateString('fr-CA') : ''
        );

        return;
      }

      if (item !== 'name') {
        form.setValue(formField, value);
      }
    });
  };

  const onManualScheduler = useCallback(async () => {
    await manualScheduler(nameSelect.value);
  }, [nameSelect.value]);

  const onSubmit = useCallback(() => {
    form.handleSubmit(async ({ id, ...data }) => {
      const { nameInput: nameInputValue, nameSelect: nameSelectValue, ...fields } = data;

      if (nameInputValue && isAdd) {
        createNewScheduler({
          ...fields,
          name: nameInputValue,
          schedulerPeriod: Number(fields.schedulerPeriod),
          lastLaunch: fields.schedulerStart,
        });
        return;
      }

      await updateScheduler({
        ...fields,
        name: isEdit
          ? nameInputValue
          : schedulerData.find((el) => el.id.toString() === nameSelectValue)?.name || '',
        id,
        schedulerPeriod: Number(data.schedulerPeriod),
        lastLaunch: data.schedulerStart,
      });

      await dispatch(getScheduler());
      success({
        title: translations('successNotification'),
        message: 'Изменения успешно применены',
      });
      setIsAdd(false);
      setIsEdit(false);
    })();
  }, [dispatch, form, isAdd, isEdit, schedulerData, success, translations]);

  return {
    isAdd,
    isEdit,
    onNameChange,
    onCancel,
    onManualScheduler,
    onAddOrEditClick,
    onSubmit,
  };
};

export default useSchedulerFormActions;
