import { useAppTranslations } from 'translations';
import { createColumns } from 'common/utils';

import { IDataTableColumn } from 'common/types';
import { IAveragePricesData } from 'api/cbd/externalSource';
import { AveragePricesColumns } from './consts';
import { useGetColumnTemplate } from './helpers';

export const useGetColumns = (): {
  columns: Record<AveragePricesColumns, IDataTableColumn<IAveragePricesData>>;
} => {
  const translations = useAppTranslations();

  const columnTemplate = useGetColumnTemplate();

  return {
    columns: createColumns<AveragePricesColumns, IAveragePricesData, null>({
      columnTemplate,
      translations,
    }),
  };
};
