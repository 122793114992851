import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import usePagination from 'hook/usePagination';
import {
  getCurrencySubscriptionsData,
  useCurrencySubscriptionsList,
} from 'store/cbd/scheduler/currencySubscriptions/currencySubscriptionsList';
import { useFiltersData } from 'store/cbd/scheduler/currencySubscriptions/filters';

export const useTableData = () => {
  const { pageable } = useCurrencySubscriptionsList();
  const dispatch = useAppDispatch();
  const { filters } = useFiltersData();
  const {
    paginationProps,
    size,
    onPageChange,
    onNext,
    onPrevious,
    onSizeChange,
    getPaginationInfo,
  } = usePagination(pageable);

  const updateTableData = useCallback(() => {
    const queryParams = filters.reduce((prev, curr) => {
      if (curr.value) {
        return {
          ...prev,
          [curr.id]: curr.value,
        };
      }

      return prev;
    }, {});

    dispatch(
      getCurrencySubscriptionsData({ page: paginationProps.currentPage, size, ...queryParams })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationProps.currentPage, size, filters]);

  return {
    updateTableData,
    paginationProps,
    size,
    onPageChange,
    onNext,
    onPrevious,
    onSizeChange,
    getPaginationInfo,
  };
};
