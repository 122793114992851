import { Button, View } from '@mozaic-ds/react';
import { getHasGrants } from 'common/utils';
import React, { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useUserData } from 'store/auth/user';
import { useBoundList } from 'store/cbd/bound/boundList';
import { useSelectedLoad } from 'store/cbd/selectedLoad';
import { CbdStatus } from 'common/cbd/types';
import { useAppTranslations } from 'translations';
import { useActions, ISubmitForm } from './useActions';
import styles from './SubmitForm.module.scss';

const SubmitForm: FC<ISubmitForm> = ({ updateTableData }) => {
  const params = useParams();
  const { user } = useUserData();
  const { selectedRowsId } = useBoundList();
  const { status } = useSelectedLoad();
  const translate = useAppTranslations();
  const hasGrants = getHasGrants(user.userGrants);
  const { onDelete, onReset, onSubmit, handleSubmit } = useActions({
    updateTableData,
  });
  const isButtonDisable = useMemo(
    () =>
      (hasGrants.AdminRole && !hasGrants.AdminCBD) ||
      status === CbdStatus.Approved ||
      status === CbdStatus.Declined,
    [hasGrants.AdminCBD, hasGrants.AdminRole, status]
  );

  return (
    <View className={styles.container} shadow="s">
      <Button
        className="mu-mr-100"
        onClick={onReset}
        size="m"
        theme="neutral"
        variant="bordered"
        isDisabled={isButtonDisable}
      >
        {translate('resetAll')}
      </Button>
      <Button
        isDisabled={!Number(params.cbdId) || isButtonDisable}
        className="mu-mr-100"
        onClick={handleSubmit((formData) => onSubmit(formData))}
        size="m"
        theme="primary"
        variant="solid"
      >
        {translate('bind')}
      </Button>
      <Button
        isDisabled={!selectedRowsId.length || isButtonDisable}
        className="mu-mr-100"
        onClick={onDelete}
        size="m"
        theme="danger"
        variant="bordered"
      >
        {translate('delete')}
      </Button>
    </View>
  );
};

export default SubmitForm;
