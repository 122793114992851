import { ICbdItemGroup } from 'common/cbd/types';
import { IColumnTemplate } from 'common/utils';
import { BoundColumns } from './consts';

type TGetColumnTemplate = { [key in BoundColumns]: IColumnTemplate<ICbdItemGroup> };

export const getColumnTemplate = (): TGetColumnTemplate => {
  const columnTemplate: TGetColumnTemplate = {
    [BoundColumns.GROUPS_NAME]: {
      message: 'devision',
      isSortable: true,
      render: (record: ICbdItemGroup) =>
        record.groups ? `${record.groups}-${record.groupName}` : '',
    },
    [BoundColumns.DEPARTMENT_NAME]: {
      message: 'subDevision',
      isSortable: true,
      render: (record: ICbdItemGroup) =>
        record.department ? `${record.department}-${record.departmentName}` : '',
    },
    [BoundColumns.CLASS_NAME]: {
      message: 'type',
      isSortable: true,
      render: (record: ICbdItemGroup) =>
        record.class ? `${record.class}-${record.className}` : '',
    },
    [BoundColumns.SUB_CLASS_NAME]: {
      message: 'subType',
      isSortable: true,
      render: (record: ICbdItemGroup) =>
        record.subclass ? `${record.subclass}-${record.subclassName}` : '',
    },
    [BoundColumns.ITEM]: {
      message: 'product',
      isSortable: true,
      render: (record: ICbdItemGroup) => record.item,
    },
    [BoundColumns.CBD_GROUP]: {
      message: 'productGroup',
      isSortable: true,
      render: (record: ICbdItemGroup) => record.cbdGroup || '',
    },
  };

  return columnTemplate;
};
