import React from 'react';
import { Flex, Button, Text } from '@mozaic-ds/react';
import { ControlMore24 } from '@mozaic-ds/icons/react';
import { useNavigate } from 'react-router-dom';
import { useAppTranslations } from 'translations';
import requestStyles from 'pages/Request/Request.module.scss';
import { getPricelistData } from 'store/priceList/priceList';
import { useAppDispatch } from 'store/hooks';
import { useMount } from 'hook/useMount';
import { useRequestData } from 'store/request/request';
import { getHasGrants } from 'common/utils';
import { isLmEnvironment } from 'common/consts';
import { usePossibilityCreationPriceList } from 'store/priceList/possibility/creation';
import { PriceListItem } from './PriceListItem';

type PriceListBlockProps = {
  id: string;
};

export const PriceListBlock = ({ id }: PriceListBlockProps) => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();
  const navigate = useNavigate();
  const { requestGrants } = useRequestData().data;
  const { error } = usePossibilityCreationPriceList().data;
  const hasGrant = getHasGrants(requestGrants);

  const { RequestPricelistForm, RequestPricelistGet, RequestPricelistEdit } = hasGrant;

  useMount(() => {
    if (RequestPricelistGet) dispatch(getPricelistData(id));
  });

  if (!RequestPricelistGet) return null;

  return (
    <Flex
      className={requestStyles.wrapper}
      marginTop="mu150"
      paddingTop="mu125"
      paddingBottom="mu125"
      paddingRight="mu200"
      paddingLeft="mu200"
      radius="l"
      direction="column"
      shadow="s"
    >
      <Flex justifyContent="space-between">
        <Text weight="semi-bold" theme="dark" size="l" as="p" textAlign="center">
          {translations('priceListTitle')}
        </Text>
        {isLmEnvironment && (
          <Button
            theme="neutral"
            variant="bordered"
            size="s"
            isDisabled={!RequestPricelistForm || Boolean(error)}
            leftIcon={<ControlMore24 />}
            onClick={() => navigate(`/new-price-list/${id}`)}
          >
            {translations('newPriceList')}
          </Button>
        )}
      </Flex>
      <PriceListItem isChangeable={Boolean(RequestPricelistEdit)} id={id} />
    </Flex>
  );
};
