import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetSupplierLinkQuery, GetSupplierLinkResponse } from '@ppm/ppm-platform-api';
import { setFailure, setLoading } from 'store/helpers';
import { RequiredStateFields, RootState } from 'store/types';
import { getSupplierLink } from 'api/links';
import { useAppSelector } from 'store/hooks';

export type SupplierLinkType = {
  supplierId: number;
  roleId: number;
  name: string;
};

interface SliceState extends RequiredStateFields {
  data: SupplierLinkType[];
}

export const getSupplierLinkData = createAsyncThunk(
  'dashboard/getSupplierLink',
  async (query: GetSupplierLinkQuery, { rejectWithValue }) => {
    try {
      return await getSupplierLink({ conf: { params: query } });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const supplierLinkSlice = createSlice({
  initialState: { data: [], isLoading: false, isFailure: false },
  name: 'supplierLink',
  reducers: {
    clearSupplierLinks(state: SliceState) {
      state.data = [];
      state.isLoading = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSupplierLinkData.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getSupplierLinkData.fulfilled,
        (state: SliceState, action: PayloadAction<GetSupplierLinkResponse>) => {
          const keys = Object.keys(action.payload.data);
          state.data = keys
            .map((key) => {
              return action.payload.data[Number(key)];
            })
            .flat()
            .map(({ roleId, supplierId, supplierName }) => ({
              supplierId,
              roleId,
              name: `${supplierId} - ${supplierName}`,
            }));
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getSupplierLinkData.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const { clearSupplierLinks } = supplierLinkSlice.actions;

export const useSupplierLink = (): { supplierLink: SliceState } => {
  return {
    supplierLink: useAppSelector((state: RootState) => state.dashboard.supplierLink),
  };
};
