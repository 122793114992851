import React from 'react';
import { Button, DateInput, Select, TextInput, View } from '@mozaic-ds/react';
import { DisplayFilter24 } from '@mozaic-ds/icons/react';
import Layer from 'components/Layer/Layer';
import { useAppTranslations } from 'translations';
import FieldWrapper from 'components/FieldWrapper/FieldWrapper';
import { useFiltersForm } from 'pages/cbd/Uploads/hooks/filters';
import { statusOptions, uploadsFiltersName } from 'pages/cbd/Uploads/hooks/consts';

const UploadsFiltersPopup = (): JSX.Element => {
  const translations = useAppTranslations();

  const { form, cbdId, cbdCreatedAt, cbdDate, fileName, cbdStatus, onSubmit } = useFiltersForm();

  return (
    <Layer
      id="filtersPopup"
      icon={<DisplayFilter24 fill="#71706b" />}
      title={translations('filterPopupHeader')}
      resetItems={() => {
        form.reset();
        uploadsFiltersName.forEach((id) => form.setValue(id, ''));
      }}
      saveItems={onSubmit}
      button={
        <Button leftIcon={<DisplayFilter24 />} variant="bordered" size="s">
          {translations('filters')}
        </Button>
      }
    >
      <View as="form" paddingLeft="mu025" paddingRight="mu025">
        <FieldWrapper label={translations('cbdId')} id="cbdId">
          <TextInput {...cbdId} size="m" placeholder={translations('cbdId')} />
        </FieldWrapper>
        <FieldWrapper label={translations('cbdLoadDate')} id="cbdCreatedAt">
          <DateInput {...cbdCreatedAt} size="m" />
        </FieldWrapper>
        <FieldWrapper label={translations('dateCbd')} id="cbdDate">
          <DateInput {...cbdDate} size="m" />
        </FieldWrapper>
        <FieldWrapper label={translations('fileName')} id="fileName">
          <TextInput {...fileName} size="m" placeholder={translations('fileName')} />
        </FieldWrapper>
        <FieldWrapper label={translations('status')} id="cbdStatus">
          <Select
            {...cbdStatus}
            size="m"
            options={statusOptions.map((item) => ({
              ...item,
              label: translations(item.label),
            }))}
            placeholder={translations('chooseAnOption')}
          />
        </FieldWrapper>
      </View>
    </Layer>
  );
};

export default UploadsFiltersPopup;
