import { IChartsExtends, IPurchaseData, IPurchasePrice } from 'common/cbd/types';
import { getChartDataValues } from '../../helpers';

interface IGetChartDataForConfig {
  data: IChartsExtends[] | IPurchasePrice;
  chartType?: 'groups' | 'currency' | 'expenses';
  datasetLabels?: string[];
  viewType?: string;
  getValueCallBack?: () => number[][];
}

export interface IChartData {
  labels: string[];
  values: number[][];
  datasetLabels?: string[];
}

const getChartDefaultValues = ({ data, chartType, viewType }: IGetChartDataForConfig) => {
  if (Array.isArray(data)) {
    return data.reduce((prev: number[][], curr) => {
      const { groupsChartDataValue, currencyChartDataValue, expensesChartDataValue } =
        getChartDataValues(curr, viewType);

      switch (chartType) {
        case 'groups': {
          return groupsChartDataValue.map((item, index) => [
            ...(prev[index] ? prev[index] : []),
            item,
          ]);
        }
        case 'currency': {
          return currencyChartDataValue.map((item, index) => [
            ...(prev[index] ? prev[index] : []),
            item,
          ]);
        }
        case 'expenses': {
          return expensesChartDataValue.map((item, index) => [
            ...(prev[index] ? prev[index] : []),
            item,
          ]);
        }
        default: {
          return groupsChartDataValue.map((item, index) => [
            ...(prev[index] ? prev[index] : []),
            item,
          ]);
        }
      }
    }, []);
  }

  return data.data && data.simulationData
    ? [data.data.map((item) => item.value), data.simulationData.map((item) => item.value)]
    : [];
};

const getChartLabels = (data: IChartsExtends[] | IPurchaseData[]) =>
  data.map((item) => `${item.id} - ${new Date(item.dateCbd).toLocaleDateString()}`);

export const getChartDataForConfig = ({
  data,
  viewType,
  chartType,
  datasetLabels,
  getValueCallBack,
}: IGetChartDataForConfig): IChartData => ({
  labels: Array.isArray(data) ? getChartLabels(data) : getChartLabels(data.data || []),
  values: getValueCallBack
    ? getValueCallBack()
    : getChartDefaultValues({ data, chartType, viewType }),
  datasetLabels,
});
