import axios, { AxiosRequestConfig } from 'axios';

const config: AxiosRequestConfig = {
  baseURL:
    process.env.NODE_ENV === 'development' ? '' : `https://${process.env.REACT_APP_API_HOST}`,
  headers: { 'X-App-Id': `${process.env.REACT_APP_ID}` },
  withCredentials: true,
};

export const apiClient = axios.create(config);
