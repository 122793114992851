import { MeResponse } from '@ppm/ppm-platform-api';
import { BusinessUnits, Language } from '@ppm/ppm-platform-common-contracts';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserData as getData } from 'api/auth';
import { UserGrants } from '@ppm/ppm-platform-user-service';
import { setFailure, setLoading } from '../helpers';
import { RequiredStateFields, RootState } from '../types';
import { useAppSelector } from '../hooks';

interface SliceState extends RequiredStateFields {
  data: MeResponse;
  lang: string;
  userGrants: UserGrants;
  buCode: BusinessUnits;
}

export const getUserData = createAsyncThunk<MeResponse>(
  'user/getUserData',
  async (_, { rejectWithValue }) => {
    try {
      return await getData({});
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const userSlice = createSlice({
  initialState: {
    data: {} as MeResponse,
    userGrants: {},
    lang: Language.English,
    isLoading: false,
    isFailure: false,
    buCode: BusinessUnits.Ru,
  },
  name: 'user',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(getUserData.fulfilled, (state: SliceState, action: PayloadAction<MeResponse>) => {
        state.data = action.payload;
        state.userGrants = action.payload.grants;
        state.buCode = action.payload.buCode;
        state.lang = action.payload.properties?.lang || Language.English;
        state.isLoading = false;
        state.isFailure = false;
      })
      .addCase(getUserData.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const useUserData = (): { user: SliceState } => {
  return { user: useAppSelector((state: RootState) => state.auth.user) };
};
