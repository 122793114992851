import {
  GetChatMessages,
  GetChatMessagesResponse,
  PostChatMessageResponse,
  PostChatMessage,
  PostChatMessageRequest,
} from '@ppm/ppm-platform-api';
import { apiCreator } from 'api/index';

export const getChatMessages = apiCreator<
  typeof GetChatMessages,
  GetChatMessagesResponse,
  Parameters<(typeof GetChatMessages)['url']>
>(GetChatMessages)[GetChatMessages.method];

export const sendChatMessage = apiCreator<
  typeof PostChatMessage,
  PostChatMessageResponse,
  Parameters<(typeof PostChatMessage)['url']>,
  PostChatMessageRequest
>(PostChatMessage)[PostChatMessage.method];
