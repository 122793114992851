import React, { useEffect, useCallback, JSX } from 'react';
import { useAppTranslations } from 'translations';
import { Flex, Overlay, Text, Toggle, View, Loader, Select, TextInput } from '@mozaic-ds/react';
import {
  changeDeletedFilters,
  setFilters,
  setSearchValue,
  useFiltersData,
} from 'store/requests/filters';
import { useAppDispatch } from 'store/hooks';
import { useRequestsData } from 'store/requests/requests';
import { useReasonsData } from 'store/dictionary/reasons';
import { useStatusData } from 'store/dictionary/status';
import { Navigation } from 'components/Navigation/Navigation';
import Dropdown from 'components/Dropdown/Dropdown';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import { useDefaultFormValue, useFiltersForm } from 'pages/Requests/hooks/filters';
import { setSearchValue as setSearchUsersValue, getUsers, useUsers } from 'store/users/users';
import cn from 'classnames';
import { debounce } from 'lodash';
import { filtersName } from './partials/consts';
import { usePaginationRequests } from './hooks/pagination';
import { useGetToggleFields, useRequestsQuery } from './hooks/requestsQuery';
import RequestTable from './RequestTable';
import { ColumnPopup, FiltersPopup, Tags } from './partials';
import styles from './Requests.module.scss';

const Requests = (): JSX.Element => {
  const translations = useAppTranslations();
  const dispatch = useAppDispatch();

  const { users, searchText } = useUsers().data;
  const { reasons } = useReasonsData().data;
  const { status } = useStatusData().data;
  const { filters } = useFiltersData().data;
  const { requests, isLoading } = useRequestsData().data;
  const { isHiddenCompleted, isReductionOfPurchasePrice, form } = useGetToggleFields();
  const { queryParams } = useRequestsQuery(form.getValues());
  const { searchValue: searchValueStore } = useFiltersData().data;

  const { statusIds, reasonListId, responsibleLdap, handelSubmit, reset } = useFiltersForm();
  const defaultValues = useDefaultFormValue();
  const { ref } = usePaginationRequests(queryParams);

  useEffect(() => {
    if (searchText) {
      dispatch(getUsers({ text: searchText }));
    }
  }, [dispatch, searchText]);

  const resetFilters = useCallback(() => {
    dispatch(changeDeletedFilters(filtersName));
    dispatch(setFilters([]));
    reset(() => ({ ...defaultValues }));
  }, [dispatch, reset, defaultValues]);

  return (
    <>
      <Navigation />
      <View marginBottom="mu150" marginTop="mu150">
        <Flex marginBottom="mu100" alignItems="center">
          <TextInput
            className={cn(styles.flexOne, 'mu-mr-125')}
            size="s"
            type="search"
            placeholder={translations('idRequest')}
            onChange={debounce((e) => dispatch(setSearchValue(e.target.value)), 500)}
            defaultValue={searchValueStore}
          />
          <Dropdown
            {...reasonListId}
            value={reasonListId.value}
            onChange={(e) => {
              reasonListId.onChange(e);
              handelSubmit();
            }}
            className={cn(styles.dropdownList, 'mu-mr-125')}
            placeholder={translations('reasonFilterPlaceholder')}
            listBoxItems={reasons}
            mode="multi"
            size="s"
          />
          <Select
            {...statusIds}
            className={cn(styles.flexOne, 'mu-mr-125')}
            onChange={(e) => {
              statusIds.onChange(e);
              handelSubmit();
            }}
            size="s"
            options={status}
            placeholder={translations('statusFilterPlaceholder')}
          />
          <View className={styles.dropdownList}>
            <Autocomplete
              {...responsibleLdap}
              autoSubmit={handelSubmit}
              size="s"
              handleSearch={setSearchUsersValue}
              placeholder={translations('responsibleLdapFilterPlaceholder')}
              listBoxItems={users.map(({ name, properties }) => ({
                label: name,
                value: Number(properties.login),
              }))}
            />
          </View>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" wrap="wrap" marginBottom="mu100">
          <Flex alignItems="center">
            <Toggle
              {...isHiddenCompleted}
              className="mu-mr-100"
              value={isHiddenCompleted.name}
              isChecked={isHiddenCompleted.value}
              size="s"
            >
              {translations('hideCompleted')}
            </Toggle>
            <Toggle
              {...isReductionOfPurchasePrice}
              value={isReductionOfPurchasePrice.name}
              isChecked={isReductionOfPurchasePrice.value}
              size="s"
            >
              {translations('reductionOfPurchasePrice')}
            </Toggle>
          </Flex>
          <Flex>
            <FiltersPopup className="mu-mr-100" />
            <ColumnPopup />
          </Flex>
        </Flex>

        {filters.length > 0 && (
          <>
            <hr />
            <View marginBottom="mu100" marginTop="mu100">
              <Flex justifyContent="space-between" alignItems="center">
                <Flex
                  className={styles.control_flex}
                  wrap="wrap"
                  alignItems="center"
                  direction="row"
                >
                  <Tags />
                </Flex>
                <span aria-hidden="true" onClick={resetFilters}>
                  <Text className={styles.control_clearTags} as="span">
                    {translations('clear')}
                  </Text>
                </span>
              </Flex>
            </View>
          </>
        )}
        <RequestTable rows={requests} isLoading={isLoading} />
        <Overlay isVisible={isLoading && requests.length > 0}>
          <Loader />
        </Overlay>

        <div ref={ref} />
      </View>
    </>
  );
};

export default Requests;
