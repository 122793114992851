import { GetNomenclatureLinkUsers } from '@ppm/ppm-platform-api';
import { NomenclatureHierarchyNode } from '@ppm/ppm-platform-dictionary-service';
import { SliceState, SupplierSliceData } from 'store/dashboard/rightsManagement/supplier';
import { sortBy, uniqBy } from 'lodash';
import { LeafDataType } from 'store/dashboard/model/nomenclature/leaf';

export const formatNomenclatureData = (
  nomenclatures: NomenclatureHierarchyNode[]
): LeafDataType[] => {
  return nomenclatures.map(({ path, name, type }) => ({
    name,
    path,
    type,
    isChecked: false,
    isFullSelected: null,
    isDirty: false,
    defaultSelectedValue: [],
  }));
};

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const fillDefaultNomenclature = (
  defaultValues: any,
  values: Array<number[]>,
  value: number[]
): Array<number[]> => {
  if (!defaultValues) return [];
  Object.keys(defaultValues)
    .filter((key) => key !== 'isComplete')
    .forEach((key) => {
      const structureValue: any = defaultValues[key];
      const structureKeys = Object.keys(structureValue);

      structureKeys.forEach((nom) => {
        if (key === 'subTypes' || structureValue[nom].isComplete) {
          values.push(
            key === 'subTypes' ? value.concat(structureValue[nom]) : value.concat(Number(nom))
          );
        } else {
          fillDefaultNomenclature(structureValue[nom], values, value.concat(Number(nom)));
        }
      });
    });
  return values;
};

export const createBodyNomenclatureLinksRequest = (
  selectedNomenclature: Array<number[]>
): GetNomenclatureLinkUsers.Query => {
  return {
    nomenclatures: selectedNomenclature.map(([department, subDepartment, type, subType]) => ({
      departmentId: department,
      subDepartmentId: subDepartment,
      typeId: type,
      subtypeId: subType,
    })),
  };
};

export const concatSuppliersWithLinks = (
  data: SupplierSliceData[],
  state: SliceState
): SupplierSliceData[] => {
  return sortBy(
    uniqBy(
      state.checkedSuppliersGroupedByRole
        .filter(({ roleId }) => roleId === state.selectedRole)
        .map(({ supplierId, name }) => ({
          label: name,
          value: supplierId,
          isChecked: true,
        }))
        .concat(data),
      ({ value }) => value
    ),
    ({ isChecked }) => !isChecked
  );
};
