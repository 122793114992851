import { IAveragePricesData, IExternalData } from 'api/cbd/externalSource';
import { ICurrencySubscription, ISubscription } from 'api/cbd/scheduler';
import {
  ICbd,
  ICbdItemGroup,
  ICbdLoad,
  ICbdWithArrayItemGroups,
  ICatalogCostTreeItem,
} from 'common/cbd/types';
import { AllUploadsColumns } from 'pages/cbd/AllUploads/hooks/consts';
import { BoundColumns } from 'pages/cbd/Bound/hooks/consts';
import { UploadsColumns } from 'pages/cbd/Uploads/hooks/consts';
import {
  IUploadTable,
  IUploadsColumnSort,
  UploadsFilterName,
} from 'pages/cbd/Uploads/Uploads.types';
import { ExternalDataColumns } from 'pages/cbd/ExternalData/partials/PriceOnComponents/hook/consts';
import { AlertAttributeColumns } from 'pages/cbd/Scheduler/partials/AlertAttribute/hook/consts';
import { FilterName as AlertCurrencyFilterName } from 'pages/cbd/Scheduler/partials/AlertCurrency/AlertCurrency.types';
import { FilterName as AlertAttributeFilterName } from 'pages/cbd/Scheduler/partials/AlertAttribute/AlertAttribute.types';
import { FilterName as ExternalDataFilterName } from 'pages/cbd/ExternalData/ExternalData.types';
import { AlertCurrencyColumns } from 'pages/cbd/Scheduler/partials/AlertCurrency/hook/consts';
import { ExternalSourceColumns } from 'pages/cbd/ExternalSource/hooks/consts';
import { CompareFilterName, ICompareTable } from 'pages/cbd/CompareTable/CompareTable.types';
import { BoundFilterName, IBoundColumnSort } from 'pages/cbd/Bound/Bound.types';
import {
  ExternalSourceFilterName,
  IExternalSourceColumnSort,
} from 'pages/cbd/ExternalSource/ExternalSource.types';
import { CompareColumns } from 'pages/cbd/CompareTable/hooks/consts';
import { BoundSortedField } from 'store/cbd/bound/columns';
import { UploadsSortedField } from 'store/cbd/uploads/columns';
import { AveragePricesColumns } from 'pages/cbd/AveragePrices/hooks/consts';
import { TemplateStructureColumns } from 'pages/cbd/EditTemplate/components/ParseTable/hooks/consts';
// import { TemplateStructureColumns } from 'pages/EditTemplate/components/ParseTable/hooks/consts';
import { ITemplateStructureColumnSort } from 'pages/cbd/EditTemplate/components/ParseTable/ParseTable.types';
import { IExternalDataColumnSort } from 'pages/cbd/ExternalData/partials/PriceOnComponents/PriceOnComponents.types';
import { ExternalDataSortedField } from 'store/cbd/externalData/columns';
import { TemplateStructureSortedField } from 'store/cbd/templateStructure/columns';
import { AllUploadsSortedField } from 'store/cbd/allUploads/columns';
import { FilterName, IAllUploadsColumnSort, ICbdTable } from '../../AllUploads/AllUploads.types';

export type ExtendsMainData =
  | ICbd
  | ICbdLoad
  | ICbdItemGroup
  | ICbdWithArrayItemGroups
  | IExternalData
  | IAveragePricesData
  | ICatalogCostTreeItem;

export type ExtendsColumns =
  | AllUploadsColumns
  | UploadsColumns
  | BoundColumns
  | ExternalDataColumns
  | AlertAttributeColumns
  | AlertCurrencyColumns
  | CompareColumns
  | ExternalSourceColumns
  | AveragePricesColumns
  | TemplateStructureColumns;

export type ExtendsTableRows =
  | ICbdTable
  | IUploadTable
  | ICbdItemGroup
  | IExternalData
  | ISubscription
  | ICurrencySubscription
  | ICompareTable
  | IAveragePricesData
  | ICatalogCostTreeItem;

export type ExtendsSortConfig =
  | IAllUploadsColumnSort
  | IUploadsColumnSort
  | IBoundColumnSort
  | IExternalSourceColumnSort
  | IExternalDataColumnSort
  | ITemplateStructureColumnSort
  | null;

export type ExtendsSortedField =
  | AllUploadsSortedField
  | UploadsSortedField
  | BoundSortedField
  | ExternalDataSortedField
  | TemplateStructureSortedField;

export type ExtendsFilters =
  | FilterName
  | BoundFilterName
  | UploadsFilterName
  | ExternalSourceFilterName
  | CompareFilterName
  | AlertCurrencyFilterName
  | AlertAttributeFilterName
  | ExternalDataFilterName;

export const FILTERS_ID = {
  name: 'name',
  cbdSupplierId: 'cbdSupplierId',
  cbdDate: 'cbdDate',
  cbdId: 'cbdId',
  loadFileName: 'loadFileName',
  cbdCreatedAt: 'cbdCreatedAt',
  itemGroup: 'itemGroup',
  itemDep: 'itemDep',
  itemClass: 'itemClass',
  itemSubclass: 'itemSubclass',
  attributeDep: 'attributeDep',
  attributeSubDep: 'attributeSubDep',
  attributeGroup: 'attributeGroup',
  externalSourceDate: 'externalSourceDate',
  cbdStatus: 'cbdStatus',
  requestStatusId: 'requestStatusId',
  categoryId: 'categoryId',
  typeId: 'typeId',
  subtypeId: 'subtypeId',
  source: 'source',
  extDataType: 'extDataType',
  region: 'region',
};

export const nomenclatureHierarchyFields = ['itemGroup', 'itemDep', 'itemClass', 'itemSubclass'];

export const attributeHierarchyFields = ['attributeDep', 'attributeSubDep', 'attributeGroup'];

export const categoryHierarchyFields = ['categoryId', 'typeId', 'subtypeId'];
