import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRoles } from 'api/roles';
import { GetRole } from '@ppm/ppm-platform-api';
import { RequiredStateFields, RootState } from '../types';
import { useAppSelector } from '../hooks';
import { setFailure, setLoading } from '../helpers';

interface SliceState extends RequiredStateFields {
  roles: GetRole.Response['roles'];
  linkableRoles: GetRole.Response['roles'];
}

export const getRolesData = createAsyncThunk<GetRole.Response>(
  'roles/getRoles',
  async (_, { rejectWithValue }) => {
    try {
      return await getRoles({});
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const initialState = {
  roles: [],
  linkableRoles: [],
  isFailure: false,
  isLoading: false,
};

export const rolesSlice = createSlice({
  initialState,
  name: 'roles',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRolesData.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(getRolesData.fulfilled, (state: SliceState, action) => {
        state.roles = action.payload.roles;
        state.linkableRoles = action.payload.roles.filter((role) => role.isLinkable);
        state.isLoading = false;
        state.isFailure = false;
      })
      .addCase(getRolesData.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const useRoles = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.roles.roles) };
};
