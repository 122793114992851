import React, { useEffect } from 'react';
import { Flex, Text, View } from '@mozaic-ds/react';
import { PublishEdit32 } from '@mozaic-ds/icons/react';
import { useAppTranslations } from 'translations';
import { setEditState, useCurrentPriceListData } from 'store/priceList/currentPriceList';
import { getHasGrants } from 'common/utils';
import { useAppDispatch } from 'store/hooks';
import { useValidationPriselistDate } from 'store/priceList/validation';
import styles from '../../PriceList.module.scss';
import { Placeholder } from './Placeholder';
import { Form } from './Form';

export const Settings = () => {
  const translations = useAppTranslations();
  const dispatch = useAppDispatch();

  const { isFailure, isLoading, grants, isEdited } = useCurrentPriceListData().data;
  const { isLoading: isValidationDateLoading } = useValidationPriselistDate().data;
  useEffect(() => {
    return () => {
      dispatch(setEditState(false));
    };
  }, [dispatch]);

  const hasGrant = getHasGrants(grants);

  const { PricelistParametersEdit } = hasGrant;

  return (
    <View
      className={styles.wrapper}
      shadow="s"
      radius="l"
      paddingTop="mu150"
      paddingBottom="mu200"
      paddingRight="mu200"
      paddingLeft="mu200"
    >
      <Flex justifyContent="space-between">
        <Text weight="semi-bold" theme="dark" size="xl" as="p">
          {translations('priceListSettings')}
        </Text>
        {!isValidationDateLoading && !isFailure && !isLoading && PricelistParametersEdit && (
          <PublishEdit32 onClick={() => dispatch(setEditState(true))} />
        )}
      </Flex>
      {!isEdited ? <Placeholder /> : <Form />}
    </View>
  );
};
