import React, { useCallback, Fragment } from 'react';
import { Pdf48, Document48, NotificationWarning24 } from '@mozaic-ds/icons/react';
import {
  Button,
  Flex,
  Link as MozaicLink,
  Loader,
  Text,
  Tooltip,
  useModals,
  View,
} from '@mozaic-ds/react';
import { PricelistStatusCode } from '@ppm/ppm-platform-pricelist-service';
import { useAppTranslations } from 'translations';
import {
  getCurrentPricelistData,
  setNeededGeneration,
  useCurrentPriceListData,
} from 'store/priceList/currentPriceList';
import {
  downloadFile,
  getHasGrants,
  getTransitionKeyByGrant,
  parseDateForMozaicComponent,
} from 'common/utils';
import { useParams } from 'react-router-dom';
import { Grants } from '@ppm/ppm-platform-user-service';
import { executePricelistFlow } from 'api/pricelist';
import { useAppDispatch } from 'store/hooks';
import { statusesOfPriceListFlow } from 'common/consts';
import { getGrantsInfo } from 'common/helpers';
import Modal from 'components/Modal/Modal';
import { useRequestData } from 'store/request/request';
import { Label } from 'components/Label/Label';
import { getButtonText } from './helpers';
import { PriceListNotifications } from './Notifications/Notifications';
import styles from '../PriceList.module.scss';

const modalId = 'priceListSendingModalId';

export const Files = () => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();
  const { open, close } = useModals();

  const { requestId } = useParams();

  const { request } = useRequestData().data;

  const { priceList, isLoading, isFailure, grants, transitions, isEdited, needToGenerateAgain } =
    useCurrentPriceListData().data;
  const {
    xlsxFileId,
    pdfFileId,
    requestId: id,
    id: priceListId,
    pricelistName,
    pricelistStartDate,
  } = priceList;

  const hasGrant = getHasGrants(grants);
  const { PricelistDownloadXLSX, PricelistDownloadPDF } = hasGrant;

  const handlePriceListFlow = useCallback(
    async (status: PricelistStatusCode | undefined) => {
      if (!status) return null;
      try {
        return await executePricelistFlow({
          urlParameters: [id, priceListId, status],
        }).then(() => {
          dispatch(setNeededGeneration(false));
          dispatch(getCurrentPricelistData({ requestId: id, priceListId }));
          return null;
        });
      } catch (e) {
        return e;
      }
    },
    [dispatch, id, priceListId]
  );

  const transitionsState = getGrantsInfo(hasGrant, statusesOfPriceListFlow).map((grant) => {
    const formedTransitionValue = getTransitionKeyByGrant(transitions, Grants[grant]);
    return {
      ...formedTransitionValue,
      isRenderedModal: Grants.PricelistTransitionPdfFormedToSending === Grants[grant],
      handler: () =>
        Grants.PricelistTransitionPdfFormedToSending === Grants[grant]
          ? open(modalId)
          : handlePriceListFlow(formedTransitionValue.transitionStatus),
    };
  });

  return (
    <View
      className={styles.wrapper}
      shadow="s"
      radius="l"
      marginTop="mu150"
      marginBottom="mu200"
      paddingTop="mu150"
      paddingBottom="mu200"
      paddingRight="mu200"
      paddingLeft="mu200"
    >
      <Text weight="semi-bold" theme="dark" size="xl" as="p" className="mu-mb-125">
        {translations('priceListFiles')}
      </Text>
      {isLoading && <Loader />}
      {!isFailure && !isLoading && priceList && (
        <Flex marginBottom="mu250">
          <Flex className="mu-mr-250" alignItems="center">
            <Document48 fill="#188803" className="mu-mr-050" />
            {xlsxFileId ? (
              <Flex direction="column">
                <Text size="s" as="p">
                  {translations('priceListXLSFormed')}
                </Text>
                <View>
                  {PricelistDownloadXLSX && (
                    <MozaicLink
                      theme="primary"
                      size="s"
                      onClick={() => downloadFile(requestId as string, Number(xlsxFileId))}
                    >
                      {translations('download')}
                    </MozaicLink>
                  )}
                </View>
              </Flex>
            ) : (
              <Text size="s" as="div">
                {translations('priceListXLSNotFormed')}
                <Text size="s" theme="primary-02" as="p">
                  {translations('awaitingFormation')}
                </Text>
              </Text>
            )}
          </Flex>
          <Flex>
            <Pdf48 fill="#C61112" className="mu-mr-050" />
            {pdfFileId ? (
              <Flex direction="column">
                <Text size="s" as="p">
                  {translations('priceListPDFFormed')}
                </Text>
                <View>
                  {PricelistDownloadPDF && (
                    <MozaicLink
                      theme="primary"
                      size="s"
                      onClick={() => downloadFile(requestId as string, Number(pdfFileId))}
                    >
                      {translations('download')}
                    </MozaicLink>
                  )}
                </View>
              </Flex>
            ) : (
              <Text size="s" as="div">
                {translations('priceListPDFNotFormed')}
                <Text size="s" theme="primary-02" as="p">
                  {xlsxFileId ? translations('awaitingFormation') : translations('firstFormXLS')}
                </Text>
              </Text>
            )}
          </Flex>
        </Flex>
      )}
      {!isEdited &&
        !needToGenerateAgain &&
        !isLoading &&
        Boolean(transitionsState.length) &&
        transitionsState.map(({ transitionState, transitionStatus, handler, isRenderedModal }) => {
          if (!transitionState) return null;
          return (
            <Fragment key={transitionState.grant}>
              <Button
                className="mu-mr-100"
                onClick={handler}
                isDisabled={isFailure || !transitionState?.isValid}
              >
                {getButtonText(translations, transitionState.grant)}
              </Button>
              {isRenderedModal && (
                <Modal
                  id={modalId}
                  title={translations('sendPriceToRmcModalTitle')}
                  heading={translations('checkPropertyOfPrice')}
                  okBtnText={translations('approvePropertyOfPrice')}
                  handleOk={async () => {
                    await handlePriceListFlow(transitionStatus);
                    close(modalId);
                  }}
                >
                  <Flex direction="column">
                    <Flex>
                      <Pdf48 fill="#C61112" className="mu-mr-050" />
                      <Flex direction="column" justifyContent="center">
                        <MozaicLink
                          theme="primary"
                          size="s"
                          onClick={() => downloadFile(requestId as string, Number(pdfFileId))}
                        >
                          {translations('priceList')} {pricelistName} №{priceListId}
                        </MozaicLink>
                        <Text>
                          {translations('from').toLowerCase()}{' '}
                          {parseDateForMozaicComponent(pricelistStartDate, 'dd-MM-yyyy')}
                        </Text>
                      </Flex>
                    </Flex>
                    <Label text={translations('supplierName')} className="mu-mt-100">
                      <Text as="p">{request?.supplierName}</Text>
                    </Label>
                    <Label text={translations('priceListSupplierId')} className="mu-mt-100">
                      <Text as="p">{request?.supplierId}</Text>
                    </Label>
                    <Flex alignItems="center" className="mu-mt-100">
                      <Label text={translations('priceApplyDate')} hasMarginTop={false}>
                        <Text as="p">
                          {parseDateForMozaicComponent(pricelistStartDate, 'dd-MM-yyyy')}
                        </Text>
                      </Label>
                      <Tooltip
                        label={translations('pricelistContractDateChangingWarning')}
                        placement="top"
                        className="mu-ml-025"
                      >
                        <NotificationWarning24 />
                      </Tooltip>
                    </Flex>
                  </Flex>
                </Modal>
              )}
            </Fragment>
          );
        })}
      <PriceListNotifications handlePriceListFlow={handlePriceListFlow} />
    </View>
  );
};
