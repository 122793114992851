import * as yup from 'yup';

export const schema = yup
  .object()
  .shape({
    overWriteKey: yup
      .string()
      .nullable()
      .when(['overwrite'], {
        is: (overwrite: boolean) => Boolean(overwrite),
        then: (shm) => shm.required('Поле обязательно для заполения'),
      }),
  })
  .required();
