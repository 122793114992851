import { DataTable, ExpandableDataTableRow, Text } from '@mozaic-ds/react';
import { ControlCircleLess24, ControlCircleMore24 } from '@mozaic-ds/icons/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppTranslations } from 'translations';
import classNames from 'classnames';
import { useAppDispatch } from 'store/hooks';
import { BodyEntity, ValuesEntity, ChildrenValuesEntity } from 'common/cbd/types';
import { getSuppliersData, useSuppliers } from 'store/cbd/suppliers';
import { useCompareCostGroupsData } from 'store/cbd/cbdAnalysis/compare/costGroups';
import styles from '../Comparison.module.scss';

export interface IComparisonTableData
  extends Record<string, string | IComparisonTableData[] | undefined> {
  bestValue: string;
  worstValue: string;
  id: string;
  group: string;
  children?: IComparisonTableData[];
}

const useTableData = () => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();
  const { data } = useCompareCostGroupsData();
  const [expandedRowIds, setExpandedRowIds] = useState<string[]>([]);
  const { suppliers } = useSuppliers();

  const onExpand = (rowId: string) => {
    setExpandedRowIds((state) => {
      if (state.includes(rowId)) {
        return state.filter((item) => item !== rowId);
      }

      return [...state, rowId];
    });
  };

  useEffect(() => {
    if (data.headers.length) {
      dispatch(getSuppliersData(data.headers.map((item) => item.supplierId)));
    }
  }, [data.headers, dispatch]);

  const columns = useMemo(
    () =>
      data.headers.length
        ? [
            {
              render: (row: IComparisonTableData) => {
                if (row.children) {
                  return expandedRowIds.includes(row.id) ? (
                    <ControlCircleLess24 onClick={() => onExpand(row.id)} />
                  ) : (
                    <ControlCircleMore24 onClick={() => onExpand(row.id)} />
                  );
                }

                return null;
              },
              width: 56,
            },
            {
              key: 'group',
              className: styles.groupBlock,
              label: translations('costGroup'),
              width: 130,
            },
            ...data.headers.map((item) => ({
              className: styles.groupBlock,
              key: item.id.toString(),
              label: `${item.id} - ${
                suppliers.find((elem) => elem.supplierId === item.supplierId)?.name || ''
              } - ${new Date(item.dateCbd).toLocaleDateString()}`,
              render: (row: IComparisonTableData) => {
                return (
                  <Text
                    as="span"
                    className={classNames({
                      [styles.valueWrapper]: true,
                      [styles.bestValue]:
                        row.bestValue === row[item.id] && row.worstValue !== row[item.id],
                      [styles.worstValue]:
                        row.worstValue === row[item.id] && row.bestValue !== row[item.id],
                    })}
                  >
                    {row[item.id]}
                  </Text>
                );
              },
            })),
            {
              className: styles.groupBlock,
              key: 'bestValue',
              label: translations('best'),
              render: (row: IComparisonTableData) => {
                return (
                  <Text as="span" className={classNames(styles.valueWrapper, styles.bestValue)}>
                    {row.bestValue}
                  </Text>
                );
              },
            },
          ]
        : [],
    [data.headers, expandedRowIds, suppliers, translations]
  );

  const rows = useMemo(
    () =>
      data.body.map((item: BodyEntity) => {
        const row = {
          id: item.id.toString(),
          group: translations(item.groups),
          ...item.values?.reduce(
            (prev: Record<string, string>, curr: ValuesEntity) => ({
              ...prev,
              [curr.cbdId]: curr.value?.toFixed(2),
            }),
            {}
          ),
          bestValue: item.bestValue?.toFixed(2),
          worstValue: item.worstValue?.toFixed(2),
        };

        if (item.childrens.length) {
          return {
            ...row,
            children: item.childrens.map((elem) => ({
              id: elem.id.toString(),
              group: translations(elem.name),
              ...elem.values?.reduce(
                (prev: Record<string, string>, curr: ChildrenValuesEntity) => ({
                  ...prev,
                  [curr.cbdId]: curr.value?.toFixed(2),
                }),
                {}
              ),
              bestValue: elem.bestValue?.toFixed(2),
              worstValue: elem.worstValue?.toFixed(2),
            })),
          };
        }

        return row;
      }),
    [data.body, translations]
  );

  const renderCustomRow = useCallback(
    (row) => {
      return (
        <ExpandableDataTableRow
          columns={columns}
          getRowKey={() => row.id}
          row={row}
          isExpanded={expandedRowIds.includes(row.id) && Boolean(row.children.length)}
        >
          <DataTable
            isHeaderHidden
            isSubTable
            rows={row.children || []}
            columns={columns}
            getRowKey={(subRow) => subRow.id}
          />
        </ExpandableDataTableRow>
      );
    },
    [columns, expandedRowIds]
  );

  const selectCustomRow = useCallback(
    (row: IComparisonTableData) => expandedRowIds.includes(row.id),
    [expandedRowIds]
  );

  return { columns, rows, renderCustomRow, selectCustomRow };
};

export default useTableData;
