import { FILTERS_ID } from 'pages/Requests/Requests.const';
import { SelectItem, IFilter } from 'common/types';
import { getLocale, getTagValueForDate } from 'common/utils';
import { FilterName } from 'pages/Requests/types';

type Options = {
  statuses: SelectItem[];
  reasons: SelectItem[];
  lang: string;
};

export const formattedFilterValue = (
  filterValue: IFilter<FilterName>,
  options: Options
): string => {
  const { id, value, label } = filterValue;
  const { statuses, reasons, lang } = options;

  switch (id) {
    case FILTERS_ID.deadlineDate:
    case FILTERS_ID.createDate:
    case FILTERS_ID.dueDate:
      return getTagValueForDate(filterValue, getLocale(lang));

    case FILTERS_ID.supplierId: {
      const inputValue: SelectItem = value;
      return `${label}: ${inputValue.value}`;
    }

    case FILTERS_ID.responsibleLdap: {
      const inputValue: SelectItem = value;

      return `${inputValue.label}(${inputValue.value})`;
    }

    case FILTERS_ID.reasonListId: {
      const inputValue: SelectItem[] = value;
      return reasons
        .filter((reason) => inputValue.find((item) => item.value === reason.value))
        .map((item) => item.label)
        .join(', ');
    }

    case FILTERS_ID.statusIds:
      return statuses?.find((status) => status.value === Number(value))?.label ?? '';

    default:
      return '';
  }
};
