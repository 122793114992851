import React from 'react';
import { useMount } from 'hook/useMount';
import { getSubRequests, useSubrequests } from 'store/subrequests/subrequests';
import { getRequest, resetStore, useRequestData } from 'store/request/request';
import { useAppDispatch } from 'store/hooks';
import { useParams } from 'react-router-dom';
import { Loader } from '@mozaic-ds/react';
import { getRolesData } from 'store/roles/roles';
import CreatingPriceList from './CreatingPriceList';

const CreatingPriceListContainer = () => {
  const dispatch = useAppDispatch();
  const { requestId } = useParams();
  const { isLoading } = useRequestData().data;
  const { isLoading: isLoadingSubrequests } = useSubrequests().data;

  useMount(() => {
    if (requestId) {
      dispatch(getSubRequests(requestId));
      dispatch(getRequest(requestId));
    }
    dispatch(getRolesData());

    return () => dispatch(resetStore());
  });
  return isLoadingSubrequests || isLoading ? <Loader /> : <CreatingPriceList />;
};

export default CreatingPriceListContainer;
