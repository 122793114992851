import React from 'react';
import { useAveragePricesList } from 'store/cbd/averagePrices/list';
import {
  AveragePricesDataColumn,
  useAveragePricesColumnsData,
} from 'store/cbd/averagePrices/columns';
import { IAveragePricesData } from 'api/cbd/externalSource';
import { useGetColumns } from './hooks/columns';
import CbdView, { ICbdView } from '../hok/CbdView';
import { AveragePricesColumns } from './hooks/consts';

const AveragePrices = () => {
  const { columns } = useGetColumns();
  const { columns: columnsFilter } = useAveragePricesColumnsData().data;
  const { data, pageable, isLoading } = useAveragePricesList();

  const config: ICbdView<
    IAveragePricesData,
    AveragePricesColumns,
    AveragePricesDataColumn,
    IAveragePricesData
  > = {
    isLoading,
    data: {
      data,
      getData: () => {},
      pageable,
    },
    table: {
      columns,
      columnsFilter,
      paginationVisible: false,
      emptyDataText: 'toDisplayDataFillRequiredFilters',
    },
  };

  return (
    <CbdView<IAveragePricesData, AveragePricesColumns, AveragePricesDataColumn, IAveragePricesData>
      {...config}
    />
  );
};

export default AveragePrices;
