import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CbdStatus } from 'common/cbd/types';
import { IUploadTable } from 'pages/cbd/Uploads/Uploads.types';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

const initialState: IUploadTable = {
  itemGroup: [],
  requestId: 0,
  cbdId: 0,
  dateCbd: '',
  id: 0,
  fileName: '',
  fileId: 0,
  createdAt: '',
  group: '',
  status: CbdStatus.NotSeen,
  errorId: null,
  isLoaded: true,
  supplierId: 0,
};

export const selectedLoadSlice = createSlice({
  initialState,
  name: 'selectedLoad',
  reducers: {
    // Нужно для селекта данных определенной загрузки CBD
    setSelectedLoadCbd(_, action: PayloadAction<IUploadTable>) {
      return action.payload;
    },
  },
});

export const useSelectedLoad = (): IUploadTable => {
  return useAppSelector((state: RootState) => state.cbd.selectedLoad);
};

export const { setSelectedLoadCbd } = selectedLoadSlice.actions;
