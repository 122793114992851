import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';
import { IRequestStatus } from 'common/cbd/types';
import { getRequestStatuses } from 'api/cbd/selects';

interface SliceState {
  list: IRequestStatus[];
}

const initialState: SliceState = {
  list: [],
};

export const getRequestStatusesList = createAsyncThunk(
  'requestStatuses/getRequestStatusesList',
  async (_, { rejectWithValue }) => {
    try {
      return await getRequestStatuses();
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const requestStatusesSlice = createSlice({
  initialState,
  name: 'requestStatuses',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRequestStatusesList.rejected, (state: SliceState) => {
        state.list = [];
      })
      .addCase(
        getRequestStatusesList.fulfilled,
        (state: SliceState, action: PayloadAction<IRequestStatus[]>) => {
          state.list = action.payload;
        }
      );
  },
});

export const useRequestStatuses = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.requestStatuses);
};
