import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getNomenclatureLink } from 'api/links';
import { GetNomenclatureLink } from '@ppm/ppm-platform-api';
import { setFailure, setLoading } from 'store/helpers';
import { RequiredStateFields, RootState } from 'store/types';
import { useAppSelector } from 'store/hooks';
import { fillDefaultNomenclature } from 'store/dashboard/helpers';

export type NomenclatureLink = Record<string, number[][]> | null;

interface SliceState extends RequiredStateFields {
  data: NomenclatureLink;
}

export const getNomenclatureByLdap = createAsyncThunk(
  'dashboard/getNomenclatureByLdap',
  async (query: GetNomenclatureLink.Query, { rejectWithValue }) => {
    try {
      return await getNomenclatureLink({ conf: { params: query } });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const nomenclatureLinkSlice = createSlice({
  initialState: { data: null, isLoading: false, isFailure: false },
  name: 'nomenclatureLink',
  reducers: {
    clearNomenclatureLinks(state: SliceState) {
      state.data = null;
      state.isLoading = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNomenclatureByLdap.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getNomenclatureByLdap.fulfilled,
        (state: SliceState, action: PayloadAction<GetNomenclatureLink.Response>) => {
          if (action.payload.data) {
            const keys = Object.keys(action.payload.data);
            state.data = keys.reduce((previousValue, roleId) => {
              return {
                [roleId]: fillDefaultNomenclature(
                  (action.payload.data as NonNullable<GetNomenclatureLink.Response['data']>)[
                    roleId
                  ],
                  [],
                  []
                ),
                ...previousValue,
              };
            }, {});
          } else {
            state.data = null;
          }
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getNomenclatureByLdap.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const { clearNomenclatureLinks } = nomenclatureLinkSlice.actions;

export const useNomenclatureLink = (): { nomenclatureLink: SliceState } => {
  return {
    nomenclatureLink: useAppSelector((state: RootState) => state.dashboard.nomenclatureLink),
  };
};
