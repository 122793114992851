import React, { useCallback } from 'react';
import { usePriceListItemsData, setPage, setLimit } from 'store/priceList/priceListItems';
import { DataTable, DataTableFooter, Notification, Overlay } from '@mozaic-ds/react';
import cn from 'classnames';
import { Paging } from 'components/Paging/Paging';
import { useAppTranslations } from 'translations';
import { useCurrentPriceListData } from 'store/priceList/currentPriceList';
import styles from '../../PriceList.module.scss';
import { useGetHeaders } from './hooks';

export const PriceListTable = () => {
  const translations = useAppTranslations();
  const headers = useGetHeaders();
  const { isLoading, data, itemsCount, limit, page } = usePriceListItemsData().data;
  const { priceList } = useCurrentPriceListData().data;

  const getRowKey = useCallback(({ articleId }) => articleId, []);

  return (
    <>
      <Overlay isVisible={isLoading} />
      {priceList.hasNewPrices && (
        <Notification
          className="mu-mb-200"
          message={translations('addingNewPriceNotification')}
          theme="info"
        />
      )}
      <DataTable
        columns={headers}
        rows={data}
        getRowKey={getRowKey}
        className={cn('mu-mb-200', styles.table)}
      >
        <DataTableFooter className="ml-flex--jc-space-between">
          <Paging
            itemsCount={itemsCount}
            limit={limit}
            page={page}
            setPage={setPage}
            setLimit={setLimit}
          />
        </DataTableFooter>
      </DataTable>
    </>
  );
};
