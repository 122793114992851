import { chartViewTypes } from 'common/consts';
import { IChartsExtends } from 'common/cbd/types';
import { getPercentage } from 'common/utils';

export const getChartDataValues = (data: IChartsExtends | null, chartViewType?: string) => {
  let groupsChartDataValue: number[] = [];
  let currencyChartDataValue: number[] = [];
  let expensesChartDataValue: number[] = [];

  if (!data)
    return {
      groupsChartDataValue,
      currencyChartDataValue,
      expensesChartDataValue,
    };

  const {
    groups: { logistics, other, production, purchase },
    sums: { groups },
    currencies: { EUR, RUB, USD },
    sums: { currencies, expenseTypes },
    expenseTypes: { permanent, variables },
  } = data;

  if (data && (!chartViewType || chartViewType === chartViewTypes[1])) {
    const rubPercent = getPercentage(RUB, currencies);
    const usdPercent = getPercentage(USD, currencies);
    const eurPercent = getPercentage(EUR, currencies);
    const permanentPercent = getPercentage(permanent, expenseTypes);
    const variablesPercent = getPercentage(variables, expenseTypes);
    const purchasePercent = getPercentage(purchase, groups);
    const productionPercent = getPercentage(production, groups);
    const logisticsPercent = getPercentage(logistics, groups);
    const otherPercent = getPercentage(other, groups);

    groupsChartDataValue = [purchasePercent, productionPercent, logisticsPercent, otherPercent];
    currencyChartDataValue = [rubPercent, usdPercent, eurPercent];
    expensesChartDataValue = [permanentPercent, variablesPercent];
  }

  if (data && chartViewType === chartViewTypes[0]) {
    groupsChartDataValue = [
      Number(purchase.toFixed(2)),
      Number(production.toFixed(2)),
      Number(logistics.toFixed(2)),
      Number(other.toFixed(2)),
    ];
    currencyChartDataValue = [
      Number(RUB.toFixed(2)),
      Number(USD.toFixed(2)),
      Number(EUR.toFixed(2)),
    ];
    expensesChartDataValue = [Number(permanent.toFixed(2)), Number(variables.toFixed(2))];
  }

  return {
    groupsChartDataValue,
    currencyChartDataValue,
    expensesChartDataValue,
  };
};
