import React from 'react';
import { CheckBox, Flex } from '@mozaic-ds/react';
import { useSubrequests } from 'store/subrequests/subrequests';
import { Controller, useFormContext } from 'react-hook-form';
import { SubRequest } from 'pages/Request/partials/SubRequests/SubRequest';
import { CreatePricelistFields } from '../types';

export const SubRequests = () => {
  const { control } = useFormContext<CreatePricelistFields>();
  const { subrequests } = useSubrequests().data;

  return (
    <Flex direction="column" marginTop="mu150">
      {subrequests.map((subRequest, index) => (
        <Flex direction="row" marginBottom="mu150" alignItems="baseline" key={subRequest.id}>
          <Controller
            render={({ field }) => (
              <CheckBox
                {...field}
                isChecked={field.value}
                value={field.name}
                isDisabled={!subRequest.flowRights.isPricelistAccessible}
              />
            )}
            name={`subrequests.${index}.isChecked`}
            control={control}
          />
          <SubRequest container={subRequest} />
        </Flex>
      ))}
    </Flex>
  );
};
