import { useController, useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { useAppTranslations } from 'translations';
import {
  clearExternalSourceDeletedFilters,
  setExternalSourceFilters,
} from 'store/cbd/externalData/analysis/filters';
import { getLabel } from './consts';
import { ExternalSourceFilterName } from '../ExternalSource.types';

interface IMultipleData {
  source: string;
  categoryId: string;
  typeId: string;
  subtypeId: string;
  name: string;
}

interface IDefaultValues {
  multipleData: IMultipleData[];
  externalSourceDate: string[];
  name2: string;
  value: string;
  region: string;
  measure: string;
  currencyCode: string;
  infoGrade: string;
  infoDiameter: string;
  infoThickness: string;
  infoContract: string;
  infoStandard: string;
  priceTypeName: string;
}

export const useFiltersForm = () => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const defaultValues: IDefaultValues = {
    multipleData: [
      {
        source: '',
        categoryId: '',
        typeId: '',
        subtypeId: '',
        name: '',
      },
    ],
    externalSourceDate: [],
    name2: '',
    value: '',
    region: '',
    measure: '',
    currencyCode: '',
    infoGrade: '',
    infoDiameter: '',
    infoThickness: '',
    infoContract: '',
    infoStandard: '',
    priceTypeName: '',
  };

  const form = useForm({
    defaultValues,
    mode: 'onChange',
  });

  const { control, handleSubmit } = form;

  const { field: name2 } = useController({ name: 'name2', control });
  const { field: externalSourceDate } = useController({ name: 'externalSourceDate', control });
  const { field: value } = useController({ name: 'value', control });
  const { field: region } = useController({ name: 'region', control });
  const { field: measure } = useController({ name: 'measure', control });
  const { field: currencyCode } = useController({ name: 'currencyCode', control });
  const { field: infoGrade } = useController({ name: 'infoGrade', control });
  const { field: infoDiameter } = useController({ name: 'infoDiameter', control });
  const { field: infoThickness } = useController({ name: 'infoThickness', control });
  const { field: infoContract } = useController({ name: 'infoContract', control });
  const { field: infoStandard } = useController({ name: 'infoStandard', control });
  const { field: priceTypeName } = useController({ name: 'priceTypeName', control });

  const onSubmit = useCallback(() => {
    handleSubmit(({ multipleData, ...data }) => {
      const mappedData = {
        ...data,
        ...multipleData.reduce(
          (prev, curr) => {
            return Object.keys(curr).reduce(
              (previous, current) => {
                const dataValue = curr[current as keyof IMultipleData];

                if (dataValue) {
                  return {
                    ...previous,
                    [current]: [...prev[current as keyof IMultipleData], dataValue],
                  };
                }

                return {
                  ...previous,
                  [current]: [...prev[current as keyof IMultipleData]],
                };
              },
              {
                source: [],
                categoryId: [],
                typeId: [],
                subtypeId: [],
                name: [],
              }
            );
          },
          {
            source: [],
            categoryId: [],
            typeId: [],
            subtypeId: [],
            name: [],
          }
        ),
      };

      const keys = Object.keys(mappedData);
      dispatch(clearExternalSourceDeletedFilters());
      dispatch(
        setExternalSourceFilters(
          keys
            .map((key) => {
              return {
                id: key,
                value: mappedData[key as ExternalSourceFilterName],
                label: getLabel(key as ExternalSourceFilterName, translations),
              };
            })
            .filter(({ value: filterValue }) =>
              Array.isArray(filterValue) ? filterValue.length > 0 : Boolean(filterValue)
            )
        )
      );
    })();
  }, [dispatch, handleSubmit, translations]);

  form.watch();

  return {
    form,
    name2,
    externalSourceDate,
    value,
    region,
    measure,
    currencyCode,
    infoGrade,
    infoDiameter,
    infoThickness,
    infoContract,
    infoStandard,
    priceTypeName,
    onSubmit,
  };
};
