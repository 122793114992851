import { useAppDispatch } from 'store/hooks';
import { getNomenclatureData, setLink } from 'store/dashboard/rightsManagement/nomenclature';
import React, { FC, useCallback } from 'react';
import {
  CheckBox,
  Flex,
  Text,
  Accordion,
  AccordionHeader,
  AccordionContent,
  AccordionHeading,
} from '@mozaic-ds/react';
import { clearAll as clearSupplierCheckbox } from 'store/dashboard/rightsManagement/supplier';
import { clearAll } from 'store/dashboard/rightsManagement/supplierLinkUsers';
import { useUserData } from 'store/auth/user';
import { getHasGrants } from 'common/utils';
import { Leaf } from 'store/dashboard/model/nomenclature/leaf';
import { useNomenclature } from './NomenclatureContext';
import styles from '../AccordionsView.module.scss';

type Props = {
  item: Leaf;
};

export const Item: FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { isInterchangeable } = useNomenclature();

  const { userGrants } = useUserData().user;
  const hasGrant = getHasGrants(userGrants);
  const { AdminNomenclatureLinksWrite } = hasGrant;

  const { item, children } = props;
  const { data, children: childrenNode } = item;

  const getNomenclature = useCallback(() => {
    if (!childrenNode.length) dispatch(getNomenclatureData({ path: data.path }));
  }, [dispatch, childrenNode, data.path]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      dispatch(setLink({ path: data.path, value: e.target.checked }));
      if (isInterchangeable) {
        dispatch(clearSupplierCheckbox({ isClearRole: false }));
        dispatch(clearAll());
      }
    },
    [data.path, dispatch, isInterchangeable]
  );

  return (
    <Accordion size="s" onClick={getNomenclature}>
      <AccordionHeader>
        <Flex direction="row" paddingLeft="mu100" paddingTop="mu100">
          <CheckBox
            className={styles.indeterminate}
            name={data.name}
            isChecked={data.isChecked}
            onClick={(e) => e.stopPropagation()}
            onChange={handleChange}
            isDisabled={isInterchangeable ? false : !AdminNomenclatureLinksWrite}
            isIndeterminate={data.isFullSelected === false}
          />
          <AccordionHeading>
            <Text>
              {data.path.at(-1)} - {data.name}
            </Text>
          </AccordionHeading>
        </Flex>
      </AccordionHeader>
      <AccordionContent className="mu-pl-150">{children}</AccordionContent>
    </Accordion>
  );
};
