import { useAppTranslations } from 'translations';
import { IDataTableColumn } from '@mozaic-ds/react';
import { PriceListItemsData, usePriceListItemsData } from 'store/priceList/priceListItems';
import { findUniqBsgHeaders } from './helpers';

export const useGetHeaders = (): Array<IDataTableColumn<PriceListItemsData[number]>> => {
  const t = useAppTranslations();
  const { data } = usePriceListItemsData().data;

  return [
    {
      label: t('priceListCode'),
      key: 'articleId',
    },
    {
      label: t('partnerProductId'),
      key: 'partnerProductId',
    },
    {
      label: t('product'),
      key: 'articleName',
    },
    {
      label: t('priceListBarCode'),
      key: 'ean',
    },
    {
      label: t('minimalOrderQuantity'),
      key: 'minimalOrderQuantity',
    },
    {
      label: t('priceListVATRate'),
      key: 'vatRate',
    },
    ...findUniqBsgHeaders(data),
  ];
};
