import React from 'react';
import { useTabsContext } from '@mozaic-ds/react/lib/components/Tabs/TabsContext';
import { BSG_TAB_NAME } from '../../consts';
import { BuyerSiteGroup } from './BuyerSiteGroup';

export const BuyerSiteGroupContainer = () => {
  const tabsContext = useTabsContext();

  if (tabsContext?.tab !== BSG_TAB_NAME) return null;

  return <BuyerSiteGroup />;
};
