import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateRequestResponse, CreateRequestBody, RequestModelView } from '@ppm/ppm-platform-api';
import { createRequest } from 'api/request';
import { Steps } from 'pages/CreatingRequest/types';
import { getStep } from 'store/requestCreation/helpers';
import { RequestFlowRights } from '@ppm/ppm-platform-request-service';
import axios from 'axios';
import { RequiredStateFields, RootState } from '../types';
import { setFailure, setLoading } from '../helpers';
import { useAppSelector } from '../hooks';

interface SliceState extends RequiredStateFields {
  request: RequestModelView | null;
  grants: RequestFlowRights['grants'];
  error: string | null;
  step: Steps | undefined;
}

const initialState = {
  request: null,
  error: null,
  grants: {},
  step: undefined,
  isLoading: false,
  isFailure: false,
};

export const getRequestCreation = createAsyncThunk<CreateRequestResponse, CreateRequestBody>(
  'requestCreation/createRequest',
  async (body, { rejectWithValue }) => {
    try {
      return await createRequest({ body });
    } catch (e: unknown) {
      return rejectWithValue(e);
    }
  }
);

export const requestCreationSlice = createSlice({
  initialState,
  name: 'requestCreation',
  reducers: {
    setError(state: SliceState, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setStep(state: SliceState, action: PayloadAction<Steps>) {
      state.step = action.payload;
    },

    setData(
      state: SliceState,
      action: PayloadAction<{
        request: RequestModelView;
        grants: RequestFlowRights['grants'];
      }>
    ) {
      state.step = getStep(action.payload.request);
      state.grants = action.payload.grants;
      state.request = action.payload.request;
      state.error = action.payload.request.error;
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRequestCreation.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getRequestCreation.fulfilled,
        (state: SliceState, action: PayloadAction<CreateRequestResponse>) => {
          state.request = action.payload.data;
          state.step = getStep(action.payload.data);
          state.error = action.payload.data.error;
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getRequestCreation.rejected, (state: SliceState, action) => {
        if (axios.isAxiosError(action.payload) && action.payload.response)
          state.error = action.payload.response?.data?.message;
        setFailure(state);
      });
  },
});

export const { setError, setStep, setData, reset } = requestCreationSlice.actions;

export const useRequestCreationData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.requestCreation.requestCreation) };
};
