import React, { FC, useCallback } from 'react';
import { RemovableTag } from '@mozaic-ds/react';
import { IFilter } from 'common/types';
import { useUserData } from 'store/auth/user';
import {
  attributeHierarchyFields,
  categoryHierarchyFields,
  ExtendsFilters,
  nomenclatureHierarchyFields,
} from '../consts';
import useFormattedFilterValue from './useFormattedFilterValue';

interface ITags {
  filters: IFilter<ExtendsFilters>[];
  className?: string;
  onRemoveTag?: (idForDelete: string[], filtersForDelete: IFilter<ExtendsFilters>[]) => void;
}

const Tags: FC<ITags> = ({ filters, className, onRemoveTag }) => {
  const { lang } = useUserData().user;
  const { formattedFilterValue } = useFormattedFilterValue(filters);

  const handleRemoveTag = useCallback(
    (id: string): void => {
      if (!onRemoveTag) return;

      switch (id) {
        case 'itemGroup':
        case 'itemDep':
        case 'itemClass':
        case 'itemSubclass': {
          onRemoveTag(
            nomenclatureHierarchyFields,
            filters.filter((item) => !nomenclatureHierarchyFields.includes(item.id))
          );
          break;
        }
        case 'categoryId':
        case 'typeId':
        case 'subtypeId': {
          onRemoveTag(
            categoryHierarchyFields,
            filters.filter((item) => !categoryHierarchyFields.includes(item.id))
          );
          break;
        }
        case 'attributeDep':
        case 'attributeSubDep':
        case 'attributeGroup': {
          onRemoveTag(
            attributeHierarchyFields,
            filters.filter((item) => !attributeHierarchyFields.includes(item.id))
          );
          break;
        }
        default: {
          onRemoveTag(
            [id],
            filters.filter((item) => item.id !== id)
          );
          break;
        }
      }
    },
    [filters, onRemoveTag]
  );

  return (
    <>
      {filters.map((tag) => {
        return (
          <RemovableTag className={className} key={tag.id} onRemove={() => handleRemoveTag(tag.id)}>
            {formattedFilterValue(tag, lang)}
          </RemovableTag>
        );
      })}
    </>
  );
};

export default Tags;
