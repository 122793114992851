import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  PricelistCollapsedItems,
  PricelistCollapsedItemsCountResponse,
  PricelistCollapsedItemsResponse,
} from '@ppm/ppm-platform-api';
import { getPricelistCollapsedItems, getPricelistCollapsedItemsCount } from 'api/pricelist';
import { setFailure, setLoading } from 'store/helpers';
import { useAppSelector } from '../hooks';
import { RequiredStateFields, RootState } from '../types';

export type PriceListItemsData = PricelistCollapsedItemsResponse['pricelistItems'];

interface SliceState extends RequiredStateFields {
  data: PriceListItemsData;
  itemsCount: number;
  limit: number;
  page: number;
}

export const getPricelistItemsData = createAsyncThunk<
  PricelistCollapsedItemsResponse,
  { requestId: string; priceListId: string; query?: PricelistCollapsedItems.Query }
>('priceListItems/getPricelistItemsData', async (args, { rejectWithValue }) => {
  try {
    return await getPricelistCollapsedItems({
      urlParameters: [args.requestId, args.priceListId],
      conf: { params: args.query },
    });
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const getPricelistItemsCountData = createAsyncThunk<
  PricelistCollapsedItemsCountResponse,
  { requestId: string; priceListId: string }
>('priceListItems/getPricelistItemsCountData', async (args, { rejectWithValue }) => {
  try {
    return await getPricelistCollapsedItemsCount({
      urlParameters: [args.requestId, args.priceListId],
    });
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const priceListItemsSlice = createSlice({
  initialState: {
    data: [],
    itemsCount: 0,
    limit: 10,
    page: 1,
    isLoading: false,
    isFailure: false,
  },
  name: 'priceListItems',
  reducers: {
    setLimit: (state: SliceState, { payload }) => {
      state.limit = payload;
    },
    setPage: (state: SliceState, { payload }) => {
      state.page = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPricelistItemsData.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(getPricelistItemsData.fulfilled, (state: SliceState, action) => {
        state.data = action.payload.pricelistItems;
        state.isLoading = false;
        state.isFailure = false;
      })
      .addCase(getPricelistItemsData.rejected, (state: SliceState) => {
        setFailure(state);
      })
      .addCase(getPricelistItemsCountData.fulfilled, (state: SliceState, action) => {
        state.itemsCount = action.payload.count;
      });
  },
});

export const { setLimit, setPage } = priceListItemsSlice.actions;

export const usePriceListItemsData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.priceList.priceListItems) };
};
