import React, { FC, useEffect } from 'react';
import { DateInput, Flex, Text, TextInput, View } from '@mozaic-ds/react';
import cn from 'classnames';
import { addDays } from 'date-fns';
import { getOtherSelectValue, useReasonsData } from 'store/dictionary/reasons';
import { useAppTranslations } from 'translations';
import { useGetReasonLabel } from 'hook/useGetReasonLabel';
import { getLocale, parseDate, parseDateForMozaicComponent, parseDateTime } from 'common/utils';
import { Label } from 'components/Label/Label';
import { useDueDateData } from 'store/dictionary/dueDate';
import Dropdown from 'components/Dropdown/Dropdown';
import { useController, useFormContext } from 'react-hook-form';
import { useUserData } from 'store/auth/user';
import { useRequestData } from 'store/request/request';
import { useGetGrants } from 'pages/Request/hooks';
import { TemplateUploader } from 'components/TemplateUploader/TemplateUploader';
import { DownloadingTemplate } from './DownloadingTemplate/DownloadingTemplate';
import { RequestFormFields } from '../types';
import { FieldDate } from './SubRequests/partials/FieldDate/FieldDate';
import { Dates } from './SubRequests/partials/FieldDate/types';
import styles from '../Request.module.scss';
import { getDaysToResponse } from '../../Requests/utils';
import { InfoText } from './InfoText';

type RequestFormProps = {
  id: string;
};

export const RequestForm: FC<RequestFormProps> = ({ id }) => {
  const translations = useAppTranslations();

  const { request, containers, selectedContainers } = useRequestData().data;
  const { lang } = useUserData().user;
  const { reasons } = useReasonsData().data;
  const { dueDate: date } = useDueDateData().data;
  const { min, max } = date;
  const getReasonLabel = useGetReasonLabel(reasons);
  const { control, setValue } = useFormContext<RequestFormFields>();
  const { hasRequestGrants, hasSubRequestGrant } = useGetGrants();

  const { field: reasonListId } = useController({ name: 'reasonListId', control });
  const { field: customReason } = useController({ name: 'customReason', control });
  const { field: dueDate } = useController({ name: 'dueDate', control });

  useEffect(() => {
    if (
      !reasonListId.value.find(({ value }) => value === getOtherSelectValue(translations).value)
    ) {
      setValue('customReason', '');
    }
  }, [setValue, reasonListId.value, containers, translations]);

  const {
    SupplierTemplateDownload,
    SupplierTemplateUpload,
    RequestEditReasonList,
    RequestEditCustomReason,
    RequestEditDueDate,
  } = hasRequestGrants;
  const {
    RequestContainerShowSigningDate,
    RequestContainerPutDateOfSigning,
    RequestContainerEditPriceApplyDate,
  } = hasSubRequestGrant;

  if (!request) return null;

  return (
    <Flex direction="row" marginTop="mu150">
      <View
        className={cn(styles.wrapper, styles.first, 'mu-mr-200')}
        paddingTop="mu150"
        paddingBottom="mu200"
        paddingRight="mu200"
        paddingLeft="mu200"
        radius="l"
        shadow="s"
      >
        <Text weight="semi-bold" theme="dark" as="p" className="mu-mb-100">
          {translations('commonInfo')}
        </Text>
        <Flex justifyContent="space-between">
          <View>
            {RequestEditReasonList ? (
              <Label text={translations('reason')}>
                <Dropdown
                  {...reasonListId}
                  onChange={reasonListId.onChange}
                  placeholder={translations('selectPlaceholder')}
                  listBoxItems={reasons}
                  mode="multi"
                />
              </Label>
            ) : (
              <InfoText label="reason" value={getReasonLabel(request)} />
            )}

            {RequestEditCustomReason ? (
              <Label text={translations('otherReason')}>
                <TextInput
                  className="mu-mt-025 mu-mb-100"
                  {...customReason}
                  placeholder={translations('otherReasonPlaceholder')}
                  size="m"
                  isDisabled={
                    !reasonListId.value.find(
                      ({ value }) => value === getOtherSelectValue(translations).value
                    )
                  }
                />
              </Label>
            ) : (
              <InfoText label="anotherReason" value={request.customReason} />
            )}
            <InfoText label="supplierName" value={request.supplierName} />

            <InfoText label="departmentNumber" value={request.supplierId} />
          </View>

          <View>
            {RequestEditDueDate ? (
              <Label text={translations('applicationDate')}>
                <DateInput
                  className={cn('mc-text-input mu-mt-025 mu-mb-100', styles.dateInput)}
                  {...dueDate}
                  min={min}
                  max={max}
                />
              </Label>
            ) : (
              <InfoText
                label="applicationDate"
                value={parseDate(new Date(request.dueDate), getLocale(lang))}
              />
            )}
            {selectedContainers.length === 1 && RequestContainerShowSigningDate && (
              <FieldDate
                id={id}
                isDisabled={!RequestContainerPutDateOfSigning}
                filedName={Dates.SigningDate}
                restriction={{
                  max: parseDateForMozaicComponent(new Date()),
                  min: `${new Date().getFullYear()}-01-01`,
                }}
              />
            )}

            {selectedContainers.length === 1 && (
              <FieldDate
                id={id}
                isDisabled={!RequestContainerEditPriceApplyDate}
                filedName={Dates.PriceApplyDate}
                restriction={{
                  min: parseDateForMozaicComponent(addDays(new Date(), 3)),
                }}
              />
            )}
          </View>
        </Flex>
        {SupplierTemplateDownload && (
          <DownloadingTemplate
            id={id}
            text="productListSuggestion"
            label="productListSuggestion"
            isHideNotification
          >
            <TemplateUploader
              id={id}
              isDisabled={!SupplierTemplateUpload}
              grants={hasRequestGrants}
              className="mu-mr-100"
            />
          </DownloadingTemplate>
        )}
      </View>

      <View
        className={cn(styles.wrapper, styles.second)}
        paddingTop="mu150"
        paddingBottom="mu200"
        paddingRight="mu200"
        paddingLeft="mu200"
        radius="l"
        shadow="s"
      >
        <Text weight="semi-bold" theme="dark" as="p" className="mu-mb-100">
          {translations('additionalInfo')}
        </Text>
        <InfoText
          label="responseTime"
          value={getDaysToResponse(request.deadlineDate, getLocale(lang))}
        />
        <InfoText
          label="dateCreated"
          value={parseDateTime(new Date(request.createDate), getLocale(lang))}
          classValue="mu-mb-250"
        />
      </View>
    </Flex>
  );
};
