import { useRequestData } from 'store/request/request';
import { useController, useForm } from 'react-hook-form';
import { FileType } from '@ppm/ppm-platform-file-service/build/enums';
import { OtherFilesFormFields } from './types';

export const useOtherFilesForm = () => {
  const { request } = useRequestData().data;
  const { control } = useForm<OtherFilesFormFields>({
    mode: 'onChange',
    defaultValues: {
      otherFiles: request
        ? request.filesInfo.filter((fileInfo) => fileInfo.fileType === FileType.OtherFiles)
        : [],
    },
  });
  const { field: otherFiles } = useController({ name: 'otherFiles', control });

  return { otherFiles };
};
