import { Loader } from '@mozaic-ds/react';
import { useMount } from 'hook/useMount';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { getNomenclature } from 'store/cbd/nomenclature';
import { useSelectedLoad } from 'store/cbd/selectedLoad';
import { getSuppliersData, useSuppliers } from 'store/cbd/suppliers';
import { useAppDispatch } from 'store/hooks';
import SubmitForm from '../SubmitForm/SubmitForm';
import { NomenclatureProvider } from './FormProvider';
import Nomenclature from './Nomenclature';

interface INomenclatureWrapper {
  updateTableData: () => void;
}

const NomenclatureWrapper: FC<INomenclatureWrapper> = ({ updateTableData }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { supplierId } = useSelectedLoad();
  const { suppliers } = useSuppliers();

  const getInitData = async () => {
    await dispatch(getNomenclature());
    dispatch(getSuppliersData(supplierId));
  };

  useMount(() => {
    if (!supplierId) {
      navigate(-1);
      return;
    }
    getInitData();
  });

  return suppliers.length !== 0 ? (
    <NomenclatureProvider>
      <SubmitForm updateTableData={updateTableData} />
      <Nomenclature />
    </NomenclatureProvider>
  ) : (
    <Loader />
  );
};

export default NomenclatureWrapper;
