import { TextInput, Select, View, Flex } from '@mozaic-ds/react';
import classNames from 'classnames';
import { getHasGrants } from 'common/utils';
import React, { useEffect, useMemo } from 'react';
import { useUserData } from 'store/auth/user';
import {
  setClassDataOptions,
  setSubClassOptions,
  setSubDepOptions,
  useNomenclature,
} from 'store/cbd/nomenclature';
import { useSelectedLoad } from 'store/cbd/selectedLoad';
import { CbdStatus } from 'common/cbd/types';
import { useAppTranslations } from 'translations';
import _ from 'lodash';
import { useAppDispatch } from 'store/hooks';
import { useNomenclatureActions } from './hooks/useNomenclatureActions';
import styles from './Nomenclature.module.scss';
import useNomenclatureForm from './hooks/useNomenclatureForm';

const Nomenclature = () => {
  const translate = useAppTranslations();
  const dispatch = useAppDispatch();
  const { user } = useUserData();
  const hasGrants = getHasGrants(user.userGrants);
  const { status } = useSelectedLoad();
  const { handleChangeDependedNomenclature } = useNomenclatureActions();
  const {
    data: { groups },
    nomenclatureOptions: { subDepOptions, subClassOptions, classDataOptions },
  } = useNomenclature();

  const { form, dep, subDep, classData, subClass, item, itemValidate } = useNomenclatureForm();

  const isDisabled: boolean = useMemo(
    () =>
      (hasGrants.AdminRole && !hasGrants.AdminCBD) ||
      status === CbdStatus.Approved ||
      status === CbdStatus.Declined,
    [hasGrants.AdminCBD, hasGrants.AdminRole, status]
  );

  useEffect(() => {
    dispatch(setSubDepOptions({ groupId: dep.value }));
    dispatch(
      setClassDataOptions({
        groupId: dep.value,
        departmentId: subDep.value,
      })
    );
    dispatch(
      setSubClassOptions({
        groupId: dep.value,
        departmentId: subDep.value,
        classId: classData.value,
      })
    );
  }, [dep.value, subDep.value, classData.value, dispatch]);

  return (
    <View marginBottom="mu150" marginTop="mu100">
      <Flex marginBottom="mu100" justifyContent="space-between" alignItems="flex-end">
        <View className={styles.field} marginRight="mu100">
          <label htmlFor="dep">{translate('devision')}</label>
          <TextInput
            {...dep}
            className={styles.groupInput}
            size="m"
            placeholder={translate('cantChoose')}
            isDisabled
            value={
              dep.value
                ? `${dep.value}-${
                    groups?.filter((groupItem) => groupItem.id === dep.value)[0]?.label || ''
                  }`
                : ''
            }
          />
        </View>
        <View className={styles.field} marginRight="mu100">
          <label htmlFor="subDep">{translate('subDevision')}</label>
          <Select
            {...subDep}
            className={styles.input}
            isDisabled={isDisabled}
            onChange={(e) => {
              subDep.onChange(e.target.value);
              handleChangeDependedNomenclature('subDep');
            }}
            options={subDepOptions}
            placeholder={translate('chooseAnOption')}
            size="m"
          />
        </View>
        <View className={styles.field}>
          <label htmlFor="classData">{translate('type')}</label>
          <Select
            {...classData}
            className={styles.input}
            isDisabled={!subDep.value || isDisabled}
            onChange={(e) => {
              classData.onChange(e.target.value);
              handleChangeDependedNomenclature('classData');
            }}
            options={classDataOptions}
            placeholder={translate('chooseAnOption')}
            size="m"
          />
        </View>
      </Flex>
      <Flex justifyContent="space-between" alignItems="flex-end">
        <View className={styles.field} marginRight="mu100">
          <label htmlFor="subClass">{translate('subType')}</label>
          <Select
            {...subClass}
            className={styles.input}
            isDisabled={!classData.value || isDisabled}
            onChange={(e) => {
              subClass.onChange(e.target.value);
              handleChangeDependedNomenclature('subClass');
            }}
            options={subClassOptions}
            placeholder={translate('chooseAnOption')}
            size="m"
          />
        </View>
        <View className={styles.field}>
          <label htmlFor="item">{translate('product')}</label>
          <TextInput
            {...form.register('item', {
              validate: itemValidate,
            })}
            onChange={_.debounce((e) => item.onChange(e.target.value), 1000)}
            type="number"
            className={classNames({
              itemInput: true,
              'is-valid':
                item.value && !form.formState.isValidating && !form.formState.errors?.item,
              'is-invalid':
                item.value && !form.formState.isValidating && Boolean(form.formState.errors?.item),
            })}
            isDisabled={isDisabled}
            id="item"
            placeholder={translate('enterItemNumber')}
            size="m"
          />
        </View>
      </Flex>
    </View>
  );
};

export default Nomenclature;
