import React, { FC, useEffect, useMemo } from 'react';
import { Accordion, AccordionHeader, AccordionHeading } from '@mozaic-ds/react';
import { useNomenclatureData } from 'store/dashboard/rightsManagement/nomenclature';
import { useAppDispatch } from 'store/hooks';
import { clear, getSupplierIds, useSupplierIdsData } from 'store/dictionary/supplierIds';
import { useAppTranslations } from 'translations';
import SuppliersContent from './SuppliersContent';

type SupplierProps = {
  isInterchangeable?: boolean;
};

const Suppliers: FC<SupplierProps> = ({ isInterchangeable = true }) => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();
  const { searchValue, offset } = useSupplierIdsData().data;
  const { checkedNomenclatureGroupedByRole } = useNomenclatureData().nomenclature;

  useEffect(() => {
    return () => {
      dispatch(clear());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSupplierIds({ searchString: searchValue, offset }));
  }, [dispatch, searchValue, offset]);

  const isDisabledAccordion = useMemo((): boolean => {
    if (!checkedNomenclatureGroupedByRole) return false;
    return Boolean(checkedNomenclatureGroupedByRole);
  }, [checkedNomenclatureGroupedByRole]);

  return (
    <Accordion isDisabled={isDisabledAccordion}>
      <AccordionHeader>
        <AccordionHeading>{translations('supplierAccordionHeader')}</AccordionHeading>
      </AccordionHeader>
      <SuppliersContent
        isInterchangeable={isInterchangeable}
        isDisabledAccordion={isDisabledAccordion}
      />
    </Accordion>
  );
};
export default Suppliers;
