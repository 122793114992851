import { useController, useForm } from 'react-hook-form';

export interface IDeletionTemplateForm {
  deleteRow: string;
  deleteColumn: string;
}

const useEditTemplateForm = () => {
  const form = useForm({
    defaultValues: {
      deleteRow: '',
      deleteColumn: '',
    },
  });

  const { control } = form;

  const { field: deleteRow } = useController({ name: 'deleteRow', control });
  const { field: deleteColumn } = useController({ name: 'deleteColumn', control });

  return {
    form,
    deleteRow,
    deleteColumn,
  };
};

export default useEditTemplateForm;
