import React from 'react';
import { RequestGateway } from '@ppm/ppm-platform-api';
import { SocketContextProvider } from 'components/SocketContainer/SocketContext';
import { useParams } from 'react-router-dom';
import { RequestSocketContainer } from './RequestSocketContainer';

const RequestSocketProvider = () => {
  const { id } = useParams();

  if (!id) return null;

  return (
    <SocketContextProvider
      id={id}
      namespace={RequestGateway.namespace}
      path={RequestGateway.path}
      keyParam="requestId"
    >
      <RequestSocketContainer id={id} />
    </SocketContextProvider>
  );
};

export default RequestSocketProvider;
