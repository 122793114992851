import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const translationNs = 'translations';

export type Translations = (key: string, options?: Record<string, string | number>) => string;

export function useAppTranslations(): Translations {
  const { t } = useTranslation(translationNs);

  return useCallback((...args: Parameters<Translations>) => t(...args), [t]);
}
