import {
  LoginResponse,
  MeResponse,
  Login,
  Me,
  IsAuthResponse,
  IsAuth,
} from '@ppm/ppm-platform-api';
import { apiCreator } from './index';

export const getAuth = apiCreator<typeof IsAuth, IsAuthResponse>(IsAuth)[IsAuth.method];

export const getAuthLoginPage = apiCreator<typeof Login, LoginResponse>(Login)[Login.method];

export const getUserData = apiCreator<typeof Me, MeResponse>(Me)[Me.method];
