import { useController, useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { useAppTranslations } from 'translations';
import { clearDeletedFilters, setFilters } from 'store/cbd/scheduler/currencySubscriptions/filters';
import { getLabel } from './consts';
import { FilterName } from '../AlertCurrency.types';

export const useFiltersForm = () => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const form = useForm({
    defaultValues: {
      login: '',
      dateStart: '',
      dateFinish: '',
      currency1: '',
      currency2: '',
      subscriptionType: '',
      value: '',
      term: '',
    },
  });

  const { control, handleSubmit } = form;

  const { field: login } = useController({ name: 'login', control });
  const { field: dateStart } = useController({ name: 'dateStart', control });
  const { field: dateFinish } = useController({ name: 'dateFinish', control });
  const { field: currency1 } = useController({ name: 'currency1', control });
  const { field: currency2 } = useController({ name: 'currency2', control });
  const { field: subscriptionType } = useController({ name: 'subscriptionType', control });
  const { field: value } = useController({ name: 'value', control });
  const { field: term } = useController({ name: 'term', control });

  const onSubmit = useCallback(() => {
    handleSubmit((data) => {
      const keys = Object.keys(data);
      dispatch(clearDeletedFilters());
      dispatch(
        setFilters(
          keys
            .map((key) => ({
              id: key,
              value: data[key as FilterName],
              label: getLabel(key as FilterName, translations),
            }))
            .filter(({ value: filterValue }) =>
              Array.isArray(filterValue) ? filterValue.length > 0 : Boolean(filterValue)
            )
        )
      );
    })();
  }, [dispatch, handleSubmit, translations]);

  return {
    form,
    login,
    dateStart,
    dateFinish,
    currency1,
    currency2,
    subscriptionType,
    value,
    term,
    onSubmit,
  };
};
