import { NomenclatureState } from 'common/cbd/types';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

export const useNomenclatureActions = () => {
  const { setValue } = useFormContext<NomenclatureState>();

  const handleChangeDependedNomenclature = useCallback(
    (id: keyof NomenclatureState) => {
      switch (id) {
        case 'subDep': {
          setValue('classData', '');
          setValue('subClass', '');
          break;
        }
        case 'classData': {
          setValue('subClass', '');
          break;
        }
        default: {
          break;
        }
      }
    },
    [setValue]
  );

  return {
    handleChangeDependedNomenclature,
  };
};
