import { NomenclatureLink } from 'store/dashboard/rightsManagement/nomenclatureLinks';
import { flatten } from 'lodash';
import { CreateReplacementLinkBody } from '@ppm/ppm-platform-api';
import { SelectItem } from 'common/types';
import { ReplacementFormValue } from './types';

export const formattedNomenclatureLinks = (links: NomenclatureLink | null) => {
  if (!links) return [];
  return flatten(
    Object.keys(links).map((key) =>
      links[key].map(([departmentId, subDepartmentId, typeId, subtypeId]) => ({
        departmentId,
        subDepartmentId,
        typeId,
        subtypeId,
        roleId: Number(key),
      }))
    )
  );
};

export const formatReplacementFormData = (
  formData: ReplacementFormValue,
  ldap: string
): CreateReplacementLinkBody => {
  return {
    ldap,
    replacementLdap: (formData.replacementLdap as SelectItem).value.toString(),
    endDate: formData.endDate || undefined,
    requestIds: formData.areFullRequests ? [] : formData.requestIds,
  };
};
