import React, { FC, useCallback, useEffect } from 'react';
import { setLink, useNomenclatureData } from 'store/dashboard/rightsManagement/nomenclature';
import { CheckBox, Flex, Text, useAccordionContext } from '@mozaic-ds/react';
import { useAppDispatch } from 'store/hooks';
import { clearAll as clearSupplierCheckbox } from 'store/dashboard/rightsManagement/supplier';
import { clearAll } from 'store/dashboard/rightsManagement/supplierLinkUsers';
import { Leaf } from 'store/dashboard/model/nomenclature/leaf';
import { useNomenclature } from './NomenclatureContext';
import { Item } from './Item';

export type ItemProps = {
  item: Leaf;
};

const SubType: FC<ItemProps> = ({ item }) => {
  const { isInterchangeable } = useNomenclature();
  const dispatch = useAppDispatch();

  const { data } = item;
  const { name, path, isChecked, isFullSelected } = data;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      dispatch(setLink({ path: data.path, value: e.target.checked }));
      if (isInterchangeable) {
        dispatch(clearSupplierCheckbox({ isClearRole: false }));
        dispatch(clearAll());
      }
    },
    [data.path, dispatch, isInterchangeable]
  );
  return (
    <Flex direction="row" paddingLeft="mu100" paddingTop="mu100">
      <CheckBox
        name={name}
        isChecked={isChecked}
        onClick={(e) => e.stopPropagation()}
        onChange={handleChange}
        isIndeterminate={isFullSelected === false}
      />
      <Text>
        {path.at(-1)} - {name}
      </Text>
    </Flex>
  );
};

const Type: FC<ItemProps> = ({ item }) => {
  return (
    <Item item={item}>
      {item.children.map((child) => (
        <SubType item={child} key={child.data.path.join()} />
      ))}
    </Item>
  );
};

const SubDepartment: FC<ItemProps> = ({ item }) => {
  return (
    <Item item={item}>
      {item.children.map((child) => (
        <Type item={child} key={child.data.path.join()} />
      ))}
    </Item>
  );
};

export const Department: FC<ItemProps> = ({ item }) => {
  const { selectedRole } = useNomenclatureData().nomenclature;
  const { isDisabledAccordion } = useNomenclature();
  const { close } = useAccordionContext();

  useEffect(() => {
    close();
  }, [close, selectedRole, isDisabledAccordion]);

  return (
    <Item item={item}>
      {item.children.map((child) => (
        <SubDepartment item={child} key={child.data.path.join()} />
      ))}
    </Item>
  );
};
