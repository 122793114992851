import { FilterName } from 'pages/Requests/types';
import { Translations } from 'translations';

export const getLabel = (key: FilterName, t: Translations) => {
  return {
    supplierId: t('department'),
    reasonListId: t('reason'),
    statusIds: t('status'),
    createDate: t('createDate'),
    deadlineDate: t('responseTime'),
    dueDate: t('dueDateFilter'),
    responsibleLdap: t('responsibleLdapFilter'),
  }[key];
};

export const filtersName: FilterName[] = [
  'supplierId',
  'reasonListId',
  'statusIds',
  'createDate',
  'deadlineDate',
  'dueDate',
  'responsibleLdap',
];
