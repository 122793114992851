import React, { useEffect } from 'react';
import { Flex, IconButton, Text, View } from '@mozaic-ds/react';
import {
  NotificationAvailable24,
  ControlMore24,
  PublishTrashbin24,
  PublishEdit24,
  ControlCross24,
} from '@mozaic-ds/icons/react';
import CbdTable from 'pages/cbd/hok/partials/CbdTable/CbdTable';
import { useAppDispatch } from 'store/hooks';
import ColumnsPopup from 'pages/cbd/hok/partials/ColumnsPopup/ColumnsPopup';
import {
  changeDeletedFilters,
  setFilters,
  useFiltersData,
} from 'store/cbd/scheduler/subscriptions/filters';
import {
  setSelectedRowsId,
  useSubscriptionsList,
} from 'store/cbd/scheduler/subscriptions/subscriptionsList';
import { ExtendsTableRows } from 'pages/cbd/hok/partials/consts';
import { ISubscription } from 'api/cbd/scheduler';
import { AlertAttributeColumn, useColumnsData } from 'store/cbd/scheduler/subscriptions/columns';
import { useAppTranslations } from 'translations';
import { IFilter } from 'common/types';
import Tags from 'pages/cbd/hok/partials/Tags/Tags';
import styles from './AlertAttribute.module.scss';
import { useTableData } from './hook/data';
import { AlertAttributeColumns, filtersName } from './hook/consts';
import useAlertAttributeActions from './hook/useAlertAttributeActions';
import { useColumnsForm, useGetColumns } from './hook/columns';
import FiltersPopup from './FiltersPopup/FiltersPopup';

const AlertAttribute = () => {
  const translations = useAppTranslations();
  const dispatch = useAppDispatch();

  const { data: tableData, selectedRowsId, isAdd, editItems } = useSubscriptionsList();
  const {
    paginationProps,
    size,
    updateTableData,
    onPageChange,
    onNext,
    onPrevious,
    onSizeChange,
    getPaginationInfo,
  } = useTableData();
  const { columns } = useGetColumns();
  const { columns: columnsFilter } = useColumnsData().data;
  const { form, saveColumnsFilter, resetColumnsFilters } = useColumnsForm();
  const { deleteExtDataItems, onAddClick, onEditClick, onCancel } = useAlertAttributeActions();
  const { filters } = useFiltersData();
  const table = {
    columns,
    columnsFilter,
    selectedRowsId,
    customRowClick: (row: ExtendsTableRows) => {
      if (editItems.length) {
        return;
      }
      if (selectedRowsId.includes(row.id?.toString() || '')) {
        dispatch(setSelectedRowsId([]));
        return;
      }
      dispatch(setSelectedRowsId([row.id?.toString() || '']));
    },
  };
  const pagination = {
    paginationProps,
    size,
    onPageChange,
    onNext,
    onPrevious,
    onSizeChange,
    getPaginationInfo,
  };
  const columnsPopup = {
    id: 'alertAttributeColumnPopup',
    columns,
    columnsFilter,
    form,
    saveColumnsFilter,
    resetColumnsFilters,
  };

  useEffect(() => {
    updateTableData();
  }, [updateTableData]);

  const onRemoveTags = (idForDelete: string[], filtersForDelete: IFilter<string>[]) => {
    dispatch(changeDeletedFilters(idForDelete));
    dispatch(setFilters(filtersForDelete));
  };

  const resetFilters = () => {
    dispatch(changeDeletedFilters(filtersName));
    dispatch(setFilters([]));
  };

  return (
    <View marginBottom="mu150">
      <Flex justifyContent="space-between" marginBottom="mu150">
        <Text weight="semi-bold">{translations('setUpPriceChangeNotification')}</Text>
        <Flex>
          <FiltersPopup />
          <View marginLeft="mu100" marginRight="mu100">
            <ColumnsPopup<AlertAttributeColumns, AlertAttributeColumn, ISubscription>
              {...columnsPopup}
            />
          </View>
          <IconButton
            className="mu-mr-100"
            onClick={onAddClick}
            theme="primary"
            isDisabled={Boolean(editItems.length) && !editItems.includes('-1')}
            variant={isAdd ? 'solid' : 'bordered'}
            size="s"
          >
            {isAdd ? <NotificationAvailable24 /> : <ControlMore24 />}
          </IconButton>
          {isAdd && (
            <IconButton
              className="mu-mr-100"
              onClick={onCancel}
              theme="danger"
              variant="bordered"
              size="s"
            >
              <ControlCross24 />
            </IconButton>
          )}
          <IconButton
            isDisabled={(!selectedRowsId.length || isAdd) && !editItems.length}
            className="mu-mr-100"
            onClick={onEditClick}
            theme="primary"
            variant={editItems.length ? 'solid' : 'bordered'}
            size="s"
          >
            {Boolean(editItems.length) && !isAdd ? <NotificationAvailable24 /> : <PublishEdit24 />}
          </IconButton>
          {Boolean(editItems.length) && !isAdd && (
            <IconButton
              className="mu-mr-100"
              onClick={onCancel}
              theme="danger"
              variant="bordered"
              size="s"
            >
              <ControlCross24 />
            </IconButton>
          )}
          <IconButton
            isDisabled={!selectedRowsId.length || isAdd || Boolean(editItems.length)}
            theme="danger"
            variant="bordered"
            size="s"
            onClick={deleteExtDataItems}
          >
            <PublishTrashbin24 />
          </IconButton>
        </Flex>
      </Flex>
      {filters.length > 0 && (
        <>
          <hr />
          <View marginBottom="mu100" marginTop="mu100">
            <Flex justifyContent="space-between" alignItems="center">
              <View className="mu-mr-100">
                <Tags filters={filters} onRemoveTag={onRemoveTags} />
              </View>
              <View onClick={resetFilters} className="mu-pl-100">
                <Text className={styles.control_clearTags} as="span">
                  {translations('clear')}
                </Text>
              </View>
            </Flex>
          </View>
        </>
      )}
      <CbdTable<AlertAttributeColumns, ISubscription>
        rows={tableData}
        isLoading={false}
        paginationVisible
        {...table}
        pagination={pagination}
      />
    </View>
  );
};

export default AlertAttribute;
