import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';
import { IFilter } from 'common/types';
import { UploadsFilterName } from 'pages/cbd/Uploads/Uploads.types';

interface SliceState {
  filters: IFilter<UploadsFilterName>[];
  deletedFilters: UploadsFilterName[];
}

export const filtersSlice = createSlice({
  initialState: {
    filters: [],
    deletedFilters: [],
  },
  name: 'cbdUploadsFilters',
  reducers: {
    clearUploadsDeletedFilters(state) {
      state.deletedFilters = [];
    },
    changeUploadsDeletedFilters(state, action) {
      state.deletedFilters = state.deletedFilters.concat(action.payload);
    },
    setUploadsFilters(state, action) {
      state.filters = action.payload;
    },
  },
});

export const { setUploadsFilters, changeUploadsDeletedFilters, clearUploadsDeletedFilters } =
  filtersSlice.actions;

export const useUploadsFiltersData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.cbd.uploads.filters) };
};
