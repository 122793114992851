import { combineReducers } from '@reduxjs/toolkit';
import { currentPriceListSlice } from './currentPriceList';
import { priceListItemsSlice } from './priceListItems';
import { priceListSlice } from './priceList';
import { validationSlice } from './validation';
import { possibilityCreationSlice } from './possibility/creation';

export const priceList = combineReducers({
  priceListItems: priceListItemsSlice.reducer,
  currentPriceList: currentPriceListSlice.reducer,
  priceList: priceListSlice.reducer,
  validation: validationSlice.reducer,
  possibility: combineReducers({
    creation: possibilityCreationSlice.reducer,
  }),
});
