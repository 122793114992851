// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { forwardRef, InputHTMLAttributes, useCallback, useEffect, useState } from 'react';
import {
  ListBox,
  ListBoxItem,
  TextInput,
  TListBoxMode,
  useListBoxValues,
  TTextInputSize,
  View,
} from '@mozaic-ds/react';
import { SelectItem } from 'common/types';
import { DropdownProps } from './types';

type Props = {
  value: any;
  mode: TListBoxMode;
  size?: TTextInputSize;
} & DropdownProps &
  Pick<InputHTMLAttributes<HTMLInputElement>, 'onFocus'>;

const Dropdown = forwardRef(
  (
    {
      mode,
      value,
      placeholder,
      listBoxItems,
      isDisabled = false,
      className,
      onChange,
      onFocus,
      size = 'm',
    }: Props,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const isMulti = mode === 'multi';
    const [focusValue, setFocus] = useState(false);
    const [values, { toggle, add, clear }] = useListBoxValues([]);

    useEffect(() => {
      if (value.length > 0 && values.length === 0) {
        value.map((item: SelectItem) => toggle(item.value.toString()));
      }
    }, [toggle, value, value.length, values.length]);
    useEffect(() => {
      if (isMulti && value.length === 0) {
        clear();
      }
    }, [clear, isMulti, value]);

    const handleMultiClick = useCallback(
      (listBoxValue?: string) => {
        if (!listBoxValue) return;
        toggle(listBoxValue);
        if (values.includes(listBoxValue)) {
          onChange(
            listBoxItems.filter((item) =>
              values.filter((listBox) => listBox !== listBoxValue).includes(item.value.toString())
            ) as any
          );
        } else {
          onChange(
            listBoxItems.filter((item) =>
              values.concat(listBoxValue).includes(item.value.toString())
            ) as any
          );
        }
      },
      [toggle, values, onChange, listBoxItems]
    );

    const handleClick = useCallback(
      (listBoxValue?: string) => {
        if (!listBoxValue) return;
        clear();
        add(listBoxValue);
        onChange(listBoxItems.find((item) => item.value.toString() === listBoxValue) as any);
      },
      [clear, add, onChange, listBoxItems]
    );

    return (
      <View
        className={className}
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={(e) => {
          if (
            e.relatedTarget?.id !== 'customListBox' &&
            (e.relatedTarget as HTMLInputElement)?.type !== 'checkbox'
          ) {
            setFocus(false);
          }
        }}
      >
        <TextInput
          ref={ref}
          value={value.map((item: any) => item.label).join(', ')}
          size={size}
          placeholder={placeholder}
          isDisabled={isDisabled}
          readOnly
        />
        {focusValue && (
          <ListBox
            id="customListBox"
            values={value.map((item: any) => item.value.toString())}
            mode={mode}
            onClick={isMulti ? handleMultiClick : handleClick}
          >
            {listBoxItems.map((item) => (
              <ListBoxItem key={item.value} value={item.value.toString()}>
                {item.label}
              </ListBoxItem>
            ))}
          </ListBox>
        )}
      </View>
    );
  }
);

export default Dropdown;
