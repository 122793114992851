import { getLocale, getTagValueForDate } from 'common/utils';
import { useCostTypes } from 'store/cbd/costTypes';
import { useScheduler } from 'store/cbd/scheduler/scheduler';
import { useRequestStatuses } from 'store/cbd/requestStatuses';
import { useAppTranslations } from 'translations';
import { extDataTypeOptions } from 'pages/cbd/Scheduler/partials/SchedulerForm/consts';
import { IFilter } from 'common/types';
import { useSupplierIdsData } from 'store/dictionary/supplierIds';
import useNomenclatureOptions from 'pages/cbd/hooks/useNomenclatureOptions';
import { ExtendsFilters, FILTERS_ID } from '../consts';

interface IGetAttributeChildren {
  index?: number;
}

interface IGetAttributeName extends IGetAttributeChildren {
  value: string;
}

const useFormattedFilterValue = (filters: IFilter<ExtendsFilters>[]) => {
  const translations = useAppTranslations();
  const { list: costTypes } = useCostTypes();
  const { data: sourceOptions } = useScheduler();
  const { list: requestStatuses } = useRequestStatuses();
  const { supplierIds } = useSupplierIdsData().data;
  const { groupsOptions, depOptions, classDataOptions, subClassOptions } = useNomenclatureOptions([
    filters.find((item) => item.id === FILTERS_ID.itemGroup)?.value.toString() || '',
    filters.find((item) => item.id === FILTERS_ID.itemDep)?.value.toString() || '',
    filters.find((item) => item.id === FILTERS_ID.itemClass)?.value.toString() || '',
  ]);

  const getAttributeDep = (depValue: string): string =>
    costTypes.find((item) => item.id.toString() === depValue)?.name || '';

  const attributeSubDepChildren = ({ index }: IGetAttributeChildren) => {
    let attributeDepValue = filters.find(
      (filterEl) => filterEl.id === FILTERS_ID.categoryId || filterEl.id === FILTERS_ID.attributeDep
    )?.value;

    if (index !== undefined) {
      attributeDepValue = attributeDepValue[index];
    }

    return costTypes.find((item) => item.id.toString() === attributeDepValue)?.children || [];
  };

  const getAttributeSubDep = ({ value: subDepValue, index }: IGetAttributeName): string =>
    attributeSubDepChildren({ index }).find((item) => item.id.toString() === subDepValue)?.name ||
    '';

  const attributeGroupChildren = ({ index }: IGetAttributeChildren) => {
    let attributeSubDepValue = filters.find(
      (filterEl) => filterEl.id === FILTERS_ID.typeId || filterEl.id === FILTERS_ID.attributeSubDep
    )?.value;

    if (index !== undefined) {
      attributeSubDepValue = attributeSubDepValue[index];
    }

    return (
      attributeSubDepChildren({
        index,
      }).find((item) => item.id.toString() === attributeSubDepValue)?.children || []
    );
  };

  const getAttributeGroup = ({ value: groupValue, index }: IGetAttributeName): string =>
    attributeGroupChildren({ index }).find((item) => item.id.toString() === groupValue)?.name || '';

  const formattedFilterValue = (filterValue: IFilter<ExtendsFilters>, lang: string): string => {
    const { id, value, label } = filterValue;

    switch (id) {
      case FILTERS_ID.cbdDate:
      case FILTERS_ID.cbdCreatedAt:
      case FILTERS_ID.externalSourceDate:
        return getTagValueForDate(filterValue, getLocale(lang));

      case FILTERS_ID.itemGroup:
        return `${label}: ${
          groupsOptions.find((item) => item.value === value)?.label.toLowerCase() || ''
        }`;

      case FILTERS_ID.itemDep:
        return `${label}: ${
          depOptions.find((item) => item.value === value)?.label.toLowerCase() || ''
        }`;

      case FILTERS_ID.itemClass:
        return `${label}: ${
          classDataOptions.find((item) => item.value === value)?.label.toLowerCase() || ''
        }`;

      case FILTERS_ID.itemSubclass:
        return `${label}: ${
          subClassOptions.find((item) => item.value === value)?.label.toLowerCase() || ''
        }`;

      case FILTERS_ID.attributeDep:
      case FILTERS_ID.categoryId:
        return `${label}: ${
          Array.isArray(value)
            ? value.reduce((prev, curr) => {
                const categoryName = getAttributeDep(curr);

                if (categoryName) {
                  return prev ? `${prev}, ${categoryName}` : categoryName;
                }

                return prev;
              }, '')
            : getAttributeDep(value)
        }`;

      case FILTERS_ID.attributeSubDep:
      case FILTERS_ID.typeId:
        return `${label}: ${
          Array.isArray(value)
            ? value.reduce((prev, curr, index) => {
                const typeName = getAttributeSubDep({
                  value: curr,
                  index,
                });

                if (typeName) {
                  return prev ? `${prev}, ${typeName}` : typeName;
                }

                return prev;
              }, '')
            : getAttributeSubDep({ value })
        }`;

      case FILTERS_ID.attributeGroup:
      case FILTERS_ID.subtypeId:
        return `${label}: ${
          Array.isArray(value)
            ? value.reduce((prev, curr, index) => {
                const subtypeName = getAttributeGroup({
                  value: curr,
                  index,
                });

                if (subtypeName) {
                  return prev ? `${prev}, ${subtypeName}` : subtypeName;
                }

                return prev;
              }, '')
            : getAttributeGroup({ value })
        }`;

      case FILTERS_ID.source:
        return `${label}: ${
          Array.isArray(value)
            ? value.reduce((prev: string, curr: string) => {
                const sourceName = sourceOptions.find((item) => `${item.id}` === curr)?.name || '';

                if (sourceName) {
                  return prev ? `${prev}, ${sourceName}` : sourceName;
                }

                return prev;
              }, '')
            : sourceOptions.find((item) => `${item.id}` === value)?.name || ''
        }`;

      case FILTERS_ID.requestStatusId:
        return `${label}: ${requestStatuses.find((item) => `${item.id}` === value)?.code || ''}`;

      case FILTERS_ID.region:
        return `${label}: ${typeof value === 'string' ? value : value.value}`;

      case FILTERS_ID.name:
        return `${label}: ${value[0]}`;

      case FILTERS_ID.extDataType:
        return `${label}: ${translations(
          extDataTypeOptions.find((item) => item.value === value)?.label || ''
        )}`;

      case FILTERS_ID.cbdStatus:
        return `${label}: ${translations(value)}`;

      case FILTERS_ID.cbdSupplierId:
        return `${label}: ${supplierIds.find((item) => item.value === value)?.label || ''}`;

      default:
        return `${label}: ${value}`;
    }
  };

  return { formattedFilterValue };
};

export default useFormattedFilterValue;
