import { IBindCbdNomenclature, NomenclatureState } from 'common/cbd/types';

type FormattedBindFormFields = {
  cbdId: string;
  body: IBindCbdNomenclature;
};

export const formatBindData = (
  params: NomenclatureState,
  cbdId?: string
): FormattedBindFormFields => {
  return {
    cbdId: cbdId || '',
    body: {
      groups: Number(params.dep),
      department: Number(params.subDep),
      class: Number(params.classData),
      subclass: Number(params.subClass),
      item: params.item,
    },
  };
};
