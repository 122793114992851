import { useMemo } from 'react';
import { getLocale, parseDate } from 'common/utils';
import { useUserData } from 'store/auth/user';
import { useSuppliers } from 'store/cbd/suppliers';
import { useAppTranslations } from 'translations';
import { useCbdInfo } from 'store/cbd/info';

interface IInfoConfig {
  header: string;
  text: string | number;
}

const useInfoConfig = () => {
  const translations = useAppTranslations();
  const { suppliers } = useSuppliers();
  const { lang } = useUserData().user;
  const { data } = useCbdInfo();

  const infoConfig: IInfoConfig[] = useMemo(
    () => [
      {
        header: translations('supplierName'),
        text: suppliers.find((item) => item.supplierId === data?.supplierId)?.name || '',
      },
      {
        header: translations('dateOfCalculation'),
        text: data?.dateCbd ? parseDate(new Date(data?.dateCbd), getLocale(lang)) : '',
      },
      {
        header: translations('department'),
        text: data?.division || '',
      },
      {
        header: translations('supplierIds'),
        text: data?.supplierId || '',
      },
      {
        header: translations('productGroup'),
        text: data?.group || '',
      },
      {
        header: translations('versionType'),
        text: data?.tpType || '',
      },
      {
        header: `${translations('country')} - ${translations('city')}`,
        text: `${data?.factoryCountry} - ${data?.factoryCity}`,
      },
      {
        header: translations('lmCode'),
        text: data?.item || '',
      },
    ],
    [data, lang, suppliers, translations]
  );

  return { infoConfig };
};

export default useInfoConfig;
