import { useMemo, useState } from 'react';
import { useCompareCommonData } from 'store/cbd/cbdAnalysis/compare/common';
import { chartViewTypes } from 'common/consts';
import { ChartType } from 'chart.js';
import { ChartProps } from 'react-chartjs-2';
import { useChartConfig } from 'components/Chart/hooks/useChartConfig';
import { useAppTranslations } from 'translations';
import { IChartData, getChartDataForConfig } from './helpers';

const useSwitchingChartData = (
  chartLabels: Array<string>,
  chartType: 'groups' | 'currency' | 'expenses'
) => {
  const translations = useAppTranslations();
  const { data } = useCompareCommonData();
  const [viewType, setViewType] = useState(chartViewTypes[0]);

  const chartData: IChartData = useMemo(
    () =>
      getChartDataForConfig({
        data,
        chartType,
        viewType,
        datasetLabels: chartLabels,
      }),
    [data, viewType, chartLabels, chartType]
  );

  const yAxisText =
    viewType === 'absolute' ? translations('rubles') : translations('dataInPercents');
  const chartConfig: ChartProps<ChartType> = useChartConfig({
    type: 'bar',
    legendPosition: 'bottom',
    axisTitle: [translations('cbdNumber'), yAxisText],
    chartData,
    needToShowLabels: false,
    needToShowLegend: false,
  });

  return {
    chartConfig,
    viewType,
    setViewType,
  };
};

export default useSwitchingChartData;
