import { ICostType } from 'api/cbd/externalSource';
import { SelectItem } from 'common/types';
import { useCostTypes } from 'store/cbd/costTypes';

const useCostTypesOptions = () => {
  const { list } = useCostTypes();

  const mapOptionValue = (items?: ICostType[]) =>
    items?.map((item) => ({
      value: item.id,
      label: item.name,
    })) || [];

  const categoryCostTypesOptions: SelectItem[] = mapOptionValue(list);

  const getTypeCostTypesOptions = (categoryId: string | number): SelectItem[] => {
    const categoryIndex = list.findIndex((item) => item.id === Number(categoryId));

    return mapOptionValue(list[categoryIndex]?.children);
  };

  const getSubtypeCostTypesOptions = (
    categoryId: string | number,
    typeId: string | number
  ): SelectItem[] => {
    const categoryIndex = list.findIndex((item) => item.id === Number(categoryId));
    const typeIndex =
      list[categoryIndex]?.children?.findIndex((item) => item.id === Number(typeId)) || -1;
    const categoryChildrenArray = list[categoryIndex]?.children || [];

    return mapOptionValue(categoryChildrenArray[typeIndex]?.children);
  };

  return {
    categoryCostTypesOptions,
    getTypeCostTypesOptions,
    getSubtypeCostTypesOptions,
  };
};

export default useCostTypesOptions;
