import { combineReducers } from '@reduxjs/toolkit';
import { columnsSlice } from './columns';
import { filtersSlice } from './filters';
import { subscriptionsListSlice } from './subscriptionsList';

export const subscriptions = combineReducers({
  list: subscriptionsListSlice.reducer,
  columns: columnsSlice.reducer,
  filters: filtersSlice.reducer,
});
