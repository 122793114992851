import React from 'react';
import { IconButton } from '@mozaic-ds/react';
import { ControlMore24, NotificationAvailable24, ControlCross24 } from '@mozaic-ds/icons/react';

type FnAddAttributeButton = {
  isAdding: boolean;
  isFormOpeningDisabled: boolean;
  openForm: () => void;
  closeForm: () => void;
  createAttribute: () => void;
};

const AddAttributeButton = ({
  isAdding,
  isFormOpeningDisabled,
  openForm,
  closeForm,
  createAttribute,
}: FnAddAttributeButton) => {
  if (!isAdding) {
    return (
      <IconButton
        className="mu-mr-100"
        onClick={openForm}
        theme="primary"
        isDisabled={isFormOpeningDisabled}
        variant="bordered"
        size="s"
      >
        <ControlMore24 />
      </IconButton>
    );
  }

  return (
    <>
      <IconButton
        className="mu-mr-100"
        onClick={createAttribute}
        theme="primary"
        variant="solid"
        size="s"
      >
        <NotificationAvailable24 />
      </IconButton>
      <IconButton
        className="mu-mr-100"
        onClick={closeForm}
        theme="danger"
        variant="bordered"
        size="s"
      >
        <ControlCross24 />
      </IconButton>
    </>
  );
};

export default AddAttributeButton;
