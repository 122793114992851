import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initPageable } from 'common/consts';
import { IGetCbdListPayload, IGetAllCbdListResponse } from 'common/cbd/types';
import { useAppSelector } from 'store/hooks';
import { getAllCbdList } from 'api/cbd/cbd';
import { setFailure, setLoading } from '../../helpers';
import { RequiredStateFields, RootState } from '../../types';

interface SliceState extends RequiredStateFields, IGetAllCbdListResponse {
  selectedRowsId: string[];
}

const initialState = {
  selectedRowsId: [],
  data: [],
  pageable: initPageable,
  isLoading: false,
  isFailure: false,
};

export const getAllCbdListData = createAsyncThunk(
  'cbd/getAllCbdListData',
  async (payload: IGetCbdListPayload, { rejectWithValue }) => {
    try {
      return await getAllCbdList(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const listSlice = createSlice({
  initialState,
  name: 'list',
  reducers: {
    setSelectedRowsId: (state, action) => {
      state.selectedRowsId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCbdListData.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getAllCbdListData.fulfilled,
        (state: SliceState, action: PayloadAction<IGetAllCbdListResponse>) => {
          state.data = action.payload.data;
          state.pageable = action.payload.pageable;
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getAllCbdListData.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const { setSelectedRowsId } = listSlice.actions;

export const useCbdList = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.allUploads.list);
};
