import { ICreateNewSchedulerFields } from 'api/cbd/scheduler';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';

export interface ISchedulerForm extends ICreateNewSchedulerFields {
  id: number;
  nameInput: string;
  nameSelect: string;
}

export const useSchedulerForm = () => {
  const form = useForm({
    criteriaMode: 'all',
    mode: 'all',
    defaultValues: {
      id: 0,
      nameInput: '',
      nameSelect: '',
      extDataType: '',
      source: '',
      destination: '',
      // хранение инструкций для парсера в формате json
      mapping: '',
      apiPeriodType: '',
      relationPeriodType: '',
      overwrite: false,
      overWriteKey: '',
      schedulerPeriodType: '',
      schedulerPeriod: 0,
      schedulerTime: '',
      schedulerStart: '',
      schedulerFinish: '',
      lastLaunch: '',
    },
    resolver: yupResolver(schema),
  });

  const { control, formState } = form;

  const { field: id } = useController({ name: 'id', control });
  const { field: nameInput } = useController({ name: 'nameInput', control });
  const { field: nameSelect } = useController({ name: 'nameSelect', control });
  const { field: extDataType } = useController({ name: 'extDataType', control });
  const { field: source } = useController({ name: 'source', control });
  const { field: destination } = useController({ name: 'destination', control });
  const { field: mapping } = useController({ name: 'mapping', control });
  const { field: apiPeriodType } = useController({ name: 'apiPeriodType', control });
  const { field: relationPeriodType } = useController({ name: 'relationPeriodType', control });
  const { field: overwrite } = useController({ name: 'overwrite', control });
  const { field: overWriteKey } = useController({ name: 'overWriteKey', control });
  const { field: schedulerPeriodType } = useController({ name: 'schedulerPeriodType', control });
  const { field: schedulerPeriod } = useController({ name: 'schedulerPeriod', control });
  const { field: schedulerTime } = useController({ name: 'schedulerTime', control });
  const { field: schedulerStart } = useController({ name: 'schedulerStart', control });
  const { field: schedulerFinish } = useController({ name: 'schedulerFinish', control });
  const { field: lastLaunch } = useController({ name: 'lastLaunch', control });

  return {
    form,
    formState,
    id,
    nameInput,
    nameSelect,
    extDataType,
    source,
    destination,
    mapping,
    apiPeriodType,
    relationPeriodType,
    overwrite,
    overWriteKey,
    schedulerPeriodType,
    schedulerPeriod,
    schedulerTime,
    schedulerStart,
    schedulerFinish,
    lastLaunch,
  };
};
