import { useCallback } from 'react';
import { useAppTranslations } from 'translations';
import { createColumns } from 'common/utils';
import { useAppDispatch } from 'store/hooks';

import { useForm } from 'react-hook-form';
import { setVisibleExternalSourceColumns } from 'store/cbd/externalData/analysis/columns';
import { IDataTableColumn } from 'common/types';
import { IExternalData } from 'api/cbd/externalSource';
import { IExternalSourceColumnSort } from '../ExternalSource.types';
import { ExternalSourceColumns } from './consts';
import { useGetColumnTemplate } from './helpers';

type ExternalSourceSortConfig = {
  [key in ExternalSourceColumns]: IExternalSourceColumnSort | null;
};

export const sortConfig: ExternalSourceSortConfig = {
  [ExternalSourceColumns.SOURCE]: 'source',
  [ExternalSourceColumns.CURRENCY_CODE]: 'currencyCode',
  [ExternalSourceColumns.CATEGORY]: 'category',
  [ExternalSourceColumns.TYPE]: 'type',
  [ExternalSourceColumns.SUBTYPE]: 'subtype',
  [ExternalSourceColumns.NAME]: 'name',
  [ExternalSourceColumns.NAME2]: 'name2',
  [ExternalSourceColumns.DATE]: 'date',
  [ExternalSourceColumns.VALUE]: 'value',
  [ExternalSourceColumns.REGION]: 'region',
  [ExternalSourceColumns.MEASURE]: 'measure',
  [ExternalSourceColumns.DIAMETER]: 'diameter',
  [ExternalSourceColumns.THICKNESS]: 'thickness',
  [ExternalSourceColumns.CONTRACT]: 'contract',
  [ExternalSourceColumns.STANDARD]: 'standard',
  [ExternalSourceColumns.GRADE]: 'grade',
  [ExternalSourceColumns.PRICE_TYPE_NAME]: 'priceTypeName',
};

export const useGetColumns = (): {
  columns: Record<ExternalSourceColumns, IDataTableColumn<IExternalData>>;
} => {
  const translations = useAppTranslations();

  const columnTemplate = useGetColumnTemplate();

  return {
    columns: createColumns<ExternalSourceColumns, IExternalData, IExternalSourceColumnSort>({
      columnTemplate,
      translations,
      sortConfig,
    }),
  };
};

export const useColumnsForm = () => {
  const dispatch = useAppDispatch();

  const form = useForm<Record<ExternalSourceColumns, boolean>>({
    defaultValues: {
      source: true,
      currencyCode: true,
      category: true,
      type: true,
      subtype: true,
      name: true,
      name2: true,
      date: true,
      value: true,
      region: true,
      measure: true,
      diameter: true,
      thickness: true,
      contract: true,
      standard: true,
      grade: true,
      priceTypeName: true,
    },
  });

  const saveColumnsFilter = useCallback(() => {
    form.handleSubmit((data) => {
      dispatch(setVisibleExternalSourceColumns(data));
    })();
  }, [dispatch, form]);

  const resetColumnsFilters = () => {
    form.reset();
    dispatch(setVisibleExternalSourceColumns(form.getValues()));
  };

  return { form, saveColumnsFilter, resetColumnsFilters };
};
