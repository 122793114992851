import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '@mozaic-ds/react';
import { ShareShareIOS24 } from '@mozaic-ds/icons/react';
import { useAppTranslations } from 'translations';
import { useGetGrants } from 'pages/Request/hooks';
import { RequestFormFields } from 'pages/Request/types';
import { useUpdateFormValues } from './hooks/hooks';

type UpdateButtonProps = { id: string };

export const UpdateButton = ({ id }: UpdateButtonProps) => {
  const translations = useAppTranslations();
  const { hasRequestGrants } = useGetGrants();
  const { updateFormData } = useUpdateFormValues(id);
  const form = useFormContext<RequestFormFields>();
  const { isDirty, isValid } = form.formState;

  const {
    RequestEditReasonList,
    RequestEditCustomReason,
    RequestEditDueDate,
    RequestEditSupplierId,
  } = hasRequestGrants;

  const isEditSomeField =
    RequestEditDueDate || RequestEditSupplierId || RequestEditReasonList || RequestEditCustomReason;

  if (!isEditSomeField) return null;

  return (
    <Button
      className="mu-mr-100"
      size="m"
      variant="bordered"
      width="fit"
      onClick={form.handleSubmit((data) => updateFormData(data))}
      isDisabled={!(isDirty && isValid)}
      leftIcon={<ShareShareIOS24 />}
    >
      {translations('shareUpdate')}
    </Button>
  );
};
