import React from 'react';
import { useAppTranslations } from 'translations';
import {
  Button,
  CheckBox,
  DateInput,
  Flex,
  Notification,
  Text,
  TextArea,
  useModals,
} from '@mozaic-ds/react';
import { InfoText } from 'pages/Request/partials/InfoText';
import { useReasonsData } from 'store/dictionary/reasons';
import { useGetReasonLabel } from 'hook/useGetReasonLabel';
import cn from 'classnames';
import styles from 'pages/CreatingRequest/CreatingRequest.module.scss';
import { Label } from 'components/Label/Label';
import { Steps } from 'pages/CreatingRequest/types';
import Modal from 'components/Modal/Modal';
import { useStepperContext } from 'components/Stepper/StepperContext';
import { useChatMessagesData } from 'store/chat/chat';
import { getTemplateInfo } from 'store/requestCreation/helpers';
import { RequestModelView } from '@ppm/ppm-platform-api';
import { confirmedModalId } from './consts';
import { useConfirmForm, useCreateRequestFinishFrom } from './hooks';

export const FinishRequestCreation: React.FC<{
  request: RequestModelView;
}> = ({ request }) => {
  const t = useAppTranslations();

  const { validatedStep } = useStepperContext<Steps>();
  const { open, close } = useModals();

  const { reasons } = useReasonsData().data;
  const { messages } = useChatMessagesData().chatMessages;

  const getReasonLabel = useGetReasonLabel(reasons);

  const { dueDate, comment, handleSubmit, submitForm, dateValidation, formState, isLoading } =
    useCreateRequestFinishFrom();
  const { min, max } = dateValidation;
  const { fields, confirmations, reset } = useConfirmForm();

  const { supplierName, supplierId, customReason } = request;

  const { supplierTemplatesHaveNoError } = getTemplateInfo(request);

  return (
    <>
      {!supplierTemplatesHaveNoError && (
        <Notification
          className="mu-mb-125"
          theme="danger"
          message={t('fillingSupplierTemplateError')}
        />
      )}
      <Text weight="semi-bold" theme="dark" as="p" className="mu-mb-150" size="xl">
        {t('requestCreationFormTitle')}
      </Text>
      <Flex justifyContent="space-between" marginBottom="mu250">
        <Flex direction="column" className={styles.basis}>
          <InfoText label="requestReasonLabel" value={getReasonLabel(request)} />
          {customReason && <InfoText label="otherReason" value={customReason} />}
          <InfoText label="department" value={`${supplierName} - ${supplierId}`} />
          <Label hasMarginTop={false} text={t('requestApplicationDateLabel')}>
            <DateInput {...dueDate} min={min} max={max} className="mu-mt-025" />
            <Text theme="danger" size="s">
              {formState.errors.dueDate?.message}
            </Text>
          </Label>
        </Flex>
        <Flex direction="column" className={styles.basis}>
          <Label text={t('requestCommentLabel')}>
            <TextArea
              {...comment}
              isDisabled={Boolean(messages.length)}
              className={cn(styles.textAria)}
              id="text-area"
              placeholder={t('commentPlaceholder')}
            />
          </Label>
        </Flex>
      </Flex>
      <hr />
      <Flex marginTop="mu250" justifyContent="space-between">
        <Button
          type="button"
          variant="bordered"
          className="mu-mr-100"
          theme="neutral"
          size="m"
          width="fit"
          onClick={() => validatedStep(Steps.cbd)}
        >
          {t('back')}
        </Button>
        <Button
          type="button"
          size="m"
          width="fit"
          isDisabled={!supplierTemplatesHaveNoError}
          onClick={() => open(confirmedModalId)}
        >
          {t('formRequest')}
        </Button>
      </Flex>
      <Modal
        id={confirmedModalId}
        isLoading={isLoading}
        handleOk={handleSubmit(async (values) => {
          await submitForm(values);
          reset();
        })}
        okBtnText={t('yes')}
        cancelBtnText={t('no')}
        handleClose={() => {
          reset();
          close(confirmedModalId);
        }}
        disabledButton={fields.some((field) => !field.value)}
      >
        {fields.map((field) => (
          <CheckBox
            key={field.name}
            {...field}
            isChecked={field.value}
            value={field.name}
            className="mu-mb-100"
          >
            {confirmations.get(field.name)}
          </CheckBox>
        ))}
      </Modal>
    </>
  );
};
