import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './allUploadsSubmitFormSchema';
import { IUploadTemplateCbdFormFields } from '../partials/UploadForm/types';

export const useEditFormAllUploads = () => {
  return useForm<IUploadTemplateCbdFormFields>({
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      templateCbdFiles: [],
    },
  });
};
