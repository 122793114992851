import React, { useEffect, useMemo } from 'react';
import {
  setSelectedRole as setRoleForSupplier,
  useSuppliersData,
} from 'store/dashboard/rightsManagement/supplier';
import {
  setSelectedRole as setRoleForNomenclature,
  useNomenclatureData,
} from 'store/dashboard/rightsManagement/nomenclature';
import { useAppDispatch } from 'store/hooks';
import { useTabsContext } from '@mozaic-ds/react/lib/components/Tabs/TabsContext';
import { union } from 'lodash';
import RoleTabs from 'components/RoleTabs/RoleTabs';

const UserLinksLayout = () => {
  const dispatch = useAppDispatch();
  const tabsContext = useTabsContext();
  const { checkedNomenclatureGroupedByRole } = useNomenclatureData().nomenclature;
  const { checkedSuppliersGroupedByRole } = useSuppliersData().supplier;

  const { rolesWithLinks } = useMemo(() => {
    const isExistSupplierLink = Boolean(checkedSuppliersGroupedByRole.length);
    const nomenclatureLinksRoleKeys = !checkedNomenclatureGroupedByRole
      ? []
      : Object.keys(checkedNomenclatureGroupedByRole).map(Number);
    const supplierLinkRoleKeys = checkedSuppliersGroupedByRole.map(({ roleId }) => roleId);

    if (!checkedNomenclatureGroupedByRole && !isExistSupplierLink) return { rolesWithLinks: [] };

    if (!checkedNomenclatureGroupedByRole && isExistSupplierLink)
      return { rolesWithLinks: supplierLinkRoleKeys };

    if (!isExistSupplierLink && checkedNomenclatureGroupedByRole)
      return { rolesWithLinks: nomenclatureLinksRoleKeys };

    return { rolesWithLinks: union(supplierLinkRoleKeys, nomenclatureLinksRoleKeys) };
  }, [checkedSuppliersGroupedByRole, checkedNomenclatureGroupedByRole]);

  useEffect(() => {
    if (tabsContext && tabsContext.tab) {
      dispatch(setRoleForSupplier(Number(tabsContext.tab)));
      dispatch(setRoleForNomenclature(Number(tabsContext.tab)));
    }
  }, [dispatch, tabsContext, tabsContext?.tab]);

  return React.cloneElement(<RoleTabs />, { rolesWithLinks });
};
export default UserLinksLayout;
