import { ChartDataset, ChartType } from 'chart.js/auto';
import { useCallback } from 'react';
import { ChartProps } from 'react-chartjs-2';
import { useAppTranslations } from 'translations';

interface IChartData {
  labels: string[];
  values: number[][];
  datasetLabels?: string[];
}

interface IUseChartConfig {
  type: ChartType;
  legendPosition: 'left' | 'right' | 'top' | 'bottom' | 'center' | 'chartArea';
  chartData: IChartData;
  needToShowLabels: boolean;
  needToShowLegend: boolean;
  axisTitle?: [string, string];
  title?: string;
  chartViewType?: string;
}

export const chartColors = ['#78BE20', '#5B737D', '#CDD4D8', '#035010'];

export const useChartConfig = ({
  type,
  title,
  legendPosition,
  chartData,
  needToShowLabels,
  needToShowLegend,
  axisTitle,
}: IUseChartConfig): ChartProps<ChartType, number[], unknown> => {
  const translations = useAppTranslations();

  const getColors = useCallback(
    (index) => {
      if (type === 'pie' || type === 'doughnut') {
        return chartColors;
      }

      return chartColors[index];
    },
    [type]
  );

  return {
    // Вид графика
    type,
    // Входящие данные по которым рисуется график
    data: {
      // Массив из названий который соответствует первому уровню datasets
      labels: chartData.labels,
      // Массив который отвечает за положение и отображение данных на графике
      datasets: chartData.values.reduce(
        (prev: ChartDataset<ChartType, number[]>[], curr, index) => {
          prev.push({
            // Применимо только к графику типа bar устанавливает его максимальную ширину
            maxBarThickness: 500,
            // Массив из значений самого элемента графика которые могут накладываться друг на друга
            data: curr.map((item) => item),
            borderColor: type === 'line' ? getColors(index) : undefined,
            backgroundColor: getColors(index),
            borderWidth: type === 'line' ? 3 : 1,
            // Массив из названий который соответствует data и подсвечивает имя элемента к которому относится каждое из значений
            label: chartData.datasetLabels
              ? translations(chartData.datasetLabels[index])
              : undefined,
          });

          return prev;
        },
        []
      ),
    },
    // Свойства для настройки и отображения графика
    options: {
      // Настройка осей
      scales: {
        x: {
          display: type !== 'pie' && type !== 'doughnut',
          stacked: type !== 'line',
          // Подпись оси Х
          title: {
            text: axisTitle ? axisTitle[0] : '',
            display: Boolean(axisTitle),
          },
        },
        y: {
          display: type !== 'pie' && type !== 'doughnut',
          stacked: type !== 'line',
          // Подпись рси У
          title: {
            text: axisTitle ? axisTitle[1] : '',
            display: Boolean(axisTitle),
          },
        },
      },
      // Настройка отображения
      plugins: {
        // Отображение описания значений на графике при наведении на них курсора
        datalabels: {
          display: needToShowLabels,
          color: '#FFFFFF',
          formatter(value: string) {
            if (Number(value) <= 0) {
              return '';
            }

            return `${value}%`;
          },
        },
        // Общее название графика
        title: {
          display: true,
          text: title,
        },
        // Отображение легенды
        legend: {
          display: needToShowLegend,
          position: legendPosition || 'bottom',
          labels: {
            generateLabels: (chart) => {
              const {
                data: { datasets, labels },
              } = chart;

              if (datasets.length === 1 && labels) {
                return labels.map((item, index) => ({
                  text: `${item} ${datasets[0].data[index]}%`,
                  fillStyle: chartColors[index],
                  strokeStyle: chartColors[index],
                }));
              }

              return (
                labels?.map((item, index) => ({
                  text: item?.toString() || '',
                  fillStyle: chartColors[index],
                  strokeStyle: chartColors[index],
                })) || []
              );
            },
          },
        },
      },
    },
  };
};
