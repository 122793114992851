import {
  ICbd,
  ICbdItemGroup,
  ICbdLoad,
  ICbdWithArrayItemGroups,
  IGetCbdListPayload,
  IPageable,
} from 'common/cbd/types';
import { IFilter } from 'common/types';
import useDebounce from 'hook/useDebounce';
import usePagination from 'hook/usePagination';
import { ICbdTable, IGetCbdListQuery } from 'pages/cbd/AllUploads/AllUploads.types';
import { ICompareTable } from 'pages/cbd/CompareTable/CompareTable.types';
import { cbdTypeValues, useCbdAnalysisInfoInfoData } from 'store/cbd/cbdAnalysis/cbdAnalysisInfo';
import { IUploadTable } from 'pages/cbd/Uploads/Uploads.types';
import { useCallback, useEffect, useState } from 'react';
import { mapAllUploads, mapCompareData, mapUploads } from '../utils';
import { ExtendsMainData, ExtendsSortedField, FILTERS_ID } from '../partials/consts';

export interface ITableData<Data> {
  data: Data[];
  getData: (params: IGetCbdListPayload) => void;
  pageable: IPageable;
}

interface IUseTableData<Data> extends ITableData<Data> {
  compareFilterVisible?: boolean;
  filtersVisible?: boolean;
  uploadsFiltersVisible?: boolean;
  uploadFormVisible?: boolean;
  toggleVisible?: boolean;
  nomenclatureVisible?: boolean;
  filters?: IFilter<string>[];
  haveError?: boolean;
  id?: string;
  sortedField?: ExtendsSortedField;
}

export const useTableData = <Data extends ExtendsMainData>({
  data,
  getData,
  filtersVisible,
  uploadsFiltersVisible,
  compareFilterVisible,
  haveError,
  id,
  filters = [],
  sortedField,
  pageable,
}: IUseTableData<Data>) => {
  const debounceId = useDebounce(id, 1000);
  const { cbdType } = useCbdAnalysisInfoInfoData();
  const { data: cbdInfoData } = useCbdAnalysisInfoInfoData();
  const [tableData, setTableData] = useState<
    ICbdTable[] | IUploadTable[] | ICbdItemGroup[] | ICompareTable[]
  >([]);
  const {
    paginationProps,
    size,
    onPageChange,
    onNext,
    onPrevious,
    onSizeChange,
    getPaginationInfo,
  } = usePagination(pageable);

  const updateTableData = useCallback(() => {
    const queryParams: IGetCbdListQuery = filters.reduce((prev, curr) => {
      if (curr.id === FILTERS_ID.cbdDate && (filtersVisible || compareFilterVisible)) {
        const [cbdDateFrom, cbdDateTo] = curr.value;

        return {
          ...prev,
          cbdDateFrom,
          cbdDateTo,
        };
      }

      if (curr.id === FILTERS_ID.externalSourceDate) {
        const [dateFrom, dateTo] = curr.value;

        return {
          ...prev,
          dateFrom,
          dateTo,
        };
      }

      if (curr.id === FILTERS_ID.cbdCreatedAt && filtersVisible) {
        const [cbdCreatedAtFrom, cbdCreatedAtTo] = curr.value;

        return {
          ...prev,
          cbdCreatedAtFrom,
          cbdCreatedAtTo,
        };
      }

      if (curr.value) {
        return {
          ...prev,
          [curr.id]: curr.value?.value ? curr.value?.value : curr.value,
        };
      }

      return prev;
    }, {});

    if (haveError) {
      queryParams.haveError = !haveError;
    }

    if (filtersVisible && debounceId) {
      queryParams.loadReqId = debounceId;
    }

    if (uploadsFiltersVisible && debounceId) {
      queryParams.cbdId = debounceId;
    }

    if (sortedField) {
      queryParams.sortDirection = sortedField.sortDirection?.toUpperCase();
      queryParams.sortValue = sortedField.field;
    }

    if (compareFilterVisible && cbdType === cbdTypeValues[1] && cbdInfoData) {
      queryParams.cbdSupplierDepartmentId = [cbdInfoData.supplierId];
    }

    if (compareFilterVisible && cbdType === cbdTypeValues[0] && cbdInfoData) {
      queryParams.cbdNotSupplierDepartmentId = [cbdInfoData.supplierId];
    }

    if (compareFilterVisible && cbdInfoData) {
      queryParams.cbdNotId = [cbdInfoData.id];
    }

    getData({ page: paginationProps.currentPage, size, ...queryParams });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paginationProps.currentPage,
    size,
    haveError,
    filtersVisible,
    debounceId,
    uploadsFiltersVisible,
    sortedField,
    filters,
    cbdType,
    cbdInfoData,
    compareFilterVisible,
  ]);

  useEffect(() => {
    updateTableData();
  }, [updateTableData]);

  const mapTableData = useCallback(
    (oldData: Data[]) => {
      let newData: ICbdTable[] | IUploadTable[] | ICbdItemGroup[] | ICompareTable[] = [];

      if (filtersVisible) {
        newData = mapAllUploads(oldData as ICbd[]);
      }

      if (compareFilterVisible) {
        newData = mapCompareData(oldData as ICbdWithArrayItemGroups[]);
      }

      if (uploadsFiltersVisible) {
        newData = mapUploads(oldData as ICbdLoad[]);
      }

      if (!uploadsFiltersVisible && !filtersVisible && !compareFilterVisible) {
        newData = oldData as ICbdItemGroup[];
      }

      setTableData(newData);
    },
    [compareFilterVisible, filtersVisible, uploadsFiltersVisible]
  );

  useEffect(() => {
    mapTableData(data);
  }, [data, mapTableData]);

  return {
    tableData,
    updateTableData,
    pagination: {
      paginationProps,
      size,
      onPageChange,
      onNext,
      onPrevious,
      getPaginationInfo,
      onSizeChange,
    },
  };
};
