import React from 'react';
import { RightsManagement, BuyerSiteGroupContainer } from './pannels';

type Tabs = {
  name: string;
  component: React.ElementType;
  isInitialTab: boolean;
  message: string;
};

export const BSG_TAB_NAME = 'buyerSiteGroup';

export const tabs: Tabs[] = [
  {
    name: 'rightsManagement',
    component: RightsManagement,
    message: 'rightsManagementTab',
    isInitialTab: true,
  },
  {
    name: BSG_TAB_NAME,
    component: BuyerSiteGroupContainer,
    message: 'buyerSiteGroupTab',
    isInitialTab: false,
  },
];
