import React, { FC, createContext, useContext, useMemo } from 'react';

type NomenclatureContextProps = {
  isInterchangeable: boolean;
  isDisabledAccordion: boolean;
};

const NomenclatureContext = createContext<NomenclatureContextProps>({
  isInterchangeable: true,
  isDisabledAccordion: false,
});

export function useNomenclature(): NomenclatureContextProps {
  const context = useContext(NomenclatureContext);

  if (context === undefined) {
    throw new Error('useNomenclature must be used within the NomenclatureProvider');
  }

  return context;
}

type NomenclatureProviderProps = {
  initialProps: NomenclatureContextProps;
};

export const NomenclatureProvider: FC<NomenclatureProviderProps> = ({ children, initialProps }) => {
  const contextValue: NomenclatureContextProps = useMemo(() => {
    return { ...initialProps };
  }, [initialProps]);
  return (
    <NomenclatureContext.Provider value={contextValue}>{children}</NomenclatureContext.Provider>
  );
};
