import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSupplierIds } from 'store/dictionary/supplierIds';
import { concatSuppliersWithLinks } from 'store/dashboard/helpers';
import { setFailure, setLoading } from 'store/helpers';
import { RequiredStateFields, RootState } from 'store/types';
import { SupplierLinkType } from 'store/dashboard/rightsManagement/supplierLinks';
import { DEFAULT_UNAVAILABLE_ROLE } from 'store/dashboard/consts';
import { useAppSelector } from 'store/hooks';
import { formatSuppliers } from 'store/dictionary/helpers';
import { SelectItem } from 'common/types';

export type SupplierSliceData = SelectItem & { isChecked: boolean };

export interface SliceState extends RequiredStateFields {
  data: SupplierSliceData[];
  checkedFields: SupplierSliceData[];
  checkedSuppliersGroupedByRole: SupplierLinkType[];
  selectedRole: number;
}

export const supplierSlice = createSlice({
  initialState: {
    data: [],
    checkedSuppliersGroupedByRole: [],
    checkedFields: [],
    isLoading: false,
    isFailure: false,
    selectedRole: DEFAULT_UNAVAILABLE_ROLE,
  },
  name: 'supplier',
  reducers: {
    setIsChecked(
      state: SliceState,
      action: PayloadAction<{
        selectedItem: SupplierSliceData;
        value: boolean;
      }>
    ) {
      const { value, selectedItem } = action.payload;

      state.checkedFields = value
        ? state.checkedFields.concat({ ...selectedItem, isChecked: true })
        : state.checkedFields.filter((checkedField) => checkedField.value !== selectedItem.value);

      state.checkedSuppliersGroupedByRole = value
        ? state.checkedSuppliersGroupedByRole.concat([
            {
              supplierId: selectedItem.value,
              roleId: state.selectedRole,
              name: selectedItem.label,
            },
          ])
        : state.checkedSuppliersGroupedByRole.filter(
            ({ supplierId, roleId }) =>
              supplierId !== selectedItem.value || roleId !== state.selectedRole
          );
      state.data = concatSuppliersWithLinks(
        state.data.map((item) => {
          if (item.value === selectedItem.value) return { ...item, isChecked: value };
          return { ...item };
        }),
        state
      );
    },
    clearAll(state: SliceState, action: PayloadAction<{ isClearRole: boolean }>) {
      state.checkedFields = [];
      state.checkedSuppliersGroupedByRole = [];
      state.data = state.data.map((item) => ({
        ...item,
        isChecked: false,
      }));
      state.selectedRole = action.payload.isClearRole
        ? DEFAULT_UNAVAILABLE_ROLE
        : state.selectedRole;
    },
    setSelectedRole(state: SliceState, action: PayloadAction<number>) {
      const data = state.data.map(({ label, value, isChecked }) => ({
        label,
        value,
        isChecked: state.checkedSuppliersGroupedByRole.find(
          ({ roleId, supplierId }) => roleId === state.selectedRole && supplierId === value
        )
          ? false
          : isChecked,
      }));
      state.selectedRole = action.payload;
      state.data = concatSuppliersWithLinks(data, state);
    },
    resetToDefaultValue(state: SliceState, action: PayloadAction<{ links: SupplierLinkType[] }>) {
      state.checkedFields = [];
      state.checkedSuppliersGroupedByRole = action.payload.links;
      state.data = concatSuppliersWithLinks(state.data, state);
    },
    matchingWithLinks(state: SliceState, action: PayloadAction<{ links: SupplierLinkType[] }>) {
      state.checkedSuppliersGroupedByRole = action.payload.links;
      state.data = concatSuppliersWithLinks(state.data, state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSupplierIds.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(getSupplierIds.fulfilled, (state: SliceState, action) => {
        const { meta, payload } = action;
        const formedData = formatSuppliers(payload.data).map((item) => ({
          ...item,
          isChecked: false,
        }));
        const data = Number(meta.arg?.offset) > 0 ? state.data.concat(formedData) : formedData;
        state.data = Number(meta.arg?.offset) > 0 ? data : concatSuppliersWithLinks(data, state);
        state.isLoading = false;
        state.isFailure = false;
      })
      .addCase(getSupplierIds.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const { setIsChecked, clearAll, matchingWithLinks, resetToDefaultValue, setSelectedRole } =
  supplierSlice.actions;

export const useSuppliersData = (): { supplier: SliceState } => {
  return {
    supplier: useAppSelector((state: RootState) => state.dashboard.supplier),
  };
};
