import React, { FC } from 'react';
import { Loader } from '@mozaic-ds/react';
import { RequestGateway } from '@ppm/ppm-platform-api';
import { SocketContextProvider } from '../../components/SocketContainer/SocketContext';
import { useRequestCreationData } from '../../store/requestCreation/requestCreation';

export const SocketWrapper: FC = ({ children }) => {
  const { request, isLoading: isLoadingRequestCreation } = useRequestCreationData().data;

  if (!request || isLoadingRequestCreation) return <Loader />;

  return (
    <SocketContextProvider
      id={request.id}
      namespace={RequestGateway.namespace}
      keyParam="requestId"
      path={RequestGateway.path}
    >
      {children}
    </SocketContextProvider>
  );
};
