import { combineReducers } from '@reduxjs/toolkit';
import { schedulerSlice } from './scheduler';
import { subscriptions } from './subscriptions';
import { currencySubscriptions } from './currencySubscriptions';

export const scheduler = combineReducers({
  subscriptions,
  currencySubscriptions,
  main: schedulerSlice.reducer,
});
