import React, { useEffect } from 'react';
import { Flex, View, Text } from '@mozaic-ds/react';
import Suppliers from 'pages/AdminDashboard/pannels/RightsManagement/partials/AccordionsView/Suppliers/Suppliers';
import Nomenclature from 'pages/AdminDashboard/pannels/RightsManagement/partials/AccordionsView/Nomenclature/Nomenclature';
import { useAppDispatch } from 'store/hooks';
import { useSupplierLink } from 'store/dashboard/rightsManagement/supplierLinks';
import {
  resetNomenclatureStoreToDefaultValue,
  useNomenclatureData,
} from 'store/dashboard/rightsManagement/nomenclature';
import {
  matchingWithLinks as matchingSuppliersWithLinks,
  useSuppliersData,
} from 'store/dashboard/rightsManagement/supplier';
import { useNomenclatureLink } from 'store/dashboard/rightsManagement/nomenclatureLinks';
import { useAppTranslations } from 'translations';
import CheckboxManagement from 'pages/AdminDashboard/pannels/RightsManagement/partials/AccordionsView/CheckboxManagement/CheckboxManagement';
import RoleTabsWrap from 'components/RoleTabs/RoleTabsWrap';
import UserLinksLayout from 'components/RoleTabs/compose/UserLinksLayout';
import styles from './Rights.module.scss';

const Rights = () => {
  const translations = useAppTranslations();
  const dispatch = useAppDispatch();
  const { data: supplierLink } = useSupplierLink().supplierLink;
  const { data: nomenclatureLink } = useNomenclatureLink().nomenclatureLink;
  const { checkedNomenclatureGroupedByRole } = useNomenclatureData().nomenclature;
  const { checkedSuppliersGroupedByRole } = useSuppliersData().supplier;

  useEffect(() => {
    dispatch(resetNomenclatureStoreToDefaultValue({ links: nomenclatureLink }));
    dispatch(matchingSuppliersWithLinks({ links: supplierLink }));
  }, [dispatch, nomenclatureLink, supplierLink]);

  return (
    <Flex direction="column" className={styles.container}>
      <Text weight="semi-bold" className="mu-mb-075">
        {translations('rightsHeader')}
      </Text>
      <View className={styles.block} shadow="s" radius="l">
        <RoleTabsWrap>
          <UserLinksLayout />
        </RoleTabsWrap>
        <CheckboxManagement
          selectedSuppliersLinks={checkedSuppliersGroupedByRole}
          selectedNomenclatureLinks={checkedNomenclatureGroupedByRole}
          className="mu-pl-250 mu-pr-250 mu-pt-125"
        />
        <View marginTop="mu050" paddingBottom="mu200" paddingRight="mu200" paddingLeft="mu200">
          <Suppliers isInterchangeable={false} />
          <Nomenclature isInterchangeable={false} />
        </View>
      </View>
    </Flex>
  );
};
export default Rights;
