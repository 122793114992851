import { useMemo } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useCurrencyRate } from 'store/cbd/externalData/currencyRate';

interface IDefaultValues {
  checked: number[];
}

const useExchangeRatesPopupForm = () => {
  const { visibleCurrencies } = useCurrencyRate();

  const defaultValues: IDefaultValues = useMemo(
    () => ({
      checked: visibleCurrencies,
    }),
    [visibleCurrencies]
  );

  const form = useForm({
    defaultValues,
  });

  const { control } = form;

  const { field: checked } = useController({ name: 'checked', control });

  return { form, checked };
};

export default useExchangeRatesPopupForm;
