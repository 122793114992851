import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRequests as getRequestsFromBackend } from 'api/request';
import { GetRequestListQuery, GetRequestListResponse } from '@ppm/ppm-platform-api';
import { DOWNLOAD_LIMIT_REQUESTS } from 'pages/Requests/Requests.const';
import { setFailure, setLoading } from '../helpers';
import { RequiredStateFields, RootState } from '../types';
import { useAppSelector } from '../hooks';

type RequestsSliceData = GetRequestListResponse['data'];

interface SliceState extends RequiredStateFields {
  requests: RequestsSliceData;
  isLastPage: boolean;
  pageNumber: number;
}

export const getRequests = createAsyncThunk<
  GetRequestListResponse,
  GetRequestListQuery | undefined
>('requests/getRequests', async (queryParams, { rejectWithValue }) => {
  try {
    return await getRequestsFromBackend({ conf: { params: queryParams } });
  } catch (e) {
    return rejectWithValue(e);
  }
});

const initialState = {
  requests: [],
  isLoading: false,
  isFailure: false,
  isLastPage: false,
  pageNumber: 1,
};

export const requestsSlice = createSlice({
  initialState,
  name: 'requests',
  reducers: {
    setRequestsStore(state, action) {
      state.requests = action.payload;
    },
    setPageNumber(state, action) {
      state.pageNumber = action.payload;
    },
    resetStore() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRequests.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(getRequests.fulfilled, (state: SliceState, action) => {
        state.isLastPage = action.payload.data.length !== DOWNLOAD_LIMIT_REQUESTS;

        state.requests = [...state.requests, ...action.payload.data];

        state.isFailure = false;
        state.isLoading = false;
      })
      .addCase(getRequests.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const { setRequestsStore, setPageNumber, resetStore } = requestsSlice.actions;

export const useRequestsData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.requests.requests) };
};
