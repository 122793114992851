import { IsAuthResponse } from '@ppm/ppm-platform-api';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAuth } from 'api/auth';
import { setFailure, setLoading } from '../helpers';
import { RequiredStateFields, RootState } from '../types';
import { useAppSelector } from '../hooks';

interface SliceState extends RequiredStateFields {
  isAccess: boolean;
}

export const getAuthData = createAsyncThunk<IsAuthResponse>(
  'access/getAuthData',
  async (_, { rejectWithValue }) => {
    try {
      return await getAuth({});
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const accessSlice = createSlice({
  initialState: {
    isAccess: false,
    isLoading: false,
    isFailure: false,
  },
  name: 'access',
  reducers: {
    expiredAccess(state) {
      state.isAccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuthData.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getAuthData.fulfilled,
        (state: SliceState, action: PayloadAction<IsAuthResponse>) => {
          state.isAccess = action.payload.isAccess;
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getAuthData.rejected, (state: SliceState) => {
        state.isAccess = false;
        setFailure(state);
      });
  },
});

export const { expiredAccess } = accessSlice.actions;

export const useAccessData = (): { data: SliceState } => {
  return { data: useAppSelector((state: RootState) => state.auth.access) };
};
