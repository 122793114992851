import { ChartType } from 'chart.js';
import { useChartConfig } from 'components/Chart/hooks/useChartConfig';
import { useMemo } from 'react';
import { ChartProps } from 'react-chartjs-2';
import { useExtChartData } from 'store/cbd/externalData/analysis/chartData';
import { useAppTranslations } from 'translations';
import { IExternalChartData } from 'api/cbd/externalSource';

const useChartData = () => {
  const translations = useAppTranslations();
  const { data } = useExtChartData();
  const datasetLabels = useMemo(() => data.map((item) => item.name), [data]);

  const chartData = useMemo(
    () => ({
      labels: data[0]?.data.map((item) => item.date) || [],
      values: data.reduce((acc: number[][], curr: IExternalChartData) => {
        acc.push(
          curr.data.map((item) =>
            item.valueGroup === null ? item.valueGroup : Number(item.valueGroup)
          )
        );
        return acc;
      }, []),
      datasetLabels,
    }),
    [data, datasetLabels]
  );

  const chartConfig: ChartProps<ChartType> = useChartConfig({
    type: 'line',
    legendPosition: 'bottom',
    axisTitle: [translations('date'), translations('percentValue')],
    chartData,
    needToShowLabels: false,
    needToShowLegend: false,
  });

  return { chartConfig, datasetLabels };
};

export default useChartData;
