import * as yup from 'yup';

export const schema = yup
  .object()
  .shape({
    replacementLdap: yup
      .object()
      .when(['requestIds', 'endDate'], {
        is: (requestIds: string[], endDate: string) =>
          Boolean(requestIds.length) || Boolean(endDate),
        then: (shm) => shm.required('Поле обязательно для заполения'),
      })
      .nullable(),
    requestIds: yup.array(),
    endDate: yup.string(),
    areFullRequests: yup.boolean(),
  })
  .required();
