import { combineReducers } from '@reduxjs/toolkit';
import { columnsSlice } from './columns';
import { currencySubscriptionsListSlice } from './currencySubscriptionsList';
import { filtersSlice } from './filters';

export const currencySubscriptions = combineReducers({
  list: currencySubscriptionsListSlice.reducer,
  columns: columnsSlice.reducer,
  filters: filtersSlice.reducer,
});
