import { useCallback } from 'react';
import { getStructure, useStructureList } from 'store/cbd/templateStructure/list';
import { useAppDispatch } from 'store/hooks';
import usePagination from 'hook/usePagination';
import { useFiltersData } from 'store/cbd/templateStructure/filters';
import { useColumnsData } from 'store/cbd/templateStructure/columns';
import { IGetTemplateStructureQuery } from '../ParseTable.types';

export const useTableData = () => {
  const { pageable } = useStructureList();
  const dispatch = useAppDispatch();
  const {
    data: { filters },
  } = useFiltersData();
  const {
    paginationProps,
    size,
    onPageChange,
    onNext,
    onPrevious,
    onSizeChange,
    getPaginationInfo,
  } = usePagination(pageable);
  const { sortedField } = useColumnsData().data;

  const updateTableData = useCallback(() => {
    const queryParams: IGetTemplateStructureQuery = filters.reduce(
      (prev: IGetTemplateStructureQuery, curr) => {
        return {
          ...prev,
          [curr.id]: curr.value,
        };
      },
      {}
    );

    dispatch(getStructure(queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationProps.currentPage, size, filters, sortedField]);

  return {
    updateTableData,
    paginationProps,
    size,
    onPageChange,
    onNext,
    onPrevious,
    onSizeChange,
    getPaginationInfo,
  };
};
