import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getMeasureUnits } from 'api/cbd/selects';
import { IGetSelectsResponse } from 'common/cbd/types';
import { StringSelectItem } from 'common/types';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

interface SliceState {
  list: StringSelectItem[];
}

const initialState: SliceState = {
  list: [],
};

export const getMeasureUnitsList = createAsyncThunk(
  'measureUnits/getMeasureUnitsList',
  async (_, { rejectWithValue }) => {
    try {
      return await getMeasureUnits();
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const measureUnitsSlice = createSlice({
  initialState,
  name: 'measureUnits',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getMeasureUnitsList.fulfilled,
      (state: SliceState, action: PayloadAction<IGetSelectsResponse>) => {
        state.list = action.payload.data;
      }
    );
  },
});

export const useMeasureUnits = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.measureUnits);
};
