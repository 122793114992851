import React, { useCallback } from 'react';
import { Text, Badge } from '@mozaic-ds/react';
import { BusinessTag, RequestSearchableFields } from '@ppm/ppm-platform-request-service';
import { useReasonsData } from 'store/dictionary/reasons';
import { useAppTranslations } from 'translations';
import { useGetReasonLabel } from 'hook/useGetReasonLabel';
import { setSortable, setVisibleColumns } from 'store/requests/columns';
import { getRequestStatusBadgeTheme, getLocale, parseDate } from 'common/utils';
import styles from 'pages/Requests/Requests.module.scss';
import { getDaysToResponse } from 'pages/Requests/utils';
import { DataTableColumn, TRequest } from 'pages/Requests/types';
import { useUserData } from 'store/auth/user';
import { useAppDispatch } from 'store/hooks';
import { useForm } from 'react-hook-form';
import { COLUMNS } from 'pages/Requests/hooks/consts';
import { SortDirection } from 'common/types';
import { PriceDownTag } from 'components/PriceDownTag/PriceDownTag';

export const useGetColumns = (): { columns: Record<COLUMNS, DataTableColumn<TRequest>> } => {
  const dispatch = useAppDispatch();
  const { reasons } = useReasonsData().data;
  const { lang } = useUserData().user;
  const translations = useAppTranslations();

  const getReasonLabel = useGetReasonLabel(reasons);

  const handleSort = (field: RequestSearchableFields, sortDirection: SortDirection) => {
    dispatch(setSortable({ field, sortDirection }));
  };

  return {
    columns: {
      id: {
        label: translations('requestId'),
        isSortable: true,
        render: (record: TRequest) => {
          return (
            <>
              {record.id}
              {record.tags && record.tags.includes(BusinessTag.Decrease) && <PriceDownTag />}
            </>
          );
        },
        onSort: (sortDirection: SortDirection) => {
          handleSort('id', sortDirection);
        },
      },
      supplierId: {
        label: translations('departmentNumber'),
        isSortable: true,
        render: (record: TRequest) => record.supplierId,
        onSort: (sortDirection: SortDirection) => handleSort('supplierId', sortDirection),
      },
      supplierName: {
        label: translations('supplierName'),
        render: (record: TRequest) => {
          const { supplierName } = record;

          return (
            <Text as="p" size="s">
              {supplierName || ''}
            </Text>
          );
        },
      },
      reasonListId: {
        label: translations('reason'),
        isSortable: true,
        render: (record: TRequest) => {
          const { customReason } = record;
          const reason: string = getReasonLabel(record);
          return (
            <Text as="p" size="s" className={styles.reasonWrapper}>
              {reason}
              {reason && customReason && `, ${customReason}`}
              {!reason && customReason && customReason}
            </Text>
          );
        },
        onSort: (sortDirection: SortDirection) => {
          handleSort('reasonListId', sortDirection);
        },
      },
      statusId: {
        label: translations('status'),
        isSortable: true,
        render: (record: TRequest) => (
          <Badge theme={getRequestStatusBadgeTheme(record.statusId)} className={styles.badge}>
            {record.statusName}
          </Badge>
        ),
        onSort: (sortDirection: SortDirection) => {
          handleSort('statusId', sortDirection);
        },
      },
      dueDate: {
        label: translations('applyDate'),
        isSortable: true,
        render: (record: TRequest) => parseDate(new Date(record.dueDate), getLocale(lang)),
        onSort: (sortDirection: SortDirection) => {
          handleSort('dueDate', sortDirection);
        },
      },
      deadlineDate: {
        label: translations('responseTime'),
        isSortable: true,
        render: (record: TRequest) => getDaysToResponse(record.deadlineDate, getLocale(lang)),
        onSort: (sortDirection: SortDirection) => {
          handleSort('deadlineDate', sortDirection);
        },
      },
      createDate: {
        label: translations('createDate'),
        isSortable: true,
        render: (record: TRequest) => parseDate(new Date(record.createDate), getLocale(lang)),
        onSort: (sortDirection: SortDirection) => {
          handleSort('createDate', sortDirection);
        },
      },
    },
  };
};

export const useColumnsForm = () => {
  const dispatch = useAppDispatch();

  const form = useForm({
    defaultValues: {
      id: true,
      supplierId: true,
      supplierName: true,
      reasonListId: true,
      statusId: true,
      dueDate: true,
      deadlineDate: true,
      createDate: true,
    },
  });

  const saveColumnsFilter = useCallback(() => {
    form.handleSubmit((data) => {
      dispatch(setVisibleColumns(data));
    })();
  }, [dispatch, form]);

  const resetColumnsFilters = useCallback(() => {
    form.reset();
    dispatch(setVisibleColumns(form.getValues()));
  }, [dispatch, form]);

  return { form, saveColumnsFilter, resetColumnsFilters };
};
