import { useController, useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { SelectItem } from 'common/types';
import { useAppDispatch } from 'store/hooks';
import { useAppTranslations } from 'translations';
import { clearDeletedFilters, setFilters } from 'store/cbd/allUploads/filters';
import { FilterName } from '../AllUploads.types';
import { getLabel } from './consts';

interface IDefaultValues {
  cbdSupplierId: SelectItem | null;
  cbdDate: string;
  cbdId: string;
  loadFileName: string;
  cbdCreatedAt: string;
  itemGroup: string;
  itemDep: string;
  itemClass: string;
  itemSubclass: string;
  attributeDep: string;
  attributeSubDep: string;
  attributeGroup: string;
  cbdStatus: string;
  requestStatusId: string;
}

export const useFiltersForm = () => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const defaultValues: IDefaultValues = {
    cbdSupplierId: null,
    cbdDate: '',
    cbdId: '',
    loadFileName: '',
    cbdCreatedAt: '',
    itemGroup: '',
    itemDep: '',
    itemClass: '',
    itemSubclass: '',
    attributeDep: '',
    attributeSubDep: '',
    attributeGroup: '',
    cbdStatus: '',
    requestStatusId: '',
  };

  const form = useForm({
    defaultValues,
  });

  const { control, handleSubmit } = form;

  const { field: cbdSupplierId } = useController({ name: 'cbdSupplierId', control });
  const { field: cbdDate } = useController({ name: 'cbdDate', control });
  const { field: cbdId } = useController({ name: 'cbdId', control });
  const { field: loadFileName } = useController({ name: 'loadFileName', control });
  const { field: cbdCreatedAt } = useController({ name: 'cbdCreatedAt', control });
  const { field: itemGroup } = useController({ name: 'itemGroup', control });
  const { field: itemDep } = useController({ name: 'itemDep', control });
  const { field: itemClass } = useController({ name: 'itemClass', control });
  const { field: itemSubclass } = useController({ name: 'itemSubclass', control });
  const { field: attributeDep } = useController({ name: 'attributeDep', control });
  const { field: attributeSubDep } = useController({ name: 'attributeSubDep', control });
  const { field: attributeGroup } = useController({ name: 'attributeGroup', control });
  const { field: cbdStatus } = useController({ name: 'cbdStatus', control });
  const { field: requestStatusId } = useController({ name: 'requestStatusId', control });

  const onSubmit = useCallback(() => {
    handleSubmit((data) => {
      const keys = Object.keys(data);
      dispatch(clearDeletedFilters());
      dispatch(
        setFilters(
          keys
            .map((key) => ({
              id: key,
              value:
                key !== 'cbdSupplierId'
                  ? data[key as FilterName]
                  : data.cbdSupplierId?.value || null,
              label: getLabel(key as FilterName, translations),
            }))
            .filter(({ value }) => (Array.isArray(value) ? value.length > 0 : Boolean(value)))
        )
      );
    })();
  }, [dispatch, handleSubmit, translations]);

  return {
    form,
    cbdSupplierId,
    cbdDate,
    cbdId,
    loadFileName,
    cbdCreatedAt,
    itemGroup,
    itemDep,
    itemClass,
    itemSubclass,
    attributeDep,
    attributeSubDep,
    attributeGroup,
    cbdStatus,
    requestStatusId,
    onSubmit,
  };
};
