import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getCbdSettingsTimeouts } from 'api/cbd/cbd';
import { ICbdLoadTimeouts } from 'common/cbd/types';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

interface SliceState {
  isTimeoutsLoaded: boolean;
  timeouts: ICbdLoadTimeouts;
}

const initialState = {
  isTimeoutsLoaded: false,
  timeouts: {
    cbdUpdateTimeout: 2000,
    cbdUploadTimeout: 300000,
  },
};

export const getCbdLoadTimeouts = createAsyncThunk(
  'cbdSettings/getCbdLoadTimeouts',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { isTimeoutsLoaded, timeouts } = (getState() as RootState).cbd.cbdSettings;
      if (isTimeoutsLoaded) return timeouts;

      const res = await getCbdSettingsTimeouts();
      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const cbdSettingsSlice = createSlice({
  initialState,
  name: 'cbdSettings',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getCbdLoadTimeouts.fulfilled,
      (state: SliceState, action: PayloadAction<ICbdLoadTimeouts>) => {
        state.isTimeoutsLoaded = true;
        state.timeouts = action.payload;
      }
    );
  },
});

export const useCbdLoadTimeouts = (): ICbdLoadTimeouts => {
  return useAppSelector((state: RootState) => state.cbd.cbdSettings.timeouts);
};
