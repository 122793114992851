import { CheckPossibilityCreatingNewRequest } from '@ppm/ppm-platform-api';
import { useAppDispatch } from 'store/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getReasons, useReasonsData } from 'store/dictionary/reasons';
import { getDueDate, useDueDateData } from 'store/dictionary/dueDate';
import { getRequestCreation, useRequestCreationData } from 'store/requestCreation/requestCreation';
import { formatCreatingData } from 'pages/Request/partials/DownloadingTemplate/helpers';
import { useStepperContext } from 'components/Stepper/StepperContext';
import { useMount } from 'hook/useMount';
import { useAppTranslations } from 'translations';
import { isProd, isSupplierEnvironment } from 'common/consts';
import { createDefaultValue } from 'pages/Request/helpers';
import { getStep } from 'store/requestCreation/helpers';
import { getCheckPossibilityCreatingNewRequest } from 'api/request/createRequest';
import { gtmClickCreateRequest } from '../../gtm';
import { CreateFormFields, Steps } from '../../types';
import { getSchema } from '../../schema';

export const useGetFormData = () => {
  const dispatch = useAppDispatch();
  const t = useAppTranslations();

  const { reasons } = useReasonsData().data;
  const { dueDate } = useDueDateData().data;

  useMount(() => {
    dispatch(getDueDate());
    dispatch(getReasons(t));
  });

  return { reasons, dueDate };
};

export const useGetCreationRequestErrors = (departmentId: number | undefined) => {
  const [errors, setErrors] = useState<CheckPossibilityCreatingNewRequest.Request['errors'] | null>(
    null
  );

  const getErrors = useCallback(async () => {
    if (!departmentId) return null;

    const { errors: response } = await getCheckPossibilityCreatingNewRequest({
      urlParameters: [departmentId.toString()],
    });

    setErrors(response);
    return null;
  }, [departmentId]);

  useEffect(() => {
    getErrors();
  }, [getErrors]);

  return { errors };
};

export const useCreateFormRequest = () => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();
  const navigate = useNavigate();

  const { reasons } = useReasonsData().data;
  const { request } = useRequestCreationData().data;
  const { dueDate: dateValidation } = useGetFormData();

  const { validatedStep } = useStepperContext<keyof typeof Steps>();

  const { control, watch, handleSubmit, formState, getValues, resetField } =
    useForm<CreateFormFields>({
      criteriaMode: 'all',
      resolver: yupResolver(getSchema({ reasons, translations, dateValidation })),
      mode: 'onChange',
      defaultValues: request
        ? createDefaultValue(request, reasons, translations)
        : {
            reasonListId: [],
            supplierId: {},
            dueDate: '',
          },
    });

  const { field: reasonListId } = useController({ name: 'reasonListId', control });
  const { field: customReason } = useController({ name: 'customReason', control });
  const { field: dueDate } = useController({ name: 'dueDate', control });
  const { field: supplierId } = useController({ name: 'supplierId', control });
  const { field: comment } = useController({ name: 'comment', control });
  const submitForm = useCallback(
    async (values: CreateFormFields) => {
      try {
        const { data } = await dispatch(
          getRequestCreation(formatCreatingData(values, translations))
        ).unwrap();
        if (isSupplierEnvironment) {
          if (isProd) {
            gtmClickCreateRequest({
              t: translations,
              requestId: data.id,
              reasons: values.reasonListId.map(({ label }) => label),
            });
          }
          validatedStep(getStep(data));
          navigate(`/create-request?id=${data.id}`);
          return null;
        }
        navigate(`/request/${data.id}`);
        return null;
      } catch (e) {
        return e;
      }
    },
    [dispatch, navigate, translations, validatedStep]
  );

  return {
    reasonListId,
    customReason,
    dueDate,
    supplierId,
    comment,
    submitForm,
    watch,
    handleSubmit,
    formState,
    getValues,
    resetField,
  };
};
