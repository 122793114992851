import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getLoadErrorCodesReq } from 'api/cbd/selects';
import { IGetLoadErrorCodesResponse } from 'common/cbd/types';
import { SelectItem } from 'common/types';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';

interface SliceState {
  list: SelectItem[];
}

const initialState = {
  list: [],
};

export const getLoadErrorCodes = createAsyncThunk(
  'cbd/loadErrorCodes/getLoadErrorCodes',
  async (_, { rejectWithValue }) => {
    try {
      return await getLoadErrorCodesReq();
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const loadErrorCodesSlice = createSlice({
  initialState,
  name: 'loadErrorCodes',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getLoadErrorCodes.fulfilled,
      (state: SliceState, action: PayloadAction<IGetLoadErrorCodesResponse>) => {
        state.list = action.payload.data;
      }
    );
  },
});

export const useLoadErrorCodes = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.loadErrorCodes);
};
